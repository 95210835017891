import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { MatDialog } from '@angular/material/dialog';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import * as _ from 'lodash';
import { PDFService } from '@app/core/services/pdf.service';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import * as util from '@app/core/services/utilityfunctions';
// Custom services
import { StoricoVenditeService } from './storico-vendite.service';
import { FiltriService } from '@app/core/services/filtri.service';
import { RoleService } from '@app/core/_base/layout/services/role.service';

import { StoricoVenditeEditComponent } from './storico-vendite-edit/storico-vendite-edit.component';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { SelectCompanyComponent } from '@app/mb-dialogs/select-company/select-company.component';
import { BulkActionsService } from '@app/core/services/bulk-actions.service';
import moment from 'moment';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';
import { Router } from '@angular/router';

declare var window: any;

enum Column {
  Check = 0,
  Date = 1,
  ProductCode = 2,
  Product = 3,
  Time = 4,
  Company = 5,
  CostCenters = 6,
  DocumentNumber = 7,
  Category = 8,
  FoodCost = 9,
  Price = 10,
  Quantity = 11,
  Unit = 12,
  Revenue = 13,
  ReceiptRevenue = 14,
  Difference = 15,
  Adjustment = 16,
  Actions = 17
}

@Component({
  selector: 'kt-storico-vendite',
  templateUrl: './storico-vendite.component.html',
  styleUrls: ['./storico-vendite.component.scss']
})
export class StoricoVenditeComponent implements OnInit {
  utility: any;
  currentCompanyFilter!: string;
  switchGroupSubcription!: Subscription;
  filtriServiceSubscription!: Subscription;

  table: any;
  currentFilterCfg: any = { Period: 'OnlyYesterday' };;
  pagination: any = {
    TotalRows: 0,
    pageSize: 10,
    pageIndex: 0
  }
  groupedByCode: boolean = false;
  groupedByDocument: boolean = false;
  showInitialImage: boolean = true;

  // FILTRI
  categorieFiltri: any;
  fornitoriFiltri: any;
  onlyCsv: boolean = false;
  OnlyDifference: boolean = false;
  aziende: any;
  Compensation: boolean = false;

  @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;

  DocumentNumber!: string;
  columnsList: any = [];
  adjustmentTypes: any;
  locale: any;

  TotalValues: any;

  bulkSelect!: any;

  SellingTypeList: any = [
    { type: 'Standard', enabled: true, value: 1 },
    { type: 'Variant', enabled: true, value: 2 },
    { type: 'MenuRoot', enabled: true, value: 4 },
    { type: 'MenuProduct', enabled: true, value: 8 },
    { type: 'Waste', enabled: true, value: 16 }
  ];

  constructor(
    public filtriService: FiltriService,
    private ref: ChangeDetectorRef,
    private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    private translationService: TranslationService,
    private switchGroupService: SwitchGroupService,
    public staticCollectionsService: StaticCollectionsService,
    private storicoVenditeService: StoricoVenditeService,
    private pdfService: PDFService,
    public dialog: MatDialog,
    public roleService: RoleService,
    private localizedDateTimePipe: LocalizedDateTimePipe,
    private bulkActionsService: BulkActionsService,
    private anagraficheService: AnagraficheService,
    public loaderService: LoaderService,
    private router: Router
  ) {

    this.utility = util;
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });

    this.columnsList = [
      { label: '', enabled: false, alignment: 'center' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.DATE'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.PRODUCT_CODE'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.PRODUCT'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.TIME'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.COMPANY'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.COSTCENTERS'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.DOCUMENT_NUMBER'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.CATEGORY'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.FOOD_COST'), enabled: true, alignment: 'right' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.PRICE'), enabled: true, alignment: 'right' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.QUANTITY'), enabled: true, alignment: 'right' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.UNIT'), enabled: true, alignment: 'left' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.REVENUE'), enabled: true, alignment: 'right' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.RECEIPT_REVENUE'), enabled: false, alignment: 'right' },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.DIFFERENCE'), enabled: false, alignment: 'right' },
      //{ label: this.translate.instant('STORICO_VENDITE.COLUMNS.IS_MENU'), enabled: true, alignment: 'center', icon: true },
      { label: this.translate.instant('STORICO_VENDITE.COLUMNS.ADJUSTMENT'), enabled: false, alignment: 'right' },
      { label: '', enabled: true }
    ];
  }

  /**
   * After view init
   */
  wasteTypes: any = [];
  async ngAfterViewInit() {
    this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
      (event: any) => {
        if (event.change) {
          this.clearFilters(false);
        }
      }
    );

    const wasteTypes: any = await lastValueFrom(this.anagraficheService.getEntity('Wastetypes'));
    if (wasteTypes) {
      this.wasteTypes = wasteTypes.filter((wt: any) => wt.IsSelling);
    }
  }

  ngOnDestroy() {
    this.switchGroupSubcription.unsubscribe();
    this.filtriServiceSubscription.unsubscribe();

    // cancello il filtro
    this.filtriService.filterConfig = {};
  }

  editSelling(item) {
    const dialogRef = this.dialog.open(StoricoVenditeEditComponent, {
      data: { item },
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.Action === 'save') {
        this.loaderService.show();
        this.storicoVenditeService.updateSelling(res.Object).toPromise()
          .then((result: any) => {
            this.loaderService.hide();
            let message = this.translate.instant('MAGAZZINO.MODIFICHE.MODIFICA_OK');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            //this.refreshTable();
            this.table.draw();

          });
      }

    });
  }

  deleteSelling(id) {
    const _title: string = this.translate.instant('STORICO_VENDITE.DELETE_DIALOG.TITLE');
    const _description: string = this.translate.instant('STORICO_VENDITE.DELETE_DIALOG.DESC');
    const _waitDesciption: string = '';
    const _yesButton = this.translate.instant('STORICO_VENDITE.DELETE_DIALOG.BUTTON');
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm) {
        this.storicoVenditeService.deleteSelling(id).subscribe(
          (result: any) => {

            let message: string = '';
            if (result.Msg === 'Ok') {
              message = this.translate.instant('STORICO_VENDITE.DELETE_DIALOG.SUCCESS');
              this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
              //this.refreshTable();
              this.table.draw();
            } else {
              message = this.translate.instant('STORICO_VENDITE.DELETE_DIALOG.ERROR');
              this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
            }
          }
        );
      }

    });
  }

  clearFilters(actionFilter: boolean = true) {
    this.PriceZero = '0';
    this.CostZero = '0';
    this.MenuSellings = '0';
    this.AdjustmentType = '';
    if (this.ProductOrCode) this.ProductOrCode.nativeElement.value = '';
    this.DocumentNumber = '';

    this.groupedByCode = false;
    this.groupedByDocument = false;

    this.removeGroup(false);

    this.filtriService.clearFilters$.next(actionFilter);
    $('#table').DataTable().clear().destroy();
    this.table = undefined;
    this.showInitialImage = true;
    this.ref.detectChanges();
  }

  ngOnInit() {

    document.body.classList.add('kt-aside--minimize');
    this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
      (filterConfig: any) => {

        if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
        if (filterConfig.constructor !== Object) return;

        this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
        if (this.table) {
          this.table.draw();
        } else {
          this.refreshTable();
        }
      }
    );

    lastValueFrom(this.anagraficheService.getEntity("WasteTypes"))
      .then((items: any) => {
        //items.forEach((element: any) => {
        //    this.wasteTypes.push(element);
        //});

        this.adjustmentTypes = [...items];

      });

    fromEvent(this.ProductOrCode.nativeElement, 'keyup')
      .pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(1500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap((ev: any) => {
          if (ev.keyCode == 13) this.filter(this.ProductOrCode.nativeElement.value, 'ProductOrCode');
        })
      )
      .subscribe();
  }

  PriceZero: string = '0';
  CostZero: string = '0';
  MenuSellings: string = '0';
  AdjustmentType: string = '';
  IsManual: string = '0';


  refreshTable() {
    this.loaderService.show();
    this.ref.detectChanges();
    if ($.fn.dataTable.isDataTable('#table')) {
      $('#table').DataTable().clear().destroy();
    }
    this.initDataTable();
  }

  initDataTable() {
    this.showInitialImage = false;
    setTimeout(() => {
      const currentLogin = this.switchGroupService.getCurrentGroup();
      let dtOptions: any = {
        initComplete: () => {
          this.loaderService.hide();
          this.ref.detectChanges();
        },
        autoWidth: true,
        destroy: true,
        dom: 'rt<"paginator">',
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        paging: true,
        searching: true,
        ordering: true,
        orderCellsTop: true,
        scrollY: 'calc(100vh - 400px)',
        scrollX: true,
        scrollCollapse: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: currentLogin.endpointURL + `/api/Selling/DataTable`,
          type: "POST",
          data: (d: any) => {
            d.filters = this.currentFilterCfg;
            d.Grouped = this.groupedByCode
            d.GroupedByDocument = this.groupedByDocument
            return JSON.stringify(d);
          },
          headers: {
            "Authorization": "Bearer " + currentLogin.access_token,
            "Content-Type": "application/json"
          },
          dataSrc: (response) => {
            this.pagination.TotalRows = response.recordsFiltered;
            this.TotalValues = response.TotalValue;
            this.ref.detectChanges();
            return response.data;
          }
        },
        createdRow: function (row, data, dataIndex) {
          if (data.IsCompensation) {
            $(row).addClass('rowCompensation');
          }
        },
        // calback after draw
        drawCallback: (settings: any) => {
          if (this.table) this.table.columns.adjust();
        },
        columnDefs: [
          {
            targets: [0], width: '20px', orderable: false, className: 'excludeFromExport max-width-20', render: (data) => {
              return `<i class="bi bi-square select pointer"></i>`;
            }, visible: this.columnsList[0].enabled
          },
          {
            targets: 1, visible: this.columnsList[1].enabled, width: '85px', className: 'max-width-85 nowrap',
            data: 'Date', name: 'Date', render: (data, type, p, meta) => {
              return this.localizedDateTimePipe.transform(data, undefined, true);
            }
          },
          {
            targets: 2, visible: this.columnsList[2].enabled, className: 'max-width-200',
            data: 'Code', name: 'Code', render: (data, type, p, meta) => {
              return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${data}</div>`;
            }
          },
          {
            targets: 3, visible: this.columnsList[3].enabled, className: 'max-width-250',
            data: 'Product', name: 'Product', render: (data, type, p, meta) => {

              let color = '', tooltip = '';
              switch (p.Type) {
                case 1: // Standard
                  color = ' text-danger1';
                  tooltip = this.translate.instant('STORICO_VENDITE.SELLING_TYPE.STANDARD');
                  break;
                case 2: // Variant
                  color = ' text-danger2';
                  tooltip = this.translate.instant('STORICO_VENDITE.SELLING_TYPE.VARIANT');
                  break;
                case 4: // MenuRoot
                  color = ' text-danger3';
                  tooltip = this.translate.instant('STORICO_VENDITE.SELLING_TYPE.MENU_ROOT');
                  break;
                case 8: // MenuProduct
                  color = ' text-danger4';
                  tooltip = this.translate.instant('STORICO_VENDITE.SELLING_TYPE.MENU_PRODUCT');
                  break;
                case 16: // Waste
                  color = ' text-danger5';
                  //tooltip = this.translate.instant('STORICO_VENDITE.SELLING_TYPE.WASTE');
                  tooltip = this.getAdjustment(p.AdjustmentType);
                  break;
              }
              const circle = p.Type ? `<i class="fas fa-circle ${color}" data-toggle="tooltip" title="${tooltip}"></i>` : '';

              return `<div class="d-flex justify-content-start align-items-center flex-align-center">${circle}&nbsp;<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${data}</div></div>`;
            }
          },
          { targets: 4, visible: this.columnsList[4].enabled, data: 'Time', name: 'Time', className: 'max-width-60' },
          {
            targets: 5, visible: this.columnsList[5].enabled, className: 'Company',
            data: 'Company', name: 'Company', render: (data, type, p, meta) => {
              return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${data}</div>`;
            },
            createdCell: (td, cellData, rowData, row, col) => {
              // Gestione dinamica della classe max-width-100
              if (this.groupedByDocument) {
                $(td).removeClass('max-width-100');
              } else {
                $(td).addClass('max-width-100');
              }
            },
          },
          {
            targets: 6, visible: this.columnsList[6].enabled, className: 'max-width-100',
            data: 'CostCenter', name: 'CostCenter', render: (data, type, p, meta) => {
              return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${data}</div>`;
            }
          },
          {
            targets: 7, visible: this.columnsList[7].enabled, className: 'DocumentNumber',
            data: 'DocumentNumber', name: 'DocumentNumber', render: (data, type, p, meta) => {
              let search = '';
              if (data) {
                search = '<a href="javascript:;" class="actions"><i class="bi bi-search" name="searchByDocNumber"></i></a>&nbsp;'
              }
              return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${search}${data || ''}</div>`;
            },
            createdCell: (td, cellData, rowData, row, col) => {
              // Gestione dinamica della classe max-width-100
              if (this.groupedByDocument) {
                $(td).removeClass('max-width-100');
              } else {
                $(td).addClass('max-width-100');
              }
            },
          },
          {
            targets: 8, visible: this.columnsList[8].enabled, className: 'max-width-100',
            data: 'Category', name: 'Category', render: (data, type, p, meta) => {
              return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${data}</div>`;
            }
          },
          {
            targets: 9, visible: this.columnsList[9].enabled, className: 'text-right max-width-65',
            data: 'FoodCost', name: 'FoodCost', render: (data, type, p, meta) => {
              return this.utility.formatNumber(data, this.locale);
            }
          },
          {
            targets: 10, visible: this.columnsList[10].enabled, className: 'text-right max-width-50',
            data: 'Price', name: 'Price', render: (data, type, p, meta) => {
              const price = this.utility.formatNumber(data, this.locale);
              return this.utility.formatNumber(data, this.locale);
            }
          },
          {
            targets: 11, visible: this.columnsList[11].enabled, className: 'text-right max-width-50',
            data: 'Quantity', name: 'Quantity', render: (data, type, p, meta) => {
              return this.utility.formatNumber(data, this.locale);
            }
          },
          { targets: 12, visible: this.columnsList[12].enabled, data: 'Unit', name: 'Unit', className: 'max-width-50' },
          {
            targets: 13, visible: this.columnsList[13].enabled, className: 'text-right max-width-50',
            data: 'Revenue', name: 'Revenue', render: (data, type, p, meta) => {
              return this.utility.formatNumber(data, this.locale);
            }
          },
          {
            targets: 14, visible: this.columnsList[14].enabled, className: 'text-right max-width-50',
            data: 'ReceiptRevenue', name: 'ReceiptRevenue', render: (data, type, p, meta) => {
              return this.utility.formatNumber(data, this.locale);
            }
          },
          {
            targets: 15, visible: this.columnsList[15].enabled, className: 'text-right max-width-50',
            data: 'Difference', name: 'Difference', render: (data, type, p, meta) => {
              const difference = Math.abs(0 - data) < 0.09 ? '0' : data;
              let differenceFormatted = difference;
              if (difference != 0) {
                differenceFormatted = `<div class="display-flex-space-between flex-align-center exportableTooltip" title="${this.utility.formatNumber(difference, this.locale)}"><i class="bi bi-exclamation-triangle text-warning"></i>${this.utility.formatNumber(difference, this.locale)}</div>`
              }
              return differenceFormatted;
            }
          },
          {
            targets: 16, visible: this.columnsList[16].enabled, className: 'max-width-50',
            data: 'Type', name: 'Type', render: (data, type, p, meta) => {
              return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${this.getAdjustment(data)}">${this.getAdjustment(data)}</div>`;
            }
          },
          {
            targets: 17, orderable: false, visible: this.columnsList[17].enabled,
            className: 'actions max-width-50 excludeFromExport',
            render: (data, type, p, meta) => {
              const deleteSelling = this.roleService.isCompanyAdminOrMore() ? '&nbsp;<i class="bi bi-trash text-danger pointer deleteSelling" name="deleteSelling"></i>' : ''
              return p.IdImportazione ? '' : `
                            <div class="d-flex justify-content-end align-items-center flex-align-center">
                                <i class="bi bi-pencil-square text-primary pointer editSelling" name="editSelling"></i>${deleteSelling}
                            </div>
                            `;
            }
          }
        ],
        headerCallback: (thead, data, start, end, display) => {
          const headerCellDoc = $(thead).find('th.DocumentNumber, th.Company');
          if (this.groupedByDocument) {
            headerCellDoc.removeClass('max-width-100');
          } else {
            headerCellDoc.addClass('max-width-100');
          }
        },
        aaSorting: [[1, 'desc']],
        buttons: [
          {
            className: 'toolbarButton',
            extend: 'pdf',
            orientation: 'landscape',
            title: this.PDFPreferences?.title,
            pageSize: 'A4',
            download: 'open',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.excludeFromExport)',
              format: {
                body: function (data, row, column, node) {
                  if (data.indexOf('exportableTooltip') >= 0) {
                    var parser = new DOMParser();
                    var html = parser.parseFromString(data, 'text/html');
                    let html$ = $(html);
                    data = html$.find(`.exportableTooltip`).attr("title");
                  } else if (data.indexOf("bi bi-square") >= 0) {
                    data = String.fromCharCode(parseInt('0x' + 'F584'));
                  } else if (data.indexOf("bi bi-check-square") >= 0) {
                    data = String.fromCharCode(parseInt('0x' + 'F26D'));
                  }

                  return data;
                }
              }
            },
            customize: (doc: any) => {

              const imageLogoB64 = $('#kt_header .logoimg').attr('src');
              const size = {
                width: $('#kt_header .logoimg').width(),
                height: $('#kt_header .logoimg').height()
              };

              let arrayCols: any = [];
              this.columnsList.filter((col: any) => col.enabled && col.label).forEach((colFiltered: any, index) => {
                if (index == 1) {
                  arrayCols.push('*');
                } else {
                  arrayCols.push('auto');
                }
              });

              const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
              doc.content[0] = docDefinition.content[0];
              doc.content[1].layout = docDefinition.content[1].layout;
              doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
              doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
              //doc.defaultStyle = docDefinition.defaultStyle;
              doc.footer = docDefinition.footer;
              doc.header = docDefinition.header;
              doc.pageMargins = docDefinition.pageMargins;
              doc.pageOrientation = docDefinition.pageOrientation;
              doc.pageSize = docDefinition.pageSize;
              doc.styles = docDefinition.styles;

              // Imposto gli allineamenti
              var rowCount = doc.content[1].table.body.length;

              const filteredColumns = this.columnsList.filter((col: any) => col.enabled && col.label);

              for (let i = 0; i < rowCount; i++) {
                for (let index = 0; index < filteredColumns.length; index++) {
                  doc.content[1].table.body[i][index].alignment = filteredColumns[index].alignment;
                }
              }

              doc.content[1].table.body.forEach((row, index) => {
                row.forEach((field, col) => {
                  if (filteredColumns[col].icon) {
                    if (index == 0) {
                      field.style = 'bootstrapIconsHeader';
                    } else if (field.style.indexOf('Odd') >= 0) {
                      field.style = 'bootstrapIconsOdd';
                    } else {
                      field.style = 'bootstrapIcons';
                    }
                  }
                })
              });

            },
            action: function (e, dt, node, config) {
                var self = this;
                var currentPageLen = dt.page.len();
                var currentPage = dt.page.info().page;
                var totRecords = dt.page.info().recordsTotal
                dt.one('draw', () => {
                    (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                    setTimeout(function () {
                        dt.page.len(currentPageLen).draw(); //set page length
                        dt.page(currentPage).draw('page'); //set current page
                    });
                });
                dt.page.len(totRecords).draw();
            }
          },
          {
            className: 'toolbarButton',
            extend: 'excelHtml5',
            //autoFilter: true,
            //sheetName: 'Exported data',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.excludeFromExport)',
              format: {
                body: (data, row, column, node) => {
                  if (data.indexOf('exportableTooltip') >= 0) {
                    var parser = new DOMParser();
                    var html = parser.parseFromString(data, 'text/html');
                    let html$ = $(html);
                    data = html$.find(`.exportableTooltip`).attr("title");
                  } else if (data.indexOf("bi-square") >= 0) {
                    data = '\0' + '☐';
                  } else if (data.indexOf("bi-check-square") >= 0) {
                    data = '\0' + '☑';
                  }

                  data = '\0' + data;

                  return data;
                }
              },
              orthogonal: 'export'
            },
            action: function (e, dt, node, config) {
                var self = this;
                var currentPageLen = dt.page.len();
                var currentPage = dt.page.info().page;
                var totRecords = dt.page.info().recordsTotal
                dt.one('draw', () => {
                    (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                    setTimeout(function () {
                        dt.page.len(currentPageLen).draw(); //set page length
                        dt.page(currentPage).draw('page'); //set current page
                    });
                });
                dt.page.len(totRecords).draw();
            }
          }
        ]
      };
      this.table = $('#table').DataTable(dtOptions);

      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });
      this.table.columns.adjust();

      this.table.on('page.dt', function () {
        $('.dataTables_scrollBody').animate({
          scrollTop: 0
        }, 'slow');
      });

      $('tbody').off('click');

      $('#table tbody').on('click', 'td:first-child i', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data: any = this.table.row(tr).data();
        if (data) {
          const isAlreadyChecked = $(event.target).hasClass('bi bi-check-square');
          if (isAlreadyChecked) {
            $(event.target).removeClass('bi bi-check-square');
            $(event.target).addClass('bi bi-square');
          } else {
            $(event.target).addClass('bi bi-check-square');
            $(event.target).removeClass('bi bi-square');
          }
          this.selectItem(!isAlreadyChecked, data);
        }
      });

      $('tbody').on('click', '.actions', async (event: any) => {
        const tr = $(event.target).closest('tr');
        const btn = $(event.target).closest('i');
        const data = this.table.row(tr.index()).data();

        switch (btn.attr('name')) {
          case 'editSelling':
            this.editSelling(data);
            break;
          case 'deleteSelling':
            this.deleteSelling(data.Id);
            break;
          case 'searchByDocNumber':

            // Rimuovo i raggruppamenti
            this.removeGroup(false);

            this.DocumentNumber = data.DocumentNumber;
            this.filter(`#${data.DocumentNumber}#`, 'DocumentNumber');
            break;
        }

      });

      $('tbody').on('click', '.actions', async (event: any) => {
        const tr = $(event.target).closest('tr');
        const btn = $(event.target).closest('i');
        const data = this.table.row(tr.index()).data();

        switch (btn.attr('name')) {
          case 'editSelling':
            this.editSelling(data);
            break;
          case 'deleteSelling':
            this.deleteSelling(data.Id);
            break;
        }

      });

      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });
    }, 100);
  }

  performCompensation() {

    //{
    //    None = 0,
    //    No = 1,
    //    Yes = 2,
    //    All = 3
    //}

    this.filter(this.Compensation ? 2 : 0, 'Compensation')
  }

  async performBulkSelection(event: any) {
    let selectedItems: any = [];
    $('#table tbody td:first-child i.bi.bi-check-square').each((index: number, el: any) => {

      const tr = $(el).closest('tr');
      const data: any = this.table.row(tr).data();
      selectedItems.push(data);

    });

    switch (event.value.toLowerCase()) {
      case 'compensate':
        await lastValueFrom(this.bulkActionsService.massiveActions({
          "Action": "Sellings_Compensate1",
          "Documents": selectedItems.map((item: any) => {
            return `${item.DocumentNumber};${item.Company};${moment(item.Date).format('YYYY-MM-DD')}`;
          }),
        }));
        this.table.draw();
        break;
    }
  }

  bulkSelection(event: any) {
    this.bulkSelect = undefined;
    this.ref.detectChanges();
    if (event.value.toLowerCase() !== "addsupplier") {
      let _title: string = '';
      switch (event.value.toLowerCase()) {
        case 'compensate':
          _title = this.translate.instant('STORICO_VENDITE.COMPENSAZIONE');
          break;
      }

      const _description: string = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.CONFIRM_ASK');
      const _waitDesciption: string = '';
      const _yesButton = this.translate.instant('COMMONS.CONFIRM');
      const _noButton = this.translate.instant('COMMONS.CANCEL');

      const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
      dialogRef.afterClosed().subscribe(async (ok: any) => {
        if (ok) {
          this.performBulkSelection(event);
        }
        this.bulkSelect = "";
        this.ref.detectChanges();
      });

    } else {
      this.performBulkSelection(event);
    }

  }

  selectItem(checked: boolean, item: any) {
    item['selected'] = checked;
  }

  selectAll(event: any) {
    $('#table tbody td:first-child i').each((index: number, el: any) => {
      if (!event.checked) {
        $(el).removeClass('bi bi-check-square');
        $(el).addClass('bi bi-square');
      } else {
        $(el).addClass('bi bi-check-square');
        $(el).removeClass('bi bi-square');
      }
    })
  }

  almoustOneSelected() {
    const almoustOne = $('#table tbody td:first-child i.bi.bi-check-square');
    return almoustOne.length > 0;
  }

  getAdjustment(t) {

    let msg = '';
    if (t >= 0) {
      msg = this.adjustmentTypes.find((at: any) => at.Id == t)?.Name;
    }
    return msg;
  }

  exportAsXLSX() {

    if (this.pagination.TotalRows > 10000) {
      this.layoutUtilsService.showActionNotification(this.translate.instant('EXPORT_PDF.TOO_MANY_RECORDS'), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
      return;
    }

    this.loaderService.show();
    this.table.button(1).trigger();
    setTimeout(() => {
      this.ref.detectChanges();
      this.loaderService.hide();
    }, 100);
  }

  PDFPreferences: any;
  exportAsPDF() {

    if (this.pagination.TotalRows > 10000) {
      this.layoutUtilsService.showActionNotification(this.translate.instant('EXPORT_PDF.TOO_MANY_RECORDS'), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
      return;
    }

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
      explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
    };

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      //if (result) this.preparePdfConfiguration(result);
      if (result) {
        result['header'] = {
          export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
          period: this.filtriService.getCurrentPeriod()
        };
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        };
        result['language'] = this.translationService.getSelectedLanguage();

        this.PDFPreferences = result;

        this.table.button(0).trigger();
      };
    });
  }

  changePagination(event: any) {
    if (this.pagination.pageSize != event.pageSize) {
      this.pagination.pageSize = event.pageSize;
      this.pagination.pageIndex = 0;
      this.table.page.len(this.pagination.pageSize).draw();
      this.table.page(0).draw('page');
    } else if (this.pagination.pageIndex != event.pageIndex) {
      this.pagination.pageIndex = event.pageIndex;
      this.table.page(this.pagination.pageIndex).draw('page');
    }
  }

  filter(event: any, type: string) {
    if (type == 'SellingType') {
      const list = this.SellingTypeList.filter((item: any) => item.enabled);
      if (list && list.length > 0) {
        this.currentFilterCfg[type] = list.map((item: any) => item.value).reduce((acc: any, curr: any) => acc + curr, 0);
      } else {
        this.currentFilterCfg[type] = 0;
      }
    } else {
      this.currentFilterCfg[type] = event;
    }
    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }

  refreshColumnsVisibility() {
    for (let index = 0; index < this.columnsList.length; index++) {
      var column = this.table.column(index);
      if (column) column.visible(this.columnsList[index].enabled);
    }
  }

  openColumnsDialog() {
    const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
      data: { columnsList: this.columnsList },
      width: '300px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success && res.columnsList) {
        this.columnsList = res.columnsList;
        this.refreshColumnsVisibility();
      }
    });
  }

  showFromYearStart() {
    this.showInitialImage = false;
    this.currentFilterCfg = { Period: 'OnlyYesterday' };
    this.filtriService.performFilter$.next(this.currentFilterCfg);
    this.ref.detectChanges();
  }

  showToday() {
    const today = new Date();
    const todayStr = today.toLocaleDateString('ja-JP');
    this.currentFilterCfg = { DateFilter: { Start: todayStr, End: todayStr } };
    this.filtriService.performFilter$.next(this.currentFilterCfg);
    this.ref.detectChanges();
  }

  groupByCode() {
    this.groupedByCode = !this.groupedByCode;

    if (this.groupedByCode) {

      this.groupedByDocument = false;

      //const columns = [3, 5, 7, 13, 14, 15, 16, 17]; Vecchi indici
      // Queste colonne verranno nascoste
      const columns = [Column.Time, Column.CostCenters, Column.DocumentNumber,
      Column.Revenue, Column.ReceiptRevenue, Column.Difference,
      Column.Adjustment, Column.Actions];
      let notAffected: number[] = [];

      this.columnsList.forEach((column: any, index: number) => {
        if (columns.includes(index)) {
          this.columnsList[index].enabled = !this.groupedByCode;
        } else {
          this.columnsList[index].enabled = true;
          notAffected.push(index);
        }

      })

      this.table.columns(notAffected).visible(true);

      this.columnsList[0].enabled = false;

      this.table.columns([0]).visible(false);

      this.table.columns(columns).visible(!this.groupedByCode).draw();
    } else {
      this.removeGroup();
    }

  }

  groupByDocument() {
    this.groupedByDocument = !this.groupedByDocument;
    if (this.groupedByDocument) {
      this.groupedByCode = false;

      //const columnsAfftected = [0, 2, 3, 5, 6, 8, 9, 10, 11, 12, 16]; Vecchi indici
      // Queste colonne verranno nascoste
      const columnsAfftected = [
        Column.Product, Column.Time, Column.CostCenters, Column.ProductCode,
        Column.Category, Column.FoodCost, Column.Price, Column.Quantity, Column.Unit, Column.Adjustment,
        Column.Actions
      ]
      let notAffected: number[] = [];

      this.columnsList.forEach((column: any, index: number) => {
        if (columnsAfftected.includes(index)) {
          this.columnsList[index].enabled = !this.groupedByDocument;
        } else {
          this.columnsList[index].enabled = true;
          notAffected.push(index);
        }
      })

      this.table.columns(notAffected).visible(true);
      this.table.columns(columnsAfftected).visible(!this.groupedByDocument).draw();
    } else {
      this.removeGroup();
    }
  }

  goToExport() {
    this.router.navigate(['/analisi/estrazione-dati']);
  }

  importCsv(event: any) {

    const dialogRef = this.dialog.open(SelectCompanyComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(async (store: any) => {
      if (_.isEmpty(store) || !store) {
        return;
      }

      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        let formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        this.loaderService.show();
        this.storicoVenditeService.importCsv(formData, store).toPromise()
          .then((result) => {

            if (result.Result == 'Ok') {
              let message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTAZIONE_OK');
              this.layoutUtilsService.showActionNotification(message, MessageType.Update, 100000, true, false, 3000, 'top', 'snackbar-success');
            }

            event.target.value = ''
          })
          .catch((error) => {
            let message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTAZIONE_FAIL');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 10000, true, false, 3000, 'top', 'snackbar-error');
            event.target.value = ''
          })
          .finally(() => this.loaderService.hide());
      }
    });


  }

  removeGroup(draw: boolean = true) {
    this.groupedByCode = false;
    this.groupedByDocument = false;
    this.OnlyDifference = false;
    this.onlyCsv = false;
    const columns = [2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 17];

    columns.forEach((index: number) => {
      this.columnsList[index].enabled = true;
    });

    this.columnsList[0].enabled = false;
    this.columnsList[14].enabled = false;
    this.columnsList[15].enabled = false;

    this.table.columns([0, 14, 15]).visible(false);
    this.table.columns(columns).visible(!this.groupedByDocument).draw(draw);
  }


  private timer: any = null; // Variabile per il timer
  private timerDuration: number = 3000; // Imposta la durata del timer in millisecondi (esempio: 3 secondi)

  manageClickSellingType() {
    // Se esiste già un timer, lo cancella
    if (this.timer) {
      console.log('resetto');
      clearTimeout(this.timer);
    }

    // Crea o riavvia il timer
    this.timer = setTimeout(() => {
      console.log('filtro');
      this.filter(0, 'SellingType')
    }, this.timerDuration);
  }

}
