import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { PageEvent } from '@angular/material/paginator';
import { InserimentoInventariPopupComponent } from './inserimento-inventari-popup/inserimento-inventari-popup.component';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { InserimentoType } from '@app/views/pages/magazzino/inserimento-comune/inserimento-comune.component';

import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { AziendeService } from '@app/core/services/aziende.service';
import { environment } from '@env/environment';
import * as util from '@app/core/services/utilityfunctions';
import { ScreenSizeService } from '@app/core/services/screen-size.service';
import moment from 'moment';
import { BloccoInventariComponent } from './blocco-inventari/blocco-inventari.component';
import { LoaderService } from '@app/core/services/loader.service';
import { info } from 'console';

declare var $: any;

@Component({
  selector: 'kt-inventari',
  templateUrl: './inventari.component.html',
  styleUrls: ['./inventari.component.scss', '../common-styles.scss'],
})
export class InventariComponent implements OnInit, AfterViewInit, OnDestroy {

  companies: any;
  currentCompanyFilter!: string;
  locale!: string;
  table: any;
  currentFilterCfg: any;

  totalQuantity: number = 0;
  totalPrice: number = 0;
  totalTotal: number = 0;

  switchGroupSubcription!: Subscription;
  filtriServiceSubscription!: Subscription;

  showInitialImage: boolean = true;
  pagination: any = {
    TotalRows: 0,
    pageSize: 15,
    pageIndex: 0
  };
  columnsList: any = [];
  orderModes: any[] = [
    { Id: 'Code', Text: 'Code' },
    { Id: 'Name', Text: 'Name' },
    { Id: 'CategoryAndName', Text: 'Category And Name' }

  ];
  selectedOrderMode: any;
  categorie: any;

  @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;

  utility: any;
  isMobile: boolean = false;

  constructor(
    private filtriService: FiltriService,
    private magazzinoService: MagazzinoService,
    private ref: ChangeDetectorRef,
    private translate: TranslateService,
    private excelService: ExcelService,
    private pdfService: PDFService,
    private layoutUtilsService: LayoutUtilsService,
    private translationService: TranslationService,
    private switchGroupService: SwitchGroupService,
    public dialog: MatDialog,
    public staticCollectionsService: StaticCollectionsService,
    public aziendeSrv: AziendeService,
    private router: Router,
    public roleService: RoleService,
    private screenSizeService: ScreenSizeService,
    private activatedRoute: ActivatedRoute,
    public loaderService: LoaderService
  ) {
    this.utility = util;

    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 1023;
    });
  }

  /**
   * After view init
   */
  async ngAfterViewInit() {
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });

    this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
      (event: any) => {
        if (event.change) {
          this.clearFilters(false);
        }
      }
    );

    await this.staticCollectionsService.fillStaticCollection(false);
    this.loaderService.hide();

    this.activatedRoute.queryParams.subscribe((params: any) => {
      setTimeout(() => {
        //this.initFiltri();
        if (params.filters) {
          try {
            const filters = JSON.parse(params.filters);
            if (!this.currentFilterCfg) this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
            for (const [key, value] of Object.entries(filters)) {
              this.currentFilterCfg[key] = value;
            }
            this.filtriService.performFilter$.next(this.currentFilterCfg);
          } catch (error) {
            console.log(error);
          }
        }
      }, 1000);
    });

    this.columnsList = [
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DATE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COMPANY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COSTCENTERS'), enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CODE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CATEGORY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NAME'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.UNIT'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.PRICE'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.TOTAL'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DEPARTMENT'), enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NOTE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
    ];
    this.selectedOrderMode = 'Name';

    this.orderModes = [
      { Id: 'Code', Text: this.translate.instant('EXPORT_PDF.SORT.CODE') },
      { Id: 'Name', Text: this.translate.instant('EXPORT_PDF.SORT.NAME') },
      { Id: 'CategoryAndName', Text: this.translate.instant('EXPORT_PDF.SORT.CAT_NAME') }
    ];
  }

  ngOnDestroy() {
    this.switchGroupSubcription.unsubscribe();
    this.filtriServiceSubscription.unsubscribe();

    // cancello il filtro
    this.filtriService.filterConfig = {};

    // Se l'url non include inserimento-comune allora svuoto la localstorage
    if (!this.router.url.includes('/magazzino/inserimento-comune')) {
      localStorage.removeItem('currentFilterCfg');
    }
  }

  searchByCode(code: string) {
    this.ProductOrCode.nativeElement.value = code;
    this.filter(code, 'ProductOrCode')
  }

  getTitle() {
    return this.translate.instant('MAGAZZINO.INVENTARI.TITLE');
  }

  goToStatistiche() {
    this.router.navigate(['/magazzino/statistiche'], { queryParams: { entity: 'Inventory', localized: 'MAGAZZINO.STATISTICHE.WAREHOUSE_SELECTOR.INVENTARI' } });
  }

  ngOnInit() {
    this.categorie = [];
    this.aziendeSrv.getAziende().subscribe(
      result => this.companies = result
    )
    document.body.classList.add('kt-aside--minimize');
    this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
      (filterConfig: any) => {

        if (localStorage.getItem('currentFilterCfg')) {
          try {
            this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
            localStorage.removeItem('currentFilterCfg');
            this.showInitialImage = false;
            this.filtriService.performFilter$.next(this.currentFilterCfg);
            return;
          } catch (error) {
            localStorage.removeItem('currentFilterCfg');
          }
        }

        //
        if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
        if (filterConfig.constructor !== Object) return;
        this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

        // Inizializzo EVENTUALMENTE le select
        setTimeout(() => {
          if (this.currentFilterCfg && this.currentFilterCfg.CategoryIds && this.currentFilterCfg.CategoryIds.length > 0) {
            this.categorie = this.currentFilterCfg.CategoryIds;
          }
        }, 1000)

        this.showInitialImage = false;
        if (this.table) {
          this.table.draw()
        } else {
          this.initDataTable();
        }
      }
    );

    fromEvent(this.ProductOrCode.nativeElement, 'keydown').subscribe(
      (event: any) => {
        if (event.keyCode === 13) {
          this.filter(this.ProductOrCode.nativeElement.value, 'ProductOrCode');
        }
      }
    );
  }

  editLocks() {
    const dialogRef = this.dialog.open(BloccoInventariComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.Action === 'save') {
        this.loaderService.show();
        this.magazzinoService.lockInventory(res.Object).toPromise()
          .then((result: any) => {
            this.loaderService.hide();
            let message = this.translate.instant('INVENTARI.BLOCCO_INVENTARI.SALVATAGGIO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            this.table.draw()

          });
      }

    });
  }

  clearFilters(actionFilter: boolean = true) {
    this.categorie = undefined;
    this.ProductOrCode.nativeElement.value = '';
    this.filtriService.clearFilters$.next(actionFilter);
    $('#tableInvetory').DataTable().clear().destroy();
    this.table = null;
    this.showInitialImage = true;
    this.ref.detectChanges();
  }

  selectCategory(categoryId: string) {
    if (this.categorie.indexOf(categoryId) < 0) {
      this.categorie.push(categoryId);
      this.filter(this.categorie, 'CategoryIds');
    }
  }  

  changePagination(event: any) {
    if (this.pagination.pageSize != event.pageSize) {
      this.pagination.pageSize = event.pageSize;
      this.pagination.pageIndex = 0;
      this.table.page.len(this.pagination.pageSize).draw();
      this.table.page(0).draw('page');
    } else if (this.pagination.pageIndex != event.pageIndex) {
      this.pagination.pageIndex = event.pageIndex;
      this.table.page(this.pagination.pageIndex).draw('page');
    }
  }

  initDataTable() {
    this.loaderService.show();

    if ($.fn.dataTable.isDataTable('#tableInvetory')) {
      $('#tableInvetory').DataTable().destroy();
    }

    setTimeout(() => {
      const currentLogin = this.switchGroupService.getCurrentGroup();

      let dtOptions: any = {
        initComplete: function (settings: any, json: any) {
          // Prendi l'elemento paginazione generato (div.dataTables_paginate)
          var $pagination = $(this.api().table().container()).find('div.dataTables_paginate');
          // Spostalo nel contenitore personalizzato che abbiamo definito in HTML
          $('.custom-pagination-container').html($pagination);

          const dataTables_length = $(this.api().table().container()).find('.dataTables_length');
          $('.custom-pagination-container').prepend(dataTables_length);

          // Prendi l'elemento info generato (div.dataTables_paginate)
          var $info = $(this.api().table().container()).find('div.dataTables_info');
          // Spostalo nel contenitore personalizzato che abbiamo definito in HTML
          $('.custom-info-container').html($info);
        },
        destroy: true,
        orderCellsTop: true,
        paging: true,
        searching: true,
        dom: 'rtilp',
        info: true,
        ordering: true,
        scrollY: 'calc(100vh - 22rem)',
        scrollX: false,
        scrollCollapse: true,
        autoWidth: true,
        aaSorting: [], // inizialmente nessun ordinamento
        processing: true,
        serverSide: true,
        ajax: {
          url: currentLogin.endpointURL + "/api/Warehouse/GetInventoryDT",
          type: "POST",
          data: (d: any) => {
            d.filters = this.currentFilterCfg;
            return JSON.stringify(d);
          },
          headers: {
            "Authorization": "Bearer " + currentLogin.access_token,
            "Content-Type": "application/json"
          },
          dataSrc: (response) => {
            this.pagination.TotalRows = response.TotalRows;
            this.totalQuantity = response.TotalValue.Quantity;
            this.totalPrice = response.TotalValue.Price;
            this.totalTotal = response.TotalValue.Total;
            this.ref.detectChanges();
            return response.data;
          }
        },
        columnDefs: [
          {
            targets: [0], data: 'Date', name: 'Date', render: (data: any, type: any, row: any, meta: any) => {
              const date = moment(data).format('DD/MM/YYYY');
              return `<a href="javascript:;" class="exportableTooltip" name="editItem" title="${date}">${date}</a>`;
            },
            visible: this.columnsList[0]?.enabled
          },
          {
            targets: [1], data: 'Company', name: 'Company', render: (data: any, type: any, row: any, meta: any) => {
              const tooltip = this.translate.instant(data);
              return `<span title="${tooltip}" class="exportableTooltip">${data}</span>`
            }, className: "overflow-ellipses",
            visible: this.columnsList[1]?.enabled
          },
          {
            targets: [2], data: 'CostCenter', name: 'CostCenter', render: (data: any, type: any, row: any, meta: any) => {
              const tooltip = this.translate.instant(data.Name);
              return `<span title="${tooltip}" class="exportableTooltip">${data.Name}</span>`
            }, className: "overflow-ellipses",
            visible: this.columnsList[2]?.enabled
          },
          {
            targets: [3], data: 'Code', name: 'Code', render: (data: any, type: any, row: any, meta: any) => {
              const tooltip = this.translate.instant('MAGAZZINO.TooltipSearchByCode');
              return `<span class="exportableTooltip" title="${data}">${data}</span><a href="javascript:;" name="searchByCode" class="ms-2 pull-right"><i class="bi bi-search pointer"
                        title="${tooltip}"></i></a>`
            }, className: "overflow-ellipses",
            visible: this.columnsList[3]?.enabled
          },
          {
            targets: [4], data: 'Category', name: 'Category', render: (data: any, type: any, row: any, meta: any) => {
              const tooltip = this.translate.instant(data.Name);
              return `<span title="${tooltip}" class="exportableTooltip">${data.Name}</span>`
            }, className: "overflow-ellipses",
            visible: this.columnsList[4]?.enabled
          },
          {
            targets: [5], data: 'Goods', name: 'Goods', render: (data: any, type: any, row: any, meta: any) => {
              const tooltip = this.translate.instant(data.Name);
              return `<span title="${tooltip}" class="exportableTooltip">${data.Name}</span>`
            }, className: "overflow-ellipses",
            visible: this.columnsList[5]?.enabled
          },
          {
            targets: [6], data: 'Quantity', name: 'Quantity', render: (data: any, type: any, row: any, meta: any) => {
              const tooltip = this.translate.instant('MAGAZZINO.INVENTARI.HINT_ZERO_QTY');
              const infoCircle = data == 0 ? `<i class="bi bi-info-circle" title="${tooltip}"></i>` : '<span>&nbsp;</span>';
              const quantity = this.utility.formatNumber(data);
              return `<div class="display-flex-space-between">
                        ${infoCircle}
                        <span class="exportableTooltip" title="${quantity}">${quantity}</span>
                      </div>`
            }, visible: this.columnsList[6]?.enabled
          },
          { targets: [7], data: 'Unit', name: 'Unit', visible: this.columnsList[7]?.enabled },
          {
            targets: [8], data: 'Price', name: 'Price', render: (data: any, type: any, row: any, meta: any) => {
              const price = this.utility.formatNumber(data);
              return `<span class="numericField">${price}</span>`
            }, className: "text-right numericField", visible: this.columnsList[8]?.enabled
          },
          {
            targets: [9], data: 'Total', name: 'Total', render: (data: any, type: any, row: any, meta: any) => {
              const quantity = this.utility.formatNumber(data);
              return `<span class="numericField">${quantity}</span>`
            }, className: "text-right", visible: this.columnsList[9]?.enabled
          },
          { targets: [10], data: 'Department', name: 'Department', visible: this.columnsList[10]?.enabled },
          {
            targets: [11], render: (data: any, type: any, row: any, meta: any) => {
              const tooltipNote = row.Note || '';
              const activeNote = row.Note ? 'active' : '';
              const editDayItemTooltip = this.translate.instant('MAGAZZINO.INVENTARI.MODIFICA_GIORNO');
              const editDayItem = this.roleService.canWrite() && !row.IsLocked ? `<a href="javascript:;" name="editDayItem"><i
                        class="ms-2 bi bi-plus-lg text-success pointer" title="${editDayItemTooltip}"></i></a>` : '';
              const editItemTooltip = this.translate.instant('MAGAZZINO.INVENTARI.MODIFICA');
              const editItem = this.roleService.canWrite() && !row.IsLocked ? `<a href="javascript:;" name="editItem"><i
                        class="ms-2 bi bi-pencil-square pointer" title="${editItemTooltip}"></i></a>` : '';
              const deleteItemTooltip = this.translate.instant('MAGAZZINO.INVENTARI.ELIMINA');
              const deleteItem = this.roleService.canWrite() && !row.IsLocked ? `<a href="javascript:;" name="deleteItem"><i
                        class="ms-2 bi bi-trash text-danger pointer" title="${deleteItemTooltip}"></i></a>` : '';

              return `<div class="d-flex justify-content-end align-items-center">
                        <i class="${activeNote} ms-2 bi bi-sticky" title="${tooltipNote}"></i>
                        ${editDayItem}
                        ${editItem}
                        ${deleteItem}
              </div>`;
            }, className: "excludeFromExport text-right", visible: this.columnsList[11]?.enabled, orderable: false
          }
        ],
        language: {
          emptyTable: this.translate.instant('DATATABLES.INFO_EMPTY'),
          zeroRecords: this.translate.instant('DATATABLES.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">',
          lengthMenu: this.translate.instant('DATATABLES.LENGTH_MENU'),
          info: 'Visualizzando _START_ a _END_ di _TOTAL_ risultati',
          // Aggiungo i valori di previous e next
          paginate: {
            first: this.translate.instant('DATATABLES.FIRST'),
            last: this.translate.instant('DATATABLES.LAST'),
            next: this.translate.instant('DATATABLES.NEXT'),
            previous: this.translate.instant('DATATABLES.PREVIOUS')
          }
        },
        buttons: [
          {
            className: 'toolbarButton',
            extend: 'pdf',
            //text: '<i class="bi bi-file-earmark-pdf"></i>',
            orientation: 'landscape',
            title: this.PDFPreferences?.title,
            pageSize: 'A4',
            download: 'open',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.excludeFromExport)',
              format: {
                body: (data, row, column, node) => {
                  if (data.indexOf('exportableTooltip') >= 0) {
                    var parser = new DOMParser();
                    var html = parser.parseFromString(data, 'text/html');
                    let html$ = $(html);
                    data = html$.find(`.exportableTooltip`).attr("title");
                  } else if (data.indexOf('numericField') >= 0) {
                    var parser = new DOMParser();
                    var html = parser.parseFromString(data, 'text/html');
                    let html$ = $(html);
                    data = html$.find(`.numericField`).text();
                    //data = this.utility.parseNumber(data, this.locale);
                  }

                  return data;
                }
              }
            },
            customize: (doc: any) => {

              const imageLogoB64 = $('#kt_header .logoimg').attr('src');
              const size = {
                width: $('#kt_header .logoimg').width(),
                height: $('#kt_header .logoimg').height()
              };

              // recuperare le colonne visibili
              const arrayCols: any = [];
              for (let index = 0; index <= doc.content[1].table.body[1].length; index++) {
                if (this.columnsList[index].enabled) {
                  arrayCols.push('auto');
                }
              }

              const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
              doc.content[0] = docDefinition.content[0];
              doc.content[1].layout = docDefinition.content[1].layout;
              doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
              doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
              ////doc.defaultStyle = docDefinition.defaultStyle;
              doc.footer = docDefinition.footer;
              doc.header = docDefinition.header;
              doc.pageMargins = docDefinition.pageMargins;
              doc.pageOrientation = docDefinition.pageOrientation;
              doc.pageSize = docDefinition.pageSize;
              doc.styles = docDefinition.styles;

            },
            action: function (e, dt, node, config) {
              var self = this;
              var currentPageLen = dt.page.len();
              var currentPage = dt.page.info().page;
              var totRecords = dt.page.info().recordsTotal
              dt.one('draw', () => {
                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                setTimeout(function () {
                  dt.page.len(currentPageLen).draw(); //set page length
                  dt.page(currentPage).draw('page'); //set current page
                });
              });
              dt.page.len(totRecords).draw();
            }
          },
          {
            className: 'toolbarButton',
            extend: 'excelHtml5',
            //autoFilter: true,
            //sheetName: 'Exported data',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.excludeFromExport)',
              format: {
                body: (data, row, column, node) => {
                  if (data.indexOf('exportableTooltip') >= 0) {
                    var parser = new DOMParser();
                    var html = parser.parseFromString(data, 'text/html');
                    let html$ = $(html);
                    data = html$.find(`.exportableTooltip`).attr("title");
                    data = '\0' + data;
                  } else if (data.indexOf('numericField') >= 0) {
                    var parser = new DOMParser();
                    var html = parser.parseFromString(data, 'text/html');
                    let html$ = $(html);
                    data = html$.find(`.numericField`).text();
                    data = this.utility.parseNumber(data, this.locale);
                  }
                  return data;
                }
              },
              orthogonal: 'export'
            },
            customize: async (xlsx: any) => {

              let sheet = xlsx.xl.worksheets['sheet1.xml'];

              const headers = $('row:eq(1) c', sheet);
              const priceText = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRICE');
              const totalText = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.TOTALE');

              // cerco dentro headers il testo delle colonne prezzo e totale e ricavo la lettera della colonna
              let colPrice = '';
              let colTotal = '';
              headers.each(function (index: any, element: any) {
                if ($(element).text() === priceText) {
                  colPrice = $(element).attr('r').replace(/[0-9]/g, '');
                }
                if ($(element).text() === totalText) {
                  colTotal = $(element).attr('r').replace(/[0-9]/g, '');
                }
              });

              // do uno stile 64 alle colonne prezzo e totali
              $(`row c[r^="${colPrice}"]`, sheet).attr('s', '64');
              $(`row c[r^="${colTotal}"]`, sheet).attr('s', '64');


            },
            action: function (e, dt, node, config) {
              var self = this;
              var currentPageLen = dt.page.len();
              var currentPage = dt.page.info().page;
              var totRecords = dt.page.info().recordsTotal
              dt.one('draw', () => {
                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                setTimeout(function () {
                  dt.page.len(currentPageLen).draw(); //set page length
                  dt.page(currentPage).draw('page'); //set current page
                });
              });
              dt.page.len(totRecords).draw();
            }
          }
        ],
        pageLength: 15,
        lengthMenu: [ [15, 50, 100, -1], [15, 50, 100, this.translate.instant('DATATABLES.TUTTI')] ],
      };
      this.table = $('#tableInvetory').DataTable(dtOptions);
      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });

      this.table.on('page.dt', function () {
        $('.dataTables_scrollBody').animate({
          scrollTop: 0
        }, 'slow');
      });

      $('tbody').on('click', 'a', async (event: any) => {
        const tr = $(event.target).closest('tr');
        const a = $(event.target).closest('a');
        const data = this.table.row(tr.index()).data();

        switch (a.attr('name')) {
          case 'searchByCode':
            this.searchByCode(data.Code);
            break;
          case 'editItem':
            this.editItem(data.Id);
            break;
          case 'editDayItem':
            this.editDayItem(data);
            break;
          case 'deleteItem':
            this.deleteItem(data);
            break;
        }

      });

      if (this.isMobile) {
        new (<any>$.fn).dataTable.Responsive(this.table, {
          details: {
            display: (<any>$.fn).dataTable.Responsive.display.childRowImmediate,
            type: 'none',
            target: ''
          }
        });

      }
    }, 100);
  }

  filterByCategory(categoryId: string) {
    this.filter([categoryId], 'CategoryIds');
  }

  filter(event: any, type: string) {
    if (this.showInitialImage) {
      this.showInitialImage = false;
      this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
      this.currentFilterCfg[type] = event;
      //this.filtriService.performFilter$.next(this.currentFilterCfg);
      this.initDataTable();
    } else {
      switch (type) {
        case 'ProductOrCode':
          if (!this.currentFilterCfg) this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
          this.currentFilterCfg['ProductOrCode'] = event;
          //this.filtriService.performFilter$.next(this.currentFilterCfg);
          this.table.draw();
          break;
        case 'CategoryIds':
          if (!this.currentFilterCfg) this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
          this.currentFilterCfg['CategoryIds'] = event;
          //this.filtriService.performFilter$.next(this.currentFilterCfg);
          this.table.draw();
          break;
        case 'SupplierIds':
          if (!this.currentFilterCfg) this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
          this.currentFilterCfg['SupplierIds'] = event;
          //this.filtriService.performFilter$.next(this.currentFilterCfg);
          this.table.draw();
          break;
      }
    }
  }

  openPopup() {
    const newEndpoint = {};
    this.editPopup(newEndpoint);
  }

  async editPopup(endpoint: any) {
    const dialogRef = this.dialog.open(InserimentoInventariPopupComponent, {
      data: { endpoint },
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success && res.body) {
        // mi salvo la configurazione dei filtri nei cookie.				
        if (this.currentFilterCfg) {
          localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));
        }

        if (res.body.selectedFile) {
          res.body.import = await this.importInventory(res.body);
        }

        this.magazzinoService.setCurrentConfig(res);

        this.openExistingInventoryOrNew(res.body);
      }

    });
  }

  openColumnsDialog() {
    const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
      data: { columnsList: this.columnsList },
      width: '300px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success && res.columnsList) {
        this.columnsList = res.columnsList;
        for (let index = 0; index < this.columnsList.length; index++) {
          var column = this.table.column(index);
          if (column) column.visible(this.columnsList[index].enabled);
        }
      }
    });
  }

  async importInventory(popupData: any) {
    const excel = await this.excelService.readFile(popupData.selectedFile, popupData.Company, 1);

    const colCategoria = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CATEGORIA');
    const colCodice = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE');
    const colProdotto = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO');
    const colQUB = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE');
    const colUB = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE');
    const colQUM = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO');
    const colUM = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_MAGAZZINO');

    let importBody: any[] = [];
    const locale = this.locale;
    const productList = excel.filter((item: any) => {

      try {

        // validQUM = true se item[colQUM] è un valore numerico >= 0
        const validQUM = _.isNumber(item[colQUM]);
        // validQUB = true se item[colQUB] è un valore numerico >= 0
        const validQUB = _.isNumber(item[colQUB]);

        const parsedColQUM = validQUM ? util.parseNumber(item[colQUM], locale) : -1;
        const parsedColQUB = validQUB ? util.parseNumber(item[colQUB], locale) : -1;

        const res = (parsedColQUM >= 0 || parsedColQUB >= 0) ? true : false;
        return res;
      } catch (error) {
        return false
      }

    });

    productList.forEach((item: any) => {
      let qMag = util.parseNumber(item[colQUM], locale);
      let qBase = util.parseNumber(item[colQUB], locale);

      let itemTmp = {
        GoodId: Number(item.Id),
        Date: popupData.Date,
        DocumentNumber: popupData.DocNumber,
        SupplierId: Number(popupData.SupplierId),
        OrderId: popupData.OrderId ? popupData.OrderId.OrderId : null,
        DepartmentId: popupData.DepartmentId ? Number(popupData.DepartmentId) : null,
        CodiceMerce: item[colCodice],
        Company: popupData.Company,

        // Servono per la precompilazione
        BaseQuantity: qBase,
        Quantity: qMag,
        Category: item[colCategoria],
      };


      var costCenter;
      costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => (cc.Id && popupData.CostCenterId) ? cc.Id.toString() === popupData.CostCenterId.toString() : false);
      if (!costCenter) costCenter = this.staticCollectionsService.costCenters$[0];
      if (costCenter && costCenter.$id) delete costCenter.$id;
      if (costCenter && !costCenter.Name) {
        costCenter.Name = '---';
      }
      itemTmp['CostCenter'] = costCenter;

      importBody.push(itemTmp);

    });

    return importBody;

  }

  openExistingInventoryOrNew(body: any) {
    this.loaderService.show();
    this.magazzinoService.getExistingInvetory(body).subscribe(
      result => {
        let resultConfig = JSON.parse(JSON.stringify(result));
        resultConfig.hideExcluded = true;
        resultConfig.hideFresh = true;
        resultConfig.import = body.import;
        const resultsRows: any = JSON.parse(JSON.stringify(result.Rows));
        delete resultConfig.Rows;
        this.magazzinoService.setCurrentConfig({
          insertType: InserimentoType.INVENTARI,
          body: resultConfig,
          items: resultsRows,
          prePop: {
            Items: resultsRows
          },
          isNewInvetoryInSameDay: true
          // import: body.import
        });

        // mi salvo la configurazione dei filtri nei cookie.
        if (this.currentFilterCfg) {
          //console.log('mi salvo la configurazione dei filtri nei cookie.');
          localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));
        }

        this.loaderService.hide();
        this.router.navigate(['/magazzino/inserimento-comune'], {
          queryParams: {
            inserimentoType: InserimentoType.INVENTARI,
            referral: '/magazzino/inventari',
            isEditFromNew: true,
            itemId: resultsRows[0].Id,
          }
        });
      },
      (err: any) => {
        this.loaderService.hide();
        this.router.navigate(['/magazzino/inserimento-comune'], {
          queryParams: {
            inserimentoType: InserimentoType.INVENTARI,
            referral: '/magazzino/inventari'
          }
        });
      }
    )
  }

  editDayItem(item: any) {

    var body = {
      Date: item.Date,

      Company: item.Company,
      DepartmentId: item.DepartmentObj.Id,
      CostCenterId: item.CostCenter.Id,

    };

    this.openExistingInventoryOrNew(body)

  }

  editItem(itemId: any) {

    this.loaderService.show();
    lastValueFrom(this.magazzinoService.itemsForUpdate(itemId, 'Inventory'))
      .then((result: any) => {
        //console.log('itemsForUpdate', result);

        const resultConfig = JSON.parse(JSON.stringify(result));
        const resultsRows: any = JSON.parse(JSON.stringify(result.Rows));
        delete resultConfig.Rows;
        this.magazzinoService.setCurrentConfig({
          insertType: InserimentoType.INVENTARI,
          body: resultConfig,
          items: resultsRows,
          prePop: {
            Items: resultsRows
          }
        });

        // mi salvo la configurazione dei filtri nei cookie.
        if (this.currentFilterCfg) {
          //console.log('mi salvo la configurazione dei filtri nei cookie.');
          localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));
        }

        this.loaderService.hide();
        this.router.navigate(['/magazzino/inserimento-comune'], {
          queryParams: {
            inserimentoType: InserimentoType.INVENTARI,
            referral: '/magazzino/inventari',
            itemId: itemId,
          }
        });

      })
      .catch((err: any) => {
        this.loaderService.hide();
      });
  }

  deleteItem(itemId: any) {

    const _title: string = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.TITLE');
    const _description: string = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.DESC_MULTI');
    const _waitDesciption: string = '';
    const _yesButton = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.BUTTON_SINGLE');
    const _yes2Button = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.BUTTON_MULTI');
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yes2Button);
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm === 2) {

        this.magazzinoService.deleteMultiInventory(itemId).subscribe(
          (result: any) => {
            let message: string = '';
            if (result.Msg === 'Ok') {
              message = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.SUCCESS');
              this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
              this.table.draw()
            } else {
              message = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.ERROR');
              this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
            }
          }
        )

      } else if (confirm) {

        this.magazzinoService.delete(InserimentoType.INVENTARI, [itemId]).subscribe(
          (result: any) => {
            let message: string = '';
            if (result.Msg === 'Ok') {
              message = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.SUCCESS');
              this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
              this.table.draw()
            } else {
              message = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.ERROR');
              this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
            }
          }
        )

      }
    });

  }

  showFromYearStart() {
    this.showInitialImage = false;
    this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
    this.filtriService.performFilter$.next(this.currentFilterCfg);
    this.ref.detectChanges();
  }

  showToday() {
    const today = new Date();
    const todayStr = today.toLocaleDateString('ja-JP');
    this.currentFilterCfg = { DateFilter: { Start: todayStr, End: todayStr }, ShowDisabled: true, ShowInvisible: true };
    this.filtriService.performFilter$.next(this.currentFilterCfg);
    this.ref.detectChanges();
  }

  /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
       | |
       |_|
    ******************************************************************************/

  exportXLSXLayout() {

    const config: any = {
      title: this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.TITLE'),
      yesButton: this.translate.instant('EXPORT_XLSX.YESBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      companyLabel: this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COMPANY'),
      orderLabel: this.translate.instant('MAGAZZINO.INVENTARI.ORDINAMENTO'),
      layoutsLabel: this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.LAYOUTS'),
      orderModes: this.orderModes,
      companies: this.companies,
      layouts: [
        { Id: '1', Name: this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.LAYOUT1') },
        { Id: '2', Name: this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.LAYOUT2') }
      ]
    };

    const dialogRef = this.layoutUtilsService.exportPdfSheet(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log('result', result);
        this.loaderService.show();
        let lang = this.translationService.getSelectedLanguage();
        lastValueFrom(this.magazzinoService.getInventoryLayout(result.orderMode, result.company, lang, result.categoryIds, result.fbType))
          .then((layout: any) => {
            console.log('getInventoryLayout', layout);

            if (layout.pdf && layout.pdf.Stores && layout.pdf.Stores.length > 0) {

              layout.pdf.Stores.forEach((store: any) => {

                if (!store.Rows || store.Rows.length == 0) return;

                let xlsx: any[] = [];
                let blockedGoods: any[] = [];

                store.Rows.forEach((item: any, index: number) => {

                  let name = item.Alias ? item.Alias : (item.Name ? item.Name : '');

                  name = name.replaceAll('\n', '');
                  if (item.Note && item.Note.length > 0) {
                    name += ' [' + item.Note + ']';
                  }

                  if (result.layout == '1') {
                    xlsx.push({
                      [`Id`]: item.Id ? item.Id : '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CATEGORIA')}`]: item.Category ? item.Category : '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE')}`]: item.Code ? item.Code : '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO')}`]: name,
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE')}`]: '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE')}`]: item.Unit1 && !item.Lock_Inventory_UnitBase ? item.Unit1 : ''
                    });
                  } else {
                    xlsx.push({
                      [`Id`]: item.Id ? item.Id : '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CATEGORIA')}`]: item.Category ? item.Category : '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE')}`]: item.Code ? item.Code : '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO')}`]: name,
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE')}`]: '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE')}`]: item.Unit1 && !item.Lock_Inventory_UnitBase ? item.Unit1 : '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO')}`]: '',
                      [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_MAGAZZINO')}`]: item.Unit2 && !item.Lock_Inventory_Unit ? item.Unit2 : ''
                    });
                  }

                  // Raccolgo tutte le info di tutte le merci con unità di misura bloccate
                  if (item.Lock_Inventory_Unit || item.Lock_Inventory_UnitBase) {
                    const blockedGood: any = { ...item };
                    blockedGood.index = index;
                    blockedGoods.push(blockedGood);
                  }

                });

                let columnsList: any = [];

                let printOptions: any = {
                  props: {
                    fit: { width: 1, height: 0 },
                    paper: "A4",
                    orientation: 'landscape'
                  },
                  margins: { left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3 }
                };
                if (result.layout == '1') {
                  columnsList = [
                    { label: 'Id', enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                    { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CATEGORIA'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                    { width: 12, label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                    { width: 85, label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO'), enabled: true, format: '', style: { alignment: { horizontal: "left", wrapText: true }, name: 'Calibri', sz: 10 } },
                    { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 10, } },
                    { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } }
                  ]
                } else {
                  columnsList = [
                    { label: 'Id', enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                    { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CATEGORIA'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                    { width: 12, label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                    { width: 75, label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO'), enabled: true, format: '', style: { alignment: { horizontal: "left", wrapText: true }, name: 'Calibri', sz: 10 } },
                    { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 10 } },
                    { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                    { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 10 } },
                    { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_MAGAZZINO'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } }
                  ]
                }

                let filename: string = store.Store.replace(' ', '_');

                filename += '_' + moment().format('YYYYMMDD');

                const alternateRows: boolean = false;
                this.excelService.exportInventoryLayout(xlsx, filename, [], columnsList, 2, [], alternateRows, store.Store, this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.NOTE'), printOptions, blockedGoods);
              });

            }

            this.loaderService.hide();

          })
          .catch((err: any) => {
            this.loaderService.hide()
          })

      };
    });
  }

  async exportAsXLSX() {
    this.table.button(1).trigger();
    this.loaderService.show();
    setTimeout(() => {
      this.ref.detectChanges();
      this.loaderService.hide();
    }, 100);
  }

  /******************************************************************************
    ______                       _     _____  _____  ______
   |  ____|                     | |   |  __ \|  __ \|  ____|
   | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
   |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
   | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
   |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
         | |
         |_|
  ******************************************************************************/

  exportPDFSheet() {

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.SHEET_TITLE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      companyLabel: this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COMPANY'),
      orderLabel: this.translate.instant('MAGAZZINO.INVENTARI.ORDINAMENTO'),
      orderModes: this.orderModes,
      companies: this.companies
    };

    const dialogRef = this.layoutUtilsService.exportPdfSheet(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {

        this.loaderService.show();
        let lang = this.translationService.getSelectedLanguage();
        this.magazzinoService.getInventoryPDF(result.orderMode, result.company, lang, result.categoryIds).subscribe(
          (resultOp: any) => {
            this.loaderService.hide();
            window.open(`${resultOp.endpointURL}${resultOp.link}`, '_blank');
          }
        );

      };
    });
  }

  PDFPreferences: any;
  exportAsPDF() {
    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
      explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
    };

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result['header'] = {
          export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
          //period: ''
        }
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        }
        result['language'] = this.translationService.getSelectedLanguage();
        result['table'].headerRows = 1;

        this.PDFPreferences = result;

        this.table.button(0).trigger();
      };
    });

  }

}
