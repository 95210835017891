// Angular
import { Injectable, Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NestedTreeControl } from "@angular/cdk/tree";

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { AnagraficheService } from '@app/core/services/anagrafiche.service';

// Translate Module
import { TranslateService } from '@ngx-translate/core';

import * as util from '@app/core/services/utilityfunctions';
import { TranslationService } from '@app/core/_base/layout';

declare var $: any;
import moment from 'moment';
import { combineLatest, lastValueFrom } from 'rxjs';
import { ScadenziarioService } from '@app/views/pages/amministrazione/scadenziario/scadenziario.service';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';
import { CeCategoriesComponent, PLNode } from '../ce-categories/ce-categories.component';

export enum InfoType {
  name = 'name',
  id = 'id',
  initiallyChecked = 'initiallyChecked',
  percentage = 'percentage'
}

/**
 * Node for to-do name
 */
//export class PLNode {
//  children!: PLNode[];
//  name!: string;
//  id?: string;
//  initiallyChecked: boolean = false;
//  percentage!: number;
//}

@Component({
  selector: 'kt-fornitori-edit',
  templateUrl: './fornitori-edit.component.html',
  styleUrls: ['./fornitori-edit.component.scss'],
})
export class FornitoriEditComponent implements OnInit {

  @ViewChild(CeCategoriesComponent) ceCategoriesComponent!: CeCategoriesComponent;

  // Public properties
  content: any;
  generalForm!: FormGroup;
  settingsForm!: FormGroup;
  distributionForm!: FormGroup;
  scadenziarioForm!: FormGroup;
  rulesForm!: FormGroup;
  hasFormErrors: boolean = false;
  viewLoading: boolean = false;
  editMode: boolean = false;
  companies: any;
  selectedCompany: any;

  //treeControl = new NestedTreeControl<PLNode>(node => node.children);
  //dataSource = new MatTreeNestedDataSource<PLNode>();

  currentTabIndex: number = 0;

  utility: any;
  locale!: string;
  dates: any = [];
  datesList: any;

  table: any;

  distributionDate: any;
  distributionAmount: any = 100;

  agents: any = {};

  // Scadenziario
  banksList: any;
  bankOperationsList: any;
  paymentModalityList: any;

  myTreeData: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private anagraficheService: AnagraficheService,
    private translate: TranslateService,
    private translationService: TranslationService,
    private scadenziarioService: ScadenziarioService,
    public loaderService: LoaderService,
    private ref: ChangeDetectorRef
  ) {
    this.utility = util;

    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });
  }

  async ngOnInit() {

    this.content = this.data.item;
    // Se non è impostato nulla allora lo metto come fornitore
    if (!this.content.SubjectType) {
      this.content.SubjectType = 1;
    }

    if (!this.content.LinkedProfittAndLossIds || this.content.LinkedProfittAndLossIds.length == 0) {
      this.content['LinkedProfittAndLossIds'] = [];
    }

    this.createForm();

    this.editMode = this.content.Id && this.content.Id > 0;

    this.viewLoading = true;

    //let plStructure: any = [];

    const values = await lastValueFrom(
      combineLatest([this.scadenziarioService.getBanks(),
      this.scadenziarioService.getBankOperations(),
      this.scadenziarioService.getPaymentModality(),
      this.anagraficheService.getCompanies(),
      this.anagraficheService.getPLStructure()])
    )
    if (values && values.length !== 5) return;
    this.banksList = values[0];
    this.bankOperationsList = values[1];
    this.paymentModalityList = values[2];
    this.companies = values[3];

    if (this.content.LinkedProfittAndLossIds && this.content.LinkedProfittAndLossIds.length > 0) {

      const setSelected = (nodes: any[], linkedIds: any[]) => {
        nodes.forEach(node => {
          const initiallyCheckedIndex = linkedIds.findIndex((item: any) => item.Id === node.Id);
          node.selected = initiallyCheckedIndex >= 0;
          node.percentage = initiallyCheckedIndex >= 0 ? linkedIds[initiallyCheckedIndex].Percentage : 0;
          if (node.Children && node.Children.length > 0) {
            setSelected(node.Children, linkedIds);
          }
        });
      };

      setSelected(values[4], this.content.LinkedProfittAndLossIds);

    }

    this.myTreeData = values[4];

    this.viewLoading = false;

    $('body').on('keyup', (e: any) => {
      if (e.code == 'F2') {
        e.preventDefault();
        e.stopPropagation();
        this.onSubmit();
      }
    });
  }

  isSupplier() {
    return (this.content.SubjectType & 1) !== 0;
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    $('body').off('keyup');
  }

  /**
   * Close alert
   *
   * @param $event: Event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  change(event: any) {
    this.selectedCompany = event.value;
  }

  createForm() {

    let body: any = {
      Name: [this.content.Name, Validators.required],
      Code: [this.content.Code],
      Email: [this.content.Email, null],
      Phone: [this.content.Phone, null],
      Company: [this.content.Company, null]
    }

    if (this.isSupplier()) {
      body['MinOrder'] = [this.content.MinOrder, null];
      body['EDICode'] = [this.content.EDICode, null];
      body['Conditions'] = [this.content.Conditions, null];
    }

    this.generalForm = this.fb.group(body);

    body = {
      IsDisabled: [this.content.IsDisabled, null],
      EnableScadenziario: [this.content.EnableScadenziario, null]
    };

    if (this.isSupplier()) {
      body['CheckPrice'] = [this.content.CheckPrice, null];
      body['IsEDI'] = [this.content.IsEDI, null];
      body['IsDefault'] = [this.content.IsDefault, null];
      body['IsDisabled'] = [this.content.IsDisabled, null];
      body['PurchaseImportExcluded'] = [this.content.PurchaseImportExcluded, null];
      body['CEImportExcluded'] = [this.content.CEImportExcluded, null];
      body['IsPLDestribution'] = [this.content.IsPLDestribution, null];
      body['DistributeOnlyCreditNote'] = [this.content.DistributeOnlyCreditNote, null];
      body['IsEscludedFromPurchase'] = [this.content.IsEscludedFromPurchase, null];
      body['IsInternal'] = [this.content.IsInternal, null];
      body['UseXmlInvoiceForSchedule'] = [this.content.UseXmlInvoiceForSchedule, null];
    }

    this.settingsForm = this.fb.group(body);

    this.distributionForm = this.fb.group({
      DistributionMode: [this.content.DistributionMode ? this.content.DistributionMode : 0, null],
      DistributionFrequency: [this.content.DistributionFrequency ? this.content.DistributionFrequency : 2, null],
      DistributionRepetition: [this.content.DistributionRepetition, null],
      DistributionStart: [this.content.DistributionStart ? this.content.DistributionStart : 0, null],
      DistributionIncludeCurrentMonth: [this.content.DistributionIncludeCurrentMonth],
    });

    this.scadenziarioForm = this.fb.group({
      ScadenziarioMode: [this.content.PaymentType ? this.content.PaymentType : 1, null],
      ScadenziarioModalitaPagamento: [this.content.PaymentModalityId ? this.content.PaymentModalityId : null, null],
      ScadenziarioBanca: [this.content.BankId ? this.content.BankId : null, null],
      ScadenziarioTipologiaPagamento: [this.content.OperationId ? this.content.OperationId : null, null],
    });

    if (this.isSupplier()) {

      this.rulesForm = this.fb.group({
        GoodsRule: [this.content.GoodsRule, null],
      });

      // Completo la tabella se ci sono degli agents impostati
      if (this.content.Agents && this.content.Agents.length > 0) {
        this.content.Agents.forEach((agent: any) => {
          this.agents[agent.Store] = agent.Email;
        })
      }

    }
  }

  /**
  * Returns page title
  */
  getTitle(): string {
    if (this.editMode) {
      return this.translate.instant('COMMONS.EDIT') + ` '${this.content.Name}'`;
    } else {
      switch (this.content.SubjectType) {
        case 1:
          return this.translate.instant('ANAGRAFICHE.FORNITORI.NEW_ENTITY');
        case 2:
          return this.translate.instant('ANAGRAFICHE.CLIENTI.NEW_ENTITY');
        case 4:
          return this.translate.instant('ANAGRAFICHE.DIPENDENTI.NEW_ENTITY');
        default:
          return '';

      }
    }
  }

  /**
   * Check control is invalid
   * @param controlName: string
   */
  isControlInvalid(controlName: string, form: FormGroup = this.generalForm): boolean {
    const control = form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  /** ACTIONS */

  /**
   * Returns prepared customer
   */
  prepareItem(): any {
    const generalControls = this.generalForm.controls;

    this.content.Name = generalControls['Name'].value;
    this.content.Code = generalControls['Code'].value;
    this.content.Email = generalControls['Email'].value;
    this.content.Phone = generalControls['Phone'].value;
    this.content.Company = generalControls['Company'].value;

    if (this.isSupplier()) {
      this.content.MinOrder = generalControls['MinOrder'].value;
      this.content.EDICode = generalControls['EDICode'].value;
      this.content.Conditions = generalControls['Conditions'].value;
    }

    const settingsControls = this.settingsForm.controls;
    this.content.IsDisabled = settingsControls['IsDisabled'].value;
    this.content.EnableScadenziario = settingsControls['EnableScadenziario'].value;

    if (this.isSupplier()) {
      this.content.IsInternal = settingsControls['IsInternal'].value;
      this.content.CheckPrice = settingsControls['CheckPrice'].value;
      this.content.IsEDI = settingsControls['IsEDI'].value;
      this.content.IsDefault = settingsControls['IsDefault'].value;
      this.content.PurchaseImportExcluded = settingsControls['PurchaseImportExcluded'].value;
      this.content.CEImportExcluded = settingsControls['CEImportExcluded'].value;
      this.content.IsPLDestribution = settingsControls['IsPLDestribution'].value;
      this.content.DistributeOnlyCreditNote = settingsControls['DistributeOnlyCreditNote'].value;
      this.content.EnableScadenziario = settingsControls['EnableScadenziario'].value;
      this.content.IsEscludedFromPurchase = settingsControls['IsEscludedFromPurchase'].value;
      this.content.UseXmlInvoiceForSchedule = settingsControls['UseXmlInvoiceForSchedule'].value;
    }

    const distributionControls = this.distributionForm.controls;
    this.content.DistributionMode = distributionControls['DistributionMode'].value;
    this.content.DistributionStart = distributionControls['DistributionStart'].value;
    this.content.DistributionFrequency = distributionControls['DistributionFrequency'].value;
    this.content.DistributionRepetition = distributionControls['DistributionRepetition'].value;
    this.content.DistributionIncludeCurrentMonth = distributionControls['DistributionIncludeCurrentMonth'].value;

    if (this.isSupplier()) {

      //Regole merci
      const rulesControl = this.rulesForm.controls;
      this.content.GoodsRule = rulesControl['GoodsRule'].value;

      // Email ordini
      if (this.agents && Object.keys(this.agents).length > 0) {
        this.content.Agents = [];
        Object.keys(this.agents).forEach((store: string) => {
          this.content.Agents.push({
            SupplierId: this.content.Id,
            Email: this.agents[store],
            Store: store
          })
        })
      } else {
        this.content.Agents = null
      }
    }

    const scadenziarioControls = this.scadenziarioForm.controls;
    this.content.PaymentType = scadenziarioControls['ScadenziarioMode'].value;
    this.content.PaymentModalityId = scadenziarioControls['ScadenziarioModalitaPagamento'].value;
    this.content.BankId = scadenziarioControls['ScadenziarioBanca'].value;
    this.content.OperationId = scadenziarioControls['ScadenziarioTipologiaPagamento'].value;

    return this.content;
  }

  /**
   * On Submit
   */
  onSubmit() {

    if (this.checkDisabled()) return;

    this.hasFormErrors = false;
    const generalControls = this.generalForm.controls;
    /** check form */
    if (this.generalForm.invalid) {
      Object.keys(generalControls).forEach(controlName => {
        generalControls[controlName].markAsTouched();
        console.log(controlName);
      });
      this.hasFormErrors = true;
      return;
    }

    const editedItem = this.prepareItem();
    this.viewLoading = true;
    if (this.editMode) {
      this.updateEntityItem(editedItem);
    } else {
      this.createEntityItem(editedItem);
    }
  }

  /**
   * Update customer
   *
   * @param _customer: CustomerModel
   */
  updateEntityItem(item: any) {
    lastValueFrom(this.anagraficheService.putEntity('Supplier', item))
      .then(
        (ret: any) => {
          console.log('updateEntityItem', ret);
          this.viewLoading = false;
          this.dialogRef.close({ item, isEdit: true });
        })
      .catch((error: any) => {
        this.viewLoading = false;
        this.dialogRef.close(null);
      }
      )
  }

  /**
   * Update customer
   *
   * @param _customer: CustomerModel
   */
  createEntityItem(item: any) {
    this.anagraficheService.postEntity('Supplier', item).toPromise()
      .then(
        (ret: any) => {
          console.log('createEntityItem', ret);
          this.viewLoading = false;
          this.dialogRef.close({ item: ret, isEdit: false });
        })
      .catch((error: any) => {
        this.viewLoading = false;
        this.dialogRef.close(null);
      }
      )
  }

  /************************************************************************************************************************
   
  $$$$$$$$\ $$$$$$$\  $$$$$$$$\ $$$$$$$$\       $$\    $$\ $$$$$$\ $$$$$$$$\ $$\      $$\ 
  \__$$  __|$$  __$$\ $$  _____|$$  _____|      $$ |   $$ |\_$$  _|$$  _____|$$ | $\  $$ |
     $$ |   $$ |  $$ |$$ |      $$ |            $$ |   $$ |  $$ |  $$ |      $$ |$$$\ $$ |
     $$ |   $$$$$$$  |$$$$$\    $$$$$\          \$$\  $$  |  $$ |  $$$$$\    $$ $$ $$\$$ |
     $$ |   $$  __$$< $$  __|   $$  __|          \$$\$$  /   $$ |  $$  __|   $$$$  _$$$$ |
     $$ |   $$ |  $$ |$$ |      $$ |              \$$$  /    $$ |  $$ |      $$$  / \$$$ |
     $$ |   $$ |  $$ |$$$$$$$$\ $$$$$$$$\          \$  /   $$$$$$\ $$$$$$$$\ $$  /   \$$ |
     \__|   \__|  \__|\________|\________|          \_/    \______|\________|\__/     \__|
  
  ************************************************************************************************************************/


  onCategoriesSelectionChanged(selectedNodes: PLNode[]): void {
    this.content.LinkedProfittAndLossIds = selectedNodes
      .filter(node => !node.children || node.children.length === 0)
      .map(node => ({
        Id: node.id,
        Name: node.name,
        Percentage: node.percentage
      }));
    this.ref.detectChanges();
  }

  checkDisabled() {
    return !this.generalForm.controls['Name'].value;
  }

  scrollToNode(node: any) {
    this.ceCategoriesComponent.scrollToNodeById(node.Id);
  }

  /*
   /$$$$$$$  /$$$$$$  /$$$$$$  /$$$$$$$$ /$$$$$$$  /$$$$$$ /$$$$$$$  /$$   /$$ /$$$$$$$$ /$$$$$$  /$$$$$$  /$$   /$$
  | $$__  $$|_  $$_/ /$$__  $$|__  $$__/| $$__  $$|_  $$_/| $$__  $$| $$  | $$|__  $$__/|_  $$_/ /$$__  $$| $$$ | $$
  | $$  \ $$  | $$  | $$  \__/   | $$   | $$  \ $$  | $$  | $$  \ $$| $$  | $$   | $$     | $$  | $$  \ $$| $$$$| $$
  | $$  | $$  | $$  |  $$$$$$    | $$   | $$$$$$$/  | $$  | $$$$$$$ | $$  | $$   | $$     | $$  | $$  | $$| $$ $$ $$
  | $$  | $$  | $$   \____  $$   | $$   | $$__  $$  | $$  | $$__  $$| $$  | $$   | $$     | $$  | $$  | $$| $$  $$$$
  | $$  | $$  | $$   /$$  \ $$   | $$   | $$  \ $$  | $$  | $$  \ $$| $$  | $$   | $$     | $$  | $$  | $$| $$\  $$$
  | $$$$$$$/ /$$$$$$|  $$$$$$/   | $$   | $$  | $$ /$$$$$$| $$$$$$$/|  $$$$$$/   | $$    /$$$$$$|  $$$$$$/| $$ \  $$
  |_______/ |______/ \______/    |__/   |__/  |__/|______/|_______/  \______/    |__/   |______/ \______/ |__/  \__/
   */

  onDateSelect($event: any) {
    this.distributionDate = new Date($event.year, $event.month - 1, $event.day);
    this.performDistribution();
  }

  async performDistribution() {

    const DistributionMode = this.distributionForm.controls['DistributionMode'].value;
    const DistributionFrequency = this.distributionForm.controls['DistributionFrequency'].value;
    const DistributionStart = this.distributionForm.controls['DistributionStart'].value;
    const DistributionRepetition = this.distributionForm.controls['DistributionRepetition'].value;
    const DistributionIncludeCurrentMonth = this.distributionForm.controls['DistributionIncludeCurrentMonth'].value;
    let datesList = [];
    let startDate
    let count = 0;

    datesList = await this.anagraficheService.getDistributionDates(this.distributionDate, DistributionStart, DistributionMode, DistributionFrequency, DistributionIncludeCurrentMonth, DistributionRepetition)
      .toPromise();
    // Periodo
    // if (DistributionMode === 0) {
    //     startDate = moment(this.distributionDate);
    //     while (count < DistributionRepetition) {
    //         let date = '';
    //         switch (DistributionFrequency) {
    //             case 0: // start
    //                 // se la data di partenza è minore della data della fattura
    //                 // e l'inizio distribuzione è impostato come "after invoice"
    //                 // allora devo spostarmi avanti come partenza
    //                 if (startDate.startOf('month').isSameOrBefore(this.distributionDate) && DistributionStart === 1) {
    //                     date = startDate.add(1, 'months').startOf('month').format('YYYY/MM/DD');
    //                 } else {
    //                     date = startDate.startOf('month').format('YYYY/MM/DD');
    //                 }
    //                 break;
    //             case 1: //'middle'

    //                 // se la data di partenza è minore della data della fattura
    //                 // e l'inizio distribuzione è impostato come "after invoice"
    //                 // allora devo spostarmi avanti come partenza
    //                 if (startDate.date(15).isSameOrBefore(this.distributionDate) && DistributionStart === 1) {
    //                     date = startDate.add(1, 'months').date(15).format('YYYY/MM/DD');

    //                     // se la data di partenza è maggiore della data della fattura
    //                     // e l'inizio distribuzione è impostato come "before invoice"
    //                     // allora devo spostarmi indietro come partenza
    //                 } else if (startDate.date(15).isSameOrAfter(this.distributionDate) && DistributionStart === 0) {

    //                     date = startDate.subtract(1, 'months').date(15).format('YYYY/MM/DD');

    //                 } else {
    //                     date = startDate.date(15).format('YYYY/MM/DD');
    //                 }

    //                 break;

    //             // end
    //             default:
    //                 // se la data di partenza è maggiore della data della fattura
    //                 // e l'inizio distribuzione è impostato come "before invoice"
    //                 // allora devo spostarmi indietro come partenza
    //                 if (startDate.endOf('month').isSameOrAfter(this.distributionDate) && DistributionStart === 0) {
    //                     date = startDate.subtract(1, 'months').endOf('month').format('YYYY/MM/DD');
    //                 } else {
    //                     date = startDate.endOf('month').format('YYYY/MM/DD');
    //                 }
    //         }

    //         datesList.push(date);

    //         if (DistributionStart == 1) {
    //             startDate.add(1, 'month');
    //         } else {
    //             startDate.subtract(1, 'month');
    //         }

    //         count++;
    //     }
    //     // Annuale
    // } else if (DistributionMode === 1) {

    //     startDate = moment(DistributionRepetition, 'YYYY');

    //     if (DistributionRepetition < 2000 || !startDate.isValid()) return;

    //     for (let month = 1; month <= 12; month++) {
    //         let date;

    //         switch (DistributionFrequency) {
    //             case 0: // start
    //                 date = startDate.startOf('month').format('YYYY/MM/DD');
    //                 break;
    //             case 1: //'middle'
    //                 date = startDate.date(15).format('YYYY/MM/DD');
    //                 break;
    //             default: // end
    //                 date = startDate.endOf('month').format('YYYY/MM/DD');
    //         }

    //         startDate.add(1, 'month');

    //         datesList.push(date);
    //     }

    // }

    const amount = this.distributionAmount / datesList.length;
    const perc = (100 / this.distributionAmount) * amount;

    this.dates = [];
    datesList.forEach((date: any) => {
      this.dates.push({
        date: date,
        amount: amount,
        perc: perc
      })
    });

    this.initDataTable();

  }

  initDataTable() {

    if ($.fn.dataTable.isDataTable('#distributionTable')) {
      $('#distributionTable').DataTable().destroy();
    }

    setTimeout(() => {
      this.table = $('#distributionTable').DataTable({
        destroy: true,
        paging: false,
        searching: false,
        ordering: false,
        scrollY: '250px',
        scrollCollapse: true,
        autoWidth: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
      });
      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });
    });
  }

  /*
  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$$  /$$$$$$$$ /$$   /$$ /$$$$$$$$ /$$$$$$  /$$$$$$  /$$$$$$$  /$$$$$$  /$$$$$$ 
  /$$__  $$ /$$__  $$ /$$__  $$| $$__  $$| $$_____/| $$$ | $$|_____ $$ |_  $$_/ /$$__  $$| $$__  $$|_  $$_/ /$$__  $$
  | $$  \__/| $$  \__/| $$  \ $$| $$  \ $$| $$      | $$$$| $$     /$$/   | $$  | $$  \ $$| $$  \ $$  | $$  | $$  \ $$
  |  $$$$$$ | $$      | $$$$$$$$| $$  | $$| $$$$$   | $$ $$ $$    /$$/    | $$  | $$$$$$$$| $$$$$$$/  | $$  | $$  | $$
  \____  $$| $$      | $$__  $$| $$  | $$| $$__/   | $$  $$$$   /$$/     | $$  | $$__  $$| $$__  $$  | $$  | $$  | $$
  /$$  \ $$| $$    $$| $$  | $$| $$  | $$| $$      | $$\  $$$  /$$/      | $$  | $$  | $$| $$  \ $$  | $$  | $$  | $$
  |  $$$$$$/|  $$$$$$/| $$  | $$| $$$$$$$/| $$$$$$$$| $$ \  $$ /$$$$$$$$ /$$$$$$| $$  | $$| $$  | $$ /$$$$$$|  $$$$$$/
  \______/  \______/ |__/  |__/|_______/ |________/|__/  \__/|________/|______/|__/  |__/|__/  |__/|______/ \______/ 
  */

  async performScadenziario() { }

  selectedCustomDatesScadenziario(event: any) {
    console.log('selectedCustomDatesScadenziario', event);
  }

  /********************************** */

  tabChanged(selectedTab: any) {
    if (selectedTab.index == 3) {
      if (!this.distributionDate) {
        this.distributionDate = moment();
        this.performDistribution();
      }
      this.distributionAmount = 100;
    } else if (selectedTab.index == 4) {
      if ($.fn.dataTable.isDataTable('#tableEmails')) {
        $('#tableEmails').DataTable().destroy();
      }

      setTimeout(() => {
        $('#tableEmails').dataTable({
          scrollY: '300px',
          destroy: true,
          paging: false,
          searching: false,
          ordering: false,
          scrollCollapse: true,
          autoWidth: true,
        });
      });
    }
  }

  modeKeyDown(event: any) {
    if (event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.performDistribution();
    }
  }
}
