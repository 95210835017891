import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent } from 'rxjs';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'lodash';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
  selector: 'kt-inserimento-ordini-popup',
  templateUrl: './inserimento-ordini-popup.component.html',
  styleUrls: ['./inserimento-ordini-popup.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class InserimentoOrdiniInterniPopupComponent implements OnInit {

  

  company: any;
  costCenter: any;
  department: any;
  order: any;
  supplier: any;
  dataDocument!: Date;
  numberDocument!: string;
  Description!: string;
  orderNumber!: string;

  listaCompanies: any;
  listaCostCenters: any;
  listaDepartment: any;
  listaOrders: any;
  listaSuppliers: any;
  listaAllSuppliers: any;

  viewLoading: boolean = false;
  hasFormErrors: boolean = false;
  errorMessage!: string;

  minDate: Date;
  maxDate: Date;
  date: Date = new Date((new Date().getTime() - 0));
  // date!: Date;

  prePop: any;
  

  isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private staticCollectionsService: StaticCollectionsService,
    private _adapter: DateAdapter<any>,
    private translationService: TranslationService,
    private magazzinoService: MagazzinoService,
    private ref: ChangeDetectorRef,
    public loaderService: LoaderService
  ) {
    this.minDate = new Date();
    this.minDate.setFullYear((new Date()).getFullYear() - 1);
    this.maxDate = new Date();
    this.maxDate.setFullYear((new Date()).getFullYear() + 1);
  }

  getTitle() {
    return this.isEdit ? this.translate.instant('MAGAZZINO.ORDINI_INTERNI.INSERIMENTO_ORDINI.TITLE_UPDATE') : this.translate.instant('MAGAZZINO.ORDINI_INTERNI.INSERIMENTO_ORDINI.TITLE');
  }

  ngOnInit() {

    if (_.isEmpty(this.data.endpoint) || !this.data) {
      ;
    } else {
      this.isEdit = true;
    }
 
    console.log('this.data', this.data);

    this._adapter.setLocale('it');//this._adapter.setLocale(this.translationService.getSelectedLanguage());

    this.listaCompanies = this.staticCollectionsService.aziende$;
    this.listaCostCenters = this.staticCollectionsService.costCenters$;
    this.listaDepartment = this.staticCollectionsService.repartiMagazzino$;
    this.listaAllSuppliers = this.staticCollectionsService.fornitoriInterni$;

    //this.listaSuppliers = this.isEdit ? this.listaAllSuppliers : this.listaAllSuppliers.filter((x: any) => !x.IsDisabled);
    //https://mantis.fbmanager.com/view.php?id=1290
    this.listaSuppliers = this.isEdit ? this.listaAllSuppliers : this.listaAllSuppliers.filter((x: any) => !x.IsDisabled && !x.PurchaseImportExcluded);
    if ( this.listaSuppliers.length == 1) {
      this.supplier = this.listaSuppliers[0].Id;
    }

    if (!this.isEdit) {
      if (this.listaCompanies.length == 1) {
        this.company = this.listaCompanies[0].Name;
        this.onChangeCompany();
      }
      if (this.listaCostCenters.length > 0) {
        const defCostCenter = this.listaCostCenters.filter(costCenter => costCenter.Name === '---');
        this.costCenter = defCostCenter ? defCostCenter[0].Id : undefined;
      }
      if (this.listaDepartment.length > 0) {
        const defDepartment = this.listaDepartment.filter(department => department.Name === '---');
        this.department = defDepartment ? defDepartment[0].Id : undefined;
      }
    } else {
    
      console.log('this.data.endpoint', this.data.endpoint);
      this.Description = this.data.endpoint.Description;
      if (this.data.endpoint.Company && this.listaCompanies) {
        const existsCompany = this.listaCompanies.find( (company: any) => company.Name.toString() ===  this.data.endpoint.Company.toString());
        if (existsCompany) {
          this.company = existsCompany.Name;
        }
      }
      if (this.data.endpoint.CostCenter && this.data.endpoint.CostCenter.Id && this.listaCostCenters) {
        const existsCostCenter = this.listaCostCenters.find( (costCenter: any) => costCenter.Id.toString() ===  this.data.endpoint.CostCenter.Id.toString());
        if (existsCostCenter) {
          this.costCenter = existsCostCenter.Id;
        }
      } else if (  this.listaCostCenters ) {
        this.costCenter = this.listaCostCenters[0].Id;
      }
      if (this.data.endpoint.DepartmentId && this.listaDepartment) {
        const existsDepartment = this.listaDepartment.find( (department: any) => department.Id.toString() ===  this.data.endpoint.DepartmentId.toString());
        if (existsDepartment) {
          this.department = existsDepartment.Id;
        }
      }
      if (this.data.endpoint.SupplierId && this.listaSuppliers) {
        const existsSuppliers = this.listaSuppliers.find( (supplier: any) => supplier.Id.toString() ===  this.data.endpoint.SupplierId.toString());
        if (existsSuppliers) {
          this.supplier = existsSuppliers.Id;
        }
      }
      if (this.data.endpoint.Date) {
        this.date = new Date((new Date(this.data.endpoint.Date).getTime() - 0));
      }
      this.numberDocument = this.data.endpoint.DocumentNumber;
      this.orderNumber = this.data.endpoint.OrderNumber;

    }

  }

  onChangeCompany() {
    //Ricarica i fornitori
    this.listaSuppliers =  this.listaAllSuppliers.filter((x: any) => !x.IsDisabled);

    if ( this.listaSuppliers.length == 1) {
      this.supplier = this.listaSuppliers[0].Id;
    }
   
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  onSubmit() {

    if (!this.company) {
      this.errorMessage = this.translate.instant('FILTRI.COMPANIES') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
      this.hasFormErrors = true;
      return;
    }
    if (!this.costCenter) {
      this.errorMessage = this.translate.instant('FILTRI.COSTS_CENTERS') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
      this.hasFormErrors = true;
      return;
    }
    if (!this.supplier) {
      this.errorMessage = this.translate.instant('FILTRI.FORNITORE') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
      this.hasFormErrors = true;
      return;
    }

    if (!this.date) {
      this.errorMessage = this.translate.instant('MAGAZZINO.ORDINI_INTERNI.INSERIMENTO_ORDINI_POPUP.DATA_DOCUMENT') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
      this.hasFormErrors = true;
      return;
    }

    this.dialogRef.close({
      success: true,
      body: {
        Company: this.company,
        CostCenterId: this.costCenter,
        DepartmentId: this.department,
        OrderId: this.order,
        SupplierId: this.supplier,
        Date: moment(this.date).format('YYYY-MM-DDTHH:mm:ss'),
        DocNumber: this.numberDocument,
        hideExcluded: true,
        hideInvisible: true,
        Description: this.Description,
        OrderNumber: this.orderNumber,
        OnlyVisibleForCompany: true
      },
      prePop: this.prePop
    });
  }


}
