import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { InserimentoGiacenzePopupComponent } from './inserimento-giacenze-popup/inserimento-giacenze-popup.component';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { InserimentoType } from '@app/views/pages/magazzino/inserimento-comune/inserimento-comune.component';
import { Router } from '@angular/router';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { environment } from '@env/environment';
import * as util from '@app/core/services/utilityfunctions';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-giacenze',
    templateUrl: './giacenze.component.html',
    styleUrls: ['./giacenze.component.scss', '../common-styles.scss']
})
export class GiacenzeComponent implements OnInit, OnDestroy, AfterViewInit {

    lista: any;
    listaFiltered: any;
    companies: any;
    currentCompanyFilter!: string;
    categorie: any;
    fornitori: any;

    
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    currentFilterCfg: any;

    @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;

    pagination: any;
    columnsList: any = [];
    groupByQty: boolean = false;

    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;
    // staticCollectionsSubcription!: Subscription;

    utility: any;
    locale!: string;

    currentApi: string = 'GetStockGrouped';

    constructor(
        private filtriService: FiltriService,
        private magazzinoService: MagazzinoService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public dialog: MatDialog,
        public staticCollectionsService: StaticCollectionsService,
        private router: Router,
        public roleService: RoleService,
        public loaderService: LoaderService
    ) {
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.columnsList = [
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COMPANY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COSTCENTERS'), enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CATEGORY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CODE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NAME'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.UNIT'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.SECONDARY_UNIT'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.PRICE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.PRICE_QTY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            //{ label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NOTE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
        ];

    }

    /**
     * After view init
     */
    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.loaderService.show();
                    this.clearFilters(false);
                    $('#table').DataTable().clear().destroy();
                    this.lista = [];
                    this.listaFiltered = [];
                    this.loaderService.hide();
                }
            }
        );

        await this.staticCollectionsService.fillStaticCollection(false);
        this.loaderService.hide();
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        // if (this.staticCollectionsSubcription) this.staticCollectionsSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};

        // Se l'url non include inserimento-comune allora svuoto la localstorage
        if (!this.router.url.includes('/magazzino/inserimento-comune')) {
            localStorage.removeItem('currentFilterCfg');
        }
    }

    goToStatistiche() {
        this.router.navigate(['/magazzino/statistiche'], { queryParams: {entity: 'Stock', localized: 'MAGAZZINO.STATISTICHE.WAREHOUSE_SELECTOR.GIACENZE'} });
    }

    searchByCode(code: string) {
        this.ProductOrCode.nativeElement.value = code;
        this.filter(code, 'ProductOrCode')
    }

    getTitle() {
        return this.translate.instant('MAGAZZINO.GIACENZE.TITLE');
    }

    ngOnInit() {
        fromEvent(this.ProductOrCode.nativeElement, 'keyup')
            .pipe(
                // tslint:disable-next-line:max-line-length
                debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                distinctUntilChanged(), // This operator will eliminate duplicate values
                tap((ev: any) => {
                    if (ev.keyCode == 13) this.filter(this.ProductOrCode.nativeElement.value, 'ProductOrCode');
                })
            )
            .subscribe();

        this.categorie = [];
        this.fornitori = [];
        document.body.classList.add('kt-aside--minimize');
        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg')||'');
                        localStorage.removeItem('currentFilterCfg');
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                        return;
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                    }
                }

                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
                if (filterConfig.constructor !== Object) return;
                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                this.pagination = {
                    page: 1,
                    pageSize: 15,
                    sort: 'Name',
                    sortMode: 'Asc',
                    TotalRows: 0
                };

                // Inizializzo EVENTUALMENTE le select
                setTimeout(() => {
                    if (this.currentFilterCfg && this.currentFilterCfg.CategoryIds && this.currentFilterCfg.CategoryIds.length > 0) {
                        this.categorie = this.currentFilterCfg.CategoryIds;
                    }
                }, 1000)

                this.refreshTable();
            }
        );
    }

    clearFilters(actionFilter: boolean = true) {
        this.categorie = undefined;
        this.ProductOrCode.nativeElement.value = '';

        this.filtriService.clearFilters$.next(actionFilter);

        $('#table').DataTable().clear().destroy();
        this.lista = [];
        this.listaFiltered = [];
        this.dataReady$.next(false);
    }

    changeView() {
        this.currentApi = this.currentApi == 'GetStockGrouped' ? 'GetStock' : 'GetStockGrouped';
        this.columnsList[1].enabled = this.currentApi == 'GetStock';
        this.refreshTable();
    }

    refreshTable() {
        this.loaderService.show();
        this.dataReady$.next(false);
        this.filtriService.readOnly$.next(true);
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        console.log(this.pagination);
        this.companies = [];
        
        this.magazzinoService.getWarehouse(this.currentApi, this.currentFilterCfg, this.pagination).toPromise()
            .then((ret: any) => {
                console.log('lista', ret);

                // TODO: Elaborare dati ricevuti
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }

                this.lista = ret;
                this.listaFiltered = JSON.parse(JSON.stringify(ret));
                this.initDataTable();
                this.loaderService.hide();
                this.dataReady$.next(true);
                this.filtriService.readOnly$.next(false);
            })
            .catch(() => {
                this.loaderService.hide();
                this.dataReady$.next(true);
                this.filtriService.readOnly$.next(false);
            });
    }

    initDataTable() {

        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 400px)',
                scrollCollapse: true,
                aaSorting: [], // inizialmente nessun ordinamento
                columnDefs: [
                    { targets: 0, width: '120px', visible: this.columnsList[0].enabled },
                    { targets: 1, width: '120px', visible: this.columnsList[1].enabled },
                    { targets: 2, width: '120px', visible: this.columnsList[2].enabled },
                    { targets: 3, width: '120px', visible: this.columnsList[3].enabled },
                    { targets: 4, visible: this.columnsList[4].enabled },
                    { targets: 5, width: '80px', visible: this.columnsList[5].enabled },
                    { targets: 6, width: '80px', visible: this.columnsList[6].enabled },
                    { targets: 7, width: '80px', visible: this.columnsList[7].enabled },
                    { targets: 8, width: '80px', visible: this.columnsList[8].enabled },
                    { targets: 9, width: '80px', visible: this.columnsList[9].enabled },
                    { targets: 10, width: '80px', visible: this.columnsList[10].enabled },
                    //{ targets: 9, width: '60px', visible: this.columnsList[9].enabled }
                ],
                autoWidth: true
            };
            this.table = $('#table').DataTable(dtOptions);
            // Mi collego all'evento di loading della tabella
            this.table.off('processing.dt');
            this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
                if (processing) {
                    this.loaderService.show();
                } else {
                    this.loaderService.hide();
                }
                this.ref.detectChanges();
            });
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
        }, 100);
    }

    changePagination(pagination: PageEvent) {
        this.pagination.page = pagination.pageIndex + 1;
        this.pagination.pageSize = pagination.pageSize;
        this.refreshTable();
    }

    sort(event: any, index: number, type: string) {
        console.log('sort', event, index);
        this.pagination.sort = type;

        if (this.columnsList[index].sort && this.columnsList[index].sort === 'ASC') {
            this.columnsList[index].sort = 'DESC';
            this.pagination.sortMode = 'Desc';
        } else {
            this.columnsList[index].sort = 'ASC';
            this.pagination.sortMode = 'Asc';
        }

        this.refreshTable();
    }

    filter(event: any, type: string) {
        console.log('filter', event, type);
        switch (type) {
            case 'ProductOrCode':
                this.currentFilterCfg['ProductOrCode'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'OrderOrDocument':
                this.currentFilterCfg['OrderOrDocument'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'CategoryIds':
                this.currentFilterCfg['CategoryIds'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'SupplierIds':
                this.currentFilterCfg['SupplierIds'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
        }
    }

    openPopup() {
        const newEndpoint = {};
        this.editPopup(newEndpoint);
    }

    editPopup(endpoint: any) {
        const dialogRef = this.dialog.open(InserimentoGiacenzePopupComponent, {
            data: { endpoint },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success && res.body) {
                this.magazzinoService.setCurrentConfig(res);

                // mi salvo la configurazione dei filtri nei cookie.
                console.log('mi salvo la configurazione dei filtri nei cookie.');
                localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));

                this.router.navigate(['/magazzino/inserimento-comune'], {
                    queryParams: {
                        inserimentoType: InserimentoType.GIACENZE,
                        referral: '/magazzino/giacenze'
                    }
                });
            }
        });
    }

    openColumnsDialog() {
        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: { columnsList: this.columnsList },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success && res.columnsList) {
                this.columnsList = res.columnsList;
                for (let index = 0; index < this.columnsList.length; index++) {
                    var column = this.table.column(index);
                    if (column) column.visible(this.columnsList[index].enabled);
                }
            }
        });
    }

    showFromYearStart() {
        this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    showToday() {
        const today = new Date();
        const todayStr = today.toLocaleDateString('ja-JP');
        this.currentFilterCfg = { DateFilter: { Start: todayStr, End: todayStr }, ShowDisabled: true, ShowInvisible: true };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    /****************************************************************************
      ______                         _
       |  ____|                       | |
       | |__ ___  _ __ _ __ ___   __ _| |_
       |  __/ _ \| '__| '_ ` _ \ / _` | __|
       | | | (_) | |  | | | | | | (_| | |_
       |_|  \___/|_|  |_| |_| |_|\__,_|\__|
  
       ****************************************************************************/
    formatNumber(x: any, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
        return this.utility.formatNumber(x, this.locale, decimal, showZero, currency);
    }

    /******************************************************************************
      ______                       _    __   ___       _______   __
       |  ____|                     | |   \ \ / / |     / ____\ \ / /
       | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
       |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
       | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
       |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
      ******************************************************************************/

    exportAsXLSX() {



        let pagination = JSON.parse(JSON.stringify(this.pagination));
        if (this.pagination.TotalRows) {

            if (pagination.TotalRows < environment.ExcelPaginationMaxSize)
                pagination.pageSize = pagination.TotalRows;
            else {
                pagination.pageSize = environment.ExcelPaginationMaxSize;
                const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
                let _description: string = this.translate.instant('EXPORT_XLSX.MAX5000');
                _description = _description.replace('{0}', '' + environment.ExcelPaginationMaxSize);
                const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

                const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


                const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
                dialogRef.afterClosed().subscribe(expand => {

                });
            }

        }
        else {
            const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
            const _description: string = this.translate.instant('EXPORT_XLSX.NO_ROWS');
            const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

            const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
            dialogRef.afterClosed().subscribe(expand => {
                return;
            });
            return;
        }



        this.loaderService.show();
        let xlsx: any[] = [];
        let merges: any[] = [];
        this.magazzinoService.getWarehouse(this.currentApi, this.currentFilterCfg, pagination).toPromise()
            .then((ret: any) => {
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }


                let printList = JSON.parse(JSON.stringify(ret));
                // Totali
                xlsx.push({
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.COMPANY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.COSTCENTERS')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.CATEGORY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.CODE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.NAME')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.QUANTITY')}`]: printList.Quantity ? this.utility.formatNumberExcel(printList.Quantity) : '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.UNIT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.QUANTITY')} `]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.SECONDARY_UNIT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.PRICE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.PRICE_QTY')}`]: printList.Total ? this.utility.formatNumberExcel(printList.Total) : ''
                });

                printList.Rows.forEach((item: any) => {
                    xlsx.push({
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.COMPANY')}`]: item.Company ? item.Company.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.COSTCENTERS')}`]: item.CostCenter && item.CostCenter.Name ? item.CostCenter.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.CATEGORY')}`]: item.Category && item.Category.Name ? item.Category.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.CODE')}`]: item.Code ? item.Code : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.NAME')}`]: item.Goods && item.Goods.Name ? item.Goods.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.QUANTITY')}`]: item.Quantity ? this.utility.formatNumberExcel(item.Quantity) : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.UNIT')}`]: item.Unit ? item.Unit : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.QUANTITY')} `]: item.Quantity && item.Ratio ? this.utility.formatNumberExcel(item.Quantity / item.Ratio) : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.SECONDARY_UNIT')}`]: item.SecondaryUnit ? item.SecondaryUnit : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.PRICE')}`]: item.Quantity && item.Ratio ? this.utility.formatNumberExcel(item.SecondaryPrice) : '',
                        [`${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.PRICE_QTY')}`]: item.Quantity && item.Ratio ? this.utility.formatNumberExcel(item.SecondaryPrice * item.Quantity) : '',
                    });
                });

                this.loaderService.hide();
                this.excelService.exportAsExcelFile(xlsx, 'stock', merges, this.columnsList, 2, [], true);

            })
            .catch((err: any) => {
                this.loaderService.hide();
            });



    }

    /******************************************************************************
      ______                       _     _____  _____  ______
     |  ____|                     | |   |  __ \|  __ \|  ____|
     | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
     |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
     | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
     |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
           | |
           |_|
    ******************************************************************************/

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;

                let widths: any = [];
                this.columnsList.filter((item: any) => item.enabled).forEach(() => {
                    widths.push('auto')
                });

                // Tolgo la colonna actions
                //widths = widths.slice(0, widths.length-1);

                widths[widths.length - 1] = '*';

                this.pdfService.makePdf(result, this.getPDFTableBody(), widths)
            };
        });

    }

    getPDFTableBody() {
        let body: any = [];
        let tmpRow: any = [];

        // aggiungo intestazione
        // totali Header
        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.COMPANY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.COSTCENTERS')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.CATEGORY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.CODE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.NAME')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.UNIT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.QUANTITY')} `, style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.SECONDARY_UNIT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[9].enabled, alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.PRICE')} `, style: 'tableHeaderStyle' },
            { visible: this.columnsList[10].enabled, alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.GIACENZE.COLUMNS.PRICE_QTY')} `, style: 'tableHeaderStyle' }
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'right', border: [true, true, true, true], text: this.listaFiltered.Quantity ? this.formatNumber(this.listaFiltered.Quantity) : ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'left', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'left', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[9].enabled, alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[10].enabled, alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.listaFiltered.Total), style: 'tableHeaderStyle' },
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        let i: number = 0;
        let sortedList = this.utility.sortList(this.listaFiltered.Rows, 'table');
        sortedList.forEach((item: any) => {
            //this.listaFiltered.Rows.forEach((item: any) => {
            tmpRow = [
                { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Company ? item.Company.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.CostCenter ? item.CostCenter.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Category ? item.Category.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Code ? item.Code : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Goods && item.Goods.Name ? item.Goods.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[5].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Quantity ? this.formatNumber(item.Quantity) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[6].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Unit ? item.Unit : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[7].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Quantity && item.Ratio > 0? this.formatNumber(item.Quantity / item.Ratio) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.SecondaryUnit ? item.SecondaryUnit : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.SecondaryPrice ? this.formatNumber(item.SecondaryPrice) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[10].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Quantity && item.SecondaryPrice? this.formatNumber(item.SecondaryPrice * item.Quantity) : ' ', style: 'tableBodyStyle' }
            ];
            body.push(tmpRow.filter((item: any) => item.visible));
            i++;
        });

        return body;
    }

}
