import { Component, OnInit, ViewChild } from '@angular/core';
import { SupportService } from '../support.service';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DndUploadComponent } from '@app/views/partials/content/widgets/dnd-upload/dnd-upload.component';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { result } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-edit-issue',
    templateUrl: './edit-issue.component.html',
    styleUrls: ['./edit-issue.component.scss']
})
export class EditIssueComponent implements OnInit {

    issue: any;
    
    newNoteText!: string;

    private dndUpload!: DndUploadComponent;
    @ViewChild('dndUpload', { static: false }) set _set(content: DndUploadComponent) {
        if (content) { // initially setter gets called with undefined
            this.dndUpload = content;
        }
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private supportService: SupportService,
        private layoutUtilsService: LayoutUtilsService,
        private sanitizer: DomSanitizer,
        private translate: TranslateService,
        public loaderService: LoaderService
    ) { 
        document.body.classList.add('kt-aside--minimize');
    }

    ngOnInit() {

        // this.supportService.getProjectList().toPromise()
        // .then(
        //   (result) => {console.log(result)}
        // );

        this.activatedRoute.queryParams.subscribe((params: any) => {
            console.log('params', params);
            if (params.issue) {
                try {
                    this.getIssue(params.issue);
                } catch (error) {
                    this.router.navigateByUrl('/support');
                }
            } else {
                this.issue = {
                    status: 'new'
                };
            }
        });
    }

    getIssue(issueId: string) {
        this.loaderService.show();
        this.supportService.getIssue(issueId).toPromise()
            .then((issues: any) => {
                // console.log('getIssue', issues);
                let issue = issues.issues[0];

                this.supportService.getIssueFiles(issue.id).toPromise()
                    .then((result: any) => {
                        console.log('getIssueFiles', result);

                        if (result.files && result.files.length > 0) {
                            result.files.forEach((file: any) => {

                                // Verifico se ci sono delle allegati alla descrizione
                                if (issue.attachments && issue.attachments.length > 0) {
                                    issue.attachments.forEach((attachment: any) => {                                                                            
                                        if (attachment.id == file.id) {
                                            attachment.content = file.content;
                                            if(this.isImage(file)) {
                                                attachment.base64 = 'data:image/jpg;base64,' + file.content
                                            } else if (this.isVideo(file)) {
                                                const videoType = this.getVideoType(file);
                                                attachment.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:${videoType};base64, ` + file.content.replace('datavideo/mp4base64', ''));
                                            }
                                        }
                                    });
                                }

                                // Verifico se ci sono delle note con degli allegati
                                if (issue.clear_notes && issue.clear_notes.length > 0) {
                                    issue.clear_notes.forEach((note: any) => {
                                        note.attachments.forEach((attachment: any) => {
                                            if (attachment.id == file.id) {
                                                attachment.content = file.content;
                                                if(this.isImage(file)) {
                                                    attachment.base64 = 'data:image/jpg;base64,' + file.content
                                                } else if (this.isVideo(file)) {
                                                    const videoType = this.getVideoType(file);
                                                    attachment.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:${videoType};base64, ` + file.content.replace('datavideo/mp4base64', ''));
                                                }
                                            }
                                        });
                                    });
                                }

                            });
                        }

                        this.issue = issue;

                        this.loaderService.hide();
                    })
                    .catch(() => this.loaderService.hide());

            })
            .catch(() => this.loaderService.hide());
    }

    openImage(attachment: any) {
        var win: any = window.open();
        win.document.write('<iframe src="' + attachment.base64 + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }

    isImage(attachment: any) {
        return attachment.content_type.indexOf('image') >= 0;
    }

    isVideo(attachment: any) {
        return attachment.content_type.indexOf('video') >= 0;
    }

    getVideoType(attachment: any) {
        const pointIndex = attachment.filename.lastIndexOf('.');
        const type = 'video/' + attachment.filename.substring(pointIndex + 1);
        return type;
    }

    getImageSrc(attachment: any) {
        const src = `https://mantis.fbmanager.com/file_download.php?file_id=${attachment.id}&type=bug`;
        return src;
    }

    addNewIssue() {
        let newIssue = {
            summary: this.issue.summary,
            description: this.issue.description,
            category: {
                id: 1,
                name: "General"
            },
            project: {
                id: 1,
                name: "MyBusiness"
            },
            handler: {
                name: "Edoardo"
            },
        };

        if (this.dndUpload.files && this.dndUpload.files.length > 0) {
            newIssue['files'] = [];

            this.dndUpload.files.forEach((file: any) => {
                newIssue['files'].push({
                    name: file.name,
                    content: file.base64.substring(file.base64.indexOf('base64,') + 7).trim()
                });
            });
        }

        this.supportService.newIssue(newIssue).toPromise().then(
            (result: any) => {
                this.router.navigateByUrl('/support');
            }
        )
    }

    addNewNote() {
        let newNote = {
            text: this.newNoteText,
            view_state: {
                id: 10,
                name: 'public'
            }
        };

        if (this.dndUpload.files && this.dndUpload.files.length > 0) {
            newNote['files'] = [];

            this.dndUpload.files.forEach((file: any) => {
                newNote['files'].push({
                    name: file.name,
                    content: file.base64.substring(file.base64.indexOf('base64,') + 7).trim()
                });
            });
        }

        this.supportService.newIssueNote(this.issue.id, newNote).toPromise().then(
            (result: any) => {
                console.log('newIssueNote', result);
                // this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                this.getIssue(this.issue.id);
                this.newNoteText = '';
                this.dndUpload.clear();
            }
        )
    }

    closeIssue() {
        this.supportService.closeIssue(this.issue.id, {
            id: this.issue.id,
            handler: { name: 'andrea' },
            status: { name: 'closed' }
        }).toPromise().then((result: any) => {
            console.log(result);
            this.getIssue(this.issue.id);
            this.newNoteText = '';
            this.dndUpload.clear();
        });
    }

}
