import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Chart } from 'chart.js/dist/chart.min.js';
import * as util from '@app/core/services/utilityfunctions';
import { LayoutConfigService, TranslationService } from '@app/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';
import { PDFService } from '@app/core/services/pdf.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { lastValueFrom } from 'rxjs';
import { ScadenziarioService } from '../scadenziario.service';
import { FiltriService } from '@app/core/services/filtri.service';
import { MatDialog } from '@angular/material/dialog';
import { ScadenziarioEditComponent } from '../scadenziario-edit/scadenziario-edit.component';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import _ from 'lodash';

@Component({
  selector: 'kt-scadenziario-grafico',
  templateUrl: './scadenziario-grafico.component.html',
  styleUrls: ['./scadenziario-grafico.component.scss']
})
export class ScadenziarioGraficoComponent implements OnInit {

  @Output() onClickTable = new EventEmitter<boolean>();
  @Output() onLoading = new EventEmitter<boolean>(false);
  @Input() banksList: any
  @Input() bankOperationsList: any
  @Input() paymentModalityList: any;
  @Input() currentFilterCfg: any

  @ViewChild('chart', { static: true }) chart!: ElementRef;
  myChart: any;
  utility: any = util;
  locale!: string;
  graphData: any;
  maxValuesPerPage: number = 20;
  maxValuesPerPageTmp: number = 20;
  excludePagination: boolean = false;

  scadenze: any;

  totDebit: number = 0;
  totCredit: number = 0;

  accountType: number = 1;
  accountList: any;
  selectedAccounts: any;

  filterStatus: string = '2';

  constructor(
    private translationService: TranslationService,
    private translate: TranslateService,
    private layoutConfigService: LayoutConfigService,
    private pdfService: PDFService,
    public staticCollectionsService: StaticCollectionsService,
    private ref: ChangeDetectorRef,
    private scadenziarioService: ScadenziarioService,
    private filtriService: FiltriService,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
  ) {
  }

  ngOnInit(): void {
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });
    if (this.currentFilterCfg) {
      this.accountType = this.currentFilterCfg['Type'] ? this.currentFilterCfg['Type'] : 1;
      this.selectedAccounts = this.currentFilterCfg['Subjects'];
      this.filterStatus = this.currentFilterCfg['DisabledStatus'] == 2 ? 'DisabledStatus' : (this.currentFilterCfg['Status'] ? this.currentFilterCfg['Status'] : 2);
    }
  }

  filterByStatus() {
    if (!this.currentFilterCfg) {
      this.currentFilterCfg = {
        Period: 'YTD',
        Type: 1,
        Status: 2 // not payed
      };
    }

    if (this.filterStatus === 'DisabledStatus') {

      delete this.currentFilterCfg['Status'];
      this.currentFilterCfg['DisabledStatus'] = 2;

    } else {
      delete this.currentFilterCfg['DisabledStatus'];
      this.currentFilterCfg['Status'] = this.filterStatus;
    }

    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }

  getScadenze(forceChangeAccount: boolean = false) {

    if (this.currentFilterCfg && !this.currentFilterCfg.Type) {
      this.currentFilterCfg.Type = 1;
    } else if (!this.currentFilterCfg) {
      this.currentFilterCfg = {
        Period: 'YTD',
        Type: 1, // supplier
        Status: 2, // not payed
      }
    }

    if (!this.currentFilterCfg.DisabledStatus) {
      this.currentFilterCfg.DisabledStatus = 1;
    }

    this.onLoading.emit(true);
    lastValueFrom(this.scadenziarioService.getScadenzeDt(true, this.currentFilterCfg))
      .then((result: any) => {
        this.scadenze = result.data;
        if (forceChangeAccount) {
          this.changeAccountType(this.accountType);
        } else {
          this.refreshGraph();
        }
      })
      .finally(() => this.onLoading.emit(false))
  }

  callGetScadenze() {
    this.currentFilterCfg['Type'] = this.accountType;
    this.currentFilterCfg['Subjects'] = this.selectedAccounts;
    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }

  paymentLimit: number = 0;
  setPaymentLimit(event: KeyboardEvent) {
    if (event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.currentFilterCfg['PaymentLimit'] = this.paymentLimit;
      this.filtriService.performFilter$.next(this.currentFilterCfg);
    }
  }

  clearFilters() {
    this.currentFilterCfg['Type'] = 1;
    this.currentFilterCfg['Status'] = 2;
    delete this.currentFilterCfg['Subjects'];
    delete this.currentFilterCfg['PaymentLimit'];
    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }

  // Se ho un filtro impostato allora lo elimino e aggiorno l'elenco delle scadenze raggruppate
  manageChangeAccountType(event: any) {
    // Pulisco i filtri
    delete this.currentFilterCfg['Subjects'];
    this.currentFilterCfg['Type'] = event;
    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }

  changeAccountType(event: any) {
    this.accountList = undefined;
    this.selectedAccounts = undefined;
    setTimeout(() => {
      switch (event) {
        case 1:
          this.accountList = this.staticCollectionsService.fornitori$;
          break;
        case 2:
          this.accountList = this.staticCollectionsService.clientiTutti$;
          break;
        case 4:
          this.accountList = this.staticCollectionsService.dipendentiTutti$;
          break;
      }

      if (!this.selectedAccounts && this.currentFilterCfg && this.currentFilterCfg['Subjects']) {
        setTimeout(() => {
          this.accountType = this.currentFilterCfg['Type'];
          this.selectedAccounts = this.currentFilterCfg['Subjects'];
          this.ref.detectChanges();
        }, 100);
      }

      this.refreshGraph();

      this.ref.detectChanges();
    }, 100);
  }

  getPlaceholder() {
    let placeholder = "";
    switch (this.accountType) {
      case 1:
        placeholder = this.translate.instant('FILTRI.FORNITORE_PLACEHOLDER');
        break;
      case 2:
        placeholder = this.translate.instant('FILTRI.CLIENTE_PLACEHOLDER');
        break;
      case 4:
        placeholder = this.translate.instant('FILTRI.DIPENDENTE_PLACEHOLDER');
        break;
    }

    return placeholder;
  }

  goToScadenziarioTable() {
    this.onClickTable.emit(true);
  }

  excludeGraphPagination(event: any) {
    this.excludePagination = event.checked;
    if (this.excludePagination) {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.myChart.config.data.labels.length - 1;
      this.myChart.config.options.scales.x.ticks.padding = 0;
    } else {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
      this.myChart.config.options.scales.x.ticks.padding = 35;
    }
    this.myChart.update();
  }

  onKeyUpMaxValues(event: any) {
    if (event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.maxValuesPerPage = this.maxValuesPerPageTmp;
      this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
      this.myChart.update();
    }
  }

  performFilter(value: any, param: string) {
  }

  isCustomer(type: number) {
    return (type & 2) !== 0;
  }

  isSupplier(type: number) {
    return (type & 1) !== 0;
  }

  isEmployee(type: number) {
    return (type & 4) !== 0;
  }

  refreshGraph() {
    let labels: any = [];
    let amount: any = [];

    let elencoDati = JSON.parse(JSON.stringify(this.scadenze));

    let datasets: any = [];

    // Recupero ricavi tot
    elencoDati = elencoDati.sort((a: any, b: any) => {
      if (this.accountType == 2) {
        return a.Credit > b.Credit ? -1 : (a.Credit < b.Credit ? 1 : 0);
      } else {
        return a.Debit > b.Debit ? -1 : (a.Debit < b.Debit ? 1 : 0);
      }
    });

    this.totDebit = 0;
    this.totCredit = 0;
    elencoDati.forEach((record: any) => {
      // Se è un cliente => credito altrimenti dipendente/fornitore => debito 
      if (this.accountType == 2 && this.isCustomer(record.Type)) {
        this.totCredit += record.Credit;
        amount.push(this.utility.parseNumber(record.Credit, this.locale, 2));
      } else if (this.accountType == 1 && this.isSupplier(record.Type)) {
        this.totDebit += record.Debit;
        amount.push(this.utility.parseNumber(record.Debit, this.locale, 2));
      } else if (this.accountType == 4 && this.isEmployee(record.Type)) {
        this.totDebit += record.Debit;
        amount.push(this.utility.parseNumber(record.Debit, this.locale, 2));
      }
    });

    datasets = [
      {
        type: 'bar',
        label: this.translate.instant('SCADENZIARIO.GRAPH.ASSE_Y'),
        backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset1'),
        data: amount,
        yAxisID: "y-axis-amount",
        accountIds: elencoDati.map((x: any) => x.InnerRows[0]?.SupplierId)
      }
    ]

    this.graphData = {
      labels: elencoDati.map((x: any) => x.Name),
      datasets: datasets
    };

    this.initChartJS(elencoDati);
  }

  /** Init chart */
  initChartJS(elencoDati: any) {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    if (this.myChart) {
      this.myChart.destroy();
    }

    // Serve per esportazione in PDF per background bianco, altrimenti rimane nero.
    const bgColor = {
      id: 'bgColor',
      beforeDraw: (chart: any, options: any) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    }

    const moveChart = {
      id: 'moveChart',
      afterEvent: (chart: any, args: any) => {
        if (this.excludePagination) return;

        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        canvas.addEventListener('mousemove', (event) => {
          const x = args.event.x;
          const y = args.event.y;

          if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const leftEnabled = chart.config.options.scales.x.min > 0
            canvas.style.cursor = leftEnabled ? 'pointer' : 'not-allowed';
          } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const dataLength = chart.config.data.labels.length;
            const rightEnabled = chart.config.options.scales.x.max < dataLength - 1;
            canvas.style.cursor = rightEnabled ? 'pointer' : 'not-allowed';
          } else {
            canvas.style.cursor = 'default';
          }
        });
      },
      afterDraw: (chart: any, args: any, pluginOptions: any) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        if (this.excludePagination) {

          // Scrivo il totale dei risultati
          ctx.beginPath();
          const rect = canvas.getBoundingClientRect();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem "Barlow Condensed"`;
          const pagination = `${chart.config.data.labels.length} ${this.translate.instant('COMMONS.RESULTS')}`;
          const textWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textWidth, top - 12);
          ctx.closePath();

        } else {

          class CircleChevron {
            //constructor(x1, y1) {}

            draw(ctx, x1, pixel, enabled: boolean = true) {
              const angle = Math.PI / 180;

              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = 'rgba(102, 102, 102, .5)';
              ctx.fillStyle = 'white';
              ctx.arc(x1, height / 2 + top, 10, angle * 0, angle * 360, false);
              ctx.stroke();
              ctx.fill();
              ctx.closePath();

              // chevron Arrow
              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = enabled ? '#5867DD' : '#CCCCCC';
              ctx.moveTo(x1 + pixel, height / 2 + top - 5.5);
              ctx.lineTo(x1 - pixel, height / 2 + top);
              ctx.lineTo(x1 + pixel, height / 2 + top + 5.5);
              ctx.stroke();
              ctx.closePath();
            }
          }

          const leftEnabled = chart.config.options.scales.x.min > 0
          let drawCircleLeft = new CircleChevron();
          drawCircleLeft.draw(ctx, left, 3, leftEnabled);

          const dataLength = chart.config.data.labels.length;
          const rightEnabled = chart.config.options.scales.x.max < dataLength - 1;
          let drawCircleRight = new CircleChevron();
          drawCircleRight.draw(ctx, right, -3, rightEnabled);

          // Scrivo la paginazione
          ctx.beginPath();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem "Barlow Condensed"`;
          const pagination = `${this.translate.instant('EXPORT_PDF.PAGE')} ${Math.ceil(chart.config.options.scales.x.min / this.maxValuesPerPage) + 1} ${this.translate.instant('EXPORT_PDF.OF')} ${Math.ceil(dataLength / this.maxValuesPerPage)}`;
          const textPaginationWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textPaginationWidth, top - 12);
          ctx.closePath();

          // Scrivo l'indicazione per cliccare sul grafico
          //ctx.beginPath();
          //ctx.fillStyle = "#505050";
          //ctx.font = `500 1rem "Barlow Condensed"`;
          //const hint = this.translate.instant('SCADENZIARIO.GRAPH.CLICK_ON_CHART')
          //ctx.fillText(hint, left, top - 12);
          //ctx.closePath();
        }
      }
    }

    const options = {
      type: 'bar',
      data: this.graphData,
      plugins: [
        bgColor,
        moveChart,
        {
          afterDraw: (chart: any) => {
            const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
            /************************************/
            // Disegno l'andamento
            const revAttuale = chart.config.data.datasets;
            if (!revAttuale[0]) return;
            const tot: number = this.isCustomer(this.accountType) ? this.totCredit : this.totDebit;

            const min = chart.scales['x'].min;
            const max = chart.scales['x'].max;
            const endIteration = (min + this.maxValuesPerPage - 1) >= max ? max - min : this.maxValuesPerPage;

            ctx.font = `500 1rem "Barlow Condensed"`;
            ctx.fillStyle = "#505050";

            for (let index = 0; index <= endIteration; index++) {
              let x = chart.scales['x'].getPixelForTick(index);
              let y = chart.scales['y-axis-amount'].bottom;

              const tRevAttuale = parseFloat(revAttuale[0].data[min + index]);
              const incidenza = tot > 0 ? tRevAttuale / tot * 100 : 0;

              const incidenceLabel = this.utility.formatNumber(incidenza, this.locale, 0);
              const textWidth1 = ctx.measureText(incidenceLabel).width;
              ctx.fillText(`${incidenceLabel}%`, x - (textWidth1 / 2), y + 15);
            }

          }
        }],
      options: {
        name: 'scadenziario',
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            bodyFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            titleFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            footerFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            mode: 'index',
            padding: 10,
            caretPadding: 10,
            callbacks: {
              title: (tooltipItem) => {
                return tooltipItem[0].label;
              },
              label: (tooltipItem, a, v) => {
                const value = parseFloat(tooltipItem.raw);
                return this.utility.formatNumber(value, this.locale, 0, true, true);
              }
            }
          },
          legend: {
            labels: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              }
            },
            display: false,
            position: 'bottom'
          }
        },
        // https://dev.to/giselamd/creating-a-custom-chart-js-legend-style-50i5
        //legendCallback: (chart) => {
        //    const renderLabels = (chart) => {
        //        const { data } = chart;
        //        return data.datasets
        //            .map(
        //                (_, i) => {
        //                    let backgroundColor;
        //                    let label;
        //                    backgroundColor = data.datasets[i].backgroundColor;
        //                    label = data.datasets[i].label;

        //                    return `<li>
        //                    <div id="legend-${i}-item" class="legend-item">
        //                      <span style="background-color:
        //                        ${backgroundColor}">
        //                        &nbsp;&nbsp;&nbsp;&nbsp;
        //                      </span>
        //                      ${label &&
        //                        `<span class="label">&nbsp;${label}</span>`
        //                        }
        //                    </div>
        //                </li>
        //              `})
        //            .join("");
        //    };
        //    return `
        //          <ul class="chartjs-legend display-flex-center flex-align-center flex-wrap list-style-none">
        //            ${renderLabels(chart)}
        //          </ul>`;
        //},
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          x: {
            min: 0,
            max: this.maxValuesPerPage - 1,
            grid: {
              display: false
            },
            stacked: false,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: 15,
              callback: (value: any, index: number, values: any) => {
                const overrideValue = this.graphData.labels[index] ? this.graphData.labels[index] : value;
                return overrideValue.length > 10 ? overrideValue.substring(0, 10) + '...' : overrideValue;
              },
            },
          },
          "y-axis-amount": {
            id: "y-axis-amount",
            position: 'left',
            display: true,
            stacked: false,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                if (parseFloat(value) > 0)
                  return this.utility.kFormatter(value, this.locale);
              },
              precision: 0,
              integerSteps: true
            },
            title: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('SCADENZIARIO.GRAPH.ASSE_Y')
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 30,
            top: 30,
            bottom: 0
          }
        },
        onHover: (event: any, chartElement: any) => {
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        onClick: (chart: any, element: any, a, b, c) => {

          try {
            const accountId = this.graphData.datasets[0].accountIds[element[0].index];
            if (accountId) {
              this.currentFilterCfg['Subjects'] = [accountId];
              this.filtriService.filterConfig = this.currentFilterCfg;
              this.goToScadenziarioTable();
            }
          } catch (error) {

          }
        }
      }
    };

    this.myChart = new Chart(this.chart.nativeElement, options);

    const moveScroll = () => {
      this.myChart.canvas.addEventListener('click', (event: any) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = this.myChart;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
          this.myChart.config.options.scales.x.min -= this.maxValuesPerPage;
          this.myChart.config.options.scales.x.max -= this.maxValuesPerPage;
          if (this.myChart.config.options.scales.x.min <= 0) {
            this.myChart.config.options.scales.x.min = 0
            this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
          }
          this.myChart.update();
        } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {

          this.myChart.config.options.scales.x.min += this.maxValuesPerPage;
          this.myChart.config.options.scales.x.max += this.maxValuesPerPage;

          const dataLength = this.myChart.config.data.labels.length;
          if (this.myChart.config.options.scales.x.max >= dataLength) {
            this.myChart.config.options.scales.x.min = dataLength - this.maxValuesPerPage;
            this.myChart.config.options.scales.x.max = dataLength - 1;
          }
          this.myChart.update();
        }

      });
    }

    this.myChart.ctx.onclick = moveScroll();

    // https://dev.to/giselamd/creating-a-custom-chart-js-legend-style-50i5
    // document.getElementById('chart-legends').innerHTML = this.myChart.generateLegend();
    // bindChartEvents(this.myChart, document);
  }

  printChart() {
    this.myChart.update();
    setTimeout(() => {
      var canvas = <HTMLCanvasElement>document.getElementById('scadenziario');
      this.pdfService.chartToPdf(canvas, this.translate.instant('SCADENZIARIO.GRAPH.TITLE'));
    }, 100);
  }

  newDeadline() {
    let scadenza: any = {}
    const dialogRef = this.dialog.open(ScadenziarioEditComponent, {
      data: {
        scadenza: scadenza,
        bankOperationsList: this.bankOperationsList,
        banksList: this.banksList,
        paymentModalityList: this.paymentModalityList,
        locale: this.locale
      },
      width: '900px'
    });

    dialogRef.componentInstance.onLoading.subscribe((res: any) => {
      //this.onLoading.emit(res);
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (!res || _.isEmpty(res)) {
        return;
      }
      const _saveMessage = this.translate.instant('SCADENZIARIO.ACTION_EDIT.UPDATE_MESSAGE');
      this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Update);

      //this.getScadenze();
    });
  }

}
