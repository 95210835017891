import { Component, OnInit } from '@angular/core';
// Material
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
// Services
import { EndpointsService } from '@app/core/services/endpoints.service';
// Component
import { EndpointsEditComponent } from '@app/views/pages/endpoints/endpoints-edit/endpoints-edit.component';
// CRUD
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
import { SelectIntervalComponent } from './select-interval/select-interval.component';

import * as _ from 'lodash';
import { UsersListComponent } from './users-list/users-list.component';
import { IntegrationsEditComponent } from './integrations-edit/integrations-edit.component';
import * as util from '@app/core/services/utilityfunctions';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-endpoints',
    templateUrl: './endpoints.component.html',
    styleUrls: ['./endpoints.component.scss']
})
export class EndpointsComponent implements OnInit {

    endpoints: any = undefined;
    users: any = undefined;
    
    currentEndpointIndex: number = 0;
    selectedEndPointId: any;
    Version: any = "";
    SomeOld: boolean = false;

    constructor(
        public dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private endpointsService: EndpointsService,
        public loaderService: LoaderService
    ) {

    }

    ngOnInit() {
        document.body.classList.add('kt-aside--minimize');
        this.getDeployVersion();

    }

    integrations(endpoint: any) {
        const dialogRef = this.dialog.open(IntegrationsEditComponent, {
            width: '500px',
            data: {
                endpoint: endpoint
            }
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }
            this.refreshEndPoints();
        });
    }

    selectEndpoint(index: number) {
        this.currentEndpointIndex = (index > this.endpoints.length - 1) ? this.endpoints.length - 1 : index;
        this.users = this.endpoints[index].Users;
        this.selectedEndPointId = this.endpoints[index].Id;
        const dialogRef = this.dialog.open(UsersListComponent, {
            data: {
                users: this.users,
                endpoint: this.endpoints[this.currentEndpointIndex],
                endpoints: this.endpoints
            }, width: '1000px'
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.refreshEndPoints();
        });

    }

    refreshEndPoints() {
        this.loaderService.show();
        this.endpointsService.getEndpoints()
            .pipe(take(1))
            .subscribe(
                (endpoints: any) => {
                    this.endpoints = endpoints;
                    this.initDataTableEndPoints();
                    this.searchSomeOld();
                    this.loaderService.hide();
                }
            )
    }

    searchSomeOld() {
        let someOld = false;
        if (!this.Version) return;

        this.endpoints.forEach((element: any) => {
            if (!someOld) {
                someOld = this.isOldVersion(element.API_Version)
            }
        });
        this.SomeOld = someOld;
    }

    initDataTableEndPoints() {
        if ($.fn.dataTable.isDataTable('#endpointsTable')) {
            $('#endpointsTable').DataTable().destroy();
        }
        setTimeout(() => {
            const dtOptions = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
                },
                paging: false,
                searching: true,
                ordering: true,
                columnDefs: [
                    { targets: [6], orderable: false }
                ]
            };

            $('#endpointsTable').DataTable(dtOptions);

        }, 100);
    }

    refresh() {
        this.endpointsService.refreshEndpoints().subscribe(
            (ret: any) => {
                this.refreshEndPoints();
            }, (error: any) => {
                console.log(error);
            }
        );
    }

    deploy(endpoint) {

        const _title: string = "Deploy Application";
        const _description: string = "Sei sicuro di voler installare la versione " + this.Version + " sull endpoint " + endpoint.Name;
        const _waitDesciption: string = "Annulla";
        const _yesDesciption: string = "Ok";

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesDesciption, _waitDesciption);
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) {


                this.endpointsService.deploy(endpoint.Id).subscribe(
                    (ret: any) => {
                        this.refreshEndPoints();
                    }, (error: any) => {
                        console.log(error);
                    }
                );
            }
        });


    }

    weather(endpoint) {
        const dialogRef = this.dialog.open(SelectIntervalComponent, { data: { endpoint }, width: '1000px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res || !res.success || !res.body.DateStart || !res.body.DateEnd) {
                return;
            } else {
                //Call weather update
                let from = res.body.DateStart.format("yyyy/MM/DD");
                let to = res.body.DateEnd.format("yyyy/MM/DD")
                this.endpointsService.weather(endpoint.Id, from, to).subscribe(
                    (ret: any) => {
                        this.layoutUtilsService.showActionNotification("Ok", MessageType.Update);
                    }, (error: any) => {
                        console.log(error);
                    }
                );
            }
        });
    }

    lastLogZip(endpoint) {
        this.endpointsService.lastLogZip(endpoint.Id).subscribe(
            (ret: any) => {
                const file = new window.Blob([ret], { type: "octet/stream" });

                const fileURL = URL.createObjectURL(file);

                let a = document.createElement('a');
                document.body.appendChild(a);

                a.setAttribute('style', 'display: none');
                a.href = fileURL;
                a.download = 'FB_' + endpoint.Name + '.zip';
                a.click();
                window.URL.revokeObjectURL(fileURL);
                a.remove();


            }, (error: any) => {
                console.log(error);
            }
        );


    }

    lastLog(endpoint) {
        this.endpointsService.lastLog(endpoint.Id).subscribe(
            (ret: any) => {
                const file = new window.Blob([ret], { type: 'text/plain' });

                const fileURL = URL.createObjectURL(file);

                let a = document.createElement('a');
                document.body.appendChild(a);

                a.setAttribute('style', 'display: none');
                a.href = fileURL;
                a.download = 'FB.log';
                a.click();
                window.URL.revokeObjectURL(fileURL);
                a.remove();
            }, (error: any) => {
                console.log(error);
            }
        );


    }

    getDeployVersion() {
        this.endpointsService.getDeployVersion().subscribe(
            (ret: any) => {
                this.Version = ret;
                this.refreshEndPoints();
            }, (error: any) => {
                console.log(error);
            }
        );
    }

    isOldVersion(ver) {
        if (!this.Version) return false;
        let p1 = ver.indexOf(':');
        let p2 = ver.indexOf('DB');
        let v = ver.substring(p1 + 1, p2).trim();
        const ver1 = v.split('.').map((s: any) => s.padStart(10)).join('.');
        const ver2 = this.Version.split('.').map((s: any) => s.padStart(10)).join('.');
        return ver1 < ver2;
    }

    /** ACTIONS */
    /**
     * Delete customer
     *
     * @param _item: CustomerModel
     */
    deleteEndpoint(_item: any) {
        const _title: string = this.translate.instant('ENDPOINTS.DELETE_ENDPOINT_SIMPLE.TITLE');
        const _description: string = this.translate.instant('ENDPOINTS.DELETE_ENDPOINT_SIMPLE.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ENDPOINTS.DELETE_ENDPOINT_SIMPLE.WAIT_DESCRIPTION');
        const _deleteMessage = this.translate.instant('ENDPOINTS.DELETE_ENDPOINT_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }

            this.endpointsService.deleteEndpoint(_item).subscribe(
                (ret: any) => {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                    this.refreshEndPoints();
                }, (error: any) => {
                    console.log(error);
                }
            )
        });
    }

    addEndpoint() {
        const newEndpoint = {
            Id: ''
        };
        this.editEndpoint(newEndpoint);
    }

    editEndpoint(endpoint: any) {
        let saveMessageTranslateParam = 'ENDPOINTS.EDIT.';
        saveMessageTranslateParam += endpoint.Id.length > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = endpoint.Id.length > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(EndpointsEditComponent, { data: { endpoint } });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.refreshEndPoints();
        });
    }
}
