import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { LayoutConfigService, SwitchGroupService } from '@app/core/_base/layout';
import * as util from '@app/core/services/utilityfunctions';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';

import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { Router } from '@angular/router';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { EditMerciComponent } from '@app/views/pages/gestione-dati/gestione-merci/edit-merci/edit-merci.component';
import { FiltriService } from '@app/core/services/filtri.service';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-consumi-dettagliati',
    templateUrl: './consumi-dettagliati.component.html',
    styleUrls: ['./consumi-dettagliati.component.scss']
})
export class ConsumiDettagliatiComponent implements OnInit {
    currentFilterCfg: any;
    @Input() visualizzaMovimentiMagazzino: boolean = false;
    @Input() visualizzaImporti: boolean = false;
    @Input() bestItems!: number;
    @Input() showBase: boolean = false;
    @Output() onLoading = new EventEmitter();

    initComplete: boolean = false;
    PDFPreferences: any;

    utility: any;
    locale!: string;
    table: any;
    columnsList: any;
    totals: any;

    pagination: any = {
        TotalRows: 0,
        pageSize: 10,
        pageIndex: 0
    }

    languageSubscription!: Subscription;

    constructor(
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        public dialog: MatDialog,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private router: Router,
        private gestioneMerciService: GestioneMerciService,
        private switchGroupService: SwitchGroupService,
        private filtriService: FiltriService,
        public loaderService: LoaderService
    ) {
        this.utility = util;
        this.languageSubscription = this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.columnsList = [
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.CODICE'), enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.CATEGORIA'), enabled: false, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.PRODOTTO'), enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.CONSUMI_TEORICI'), format: '#.##0,00', enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.IMP_CONSUMI_TEORICI'), format: '#.##0,00', enabled: this.visualizzaImporti ? true : false, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.UNITA'), enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.PREZZO'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.INVENTARIO_INIZIALE'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: this.visualizzaMovimentiMagazzino ? true : false },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.IMP_INVENTARIO_INIZIALE'), enabled: this.visualizzaImporti ? true : false, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.DATA_INIZIO'), enabled: this.visualizzaMovimentiMagazzino ? true : false, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.ACQUISTI'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: this.visualizzaMovimentiMagazzino ? true : false },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.IMP_ACQUISTI'), enabled: this.visualizzaImporti ? true : false, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.TRASFERIMENTI'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: this.visualizzaMovimentiMagazzino ? true : false },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.IMP_TRASFERIMENTI'), enabled: this.visualizzaImporti ? true : false, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.RETTIFICHE'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: this.visualizzaMovimentiMagazzino ? true : false },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.IMP_RETTIFICHE'), enabled: this.visualizzaImporti ? true : false, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.INVENTARIO_FINALE'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: this.visualizzaMovimentiMagazzino ? true : false },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.IMP_INVENTARIO_FINALE'), enabled: this.visualizzaImporti ? true : false, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.DATA_FINE'), enabled: this.visualizzaMovimentiMagazzino ? true : false, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.CONSUMI'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: this.visualizzaMovimentiMagazzino ? true : false },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.IMPORTO'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: this.visualizzaMovimentiMagazzino ? true : false },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.PERC'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: this.visualizzaMovimentiMagazzino ? true : false },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.QUANTITA'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.VALORE'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_DETTAGLIATI.COLUMNS.DIFF_PERC'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
        ];
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.languageSubscription.unsubscribe();
    }

    dtOptions: any;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#tableDettagli')) {
            $('#tableDettagli').DataTable().destroy();
        }
        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();
            this.dtOptions = {
                initComplete: () => {
                    this.initComplete = true;
                    this.ref.detectChanges();
                },
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Consumption/DataTable",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        d.filters['UseRealInvetoryDate'] = false;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        // Loop through  the response and store all key values in the totals object
                        this.totals = {};
                        for (let key in response) {
                            this.totals[key] = response[key];
                        }
                        delete this.totals['data'];
                        this.ref.detectChanges();
                        return response.data;
                    }
                },
                destroy: true,
                dom: 'rt<"paginator">',
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
                },
                paging: true,
                searching: true,
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 380px)',
                scrollCollapse: true,
                scrollX: true,
                fixedColumns: {
                    leftColumns: this.ckMovimentiMagazzino ? 3 : 2
                },
                columnDefs: [
                    // CODICE
                    {
                        targets: 0, data: 'Code', name: 'Code', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportText" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, width: '50px', visible: this.columnsList[0].enabled
                    },
                    // CATEGORIA
                    {
                        targets: 1, data: 'Category', name: 'Category', render: (data, type, p, meta) => {

                            const classColor = p.Err1 && this.currentFilterCfg['Err1Filter'] && this.showingErrors ? 'text-danger1' : (
                                p.Err2 && this.currentFilterCfg['Err2Filter'] && this.showingErrors ? 'text-danger2' : (
                                    p.Err3 && this.currentFilterCfg['Err3Filter'] && this.showingErrors ? 'text-danger3' : ''
                                )
                            )

                            return `<div class="overflow-ellipses exportText ${classColor}" data-toggle="tooltip" title="${p.Category?.Name}">${p.Category?.Name}</div>`;
                        }, className: 'max-width-100', visible: this.columnsList[1].enabled
                    },
                    // PRODOTTO                    
                    {
                        targets: 2, data: 'Goods', name: 'Goods', render: (data, type, p, meta) => {
                            const classColor = p.Err1 && this.currentFilterCfg['Err1Filter'] && this.showingErrors ? 'text-danger1' : (
                                p.Err2 && this.currentFilterCfg['Err2Filter'] && this.showingErrors ? 'text-danger2' : (
                                    p.Err3 && this.currentFilterCfg['Err3Filter'] && this.showingErrors ? 'text-danger3' : (
                                        !p.bug || !this.showingErrors ? 'text-blue' : ''
                                    )
                                )
                            )
                            return `<div class="d-flex justify-content-start align-items-center"><a href="javascript:;" data-id="${data.Id}" name="openMerce"><i class="bi bi-pencil-square"></i></a>&nbsp;
                            <div class="overflow-ellipses"><a href="javascript:;" name="openPopup" class="exportText" data-toggle="tooltip" title="${p.Goods?.Name}">${p.Goods?.Name}</a></div></div>`;
                        }, className: 'linktoAction max-width-250', visible: this.columnsList[2].enabled
                    },
                    // CONSUMI_TEORICI
                    {
                        targets: 3, data: 'BaseConsumption', name: 'BaseConsumption', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'Consumption'), this.locale, 2, true)
                        }, width: '80px', visible: this.columnsList[3].enabled, className: 'text-right numeric'
                    },
                    // IMP_CONSUMI_TEORICI
                    {
                        targets: 4, data: 'BaseConsumption', name: 'BaseConsumption', render: (data, type, p, meta) => {
                            return `${this.utility.formatNumber(this.whatColumn(p, 'Consumption') * this.whatColumn(p, 'Price'), this.locale, 2, true)}`;
                        }, width: '80px', className: 'text-right numeric higlight_col', orderable: true, visible: this.columnsList[4].enabled
                    },
                    // UNITA_MAGAZZINO
                    {
                        targets: 5, data: 'Units', name: 'Units', render: (data, type, p, meta) => {
                            return this.whatColumn(p, 'Unit');
                        }, width: '80px', className: '', orderable: false, visible: this.columnsList[5].enabled
                    },
                    // PREZZO
                    {
                        targets: 6, data: 'Price', name: 'Price', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'Price'), this.locale, 2, true)
                        }, width: '80px', visible: this.columnsList[6].enabled, className: 'text-right numeric'
                    },
                    // INVENTARIO_INIZIALE
                    {
                        targets: 7, data: 'PrevInventory.Quantity', name: 'PrevInventory.Quantity', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'PrevInventory.Quantity'), this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right', orderable: true, visible: this.columnsList[7].enabled
                    },
                    // IMP_INVENTARIO_INIZIALE
                    {
                        targets: 8, data: 'PrevInventory.Amount', name: 'PrevInventory.Amount', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'PrevInventory.Amount'), this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right higlight_col', orderable: true, visible: this.columnsList[8].enabled
                    },
                    // DATA_INIZIO
                    {
                        targets: 9, data: 'PrevInventory.Date', name: 'PrevInventory.Date', render: (data, type, p, meta) => {
                            return p.Goods.IsFresh ? 'N/D' : this.utility.getLocaleData(p.PrevInventory.Date, this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol', orderable: true, visible: this.columnsList[9].enabled
                    },
                    // ACQUISTI
                    {
                        targets: 10, data: 'Purchase', name: 'Purchase', render: (data, type, p, meta) => {
                            const format = this.utility.formatNumber(this.whatColumn(p, 'Purchase'), this.locale, 2, true);
                            return `<span class="exportText">${format}</span>&nbsp;<a href="javascript:;" name="goToAcquisti"><i class="bi bi-share"></i></a>`;
                        }, width: '80px', className: 'movementsCol linktoAction numeric text-right', orderable: true, visible: this.columnsList[10].enabled
                    },
                    // IMP_ACQUISTI
                    {
                        targets: 11, data: 'PurchaseAmount', name: 'PurchaseAmount', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(data, this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right higlight_col', orderable: true, visible: this.columnsList[11].enabled
                    },
                    // TRASFERIMENTI
                    {
                        targets: 12, data: 'Transfert', name: 'Transfert', render: (data, type, p, meta) => {
                            const format = this.utility.formatNumber(this.whatColumn(p, 'Transfert'), this.locale, 2, true);
                            return `<span class="exportText">${format}</span>&nbsp;<a href="javascript:;" name="goToTrasferimenti"><i class="bi bi-share"></i></a>`;
                        }, width: '80px', className: 'movementsCol linktoAction numeric text-right', orderable: true, visible: this.columnsList[12].enabled
                    },
                    // IMP_TRASFERIMENTI
                    {
                        targets: 13, data: 'TransfertAmount', name: 'TransfertAmount', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(data, this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right higlight_col', orderable: true, visible: this.columnsList[13].enabled
                    },
                    // RETTIFICHE
                    {
                        targets: 14, data: 'Adjustments', name: 'Adjustments', render: (data, type, p, meta) => {
                            const format = this.utility.formatNumber(this.whatColumn(p, 'Adjustments'), this.locale, 2, true);
                            return `<span class="exportText">${format}</span>&nbsp;<a href="javascript:;" name="goToSprechi"><i class="bi bi-share"></i></a>`;
                        }, width: '80px', className: 'movementsCol linktoAction numeric text-right', orderable: true, visible: this.columnsList[14].enabled
                    },
                    // IMP_RETTIFICHE
                    {
                        targets: 15, data: 'AdjustmentsAmount', name: 'AdjustmentsAmount', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(data, this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right higlight_col', orderable: true, visible: this.columnsList[15].enabled
                    },
                    // INVENTARIO_FINALE
                    {
                        targets: 16, data: 'Inventory.Quantity', name: 'Inventory.Quantity', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'Inventory.Quantity'), this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right', orderable: true, visible: this.columnsList[16].enabled
                    },
                    // IMP_INVENTARIO_FINALE
                    {
                        targets: 17, data: 'Inventory.Amount', name: 'Inventory.Amount', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'Inventory.Amount'), this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right higlight_col', orderable: true, visible: this.columnsList[17].enabled
                    },
                    // DATA_FINE
                    {
                        targets: 18, data: 'Inventory.Date', name: 'Inventory.Date', render: (data, type, p, meta) => {
                            return p.Goods.IsFresh ? 'N/D' : this.utility.getLocaleData(p.Inventory.Date, this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol', orderable: true, visible: this.columnsList[18].enabled
                    },
                    // CONSUMI
                    {
                        targets: 19, data: 'EffectiveConsumption', name: 'EffectiveConsumption', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'EffectiveConsumption'), this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol text-right numeric higlight_col', orderable: true, visible: this.columnsList[19].enabled
                    },
                    // IMPORTO
                    {
                        targets: 20, data: 'EffectiveConsumptionValue', name: 'EffectiveConsumptionValue', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(data, this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right higlight_col', orderable: true, visible: this.columnsList[20].enabled
                    },
                    // PERC
                    {
                        targets: 21, data: 'EffectiveConsumptionPerc', name: 'EffectiveConsumptionPerc', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(data, this.locale, 2, true);
                        }, width: '80px', className: 'movementsCol numeric text-right', orderable: true, visible: this.columnsList[21].enabled
                    },
                    // QUANTITA
                    {
                        targets: 22, data: 'Rest', name: 'Rest', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'Rest'), this.locale, 2, true);
                        }, width: '80px', className: 'text-blue text-right numeric', orderable: true, visible: this.columnsList[22].enabled
                    },
                    // VALORE
                    {
                        targets: 23, data: 'RestValue', name: 'RestValue', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(data, this.locale, 2, true);
                        }, width: '80px', className: 'text-blue numeric text-right', orderable: true, visible: this.columnsList[23].enabled
                    },
                    // DIFF_PERC
                    {
                        targets: 24, data: 'RestVariancePerc', name: 'RestVariancePerc', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(data * 100, this.locale, 2, true);
                        }, width: '80px', className: 'text-blue numeric text-right', orderable: true, visible: this.columnsList[24].enabled
                    },

                    //{ targets: [3, 5, 7, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19], orderable: true, type: 'string-num' }
                ],
                aaSorting: this.bestItems && this.bestItems > 0 ? [[12, 'desc']] : [],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdfHtml5',
                        //text: '<i class="bi bi-file-earmark-pdf"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const columns = doc.content[1].table.body.length > 0 ? doc.content[1].table.body[0].length : 0;

                            const arrayCols = Array(columns).fill('auto');
                            arrayCols[2] = '*'; // La colonan prodotto la metto espandibile al massimo

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            ////doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            // Faccio un clone della prima riga che contiene i totali
                            // e gli inserisco i titoli delle colonne
                            const headers = JSON.parse(JSON.stringify(doc.content[1].table.body[0]));

                            // Inserisco i totali
                            let headerTotals: any = [];
                            headers.forEach((header: any, index: number) => {
                                switch (index) {
                                    case 4:
                                        headerTotals.push({
                                            text: this.utility.formatNumber(this.getTotal('Consumption'), this.locale, 2, true),
                                            style: 'tableHeader'
                                        });
                                        break;
                                    case 8:
                                        headerTotals.push({
                                            text: this.utility.formatNumber(this.getTotal('PrevInventory.Quantity'), this.locale, 2, true),
                                            style: 'tableHeader'
                                        });
                                        break;
                                    case 11:
                                        headerTotals.push({
                                            text: this.utility.formatNumber(this.getTotal('Purchase'), this.locale, 2, true),
                                            style: 'tableHeader'
                                        });
                                        break;
                                    case 13:
                                        headerTotals.push({
                                            text: this.utility.formatNumber(this.getTotal('Transfert'), this.locale, 2, true),
                                            style: 'tableHeader'
                                        });
                                        break;
                                    case 15:
                                        headerTotals.push({
                                            text: this.utility.formatNumber(this.getTotal('Adjustments'), this.locale, 2, true),
                                            style: 'tableHeader'
                                        });
                                        break;
                                    case 17:
                                        headerTotals.push({
                                            text: this.utility.formatNumber(this.getTotal('Inventory.Quantity'), this.locale, 2, true),
                                            style: 'tableHeader'
                                        });
                                        break;
                                    case 20:
                                        headerTotals.push({
                                            text: this.utility.formatNumber(this.getTotal('EffectiveConsumptionValue'), this.locale, 2, true),
                                            style: 'tableHeader'
                                        });
                                        break;
                                    case 23:
                                        headerTotals.push({
                                            text: this.utility.formatNumber(this.getTotal('RestValue'), this.locale, 2, true),
                                            style: 'tableHeader'
                                        });
                                        break;

                                    default:
                                        headerTotals.push({ text: '', style: 'tableHeader' });
                                        break;
                                }
                            });
                            doc.content[1].table.body[0] = headerTotals;
                            // Aggiungo gli headers in prima posizione
                            doc.content[1].table.body.unshift(headers);

                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                body: (data, row, column, node) => {

                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        data = $(htmlDoc).find('.exportText').text();
                                    }

                                    if (node.className.includes('numeric')) {
                                        return this.utility.parseNumber(data, this.locale);
                                    } else {
                                        return '\0' + data
                                    }
                                }
                            },
                            orthogonal: 'export'
                        },
                        customize: async (xlsx: any) => {
                            let sheet = xlsx.xl.worksheets['sheet1.xml'];

                            const columnsList: any = $('#tableDettagli').DataTable().columns();
                            // Filter all the visible columns in visibleColumnsCount
                            const visibleColumnsCount = columnsList.header().filter(':visible');
                            // convert the header to an array
                            const headerArray = Array.from(visibleColumnsCount).map((header: any) => header.innerText);

                            var numColumns = headerArray.length;
                            var newRow = '<row>';
                            for (var i = 0; i < numColumns; i++) {

                                switch (i) {
                                    case 4:
                                        newRow += `<c t="n"/><v>${this.utility.formatNumber(this.getTotal('Consumption'), this.locale, 2, true)}</v><c/>`;
                                        break;
                                    case 8:
                                        newRow += `<c t="n"/><v>${this.utility.formatNumber(this.getTotal('PrevInventory.Quantity'), this.locale, 2, true)}</v><c/>`;
                                        break;
                                    case 11:
                                        newRow += `<c t="n"/><v>${this.utility.formatNumber(this.getTotal('Purchase'), this.locale, 2, true)}</v><c/>`;
                                        break;
                                    case 13:
                                        newRow += `<c t="n"/><v>${this.utility.formatNumber(this.getTotal('Transfert'), this.locale, 2, true)}</v><c/>`;
                                        break;
                                    case 15:
                                        newRow += `<c t="n"/><v>${this.utility.formatNumber(this.getTotal('Adjustments'), this.locale, 2, true)}</v><c/>`;
                                        break;
                                    case 17:
                                        newRow += `<c t="n"/><v>${this.utility.formatNumber(this.getTotal('Inventory.Quantity'), this.locale, 2, true)}</v><c/>`;
                                        break;
                                    case 20:
                                        newRow += `<c t="n"/><v>${this.utility.formatNumber(this.getTotal('EffectiveConsumptionValue'), this.locale, 2, true)}</v><c/>`;
                                        break;
                                    case 23:
                                        newRow += `<c t="n"/><v>${this.utility.formatNumber(this.getTotal('RestValue'), this.locale, 2, true)}</v><c/>`;
                                        break;

                                    default:
                                        newRow += '<c t="n"/><v></v><c/>';
                                        break;
                                }

                                
                            }
                            newRow += '</row>';

                            // Trova la prima riga di dati nel foglio di lavoro
                            var firstDataRow = $(sheet).find('sheetData row:first');

                            // Inserisci la nuova riga dopo la prima intestazione
                            $(newRow).insertAfter(firstDataRow);

                            const visibleColumns = this.table.columns().visible().toArray();

                            const numToAlpha = (num: number) => {
                                var alpha = '';
                                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                                    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                                }
                                return alpha;
                            }

                            visibleColumns.forEach((visible: boolean, index: number) => {
                                if (visible) {
                                    const column = this.table.column(index);
                                    if (column.header().className.includes('numeric')) {
                                        const alpha = numToAlpha(index);
                                        $(`row c[r^="${alpha}"]`, sheet).attr('s', '64');
                                    }
                                }
                            });

                        },

                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ]
            };

            // Se non ci sono dati, non metto le colonne fisse a sinistra che fanno casino con l'immagine
            //if (!this.consumptionListFiltered || this.consumptionListFiltered.length == 0) {
            //    this.dtOptions.fixedColumns = false;
            //}

            console.log(this.dtOptions.columnDefs);

            this.table = $('#tableDettagli').DataTable(this.dtOptions);
            // Mi collego all'evento di loading della tabella
            this.table.off('processing.dt');
            this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
                if (processing) {
                    this.loaderService.show();
                } else {
                    this.loaderService.hide();
                }
                this.ref.detectChanges();
            });

            $('tbody').off('click');

            $('tbody').on('click', '.linktoAction', async (event: any) => {
                const tr = $(event.target).closest('tr');
                const a = $(event.target).closest('a');
                const data = this.table.row(tr.index()).data();

                switch (a.attr('name')) {
                    case 'openMerce':
                        this.openMerce(data);
                        break;
                    case 'goToAcquisti':
                        this.goToAcquisti(data);
                        break;
                    case 'goToTrasferimenti':
                        this.goToTrasferimenti(data);
                        break;
                    case 'goToSprechi':
                        this.goToSprechi(data);
                        break;
                }

            });

        }, 100);

    }

    changePagination(event: any) {
        if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.pagination.pageIndex = 0;
            this.table.page.len(this.pagination.pageSize).draw();
            this.table.page(0).draw('page');
        } else if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        }
    }

    baseColumn(column: string) {
        if (column == 'Adjustments') {
            return this.showBase ? 'AdjustmentsBase' : 'Adjustments';
        }
        if (column == 'EffectiveConsumption') {
            return this.showBase ? 'EffectiveConsumptionBase' : 'EffectiveConsumption';
        }
        if (column == 'PrevInventory.Quantity') {
            return this.showBase ? 'PrevInventory.QuantityBase' : 'PrevInventory.Quantity';
        }
        if (column == 'Inventory.Quantity') {
            return this.showBase ? 'Inventory.QuantityBase' : 'Inventory.Quantity';
        }
        if (column == 'Inventory.Quantity') {
            return this.showBase ? 'Inventory.QuantityBase' : 'Inventory.Quantity';
        }
        if (column == 'Price') {
            return this.showBase ? 'PriceBase' : 'Price';
        }
        if (column == 'Purchase') {
            return this.showBase ? 'PurchaseBase' : 'Purchase';
        }
        if (column == 'Rest') {
            return this.showBase ? 'RestBase' : 'Rest';
        }
        if (column == 'Transfert') {
            return this.showBase ? 'TransfertBase' : 'Transfert';
        }
        if (column == 'Consumption') {
            return this.showBase ? 'BaseConsumption' : 'WarehouseConsumption';
        }
        if (column == 'Unit') {
            return this.showBase ? 'BaseUnit' : 'WarehouseUnit';
        }
        return '';
    }

    whatColumn(item: any, column: string) {
        if (column == 'PrevInventory.Amount') {
            return item['PrevInventory']['Amount'];
        }
        else if (column == 'Inventory.Amount') {
            return item['Inventory']['Amount'];
        }
        else if (column == 'PrevInventory.Quantity') {
            return this.showBase ? item['PrevInventory']['QuantityBase'] : item['PrevInventory']['Quantity'];
        } else if (column == 'Inventory.Quantity') {
            return this.showBase ? item['Inventory']['QuantityBase'] : item['Inventory']['Quantity'];
        } else {
            if (column == 'Adjustments') {
                return this.showBase ? item['AdjustmentsBase'] : item['Adjustments'];
            }
            if (column == 'EffectiveConsumption') {
                return this.showBase ? item['EffectiveConsumptionBase'] : item['EffectiveConsumption'];
            }
            if (column == 'Price') {
                return this.showBase ? item['PriceBase'] : item['Price'];
            }
            if (column == 'Purchase') {
                return this.showBase ? item['PurchaseBase'] : item['Purchase'];
            }
            if (column == 'Rest') {
                return this.showBase ? item['RestBase'] : item['Rest'];
            }
            if (column == 'Transfert') {
                return this.showBase ? item['TransfertBase'] : item['Transfert'];
            }
            if (column == 'Consumption') {
                return this.showBase ? item['BaseConsumption'] : item['WarehouseConsumption'];
            }
            if (column == 'Unit') {
                return this.showBase ? item['Units']['BaseUnit'] : item['Units']['WarehouseUnit'];
            }
        }
        return '';
    }

    whatColumnName(column: string) {
        if (column == 'PrevInventory.Quantity') {
            return this.showBase ? 'PrevInventory.QuantityBase' : 'PrevInventory.Quantity';
        } else if (column == 'Inventory.Quantity') {
            return this.showBase ? 'Inventory.QuantityBase' : 'Inventory.Quantity';
        } else {
            if (column == 'Adjustments') {
                return this.showBase ? 'AdjustmentsBase' : 'Adjustments';
            }
            if (column == 'EffectiveConsumption') {
                return this.showBase ? 'EffectiveConsumptionBase' : 'EffectiveConsumption';
            }
            if (column == 'Price') {
                return this.showBase ? 'PriceBase' : 'Price';
            }
            if (column == 'Purchase') {
                return this.showBase ? 'PurchaseBase' : 'Purchase';
            }
            if (column == 'Rest') {
                return this.showBase ? 'RestBase' : 'Rest';
            }
            if (column == 'Transfert') {
                return this.showBase ? 'TransfertBase' : 'Transfert';
            }
            if (column == 'Consumption') {
                return this.showBase ? 'BaseConsumption' : 'WarehouseConsumption';
            }
            if (column == 'Unit') {
                return this.showBase ? 'Units.BaseUnit' : 'Units.WarehouseUnit';
            }
        }
        return '';
    }

    refreshColumnsVisibility() {
        for (let index = 0; index < this.columnsList.length; index++) {
            var column = this.table.column(index);
            if (column) column.visible(this.columnsList[index].enabled);
        }

        this.checkLeftColumns();
    }

    checkLeftColumns() {
        let leftColumns = 0;
        for (let index = 0; index < 3; index++) {
            leftColumns = this.columnsList[index].enabled ? leftColumns + 1 : leftColumns;
        }

        if (leftColumns !== this.dtOptions.fixedColumns.leftColumns) {
            setTimeout(() => {
                this.dtOptions.fixedColumns.leftColumns = leftColumns;
                this.table.columns.adjust().draw();
            });
        }
    }

    openColumnsDialog() {

        const columnsList = this.columnsList.filter((c: any) => {
            if (!this.showBase) {
                return !c.baseUnitCol || c.defaultUnitCol;
            } else {
                return c.baseUnitCol || !c.defaultUnitCol;
            }
        });

        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: { columnsList: columnsList },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success && res.columnsList) {
                //this.columnsList = res.columnsList;

                res.columnsList.forEach((c: any) => {
                    const index = this.columnsList.findIndex((i: any) => {
                        if (!this.showBase) {
                            return (!i.baseUnitCol || i.defaultUnitCol) && i.label == c.label;
                        } else {
                            return (i.baseUnitCol || !i.defaultUnitCol) && i.label == c.label;
                        }
                    });
                    this.columnsList[index] = c;
                });

                this.refreshColumnsVisibility();
            }
        });
    }

    goToAcquisti(consumption: any) {

        let filters = this.currentFilterCfg;
        filters['ProductOrCode'] = consumption.Code;
        delete filters['ValueZero'];

        this.router.navigate([`/magazzino/acquisti`], {
            queryParams: {
                filters: JSON.stringify(filters)
            }
        });
    }

    goToTrasferimenti(consumption: any) {

        let filters = this.currentFilterCfg;
        filters['ProductOrCode'] = consumption.Code;
        delete filters['ValueZero'];

        this.router.navigate([`/magazzino/trasferimenti`], {
            queryParams: {
                filters: JSON.stringify(filters)
            }
        });
    }

    goToSprechi(consumption: any) {

        let filters = this.currentFilterCfg;
        filters['ProductOrCode'] = consumption.Code;
        delete filters['ValueZero'];

        this.router.navigate([`/magazzino/sprechi`], {
            queryParams: {
                filters: JSON.stringify(filters)
            }
        });
    }

    async openMerce(item: any) {
        const merce = await this.gestioneMerciService.getSingleGoods(item.Goods.Id).toPromise();

        this.dialog
            .open(EditMerciComponent, {
                data: {
                    merce: merce,
                    readOnly: true
                },
                width: '100%',
                height: '100%'
            });
    }

    ckMovimentiMagazzino: boolean = false;
    public performGroupColumns(showColumns) {
        this.ckMovimentiMagazzino = showColumns;
        [7, 9, 10, 12, 14, 16, 18, 19, 20, 21].forEach((index: number) => {
            this.columnsList[index].enabled = showColumns;
        });
        // this.initDataTable();
        this.table.columns([7, 9, 10, 12, 14, 16, 18, 19, 20, 21]).visible(showColumns);
    }

    filterErrors(event: any, type: string) {
        this.currentFilterCfg[type] = event.checked;
        this.filtriService.filterConfig = this.currentFilterCfg;
        this.redrawTable();
    }

    redrawTable() {
        this.table.ajax.reload(() => {
            this.table.columns([1, 2]).nodes().each((cell, i) => {
                const rowData = this.table.row(i).data();
                const classColor = rowData && rowData.Err1 && this.currentFilterCfg['Err1Filter'] && this.showingErrors ? 'text-danger1' : (
                    rowData && rowData.Err2 && this.currentFilterCfg['Err2Filter'] && this.showingErrors ? 'text-danger2' : (
                        rowData && rowData.Err3 && this.currentFilterCfg['Err3Filter'] && this.showingErrors ? 'text-danger3' : ''
                    )
                );
                $(cell).find('.exportText').removeClass('text-danger1 text-danger2 text-danger3').addClass(classColor);
            });
        });
    }

    showingErrors: boolean = false;
    showErrors(show: boolean) {
        this.showingErrors = show;
        this.currentFilterCfg['Err1Filter'] = this.showingErrors;
        this.currentFilterCfg['Err2Filter'] = this.showingErrors;
        this.currentFilterCfg['Err3Filter'] = this.showingErrors;

        this.filtriService.filterConfig = this.currentFilterCfg;
        this.redrawTable();
    }

    showingImports: boolean = false;
    showImports() {

        if (!this.showingImports) {
            this.showingImports = true;
        } else {
            this.showingImports = false;
        }

        [4, 8, 11, 13, 15, 17].forEach((index: number) => {
            this.columnsList[index].enabled = this.showingImports;
        });

        this.table.columns([4, 8, 11, 13, 15, 17]).visible(this.showingImports);

        this.ref.detectChanges();
    }

    showingTotals: boolean = false;
    showTotals() {
        this.showingTotals = !this.showingTotals;
        if (this.showingTotals) {
            $('.totals').addClass('show');
        } else {
            $('.totals').removeClass('show');
        }
        this.table.columns.adjust().draw();
    }

    getTotal(column: string, perPrice: boolean = true): Number {
        if (!this.totals) return 0;
        const totalModel = this.whatColumnName(column);
        return totalModel ? this.totals[totalModel] : 0;
    }

    /****************************************************************************
      ______                       _
     |  ____|                     | |
     | |__  __  ___ __   ___  _ __| |_
     |  __| \ \/ / '_ \ / _ \| '__| __|
     | |____ >  <| |_) | (_) | |  | |_
     |______/_/\_\ .__/ \___/|_|   \__|
                 | |
                 |_|
     ****************************************************************************/


    exportAsXLSX() {
        this.table.button(1).trigger();
    }

    /******************************************************************************
        ______                       _     _____  _____  ______
       |  ____|                     | |   |  __ \|  __ \|  ____|
       | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
       |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
       | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
       |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
                   | |
                   |_|
      ******************************************************************************/

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('ANALISI_CONSUMI.TITLE') }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;
                //let columnsTot = this.columnsList.filter((item: any) => item.enabled).length;
                //this.pdfService.makePdf(result, this.getPDFTableBody(), Array(columnsTot).fill('auto'));

                this.PDFPreferences = result;
                this.table.button(0).trigger();
            };
        });

    }

}
