// custom-date-parser-formatter.ts
import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? `${this.padNumber(date.day)}/${this.padNumber(date.month)}/${date.year}`
      : '';
  }

  private padNumber(value: number): string {
    if (isNumber(value)) {
      return (`0${value}`).slice(-2);
    } else {
      return '';
    }
  }
}

function isNumber(value: any): value is number {
  return !isNaN(parseInt(`${value}`, 10));
}
