import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, tap } from 'rxjs/operators';

// Importa i servizi necessari
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoleService } from '@app/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import * as util from '@app/core/services/utilityfunctions';
import _ from 'lodash';
import { InsertNotesComponent } from '../edit-ricetta/insert-notes/insert-notes.component';

@Component({
  selector: 'kt-sostituisci-ingrediente-lite',
  templateUrl: './sostituisci-ingrediente-lite.component.html',
  styleUrls: ['./sostituisci-ingrediente-lite.component.scss']
})
export class SostituisciIngredienteLiteComponent implements OnInit {
  @ViewChild('inputSostituente', { static: true }) inputSostituente!: ElementRef;
  @ViewChild('instance', { static: true }) instance!: NgbTypeahead;

  private inputQuantity!: ElementRef;
  @ViewChild('inputQuantity', { static: false }) set _setInputQuantity(content: ElementRef) {
    if (content) {
      this.inputQuantity = content;
    }
  }

  daSostituire: any;
  sostituente = new FormControl();
  showAlert = false;
  addNew: boolean = false;
  newIngredients: any[] = [];
  inputChanged: Subject<any> = new Subject<any>();
  unitId: any;
  private focus$ = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<any>,
    private gestioneRicetteService: GestioneRicetteService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public roleService: RoleService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.daSostituire = this.data.item;
    this.addNew = !this.data.item || (this.data.mode && this.data.mode == 'add');

    this.inputChanged
      .pipe(
        debounceTime(1000),
      )
      .subscribe(model => {
        if (!model) return;
        this.calcoloSingoloIngrediente(model.item, model.changedColumn);
      });
  }

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    merge(text$, this.focus$).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term: any) => {
        return this._filter(term)
      })
    )

  // Implementa _filter che chiama il servizio per ottenere gli ingredienti disponibili
  private _filter(value: string): Observable<any[]> {
    const filterValue = value ? value.toLowerCase() : '';
    return this.gestioneRicetteService.getAvailableIngredients({ Name: filterValue }).pipe(
      map(results => {
        if (!filterValue) {
          return results.slice(0, 10); // Limita a 10 risultati
        }
        return results;
      })
    );
  }

  // Formattazione del risultato mostrato nel menu a tendina
  resultFormatter = (prod: any) => {
    return prod && prod.Goods
      ? `(${prod.Goods.Code}) ${prod.Goods.AlternateName}`
      : '';
  };

  // Formattazione per l'input: visualizza il testo selezionato
  inputFormatter = (prod: any) => {
    return prod && prod.Goods
      ? `(${prod.Goods.Code}) ${prod.Goods.AlternateName}`
      : '';
  };

  // Gestione dell'evento select item
  onSelectedOption(event: NgbTypeaheadSelectItemEvent) {
    event.item.Unit = this.showSelectUnit(event.item);
    this.unitId = event.item.Unit.Id;
    event.item.Quantity = 0;
    event.item.Waste = 0;
    event.item.QtaLorda = 0;
    this.sostituente.setValue(event.item);

    setTimeout(() => {
      this.inputQuantity.nativeElement.focus();
      this.inputQuantity.nativeElement.select();
    }, 100);

    this.ref.detectChanges();
  }

  triggerTypeahead(event: any) {
    this.focus$.next('');
  }

  onInputChanged(item: any, column: string) {
    // if item is string then return
    if (typeof item === 'string') return;
    this.inputChanged.next({
      item: item,
      changedColumn: column
    });
  }

  onUnitChanged(item: any) {
    item.Unit = this.unitaMisuraLst.find((u: any) => u.Id == this.unitId);
    this.sostituente.setValue(item);
    this.ref.detectChanges();
  }

  onSubmit() {
    const alreadyExistsIndex = this.sostituente.value && this.sostituente.value.Goods
      ? this.newIngredients.findIndex((item) => item.Goods.Id == this.sostituente.value.Goods.Id)
      : -1;
    if (alreadyExistsIndex === -1 && this.sostituente.value) {
      this.newIngredients.push(this.sostituente.value);
    }
    // Chiudi la finestra di dialogo e ritorna il body
    this.dialogRef.close({
      success: true,
      newIngredients: this.newIngredients
    });
  }

  onSaveAndRest() {
    const alreadyExistsIndex = this.newIngredients.findIndex((item) => item.Goods.Id == this.sostituente.value.Goods.Id);
    if (alreadyExistsIndex === -1 && this.sostituente.value) {
      this.newIngredients.push(this.sostituente.value);
      this.sostituente.setValue('');
    }
    this.ref.detectChanges();

    setTimeout(() => {
      this.inputSostituente.nativeElement.focus();
    }, 500);
  }

  somethingToSave() {
    return this.newIngredients && this.newIngredients.length > 0;
  }

  getTooltipSave() {
    if (!this.newIngredients || this.newIngredients.length === 0) {
      return "";
    }
    const tooltip = this.newIngredients.map((item) => item.Goods.AlternateName).join(', ');
    console.log(tooltip);
    return tooltip;
  }

  openPopupComplement(ingredient: any) {
    if (!this.roleService.isCompanyAdminOrMore()) return;

    const dialogRef = this.dialog.open(InsertNotesComponent, {
      data: {
        note: ingredient.Complement,
        title: this.translate.instant('GESTIONE_RICETTE.EDIT.COMPLEMENTS_POPUP.TITLE'),
        subtitle: this.translate.instant('GESTIONE_RICETTE.EDIT.COMPLEMENTS_POPUP.SUBTITLE')
      },
      width: '600px',
      panelClass: 'InsertNotesComponent'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success) {
        ingredient.Complement = res.Note;
        this.ref.detectChanges();
      }
    });
  }

  unitaMisuraLst: any;
  showSelectUnit(item: any) {
    if (item.Unit) {
      this.unitaMisuraLst = this.data.unit$.filter((u: any) => u.MainUnitId === item.Unit.BaseId);
    } else {
      this.unitaMisuraLst = this.data.unit$;
    }
    //this.sostituente.value.Unit = util.getBaseUnit(item, this.unitaMisuraLst);
    return util.getBaseUnit(item, this.unitaMisuraLst);
  }

  // Funzione per calcolare l'ingrediente singolo
  async calcoloSingoloIngrediente(ingredient: any, changedColumn: any = 'Quantity') {
    let wasteView = ingredient.Waste ? util.parseNumber(ingredient.Waste, this.data.locale) : 0;
    let qtaLorda = ingredient.QtaLorda ? util.parseNumber(ingredient.QtaLorda, this.data.locale) : 0;
    let qty = ingredient.Quantity ? util.parseNumber(ingredient.Quantity, this.data.locale) : 0;

    // Se Waste o Quantity
    switch (this.data.optScarto) {
      case 0:
        {
          switch (changedColumn) {
            case "QtaLorda":
              qty = qtaLorda / (1 + wasteView / 100);
              break;
            case "Quantity":
              qtaLorda = qty * (1 + wasteView / 100);
              break;
            case "Waste":
              qtaLorda = qty * (1 + wasteView / 100);
              break;
          }
        }
        break;
      case 1:
        {
          switch (changedColumn) {
            case "QtaLorda":
              qty = qtaLorda * (1 - wasteView / 100);
              break;
            case "Quantity":
              qtaLorda = qty / (1 - wasteView / 100);
              break;
            case "Waste":
              qtaLorda = qty / (1 - wasteView / 100);
              break;
          }
        }
        break;
      case 2:
        {
          //x= lordo * 100 / Netto;
          switch (changedColumn) {
            case "QtaLorda":
              qty = qtaLorda * 100 / wasteView;
              break;
            case "Quantity":
              qtaLorda = qty * wasteView / 100;
              break;
            case "Waste":
              qtaLorda = qty * wasteView / 100;
              break;
          }
        }
        break;
    }

    ingredient.Waste = util.formatNumber(wasteView, this.data.locale, 2, true);
    ingredient.Quantity = util.formatNumber(qty, this.data.locale, 2, true);
    ingredient.QtaLorda = util.formatNumber(qtaLorda, this.data.locale, 2, true);

    this.ref.detectChanges();
  }
}
