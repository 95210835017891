// Angular
import { AfterViewInit, Component, OnInit, ChangeDetectorRef } from '@angular/core';
// Layout
import { LayoutConfigService, ToggleOptions } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';

import { environment } from '@env/environment';

@Component({
	selector: 'kt-brand',
	templateUrl: './brand.component.html',
    styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit, AfterViewInit {
	// Public properties
	headerLogo!: string;
	headerStickyLogo!: string;
	currentUser:any;
	hasDashboard:boolean;

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-aside--minimize',
		togglerState: 'kt-aside__brand-aside-toggler--active'
	};

	version: string = environment.version;
    isNewUi: boolean = environment.newUI;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param htmlClassService: HtmlClassService 
	 */
	constructor(private layoutConfigService: LayoutConfigService, public htmlClassService: HtmlClassService, private ref: ChangeDetectorRef) {
    const currUser = localStorage.getItem('currentUser') || '';
		this.currentUser = JSON.parse(currUser);
		this.hasDashboard=this.currentUser.Permissions.Dashboard;
		this.htmlClassService.updateLogo$.subscribe((changed) => {
			if (changed) this.updateLogo();
		});

	}

	/**
	 * @ Lifecycle sequence(s: any) => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.headerLogo = this.layoutConfigService.getLogo();
		this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
	}

	/**
	 * On after view init
	 */
	ngAfterViewInit(): void {		
		setTimeout(() => this.updateLogo());
	}

	updateLogo() {		
		this.headerLogo = this.layoutConfigService.getLogo();
		// this.ref.detectChanges();
	}
}
