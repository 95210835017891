import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import * as util from '@app/core/services/utilityfunctions';
declare var $: any;

@Component({
    selector: 'm-export-pdf-sheet-dialog',
    templateUrl: './export-pdf-sheet-dialog.component.html'
})
export class ExportPdfSheetDialogComponent implements OnInit {
    viewLoading: boolean = false;
    selectedOrderMode: any;
    selectedCompany: any;
    selectedLayout: any;
    categorie: any;
    categorieMerciList: any;
    selectedFBType: string = '0';

    constructor(
        public dialogRef: MatDialogRef<ExportPdfSheetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        public staticCollections: StaticCollectionsService
    ) { }

    ngOnInit() {
        this.selectedOrderMode = 'Name';
        this.selectedCompany = '-';
        this.selectedLayout = '1';
        this.onFBTypeChange(this.selectedFBType);
    }

    initCateogriesSelect2() {

        this.categorie = [];
        setTimeout(() => {
            if ($('#select2Categories').data('select2')) {
                (<any>$('#select2Categories')).select2('destroy');
            }
            (<any>$('#select2Categories')).select2({
                placeholder: this.translate.instant('FILTRI.CATEGORIES_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            $('#select2Categories').on('select2:select', (e: any) => {
                this.categorie.push(e.params.data.id);
            });
            $('#select2Categories').on('select2:clear', (e: any) => {
                this.categorie = [];
            });
            $('#select2Categories').on('select2:unselect', (e: any) => {
                this.categorie = this.categorie.filter((categoria: any) => categoria != e.params.data.id);
            });

            setTimeout(() => {
                
                if (this.data.categorieIds) {
                    this.categorie = this.data.categorieIds;
                    (<any>$('#select2Categories')).val(this.data.categorieIds).trigger('change');
                } else {
                    this.categorie = [];
                    (<any>$('#select2Categories')).val([]).trigger('change');
                }

            }, 100);
        }, 100);
    }

    onFBTypeChange(value: string) {
        if (value == '0') {
            this.categorieMerciList = this.staticCollections.categorieMerci$;
        } else {
            this.categorieMerciList = this.staticCollections.categorieMerci$
                .filter((cat: any) => {
                    const fbTypeIndex = util.getFBTypeIndexByValue(cat.FBType);
                    return fbTypeIndex == Number(value)
                });

            if ($('#select2Categories').data('select2')) {
                (<any>$('#select2Categories')).select2('destroy');
            }
        }
        this.initCateogriesSelect2();
    }

    prepareResponse(confirm: boolean) {
        let response: any = {
            confirm: confirm,
            orderMode: this.selectedOrderMode,
            company: this.selectedCompany,
            layout: this.selectedLayout,
            categoryIds: this.categorie,
            fbType: this.selectedFBType
        };
        return response;
    }

    onYesClick(): void {
        this.viewLoading = true;
        this.dialogRef.close(this.prepareResponse(true));
    }

    onCloseClick(): void {
        this.dialogRef.close(undefined);
    }
}
