// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
// Layout
import { LayoutConfigService, TranslationService } from '@app/core/_base/layout';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
// Charts
import { Chart } from 'chart.js/dist/chart.min.js';
import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, take, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import * as util from '@app/core/services/utilityfunctions';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { isDate } from 'lodash';
import { AnalisiPrezziFornitoriDialogComponent } from '@app/views/partials/content/crud/analisi-prezzi-fornitori-dialog/analisi-prezzi-fornitori-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
  selector: 'kt-top-items',
  templateUrl: './top-items.component.html',
  styleUrls: ['./top-items.component.scss']
})
export class TopItemsComponent implements OnInit {

  utility: any;
  data: any;
  currentFilterCfg: any;



  // Public properties  
  @Input() entity!: string;
  @Input() entityLocalized!: string;

  @ViewChild('chart', { static: true }) chart!: ElementRef;

  MONTHS: any = ['', 'GENNAIO', 'FEBBRAIO', 'MARZO', 'APRILE', 'MAGGIO', 'GIUGNO', 'LUGLIO', 'AGOSTO', 'SETTEMBRE', 'OTTOBRE', 'NOVEMBRE', 'DICEMBRE']
  MONTHS_SHORT: any = ['', 'GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC']
  WEEKDAY: any = ['', 'LUNEDI\'', 'MARTEDI\'', 'MERCOLEDI\'', 'GIOVEDI\'', 'VENERDI\'', 'SABATO', 'DOMENICA']

  LIMIT_PRINT_INFOS: number = 25;
  PADDING_WITH_INFOS: number = 50;
  DEFAULT_PAGINATION_RECORD: number = 20;

  totQty!: number;
  totRev!: number;

  locale!: string;


  graphData: any;
  bestItems: number = this.DEFAULT_PAGINATION_RECORD;
  filtriServiceSubscription!: Subscription;
  translationServiceSubscription!: Subscription;

  currentView: string = 'rev';
  myChart;
  columnsList: any;

  maxValuesPerPageTmp: number;

  excludePagination: boolean = false;

  private subject: Subject<number> = new Subject();

  currentClickListener: any;

  pagination: any = {
    arrayIndex: 0,
    maxPerPage: this.DEFAULT_PAGINATION_RECORD,
    totRecords: 0
  };

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private filtriService: FiltriService,
    private translate: TranslateService,
    private magazzinoService: MagazzinoService,
    private ref: ChangeDetectorRef,
    private translationService: TranslationService,
    private excelService: ExcelService,
    private pdfService: PDFService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    public dialog: MatDialog,
    public loaderService: LoaderService
  ) {
    this.maxValuesPerPageTmp = this.pagination.maxPerPage;
    this.utility = util;
    this.translationServiceSubscription = this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
      if (this.myChart) {
        this.initChartJS();
      }
    });

    this.columnsList = [
      { label: '', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];

    // this.entity = 'Purchase';
    // this.entityLocalized = this.translate.instant('MAGAZZINO.STATISTICHE.WAREHOUSE_SELECTOR.ACQUISTI');
  }

  ngOnDestroy() {
    this.myChart = undefined;
    if (this.filtriServiceSubscription) this.filtriServiceSubscription.unsubscribe();
    if (this.translationServiceSubscription) this.translationServiceSubscription.unsubscribe();
  }

  /**
* On init
*/
  ngOnInit() {

    this.filtriServiceSubscription = this.filtriService.performFilter$
      .subscribe(
        (filterConfig: any) => {

          if ((filterConfig.constructor !== Object) || (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object)) {
            return
          };
          this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

          this.getData({
            model: this.entity,
            localized: this.entityLocalized
          });

        }
      );

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.refreshTopSeller();
    });
  }

  getData(selector: any) {
    if (!this.currentFilterCfg) this.currentFilterCfg = {};

    this.loaderService.show();

    this.currentFilterCfg['Start'] = this.pagination.arrayIndex;
    this.currentFilterCfg['Length'] = this.pagination.maxPerPage;

    lastValueFrom(this.magazzinoService.getWarehouseTopItems(selector.model, this.currentFilterCfg))
      .then((ret: any) => {
        this.data = ret;

        if (!this.data || this.data.length == 0) return;

        this.pagination.totRecords = this.data.TotRows;
        // quando i risultati sono inferiori al maxPerPage, allora il maxPerPage diventa il totale dei risultati
        if (this.pagination.totRecords < this.pagination.maxPerPage) {
          this.maxValuesPerPageTmp = this.pagination.totRecords;
        }

        this.refreshGraph();
      })
      .finally(() => {
        this.loaderService.hide();
      });

  }

  refreshTopSeller() {
    this.loaderService.show();
    let currentFilter = this.filtriService.filterConfig;
    //currentFilter['BestItems'] = this.bestItems;
    lastValueFrom(this.magazzinoService.getWarehouseTopItems(this.entity, currentFilter))
      .then((data: any) => {

        if (!data || data.length == 0) return;

        this.data = JSON.parse(JSON.stringify(data));
        this.refreshGraph();
      })
      .finally(() => {
        this.loaderService.hide();
      });
  }

  excludeGraphPagination(event: any) {
    this.excludePagination = event.checked;
    this.pagination.maxPerPage = this.pagination.totRecords;
    if (this.excludePagination) {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.pagination.totRecords;
      this.myChart.config.options.scales.x.ticks.padding = 0;
    } else {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.pagination.maxPerPage - 1;
      this.myChart.config.options.scales.x.ticks.padding = this.PADDING_WITH_INFOS;
      this.pagination.maxPerPage = this.DEFAULT_PAGINATION_RECORD;
    }
    this.maxValuesPerPageTmp = this.pagination.maxPerPage;
    this.getData({
      model: this.entity,
      localized: this.entityLocalized
    });
  }

  onKeyUpMaxValues(event: KeyboardEvent) {
    if (event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.pagination.maxPerPage = parseInt(this.pagination.maxPerPage);
      this.pagination.arrayIndex = 0;
      this.maxValuesPerPageTmp = this.pagination.maxPerPage;
      this.getData({
        model: this.entity,
        localized: this.entityLocalized
      });
    }
  }

  getTotRev() {
    return this.utility.formatNumber(this.totRev, this.locale, 2, true, true);
  }

  getTotQty() {
    return this.utility.formatNumber(this.totQty, this.locale, 2, true, true);
  }

  onKeyUp() {
    this.subject.next(this.bestItems);
  }

  switchView(view: string) {
    this.currentView = view;
    this.refreshGraph();
  }

  refreshGraph() {
    let labels: any = [];
    let dataQuantita: any = [];
    let dataUnit: any = [];
    let dataTotRevenue: any = [];
    let productIds: any[] = [];

    let elencoDati = JSON.parse(JSON.stringify(this.data.Items));

    switch (this.currentView) {
      case 'rev':
        elencoDati = elencoDati.sort((a, b) => {
          if (a.ActualValue.GrossRevenue < b.ActualValue.GrossRevenue) return 1;
          else if (a.ActualValue.GrossRevenue > b.ActualValue.GrossRevenue) return -1;
          else return 0;
        });
        break;
      case 'qty':
        elencoDati = elencoDati.sort((a, b) => {
          if (a.ActualValue.Quantity < b.ActualValue.Quantity) return 1;
          else if (a.ActualValue.Quantity > b.ActualValue.Quantity) return -1;
          else return 0;
        });
        break;
    }

    elencoDati.forEach((record: any) => {
      labels.push(record.Name.trim());

      if (record.ActualValue) {
        dataUnit.push(record.ActualValue.Unit);
        dataQuantita.push(parseFloat(record.ActualValue.Quantity));
        dataTotRevenue.push(parseFloat(record.ActualValue.GrossRevenue).toFixed(2));
        if (record.ActualValue.PriceMaxInfo && record.ActualValue.PriceMaxInfo.length > 0) {
          productIds.push(record.ActualValue.PriceMaxInfo[0].GoodId)
        } else {
          productIds.push(undefined);
        }
      }

    });

    const datasetQty = {
      type: 'bar',
      label: this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.QUATITY_LEGEND'),
      backgroundColor: this.layoutConfigService.getConfig('colors.state.primary'),
      data: dataQuantita,
      units: dataUnit,
      productIds: productIds,
      yAxisID: "y-axis-quantity",
      tipo: 'qty'
    };

    const datasetRev = {
      type: 'bar',
      label: this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.GROSSREVENUE_LEGEND', { entity: this.entityLocalized }),
      backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset1'),
      data: dataTotRevenue,
      productIds: productIds,
      yAxisID: "y-axis-revenue",
      tipo: 'rev'
    };

    this.graphData = {
      labels: labels,
      datasets: []
    };

    switch (this.currentView) {
      case 'rev':
        this.graphData.datasets.push(datasetRev);
        this.graphData.datasets.push(datasetQty);
        break;
      case 'qty':
        this.graphData.datasets.push(datasetQty);
        this.graphData.datasets.push(datasetRev);
        break;
    }

    this.totQty = 0;
    this.totRev = 0;

    this.graphData.datasets.forEach((dataset: any) => {
      dataset.data.forEach((data: any) => {
        (dataset.tipo === 'rev') ? this.totRev += parseFloat(data) : this.totQty += parseFloat(data);
      });
    });

    this.initChartJS();
  }

  formatNumber(x: any, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
    return this.utility.formatNumber(x, this.locale, decimal, showZero, currency);
  }

  /** Init chart */
  initChartJS() {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    if (this.myChart) {
      this.myChart.destroy();
    }
    const moveChart = {
      id: 'moveChart',
      afterEvent: (chart: any, args: any) => {
        if (this.excludePagination || !chart || !chart.chartArea) return;

        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        canvas.addEventListener('mousemove', (event) => {
          const x = args.event.x;
          const y = args.event.y;

          if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const leftEnabled = this.pagination.arrayIndex - this.pagination.maxPerPage >= 0;
            canvas.style.cursor = leftEnabled ? 'pointer' : 'not-allowed';
          } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const dataLength = this.pagination.totRecords;
            const rightEnabled = this.pagination.arrayIndex + this.maxValuesPerPageTmp < dataLength;
            canvas.style.cursor = rightEnabled ? 'pointer' : 'not-allowed';
          } else {
            canvas.style.cursor = 'default';
          }
        });
      },
      afterDraw: (chart: any, args: any, pluginOptions: any) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        if (this.excludePagination) {

          // Scrivo il totale dei risultati
          ctx.beginPath();
          const rect = canvas.getBoundingClientRect();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem 'Barlow Condensed'`;
          const pagination = `${chart.config.data.labels.length} ${this.translate.instant('COMMONS.RESULTS')}`;
          const textWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textWidth, top - 12);
          ctx.closePath();

        } else {

          class CircleChevron {
            draw(ctx, x1, pixel, enabled: boolean = true) {
              const angle = Math.PI / 180;

              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = 'rgba(102, 102, 102, .5)';
              ctx.fillStyle = 'white';
              ctx.arc(x1, height / 2 + top, 10, angle * 0, angle * 360, false);
              ctx.stroke();
              ctx.fill();
              ctx.closePath();

              // chevron Arrow
              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = enabled ? '#5867DD' : '#CCCCCC';
              ctx.moveTo(x1 + pixel, height / 2 + top - 5.5);
              ctx.lineTo(x1 - pixel, height / 2 + top);
              ctx.lineTo(x1 + pixel, height / 2 + top + 5.5);
              ctx.stroke();
              ctx.closePath();
            }
          }

          const leftEnabled = this.pagination.arrayIndex - this.pagination.maxPerPage >= 0
          let drawCircleLeft = new CircleChevron();
          drawCircleLeft.draw(ctx, left, 3, leftEnabled);

          const dataLength = this.pagination.totRecords;
          const rightEnabled = this.pagination.arrayIndex + this.pagination.maxPerPage < dataLength;
          let drawCircleRight = new CircleChevron();
          drawCircleRight.draw(ctx, right, -3, rightEnabled);

          // Scrivo la paginazione
          ctx.beginPath();
          const rect = canvas.getBoundingClientRect();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem 'Barlow Condensed'`;
          const pagination = `${this.translate.instant('EXPORT_PDF.PAGE')} ${(this.pagination.arrayIndex / this.pagination.maxPerPage) + 1} ${this.translate.instant('EXPORT_PDF.OF')} ${Math.ceil(this.pagination.totRecords / this.pagination.maxPerPage)}`;
          const textWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textWidth, top - 12);
          ctx.closePath();
        }
      }
    }

    this.myChart = new Chart(this.chart.nativeElement, {
      type: 'bar',
      data: this.graphData,
      plugins: [
        moveChart,
        {
          afterDraw: (chart: any) => {
            const ctx = this.chart.nativeElement.getContext('2d');

            // Scrivo la frase in alto a destra sul grafico per indicare che si può cliccare sul grafico
            ctx.font = `400 1rem 'Barlow Condensed'`;
            const x = chart.scales['x'].left;
            const y = chart.scales['y-axis-revenue'].top - 10;
            const label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.CONTEXT_HINT');
            ctx.fillText(`${label}`, x, y);

            //********************************** */

            const rev = chart.config.data.datasets.find((record: any) => record.tipo === 'rev');
            const qty = chart.config.data.datasets.find((record: any) => record.tipo === 'qty');

            if (!rev || rev.data.length > this.LIMIT_PRINT_INFOS || this.excludePagination) return;

            const min = chart.scales['x'].min;
            const max = chart.scales['x'].max;

            const endIteration = (min + this.maxValuesPerPageTmp - 1) > max ? max - min : this.maxValuesPerPageTmp;

            for (let index = 0; index < endIteration; index++) {
              let x = chart.scales['x'].getPixelForTick(index);
              let y = chart.scales['y-axis-revenue'].bottom;

              // let fontSize = Object.keys(chart.scales['x'].longestTextCache)[0];
              // fontSize = fontSize.split(" ")[1].replace("px", "");              

              ctx.fillStyle = "#505050";
              ctx.font = `500 1rem 'Barlow Condensed'`;

              const revenue = parseFloat(rev.data[min + index]);
              const quantity = parseFloat(qty.data[min + index]);

              const offsetX = 0
              ctx.fillStyle = qty.backgroundColor;
              if (!qty.hidden) {
                const unit = qty.units && qty.units.length > 0 && qty.units[min + index] ? qty.units[min + index] : '';
                const label = this.formatNumber(quantity, 0);
                const textWidth = ctx.measureText(`${label} ${unit}`).width;
                ctx.fillText(`${label} ${unit}`, x - (textWidth / 2), y + 17);
              }
              ctx.fillStyle = rev.backgroundColor;
              if (!rev.hidden) {
                const label = this.formatNumber(revenue, 2);
                const textWidth = ctx.measureText(label).width;
                ctx.fillText(`${label}`, x - (textWidth / 2), y + 32);
              }

              ctx.fillStyle = '#000000';
              const label = this.formatNumber(revenue / quantity, 2, true, true);
              const textWidth = ctx.measureText(label).width;
              ctx.fillText(`${label}`, x - (textWidth / 2), y + 47);
            }
          }
        }],
      options: {
        name: 'TopItems',
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            bodyFont: {
                              family:  "Barlow Condensed",
                weight: '500',
                size: 14

            },
            titleFont: {
                              family:  "Barlow Condensed",
                weight: '500',
                size: 14

            },
            footerFont: {
                              family:  "Barlow Condensed",
                weight: '500',
                size: 14

            },
            //intersect: false,
            //mode: 'nearest',
            padding: 10,
            caretPadding: 10,
            callbacks: {
              afterTitle: (tooltipItem) => {
                return tooltipItem[0].dataset.label;
              },
              label: (tooltipItem) => {
                //console.log(tooltipItem, this.currentView);
                switch (this.currentView) {
                  case 'rev':
                    switch (tooltipItem.datasetIndex) {
                      case 0: // Totale Acquisti
                        return this.formatNumber(parseFloat(tooltipItem.raw), 2, true, true);
                        break;
                      case 1: // Quantità
                        return this.formatNumber(parseFloat(tooltipItem.raw), 0) + tooltipItem.chart.data.datasets[tooltipItem.datasetIndex].units[tooltipItem.dataIndex];
                        break;
                    }
                    break;
                  case 'qty':
                    switch (tooltipItem.datasetIndex) {
                      case 0: // Quantità
                        return this.formatNumber(parseFloat(tooltipItem.raw), 0) + tooltipItem[0].chart.data.datasets[tooltipItem.datasetIndex].units[tooltipItem.dataIndex];
                        break;
                      case 1: // Totale Acquisti
                        return this.formatNumber(parseFloat(tooltipItem.raw), 2, true, true);
                        break;
                    }
                    break;
                }
              }
            }
          },
          legend: {
            labels: {
              font: {
                                family:  "Barlow Condensed",
                weight: '500',
                size: 14

              }
            },
            display: true,
            position: 'bottom',
            onClick: (e, legendItem) => {
              var index = legendItem.datasetIndex;
              var ci = this.myChart;
              var meta = ci.getDatasetMeta(index);

              // See controller.isDatasetVisible comment
              meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

              this.myChart.config.data.datasets[index].hidden = meta.hidden;

              // We hide a dataset ... rerender the chart
              ci.update();

              this.ref.detectChanges();
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          x: {
            min: 0,
            max: this.pagination.maxPerPage - 1,
            display: true,
            grid: {
              display: false
            },
            stacked: false,
            ticks: {
              font: {
                                family:  "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: this.pagination.maxPerPage > this.LIMIT_PRINT_INFOS || this.excludePagination ? 0 : this.PADDING_WITH_INFOS,
              callback: (value: any, index: number, values: any) => {
                const overrideValue = this.graphData.labels[value];
                return overrideValue.length > 15 ? overrideValue.substring(0, 15) + '...' : overrideValue;
              }
            }
          },
          "y-axis-revenue": {
            position: 'left',
            display: true,
            stacked: false,
            ticks: {
              font: {
                                family:  "Barlow Condensed",
                weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                if (parseFloat(value) > 0)
                  return this.utility.kFormatter(value, this.locale);
              }
            },
            title: {
              font: {
                                family:  "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.REVENUE_Y', { entity: this.entityLocalized })
            },
            beginAtZero: true,
          },
          "y-axis-quantity": {
            position: 'right',
            display: true,
            stacked: false,
            ticks: {
              font: {
                                family:  "Barlow Condensed",
                weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                return (parseFloat(value).toFixed(0)).replace(new RegExp('000$'), 'K');
              }
            },
            title: {
              font: {
                                family:  "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.QUANTITY_Y')
            },
            beginAtZero: true,
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 18,
            bottom: 0
          }
        },
        onHover: (event: any, chartElement: any) => {
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        onClick: (chart: any, element: any, a, b, c) => {

          if ($('#chartMenu').is(':visible')) {
            $('#chartMenu').hide();
          } else {

            $('#chartMenu').css('left', chart.chart.tooltip.x);
            $('#chartMenu').css('top', chart.chart.tooltip.y);
            $('#chartMenu').show();

            $('#chartMenu').one('click', 'li', (ev: any) => {
              const choice = ev.target.id;

              if (choice == 'purchases' && element && element[0].datasetIndex == 0) {
                this.router.navigate([`/magazzino/acquisti`], { queryParams: { ProductOrCode: this.graphData.labels[element[0].index] } });
                $('#chartMenu').hide();
              } else if (choice == 'pricegraph') {

                console.log('element', element, this.graphData);


                const dialogRef = this.dialog.open(AnalisiPrezziFornitoriDialogComponent, {
                  data: {
                    ProductId: this.graphData.datasets[0].productIds[element[0].index]
                  },
                  width: '100%',
                  height: '800px'
                });

                $('#chartMenu').hide();

              }
            });

          }


        }
      }
    });

    const moveScroll = () => {
      // Rimuove il listener precedente per prevenire la duplicazione
      this.myChart.canvas.removeEventListener('click', this.currentClickListener);


      this.currentClickListener = (event) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = this.myChart;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        let refreshData = false;
        if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
          this.pagination.arrayIndex -= this.pagination.maxPerPage;
          refreshData = true;
        } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
          this.pagination.arrayIndex += this.pagination.maxPerPage;
          refreshData = true;
        }
        if (refreshData) {
          this.getData({
            model: this.entity,
            localized: this.entityLocalized
          });
        }

      };

      // Aggiunge il nuovo listener
      this.myChart.canvas.addEventListener('click', this.currentClickListener);
    }

    this.myChart.ctx.onclick = moveScroll();
  }


  showTableResult: boolean = false;
  exportTableRows: any;
  prepareShowTableResult() {
    this.showTableResult = !this.showTableResult;
    this.exportTableRows = [];
    if (this.showTableResult) {
      var elencoDati;
      switch (this.currentView) {
        case 'rev':
          elencoDati = this.data.Items.sort((a, b) => {
            if (a.ActualValue.GrossRevenue < b.ActualValue.GrossRevenue) return 1;
            else if (a.ActualValue.GrossRevenue > b.ActualValue.GrossRevenue) return -1;
            else return 0;
          });
          break;
        case 'qty':
          elencoDati = this.data.Items.sort((a, b) => {
            if (a.ActualValue.Quantity < b.ActualValue.Quantity) return 1;
            else if (a.ActualValue.Quantity > b.ActualValue.Quantity) return -1;
            else return 0;
          });
          break;
      }

      this.data.Items.forEach((item: any) => {
        console.log(item);

        let revenue = item.ActualValue.GrossRevenue;
        let quantity = item.ActualValue.Quantity;
        let unit = item.ActualValue.Unit;

        this.exportTableRows.push([
          item.Name,
          revenue,
          quantity,
          unit,
          // revenue/quantity
        ]);
      });
    }

  }

  /******************************************************************************
    ______                       _    __   ___       _______   __
   |  ____|                     | |   \ \ / / |     / ____\ \ / /
   | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
   |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
   | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
   |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
  ******************************************************************************/

  exportAsXLSX() {
    this.loaderService.show();

    let xlsx: any[] = [];
    let merges: any[] = [];
    let i: number = 0;

    let rowsStyles: any = [];
    let elencoDati;
    let col1Label, col2Label;
    if (this.currentView == 'rev') {
      col1Label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.REV');
      col2Label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.QTY');
      elencoDati = this.data.Items.sort((a, b) => {
        if (a.ActualValue.GrossRevenue < b.ActualValue.GrossRevenue) return 1;
        else if (a.ActualValue.GrossRevenue > b.ActualValue.GrossRevenue) return -1;
        else return 0;
      });
    } else {
      col1Label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.QTY');
      col2Label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.REV');
      elencoDati = this.data.Items.sort((a, b) => {
        if (a.ActualValue.Quantity < b.ActualValue.Quantity) return 1;
        else if (a.ActualValue.Quantity > b.ActualValue.Quantity) return -1;
        else return 0;
      });
    }

    // Per i totali
    rowsStyles[1] = {
      fgColor: { rgb: 'ffdd45' },
      bold: true,
      alignment: { wrapText: true }
    };

    // IL BODY
    let xlsxBody: any[] = [];
    elencoDati.forEach((item: any) => {
      rowsStyles.push({
        sz: 10
      });

      let columns: any = {};

      let revenue = item.ActualValue.GrossRevenue;
      let quantity = item.ActualValue.Quantity;
      let unit = item.ActualValue.Unit;

      columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.PRODUCT')}`] = item.Name;
      columns[`${col1Label}`] = this.currentView == 'rev' ? this.utility.formatNumberExcel(revenue, 2) : this.utility.formatNumberExcel(quantity, 0);
      columns[`${col2Label}`] = this.currentView == 'rev' ? this.utility.formatNumberExcel(quantity, 0) : this.utility.formatNumberExcel(revenue, 2);
      columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.UNIT')}`] = unit;
      // columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.AVG')}`] = this.utility.formatNumberExcel(revenue / quantity, 2);

      xlsxBody.push(columns);
      i++;
    });

    // TOTALI
    let columns: any = {};

    columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.PRODUCT')}`] = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.TOTALS');
    columns[`${col1Label}`] = this.currentView == 'rev' ? this.utility.formatNumberExcel(this.totRev, 2) : this.utility.formatNumberExcel(this.totQty, 0);
    columns[`${col2Label}`] = this.currentView == 'rev' ? this.utility.formatNumberExcel(this.totQty, 0) : this.utility.formatNumberExcel(this.totRev, 2);
    columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.UNIT')}`] = "";
    // columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.AVG')}`] = this.utility.formatNumberExcel(this.totRev / this.totQty, 2);

    xlsx.push(columns);

    xlsx = xlsx.concat(xlsxBody);

    this.loaderService.hide();
    this.excelService.exportAsExcelFile(xlsx, this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.EXPORT_TITLE'), merges, this.columnsList, 1, rowsStyles, true);
  }

  /******************************************************************************
    ______                       _     _____  _____  ______
   |  ____|                     | |   |  __ \|  __ \|  ____|
   | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
   |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
   | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
   |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
               | |
               |_|
  ******************************************************************************/

  exportAsPDF() {

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
    };

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result['header'] = {
          export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.EXPORT_TITLE') }),
          period: this.filtriService.getCurrentPeriod()
        }
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        }
        result['language'] = this.translationService.getSelectedLanguage();
        result['table'].headerRows = 1;
        result['orientation'] = 'portrait';
        let columnsTot = this.columnsList.filter((item: any) => item.enabled).length;

        let pdfTableBody = this.getPDFTableBody();
        this.pdfService.makePdf(result, pdfTableBody, Array(columnsTot).fill('auto'));
      };
    });

  }

  getPDFTableBody() {

    let elencoDati;
    let col1Label, col2Label;
    if (this.currentView == 'rev') {
      col1Label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.REV');
      col2Label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.QTY');
      elencoDati = this.data.Items.sort((a, b) => {
        if (a.ActualValue.GrossRevenue < b.ActualValue.GrossRevenue) return 1;
        else if (a.ActualValue.GrossRevenue > b.ActualValue.GrossRevenue) return -1;
        else return 0;
      });
    } else {
      col1Label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.QTY');
      col2Label = this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.REV');
      elencoDati = this.data.Items.sort((a, b) => {
        if (a.ActualValue.Quantity < b.ActualValue.Quantity) return 1;
        else if (a.ActualValue.Quantity > b.ActualValue.Quantity) return -1;
        else return 0;
      });
    }

    let body: any = [];
    let tmpRow: any = [];

    // aggiungo intestazione
    // Header
    tmpRow = [
      { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${col1Label}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${col2Label}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.UNIT')}`, style: 'tableHeaderStyle' },
      // { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.AVG')}`, style: 'tableHeaderStyle' },
    ];
    body.push(tmpRow.filter((item: any) => item.visible));


    let i: number = 0;
    let totFc: number = 0;
    let bodyTmp: any = [];
    elencoDati.forEach((item: any) => {

      let revenue = item.ActualValue.GrossRevenue;
      let quantity = item.ActualValue.Quantity;
      let unit = item.ActualValue.Unit;

      tmpRow = [
        { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Name.toUpperCase(), style: 'tableBodyStyle' },
        { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.currentView == 'rev' ? this.utility.formatNumber(revenue, 2) : this.utility.formatNumber(quantity, 0), style: 'tableBodyStyle' },
        { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.currentView == 'rev' ? this.utility.formatNumber(quantity, 0) : this.utility.formatNumber(revenue, 2), style: 'tableBodyStyle' },
        { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: unit, style: 'tableBodyStyle' },
        // { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.utility.formatNumber(revenue / quantity, this.locale, 2), style: 'tableBodyStyle' }
      ];
      bodyTmp.push(tmpRow.filter((item: any) => item.visible));
      i++;

    });

    // totali Header
    tmpRow = [
      { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.STATISTICHE.TOP_ITEMS.COLUMNS.TOTALS')}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.currentView == 'rev' ? this.utility.formatNumber(this.totRev, 2) : this.utility.formatNumber(this.totQty, 0), style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.currentView == 'rev' ? this.utility.formatNumber(this.totQty, 0) : this.utility.formatNumber(this.totRev, 2), style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: '', style: 'tableHeaderStyle' }
    ];
    body.push(tmpRow.filter((item: any) => item.visible));


    body = body.concat(bodyTmp);

    return body;
  }

}
