import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { SupportService } from './support.service';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { Router } from '@angular/router';
import { SwitchGroupService } from '@app/core/_base/layout';
import * as util from '@app/core/services/utilityfunctions';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

    issues: any;
    issuesFiltered: any;
    
    hoverglassActive: boolean = false;
    table: any;
    status: string = '';
    switchGroupSubcription!: Subscription;
    selectedEndpoints: any = undefined;
    endpointList: any;

    @ViewChild('globalFilter', { static: true }) globalFilter!: ElementRef;

    constructor(
        private supportService: SupportService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        public roleService: RoleService,
        private router: Router,
        private switchGroupService: SwitchGroupService,
        public loaderService: LoaderService
    ) {
        document.body.classList.add('kt-aside--minimize');
    }

    async ngOnInit() {

        $.extend((<any>$.fn).dataTableExt.oSort, {
            "datalocalized-desc": (a, b) => {
                try {
                    const aDates = a.split('/');
                    const aParsed = parseInt(aDates[2] + aDates[1] + aDates[0]);
                    const bDates = b.split('/');
                    const bParsed = parseInt(bDates[2] + bDates[1] + bDates[0]);
                    return ((aParsed < bParsed) ? 1 : ((aParsed > bParsed) ? -1 : 0));
                } catch (error) {
                    return 0;
                }
            },
            "datalocalized-asc": (a, b) => {
                try {
                    const aDates = a.split('/');
                    const aParsed = parseInt(aDates[2] + aDates[1] + aDates[0]);
                    const bDates = b.split('/');
                    const bParsed = parseInt(bDates[2] + bDates[1] + bDates[0]);
                    return ((aParsed < bParsed) ? -1 : ((aParsed > bParsed) ? 1 : 0));
                } catch (error) {
                    return 0;
                }
            }
        });

        fromEvent(this.globalFilter.nativeElement, 'keydown').subscribe(
            (event: any) => {
                if (event.keyCode === 13) {
                    this.filter();
                }
            }
        );

        $.fn.dataTable.ext.search.push(
            (settings, data, dataIndex) => {

                var colTicket = data[0];
                var colData = data[1];
                var colTitle = data[2];
                var colStatus = data[3];

                const globalFilter = this.globalFilter.nativeElement.value;
                const status = this.status;

                let findGlobalFilter: boolean = true,
                    findStatus: boolean = true;

                if (globalFilter) {
                    findGlobalFilter = colTicket.includes(globalFilter) ||
                        colData.includes(globalFilter) ||
                        colTitle.toLowerCase().includes(globalFilter.toLowerCase()) ||
                        colStatus.toLowerCase().includes(globalFilter.toLowerCase());
                }

                if (status) {
                    findStatus = colStatus.toLowerCase() == status.toLowerCase();
                }

                return findGlobalFilter && findStatus;

            }
        );

        this.getIssues();
    }

    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {

                    if ($.fn.dataTable.isDataTable('#table')) {
                        $('#table').DataTable().clear().destroy();
                    }

                    this.getIssues();
                }
            }
        );

        this.selectedEndpoints = undefined;
    }

    getIssues() {
        this.loaderService.show();

        this.supportService.getAllIssues()
            .toPromise().then((result: any) => {
                this.endpointList = [];
                result.issues.forEach((issue: any) => {
                    const cfAuthor = issue.custom_fields.find((cf: any) => cf.field.name == 'ExternalUser')
                    const author = cfAuthor ? cfAuthor.value : undefined;

                    const cfEndpoint = issue.custom_fields.find((cf: any) => cf.field.name == 'Endpoint')
                    const endpoint = cfEndpoint ? cfEndpoint.value : undefined;

                    if(!this.endpointList.map((endpoint: any) => endpoint.name).includes(endpoint)) {
                        this.endpointList.push({name: endpoint});
                    }

                    issue.author = author;
                    issue.endpoint = endpoint;
                });

                this.issues = result.issues;
                this.issuesFiltered = JSON.parse(JSON.stringify(result.issues));
                this.initTable();
            })
            .catch((err: any) => {
                console.log('error', err);
            })
            .finally(() => this.loaderService.hide())
    }

    initTable() {
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        this.hoverglassActive = true;
        setTimeout(() => {
            let dtOptions: any = {
                initComplete: () => {
                    this.hoverglassActive = false;
                    this.ref.detectChanges();
                },
                dom: 'lrtip',
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
                },
                paging: false,
                searching: true,
                ordering: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                columnDefs: [
                    { targets: [0], width: '60px', orderable: true }, // ID
                    { targets: [1], width: '100px', orderable: true, type: 'datalocalized' }, // Data Creazione
                    { targets: [2], orderable: true }, // Summary
                    { targets: [3], visible: this.roleService.isSuperAdmin() || this.roleService.isCompanyAdminOrMore }, // EndPoint
                    { targets: [4], visible: this.roleService.isSuperAdmin() || this.roleService.isCompanyAdminOrMore }, // Author
                    { targets: [5], width: '100px', orderable: true }, // State
                    { targets: [6], width: '150px', orderable: true, type: 'datalocalized' }, // Data Ultima modifica
                ],
                autoWidth: true,
                aaSorting: [[0, 'desc']]
            };
            this.table = $('#table').DataTable(dtOptions);
            // Mi collego all'evento di loading della tabella
            this.table.off('processing.dt');
            this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
                if (processing) {
                    this.loaderService.show();
                } else {
                    this.loaderService.hide();
                }
                this.ref.detectChanges();
            });
        }, 100);

    }

    newTicket() {
        this.router.navigateByUrl('/support/edit-issue');
    }

    openTicketDialog(issue: any) {
        this.router.navigate(['/support/edit-issue'], { queryParams: { issue: issue.id } });
    }

    filter() {
        // Check riceca libera
        let filtered: boolean = false;
        if(this.globalFilter.nativeElement.value) {
            this.issuesFiltered = this.issues.filter((issue: any) => {
                const cfAuthor = issue.custom_fields.find((cf: any) => cf.field.name == 'ExternalUser')
                const author = cfAuthor ? cfAuthor.value : undefined;

                return issue.summary.indexOf(this.globalFilter.nativeElement.value) >= 0 || 
                author.indexOf(this.globalFilter.nativeElement.value) >= 0

            });

            filtered = true;
        }
        // Check Status
        if (this.status) {
            const model = filtered ? this.issuesFiltered : this.issues;
            this.issuesFiltered = model.filter((issue: any) => {
                return issue.status.name == this.status;
            });
            filtered = true;
        }
        // Check Endpoint
        if (this.selectedEndpoints && this.selectedEndpoints.length >0 ) {
            const model = filtered ? this.issuesFiltered : this.issues;
            this.issuesFiltered = model.filter((issue: any) => {
                const cfEndpoint = issue.custom_fields.find((cf: any) => cf.field.name == 'Endpoint')
                const endpoint = cfEndpoint ? cfEndpoint.value : undefined;

                return this.selectedEndpoints.includes(endpoint);
            });
            filtered = true;
        }

        this.issuesFiltered = filtered ? this.issuesFiltered : JSON.parse(JSON.stringify(this.issues));
        
        this.ref.detectChanges();
    }

    hasAnswered(issue: any) {
        if (issue.status == 'new' || issue.status.name == 'closed') return '';
        if (issue.notes && issue.notes.length > 0) {
            return ', ' + this.translate.instant('SUPPORT.ANSWERED');
        }
        return '';
    }
}
