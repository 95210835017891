import { ViewEncapsulation, Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { FiltriService } from '@app/core/services/filtri.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { AnalisiVenditeService } from '@app/core/services/analisi-vendite.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';

// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels)

import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin)

import * as util from '@app/core/services/utilityfunctions';
import * as _ from 'lodash';

import { MatDialog } from '@angular/material/dialog';

import { EditRicettaComponent } from '@app/views/pages/gestione-dati/gestione-ricette/edit-ricetta/edit-ricetta.component';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { type } from 'os';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';
import { auto } from '@popperjs/core';


@Component({
  selector: 'kt-analisi-vendite',
  templateUrl: './analisi-vendite.component.html',
  styleUrls: ['./analisi-vendite.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AnalisiVenditeComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup', { static: true }) tabGroup;
  @ViewChild('chart', { static: true }) chart!: ElementRef;
  @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;

  ProductOrCodeActiveFilter!: string;


  tableByCategories: any;
  tableByDepartments: any;
  tableByMenu: any;
  tableByCompany: any;
  tableByTimeSlots: any;
  tableMenuEngeenering: any;
  tableCostiMarginali: any;
  tablePercentageMatrix: any;
  tableObiettivi: any;
  currentTabIndex!: number;
  currentFilterCfg: any;

  myChart: any;
  graphData: any;

  explosedData: any = [];

  showInitialImage: boolean = true;

  byCategoriesData: any = undefined;
  byCategoriesDataFiltered: any = undefined;
  byDepartmentsData: any = undefined;
  byDepartmentsDataFiltered: any = undefined;
  byMenuData: any = undefined;
  byMenuDataFiltered: any = undefined;
  byCompanyData: any = undefined;
  byCompanyDataFiltered: any = undefined;
  byTimeSlotData: any = undefined;
  byTimeSlotDataFiltered: any = undefined;

  menuEngeeneringData: any = undefined;
  menuEngeeneringDataFiltered: any = undefined;

  hidePricesZero: boolean = false;
  hideCostsZero: boolean = false;
  hideFcHigher100: boolean = false;

  arrayColori: string[] = [
    '#5897fb',
    '#123a79',
    '#2a50a5',
    '#6182cc',
    '#0b49a5'
  ]

  // TimeSlots
  timeSlot: string = 'custom';
  viewType: string = 'valore';

  // InitObiettivi
  costoDelLavoro: number = 0.3;

  // Menu Engeenering
  livelloMinimoRendita: string = '0,8';

  // Subscription
  filtriServiceSubscription!: Subscription;

  // Graph
  selectedCategorie: any;
  categorieDisponibili: any;

  vendite1000: boolean = false;
  vendite1000ShowHeader: boolean = true;

  locale: any;
  utility: any;

  constructor(
    private filtriService: FiltriService,
    private analisiVenditeService: AnalisiVenditeService,
    private ref: ChangeDetectorRef,
    private el: ElementRef,
    private translate: TranslateService,
    private layoutConfigService: LayoutConfigService,
    private excelService: ExcelService,
    private pdfService: PDFService,
    private layoutUtilsService: LayoutUtilsService,
    public staticCollections: StaticCollectionsService,
    private translationService: TranslationService,
    private gestioneRicetteService: GestioneRicetteService,
    private dialog: MatDialog,
    public loaderService: LoaderService
  ) {
    this.utility = util;
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    })
  }

  //firstTimeLoad: boolean = true;
  ngOnInit() {
    document.body.classList.add('kt-aside--minimize');
    this.currentTabIndex = 0;

    this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
      (filterConfig: any) => {

        if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
        this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

        this.currentFilterCfg['hidePricesZero'] = this.hidePricesZero;
        this.currentFilterCfg['hideCostsZero'] = this.hideCostsZero;
        this.currentFilterCfg['hideFcHigher100'] = this.hideFcHigher100;

        // Forzo la lettura dei dati dall'api
        this.menuEngeeneringData = undefined;

        const forceShowTable = (this.currentTabIndex >= 5 && this.currentTabIndex <= 7 && !this.firstTime) && this.canExpandGraph();

        this.showInitialImage = false;

        this.switchTab(forceShowTable, this.firstTime, false, false);
      }
    );

    $.extend((<any>$.fn).dataTableExt.oSort, {
      "string-num-desc": (a, b) => {
        var x = (a == "-") ? 0 : a.replace('.', "").replace('&nbsp;', "").replace(/,/, ".");
        var y = (b == "-") ? 0 : b.replace('.', "").replace('&nbsp;', "").replace(/,/, ".");
        x = parseFloat(x);
        y = parseFloat(y);
        return ((x < y) ? 1 : ((x > y) ? -1 : 0));
      },
      "string-num-asc": (a, b) => {
        var x = (a == "-") ? 0 : a.replace('.', "").replace('&nbsp;', "").replace(/,/, ".");
        var y = (b == "-") ? 0 : b.replace('.', "").replace('&nbsp;', "").replace(/,/, ".");
        x = parseFloat(x);
        y = parseFloat(y);
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      },
    });

    $.fn.dataTable.ext.order['icon-value'] = (settings, col) => {
      return this.getCurrentTable().column(col, { order: 'index' }).nodes().map(function (td, i) {
        const content: any = $(td).find('span').html();
        return Number(content);
      });
    }

    fromEvent(this.ProductOrCode.nativeElement, 'keydown').subscribe(
      (event: any) => {
        if (event.keyCode === 13) {
          this.ProductOrCodeActiveFilter = this.ProductOrCode.nativeElement.value;
          let currentTable = this.getCurrentTable();
          currentTable.draw();
        }
      }
    );

    $.fn.dataTable.ext.search.push(
      (settings, searchData, index, rowData, counter) => {
        // //console.log(searchData, index, counter, rowData);
        let currentTable = this.getCurrentTable();
        let currentModel = this.getCurrentModel();
        if (this.ProductOrCodeActiveFilter) {
          if (this.currentTabIndex <= 4 && currentTable.row(index).child.isShown) {
            currentTable.row(index).child.hide();
            let item = currentModel.Items[index];
            setTimeout(() => {
              this.showDetailByCurrentTable(index, item);
            }, 100);
            return this.detailByCurrentTable(item).length > 0;
          } else {
            return searchData.toString().toLowerCase().includes(this.ProductOrCodeActiveFilter);
          }
        } else {
          if (currentModel && currentModel.Items && currentModel.Items[index]) currentModel.Items[index]['shown'] = false;
          if (currentTable) currentTable.row(index).child.hide();
          return true;
        }
      }
    );
  }

  clearFilters() {
    this.filtriService.clearFilters$.next(false);

    this.byCategoriesDataFiltered = null;
    this.byCategoriesData = null;
    if ($.fn.dataTable.isDataTable('#analisiVenditeByCategories')) {
      $('#analisiVenditeByCategories').DataTable().destroy();
    }

    this.byCompanyDataFiltered = null;
    this.byCompanyData = null;
    if ($.fn.dataTable.isDataTable('#analisiVenditeByCompany')) {
      $('#analisiVenditeByCompany').DataTable().destroy();
    }

    this.byDepartmentsDataFiltered = null;
    this.byDepartmentsData = null;
    if ($.fn.dataTable.isDataTable('#analisiVenditeByDepartments')) {
      $('#analisiVenditeByDepartments').DataTable().destroy();
    }

    this.byMenuDataFiltered = null;
    this.byMenuData = null;
    if ($.fn.dataTable.isDataTable('#analisiVenditeByMenu')) {
      $('#analisiVenditeByMenu').DataTable().destroy();
    }

    this.byTimeSlotDataFiltered = null;
    this.byTimeSlotData = null;
    if ($.fn.dataTable.isDataTable('#analisiVenditeByTimeSlots')) {
      $('#analisiVenditeByTimeSlots').DataTable().destroy();
    }

    this.menuEngeeneringDataFiltered = null;
    this.menuEngeeneringData = null;
    if ($.fn.dataTable.isDataTable('#analisiVenditeMenuEngeenering')) {
      $('#analisiVenditeMenuEngeenering').DataTable().destroy();
    }
    if ($.fn.dataTable.isDataTable('#analisiVenditePercentageMatrix')) {
      $('#analisiVenditePercentageMatrix').DataTable().destroy();
    }
    if ($.fn.dataTable.isDataTable('#analisiVenditeObiettivi')) {
      $('#analisiVenditeObiettivi').DataTable().destroy();
    }
    if ($.fn.dataTable.isDataTable('#analisiVenditeCostiMarginali')) {
      $('#analisiVenditeCostiMarginali').DataTable().destroy();
    }

    this.showInitialImage = true;
  }

  checkShowZeroRecordsImage() {
    let validation = false;
    switch (this.currentTabIndex) {
      case 0:
        validation = this.byCategoriesDataFiltered && this.vendite1000ShowHeader && this.byCategoriesDataFiltered.Items && this.byCategoriesDataFiltered.Items.length > 0
        break;
      case 1:
        validation = this.byDepartmentsDataFiltered && this.byDepartmentsDataFiltered.Items && this.byDepartmentsDataFiltered.Items.length > 0
        break;
      case 2:
        validation = this.byMenuDataFiltered && this.byMenuDataFiltered.Items && this.byMenuDataFiltered.Items.length > 0
        break;
      case 3:
        validation = this.byTimeSlotDataFiltered && this.byTimeSlotDataFiltered.Items && this.byTimeSlotDataFiltered.Items.length > 0
        break;
      case 4:
        validation = this.byCompanyDataFiltered && this.byCompanyDataFiltered.Items && this.byCompanyDataFiltered.Items.length > 0
        break;
      case 5:
      case 6:
      case 7:
        validation = this.menuEngeeneringDataFiltered && this.canExpandGraph() && this.menuEngeeneringDataFiltered.Items || this.menuEngeeneringDataFiltered.Items.length == 0;
        break;
      case 8:
        validation = this.menuEngeeneringDataFiltered && this.menuEngeeneringDataFiltered.Items || this.menuEngeeneringDataFiltered.Items.length == 0;
        break;
    }

    return !validation;
  }

  showDetailByCurrentTable(index: number, item: any) {
    switch (this.currentTabIndex) {
      case 0:
        this.showDetailByCategories(index, item);
        break;
      case 1:
        this.showDetailByDepartments(index, item);
        break;
      case 2:
        this.showDetailByMenu(index, item);
        break;
      case 3:
        this.showDetailByTimeSlot(index, item);
        break;
      case 4:
        this.showDetailByCompany(index, item, true);
        break;
    }
  }

  detailByCurrentTable(item: any) {
    let result;
    switch (this.currentTabIndex) {
      case 0:
        result = this.detailByCategories(item);
        break;
      case 1:
        result = this.detailByDepartments(item);
        break;
      case 2:
        result = this.detailByMenu(item);
        break;
      case 3:
        result = this.detailByTimeSlot(item);
        break;
      case 4:
        result = this.detailByCompany(item, true);
        break;
      default:
        result = [];
    }

    return result;
  }

  initCompleteTable() {
    this.loaderService.hide();
  }

  getRandomBlue() {
    const h = 240;
    const s = Math.floor(Math.random() * 100);
    const l = Math.floor(Math.random() * 100);
    const color = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
    return color;
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.destroyTables();
    this.filtriServiceSubscription.unsubscribe();

    // cancello il filtro
    this.filtriService.filterConfig = {};
  }

  destroyTables() {
    if (this.tableByCategories) this.tableByCategories.destroy();
    if (this.tableByDepartments) this.tableByDepartments.destroy();
    if (this.tableByMenu) this.tableByMenu.destroy();
    if (this.tableByTimeSlots) this.tableByTimeSlots.destroy();
    if (this.tableByCompany) this.tableByCompany.destroy();
    if (this.tableMenuEngeenering) this.tableMenuEngeenering.destroy();
    if (this.tableCostiMarginali) this.tableCostiMarginali.destroy();
    if (this.tablePercentageMatrix) this.tablePercentageMatrix.destroy();
    if (this.tableObiettivi) this.tableObiettivi.destroy();
  }

  initSelectCategorie() {

    setTimeout(async () => {

      // Cerco la categoria che ha il GrossRevenue più alto

      let grossRevenue = 0;
      let category: any = {};

      // Raggruppo per categoria
      const categories: any = _.groupBy(this.menuEngeeneringData.Items, 'CategoryId');

      Object.values(categories).forEach((c: any) => {

        // Per ogni categoria sommo i grossRevenue
        const sum = _.sumBy(c, 'GrossRevenue');

        // cerco tra i grossrevenue il max
        if (sum > grossRevenue) {
          category = {
            Id: c[0].CategoryId,
            Name: c[0].CategoryName
          };

          grossRevenue = sum;
        }


      });

      // $('#select2CategorieMenuEngeenering').val(category.Id).trigger('change');
      this.selectedCategorie = [category.Id];

      this.selectCategorie();

      //this.currentFilterCfg['CategoryIds'] = this.selectedCategorie;
      //this.currentFilterCfg['fromLocal'] = false;
      //this.menuEngeeneringData = await this.analisiVenditeService.getMenuEngeenering(this.currentFilterCfg).toPromise();
      //this.menuEngeeneringData['allShown'] = false;
      //this.menuEngeeneringDataFiltered = JSON.parse(JSON.stringify(this.menuEngeeneringData));

      //this.refreshGraph();
    }, 100);
    /**********************************************************/
  }

  switchTab(forceShowTable: boolean = false, resetFirstTime: boolean = false, cleanCategory: boolean = false, cleanDepartment: boolean = false): void {
    //this.destroyTables();
    this.loaderService.show();
    this.filtriService.readOnly$.next(true);
    this.ProductOrCodeActiveFilter = '';
    this.ProductOrCode.nativeElement.value = '';

    this.firstTime = resetFirstTime;

    switch (this.currentTabIndex) {
      case 0:
        this.selectedCategorie = undefined;
        if (cleanCategory && this.currentFilterCfg) delete this.currentFilterCfg['CategoryIds'];
        this.InitByCategoriesDataTable();
        break;
      case 1:
        this.selectedCategorie = undefined;
        if (cleanDepartment && this.currentFilterCfg) delete this.currentFilterCfg['DepartmentIds'];
        this.InitByDepartmentsDataTable();
        break;
      case 2:
        this.selectedCategorie = undefined;
        if (cleanCategory && this.currentFilterCfg) delete this.currentFilterCfg['CategoryIds'];
        this.InitByMenuDataTable();
        break;
      case 3:
        this.selectedCategorie = undefined;
        if (cleanCategory && this.currentFilterCfg) delete this.currentFilterCfg['CategoryIds'];
        this.InitByTimeSlotDataTable();
        break;
      case 4:
        this.selectedCategorie = undefined;
        if (cleanCategory && this.currentFilterCfg) delete this.currentFilterCfg['CategoryIds'];
        this.InitByCompanyDataTable();
        break;
      case 5:
        this.InitMenuEngeenering(forceShowTable);
        break;
      case 6:
        this.InitCostiMarginali(forceShowTable);
        break;
      case 7:
        this.InitPercentageMatrix(forceShowTable);
        break;
      case 8:
        this.selectedCategorie = undefined;
        if (cleanCategory && this.currentFilterCfg) delete this.currentFilterCfg['CategoryIds'];
        this.InitObiettivi();
        break;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent, resetFirstTime: boolean = true, cleanCategory: boolean = false): void {
    if (tabChangeEvent.index === this.currentTabIndex || tabChangeEvent.index < 0) return;

    if (!this.currentFilterCfg) {
      this.currentFilterCfg = {};
    }

    if (cleanCategory && this.currentFilterCfg) delete this.currentFilterCfg['CategoryIds'];
    this.firstTime = resetFirstTime;

    this.currentTabIndex = tabChangeEvent.index;

    this.currentFilterCfg['fromLocal'] = false;

    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }

  selectCategorie() {
    if (this.selectedCategorie && this.selectedCategorie.length > 0) {
      this.currentFilterCfg['CategoryIds'] = this.selectedCategorie;
    } else if (this.currentFilterCfg) {
      delete this.currentFilterCfg['CategoryIds'];
    }

    this.currentFilterCfg['fromLocal'] = false;

    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }


  /****************************************************************************
     ____  _     _      _   _   _       _
    / __ \| |   (_)    | | | | (_)     (_)
   | |  | | |__  _  ___| |_| |_ ___   ___
   | |  | | '_ \| |/ _ \ __| __| \ \ / / |
   | |__| | |_) | |  __/ |_| |_| |\ V /| |
    \____/|_.__/|_|\___|\__|\__|_| \_/ |_|
   ****************************************************************************/

  async InitObiettivi() {
    // $('#analisiVenditeObiettivi').DataTable().clear().destroy();
    if ($.fn.dataTable.isDataTable('#analisiVenditeObiettivi')) {
      $('#analisiVenditeObiettivi').DataTable().destroy();
    }

    if (!this.categorieDisponibili || this.categorieDisponibili.length == 0) {
      this.categorieDisponibili = this.staticCollections.categorieProdotti$;
      this.selectedCategorie = [];
    }

    this.analisiVenditeService.getMenuEngeenering(this.currentFilterCfg).toPromise()
      .then((ret: any) => {
        this.menuEngeeneringData = ret;
        this.menuEngeeneringData['allShown'] = false;
        this.menuEngeeneringDataFiltered = JSON.parse(JSON.stringify(this.menuEngeeneringData));
        this.ref.detectChanges();
        this.filtriService.readOnly$.next(false);
        this.initTableObiettivi();
      })
      .catch((error: any) => {
        this.filtriService.readOnly$.next(false);
      })
      .finally(() => {
        this.loaderService.hide();
      });

  }

  initTableObiettivi() {
    setTimeout(() => {
      let dtOptions: any = {
        initComplete: () => {
          this.initCompleteTable();
        },
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        paging: false,
        dom: 't',
        searching: true,
        ordering: true,
        scrollY: 'calc(100vh - 330px)',
        scrollCollapse: true,
        columnDefs: [
          { targets: '_all', orderable: true },
          { targets: [2, 3, 4, 5, 6, 7, 8, 9], type: 'string-num' },
          //{ targets: [0], width: '15%' },
          { targets: [1, 2, 3, 4, 5, 6, 7, 8, 9], width: '70px' },
        ],
        orderCellsTop: true,
        aaSorting: [[9, 'desc']]
      };
      this.tableObiettivi = $('#analisiVenditeObiettivi').DataTable(dtOptions);
      //this.tableObiettivi.order([9, 'desc']).draw();
    }, 500);
  }

  getAnalisiObiettiviRow(item: any) {
    return (1 - item.FoodCostPerc) * item.Quantity * item.NetPrice * (1 - (item.FoodCostPerc + this.costoDelLavoro));
  }

  getAnalisiObiettiviFCTot() {
    let x = 0;
    if (this.menuEngeeneringDataFiltered.NetRevenue) {
      x = this.menuEngeeneringDataFiltered.TotalFoodCost * 100 / this.menuEngeeneringDataFiltered.NetRevenue;
    }
    return x;
  }

  getAnalisiObiettiviTot() {
    //return (1 - this.menuEngeeneringDataFiltered.FoodCostPerc) * (this.menuEngeeneringDataFiltered.Quantity / this.menuEngeeneringDataFiltered.Items.length) * this.menuEngeeneringDataFiltered.GrossPrice * (1 - (this.menuEngeeneringDataFiltered.FoodCostPerc + this.costoDelLavoro));
    let p = this.getAnalisiObiettiviFCTot() / 100;
    return (1 - p) * (this.menuEngeeneringDataFiltered.Quantity / this.menuEngeeneringDataFiltered.Items.length) * this.menuEngeeneringDataFiltered.GrossPrice * (1 - (p + this.costoDelLavoro));
  }

  getAnalisiObiettiviColor(item: any) {
    let textColor: string = 'black;'

    const obbiettivoRow = this.getAnalisiObiettiviRow(item);
    const obbiettivoTot = this.getAnalisiObiettiviTot();

    if ((obbiettivoTot - obbiettivoRow) > 50) textColor = this.layoutConfigService.getConfig('colors.graph.dataset3');
    if ((obbiettivoTot - obbiettivoRow) < 50) textColor = this.layoutConfigService.getConfig('colors.graph.dataset4');
    if ((obbiettivoTot - obbiettivoRow) < 1) textColor = this.layoutConfigService.getConfig('colors.graph.dataset1');

    return {
      color: textColor
    }
  }

  /****************************************************************************
    _____                        _                   __  __       _        _
   |  __ \                      | |                 |  \/  |     | |      (_)
   | |__) |__ _ __ ___ ___ _ __ | |_ __ _  __ _  ___| \  / | __ _| |_ _ __ ___  __
   |  ___/ _ \ '__/ __/ _ \ '_ \| __/ _` |/ _` |/ _ \ |\/| |/ _` | __| '__| \ \/ /
   | |  |  __/ | | (_|  __/ | | | || (_| | (_| |  __/ |  | | (_| | |_| |  | |>  <
   |_|   \___|_|  \___\___|_| |_|\__\__,_|\__, |\___|_|  |_|\__,_|\__|_|  |_/_/\_\
                                           __/ |
                                          |___/
   ****************************************************************************/

  async InitPercentageMatrix(collapseGraph: boolean = false) {

    if (!this.menuEngeeneringData || this.menuEngeeneringData.length == 0) {
      this.menuEngeeneringData = await this.analisiVenditeService.getMenuEngeenering(this.currentFilterCfg).toPromise();
      this.menuEngeeneringData['allShown'] = false;
      this.menuEngeeneringDataFiltered = JSON.parse(JSON.stringify(this.menuEngeeneringData));
    }

    if ((!this.categorieDisponibili || this.categorieDisponibili.length == 0) && !collapseGraph) {
      this.categorieDisponibili = this.staticCollections.categorieProdotti$;
      this.selectedCategorie = [];
    }

    if (collapseGraph) {
      if ($.fn.dataTable.isDataTable('#analisiVenditePercentageMatrix')) {
        $('#analisiVenditePercentageMatrix').DataTable().clear().destroy();
      }
      this.collapseGraph();
    } else {
      this.expandGraph();
    }
    this.loaderService.hide();
    this.filtriService.readOnly$.next(false);
  }

  initTablePercentageMatrix() {
    if ($.fn.dataTable.isDataTable('#analisiVenditePercentageMatrix')) {
      $('#analisiVenditePercentageMatrix').DataTable().destroy();
    }
    setTimeout(() => {
      let dtOptions: any = {
        initComplete: () => {
          this.initCompleteTable();
        },
        aaSorting: [],
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        paging: false,
        dom: 't',
        searching: true,
        ordering: true,
        scrollY: 'calc(100vh - 330px)',
        scrollCollapse: true,
        columnDefs: [
          { targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 13], orderable: true },
          { targets: [10, 11], orderable: false },
          { targets: [12], orderDataType: 'icon-value' },
          { targets: [2, 3, 4, 5, 6, 7, 8, 9], type: 'string-num' },
          { targets: [0], width: '15%' },
          { targets: [1, 7, 8, 9], width: '70px' }
        ],
        orderCellsTop: true
      };
      this.tablePercentageMatrix = $('#analisiVenditePercentageMatrix').DataTable(dtOptions);
      this.tablePercentageMatrix.order([12, 'desc']).draw();

    }, 100);
  }

  /****************************************************************************
     _____          _   _ __  __                 _             _ _
    / ____|        | | (_)  \/  |               (_)           | (_)
   | |     ___  ___| |_ _| \  / | __ _ _ __ __ _ _ _ __   __ _| |_
   | |    / _ \/ __| __| | |\/| |/ _` | '__/ _` | | '_ \ / _` | | |
   | |___| (_) \__ \ |_| | |  | | (_| | | | (_| | | | | | (_| | | |
    \_____\___/|___/\__|_|_|  |_|\__,_|_|  \__, |_|_| |_|\__,_|_|_|
                                            __/ |
                                           |___/
   ****************************************************************************/

  async InitCostiMarginali(collapseGraph: boolean = false) {

    if (!this.menuEngeeneringData || this.menuEngeeneringData.length == 0) {
      this.filtriService.readOnly$.next(true);
      this.menuEngeeneringData = await this.analisiVenditeService.getMenuEngeenering(this.currentFilterCfg).toPromise();
      this.menuEngeeneringDataFiltered = JSON.parse(JSON.stringify(this.menuEngeeneringData));
    }

    if ((!this.categorieDisponibili || this.categorieDisponibili.length == 0) && !collapseGraph) {
      this.categorieDisponibili = this.staticCollections.categorieProdotti$;
      this.selectedCategorie = [];
    }

    if (collapseGraph) {
      if ($.fn.dataTable.isDataTable('#analisiVenditeCostiMarginali')) {
        $('#analisiVenditeCostiMarginali').DataTable().clear().destroy();
      }
      this.collapseGraph();
    } else {
      this.expandGraph();
    }
    this.loaderService.hide();
    this.filtriService.readOnly$.next(false);

    //this.expandGraph();
    //this.loaderService.show();
    //this.filtriService.readOnly$.next(false);
  }

  initTableCostiMarginali() {
    if ($.fn.dataTable.isDataTable('#analisiVenditeCostiMarginali')) {
      $('#analisiVenditeCostiMarginali').DataTable().destroy();
    }
    setTimeout(() => {
      let dtOptions: any = {
        initComplete: () => {
          this.initCompleteTable();
        },
        aaSorting: [],
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        paging: false,
        dom: 't',
        searching: true,
        ordering: true,
        scrollY: 'calc(100vh - 330px)',
        scrollCollapse: true,
        columnDefs: [
          { targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 13], orderable: true },
          { targets: [10, 11], orderable: false },
          { targets: [12], orderDataType: 'icon-value' },
          { targets: [2, 3, 4, 5, 6, 7, 8, 9], type: 'string-num' },
          { targets: [0], width: '15%' },
          { targets: [1, 7, 8, 9], width: '70px' }
        ],
        orderCellsTop: true
      };
      this.tableCostiMarginali = $('#analisiVenditeCostiMarginali').DataTable(dtOptions);
      this.tableCostiMarginali.order([12, 'desc']).draw();

      // this.categorieDisponibili = this.staticCollections.categorieProdotti$;
      // this.selectedCategorie = [];
      // this.selectedCategorie.push({
      // 	Id: this.categorieDisponibili[0].Id,
      // 	Name: this.categorieDisponibili[0].Name
      // });

    }, 500);


  }

  /****************************************************************************
    __  __                  ______                                       _
   |  \/  |                |  ____|                                     (_)
   | \  / | ___ _ __  _   _| |__   _ __   __ _  ___  ___ _ __   ___ _ __ _ _ __   __ _
   | |\/| |/ _ \ '_ \| | | |  __| | '_ \ / _` |/ _ \/ _ \ '_ \ / _ \ '__| | '_ \ / _` |
   | |  | |  __/ | | | |_| | |____| | | | (_| |  __/  __/ | | |  __/ |  | | | | | (_| |
   |_|  |_|\___|_| |_|\__,_|______|_| |_|\__, |\___|\___|_| |_|\___|_|  |_|_| |_|\__, |
                                          __/ |                                   __/ |
                                         |___/                                   |___/
   ****************************************************************************/

  async InitMenuEngeenering(collapseGraph: boolean = false) {

    if (!this.menuEngeeneringData || this.menuEngeeneringData.length == 0) {
      this.filtriService.readOnly$.next(true);
      this.menuEngeeneringData = await this.analisiVenditeService.getMenuEngeenering(this.currentFilterCfg).toPromise();
      this.menuEngeeneringData['allShown'] = false;
      this.menuEngeeneringDataFiltered = JSON.parse(JSON.stringify(this.menuEngeeneringData));
    }

    if ((!this.categorieDisponibili || this.categorieDisponibili.length == 0) && !collapseGraph) {
      this.categorieDisponibili = this.staticCollections.categorieProdotti$;
      this.selectedCategorie = [];
    }

    if (collapseGraph) {
      if ($.fn.dataTable.isDataTable('#analisiVenditeMenuEngeenering')) {
        $('#analisiVenditeMenuEngeenering').DataTable().clear().destroy();
      }
      this.collapseGraph();
    } else {
      this.expandGraph();
    }
    this.loaderService.hide();
    this.filtriService.readOnly$.next(false);

  }

  initTableMenuEngeenering() {
    if ($.fn.dataTable.isDataTable('#analisiVenditeMenuEngeenering')) {
      $('#analisiVenditeMenuEngeenering').DataTable().destroy();
    }

    setTimeout(() => {
      let dtOptions: any = {
        initComplete: () => {
          this.initCompleteTable();
        },
        aaSorting: [],
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        paging: false,
        dom: 't',
        searching: true,
        ordering: true,
        scrollY: 'calc(100vh - 330px)',
        scrollCollapse: true,
        columnDefs: [
          { targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 13], orderable: true },
          { targets: [10, 11], orderable: false },
          { targets: [12], orderDataType: 'icon-value' },
          { targets: [2, 3, 4, 5, 6, 7, 8, 9], type: 'string-num' },
          { targets: [0], width: '5px' },
          //{ targets: [1, 7, 8, 9], width: '70px' }
        ],
        orderCellsTop: true,
        autoWidth: false
      };
      this.tableMenuEngeenering = $('#analisiVenditeMenuEngeenering').DataTable(dtOptions);
      this.tableMenuEngeenering.order([12, 'desc']).draw();
    }, 500);


  }

  getMdcPonderato() {
    return (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Quantity);
  }

  getMdcTotalePonderato() {
    let totalMDC: number = 0, elements: number = 0;
    try {
      totalMDC = this.menuEngeeneringDataFiltered.TotalMC;
      elements = this.menuEngeeneringDataFiltered.Items.length;
    } catch (e) {
      //console.log(e);
      totalMDC = 0;
      elements = 1;
    } finally {
      //console.log(totalMDC / elements);
      return (totalMDC / elements);
    }
  }

  getMdcPonderatoGraph() {
    let totalMDC: number = 0, totalQty: number = 0, elements: number = 0, qty: number = 0, mdc: number = 0;
    try {
      this.graphData.datasets.forEach((prodotto: any) => {
        mdc = (!prodotto.MC || String(prodotto.MC).includes('NaN')) ? 0 : prodotto.MC;
        qty = (!prodotto.Quantity || String(prodotto.Quantity).includes('NaN')) ? 0 : prodotto.Quantity;
        totalQty += (!prodotto.Quantity || String(prodotto.Quantity).includes('NaN')) ? 0 : prodotto.Quantity;
        totalMDC += mdc * qty;
      });
      elements = this.graphData.datasets.length;
    } catch (e) {
      //console.log(e);
      totalMDC = 0;
      elements = 1;
    } finally {
      //console.log(totalMDC / elements);
      //return (totalMDC / elements);
      return totalMDC / totalQty;
    }
  }

  getMdcTotalePonderatoGraph() {
    let totalMC: number = 0, elements: number = 0;
    try {
      this.graphData.datasets.forEach((prodotto: any) => {
        totalMC += (!prodotto.TotalMC || String(prodotto.TotalMC).includes('NaN')) ? 0 : prodotto.TotalMC;
      });
      elements = this.graphData.datasets.length;
    } catch (e) {
      //console.log(e);
      totalMC = 0;
      elements = 1;
    } finally {
      return (totalMC / elements);
    }
  }

  getFoodCostTotaleGraph() {
    let totalFoodCost: number = 0, grossRevenue: number = 0;
    try {
      this.graphData.datasets.forEach((prodotto: any) => {
        totalFoodCost += (!prodotto.TotalFoodCost || String(prodotto.TotalFoodCost).includes('NaN')) ? 0 : prodotto.TotalFoodCost;
        grossRevenue += (!prodotto.GrossRevenue || String(prodotto.GrossRevenue).includes('NaN')) ? 0 : prodotto.GrossRevenue;
      });
    } catch (e) {
      //console.log(e);
      totalFoodCost = 0;
      grossRevenue = 1;
    } finally {
      return (totalFoodCost / grossRevenue * 100);
    }
  }

  getMediaPopolaritaTotale() {
    let pop: number = 0, elements: number = 0;
    try {
      this.menuEngeeneringDataFiltered.Items.forEach((prodotto: any) => {
        pop += (!prodotto.Quantity || String(prodotto.Quantity).includes('NaN')) ? 0 : prodotto.Quantity;
      });
      elements = this.menuEngeeneringDataFiltered.Items.length;
    } catch (e) {
      //console.log(e);
      pop = 0;
      elements = 1;
    } finally {
      return ((pop * this.parseLivelloMinimoRendita()) / elements);
    }
  }

  getMediaPopolaritaGraph() {
    let pop: number = 0, elements: number = 0;
    try {
      this.graphData.datasets.forEach((prodotto: any) => {
        pop += (!prodotto.Quantity || String(prodotto.Quantity).includes('NaN')) ? 0 : prodotto.Quantity;
      });
      elements = this.graphData.datasets.length;
    } catch (e) {
      //console.log(e);
      pop = 0;
      elements = 1;
    } finally {
      return ((pop * this.parseLivelloMinimoRendita()) / elements);
    }
  }

  parseLivelloMinimoRendita(): number {
    return parseFloat(this.livelloMinimoRendita.replace(',', '.'));
  }

  /****************************************************************************
    ____     _______ _                 _____ _       _
   |  _ \   |__   __(_)               / ____| |     | |
   | |_) |_   _| |   _ _ __ ___   ___| (___ | | ___ | |_
   |  _ <| | | | |  | | '_ ` _ \ / _ \\___ \| |/ _ \| __|
   | |_) | |_| | |  | | | | | | |  __/____) | | (_) | |_
   |____/ \__, |_|  |_|_| |_| |_|\___|_____/|_|\___/ \__|
           __/ |
          |___/
   ****************************************************************************/

  async InitByTimeSlotDataTable() {
    let filterStimeSlot = JSON.parse(JSON.stringify(this.currentFilterCfg));
    if (filterStimeSlot.Grouping) delete filterStimeSlot.Grouping;
    if (!filterStimeSlot.TimeSlot) filterStimeSlot['TimeSlot'] = 'custom';
    if ($.fn.dataTable.isDataTable('#analisiVenditeByTimeSlot')) {
      $('#analisiVenditeByTimeSlot').DataTable().destroy();
    }

    //if (!this.byTimeSlotData || this.byTimeSlotData.length == 0) {
    this.byTimeSlotData = await this.analisiVenditeService.getByTimeSlot(filterStimeSlot).toPromise();
    this.byTimeSlotData['allShown'] = false;
    this.byTimeSlotDataFiltered = JSON.parse(JSON.stringify(this.byTimeSlotData));
    //}

    this.loaderService.hide();
    this.filtriService.readOnly$.next(false);
    this.initTableTimeSlot();
  }

  checkShown(item: any) {
    return item && item.shown ? 'shown' : ''
  }

  initTableTimeSlot() {

    let options = {
      destroy: true,
      language: {
        emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
        zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
        processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
      },
      paging: false,
      searching: false,
      ordering: true,
      orderCellsTop: true,
      aaSorting: [],
      scrollY: 'calc(100vh - 330px)',
      scrollX: false,
      scrollCollapse: true,
      columnDefs: [
        { targets: [0], width: '5px' }
      ],
      autoWidth: false,
      /*
      fixedColumns: {
          leftColumns: 4
      }
      */
    };

    let targets: number[] = [];
    for (let i = 3; i <= 3 + (this.byTimeSlotDataFiltered.TimeSlotValues.length * 2); i++) {
      targets.push(i);
    }
    setTimeout(() => {
      this.tableByTimeSlots = $('#analisiVenditeByTimeSlot').DataTable(options);
      this.tableByTimeSlots.columns.adjust().draw();
      //#analisiVenditeByTimeSlot tbody tr td:first-child i,
      $('.DTFC_LeftWrapper tbody tr td:first-child i').click((event) => {
        //console.log($(event.target));
        const index = $(event.target).data('indexrow');
        this.showDetailByTimeSlot(index, this.byTimeSlotDataFiltered.Rows[index]);
      })

    }, 500);
  }

  getStringify(item: any) {
    return JSON.stringify(item);
  }

  getNumColumnsTimeSlotsDouble() {
    let counter: number = 0;
    return Array(this.byTimeSlotDataFiltered.TimeSlotValues.length * 2).fill(1).map(
      (x, i) => (i % 2 == 1) ? counter++ : counter
    );
  }

  getNumColumnsTimeSlots() {
    let counter: number = 0;
    return Array(this.byTimeSlotDataFiltered.TimeSlotValues.length).fill(1);
  }

  updateTimeSlot() {
    this.loaderService.show();
    this.filtriService.readOnly$.next(true);
    const filterStimeSlot = JSON.parse(JSON.stringify(this.currentFilterCfg));
    filterStimeSlot['TimeSlot'] = this.timeSlot;
    delete filterStimeSlot.Grouping;
    this.analisiVenditeService.getByTimeSlot(filterStimeSlot).toPromise()
      .then(
        (data: any) => {
          this.byTimeSlotDataFiltered = data;
          this.byTimeSlotDataFiltered['allShown'] = false;
          this.loaderService.hide();
          this.filtriService.readOnly$.next(false);
          $('#analisiVenditeByTimeSlot').DataTable().destroy();
          this.initTableTimeSlot();
        }
      )
      .catch(() => {
        this.loaderService.hide();
        this.filtriService.readOnly$.next(false);
      })
  }

  detailByTimeSlot(item: any) {
    let firstSubLevel = '';
    let index: number = 0;
    item.Products = item.Products.sort((a, b) => {
      if (a.GrossRevenue < b.GrossRevenue) return 1;
      else if (a.GrossRevenue > b.GrossRevenue) return -1;
      else return 0;
    });
    item.Products.forEach((product: any) => {

      if (this.ProductOrCodeActiveFilter && !product.Product.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter)) {
        return;
      }

      let row = `<tr class="detail">` +
        `<td></td>` +
        //`<td class="columnDetail text-right">${product.Id}</td>` +
        //`<td class="columnDetail">${product.Product}</td>`;
        `<td class="columnDetail columnDetail-padding-left" colspan="2">${product.Id}&nbsp;<a href="javascript:;" data-id="${product.Id}" class="openGood"><i class="bi bi-pencil-square"></i></a>&nbsp;${product.Product}</td>`;

      if (this.viewType === 'valore') {
        row += `<td class="text-right">${this.formatNumber(product.GrossRevenue)}</td>`;
      } else {
        row += `<td class="text-right">${this.formatNumber(product.Quantity, 2)}</td>`;
      }

      product.TimeSlotValues.forEach(
        (timeSlot: any) => {
          row += (this.viewType === 'valore') ? `<td class="text-right">${this.formatNumber(timeSlot.GrossRevenue)}</td>` : `<td>${this.formatNumber(timeSlot.Quantity)}</td>`;
          row += (this.viewType === 'valore') ? `<td class="text-right">${this.formatNumber(timeSlot.ValuePercentage * 100, 2)}</td>` : `<td>${this.formatNumber(timeSlot.QuantityPercentage * 100, 2)}</td>`;
        }
      )

      row = row + `</tr>`;
      firstSubLevel = firstSubLevel + row;
      index++;
    });

    return firstSubLevel;
  }

  showDetailByTimeSlot(rowIndex: number, item: any) {
    let row = this.tableByTimeSlots.row(rowIndex);
    //console.log(rowIndex, row.child.isShown());
    if (row.child.isShown()) { // Era già aperta la righa? => la chiudo
      row.child.hide();
      item['shown'] = false;
    }
    else {
      row.child($(this.detailByTimeSlot(item))).show();
      item['shown'] = true;

      $('.openGood').off('click');
      $('.openGood').on('click', (event: any) => {
        const merceId = $(event.target).closest('a').data('id');
        this.openRicetta(merceId);
      });
    }
  }

  showAllDetailByTimeSlot() {
    let table = this.tableByTimeSlots;
    this.loaderService.show();
    if (this.byTimeSlotDataFiltered.allShown) {
      for (let index = 0; index < this.byTimeSlotDataFiltered.Rows.length; index++) {
        this.byTimeSlotDataFiltered.Rows[index]['shown'] = false;
        let row = table.row(index);
        row.child.hide();
      }
      this.byTimeSlotDataFiltered.allShown = false;
    } else {
      for (let index: number = 0; index < this.byTimeSlotDataFiltered.Rows.length; index++) {
        let item = this.byTimeSlotDataFiltered.Rows[index];
        item['shown'] = true
        let row = table.row(index);
        row.child($(this.detailByTimeSlot(item))).show();
      }
      this.byTimeSlotDataFiltered.allShown = true;
    }
    this.loaderService.hide();

  }

  switchTypeValQty(obj: any, exportExcel: boolean = false) {
    switch (this.viewType) {
      case 'valore':
        return exportExcel ? this.utility.formatNumberExcel(obj.GrossRevenue) : this.formatNumber(obj.GrossRevenue);
        break;
      case 'quantità':
        return this.utility.formatNumberExcel(obj.Quantity);
        break;
    }
  }

  switchType(isEven: boolean, obj: any) {
    switch (this.viewType) {
      case 'valore':
        return isEven ? this.formatNumber(obj.GrossRevenue) : this.formatNumber(obj.ValuePercentage * 100, 2);
        break;
      case 'quantità':
        return isEven ? this.formatNumber(obj.Quantity, 2) : this.formatNumber(obj.QuantityPercentage * 100, 2);
        break;
    }
  }

  /****************************************************************************
    ____         _____
   |  _ \       / ____|
   | |_) |_   _| |     ___  _ __ ___  _ __   __ _ _ __  _   _
   |  _ <| | | | |    / _ \| '_ ` _ \| '_ \ / _` | '_ \| | | |
   | |_) | |_| | |___| (_) | | | | | | |_) | (_| | | | | |_| |
   |____/ \__, |\_____\___/|_| |_| |_| .__/ \__,_|_| |_|\__, |
           __/ |                     | |                 __/ |
          |___/                      |_|                |___/
   ****************************************************************************/

  async InitByCompanyDataTable() {
    // $('#analisiVenditeByCompany').DataTable().clear().destroy();
    if ($.fn.dataTable.isDataTable('#analisiVenditeByCompany')) {
      $('#analisiVenditeByCompany').DataTable().destroy();
    }

    //if (!this.byCompanyData || this.byCompanyData.length == 0) {
    this.byCompanyData = await this.analisiVenditeService.getByCompany(this.currentFilterCfg).toPromise();
    this.byCompanyData['allShown'] = false;
    this.byCompanyDataFiltered = JSON.parse(JSON.stringify(this.byCompanyData));
    this.loaderService.hide();
    //}

    this.filtriService.readOnly$.next(false);
    this.initTableCompany();
  }

  initTableCompany() {
    setTimeout(() => {
      let dtOptions: any = {
        initComplete: () => {
          this.initCompleteTable();
        },
        aaSorting: [],
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        paging: false,
        dom: 't',
        searching: true,
        ordering: true,
        scrollY: 'calc(100vh - 330px)',
        scrollCollapse: true,
        columnDefs: [
          { targets: [0, 2], orderable: false },
          { targets: [0], width: '5px' },
          //{ targets: [1, 2], width: '10%' },
          { targets: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], type: 'string-num' },
          //{ targets: [7, 9, 11, 13], width: '70px' }
        ],
        autoWidth: false,
        orderCellsTop: true
      };
      this.tableByCompany = $('#analisiVenditeByCompany').DataTable(dtOptions);
      //this.tableByCompany.columns.adjust().draw();
    }, 500);
  }

  detailByCompany(item: any, expandAll: boolean) {
    let firstSubLevel = '';
    const freccia = expandAll ? 'bi-caret-down' : 'bi-caret-right';
    item.Items = item.Items.sort((a, b) => {
      if (a.GrossRevenue < b.GrossRevenue) return 1;
      else if (a.GrossRevenue > b.GrossRevenue) return -1;
      else return 0;
    });
    item.Items.forEach((subItem: any) => {

      // Controllo già adesso che nella sottoriga ci sia almeno un elemento, altrimenti nemmeno la stampo.
      const checkSubSubRow = this.ProductOrCodeActiveFilter ? subItem.Items.filter((subRow: any) => {
        return subRow.Product_Code.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter) ||
          subRow.Product.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter);
      }) : [];

      if (checkSubSubRow.length == 0 && this.ProductOrCodeActiveFilter) return;

      let row = `<tr class="detail" data-parent="${item.Id}${subItem.$id}">` +
        //`<td class="columnDetail"><i class="fa ${freccia} pointer" style="width: 30px;"></i></td>` +
        `<td class="columnDetail columnDetail-padding-left" colspan="2"><i class="fa ${freccia} pointer" style="width: 30px;"></i>&nbsp;${subItem.Company}</td>` +
        `<td class="columnDetail"></td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.AvgGrossPrice)}</td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.AvgNetPrice)}</td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.FoodCost, 3)}</td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.AvgMC)}</td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.Quantity, 2)}</td>` +
        `<td class="columnDetail text-right text-bold">${subItem.QuantityIncidence >= 0 ? this.formatNumber(subItem.QuantityIncidence * 100) : ''}</td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.GrossRevenue)}</td>` +
        `<td class="columnDetail text-right text-bold">${subItem.RevenueIncidence >= 0 ? this.formatNumber(subItem.RevenueIncidence * 100) : ''}</td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.TotalFoodCost)}</td>` +
        `<td class="columnDetail text-right text-bold">${subItem.CostIncidence >= 0 ? this.formatNumber(subItem.CostIncidence * 100) : ''}</td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.TotalMC)}</td>` +
        `<td class="columnDetail text-right text-bold">${subItem.MCIncidence >= 0 ? this.formatNumber(subItem.MCIncidence * 100) : ''}</td>` +
        `<td class="columnDetail text-right text-blue text-bold">${this.formatNumber(subItem.FCIncidence * 100)}</td>` +
        `</tr>`;
      firstSubLevel = firstSubLevel + row;
      const hide = expandAll ? 'shown' : 'hide';
      subItem.Items = subItem.Items.sort((a, b) => {
        if (a.GrossRevenue < b.GrossRevenue) return 1;
        else if (a.GrossRevenue > b.GrossRevenue) return -1;
        else return 0;
      });
      subItem.Items.forEach((subSubItem: any) => {

        if (this.ProductOrCodeActiveFilter && (!subSubItem.Product_Code.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter) &&
          !subSubItem.Product.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter)
        )) {
          return;
        }

        let subRow = `<tr class="subDetails ${hide} ${item.Id}${subItem.$id}">` +
          `<td></td>` +
          //`<td>${subSubItem.Product_Code}</td>` +
          //`<td class="columnDetail">${subSubItem.Product}</td>` +
          `<td class="columnDetail columnDetail-padding-left-double" colspan="2">${subSubItem.Product_Code}&nbsp;<a href="javascript:;" data-id="${subSubItem.Id}" class="openGood"><i class="bi bi-pencil-square"></i></a>&nbsp;${subSubItem.Product}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.GrossPrice)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.NetPrice)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.FoodCost, 3)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.MC)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.Quantity, 2)}</td>` +
          `<td class="columnDetail text-right text-bold">${subSubItem.QuantityIncidence >= 0 ? this.formatNumber(subSubItem.QuantityIncidence * 100) : ''}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.GrossRevenue)}</td>` +
          `<td class="columnDetail text-right text-bold">${subSubItem.RevenueIncidence >= 0 ? this.formatNumber(subSubItem.RevenueIncidence * 100) : ''}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.TotalFoodCost)}</td>` +
          `<td class="columnDetail text-right text-bold">${subSubItem.CostIncidence >= 0 ? this.formatNumber(subSubItem.CostIncidence * 100) : ''}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.TotalMC)}</td>` +
          `<td class="columnDetail text-right text-bold">${subSubItem.MCIncidence >= 0 ? this.formatNumber(subSubItem.MCIncidence * 100) : ''}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subSubItem.FCIncidence * 100)}</td>` +
          `</tr>`;
        firstSubLevel = firstSubLevel + subRow;
      })
    });
    return firstSubLevel;
  }

  showDetailByCompany(rowIndex: number, item: any, expandAll: boolean = false) {
    let row = this.tableByCompany.row(rowIndex);
    if (row.child.isShown()) { // Se era già a perta la chiudo
      row.child.hide();
      item['shown'] = false;
    }
    else {
      row.child($(this.detailByCompany(item, expandAll))).show();
      item['shown'] = true;

      $('.openGood').off('click');
      $('.openGood').on('click', (event: any) => {
        const merceId = $(event.target).closest('a').data('id');
        console.log(merceId);
        this.openRicetta(merceId);
      });
    }

    $('#analisiVenditeByCompany .columnDetail .fa').click((event) => {
      const parentId = $($(event.target).closest('tr')).data('parent');
      if ($(`tr.${parentId}`).hasClass('shown')) {
        $(`tr.${parentId}`).removeClass('shown');
        $(`tr.${parentId}`).addClass('hide');
        $(event.target).removeClass('bi-caret-down');
        $(event.target).addClass('bi-caret-right');
      } else {
        $(`tr.${parentId}`).removeClass('hide');
        $(`tr.${parentId}`).addClass('shown');
        $(event.target).addClass('bi-caret-down');
        $(event.target).removeClass('bi-caret-right');
      }
    });


  }

  showAllDetailByCompany() {
    let table = this.tableByCompany;
    this.loaderService.show();
    if (this.byCompanyDataFiltered.allShown) {
      for (let index = 0; index < this.byCompanyDataFiltered.Items.length; index++) {
        this.byCompanyDataFiltered.Items[index]['shown'] = false;
        let row = table.row(index);
        row.child.hide();
      }
      this.byCompanyDataFiltered.allShown = false;
    } else {
      for (let index: number = 0; index < this.byCompanyDataFiltered.Items.length; index++) {
        let item = this.byCompanyDataFiltered.Items[index];
        item['shown'] = true
        let row = table.row(index);
        row.child($(this.detailByCompany(item, true))).show();
      }
      this.byCompanyDataFiltered.allShown = true;
    }

    $('#analisiVenditeByCompany .columnDetail .fa').click((event) => {
      const parentId = $($(event.target).closest('tr')).data('parent');
      if ($(`tr.${parentId}`).hasClass('shown')) {
        $(`tr.${parentId}`).removeClass('shown');
        $(`tr.${parentId}`).addClass('hide');
        $(event.target).removeClass('bi-caret-down');
        $(event.target).addClass('bi-caret-right');
      } else {
        $(`tr.${parentId}`).removeClass('hide');
        $(`tr.${parentId}`).addClass('shown');
        $(event.target).addClass('bi-caret-down');
        $(event.target).removeClass('bi-caret-right');
      }

    });

    this.loaderService.hide();

  }
  /****************************************************************************
    ____        __  __
   |  _ \      |  \/  |
   | |_) |_   _| \  / | ___ _ __  _   _
   |  _ <| | | | |\/| |/ _ \ '_ \| | | |
   | |_) | |_| | |  | |  __/ | | | |_| |
   |____/ \__, |_|  |_|\___|_| |_|\__,_|
           __/ |
          |___/
   ****************************************************************************/

  InitByMenuDataTable() {
    // $('#analisiVenditeByMenu').DataTable().clear().destroy();
    if ($.fn.dataTable.isDataTable('#analisiVenditeByMenu')) {
      $('#analisiVenditeByMenu').DataTable().destroy();
    }
    this.analisiVenditeService.getByMenu(this.currentFilterCfg).toPromise()
      .then((ret: any) => {
        //console.log('InitByMenuDataTable', ret);
        this.byMenuData = ret;
        this.byMenuData['allShown'] = false;
        this.byMenuDataFiltered = JSON.parse(JSON.stringify(this.byMenuData));
        //this.onTbHideChange();
        this.loaderService.hide();
        this.filtriService.readOnly$.next(false);
        this.initTableMenu();
      })
      .catch((error: any) => {
        this.loaderService.hide();
        this.filtriService.readOnly$.next(false);
      });
  }

  initTableMenu() {
    setTimeout(() => {
      let dtOptions: any = {
        initComplete: () => {
          this.initCompleteTable();
        },
        aaSorting: [[2, 'asc']],
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        paging: false,
        dom: 't',
        searching: true,
        ordering: true,
        scrollY: 'calc(100vh - 330px)',
        scrollCollapse: true,
        columnDefs: [
          //{ targets: [0, 3], orderable: false },
          { targets: [0], width: '5px' },
          //{ targets: [1, 2], width: '10%' },
          //{ targets: [7, 8, 9, 10], width: '70px' },
          { targets: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], type: 'string-num' }
        ],
        autoWidth: false,
        orderCellsTop: true
      };
      this.tableByMenu = $('#analisiVenditeByMenu').DataTable(dtOptions);
      this.tableByMenu.columns.adjust().draw();
    }, 500)
  }

  detailByMenu(item: any) {
    let firstSubLevel = '';
    let index: number = 0;
    item.Items = item.Items.sort((a, b) => {
      if (a.GrossRevenue < b.GrossRevenue) return 1;
      else if (a.GrossRevenue > b.GrossRevenue) return -1;
      else return 0;
    });
    item.Items.forEach((subItem: any) => {

      if (this.ProductOrCodeActiveFilter && (!subItem.Product_Code.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter) &&
        !subItem.Product.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter)
      )) {
        return;
      }

      let row = `<tr class="detail">` +
        `<td></td>` +
        //`<td class="columnDetail">${subItem.Product_Code}</td>` +
        //`<td class="columnDetail">${subItem.Product}</td>` +
        `<td class="columnDetail columnDetail-padding-left" colspan="2">${subItem.Product_Code}&nbsp;<a href="javascript:;" data-id="${subItem.Id}" class="openGood"><i class="bi bi-pencil-square"></i></a>&nbsp;${subItem.Product}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.GrossPrice)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.NetPrice)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.FoodCost, 3)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.MC)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.Quantity, 2)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.GrossRevenue)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.TotalFoodCost)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.TotalMC)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.FoodCostPerc * 100, 2)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.PopularityPerc * 100, 2)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.MCPerc * 100, 2)}</td>`;

      if (index == 0) {
        row = row + `<td rowspan="${item.Items.length}" class="columnDetail text-right">${this.formatNumber(subItem.CurrentAvgFoodCost * 100, 2)}</td>` +
          `<td rowspan="${item.Items.length}" class="columnDetail text-right">${this.formatNumber(subItem.FoodCostDeviation, 2)}</td>`;
      }

      row = row + `</tr>`;
      firstSubLevel = firstSubLevel + row;
      index++;

    });
    return firstSubLevel;
  }

  showDetailByMenu(rowIndex: number, item: any) {
    let row = this.tableByMenu.row(rowIndex);
    if (row.child.isShown()) { // Se era già a perta la chiudo
      row.child.hide();
      item['shown'] = false;
    }
    else {
      row.child($(this.detailByMenu(item))).show();
      item['shown'] = true;

      $('.openGood').off('click');
      $('.openGood').on('click', (event: any) => {
        const merceId = $(event.target).closest('a').data('id');
        console.log(merceId);
        this.openRicetta(merceId);
      });
    }

  }

  showAllDetailByMenu() {
    let table = this.tableByMenu;
    this.loaderService.show();
    if (this.byMenuDataFiltered.allShown) {
      for (let index = 0; index < this.byMenuDataFiltered.Items.length; index++) {
        this.byMenuDataFiltered.Items[index]['shown'] = false;
        let row = table.row(index);
        row.child.hide();
      }
      this.byMenuDataFiltered.allShown = false;
    } else {
      for (let index: number = 0; index < this.byMenuDataFiltered.Items.length; index++) {
        let item = this.byMenuDataFiltered.Items[index];
        item['shown'] = true
        let row = table.row(index);
        row.child($(this.detailByMenu(item))).show();
      }
      this.byMenuDataFiltered.allShown = true;
    }
    this.loaderService.hide();

  }

  /****************************************************************************
____        _____                        _                        _       
|  _ \      |  __ \                      | |                      | |      
| |_) |_   _| |  | | ___ _ __   __ _ _ __| |_ _ __ ___   ___ _ __ | |_ ___ 
|  _ <| | | | |  | |/ _ \ '_ \ / _` | '__| __| '_ ` _ \ / _ \ '_ \| __/ __|
| |_) | |_| | |__| |  __/ |_) | (_| | |  | |_| | | | | |  __/ | | | |_\__ \
|____/ \__, |_____/ \___| .__/ \__,_|_|   \__|_| |_| |_|\___|_| |_|\__|___/
       __/ |           | |                                                
      |___/            |_|                                                
 
****************************************************************************/
  InitByDepartmentsDataTable() {
    if ($.fn.dataTable.isDataTable('#analisiVenditeByDepartments')) {
      $('#analisiVenditeByDepartments').DataTable().destroy();
    }
    // //console.log(JSON.stringify(this.currentFilterCfg));
    this.analisiVenditeService.getByDepartments(this.currentFilterCfg).toPromise()
      .then((ret: any) => {
        this.byDepartmentsData = ret;
        this.byDepartmentsData['allShown'] = false;
        this.byDepartmentsDataFiltered = JSON.parse(JSON.stringify(this.byDepartmentsData));
        this.loaderService.hide();
        this.filtriService.readOnly$.next(false);
        this.initTableDepartment();
      })
      .catch((error: any) => {
        this.loaderService.hide();
        this.filtriService.readOnly$.next(false);
      });
  }

  initTableDepartment() {
    setTimeout(() => {
      let dtOptions: any = {
        initComplete: () => {
          this.initCompleteTable();
        },
        aaSorting: [],
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">',
        },
        paging: false,
        dom: 't',
        searching: true,
        scrollX: false,
        scrollY: 'calc(100vh - 330px)',
        scrollCollapse: true,
        ordering: true,
        columnDefs: [
          { targets: [0, 2], orderable: false },
          { targets: [0], width: '5px' },
          { targets: [4], type: 'string-num' }, // POP%
        ],
        orderCellsTop: true,
        autoWidth: false
      };

      this.tableByDepartments = $('#analisiVenditeByDepartments').DataTable(dtOptions);
      //this.tableByDepartments.columns.adjust().draw();
    }, 100);
  }

  detailByDepartments(department: any) {
    let firstSubLevel = '';
    let index: number = 0;

    department.Items.forEach((typeItem: any) => {

      const title = typeItem.IsMenu ? this.translate.instant('ANALISI_VENDITE.DEPARTMENTS.IS_MENU') : this.translate.instant('ANALISI_VENDITE.DEPARTMENTS.NOT_MENU');

      let row = `<tr class="detail-header">
          <th></th>
          <th colspan="2">${title}</th>
          <th class="columnDetail text-right">${this.formatNumber(typeItem.Quantity, 2)}</th>
          <th class="columnDetail text-right">${this.formatNumber(typeItem.PopularityPerc * 100, 2)}</th>
          </tr>`;

      firstSubLevel = firstSubLevel + row;

      typeItem.Items.forEach((subItem: any) => {

        if (this.ProductOrCodeActiveFilter && (!subItem.Product_Code.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter) &&
          !subItem.Product.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter)
        )) {
          return;
        }

        let row = `<tr class="detail">` +
          `<td></td>` +
          `<td class="columnDetail columnDetail-padding-left" colspan="2">${subItem.Product_Code}&nbsp;<a href="javascript:;" data-id="${subItem.Id}" class="openGood"><i class="bi bi-pencil-square"></i></a>&nbsp;${subItem.Product}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subItem.Quantity, 2)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subItem.PopularityPerc * 100, 2)}</td>` +
          `</tr>`;
        firstSubLevel = firstSubLevel + row;
        index++;

      });

    });

    return firstSubLevel;
  }

  showDetailByDepartments(rowIndex: number, item: any) {
    let row = this.tableByDepartments.row(rowIndex);
    if (row.child.isShown()) { // Se era già a perta la chiudo
      row.child.hide();
      item['shown'] = false;

    } else {
      row.child($(this.detailByDepartments(item))).show();
      item['shown'] = true;

      $('.openGood').off('click');
      $('.openGood').on('click', (event: any) => {
        const merceId = $(event.target).closest('a').data('id');
        console.log(merceId);
        this.openRicetta(merceId);
      });
    }
  }

  showAllDetailByDepartment(forceExpand: boolean = false) {
    let table = this.tableByDepartments;
    this.loaderService.show();
    if (this.byDepartmentsDataFiltered.allShown && !forceExpand) {
      for (let index = 0; index < this.byDepartmentsDataFiltered.Items.length; index++) {
        this.byDepartmentsDataFiltered.Items[index]['shown'] = false;
        let row = table.row(index);
        row.child.hide();
      }
      this.byDepartmentsDataFiltered.allShown = false;
    } else {
      for (let index: number = 0; index < this.byDepartmentsDataFiltered.Items.length; index++) {
        let item = this.byDepartmentsDataFiltered.Items[index];
        item['shown'] = true
        let row = table.row(index);
        row.child($(this.detailByDepartments(item))).show();
      }
      this.byDepartmentsDataFiltered.allShown = true;
    }
    this.loaderService.hide();
  }

  /****************************************************************************
   ____         _____      _                        _
  |  _ \       / ____|    | |                      (_)
  | |_) |_   _| |     __ _| |_ ___  __ _  ___  _ __ _  ___  ___
  |  _ <| | | | |    / _` | __/ _ \/ _` |/ _ \| '__| |/ _ \/ __|
  | |_) | |_| | |___| (_| | ||  __/ (_| | (_) | |  | |  __/\__ \
  |____/ \__, |\_____\__,_|\__\___|\__, |\___/|_|  |_|\___||___/
          __/ |                     __/ |
          |___/                     |___/
  ****************************************************************************/
  InitByCategoriesDataTable() {
    if ($.fn.dataTable.isDataTable('#analisiVenditeByCategories')) {
      $('#analisiVenditeByCategories').DataTable().destroy();
    }
    this.analisiVenditeService.getByCategories(this.currentFilterCfg).toPromise()
      .then((ret: any) => {
        this.byCategoriesData = ret;
        this.byCategoriesData['allShown'] = false;
        this.byCategoriesDataFiltered = JSON.parse(JSON.stringify(this.byCategoriesData));
        this.loaderService.hide();
        this.filtriService.readOnly$.next(false);
        this.initTableCategory();
      })
      .catch((error: any) => {
        this.loaderService.hide();
        this.filtriService.readOnly$.next(false);
      });
  }

  initTableCategory() {
    setTimeout(() => {
      let dtOptions: any = {
        initComplete: () => {
          this.initCompleteTable();
        },
        aaSorting: [],
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">',
        },
        paging: false,
        dom: 't',
        searching: true,
        scrollX: false,
        scrollY: 'calc(100vh - 330px)',
        scrollCollapse: true,
        //autoWidth: true,
        ordering: true,
        columnDefs: [
          { targets: [0, 2], orderable: false },
          //{ targets: [0], width: '5px' },
          //{ targets: [1, 2], width: '8%' }
        ],
        orderCellsTop: true,
        autoWidth: false
      };

      if (!this.vendite1000) {
        dtOptions.columnDefs.push({ targets: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], type: 'string-num' });
        //dtOptions.columnDefs.push({ targets: [7, 8, 9, 10], width: '70px' });
      }

      this.tableByCategories = $('#analisiVenditeByCategories').DataTable(dtOptions);

      this.tableByCategories.columns.adjust().draw();
    }, 500);
  }

  detailByCategories(item: any) {
    let firstSubLevel = '';
    let index: number = 0;
    item.Items = item.Items.sort((a, b) => {
      if (a.GrossRevenue < b.GrossRevenue) return 1;
      else if (a.GrossRevenue > b.GrossRevenue) return -1;
      else return 0;
    });
    item.Items.forEach((subItem: any) => {

      if (this.ProductOrCodeActiveFilter && (!subItem.Product_Code.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter) &&
        !subItem.Product.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter)
      )) {
        return;
      }

      let row = `<tr class="detail">` +
        `<td></td>` +
        //`<td class="columnDetail">${subItem.Product_Code}</td>` +
        `<td class="columnDetail columnDetail-padding-left" colspan="2">${subItem.Product_Code}&nbsp;<a href="javascript:;" data-id="${subItem.Id}" class="openGood"><i class="bi bi-pencil-square"></i></a>&nbsp;${subItem.Product}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.GrossPrice)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.NetPrice)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.FoodCost, 3)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.MC)}</td>` +
        `<td class="columnDetail text-right">${this.formatNumber(subItem.Quantity, 2)}</td>`;

      if (this.vendite1000) {
        row += `<td class="columnDetail text-right">${this.formatNumber(subItem.Quantity * 1000 / this.byCategoriesDataFiltered.Covers)}</td>`;
      } else {
        row += `<td class="columnDetail text-right">${this.formatNumber(subItem.GrossRevenue)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subItem.TotalFoodCost, 3)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subItem.TotalMC)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subItem.FoodCostPerc * 100, 2)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subItem.PopularityPerc * 100, 2)}</td>` +
          `<td class="columnDetail text-right">${this.formatNumber(subItem.MCPerc * 100, 2)}</td>`;
        if (index == 0) {
          row = row + `<td rowspan="${item.Items.length}" class="columnDetail text-right">${this.formatNumber(item.CurrentAvgFoodCost * 100, 2)}</td>` +
            `<td rowspan="${item.Items.length}" class="columnDetail text-right">${this.formatNumber(item.FoodCostDeviation, 2)}</td>`;
        }
      }

      row = row + `</tr>`;
      firstSubLevel = firstSubLevel + row;
      index++;

    });

    return firstSubLevel;
  }

  showDetailByCategories(rowIndex: number, item: any) {
    let row = this.tableByCategories.row(rowIndex);
    if (row.child.isShown()) { // Se era già a perta la chiudo
      row.child.hide();
      item['shown'] = false;

    } else {
      row.child($(this.detailByCategories(item))).show();
      item['shown'] = true;

      $('.openGood').off('click');
      $('.openGood').on('click', (event: any) => {
        const merceId = $(event.target).closest('a').data('id');
        console.log(merceId);
        this.openRicetta(merceId);
      });
    }
  }

  showAllDetailByCategory(forceExpand: boolean = false) {
    let table = this.tableByCategories;
    this.loaderService.show();
    if (this.byCategoriesDataFiltered.allShown && !forceExpand) {
      for (let index = 0; index < this.byCategoriesDataFiltered.Items.length; index++) {
        this.byCategoriesDataFiltered.Items[index]['shown'] = false;
        let row = table.row(index);
        row.child.hide();
      }
      this.byCategoriesDataFiltered.allShown = false;
    } else {
      for (let index: number = 0; index < this.byCategoriesDataFiltered.Items.length; index++) {
        let item = this.byCategoriesDataFiltered.Items[index];
        item['shown'] = true
        let row = table.row(index);
        row.child($(this.detailByCategories(item))).show();
      }
      this.byCategoriesDataFiltered.allShown = true;
    }
    this.loaderService.hide();
  }

  performVendite1000() {
    $('#analisiVenditeByCategories').DataTable().destroy();
    this.vendite1000 = !this.vendite1000;
    this.vendite1000ShowHeader = false;
    this.loaderService.show();
    setTimeout(() => {
      this.vendite1000ShowHeader = true;
      this.initTableCategory();
      this.loaderService.hide();
    }, 1000);
  }

  /****************************************************************************
    ______                       _
   |  ____|                     | |
   | |__  __  ___ __   ___  _ __| |_
   |  __| \ \/ / '_ \ / _ \| '__| __|
   | |____ >  <| |_) | (_) | |  | |_
   |______/_/\_\ .__/ \___/|_|   \__|
               | |
               |_|
   ****************************************************************************/

  exportXLSXCategories() {

    const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
    const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
    const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
    const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
    const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
    const _noButton = this.translate.instant('EXPORT_XLSX.NOBUTTON');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(expand => {
      this.loaderService.show();
      let xlsx: any[] = [];
      let rowsStyles: any = [];
      let headerRows: number = 2;

      const columnsInfo = [
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
      ];

      // aggiungo intestazione
      let totali = {
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_AVG')}`,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.AvgGrossPrice, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.AvgNetPrice, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.FoodCost, 3),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.AvgMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.Quantity, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.GrossRevenue, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.TotalFoodCost, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.TotalMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.AvgFCPerc * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.PopularityPerc * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}%`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.MCPerc * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CURRENT_AVG_FC')}`]: this.utility.formatNumberExcel(this.byCategoriesDataFiltered.CurrentAvgFoodCost * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.DEVIATION')}`]: this.utility.formatNumberExcel((this.byCategoriesDataFiltered.AvgFCPerc - this.byCategoriesDataFiltered.CurrentAvgFoodCost) * 100, 2)
      };
      xlsx.push(totali);

      let parentIndex: number = headerRows;
      let merges: any[] = [];
      let row = 0;
      let sortedList = this.utility.sortList(this.byCategoriesDataFiltered.Items, 'analisiVenditeByCategories');
      sortedList.forEach((item: any) => {
        xlsx.push({
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${item.Category}`,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(item.AvgGrossPrice, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(item.AvgNetPrice, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(item.AvgMC, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.GrossRevenue, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(item.AvgFCPerc * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(item.PopularityPerc * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}%`]: this.utility.formatNumberExcel(item.MCPerc * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CURRENT_AVG_FC')}`]: this.utility.formatNumberExcel(item.CurrentAvgFoodCost * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.DEVIATION')}`]: this.utility.formatNumberExcel((item.AvgFCPerc - item.CurrentAvgFoodCost) * 100, 2)
        });

        if (expand) {
          rowsStyles[parentIndex] = {
            fgColor: { rgb: 'ffdd45' },
            bold: true,
            alignment: {
              horizontal: "right"
            }
          };
          let index = 0;
          item.Items.forEach((item: any) => {
            xlsx.push({
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${item.Product_Code}`,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${item.Product}`,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(item.GrossPrice, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(item.NetPrice, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(item.MC, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.GrossRevenue, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(item.FoodCostPerc * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(item.PopularityPerc * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}%`]: this.utility.formatNumberExcel(item.MCPerc * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CURRENT_AVG_FC')}`]: ` `,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.DEVIATION')}`]: ` `
            });
            index++;
            parentIndex++;
          });

        }
        row++;
        parentIndex++;
      });

      this.loaderService.hide();

      this.excelService.exportAsExcelFile(xlsx, this.translate.instant('ANALISI_VENDITE.TABS.CATEGORIES').trim(), merges, columnsInfo, headerRows, rowsStyles, !expand);
      this.layoutUtilsService.showActionNotification(_success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

    });


  }

  exportXLSXDepartments() {

    const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
    const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
    const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
    const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
    const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
    const _noButton = this.translate.instant('EXPORT_XLSX.NOBUTTON');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(expand => {
      this.loaderService.show();
      let xlsx: any[] = [];
      let rowsStyles: any = [];
      let headerRows: number = 2;

      const columnsInfo = [
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      ];

      // aggiungo intestazione
      let totali = {
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_AVG')}`,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(this.byDepartmentsDataFiltered.Quantity, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(this.byDepartmentsDataFiltered.PopularityPerc * 100, 2),
      };
      xlsx.push(totali);

      let parentIndex: number = headerRows;
      let merges: any[] = [];
      let row = 0;
      let sortedList = this.utility.sortList(this.byDepartmentsDataFiltered.Items, 'analisiVenditeByDepartments');
      sortedList.forEach((department: any) => {
        //this.byDepartmentsDataFiltered.Items.forEach((item: any) => {
        xlsx.push({
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${department.Department}`,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(department.Quantity, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(department.PopularityPerc * 100, 2),
        });

        if (expand) {
          rowsStyles[parentIndex] = {
            fgColor: { rgb: 'ffdd45' },
            bold: true,
            alignment: {
              horizontal: "right"
            }
          };
          let index = 0;

          department.Items.forEach((typeItem: any,) => {

            rowsStyles[++parentIndex] = {
              fgColor: { rgb: 'cccccc' },
              bold: true,
              alignment: {
                horizontal: "right"
              }
            };

            const title = typeItem.IsMenu ? this.translate.instant('ANALISI_VENDITE.DEPARTMENTS.IS_MENU') : this.translate.instant('ANALISI_VENDITE.DEPARTMENTS.NOT_MENU');

            xlsx.push({
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${title}`,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ``,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(typeItem.Quantity, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(typeItem.PopularityPerc * 100, 2),
            });

            typeItem.Items.forEach((subItem: any) => {

              if (this.ProductOrCodeActiveFilter && (!subItem.Product_Code.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter) &&
                !subItem.Product.toLocaleLowerCase().includes(this.ProductOrCodeActiveFilter)
              )) {
                return;
              }

              xlsx.push({
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${subItem.Product_Code}`,
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${subItem.Product}`,
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(subItem.Quantity, 2),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(subItem.PopularityPerc * 100, 2),
              });
              index++;
              parentIndex++;
            });
          });

        }
        row++;
        parentIndex++;
      });

      this.loaderService.hide();

      this.excelService.exportAsExcelFile(xlsx, this.translate.instant('ANALISI_VENDITE.TABS.DEPARTMENTS').trim(), merges, columnsInfo, headerRows, rowsStyles, !expand);
      this.layoutUtilsService.showActionNotification(_success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

    });


  }

  exportXLSXMenu() {

    const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
    const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
    const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
    const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
    const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
    const _noButton = this.translate.instant('EXPORT_XLSX.NOBUTTON');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(expand => {
      this.loaderService.show();
      let xlsx: any[] = [];
      let rowsStyles: any = [];
      let headerRows: number = 2;

      const columnsInfo = [
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
      ];

      // aggiungo intestazione
      let totali = {
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_AVG')}`,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.GrossPrice, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.NetPrice),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.FoodCost, 3),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.MC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.Quantity, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.GrossRevenue),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.TotalFoodCost),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.TotalMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.FoodCostPerc * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.PopularityPerc * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}%`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.MCPerc * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CURRENT_AVG_FC')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.CurrentAvgFoodCost * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.DEVIATION')}`]: this.utility.formatNumberExcel(this.byMenuDataFiltered.FoodCostDeviation, 2)
      };
      xlsx.push(totali);

      let parentIndex: number = headerRows;
      let merges: any[] = [];
      let row = 0;
      let sortedList = this.utility.sortList(this.byMenuDataFiltered.Items, 'analisiVenditeByMenu');
      sortedList.forEach((item: any) => {
        //this.byMenuDataFiltered.Items.forEach((item: any) => {
        xlsx.push({
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${item.Category}`,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(item.GrossPrice),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(item.NetPrice),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(item.MC, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.GrossRevenue),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(item.FoodCostPerc * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(item.PopularityPerc * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}%`]: this.utility.formatNumberExcel(item.MCPerc * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CURRENT_AVG_FC')}`]: this.utility.formatNumberExcel(item.CurrentAvgFoodCost * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.DEVIATION')}`]: this.utility.formatNumberExcel(item.FoodCostDeviation, 2)
        });
        if (expand) {
          rowsStyles[parentIndex] = {
            fgColor: { rgb: 'ffdd45' },
            bold: true,
            alignment: {
              horizontal: "right"
            }
          };
          let index = 0;
          item.Items.forEach((item: any) => {
            xlsx.push({
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${item.Product_Code}`,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${item.Product}`,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(item.GrossPrice),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(item.NetPrice),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(item.MC, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.GrossRevenue),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(item.FoodCostPerc * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`]: this.utility.formatNumberExcel(item.PopularityPerc * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}%`]: this.utility.formatNumberExcel(item.MCPerc * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CURRENT_AVG_FC')}`]: ` `,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.DEVIATION')}`]: ` `
            });
            index++;
            parentIndex++;
          });

          /*
          if ( index > 1 ) {
              merges.push( { s: { c: 13, r: row+3 }, e: { c: 13, r: row+3+index-1 } } );
              merges.push( { s: { c: 14, r: row+3 }, e: { c: 14, r: row+3+index-1 } } );
              //console.log(item.Category, row, index, merges);
          }
          */
        }
        row++;
        parentIndex++;
      });

      this.loaderService.hide();

      this.excelService.exportAsExcelFile(xlsx, 'MenuAnalysis', merges, columnsInfo, headerRows, rowsStyles, !expand);
      this.layoutUtilsService.showActionNotification(_success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

    });


  }

  exportXLSXTimeSlot() {

    const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
    const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
    const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
    const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
    const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
    const _noButton = this.translate.instant('EXPORT_XLSX.NOBUTTON');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(expand => {
      this.loaderService.show();
      let xlsx: any[] = [];
      let merges: any[] = [];
      let rowsStyles: any = [];
      let headerRows: number = 3;

      let columnsInfo = [
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      ];

      if (this.viewType === 'valore') {
        columnsInfo.push({ enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } });
      } else {
        columnsInfo.push({ enabled: true, format: '#.##0', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } });
      }

      // aggiungo intestazione
      let intestazione = {
        [this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')]: ` `,
        [this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')]: ` `,
        [(this.viewType === 'valore') ? this.translate.instant('ANALISI_VENDITE.COLUMNS.VALUE') : this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')]: this.utility.formatNumberExcel(this.byTimeSlotDataFiltered.GrossPrice),
      };
      let col: number = 0;
      const offset: number = 3;
      let realCol: number = 0;
      this.byTimeSlotDataFiltered.TimeSlotValues.forEach((colonna: any) => {
        let colonnaVal = colonna.TimeSlot + ' ';
        let colonnaPerc = colonna.TimeSlot + '%';
        intestazione[colonnaVal] = (this.viewType === 'valore') ? this.translate.instant('ANALISI_VENDITE.COLUMNS.VAL') : this.translate.instant('ANALISI_VENDITE.COLUMNS.QTY');
        intestazione[colonnaPerc] = `%`;
        realCol = offset + (col * 2);
        merges.push({ s: { r: 0, c: realCol }, e: { r: 0, c: realCol + 1 } });
        col++;

        if (this.viewType === 'valore') {
          columnsInfo.push({ enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } });
        } else {
          columnsInfo.push({ enabled: true, format: '#.##0', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } });
        }
        columnsInfo.push({ enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } });
      });
      xlsx.push(intestazione);

      // Aggiungo totali
      let totali = {
        [this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL')}`,
        [this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')]: ` `,
        [(this.viewType === 'valore') ? this.translate.instant('ANALISI_VENDITE.COLUMNS.VALUE') : this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')]: this.switchTypeValQty(this.byTimeSlotDataFiltered, true),
      };

      this.byTimeSlotDataFiltered.TimeSlotValues.forEach((colonna: any) => {
        let colonnaVal = colonna.TimeSlot + ' ';
        let colonnaPerc = colonna.TimeSlot + '%';
        totali[colonnaVal] = (this.viewType === 'valore') ? this.utility.formatNumberExcel(colonna.GrossRevenue, 2) : this.utility.formatNumberExcel(colonna.Quantity, 2);
        totali[colonnaPerc] = (this.viewType === 'valore') ? this.utility.formatNumberExcel(colonna.ValuePercentage * 100, 2) : this.utility.formatNumberExcel(colonna.QuantityPercentage * 100, 2);
      });
      xlsx.push(totali);

      // Aggiungo body
      let parentIndex: number = headerRows;
      let row = 0;
      let sortedList = this.utility.sortList(this.byTimeSlotDataFiltered.Rows, 'analisiVenditeByTimeSlot');
      sortedList.forEach((item: any) => {
        //this.byTimeSlotDataFiltered.Rows.forEach((item: any) => {

        let riga = {
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${item.Category}`,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
          [(this.viewType === 'valore') ? this.translate.instant('ANALISI_VENDITE.COLUMNS.VALUE') : this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')]: this.switchTypeValQty(item, true),
        };

        // Aggiungo body
        item.TimeSlotValues.forEach((colonna: any) => {
          let colonnaVal = colonna.TimeSlot + ' ';
          let colonnaPerc = colonna.TimeSlot + '%';
          riga[colonnaVal] = (this.viewType === 'valore') ? this.utility.formatNumberExcel(colonna.GrossRevenue, 2) : this.utility.formatNumberExcel(colonna.Quantity, 2);
          riga[colonnaPerc] = (this.viewType === 'valore') ? this.utility.formatNumberExcel(colonna.ValuePercentage * 100, 2) : this.utility.formatNumberExcel(colonna.QuantityPercentage * 100, 2);
        });
        xlsx.push(riga);

        if (expand) {
          rowsStyles[parentIndex] = {
            fgColor: { rgb: 'ffdd45' },
            bold: true,
            alignment: {
              horizontal: "right"
            }
          };
          let index = 0;
          item.Products.forEach((item: any) => {
            let subRiga = {
              [this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')]: `${item.Id}`,
              [this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')]: `${item.Product}`,
              [(this.viewType === 'valore') ? this.translate.instant('ANALISI_VENDITE.COLUMNS.VALUE') : this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')]: this.switchTypeValQty(item, true),
            };
            item.TimeSlotValues.forEach((colonna: any) => {
              let colonnaVal = colonna.TimeSlot + ' ';
              let colonnaPerc = colonna.TimeSlot + '%';
              subRiga[colonnaVal] = (this.viewType === 'valore') ? this.utility.formatNumberExcel(colonna.GrossRevenue, 2) : this.utility.formatNumberExcel(colonna.Quantity, 2);
              subRiga[colonnaPerc] = (this.viewType === 'valore') ? this.utility.formatNumberExcel(colonna.ValuePercentage * 100, 2) : this.utility.formatNumberExcel(colonna.QuantityPercentage * 100, 2);
            });

            xlsx.push(subRiga);
            index++;
            parentIndex++;
          });

        }
        row++;
        parentIndex++;
      });

      this.loaderService.hide();


      this.excelService.exportAsExcelFile(xlsx, 'tileslotAnalysis', merges, columnsInfo, headerRows, rowsStyles, !expand);
      this.layoutUtilsService.showActionNotification(_success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

    });

  }

  exportXLSXCompany() {

    const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
    const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
    const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
    const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
    const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
    const _noButton = this.translate.instant('EXPORT_XLSX.NOBUTTON');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(expand => {
      this.loaderService.show();
      let xlsx: any[] = [];
      let rowsStyles: any = [];
      let headerRows: number = 2;

      const columnsInfo = [
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
        { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
      ];

      // aggiungo intestazione
      let totali = {
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_AVG')}`,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.AvgGrossPrice),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.AvgNetPrice),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.FoodCost, 3),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.AvgMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.Quantity, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_qty`]: this.utility.formatNumberExcel(1 * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.GrossRevenue),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_rev`]: this.utility.formatNumberExcel(1 * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.TotalFoodCost),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_fc`]: this.utility.formatNumberExcel(1 * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.TotalMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_mdc`]: this.utility.formatNumberExcel(1 * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(this.byCompanyDataFiltered.FCIncidence * 100, 2),
      };
      xlsx.push(totali);

      let parentIndex: number = headerRows;
      let merges: any[] = [];
      let row = 0;

      let sortedList = this.utility.sortList(this.byCompanyDataFiltered.Items, 'analisiVenditeByCompany');
      sortedList.forEach((item: any) => {

        //this.byCompanyDataFiltered.Items.forEach((item: any) => {
        xlsx.push({
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${item.Category}`,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(item.AvgGrossPrice),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(item.AvgNetPrice),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(item.AvgMC, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_qty`]: this.utility.formatNumberExcel(item.QuantityIncidence * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.GrossRevenue),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_rev`]: this.utility.formatNumberExcel(item.RevenueIncidence * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_fc`]: this.utility.formatNumberExcel(item.CostIncidence * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_mdc`]: this.utility.formatNumberExcel(item.MCIncidence * 100, 2),
          [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(item.FCIncidence * 100, 2)
        });
        if (expand) {
          rowsStyles[parentIndex] = {
            fgColor: { rgb: 'ffdd45' },
            bold: true,
            alignment: {
              horizontal: "right"
            }
          };
          item.Items.forEach((item: any) => {
            xlsx.push({
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${item.Company}`,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: ` `,
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(item.AvgGrossPrice),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(item.AvgNetPrice),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(item.AvgMC, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_qty`]: this.utility.formatNumberExcel(item.QuantityIncidence * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.GrossRevenue),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_rev`]: this.utility.formatNumberExcel(item.RevenueIncidence * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_fc`]: this.utility.formatNumberExcel(item.CostIncidence * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_mdc`]: this.utility.formatNumberExcel(item.MCIncidence * 100, 2),
              [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(item.FCIncidence * 100, 2)
            });
            parentIndex++;
            rowsStyles[parentIndex] = {
              fgColor: { rgb: 'e6edfb' },
              bold: true,
              alignment: {
                horizontal: "right"
              }
            };
            item.Items.forEach((item: any) => {
              xlsx.push({
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`]: `${item.Product_Code}`,
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${item.Product}`,
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`]: this.utility.formatNumberExcel(item.GrossPrice),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`]: this.utility.formatNumberExcel(item.NetPrice),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`]: this.utility.formatNumberExcel(item.MC, 2),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_qty`]: this.utility.formatNumberExcel(item.QuantityIncidence * 100, 2),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.GrossRevenue),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_rev`]: this.utility.formatNumberExcel(item.RevenueIncidence * 100, 2),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_fc`]: this.utility.formatNumberExcel(item.CostIncidence * 100, 2),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}_mdc`]: this.utility.formatNumberExcel(item.MCIncidence * 100, 2),
                [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`]: this.utility.formatNumberExcel(item.FoodCostPerc * 100, 2)
              });
              parentIndex++;
            });

          });
          /*
          if ( index > 1 ) {
              merges.push( { s: { c: 13, r: row+3 }, e: { c: 13, r: row+3+index-1 } } );
              merges.push( { s: { c: 14, r: row+3 }, e: { c: 14, r: row+3+index-1 } } );
              //console.log(item.Category, row, index, merges);
          }
          */
        }
        row++;
        parentIndex++;
      });

      this.loaderService.hide();

      this.excelService.exportAsExcelFile(xlsx, 'companyAnalysis', merges, columnsInfo, headerRows, rowsStyles, !expand);
      this.layoutUtilsService.showActionNotification(_success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

    });


  }

  exportXLSXMenuEngeenering() {

    this.loaderService.show();
    let xlsx: any[] = [];
    let rowsStyles: any = [];
    let headerRows: number = 2;

    const columnsInfo = [
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
    ];

    // aggiungo intestazione
    let totali = {
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL')}`,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.Quantity, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.TotalFoodCost),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.NetRevenue, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.TotalMC, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MC_CATEGORY')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MM_CATEGORY')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`]: ` `
    };
    xlsx.push(totali);

    let parentIndex: number = headerRows;
    let merges: any[] = [];
    let sortedList = this.utility.sortList(this.menuEngeeneringDataFiltered.Items, 'analisiVenditeMenuEngeenering');
    sortedList.forEach((item: any) => {
      //this.menuEngeeneringDataFiltered.Items.forEach((item: any) => {
      xlsx.push({

        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${item.Product}`,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`]: item.QuantityIncidence >= 0 ? (this.utility.formatNumberExcel(item.QuantityIncidence * 100, 2)) : ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: item.FoodCostPerc >= 0 ? this.utility.formatNumberExcel(item.FoodCostPerc * 100, 2) : ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`]: this.utility.formatNumberExcel(item.NetPrice),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`]: this.utility.formatNumberExcel(item.MC),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.NetRevenue, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MC_CATEGORY')}`]: item.MC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Quantity) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW'),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MM_CATEGORY')}`]: item.Quantity >= ((this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW'),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`]: this.getClassificationText(item),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`]: this.getAction(item)
      });
    });

    this.loaderService.hide();
    this.excelService.exportAsExcelFile(xlsx, 'menuengAnalysis', merges, columnsInfo, headerRows, rowsStyles, true);
  }

  exportXLSXCostiMarginali() {

    this.loaderService.show();
    let xlsx: any[] = [];
    let rowsStyles: any = [];
    let headerRows: number = 2;

    // aggiungo intestazione
    let totali = {
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL')}`,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.Quantity, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.TotalFoodCost),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.NetRevenue, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.TotalMC, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC_CATEGORY')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MC_CATEGORY')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`]: ` `
    };
    xlsx.push(totali);

    let parentIndex: number = headerRows;
    let merges: any[] = [];
    let sortedList = this.utility.sortList(this.menuEngeeneringDataFiltered.Items, 'analisiVenditeCostiMarginali');
    sortedList.forEach((item: any) => {
      //this.menuEngeeneringDataFiltered.Items.forEach((item: any) => {
      xlsx.push({

        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${item.Product}`,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`]: item.QuantityIncidence >= 0 ? (this.utility.formatNumberExcel(item.QuantityIncidence * 100, 2)) : ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: item.FoodCostPerc >= 0 ? this.utility.formatNumberExcel(item.FoodCostPerc * 100, 2) : ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`]: this.utility.formatNumberExcel(item.NetPrice),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`]: this.utility.formatNumberExcel(item.MC),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.NetRevenue, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC_CATEGORY')}`]: item.FoodCostPerc >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW'),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MC_CATEGORY')}`]: item.TotalMC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Items.length) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW'),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`]: this.getClassificationText(item),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`]: this.getAction(item)
      });
    });


    const columnsInfo = [
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
    ];

    this.loaderService.hide();

    this.excelService.exportAsExcelFile(xlsx, 'costAnalysis', merges, [], headerRows, rowsStyles);
  }

  exportXLSXFCPercentageMix() {

    this.loaderService.show();
    let xlsx: any[] = [];
    let rowsStyles: any = [];
    let headerRows: number = 2;

    // aggiungo intestazione
    let totali = {
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL')}`,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.Quantity, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.TotalFoodCost),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.NetRevenue, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.TotalMC, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC_CATEGORY')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MC_CATEGORY')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`]: ` `,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`]: ` `
    };
    xlsx.push(totali);

    let parentIndex: number = headerRows;
    let merges: any[] = [];
    let sortedList = this.utility.sortList(this.menuEngeeneringDataFiltered.Items, 'analisiVenditePercentageMatrix');
    sortedList.forEach((item: any) => {
      //this.menuEngeeneringDataFiltered.Items.forEach((item: any) => {
      xlsx.push({

        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${item.Product}`,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`]: item.QuantityIncidence >= 0 ? (this.utility.formatNumberExcel(item.QuantityIncidence * 100, 2)) : ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: item.FoodCostPerc >= 0 ? this.utility.formatNumberExcel(item.FoodCostPerc * 100, 2) : ` `,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`]: this.utility.formatNumberExcel(item.NetPrice),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`]: this.utility.formatNumberExcel(item.MC),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.NetRevenue, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC_CATEGORY')}`]: item.FoodCostPerc >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW'),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.MC_CATEGORY')}`]: item.Quantity >= ((this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW'),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`]: this.getClassificationText(item),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`]: this.getAction(item)
      });
    });

    this.loaderService.hide();

    const columnsInfo = [
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
    ];

    this.excelService.exportAsExcelFile(xlsx, 'fcpercAnalysis', merges, [], headerRows, rowsStyles);
  }

  exportXLSXFCAnalisiObbiettivi() {

    this.loaderService.show();
    let xlsx: any[] = [];
    let rowsStyles: any = [];
    let headerRows: number = 2;

    // aggiungo intestazione
    let totali = {
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.WEIGHTED_AVG')}`,
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.Quantity, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.NetPrice),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.GrossRevenue),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.FoodCost, 3),
      //[`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.FoodCostPerc * 100, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: this.utility.formatNumberExcel(this.getAnalisiObiettiviFCTot(), 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.TotalFoodCost),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.MC),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(this.menuEngeeneringDataFiltered.TotalMC, 2),
      [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TARGET')}`]: this.utility.formatNumberExcel(this.getAnalisiObiettiviTot(), 0),
    };
    xlsx.push(totali);

    let parentIndex: number = headerRows;
    let merges: any[] = [];

    const sortedItems = this.menuEngeeneringDataFiltered.Items.sort((a, b) => {
      const x = this.getAnalisiObiettiviRow(a);
      const y = this.getAnalisiObiettiviRow(b);
      if (x < y) return 1;
      else if (x > y) return -1;
      else return 0;
    });

    sortedItems.forEach((item: any) => {
      xlsx.push({
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`]: `${item.Product}`,
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`]: this.utility.formatNumberExcel(item.Quantity, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`]: this.utility.formatNumberExcel(item.NetPrice),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`]: this.utility.formatNumberExcel(item.GrossRevenue),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`]: this.utility.formatNumberExcel(item.FoodCost, 3),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`]: this.utility.formatNumberExcel(item.FoodCostPerc * 100, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`]: this.utility.formatNumberExcel(item.TotalFoodCost),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`]: this.utility.formatNumberExcel(item.MC),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`]: this.utility.formatNumberExcel(item.TotalMC, 2),
        [`${this.translate.instant('ANALISI_VENDITE.COLUMNS.TARGET')}`]: this.utility.formatNumberExcel(this.getAnalisiObiettiviRow(item), 0),
      });
    });

    const columnsInfo = [
      { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];

    this.loaderService.hide();

    this.excelService.exportAsExcelFile(xlsx, 'ObjAnalysis', merges, [], headerRows, rowsStyles);
  }

  exportAsXLSX() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
        this.exportXLSXCategories();
        break;
      case 1: // ByCategories
        this.exportXLSXDepartments();
        break;
      case 2: // ByMenu
        this.exportXLSXMenu();
        break;
      case 3: // ByTimeSlot
        this.exportXLSXTimeSlot();
        break;
      case 4: // ByCompany
        this.exportXLSXCompany();
        break;
      case 5: // MenuEngeenering
        this.exportXLSXMenuEngeenering();
        break;
      case 6: // CostiMarginali
        this.exportXLSXCostiMarginali();
        break;
      case 7: // PercentageMatrix
        this.exportXLSXFCPercentageMix();
        break;
      case 8: // Obiettivi
        this.exportXLSXFCAnalisiObbiettivi();
        break;
    }
  }

  getPDFTableWidths() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
        return this.vendite1000 ? ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      case 1: // ByDepartments
        return ['auto', '*', 'auto', 'auto'];//['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      case 2: // ByMenu
        return ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      case 3: // ByTimeSlot
        const columns = $('#analisiVenditeByTimeSlot>thead>tr:nth-child(2)>th').length - 1;
        let widths: string[] = Array(columns).fill('auto');
        widths[0] = '*';
        return widths;
      case 4: // ByCompany
        return ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      case 5: // MenuEngeenering
        return ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      case 6: // CostiMarginali
        return ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      case 7: // PercentageMatrix
        return ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      case 8: // Obiettivi
        return ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
    }
    return [];
  }

  getPDFTitle() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
        return this.translate.instant('EXPORT_PDF.TITLE_CATEGORIE');
      case 1: // ByDepartments
        return this.translate.instant('EXPORT_PDF.TITLE_DEPARTMENTS');
      case 2: // ByMenu
        return this.translate.instant('EXPORT_PDF.TITLE_MENU');
      case 3: // ByTimeSlot
        return this.translate.instant('EXPORT_PDF.TITLE_TIMESLOT');
      case 4: // ByCompany
        return this.translate.instant('EXPORT_PDF.TITLE_AZIENDE');
      case 5: // MenuEngeenering
        return this.translate.instant('EXPORT_PDF.TITLE_MENUENGEENERING');
      case 6: // CostiMarginali
        return this.translate.instant('EXPORT_PDF.TITLE_COSTIMARGINALI');
      case 7: // PercentageMatrix
        return this.translate.instant('EXPORT_PDF.TITLE_FCPERCENTAGEMATRIX');
      case 8: // Obiettivi
        return this.translate.instant('EXPORT_PDF.TITLE_OBBIETTIVI');
    }
  }

  preparePDFBodyCategory(expand) {
    let body: any = [];
    let bodyTmp: any;
    // aggiungo intestazione
    // totali Header

    bodyTmp = [
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' }
    ];

    if (this.vendite1000) {
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.TOOLSBAR.VENDITE')}`, style: 'tableHeaderStyle' });
    } else {
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`, style: 'tableHeaderStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`, style: 'tableHeaderStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`, style: 'tableHeaderStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`, style: 'tableHeaderStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`, style: 'tableHeaderStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}%`, style: 'tableHeaderStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CURRENT_AVG_FC')}`, style: 'tableHeaderStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.DEVIATION')}`, style: 'tableHeaderStyle' });
    }
    body.push(bodyTmp);

    // totali Values

    bodyTmp = [
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_AVG')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: '', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.AvgGrossPrice, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.AvgNetPrice, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.FoodCost, 3), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.AvgMC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.Quantity, 2), style: 'tableHeaderStyle' }
    ];

    if (this.vendite1000) {
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.Quantity * 1000 / this.byCategoriesDataFiltered.Covers), style: 'tableBodyStyle' });
    } else {
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.GrossRevenue, 2), style: 'tableBodyStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.TotalFoodCost, 2), style: 'tableBodyStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.TotalMC, 2), style: 'tableBodyStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.AvgFCPerc * 100, 2), style: 'tableBodyStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.PopularityPerc * 100, 2), style: 'tableBodyStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.MCPerc * 100, 2), style: 'tableBodyStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCategoriesDataFiltered.CurrentAvgFoodCost * 100, 2), style: 'tableBodyStyle' });
      bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber((this.byCategoriesDataFiltered.AvgFCPerc - this.byCategoriesDataFiltered.CurrentAvgFoodCost) * 100, 2), style: 'tableBodyStyle' });
    }
    body.push(bodyTmp);

    let rowIndex: number = 0;

    let sortedList = this.utility.sortList(this.byCategoriesDataFiltered.Items, 'analisiVenditeByCategories');

    //this.byCategoriesDataFiltered.Items.forEach((item: any) => {
    sortedList.forEach((item: any) => {

      bodyTmp = [
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: `${item.Category}` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.AvgGrossPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.AvgNetPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.AvgMC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.Quantity, 2) }
      ];

      if (this.vendite1000) {
        bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(item.Quantity * 1000 / item.Covers), style: 'tableBodyStyle' });
      } else {
        bodyTmp.push({ border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.GrossRevenue, 2) });
        bodyTmp.push({ border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) });
        bodyTmp.push({ border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) });
        bodyTmp.push({ border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.AvgFCPerc * 100, 2) });
        bodyTmp.push({ border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.PopularityPerc * 100, 2) });
        bodyTmp.push({ border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.MCPerc * 100, 2) });
        bodyTmp.push({ border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.CurrentAvgFoodCost * 100, 2) });
        bodyTmp.push({ border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber((item.AvgFCPerc - item.CurrentAvgFoodCost) * 100, 2) });
      }
      body.push(bodyTmp);

      rowIndex++;


      if (expand) {
        item.Items.forEach((item: any) => {
          bodyTmp = [
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'left', text: `${item.Product_Code}` },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'left', text: `${item.Product}` },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.GrossPrice, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.NetPrice, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.MC, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.Quantity, 2) }
          ];
          if (this.vendite1000) {
            bodyTmp.push({ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(item.Quantity * 1000 / item.Covers), style: 'tableBodyStyle' });
          } else {
            bodyTmp.push({ border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.GrossRevenue, 2) });
            bodyTmp.push({ border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) });
            bodyTmp.push({ border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) });
            bodyTmp.push({ border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.FoodCostPerc * 100, 2) });
            bodyTmp.push({ border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.PopularityPerc * 100, 2) });
            bodyTmp.push({ border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.MCPerc * 100, 2) });
            bodyTmp.push({ border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: ` ` });
            bodyTmp.push({ border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: ` ` });
          }
          body.push(bodyTmp);
        });
      }
    });

    return body;
  }

  preparePDFBodyDepartment(expand) {
    let body: any = [];
    let bodyTmp: any;
    // aggiungo intestazione
    // totali Header

    bodyTmp = [
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`, style: 'tableHeaderStyle' }
    ];

    body.push(bodyTmp);

    // totali Values
    bodyTmp = [
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_AVG')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: '', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byDepartmentsDataFiltered.Quantity, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byDepartmentsDataFiltered.PopularityPerc * 100, 2), style: 'tableHeaderStyle' }
    ];

    body.push(bodyTmp);

    let rowIndex: number = 0;

    let sortedList = this.utility.sortList(this.byDepartmentsDataFiltered.Items, 'analisiVenditeByDepartments');

    sortedList.forEach((item: any) => {

      bodyTmp = [
        { border: [true, true, true, true], fillColor: '#ffdd45', alignment: 'left', text: `${item.Department}` },
        { border: [true, true, true, true], fillColor: '#ffdd45', alignment: 'left', text: ` ` },
        { border: [true, true, true, true], fillColor: '#ffdd45', alignment: 'right', text: this.formatNumber(item.Quantity, 2) },
        { border: [true, true, true, true], fillColor: '#ffdd45', alignment: 'right', text: this.formatNumber(item.PopularityPerc * 100, 2) }
      ];

      body.push(bodyTmp);

      rowIndex++;

      if (expand) {
        item.Items.forEach((typeItem: any) => {

          const title = typeItem.IsMenu ? this.translate.instant('ANALISI_VENDITE.DEPARTMENTS.IS_MENU') : this.translate.instant('ANALISI_VENDITE.DEPARTMENTS.NOT_MENU');
          bodyTmp = [
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'left', text: `${title}` },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'left', text: `` },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(typeItem.Quantity, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(typeItem.PopularityPerc * 100, 2) }
          ];
          body.push(bodyTmp);

          typeItem.Items.forEach((subItem: any) => {

            bodyTmp = [
              { border: [true, true, true, true], fillColor: '', alignment: 'left', text: `${subItem.Product_Code}` },
              { border: [true, true, true, true], fillColor: '', alignment: 'left', text: `${subItem.Product}` },
              { border: [true, true, true, true], fillColor: '', alignment: 'right', text: this.formatNumber(subItem.Quantity, 2) },
              { border: [true, true, true, true], fillColor: '', alignment: 'right', text: this.formatNumber(subItem.PopularityPerc * 100, 2) }
            ];
            body.push(bodyTmp);

          });


        });
      }
    });

    return body;
  }

  preparePDFBodyMenu(expand) {
    let body: any = [];

    // aggiungo intestazione
    // totali Header
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.POP')}%`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}%`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CURRENT_AVG_FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.DEVIATION')}`, style: 'tableHeaderStyle' }
    ]);

    // totali Values
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_AVG')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: '', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.GrossPrice, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.NetPrice, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.FoodCost, 3), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.MC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.Quantity, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.GrossRevenue, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.TotalFoodCost, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.TotalMC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.FoodCostPerc * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.PopularityPerc * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.MCPerc * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.CurrentAvgFoodCost * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byMenuDataFiltered.FoodCostDeviation, 2), style: 'tableHeaderStyle' }
    ]);

    let rowIndex: number = 0;

    let sortedList = this.utility.sortList(this.byMenuDataFiltered.Items, 'analisiVenditeByMenu');

    //this.byMenuDataFiltered.Items.forEach((item: any) => {
    sortedList.forEach((item: any) => {

      body.push([
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: `${item.Category}` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.GrossPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.NetPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.MC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.Quantity, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.GrossRevenue, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCostPerc * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.PopularityPerc * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.MCPerc * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.CurrentAvgFoodCost * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCostDeviation, 2) }
      ]);
      rowIndex++;


      if (expand) {
        item.Items.forEach((item: any) => {
          body.push([
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'left', text: `${item.Product_Code}` },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'left', text: `${item.Product}` },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.GrossPrice, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.NetPrice, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.MC, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.Quantity, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.GrossRevenue, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.FoodCostPerc * 100, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.PopularityPerc * 100, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: this.formatNumber(item.MCPerc * 100, 2) },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: ` ` },
            { border: [true, true, true, true], fillColor: '#e6edfb', alignment: 'right', text: ` ` }
          ]);
        });
      }
    });

    return body;
  }

  preparePDFBodyTimeSlot(expand) {
    let body: any = [];
    let bodyTmp: any = [];
    // Aggiungo intestazione
    // Header1
    bodyTmp = [
      { alignment: 'left', border: [true, true, true, true], text: this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY'), style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
    ];
    this.byTimeSlotDataFiltered.TimeSlotValues.forEach((colonna: any) => {
      bodyTmp.push({ alignment: 'center', border: [true, true, true, true], text: colonna.TimeSlot, style: 'tableHeaderStyle' });
      bodyTmp.push({ alignment: 'center', border: [true, true, true, true], text: colonna.TimeSlot, style: 'tableHeaderStyle' });
    });
    body.push(bodyTmp);
    //console.log(body);
    // Header2
    bodyTmp = [
      { alignment: 'left', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: (this.viewType === 'valore') ? this.translate.instant('ANALISI_VENDITE.COLUMNS.VALUE') : this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY'), style: 'tableHeaderStyle' },
    ];
    this.byTimeSlotDataFiltered.TimeSlotValues.forEach((colonna: any) => {
      bodyTmp.push(
        { alignment: 'right', border: [true, true, true, true], text: (this.viewType === 'valore') ? this.translate.instant('ANALISI_VENDITE.COLUMNS.VAL') : this.translate.instant('ANALISI_VENDITE.COLUMNS.QTY'), style: 'tableHeaderStyle' },
        { alignment: 'right', border: [true, true, true, true], text: `%`, style: 'tableHeaderStyle' }
      );
    });
    body.push(bodyTmp);

    // Aggiungo totali
    bodyTmp = [
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.switchTypeValQty(this.byTimeSlotDataFiltered, true), style: 'tableHeaderStyle' },
    ];
    this.byTimeSlotDataFiltered.TimeSlotValues.forEach((colonna: any) => {
      bodyTmp.push(
        { alignment: 'right', border: [true, true, true, true], text: (this.viewType === 'valore') ? this.formatNumber(colonna.GrossRevenue, 2) : this.formatNumber(colonna.Quantity, 2), style: 'tableHeaderStyle' },
        { alignment: 'right', border: [true, true, true, true], text: (this.viewType === 'valore') ? this.formatNumber(colonna.ValuePercentage * 100, 2) : this.formatNumber(colonna.QuantityPercentage * 100, 2), style: 'tableHeaderStyle' },
      )
    });
    body.push(bodyTmp);

    // Aggiungo body
    let rowIndex: number = 0;

    let sortedList = this.utility.sortList(this.byTimeSlotDataFiltered.Rows, 'analisiVenditeByTimeSlot');
    sortedList.forEach((item: any) => {

      //this.byTimeSlotDataFiltered.Rows.forEach((item: any) => {		
      bodyTmp = [
        { alignment: 'left', fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', border: [true, true, true, true], text: `${item.Category}`, style: 'tableBodyStyle' },
        { alignment: 'left', fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', border: [true, true, true, true], text: ``, style: 'tableBodyStyle' },
        { alignment: 'right', fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', border: [true, true, true, true], text: this.switchTypeValQty(item, true), style: 'tableBodyStyle' },
      ];
      item.TimeSlotValues.forEach((colonna: any) => {
        bodyTmp.push(
          { alignment: 'right', fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', border: [true, true, true, true], text: (this.viewType === 'valore') ? this.formatNumber(colonna.GrossRevenue, 2) : this.formatNumber(colonna.Quantity, 2), style: 'tableBodyStyle' },
          { alignment: 'right', fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', border: [true, true, true, true], text: (this.viewType === 'valore') ? this.formatNumber(colonna.ValuePercentage * 100, 2) : this.formatNumber(colonna.QuantityPercentage * 100, 2), style: 'tableBodyStyle' },
        )
      });
      body.push(bodyTmp);

      if (expand) {
        item.Products.forEach((item: any) => {

          bodyTmp = [
            { alignment: 'left', fillColor: '#e6edfb', border: [true, true, true, true], text: `${item.Id}`, style: 'tableBodyStyle' },
            { alignment: 'left', fillColor: '#e6edfb', border: [true, true, true, true], text: `${item.Product}`, style: 'tableBodyStyle' },
            { alignment: 'right', fillColor: '#e6edfb', border: [true, true, true, true], text: this.switchTypeValQty(item, true), style: 'tableBodyStyle' },
          ];
          item.TimeSlotValues.forEach((colonna: any) => {
            bodyTmp.push(
              { alignment: 'right', fillColor: '#e6edfb', border: [true, true, true, true], text: (this.viewType === 'valore') ? this.formatNumber(colonna.GrossRevenue, 2) : this.formatNumber(colonna.Quantity, 2), style: 'tableBodyStyle' },
              { alignment: 'right', fillColor: '#e6edfb', border: [true, true, true, true], text: (this.viewType === 'valore') ? this.formatNumber(colonna.ValuePercentage * 100, 2) : this.formatNumber(colonna.QuantityPercentage * 100, 2), style: 'tableBodyStyle' },
            )
          });
          body.push(bodyTmp);


        });
      }
      rowIndex++;
    });

    return body;
  }

  preparePDFBodyCompany(expand) {
    let body: any = [];

    // aggiungo intestazione
    // totali Header
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.NET_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.INCIDENCE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}%`, style: 'tableHeaderStyle' }
    ]);

    // totali Values
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_AVG')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: '', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.AvgGrossPrice, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.AvgNetPrice, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.FoodCost, 3), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.AvgMC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.Quantity, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(1 * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.GrossRevenue, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(1 * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.TotalFoodCost, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(1 * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.TotalMC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(1 * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.byCompanyDataFiltered.FCIncidence * 100, 2), style: 'tableHeaderStyle' }
    ]);

    let rowIndex: number = 0;
    let sortedList = this.utility.sortList(this.byCompanyDataFiltered.Items, 'analisiVenditeByCompany');
    sortedList.forEach((item: any) => {

      //this.byCompanyDataFiltered.Items.forEach((item: any) => {

      body.push([
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: `${item.Category}` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.AvgGrossPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.AvgNetPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.AvgMC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.Quantity, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.QuantityIncidence * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.GrossRevenue, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.RevenueIncidence * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.CostIncidence * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.MCIncidence * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FCIncidence * 100, 2) }
      ]);
      rowIndex++;


      if (expand) {
        item.Items.forEach((item: any) => {
          body.push([
            { border: [true, true, true, true], alignment: 'left', text: `${item.Company}` },
            { border: [true, true, true, true], alignment: 'left', text: ` ` },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.AvgGrossPrice, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.AvgNetPrice, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.AvgMC, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.Quantity, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.QuantityIncidence * 100, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.GrossRevenue, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.RevenueIncidence * 100, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.CostIncidence * 100, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.MCIncidence * 100, 2) },
            { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.FCIncidence * 100, 2) }
          ]);
          item.Items.forEach((item: any) => {
            body.push([
              { border: [true, true, true, true], alignment: 'left', text: `${item.Product_Code}` },
              { border: [true, true, true, true], alignment: 'left', text: `${item.Product}` },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.GrossPrice, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.NetPrice, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.MC, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.Quantity, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.QuantityIncidence * 100, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.GrossRevenue, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.RevenueIncidence * 100, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.CostIncidence * 100, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.MCIncidence * 100, 2) },
              { border: [true, true, true, true], alignment: 'right', text: this.formatNumber(item.FoodCostPerc * 100, 2) }
            ]);
          });
        });
      }
    });


    return body;
  }

  preparePDFBodyMenuEngeenering(expand) {
    let body: any = [];

    // aggiungo intestazione
    // totali Header
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MC_CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MM_CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`, style: 'tableHeaderStyle' }
    ]);

    // totali Values
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.Quantity, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.TotalFoodCost, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.NetRevenue, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.TotalMC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
    ]);

    let rowIndex: number = 0;

    let sortedList = this.utility.sortList(this.menuEngeeneringDataFiltered.Items, 'analisiVenditeMenuEngeenering');
    sortedList.forEach((item: any) => {

      //this.menuEngeeneringDataFiltered.Items.forEach((item: any) => {

      body.push([
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: `${item.Product}` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.Quantity, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.QuantityIncidence >= 0 ? (this.formatNumber(item.QuantityIncidence * 100, 2)) : ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.FoodCostPerc >= 0 ? this.formatNumber(item.FoodCostPerc * 100, 2) : ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.NetPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.MC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.NetRevenue, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.MC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Quantity) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW') },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.Quantity >= ((this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW') },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.getClassificationText(item) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.getAction(item) },
      ]);
      rowIndex++;
    });


    return body;
  }

  preparePDFBodyCostiMarginali(expand) {
    let body: any = [];

    // aggiungo intestazione
    // totali Header
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC_CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MC_CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`, style: 'tableHeaderStyle' }
    ]);

    // totali Values
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.Quantity, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.TotalFoodCost, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.NetRevenue, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.TotalMC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
    ]);

    let rowIndex: number = 0;

    let sortedList = this.utility.sortList(this.menuEngeeneringDataFiltered.Items, 'analisiVenditeCostiMarginali');
    sortedList.forEach((item: any) => {

      //this.menuEngeeneringDataFiltered.Items.forEach((item: any) => {

      body.push([
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: `${item.Product}` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.Quantity },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.QuantityIncidence >= 0 ? (this.formatNumber(item.QuantityIncidence * 100, 2)) : ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.FoodCostPerc >= 0 ? this.formatNumber(item.FoodCostPerc * 100, 2) : ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.NetPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.MC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.NetRevenue, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.FoodCostPerc >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW') },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.TotalMC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Items.length) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW') },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.getClassificationText(item) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.getAction(item) },
      ]);
      rowIndex++;
    });


    return body;
  }

  preparePDFBodyPercentageMatrix(expand) {
    let body: any = [];

    // aggiungo intestazione
    // totali Header
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MENU_MIX')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC_CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.MM_CATEGORY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.CLASSIFICATION')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.ACTION')}`, style: 'tableHeaderStyle' }
    ]);

    // totali Values
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.menuEngeeneringDataFiltered.Quantity, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.TotalFoodCost, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.NetRevenue, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.TotalMC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
    ]);

    let rowIndex: number = 0;

    let sortedList = this.utility.sortList(this.menuEngeeneringDataFiltered.Items, 'analisiVenditePercentageMatrix');
    sortedList.forEach((item: any) => {

      //this.menuEngeeneringDataFiltered.Items.forEach((item: any) => {

      body.push([
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: `${item.Product}` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.Quantity },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.QuantityIncidence >= 0 ? (this.formatNumber(item.QuantityIncidence * 100, 2)) : ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.FoodCostPerc >= 0 ? this.formatNumber(item.FoodCostPerc * 100, 2) : ` ` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.NetPrice, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.MC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.NetRevenue, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.FoodCostPerc >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW') },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: item.Quantity >= ((this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length) ? this.translate.instant('ANALISI_VENDITE.LABELS.HIGH') : this.translate.instant('ANALISI_VENDITE.LABELS.LOW') },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.getClassificationText(item) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.getAction(item) },
      ]);
      rowIndex++;
    });


    return body;
  }

  preparePDFBodyObiettivi(expand) {
    let body: any = [];

    // aggiungo intestazione
    // totali Header
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.SALES_PRICE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.GROSS_REVENUE')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.FC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_FOOD_COST')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.PRODUCT_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TOTAL_MDC')}`, style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.TARGET')}`, style: 'tableHeaderStyle' },
    ]);

    // totali Values
    body.push([
      { alignment: 'left', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_VENDITE.COLUMNS.WEIGHTED_AVG')}`, style: 'tableHeaderStyle' },
      { alignment: 'left', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.Quantity, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.NetPrice), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.GrossRevenue), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.FoodCost, 3), style: 'tableHeaderStyle' },
      //{ alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.FoodCostPerc * 100, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.getAnalisiObiettiviFCTot(), 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.TotalFoodCost), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.MC), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.menuEngeeneringDataFiltered.TotalMC, 2), style: 'tableHeaderStyle' },
      { alignment: 'right', border: [true, true, true, true], text: this.formatNumber(this.getAnalisiObiettiviTot(), 0), style: 'tableHeaderStyle' },
    ]);

    let rowIndex: number = 0;
    let sortedList = this.utility.sortList(this.menuEngeeneringDataFiltered.Items, 'analisiVenditeObiettivi');
    sortedList.forEach((item: any) => {

      //this.menuEngeeneringDataFiltered.Items.forEach((item: any) => {

      body.push([
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: `${item.Product}` },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'left', text: this.formatNumber(item.Quantity, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.GrossPrice) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.GrossRevenue, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCost, 3) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.FoodCostPerc * 100, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalFoodCost, 0) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.MC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(item.TotalMC, 2) },
        { border: [true, true, true, true], fillColor: (rowIndex % 2 === 0) ? '#f7f8fa' : '', alignment: 'right', text: this.formatNumber(this.getAnalisiObiettiviRow(item), 0) },
      ]);
      rowIndex++;
    });


    return body;
  }

  getPDFTableBody(expand) {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
        return this.preparePDFBodyCategory(expand);
      case 1: // ByDepartments
        return this.preparePDFBodyDepartment(expand);
      case 2: // ByMenu
        return this.preparePDFBodyMenu(expand);
      case 3: // ByTimeSlot
        return this.preparePDFBodyTimeSlot(expand);
      case 4: // ByCompany
        return this.preparePDFBodyCompany(expand);
      case 5: // MenuEngeenering
        return this.preparePDFBodyMenuEngeenering(expand);
      case 6: // CostiMarginali
        return this.preparePDFBodyCostiMarginali(expand);
      case 7: // PercentageMatrix
        return this.preparePDFBodyPercentageMatrix(expand);
      case 8: // Obiettivi
        return this.preparePDFBodyObiettivi(expand);
    }
  }

  getCurrentModel() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
        return this.byCategoriesDataFiltered;
      case 1: // ByDepartments
        return this.byDepartmentsDataFiltered;
      case 2: // ByMenu
        return this.byMenuDataFiltered;
      case 3: // ByTimeSlot
        return this.byTimeSlotDataFiltered;
      case 4: // ByCompany
        return this.byCompanyDataFiltered;
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
      case 8: // Obiettivi
        return this.menuEngeeneringDataFiltered;
    }
  }

  getCurrentTable() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
        return this.tableByCategories;
      case 1: // ByDepartments
        return this.tableByDepartments;
      case 2: // ByMenu
        return this.tableByMenu;
      case 3: // ByTimeSlot
        return this.tableByTimeSlots;
      case 4: // ByCompany
        return this.tableByCompany;
      case 5: // MenuEngeenering
        return this.tableMenuEngeenering;
      case 6: // CostiMarginali
        return this.tableCostiMarginali;
      case 7: // PercentageMatrix
        return this.tablePercentageMatrix;
      case 8: // Obiettivi
        return this.tableObiettivi;
    }
  }

  PDFPreferences: any;
  exportAsPDF(expand: boolean = false) {

    let askExplodeRows: boolean = false;

    switch (this.currentTabIndex) {
      case 0: // ByCategories
      case 1: // ByDepartments
      case 2: // ByMenu
      case 3: // ByTimeSlot
      case 4: // ByCompany
        askExplodeRows = true;
        break;
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
      case 8: // Obiettivi
        askExplodeRows = false;
        break;
    }

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: askExplodeRows,
      explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
    };

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      //if (result) this.preparePdfConfiguration(result);
      if (result) {
        result['header'] = {
          export_title: this.getPDFTitle(),
          period: this.filtriService.getCurrentPeriod()
        };
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        };
        result['language'] = this.translationService.getSelectedLanguage();

        this.PDFPreferences = result;

        this.pdfService.makePdf(result, this.getPDFTableBody(result.explode), this.getPDFTableWidths())

        //(this.getCurrentTable()).button( 0 ).trigger();
      };
    });

  }

  initTable() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
        this.initTableCategory();
        break;
      case 1: // ByDepartments
        this.initTableDepartment();
        break;
      case 2: // ByMenu
        this.initTableMenu();
        break;
      case 3: // ByTimeSlot
        this.initTableTimeSlot();
        break;
      case 4: // ByCompany
        this.initTableCompany();
        break;
      case 5: // MenuEngeenering
        this.initTableMenuEngeenering();
        break;
      case 6: // CostiMarginali
        this.initTableCostiMarginali();
        break;
      case 7: // PercentageMatrix
        this.initTablePercentageMatrix();
        break;
      case 8: // Obiettivi
        this.initTableObiettivi();
        break;
    }
  }

  canExpand() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
      case 1: // ByDepartmens
      case 2: // ByMenu
      case 4: // ByCompany
        return false;
      case 3: // ByTimeSlot
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
      case 8: // Obiettivi
        return !$('.MenuEngeeneringTotali').hasClass('expanded');
    }
    return false;
  }

  canCollapse() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
      case 1: // ByDepartmens
      case 2: // ByMenu
      case 4: // ByCompany
        return false;
      case 3: // ByTimeSlot
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
      case 8: // Obiettivi
        return $('.MenuEngeeneringTotali').hasClass('expanded');
    }
    return false;
  }

  expandTot() {
    switch (this.currentTabIndex) {
      case 3: // ByTimeSlot
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
      case 8: // Obiettivi
        $('.MenuEngeeneringTotali').addClass('expanded');
        break;
    }
  }

  collapseTot() {
    switch (this.currentTabIndex) {
      case 3: // ByTimeSlot
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
      case 8: // Obiettivi
        $('.MenuEngeeneringTotali').removeClass('expanded');
        $('.MenuEngeeneringTotali').addClass('collapsed');
        break;
    }
  }

  canExpandGraph() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
      case 1: // ByDepartmens
      case 2: // ByMenu
      case 3: // ByTimeSlot
      case 4: // ByCompany
      case 8: // Obiettivi
        return false;
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
        return !$('.MenuEngeeneringGraph').hasClass('expanded');
    }
    return false;
  }

  canCollapseGraph() {
    switch (this.currentTabIndex) {
      case 0: // ByCategories
      case 1: // ByDepartmens
      case 2: // ByMenu
      case 3: // ByTimeSlot
      case 4: // ByCompany
      case 8: // Obiettivi
        return false;
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
        return $('.MenuEngeeneringGraph').hasClass('expanded');
    }
    return false;
  }

  firstTime = true;
  expandGraph() {
    console.log('expandGraph');
    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
        if ($.fn.dataTable.isDataTable('#analisiVenditeMenuEngeenering')) {
          $('#analisiVenditeMenuEngeenering').DataTable().destroy();
        }
        if ($.fn.dataTable.isDataTable('#analisiVenditeCostiMarginali')) {
          $('#analisiVenditeCostiMarginali').DataTable().destroy();
        }
        if ($.fn.dataTable.isDataTable('#analisiVenditePercentageMatrix')) {
          $('#analisiVenditeCostiMarginali').DataTable().destroy();
        }
        $('.MenuEngeeneringGraph').addClass('expanded');
        $('.MenuEngeeneringGraph').removeClass('collapsed');
        if (!this.firstTime) {
          this.refreshGraph();
        } else {
          this.firstTime = false;
          setTimeout(() => {
            this.initSelectCategorie()
            // this.refreshGraph(); // il refresh graph lo faccio già all'interno di initSelectCategorie
            this.ref.detectChanges();
          }, 1000);
        }

        break;
    }
  }

  collapseGraph(forceRealoadData: boolean = false) {

    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
      case 6: // CostiMarginali
      case 7: // PercentageMatrix
        $('.MenuEngeeneringGraph').removeClass('expanded');

        if (forceRealoadData) {
          this.selectCategorie();
        } else {

          $('.MenuEngeeneringGraph').addClass('collapsed');
          setTimeout(() => {
            this.initTable();
          }, 100);
        }
        break;
    }
  }

  refreshGraph() {
    // Forzo l'altezza del canvas
    //$('#canvasContainerWrapper').css('height', $('.canvasContainer').height() + 'px');

    let labels: any = [];
    let points: any = [];

    this.graphData = {
      labels: labels,
      datasets: []
    };

    let model;

    if (!this.selectedCategorie || this.selectedCategorie.length == 0) {
      model = this.categorieDisponibili.map(cat => cat.Id);
    } else {
      model = this.selectedCategorie;
    }

    if (!model || model.length == 0) return

    //let Items: any = [];
    model.forEach((category: any) => {

      const prodotti = this.menuEngeeneringDataFiltered.Items.filter(prodotto => prodotto.CategoryId == category);
      //Items = Items.concat(prodotti);
      let index: number = 1;
      prodotti.forEach((prodotto: any) => {
        let points: any = [];
        switch (this.currentTabIndex) {
          case 5: //menuEngeenering
            points.push({ 'x': parseFloat(prodotto.MC).toFixed(2), 'y': (prodotto.Quantity).toFixed(2), 'r': 10, 'v': index });
            break;
          case 6: // costiMarginali
            points.push({ 'x': (parseFloat(prodotto.FoodCostPerc) * 100).toFixed(2), 'y': parseFloat(prodotto.TotalMC).toFixed(2), 'r': 10, 'v': index });
            break;
          case 7: // percentageMatrix
            points.push({ 'x': (parseFloat(prodotto.FoodCostPerc) * 100).toFixed(2), 'y': (prodotto.Quantity).toFixed(2), 'r': 10, 'v': index });
            break;
        }
        let dataset = {
          label: `(${index}) ${prodotto.Product}`,
          backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset2'),//this.getRandomBlue(),
          data: points,
          title: 12,
          yAxisID: "y",
          xAxisID: "x",
          MC: prodotto.MC,
          Quantity: prodotto.Quantity,
          TotalMC: prodotto.TotalMC,
          TotalFoodCost: prodotto.TotalFoodCost,
          GrossRevenue: prodotto.GrossRevenue,
          FoodCostPerc: prodotto.FoodCostPerc
        }
        this.graphData.datasets.push(dataset);
        index++;
      })
    });

    // Siccome per la prima volta menuEngeeneringDataFiltered.Items contiene tutto, lo filtro subito
    //this.menuEngeeneringDataFiltered.Items = Items;

    this.graphData.datasets = this.graphData.datasets.sort((a: any, b: any) => {
      const valueA = this.getClassificationValue(a);
      const valueB = this.getClassificationValue(b);
      return valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
    });

    let oldIcon = '';
    this.graphData.datasets.forEach((dataset: any) => {
      dataset.Icon = this.getClassificationIcon(dataset);
      dataset.Unicode = this.getClassificationUnicode(dataset);
      if (dataset.Unicode !== oldIcon) {
        oldIcon = dataset.Unicode;
      }
    });

    const legendsPds: any = _.groupBy(this.graphData.datasets, 'Unicode');
    const legendsChart: any = _.groupBy(this.graphData.datasets, 'Icon');
    this.graphData.legendsPdf = [];
    this.graphData.legendsChart = [];

    Object.keys(legendsPds).forEach(key => {
      this.graphData.legendsPdf.push({
        unicode: key,
        value: legendsPds[key]
      });
    })

    Object.keys(legendsChart).forEach(key => {
      this.graphData.legendsChart.push({
        icon: key,
        value: legendsChart[key]
      });
    })

    this.initChartJS();
  }

  /** Init chart */
  initChartJS(elementId: string = 'menuEngeeneringGraph') {
    // Chart.register(ChartAnnotation);
    // Chart.register(ChartDataLabels);
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    if (this.myChart) {
      this.myChart.destroy();
    }

    const el = <HTMLCanvasElement>document.getElementById(elementId);
    var ctx: any = el.getContext('2d');

    let lineaVerticale: number = 0;
    let lineaOrizzontale: number = 0;
    let labelAsseY: string = '';
    let labelAsseX: string = '';

    switch (this.currentTabIndex) {
      case 5: //menuEngeenering
        lineaVerticale = parseFloat(this.getMdcPonderatoGraph().toFixed(2));
        lineaOrizzontale = parseFloat(this.getMediaPopolaritaGraph().toFixed(2));
        labelAsseX = this.translate.instant('ANALISI_VENDITE.MENU_ENGEENERING.GRAPH.LABEL_X');
        labelAsseY = this.translate.instant('ANALISI_VENDITE.MENU_ENGEENERING.GRAPH.LABEL_Y');
        break;
      case 6: // costiMarginali
        lineaVerticale = parseFloat(this.getFoodCostTotaleGraph().toFixed(2));
        lineaOrizzontale = parseFloat(this.getMdcTotalePonderatoGraph().toFixed(2));
        labelAsseX = this.translate.instant('ANALISI_VENDITE.MARGINAL_COSTS.GRAPH.LABEL_X');
        labelAsseY = this.translate.instant('ANALISI_VENDITE.MARGINAL_COSTS.GRAPH.LABEL_Y');
        break;
      case 7: // percentageMatrix
        lineaVerticale = parseFloat(this.getFoodCostTotaleGraph().toFixed(2));
        lineaOrizzontale = parseFloat(this.getMediaPopolaritaGraph().toFixed(2));
        labelAsseX = this.translate.instant('ANALISI_VENDITE.FC_PERCENTAGE_MATRIX.GRAPH.LABEL_X');
        labelAsseY = this.translate.instant('ANALISI_VENDITE.FC_PERCENTAGE_MATRIX.GRAPH.LABEL_Y');
        break;
    }

    // Serve per esportazione in PDF per background bianco, altrimenti rimane nero.
    const bgColor = {
      id: 'bgColor',
      beforeDraw: (chart: any, options: any) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    }

    const getQuadrant = (chart, mouseX, mouseY, iconSize: number = 24) => {
      /*
          4 | 1
          -----
          3 | 2
      */
      const xLeft = chart.chartArea.left;
      const xRight = chart.chartArea.right - 30;
      const yTop = chart.chartArea.top - 30;
      const yBottom = chart.chartArea.bottom + 50;

      if (mouseX >= xRight && mouseX <= (xRight + iconSize) && mouseY <= yTop && mouseY >= (yTop - iconSize)) {
        return 1;
      } else if (mouseX >= xRight && mouseX <= (xRight + iconSize) && mouseY <= yBottom && mouseY >= (yBottom - iconSize)) {
        return 2;
      } else if (mouseX >= xLeft && mouseX <= (xLeft + iconSize) && mouseY <= yBottom && mouseY >= (yBottom - iconSize)) {
        return 3;
      } else if (mouseX >= xLeft && mouseX <= (xLeft + iconSize) && mouseY <= yTop && mouseY >= (yTop - iconSize)) {
        return 4;
      }

      return 0;
    }

    const options: any = {
      type: 'bubble',
      data: this.graphData,
      plugins: [
        // ChartDataLabels,
        bgColor,
        {
          id: 'drawIcons',
          afterDraw: (chart: any) => {

            const canvas = <HTMLCanvasElement>document.getElementById(elementId);
            if (!canvas) return;
            const ctx: any = canvas.getContext('2d');
            const font = '900 24px "Font Awesome 5 Free"';

            (document as any).fonts.load(font).then((_) => {
              ctx.font = font;
              ctx.fillStyle = this.layoutConfigService.getConfig('colors.graph.dataset1');
              /*
              4 | 1
              -----
              3 | 2
              */
              const xLeft = chart.chartArea.left;
              const xRight = chart.chartArea.right - 30;
              const yTop = chart.chartArea.top - 30;
              const yBottom = chart.chartArea.bottom + 50;

              switch (this.currentTabIndex) {
                case 5: // MenuEngeenering
                  ctx.fillText("\uF588", xRight, yTop); // Quadrante 1 => bi-star
                  ctx.fillText("\uF12E", xRight, yBottom); // Quadrante 2 => fa-puzzle-piece
                  ctx.fillText("\uF6D3", xLeft, yBottom); // Quadrante 3 => fa-dog
                  ctx.fillText("\uF6F0", xLeft, yTop); // Quadrante 4 => fa-horse
                  break;
                case 6: // CostiMarginali
                  ctx.fillText("\uF6D1", xRight, yTop); // Quadrante 1 => fa-dice-d6
                  ctx.fillText("\uF06A", xRight, yBottom); // Quadrante 2 => fa-exclamation-circle
                  ctx.fillText("\uF236", xLeft, yBottom); // Quadrante 3 => fa-bed
                  ctx.fillText("\uF164", xLeft, yTop); // Quadrante 4 => fa-thumbs-up
                  break;
                case 7: // PercentageMatrix
                  ctx.fillText("\uF525", xRight, yTop); // Quadrante 1 => fa-dice-one
                  ctx.fillText("\uF165", xRight, yBottom); // Quadrante 2 => fa-thumbs-down
                  ctx.fillText("\uF528", xLeft, yBottom); // Quadrante 3 => fa-dice-two
                  ctx.fillText("\uF091", xLeft, yTop); // Quadrante 4 => fa-trophy
                  break;
              }

            })
          },
          afterEvent: (chart, e) => {
            if (e.event.type == 'mousemove') {
              const iconSize = 24;
              const quadrant = getQuadrant(chart, e.event.x, e.event.y, iconSize);
              if (quadrant >= 1 && quadrant <= 4) {

                if ($('#canvasContainerWrapper').find('.iconTooltip').length > 0) {
                  return;
                }

                const xLeft = chart.chartArea.left;
                const xRight = chart.chartArea.right;
                const yTop = chart.chartArea.top;
                const yBottom = chart.chartArea.bottom;

                const iconTooltip = document.createElement('div');
                iconTooltip.style.background = 'rgba(0, 0, 0, .7)';
                iconTooltip.className = "iconTooltip";
                iconTooltip.style.color = 'white';
                iconTooltip.style.position = 'absolute';

                let classification = '';

                switch (this.currentTabIndex) {
                  case 5: // MenuEngeenering
                    if (quadrant == 1) classification = this.translate.instant('ANALISI_VENDITE.LABELS.STARS_TOOLTIP');
                    else if (quadrant == 2) classification = this.translate.instant('ANALISI_VENDITE.LABELS.PUZZLES_TOOLTIP');
                    else if (quadrant == 3) classification = this.translate.instant('ANALISI_VENDITE.LABELS.DOGS_TOOLTIP');
                    else if (quadrant == 4) classification = this.translate.instant('ANALISI_VENDITE.LABELS.PLOWHORSES_TOOLTIP');
                    else classification = '';
                    break;
                  case 6: // CostiMarginali
                    if (quadrant == 1) classification = this.translate.instant('ANALISI_VENDITE.LABELS.STANDARDS_TOOLTIP');
                    else if (quadrant == 2) classification = this.translate.instant('ANALISI_VENDITE.LABELS.PROBLEMS_TOOLTIP');
                    else if (quadrant == 3) classification = this.translate.instant('ANALISI_VENDITE.LABELS.SLEEPERS_TOOLTIP');
                    else if (quadrant == 4) classification = this.translate.instant('ANALISI_VENDITE.LABELS.PRIMES_TOOLTIP');
                    else classification = '';
                    break;
                  case 7: // PercentageMatrix
                    if (quadrant == 1) classification = this.translate.instant('ANALISI_VENDITE.LABELS.MARGINALS_1_TOOLTIP');
                    else if (quadrant == 2) classification = this.translate.instant('ANALISI_VENDITE.LABELS.LOSERS_TOOLTIP');
                    else if (quadrant == 3) classification = this.translate.instant('ANALISI_VENDITE.LABELS.MARGINALS_2_TOOLTIP');
                    else if (quadrant == 4) classification = this.translate.instant('ANALISI_VENDITE.LABELS.WINNERS_TOOLTIP');
                    else classification = '';
                    break;
                }

                switch (quadrant) {
                  case 1:
                    {
                      const canvas = <HTMLCanvasElement>document.getElementById(elementId);
                      const ctx: any = canvas.getContext('2d');
                      ctx.font = "500 1rem 'Barlow Condensed";
                      const textWidth = ctx.measureText(classification).width;

                      iconTooltip.style.top = yTop - 50 + 'px';
                      iconTooltip.style.left = xRight - textWidth - 30 + 'px';
                      break;
                    }
                  case 2:
                    {
                      const canvas = <HTMLCanvasElement>document.getElementById(elementId);
                      const ctx: any = canvas.getContext('2d');
                      ctx.font = "500 1rem 'Barlow Condensed'";
                      const textWidth = ctx.measureText(classification).width

                      iconTooltip.style.top = yBottom - iconSize + 55 + 'px';
                      iconTooltip.style.left = xRight - textWidth - 30 + 'px';
                      break;
                    }
                  case 3:
                    iconTooltip.style.top = yBottom - iconSize + 55 + 'px';
                    iconTooltip.style.left = xLeft + 30 + 'px';
                    break;
                  case 4:
                    iconTooltip.style.top = yTop - 50 + 'px';
                    iconTooltip.style.left = xLeft + 30 + 'px';
                    break;
                }

                $(iconTooltip).text(classification);

                $('#canvasContainerWrapper').append(iconTooltip);


              } else {
                $('.iconTooltip').remove();
              }
            }
          }
        },
      ],
      options: {
        elements: {
          lineAnnotation: {
            drawTime: 'beforeDatasetsDraw',
            label: {
              drawTime: 'afterDraw'
            }
          }
        },
        interaction: {
          mode: 'point'
        },
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            bodyFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            titleFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            footerFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            // external: (tooltip) => {
            // 	//tooltip.displayColors = false;
            // },
            displayColors: false,
            padding: 10,
            caretPadding: 10,
            callbacks: {
              title: (tooltipItem) => {
                return tooltipItem[0].label;
              },
              label: (tooltipItem) => {
                let x, y;

                switch (this.currentTabIndex) {
                  case 5: //menuEngeenering
                    x = this.translate.instant('ANALISI_VENDITE.MENU_ENGEENERING_GRAPH.MC_X');
                    y = this.translate.instant('ANALISI_VENDITE.MENU_ENGEENERING_GRAPH.QUANTITY_Y');
                    break;
                  case 6: // costiMarginali
                    x = this.translate.instant('ANALISI_VENDITE.MENU_ENGEENERING_GRAPH.FOODCOSTPERC_X');
                    y = this.translate.instant('ANALISI_VENDITE.MENU_ENGEENERING_GRAPH.TOTAL_MC_Y');
                    break;
                  case 7: // percentageMatrix
                    x = this.translate.instant('ANALISI_VENDITE.MENU_ENGEENERING_GRAPH.FOODCOSTPERC_X');
                    y = this.translate.instant('ANALISI_VENDITE.MENU_ENGEENERING_GRAPH.QUANTITY_Y');
                    break;
                }
                return `${x}: ${tooltipItem.chart.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex]['x']}, ${y}: ${tooltipItem.chart.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex]['y']}`;
              },
              afterLabel: (tooltipItem) => {
                return `[${this.getGraphTooltipsClassificationText(tooltipItem.chart.data.datasets[tooltipItem.datasetIndex])}]`;
              }
            }
          },
          legend: {
            labels: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              }
            },
            display: false,
          },
          datalabels: {
            anchor: 'center',
            align: 'center',
            color: 'white',
            font: {
              weight: 'bold',
              size: 9
            },
            formatter: function (value) {
              return Math.round(value.v);
            },
            offset: 2,
            padding: 0
          },
          annotation: {
            annotations: {
              lineaVerticale: {
                type: 'line',
                xMin: lineaVerticale,
                xMax: lineaVerticale,
                // scaleID: 'x',
                // value: lineaVerticale,
                borderColor: this.layoutConfigService.getConfig('colors.graph.dataset1'), //'rgb(75, 192, 192)',
                borderWidth: 2,
              },
              lineaOrizzontale: {
                type: 'line',
                yMin: lineaOrizzontale,
                yMax: lineaOrizzontale,
                // scaleID: 'y',
                // value: lineaOrizzontale,
                borderColor: this.layoutConfigService.getConfig('colors.graph.dataset1'), //'rgb(75, 192, 192)',
                borderWidth: 2,
              }
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            stacked: false,
            beginAtZero: true,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: 0,

            },
            title: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: labelAsseX
            }
          },
          y: {
            display: true,
            stacked: false,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: 0,
            },
            title: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: labelAsseY
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 50,
            bottom: 20
          }
        }
      }
    };

    this.myChart = new Chart(ctx, options);

    this.ref.detectChanges();
  }

  getTitleByTabIndex() {
    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
        return this.translate.instant('ANALISI_VENDITE.TABS.MENU_ENGEENERING');
      case 6: // CostiMarginali
        return this.translate.instant('ANALISI_VENDITE.TABS.MARGINAL_COSTS');
      case 7: // PercentageMatrix
        return this.translate.instant('ANALISI_VENDITE.TABS.FC_PERCENTAGE_MATRIX');
    }
  }

  printChart() {
    // Imposto provvisoriamente maintainAspectRatio a true per stampare correttamente su pdf
    //this.myChart.options.maintainAspectRatio = true;
    this.myChart.update();
    setTimeout(() => {
      var canvas = <HTMLCanvasElement>document.getElementById('menuEngeeneringGraph');
      this.pdfService.chartToPdf(canvas, this.getTitleByTabIndex(), this.graphData.legendsPdf);
      //this.myChart.options.maintainAspectRatio = false;
      //this.myChart.update();
    }, 100);
  }

  getGraphTooltipsClassificationText(item: any) {
    let highMDC: boolean = false;
    let highPop: boolean = false;
    let highFC: boolean = false;

    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
        highMDC = item.MC >= this.getMdcPonderatoGraph();
        highPop = item.Quantity >= this.getMediaPopolaritaGraph();
        if (highMDC && highPop) return this.translate.instant('ANALISI_VENDITE.LABELS.STARS');
        if (!highMDC && highPop) return this.translate.instant('ANALISI_VENDITE.LABELS.PLOWHORSES');
        if (highMDC && !highPop) return this.translate.instant('ANALISI_VENDITE.LABELS.PUZZLES');
        if (!highMDC && !highPop) return this.translate.instant('ANALISI_VENDITE.LABELS.DOGS');
        break;
      case 6: // CostiMarginali
        highMDC = item.TotalMC >= this.getMdcTotalePonderatoGraph();
        highFC = item.FoodCostPerc * 100 >= this.getFoodCostTotaleGraph();
        if (highMDC && highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.STANDARDS');
        if (!highMDC && highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.PROBLEMS');
        if (highMDC && !highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.PRIMES');
        if (!highMDC && !highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.SLEEPERS');
        break;
      case 7: // PercentageMatrix
        highPop = item.Quantity >= this.getMediaPopolaritaGraph();
        highFC = item.FoodCostPerc * 100 >= this.getFoodCostTotaleGraph();
        if (highPop && highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.MARGINALS_1');
        if (!highPop && highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.LOSERS');
        if (highPop && !highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.WINNERS');
        if (!highPop && !highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.MARGINALS_2');
        break;
    }
  }

  getClassificationText(item: any) {
    let highMDC: boolean = false;
    let highPop: boolean = false;
    let highFC: boolean = false;

    //console.log(item);

    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
        highMDC = item.MC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Quantity);
        highPop = item.Quantity >= (this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length;
        if (highMDC && highPop) return this.translate.instant('ANALISI_VENDITE.LABELS.STARS');
        if (!highMDC && highPop) return this.translate.instant('ANALISI_VENDITE.LABELS.PLOWHORSES');
        if (highMDC && !highPop) return this.translate.instant('ANALISI_VENDITE.LABELS.PUZZLES');
        if (!highMDC && !highPop) return this.translate.instant('ANALISI_VENDITE.LABELS.DOGS');
        break;
      case 6: // CostiMarginali
        highMDC = item.TotalMC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Items.length);
        highFC = item.FoodCostPerc * 100 >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        if (highMDC && highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.STANDARDS');
        if (!highMDC && highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.PROBLEMS');
        if (highMDC && !highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.PRIMES');
        if (!highMDC && !highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.SLEEPERS');
        break;
      case 7: // PercentageMatrix
        highPop = item.Quantity >= (this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length;
        highFC = item.FoodCostPerc * 100 >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        if (highPop && highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.MARGINALS_1');
        if (!highPop && highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.LOSERS');
        if (highPop && !highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.WINNERS');
        if (!highPop && !highFC) return this.translate.instant('ANALISI_VENDITE.LABELS.MARGINALS_2');
        break;
    }
  }

  getClassificationIcon(item: any) {
    let highMDC: boolean = false;
    let highPop: boolean = false;
    let highFC: boolean = false;

    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
        highMDC = item.MC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Quantity);
        highPop = item.Quantity >= (this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length;
        if (highMDC && highPop) return 'bi-star';//this.translate.instant('ANALISI_VENDITE.LABELS.STARS');
        if (!highMDC && highPop) return 'fa-horse';//this.translate.instant('ANALISI_VENDITE.LABELS.PLOWHORSES');
        if (highMDC && !highPop) return 'fa-puzzle-piece';//this.translate.instant('ANALISI_VENDITE.LABELS.PUZZLES');
        if (!highMDC && !highPop) return 'fa-dog';//this.translate.instant('ANALISI_VENDITE.LABELS.DOGS');
        break;
      case 6: // CostiMarginali
        highMDC = item.TotalMC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Items.length);
        highFC = item.FoodCostPerc * 100 >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        if (highMDC && highFC) return 'fa-dice-d6';
        if (!highMDC && highFC) return 'fa-exclamation-circle';
        if (highMDC && !highFC) return 'fa-thumbs-up';
        if (!highMDC && !highFC) return 'fa-bed';
        break;
      case 7: // PercentageMatrix
        highPop = item.Quantity >= (this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length;
        highFC = item.FoodCostPerc * 100 >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        if (highPop && highFC) return 'fa-dice-one'; // MARGINALS_1
        if (!highPop && highFC) return 'fa-thumbs-down'; // LOSERS
        if (highPop && !highFC) return 'fa-trophy'; // WINNERS
        if (!highPop && !highFC) return 'fa-dice-two'; // MARGINALS_2
        break;
    }
    return '';
  }

  getClassificationUnicode(item: any) {
    let highMDC: boolean = false;
    let highPop: boolean = false;
    let highFC: boolean = false;

    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
        highMDC = item.MC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Quantity);
        highPop = item.Quantity >= (this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length;
        if (highMDC && highPop) return 'f005';
        if (!highMDC && highPop) return 'f6f0';
        if (highMDC && !highPop) return 'f12e';
        if (!highMDC && !highPop) return 'f6d3';
        break;
      case 6: // CostiMarginali
        highMDC = item.TotalMC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Items.length);
        highFC = item.FoodCostPerc * 100 >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        if (highMDC && highFC) return 'f6d1';
        if (!highMDC && highFC) return 'f06a';
        if (highMDC && !highFC) return 'f164';
        if (!highMDC && !highFC) return 'f236';
        break;
      case 7: // PercentageMatrix
        highPop = item.Quantity >= (this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length;
        highFC = item.FoodCostPerc * 100 >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        if (highPop && highFC) return 'f525'; // MARGINALS_1
        if (!highPop && highFC) return 'f165'; // LOSERS
        if (highPop && !highFC) return 'f091'; // WINNERS
        if (!highPop && !highFC) return 'f528'; // MARGINALS_2
        break;
    }
    return '';
  }

  getClassificationValue(item: any): number {
    let highMDC: boolean = false;
    let highPop: boolean = false;
    let highFC: boolean = false;

    let value = 0;

    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
        highMDC = item.MC >= this.getMdcPonderatoGraph(); //(this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Quantity);
        highPop = item.Quantity >= this.getMediaPopolaritaGraph();//(this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.graphData.datasets.length;
        if (highMDC && highPop) value = item.TotalMC * 1000000; // Quadrante 1
        if (!highMDC && highPop) value = item.TotalMC * 10000;// Quadrante 4
        if (highMDC && !highPop) value = item.TotalMC * 100; // Quadrante 2
        if (!highMDC && !highPop) value = item.TotalMC; // Quadrante 3
        break;
      case 6: // CostiMarginali
        highMDC = item.TotalMC >= this.getMdcTotalePonderatoGraph();//(this.menuEngeeneringDataFiltered.TotalMC / this.graphData.datasets.length);
        highFC = item.FoodCostPerc * 100 >= this.getFoodCostTotaleGraph();//(this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        if (highMDC && !highFC) value = item.TotalMC * 1000000; // Quadrante 4
        if (highMDC && highFC) value = item.TotalMC * 10000;   // Quadrante 1              
        if (!highMDC && highFC) value = item.TotalMC * 100;  // Quadrante 2
        if (!highMDC && !highFC) value = item.TotalMC;  // Quadrante 3
        break;
      case 7: // PercentageMatrix
        highPop = item.Quantity >= this.getMediaPopolaritaGraph();//(this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.graphData.datasets.length;
        highFC = item.FoodCostPerc * 100 >= this.getFoodCostTotaleGraph();//(this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        if (highPop && !highFC) value = item.TotalMC * 1000000; // Quadrante 4
        if (highPop && highFC) value = item.TotalMC * 10000; // Quadrante 1
        if (!highPop && highFC) value = item.TotalMC * 100;  // Quadrante 2
        if (!highPop && !highFC) value = item.TotalMC;  // Quadrante 3
        break;
    }

    return value;
  }

  getAction(item: any) {
    let highMDC: boolean = false;
    let highPop: boolean = false;
    let highFC: boolean = false;

    switch (this.currentTabIndex) {
      case 5: // MenuEngeenering
        highMDC = item.MC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Quantity);
        highPop = item.Quantity >= (this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length;
        // STARS
        if (highMDC && highPop) return this.translate.instant('ANALISI_VENDITE.ACTIONS.MAINTAIN');
        // PLOWHORSES
        if (!highMDC && highPop) return this.translate.instant('ANALISI_VENDITE.ACTIONS.INCREASE');
        // PUZZLES
        if (highMDC && !highPop) return this.translate.instant('ANALISI_VENDITE.ACTIONS.PROMOTE');
        // DOGS
        if (!highMDC && !highPop) return this.translate.instant('ANALISI_VENDITE.ACTIONS.REMOVE');
        break;
      case 6: // CostiMarginali
        highMDC = item.TotalMC >= (this.menuEngeeneringDataFiltered.TotalMC / this.menuEngeeneringDataFiltered.Items.length);
        highFC = item.FoodCostPerc * 100 >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        // STANDARDS
        if (highMDC && highFC) return this.translate.instant('ANALISI_VENDITE.ACTIONS.INCREASE');
        // PROBLEMS
        if (!highMDC && highFC) return this.translate.instant('ANALISI_VENDITE.ACTIONS.REMOVE');
        // PRIMES
        if (highMDC && !highFC) return this.translate.instant('ANALISI_VENDITE.ACTIONS.MAINTAIN');
        // SLEEPERS
        if (!highMDC && !highFC) return this.translate.instant('ANALISI_VENDITE.ACTIONS.PROMOTE');
        break;
      case 7: // PercentageMatrix
        highPop = item.Quantity >= (this.menuEngeeneringDataFiltered.Quantity * this.parseLivelloMinimoRendita()) / this.menuEngeeneringDataFiltered.Items.length;
        highFC = item.FoodCostPerc * 100 >= (this.menuEngeeneringDataFiltered.TotalFoodCost / this.menuEngeeneringDataFiltered.GrossRevenue) * 100;
        // MARGINALS_1
        if (highPop && highFC) return this.translate.instant('ANALISI_VENDITE.ACTIONS.INCREASE');
        // LOSERS
        if (!highPop && highFC) return this.translate.instant('ANALISI_VENDITE.ACTIONS.REMOVE');
        // WINNERS
        if (highPop && !highFC) return this.translate.instant('ANALISI_VENDITE.ACTIONS.MAINTAIN');
        // MARGINALS_2
        if (!highPop && !highFC) return this.translate.instant('ANALISI_VENDITE.ACTIONS.PROMOTE');
        break;
    }
  }

  performFilterNaN(dataset: any, timeSlots: boolean = false) {
    let tmpItems: any[] = [];
    if (!dataset || dataset.length == 0) return tmpItems;
    dataset.forEach((item: any) => {
      let exlude: boolean = false;
      for (let key in item) {

        if (timeSlots) {
          if (key === 'Products') {
            item[key] = this.performFilterNaN(item[key]);
          }
        } else {
          if (item.hasOwnProperty(key) && this.hidePricesZero && key === 'GrossPrice' && (item[key] === 0 || item[key] === 'NaN')) {
            exlude = true;
            break;
          }
          if (item.hasOwnProperty(key) && this.hideCostsZero && key === 'FoodCost' && (item[key] === 0 || item[key] === 'NaN')) {
            exlude = true;
            break;
          }

          if (key === 'Items') {
            item[key] = this.performFilterNaN(item[key]);
          }
        }
      }

      // tutto ok aggiungo
      if (!exlude) {
        tmpItems.push(item);
      }
    });
    return tmpItems
  }

  onTbHideChange() {
    this.currentFilterCfg['hidePricesZero'] = this.hidePricesZero;
    this.currentFilterCfg['hideCostsZero'] = this.hideCostsZero;
    this.currentFilterCfg['hideFcHigher100'] = this.hideFcHigher100;
    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }
  /*******************************************************************************
   *  TOTAL AND AVERAGE CALCULATIONS
   *******************************************************************************/
  calculateTotals(datasetName, viewModel) {
    switch (datasetName) {
      case "byCategoriesData":
      case "byDepartmentsData":
      case "byMenuData":
      case "byCompanyData":
        break;
      case "menuEngeeneringData":
        break;
    }
  }
  /*******************************************************************************
   *  RECURSIVE BY ENGEENERING TOTAL AND AVERAGE CALCULATIONS
   *******************************************************************************/
  menuEngeeneringCalculate(viewModel) {
    let Quantity = 0.0;
    let TotalFoodCost = 0.0;
    let GrossRevenue = 0.0;
    let NetRevenue = 0.0;
    let TotalMC = 0.0;
    let NetPrice = 0.0;
    let FoodCost = 0.0;
    let FoodCostPerc = 0.0;
    let MC = 0.0;
    viewModel.Items.forEach((element: any) => {
      Quantity += element.Quantity;
      TotalFoodCost += element.TotalFoodCost;
      GrossRevenue += element.GrossRevenue;
      NetRevenue += element.NetRevenue;
      TotalMC += element.TotalMC;
      NetPrice += element.NetPrice * element.Quantity;
      FoodCost += element.FoodCost * element.Quantity;
      FoodCostPerc += element.FoodCostPerc * element.Quantity;
      MC += element.MC * element.Quantity;
    });
    viewModel.Quantity = Quantity;
    viewModel.TotalFoodCost = TotalFoodCost;
    viewModel.GrossRevenue = GrossRevenue;
    viewModel.NetRevenue = NetRevenue;
    viewModel.TotalMC = TotalMC;
    viewModel.NetPrice = NetPrice / Quantity;
    viewModel.FoodCost = FoodCost / Quantity;
    viewModel.FoodCostPerc = FoodCostPerc / Quantity;
    viewModel.MC = TotalMC / Quantity;


    viewModel.Items.forEach((element: any) => {
      element.QuantityIncidence = element.Quantity / Quantity;
    });

    //console.log('menuEngeeneringCalculate', viewModel.NetPrice);
  }

  /*******************************************************************************
   *  RECURSIVE BY CATEGORIES/MENUS TOTAL AND AVERAGE CALCULATIONS
   *******************************************************************************/
  byCategoryCalculate(level, viewModel, parent) {
    //console.log('byCategoryCalculate', level, viewModel, parent);
    let GrossRevenue = 0.0;
    let NetRevenue = 0.0;
    let Quantity = 0.0;
    let TotalFoodCost = 0.0;
    let TotalMC = 0.0;

    let count = 0;
    let countRowGrossPrice = 0;
    let countRowNetPrice = 0;
    let countRowFoodCost = 0;
    let countRowFoodCostPerc = 0;
    let countRowMC = 0;

    let countGP = 0;
    let countFC = 0;
    let countMC = 0;
    let GrossPrice = 0.0;
    let NetPrice = 0.0;
    let FoodCost = 0.0;
    let FoodCostPerc = 0.0;
    let MC = 0.0;
    let GlobalGP = 0.0;
    let GlobalNP = 0.0;
    let GlobalFC = 0.0;
    let GlobalMC = 0.0;
    let GlobalCountGP = 0.0;
    let GlobalCountFC = 0.0;
    let GlobalFCPerc = 0.0;
    let GlobalCountMC = 0.0;
    viewModel.Items.forEach((element: any) => {
      //console.log(element);
      if (element.Product) //console.log(element.Product);
        if (element.Items) {
          let nodeValues = this.byCategoryCalculate(level + 1, element, viewModel);
          GlobalGP += (nodeValues.GrossPrice > 0) ? nodeValues.GrossPrice : 0;
          GlobalNP += nodeValues.NetPrice;
          GlobalFC += nodeValues.FoodCost;
          GlobalFCPerc += nodeValues.FoodCostPerc;
          GlobalMC += nodeValues.MC;

          countGP += (nodeValues.GrossPrice > 0) ? nodeValues.countGP : 0;
          countFC += nodeValues.countFC;
          countMC += nodeValues.countMC;
          GlobalCountGP += nodeValues.countGP;
          GlobalCountFC += nodeValues.countFC;
          GlobalCountMC += nodeValues.countMC;
          GrossPrice += nodeValues.GrossPrice;
          NetPrice += nodeValues.NetPrice;
          FoodCost += nodeValues.FoodCost;
          MC += nodeValues.MC;
          FoodCostPerc += nodeValues.FoodCostPerc;

          if (nodeValues.GrossPrice) countRowGrossPrice++;
          if (nodeValues.NetPrice) countRowNetPrice++;
          if (nodeValues.FoodCost) countRowFoodCost++;
          if (nodeValues.FoodCostPerc) countRowFoodCostPerc++;
          if (nodeValues.MC) countRowMC++;

          //console.log(nodeValues, 'GrossPrice', GrossPrice, nodeValues.GrossPrice, 'GlobalCountGP', GlobalCountGP, 'nodeValues.countGP', nodeValues.countGP);
        } else {
          if (element.GrossPrice > 0) countGP++;
          if (element.FoodCost > 0) countFC++;
          if (element.MC > 0) countMC++;
          GrossPrice += (element.GrossPrice > 0) ? element.GrossPrice : 0;
          NetPrice += element.NetPrice;
          FoodCost += element.FoodCost;
          MC += element.MC;
          FoodCostPerc += element.FoodCostPerc;
        }

      GrossRevenue += element.GrossRevenue;
      NetRevenue += element.NetRevenue;
      Quantity += element.Quantity;
      TotalFoodCost += element.TotalFoodCost;
      TotalMC += element.TotalMC;
      count++;
    });

    viewModel.GrossRevenue = GrossRevenue;
    viewModel.NetRevenue = NetRevenue;
    viewModel.Quantity = Quantity;
    viewModel.TotalFoodCost = TotalFoodCost;
    viewModel.TotalMC = TotalMC;
    if (viewModel.NetRevenue) viewModel.CurrentAvgFoodCost = viewModel.TotalFoodCost / viewModel.NetRevenue;
    if (parent && parent.Quantity) viewModel.PopularityPerc = viewModel.Quantity / parent.Quantity;
    if (level == 0) {
      viewModel.GrossPrice = GlobalGP / GlobalCountGP;
      viewModel.NetPrice = GlobalNP / GlobalCountGP;
      viewModel.FoodCost = GlobalFC / GlobalCountFC;
      viewModel.MC = GlobalMC / GlobalCountMC;
      viewModel.FoodCostPerc = GlobalFCPerc / GlobalCountFC;
      this.setPopularity(viewModel)
    } else if (count > 0) {
      if (countRowGrossPrice) viewModel.GrossPrice = GrossPrice / countRowGrossPrice;
      if (countRowNetPrice) viewModel.NetPrice = NetPrice / countRowNetPrice;
      if (countRowFoodCost) viewModel.FoodCost = FoodCost / countRowFoodCost;
      if (countRowFoodCostPerc) viewModel.FoodCostPerc = FoodCostPerc / countRowFoodCostPerc;
      if (countRowMC) viewModel.MC = MC / countRowMC;
    }

    let returnObj = {
      GrossRevenue,
      NetRevenue,
      Quantity,
      TotalFoodCost,
      GrossPrice,
      NetPrice,
      FoodCost,
      FoodCostPerc,
      MC,
      countGP,
      countFC,
      countMC
    };

    return returnObj;
  }

  setPopularity(viewModel) {
    viewModel.Items.forEach((element: any) => {
      if (viewModel.Quantity) element.PopularityPerc = element.Quantity / viewModel.Quantity;
      if (viewModel.TotalMC) element.MCPerc = element.TotalMC / viewModel.TotalMC;
      if (viewModel.Quantity) element.QuantityIncidence = element.Quantity / viewModel.Quantity;
      if (viewModel.GrossRevenue) element.RevenueIncidence = element.GrossRevenue / viewModel.GrossRevenue;
      if (viewModel.TotalFoodCost) element.CostIncidence = element.TotalFoodCost / viewModel.TotalFoodCost;
      if (viewModel.TotalMC) element.MCIncidence = element.TotalMC / viewModel.TotalMC;
      if (element.Items) this.setPopularity(element);
    });
  }

  formatNumber(x: any, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
    return this.utility.formatNumber(x, this.locale, decimal, showZero, currency);
  }

  async openRicetta(goodId: string) {

    const merce = await this.gestioneRicetteService.getSingleRecipe(goodId).toPromise();

    this.dialog
      .open(EditRicettaComponent, {
        data: {
          ricetta: merce,
          readonly: true
        },
        width: '100%',
        height: '100%'
      });

  }

  showFromYearStart() {
    this.showInitialImage = false;
    this.currentFilterCfg = { Period: 'OnlyYesterday' };
    this.filtriService.performFilter$.next(this.currentFilterCfg);
    this.ref.detectChanges();
  }

}
