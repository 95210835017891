import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { FiltriService } from '@app/core/services/filtri.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { InserimentoType } from '@app/views/pages/magazzino/inserimento-comune/inserimento-comune.component';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { PDFService } from '@app/core/services/pdf.service';
import { GestioneFattureService } from './gestione-fatture.service';
import { ContoEconomicoService } from '../../amministrazione/conto-economico/conto-economico.service';

import { ImportedPurchasesComponent } from '../../../partials/content/crud/imported-purchases/imported-purchases.component';

// RXJS
import { Subscription } from 'rxjs';

import moment from 'moment';
import * as util from '@app/core/services/utilityfunctions';
import * as _ from 'lodash';

// Pipes
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';

import { MatDialog } from '@angular/material/dialog';


import { CompaniesDistributionComponent } from './companies-distribution/companies-distribution.component';
import { MbTimeDistributionComponent } from '@app/mb-components/mb-time-distribution/mb-time-distribution.component';
import { LinkDDTComponent } from './link-ddt/link-ddt.component';
import { FornitoriEditComponent } from '../../anagrafiche/fornitori/fornitori-edit/fornitori-edit.component';
import { PlDetailComponent } from './pl-detail/pl-detail.component';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';


declare var $: any;

@Component({
  selector: 'kt-gestione-fatture',
  templateUrl: './gestione-fatture.component.html',
  styleUrls: ['./gestione-fatture.component.scss']
})
export class GestioneFattureComponent implements OnInit {

  bulkSelect!: any;


  switchGroupSubcription!: Subscription;
  filtriServiceSubscription!: Subscription;
  staticCollectionsSubcription!: Subscription;
  currentFilterCfg: any;
  locale!: string;
  fornitori: any;
  invoiceNumber!: string;
  ordStartsWith: boolean = false;
  showInitialImage: boolean = true;
  utility: any;

  filterType: string = '0';
  filterNC: string = 'All';

  constructor(
    public staticCollectionsService: StaticCollectionsService,
    public filtriService: FiltriService,
    private switchGroupService: SwitchGroupService,
    public roleService: RoleService,
    private magazzinoService: MagazzinoService,
    private router: Router,
    private translate: TranslateService,
    private localizedDateTimePipe: LocalizedDateTimePipe,
    private translationService: TranslationService,
    private layoutUtilsService: LayoutUtilsService,
    private pdfService: PDFService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private gestioneFattureService: GestioneFattureService,
    private contoEconomicoService: ContoEconomicoService,
    private gestioneMerciService: GestioneMerciService,
    public loaderService: LoaderService
  ) {
    this.utility = util;
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });
  }

  ngOnInit(): void {
    document.body.classList.add('kt-aside--minimize');

    this.fornitori = [];
    this.invoiceNumber = '';

    this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
      (filterConfig: any) => {

        // Se ho un filtro memorizzato lo recupero

        if (localStorage.getItem('currentFilterCfg')) {
          try {
            this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
            localStorage.removeItem('currentFilterCfg');
            this.showInitialImage = false;
            this.filtriService.performFilter$.next(this.currentFilterCfg);
            return;
          } catch (error) {
            localStorage.removeItem('currentFilterCfg');
          }
        }

        if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
          return
        };
        if (filterConfig.constructor !== Object) {
          return
        };
        this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

        if (this.currentFilterCfg && this.currentFilterCfg.Number) {
          this.invoiceNumber = this.currentFilterCfg.Number;
        }

        // Inizializzo EVENTUALMENTE le select
        if (this.currentFilterCfg && this.currentFilterCfg.SupplierIds) {
          this.fornitori = this.currentFilterCfg.SupplierIds;
        }

        //
        if (this.fornitori && this.fornitori.length > 0) {
          this.currentFilterCfg['SupplierName'] = this.fornitori;
        } else {
          delete this.currentFilterCfg.SupplierName;
        }

        this.showInitialImage = false;

        if (this.table) {
          this.table.draw()
        } else {
          this.initDataTable();
        }
      }
    );

  }

  showFromYearStart() {
    this.showInitialImage = false;
    this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
    this.filtriService.performFilter$.next(this.currentFilterCfg);
    this.ref.detectChanges();
  }

  async ngAfterViewInit() {

    this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
      (event: any) => {
        if (event.change) {
          this.clearFilters(false);
        }
      }
    );

    await this.staticCollectionsService.fillStaticCollection(false);
  }

  ngOnDestroy() {
    this.switchGroupSubcription.unsubscribe();
    this.filtriServiceSubscription.unsubscribe();

    // cancello il filtro
    this.filtriService.filterConfig = {};

    // Se l'url non include inserimento-comune allora svuoto la localstorage
    if (!this.router.url.includes('/magazzino/acquisti')) {
      localStorage.removeItem('currentFilterCfg');
    }
  }

  clearFilters(actionFilter: boolean = true) {
    this.table = undefined;
    this.filterType = '0';
    this.fornitori = [];
    this.invoiceNumber = '';
    this.filtriService.clearFilters$.next(actionFilter);
    $('#gestioneFattureTable').DataTable().clear().destroy();
    if (this.currentFilterCfg && this.currentFilterCfg['SupplierName']) delete this.currentFilterCfg['SupplierName'];
    if (this.currentFilterCfg && this.currentFilterCfg['Number']) delete this.currentFilterCfg['Number'];
    if (this.currentFilterCfg && this.currentFilterCfg['Mode']) delete this.currentFilterCfg['Mode'];
    if (this.currentFilterCfg && this.currentFilterCfg['DDTMode']) delete this.currentFilterCfg['DDTMode'];
    this.showInitialImage = true;
    this.ref.detectChanges();
  }

  pagination: any = {
    TotalRows: 0,
    pageSize: 10,
    pageIndex: 0
  }

  changePagination(event: any) {
    if (this.pagination.pageSize != event.pageSize) {
      this.pagination.pageSize = event.pageSize;
      this.pagination.pageIndex = 0;
      this.table.page.len(this.pagination.pageSize).draw();
      this.table.page(0).draw('page');
    } else if (this.pagination.pageIndex != event.pageIndex) {
      this.pagination.pageIndex = event.pageIndex;
      this.table.page(this.pagination.pageIndex).draw('page');
    }
  }

  table: any;
  totalDelta: number = 0;
  totalPurchase: number = 0;
  totalPLAmount: number = 0;
  totalPLGrosAmount: number = 0;
  totalVat: number = 0;
  totalInvoices: number = 0;
  totalPurchasesTot: number = 0;
  initDataTable() {
    this.loaderService.show();

    if ($.fn.dataTable.isDataTable('#gestioneFattureTable')) {
      $('#gestioneFattureTable').DataTable().destroy();
    }

    setTimeout(() => {
      const currentLogin = this.switchGroupService.getCurrentGroup();

      const dtOptions: any = {
        initComplete: function (settings: any, json: any) {
          // Prendi l'elemento paginazione generato (div.dataTables_paginate)
          var $pagination = $(this.api().table().container()).find('div.dataTables_paginate');
          // Spostalo nel contenitore personalizzato che abbiamo definito in HTML
          $('.custom-pagination-container').html($pagination);

          const dataTables_length = $(this.api().table().container()).find('.dataTables_length');
          $('.custom-pagination-container').prepend(dataTables_length);
        },
        destroy: true,
        orderCellsTop: true,        
        paging: true,
        searching: true,
        dom: 'rtpl',
        ordering: true,
        scrollY: 'calc(100vh - 27rem)',
        scrollCollapse: true,
        autoWidth: false,
        aaSorting: [[1, 'desc']],
        processing: true,
        serverSide: true,
        ajax: {
          url: currentLogin.endpointURL + "/api/Fe/ImportedFeList",
          type: "POST",
          data: (d: any) => {
            d.filters = this.currentFilterCfg;
            return JSON.stringify(d);
          },
          headers: {
            "Authorization": "Bearer " + currentLogin.access_token,
            "Content-Type": "application/json"
          },
          dataSrc: (response) => {
            this.pagination.TotalRows = response.recordsFiltered;
            this.totalDelta = response.TotalDelta;
            this.totalInvoices = response.TotaleInvoces;
            this.totalPurchase = response.TotalPurchase;
            this.totalPLAmount = response.TotalPLAmount;
            this.totalPLGrosAmount = response.TotalPLGrosAmount;
            this.totalPurchasesTot = response?.TotalPurchasesTot;
            this.totalVat = response.TotalVat;
            this.ref.detectChanges();
            return response.data;
          }
        },
        columnDefs: [
          {
            targets: [0], orderable: false, className: 'excludeFromExport', render: (data) => {
              return `<i class="bi bi-square select pointer"></i>`;
            }
          },
          {
            targets: 1, data: 'Date', name: 'Date', render: (data, type, p, meta) => {
              return this.localizedDateTimePipe.transform(data, undefined, true);
            }
          },
          {
            targets: 2, data: 'Store', name: 'Store', render: (data, type, p, meta) => {
              return `<div class="overflow-ellipses exportableTooltip" title="${data}">${data}</div>`;
            }, className: 'max-width-80'
          },
          {
            targets: 3, width: '250px', data: 'SupplierName', name: 'SupplierName', render: (data, type, p, meta) => {
              return `<div class="overflow-ellipses exportableTooltip" title="${data}"><a href="javascript:;" name="editSupplier">${data}</a></div>`;
            }, className: 'max-width-200'
          },
          {
            targets: 4, data: 'Number', name: 'Number', render: (data, type, p, meta) => {
              let msg = data;
              if (p.IsCredit) {
                msg = '<i class="bi bi-currency-exchange" title="Credit"></i>&nbsp;' + msg
              }
              const classEllipses = p.IsCredit ? 'overflow-ellipses-100' : 'overflow-ellipses'
              return `<div class="${classEllipses} exportableTooltip" data-toggle="tooltip" title="${data}">${msg}</div>`;
            }, className: 'max-width-80'
          },
          {
            targets: 5, data: 'Amount', name: 'Amount', className: 'text-right', render: (data, type, p, meta) => {
              return util.formatNumber(data, this.locale);
            }
          },
          {
            targets: 6, data: 'TotalVat', name: 'TotalVat', className: 'text-right', render: (data, type, p, meta) => {
              return util.formatNumber(data, this.locale);
            }
          },
          {
            targets: 7, visible: false, data: 'VatDetails', name: 'VatDetails', render: (data, type, p, meta) => {
              if (data) {
                try {
                  const details = JSON.parse(data);
                  let lines = '';
                  details.forEach(d => {
                    if (d.Amount == 0) return;
                    if (lines.length > 0) lines += '<br>';
                    lines += `${d.Percentage}: ${util.formatNumber(d.Amount, this.locale)}`;
                  });
                  return lines;
                } catch (error) {
                  console.log(error);
                  return '';
                }
              } else {
                return '';
              }
            }
          },
          {
            targets: 8, data: 'TotalDdt', name: 'TotalDdt', className: 'text-right', render: (data, type, p, meta) => {
              return util.formatNumber(data, this.locale);
            }
          },
          {
            targets: 9, data: 'PurchasesTot', name: 'PurchasesTot', className: 'text-right', render: (data, type, p, meta) => {
              const goToWareHouse = p.IsPurchaseInvoice && p.PurchasesTot > 0 ? `<i class="actionBtn bi bi-search text-primary pointer" name="goToWareHouse" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.GO_TO_INVOICE')}"></i>` : '';

              // SITUAZIONE 3 => [bi-boxes] per fatture di acquisto (IsPurchaseInvoice) ma che non sono finite negli acquisti per qualche motivo
              /*
                  PREMESSA => icona bi-boxes compare solo se p.PurchasesTot == 0 && p.IsPurchaseInvoice
                  1. Va tutto bene e si può importare(CanSendToPurchase) => text-succes(s: any) => apro poup CompaniesDistributionComponent con company segnalata sulla fattura predefinito a 100%
                  2. Si può importare(CanSendToPurchase) ma manca la mappatura => text-warning => far unscire un popup che dice "mappare le merci non esistenti"
                  3. Non si può importare (fornitore escluso da acquisti) => text-danger e non si apre popup
              */
              const isSituazione3 = p.IsPurchaseInvoice && p.PurchasesTot == 0;
              let sendMagColor = '', sendMagPointer = '', sendMagAction = '', sendMagTooltip = '';
              if (isSituazione3) {

                // Punto 1
                if (p.CanSendToPurchase) {
                  sendMagColor = 'text-success situazione2';
                  sendMagTooltip = this.translate.instant('GESTIONE_FATTURE.NO_WAREHOUSE_IMPORTED');

                  // Punto 2
                } else if (!p.CanSendToPurchase) {
                  sendMagColor = 'text-warning situazione2';
                  sendMagPointer = 'pointer';
                  sendMagAction = 'noMag';
                  sendMagTooltip = this.translate.instant('GESTIONE_FATTURE.NO_WAREHOUSE_IMPORTED');
                }

              }
              let noMag = isSituazione3 ? `<i data-toggle="tooltip" title="${sendMagTooltip}" class="bi boxes ${sendMagColor} actionBtn ${sendMagPointer}" name="${sendMagAction}"></i>` : '';

              if (goToWareHouse) {
                noMag = '<div class="h_spacer"></div>' + noMag;
              }

              return `<div class="display-flex-space-between flex-align-center">
                                        <div class="display-flex-left flex-align-center">
                                            ${goToWareHouse}
                                            ${noMag}
                                        </div>  
                                        <div class="exportableTooltip" title="${util.formatNumber(data, this.locale)}">
                                            ${util.formatNumber(data, this.locale)}   
                                        </div>                                          
                                    </div>`;
            }
          },
          {
            targets: 10, data: 'PlAmount', name: 'PlAmount', className: 'text-right', render: (data, type, p, meta) => {
              return util.formatNumber(data, this.locale);
            },
            visible: false
          },
          {
            targets: 11, data: 'PLGrossAmount', name: 'PlGrossAmount', className: 'text-right', render: (data, type, p, meta) => {
              // SITUAZIONE 1 => [bi-currency-exchange] per fatture di acquisto (IsPurchaseInvoice) 
              /*
                  PREMESSA => icona bi-currency-exchange compare solo se PLGrossAmount = 0
                  1. Va tutto bene e si può importare => text-succes(s: any) => apro poup CompaniesDistributionComponent con company segnalata sulla fattura predefinito a 100%
                  2. Si può importare ma manca la mappatura => text-warning => far uscire un popup che dice "mappare le categorie delle merci nel ce"
                  3. Non si può importare (fornitore escluso da pl) => text-danger e non si apre popup
              */

              // SITUAZIONE 2 => [bi-currency-exchange] per altre fatture (!IsPurchaseInvoice)
              /*
                  PREMESSA => icona bi-currency-exchange compare solo se PLGrossAmount = 0
                  1. Va tutto bene e si può importare => text-succes(s: any) => apro poup CompaniesDistributionComponent con company segnalata sulla fattura predefinito a 100%
                  2. Si può importare(CanSendToPL) ma manca la mappatura => text-warning => far uscire un popup che dice "mappare il fornitore nel ce"
                  3. Non si può importare (fornitore escluso da pl) => text-danger e non si apre popup
              */

              // SITUAZIONE 3 => [bi-boxes] per fatture di acquisto (IsPurchaseInvoice) ma che non sono finite negli acquisti per qualche motivo
              /*
                  PREMESSA => icona bi-boxes compare solo se p.PurchasesTot == 0 && p.IsPurchaseInvoice
                  1. Va tutto bene e si può importare(CanSendToPurchase) => text-succes(s: any) => apro poup CompaniesDistributionComponent con company segnalata sulla fattura predefinito a 100%
                  2. Si può importare(CanSendToPurchase) ma manca la mappatura => text-warning => far unscire un popup che dice "mappare le merci non esistenti"
                  3. Non si può importare (fornitore escluso da acquisti) => text-danger e non si apre popup
              */

              const isSituazione1 = p.IsPurchaseInvoice && p.PLGrossAmount == 0;
              const isSituazione2 = !p.IsPurchaseInvoice && p.PLGrossAmount == 0;
              //const isSituazione3 = p.IsPurchaseInvoice && p.PurchasesTot == 0;

              let sendCEColor = '', sendMagColor = '', sendCEPointer = '', sendMagPointer = '', sendCEAction = '', sendMagAction = '', sendCETooltip = '', sendMagTooltip = '';

              if (isSituazione1) {

                // Punto 1
                if (p.CanSendToPL && !p.IsEscludedFromCE) {
                  sendCEColor = 'text-success situazione1';
                  sendCEPointer = 'pointer';
                  sendCEAction = 'noCE';
                  sendCETooltip = this.translate.instant('GESTIONE_FATTURE.NO_PL_IMPORTED');

                  // Punto 2
                } else if (!p.CanSendToPL && !p.IsEscludedFromCE) {
                  sendCEColor = 'text-warning situazione1';
                  sendCEPointer = 'pointer';
                  sendCEAction = 'noCE';
                  sendCETooltip = this.translate.instant('GESTIONE_FATTURE.NO_PL_IMPORTED');

                  // Punto 3
                } else if (p.IsEscludedFromCE) {
                  sendCEColor = 'text-success situazione1';
                  sendCEPointer = 'pointer';
                  sendCEAction = 'noCE';
                  sendCETooltip = this.translate.instant('GESTIONE_FATTURE.NO_PL_IMPORTED');
                }

              } else if (isSituazione2) {

                // Punto 1
                if (p.CanSendToPL && !p.IsEscludedFromCE) {
                  sendCEColor = 'text-success situazione2';
                  sendCEPointer = 'pointer';
                  sendCEAction = 'noCE';
                  sendCETooltip = this.translate.instant('GESTIONE_FATTURE.NO_PL_IMPORTED');

                  // Punto 2
                } else if (!p.CanSendToPL && !p.IsEscludedFromCE) {
                  sendCEColor = 'text-warning situazione2';
                  sendCEPointer = 'pointer';
                  sendCEAction = 'noCE';
                  sendCETooltip = this.translate.instant('GESTIONE_FATTURE.NO_PL_IMPORTED');

                  // Punto 3
                } else if (!p.IsEscludedFromCE) {
                  sendCEColor = 'text-success situazione2';
                  sendCEPointer = 'pointer';
                  sendCEAction = 'noCE';
                  sendCETooltip = this.translate.instant('GESTIONE_FATTURE.NO_PL_IMPORTED');
                }

              }

              //https://mantis.fbmanager.com/view.php?id=1214
              const openPlDetail = (p.PLSplits && p.PLSplits.length > 0) && !(p.IsPurchaseInvoice && p.PurchasesTot > 0) ? `<i class="actionBtn bi bi-search text-primary pointer" name="openPlDetail" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_FATTURE.DETTAGLIO_PL')}"></i><div class="h_spacer"></div>` : '';

              const noCE = isSituazione1 || isSituazione2 ? `<i data-toggle="tooltip" title="${sendCETooltip}" class="bi bi-currency-exchange ${sendCEColor} actionBtn ${sendCEPointer}"  name="${sendCEAction}"></i>` : '';
              const noMag = '';

              return `
                                <div class="display-flex-space-between flex-align-center">
                                    <div class="display-flex-left">
                                        ${openPlDetail}
                                        ${noCE}
                                        ${noMag}
                                    </div>
                                    <div class="exportableTooltip" title="${util.formatNumber(data, this.locale)}">
                                        ${util.formatNumber(data, this.locale)}   
                                    </div>                                    
                                </div>
                            `;
            }
          },
          {
            targets: 12, data: 'Delta', name: 'Delta', className: 'text-right', render: (data, type, p, meta) => {

              // http://mantis.fbmanager.com/view.php?id=1097
              // Se la fattura ha solo la colonna CE Lordo popolata la differenza deve essere fatta su: CE Lordo e Totale Fattura + IVA
              //if (p.PLGrossAmount > 0 && p.PurchasesTot == 0) {
              //    delta = p.Delta2;
              //}

              // http://mantis.fbmanager.com/view.php?id=1178
              let delta = Math.abs(data) <= 0.99 ? 0 : data;
              const deltaFormatted = util.formatNumber(delta, this.locale);

              return Math.abs(delta) >= 1 ? `<span class="text-danger exportableTooltip" title="${deltaFormatted}">${deltaFormatted}</span>` : deltaFormatted;
            }
          },
          // Actions
          {
            targets: 13, orderable: false, className: 'excludeFromExport section text-right', render: (data, type, p, meta) => {

              const deleteButton = this.roleService.canWrite()/* && !p.IsLocked*/ ? `<i class="ms-2 actionBtn bi bi-chevron-double-right text-danger pointer" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_FATTURE.MOVE_TO_INVOICES')}" name="deleteItem"></i>` : '';
              const previewXml = p.FileName ? `<i class="ms-2 actionBtn bi bi-eye text-primary pointer" name="previewXml" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.SHOW_XML')}"></i>` : '';
              const downloadXml = p.FileName ? `<i class="ms-2 actionBtn bi bi-download text-primary pointer" name="downloadXml" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.DOWNLOAD_XML')}"></i>` : '';

              const distributionIconColor = p.SuplierNeedDistribution && p.PLGrossAmount != 0 && !p.AlreadyDistributed ? 'success' : 'warning';
              const distributionTooltip = p.SuplierNeedDistribution && p.PLGrossAmount != 0 && !p.AlreadyDistributed ? this.translate.instant('GESTIONE_FATTURE.NEEDS_DISTRIBUTION') : this.translate.instant('GESTIONE_FATTURE.ALREADY_DISTRIBUTED');
              const distribution = (p.SuplierNeedDistribution && p.PLGrossAmount != 0) || p.AlreadyDistributed ? `<i class="ms-2 actionBtn bi bi-briefcase text-${distributionIconColor} pointer" name="timeDistribution" data-toggle="tooltip" title="${distributionTooltip}"></i>` : '';

              let ddtIconColor;
              let ddtTooltip;

              if (p.LinkedDDT && p.LinkedDDT.length > 0) {
                if (p.DeltaDDT == 0) {
                  ddtIconColor = 'success';
                  ddtTooltip = this.translate.instant('GESTIONE_FATTURE.LINKED_DDT');
                } else if (p.ForceCheckDDT) {
                  ddtIconColor = 'success2';
                  ddtTooltip = this.translate.instant('GESTIONE_FATTURE.LINKED_DDT_FORCES');
                }
                else {
                  ddtIconColor = 'danger';
                  ddtTooltip = this.translate.instant('GESTIONE_FATTURE.LINKED_DDT_DANGER');
                }
              } else if (p.AvailableDDT && p.AvailableDDT.length > 0) {
                ddtIconColor = 'warning';
                ddtTooltip = this.translate.instant('GESTIONE_FATTURE.LINK_DDT');
              }

              const linkDDT = (p.AvailableDDT && p.AvailableDDT.length > 0) || (p.LinkedDDT && p.LinkedDDT.length > 0) ? `<i class="ms-2 actionBtn bi bi-receipt text-${ddtIconColor} pointer" name="linkDDT" data-toggle="tooltip" title="${ddtTooltip}"></i>` : '';

              const isPayed = p.IsPayed ? `<i class="actionBtn bi bi-check-square text-success pointer" name="isPayed" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_FATTURE.TOOLBAR.PAYED_MODE.PAYED')}"></i>` : '';

              return `
                            <div class="display-flex-right"> 
                                ${isPayed}                               
                                ${distribution}
                                ${linkDDT}
                                ${previewXml}   
                                ${downloadXml}                             
                                ${deleteButton}
                            </div>
                        `;
            }
          },

          // Serve per evitare di riceve avvisi quando arrivano campi a null
          {
            defaultContent: '-',
            targets: '_all'
          }
        ],
        language: {
          emptyTable: this.translate.instant('DATATABLES.INFO_EMPTY'),
          zeroRecords: this.translate.instant('DATATABLES.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">',
          lengthMenu: this.translate.instant('DATATABLES.LENGTH_MENU'),
          // Aggiungo i valori di previous e next
          paginate: {
            first: this.translate.instant('DATATABLES.FIRST'),
            last: this.translate.instant('DATATABLES.LAST'),
            next: this.translate.instant('DATATABLES.NEXT'),
            previous: this.translate.instant('DATATABLES.PREVIOUS')
          }
        },
        buttons: [
          {
            className: 'toolbarButton',
            extend: 'pdf',
            //text: '<i class="bi bi-file-earmark-pdf"></i>',
            orientation: 'landscape',
            title: this.PDFPreferences?.title,
            pageSize: 'A4',
            download: 'open',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.excludeFromExport)',
              format: {
                body: function (data, row, column, node) {
                  if (column == 4) {
                    data = data.replace(/<i class="bi bi-currency-exchange" style="padding-left:10px;" title="Credit"><\/i>/g, '');
                  }
                  if (column === 7) {
                    data = data.replace(/<br>/g, '\n');
                  }

                  if (data.indexOf('exportableTooltip') >= 0) {
                    var parser = new DOMParser();
                    var html = parser.parseFromString(data, 'text/html');
                    let html$ = $(html);
                    data = html$.find(`.exportableTooltip`).attr("title");
                  }

                  return data;
                }
              }
            },
            customize: (doc: any) => {

              const imageLogoB64 = $('#kt_header .logoimg').attr('src');
              const size = {
                width: $('#kt_header .logoimg').width(),
                height: $('#kt_header .logoimg').height()
              };

              const arrayCols = this.table.column(7).visible() ? ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']

              const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
              doc.content[0] = docDefinition.content[0];
              doc.content[1].layout = docDefinition.content[1].layout;
              doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
              doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
              ////doc.defaultStyle = docDefinition.defaultStyle;
              doc.footer = docDefinition.footer;
              doc.header = docDefinition.header;
              doc.pageMargins = docDefinition.pageMargins;
              doc.pageOrientation = docDefinition.pageOrientation;
              doc.pageSize = docDefinition.pageSize;
              doc.styles = docDefinition.styles;

              // Imposto gli allineamenti
              var rowCount = doc.content[1].table.body.length;
              for (let i = 0; i < rowCount; i++) {
                doc.content[1].table.body[i][0].alignment = 'left';
                doc.content[1].table.body[i][1].alignment = 'left';
                doc.content[1].table.body[i][2].alignment = 'left';
                doc.content[1].table.body[i][3].alignment = 'left';
                doc.content[1].table.body[i][4].alignment = 'right';
                doc.content[1].table.body[i][5].alignment = 'right';
                doc.content[1].table.body[i][6].alignment = 'right';
                doc.content[1].table.body[i][7].alignment = 'right';
                doc.content[1].table.body[i][8].alignment = 'right';
                doc.content[1].table.body[i][9].alignment = 'right';

                if (this.table.column(7).visible()) {
                  doc.content[1].table.body[i][10].alignment = 'right';
                }
              }
            },
            action: function (e, dt, node, config) {
              var self = this;
              var currentPageLen = dt.page.len();
              var currentPage = dt.page.info().page;
              var totRecords = dt.page.info().recordsTotal
              dt.one('draw', () => {
                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                setTimeout(function () {
                  dt.page.len(currentPageLen).draw(); //set page length
                  dt.page(currentPage).draw('page'); //set current page
                });
              });
              dt.page.len(totRecords).draw();
            }
          },
          {
            className: 'toolbarButton',
            extend: 'excelHtml5',
            //autoFilter: true,
            //sheetName: 'Exported data',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.excludeFromExport)',
              format: {
                body: (data, row, column, node) => {
                  if (column == 4) {
                    data = data.replace(/<i class="bi bi-currency-exchange" style="padding-left:10px;" title="Credit"><\/i>/g, '');
                  }

                  if (column === 5 || column === 6 || column === 7 || column === 8 || column === 9 || column === 10) {
                    data = '\0' + data;
                  }

                  if (this.table.column(7).visible() && column === 6) {
                    data = data.replace(/<br>/g, '\r\n');
                  }

                  if (data.indexOf('exportableTooltip') >= 0) {
                    var parser = new DOMParser();
                    var html = parser.parseFromString(data, 'text/html');
                    let html$ = $(html);
                    data = html$.find(`.exportableTooltip`).attr("title");
                    data = '\0' + data;
                  }

                  return data;
                }
              },
              orthogonal: 'export'
            },
            customize: async (xlsx: any) => {

              let sheet = xlsx.xl.worksheets['sheet1.xml'];

              const colCodeDiff = this.table.column(7).visible() ? 'J' : 'I';
              $(`row c[r^="${colCodeDiff}"]`, sheet).each((index: any, element: any) => {
                let tmp = $(element).text();
                if (tmp.includes('<span class="text-danger">')) {
                  tmp = tmp.replace('<span class="text-danger">', '').replace('</span>', '');
                  $(`row c[r="${colCodeDiff}${index + 2}"] t`, sheet).text(tmp);
                  $(`row c[r="${colCodeDiff}${index + 2}"]`, sheet).attr('s', '10');
                }

              });

              if (this.table.column(7).visible()) {
                $(`row c[r^="G"]`, sheet).each((index: any, element: any) => {
                  let tmp = $(element).text();
                  if (tmp.includes('\n')) {
                    // Aggiungo a capo
                    $(`row c[r="G${index + 2}"]`, sheet).attr('s', '55');
                  }
                });
              }

              // Allineo a sx
              $('row c[r^="D"]', sheet).attr('s', '50');

              // Allineo a dx
              $('row c[r^="E"]', sheet).attr('s', '52');
              $('row c[r^="F"]', sheet).attr('s', '52');
              $('row c[r^="G"]', sheet).attr('s', '52');
              $('row c[r^="H"]', sheet).attr('s', '52');
              $('row c[r^="I"]', sheet).attr('s', '52');
              $('row c[r^="L"]', sheet).attr('s', '52');
              $('row c[r^="M"]', sheet).attr('s', '52');

            },
            action: function (e, dt, node, config) {
              var self = this;
              var currentPageLen = dt.page.len();
              var currentPage = dt.page.info().page;
              var totRecords = dt.page.info().recordsTotal
              dt.one('draw', () => {
                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                setTimeout(function () {
                  dt.page.len(currentPageLen).draw(); //set page length
                  dt.page(currentPage).draw('page'); //set current page
                });
              });
              dt.page.len(totRecords).draw();
            }
          }
        ]
      };

      this.table = $('#gestioneFattureTable').DataTable(dtOptions);
      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });

      this.table.on('page.dt', function () {
        $('.dataTables_scrollBody').animate({
          scrollTop: 0
        }, 'slow');
      });

      if (this.invoiceNumber) {
        const v = this.ordStartsWith ? '#' + $("#invoiceNumberSearch").val() : $("#invoiceNumberSearch").val();
        this.performFilter(v, 'Number');
      }

      window.pdfMake.vfs['fa-solid-900-Regular.ttf'] = this.pdfService.addFontAwesome();
      window.pdfMake.vfs['fa-solid-900-Medium.ttf'] = this.pdfService.addFontAwesome();
      window.pdfMake.vfs['fa-solid-900-Italic.ttf'] = this.pdfService.addFontAwesome();
      window.pdfMake.vfs['fa-solid-900-Bold.ttf'] = this.pdfService.addFontAwesome();

      window.pdfMake.fonts = {
        // Default font should still be available
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-Italic.ttf'
        },
        // Make sure you define all 4 components - normal, bold, italics, bolditalics - (even if they all point to the same font file)
        'Font Awesome 5 Free': {
          normal: 'fa-solid-900-Regular.ttf',
          bold: 'fa-solid-900-Bold.ttf',
          italics: 'fa-solid-900-Italics.ttf',
          bolditalics: 'fa-solid-900-Italics.ttf'
        },
        // Add Bootstrap Icons font
        'Bootstrap Icons': {
          normal: 'bootstrap-icons.ttf',
          bold: 'bootstrap-icons.ttf',
          italics: 'bootstrap-icons.ttf',
          bolditalics: 'bootstrap-icons.ttf',
        },
        DINpro: {
          normal: 'DINpro-Cond.ttf',
          bold: 'DINpro-CondBold.ttf',
          italics: 'DINpro-CondIta.ttf',
          bolditalics: 'DINpro-CondBoldIta.ttf'
        },
      };

      this.loaderService.hide();

      this.bulkSelect = "";
      this.showInitialImage = false;
      this.ref.detectChanges();

      $('tbody').off('click');
      $('tbody').on('click', 'a', async (event: any) => {
        const tr = $(event.target).closest('tr');
        const data = this.table.row(tr.index()).data();
        this.editSupplier(data);
      });

      //setTimeout(() => {
      $('#gestioneFattureTable tbody ').on('click', 'td i.select', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data: any = this.table.row(tr).data();
        if (data) {
          const isAlreadyChecked = $(event.target).hasClass('bi-check-square');
          if (isAlreadyChecked) {
            $(event.target).removeClass('bi-check-square');
            $(event.target).addClass('bi-square');
          } else {
            $(event.target).addClass('bi-check-square');
            $(event.target).removeClass('bi-square');
          }
          this.selectItem(!isAlreadyChecked, data);
        }
        this.ref.detectChanges();
      });


      $('tbody').on('click', '.actionBtn', async (event: any) => {
        const tr = $(event.target).closest('tr');
        const btn = $(event.target).closest('i');
        const data = this.table.row(tr.index()).data();

        switch (btn.attr('name')) {
          case 'editItem':
            this.editItem(data);
            break;
          case 'deleteItem':
            this.deleteItem(data);
            break;
          case 'previewXml':
            this.showXml({
              FileName: data.FileName,
              Name: data.FileName
            });
            break;
          case 'downloadXml':
            //this.loaderService.show();
            const xml: any = await this.magazzinoService.getRawXml(data.FileName).toPromise();
            let blob = new Blob([xml], { type: 'text/xml' });
            let url = URL.createObjectURL(blob);
            window.open(url);
            URL.revokeObjectURL(url); //Releases the resources
            //this.pdfService.htmlToPdf(html, data.FileName).then(() => this.loaderService.hide());
            break;
          case 'goToWareHouse':
            this.goToWareHouse(data);
            break;
          case 'noCE': {
            const situazione1: boolean = btn.hasClass('situazione1');
            const situazione2: boolean = btn.hasClass('situazione2');
            const punto1: boolean = btn.hasClass('text-success');
            const punto2: boolean = btn.hasClass('text-warning');
            const punto3: boolean = btn.hasClass('text-danger');
            if (punto1) {
              const dialogRef = this.dialog.open(CompaniesDistributionComponent, {
                data,
                width: '500px',
              });
              dialogRef.afterClosed().subscribe(async (res: any) => {
                if (res) {
                  await this.contoEconomicoService.sendPL({
                    FileName: data.FileName,
                    Items: res.filter((x: any) => x.Percentage && this.utility.parseNumber(x.Percentage, this.locale) > 0)
                      .map((x: any) => {
                        return {
                          Store: x.Name,
                          Percentage: this.utility.parseNumber(x.Percentage, this.locale) / 100
                        }
                      })
                  }).toPromise();
                  this.table.draw();
                }
              });

            } else if (punto2) {

              const _title: string = this.translate.instant('GESTIONE_FATTURE.WARNING_DIALOG.TITLE');
              const _description: string = situazione1 ? this.translate.instant('GESTIONE_FATTURE.WARNING_DIALOG.MAP_CAT_MERCI') : this.translate.instant('GESTIONE_FATTURE.WARNING_DIALOG.MAP_FORNITORE');
              const _yesButton = this.translate.instant('GESTIONE_FATTURE.WARNING_DIALOG.BUTTON');
              const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, '', _yesButton);

            }

            break;
          }
          case 'noMag': {
            const punto1: boolean = btn.hasClass('text-success');
            const punto2: boolean = btn.hasClass('text-warning');
            const punto3: boolean = btn.hasClass('text-danger');
            if (punto1) {
              const dialogRef = this.dialog.open(CompaniesDistributionComponent, {
                data,
                width: '500px',
              });
              dialogRef.afterClosed().subscribe(async (res: any) => {
                if (res) {
                  await this.contoEconomicoService.sendPL({
                    FileName: data.FileName,
                    Items: res.filter((x: any) => x.Percentage && this.utility.parseNumber(x.Percentage, this.locale) > 0)
                      .map((x: any) => {
                        return {
                          Store: x.Name,
                          Percentage: this.utility.parseNumber(x.Percentage, this.locale) / 100
                        }
                      })
                  }).toPromise();
                  this.table.draw();
                }
              });

            } else if (punto2) {

              const _title: string = this.translate.instant('GESTIONE_FATTURE.WARNING_DIALOG.TITLE');
              const _description: string = this.translate.instant('GESTIONE_FATTURE.WARNING_DIALOG.MAP_MERCI')
              const _yesButton = this.translate.instant('GESTIONE_FATTURE.WARNING_DIALOG.BUTTON');
              const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, '', _yesButton);

            }
            break;
          }
          case 'openPlDetail': {
            this.openPlDetail(data);
            break;
          }
          case 'timeDistribution': {
            this.timeDistribution(data);
            break;
          }
          case 'linkDDT': {
            this.linkDDT(data);
            break;
          }
        }

      });

    }, 100);

  }

  async performBulkSelection(event: any) {
    let selectedItems: any = [];
    $('#gestioneFattureTable tbody td:first-child i.bi-check-square').each((index: number, el: any) => {

      const tr = $(el).closest('tr');
      const data: any = this.table.row(tr).data();
      selectedItems.push(data);

    });

    switch (event.value.toLowerCase()) {
      case 'invoicesreversal':
        await this.gestioneFattureService.reverseInvoices({ fileNames: selectedItems.map((item: any) => item.FileName) }).toPromise();
        this.table.draw();
        break;
    }
  }

  selectAll(event: any) {
    $('#gestioneFattureTable tbody td:first-child i').each((index: number, el: any) => {
      if (!event.checked) {
        $(el).removeClass('bi-check-square');
        $(el).addClass('bi-square');
      } else {
        $(el).addClass('bi-check-square');
        $(el).removeClass('bi-square');
      }
    })
  }

  bulkSelection(event: any) {
    this.bulkSelect = undefined;
    this.ref.detectChanges();

    let _title: string = '';
    switch (event.value.toLowerCase()) {
      case 'invoicesreversal':
        _title = this.translate.instant('GESTIONE_FATTURE.BULK_ACTIONS.INVOICES_REVERSAL');
        break;
    }

    const _description: string = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.CONFIRM_ASK');
    const _waitDesciption: string = '';
    const _yesButton = this.translate.instant('COMMONS.CONFIRM');
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(async (ok: any) => {
      if (ok) {
        this.performBulkSelection(event);
      }
      this.bulkSelect = "";
      this.ref.detectChanges();
    });

  }

  selectItem(checked: boolean, item: any) {
    item['selected'] = checked;
  }

  showVatDetails() {
    const visible = this.table.column(7).visible();
    this.table.column(7).visible(!visible).draw();
  }

  popupXml: any;
  async showXml(merce: any) {

    this.gestioneMerciService.openXmlHighlightedByFileName(merce.FileName, "", "")
      .then(() => console.log)
      .catch((error: any) => {
        this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
      })

  }

  PDFPreferences: any;
  exportAsPDF(expand: boolean = false) {

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
      explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
    };

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      //if (result) this.preparePdfConfiguration(result);
      if (result) {
        result['header'] = {
          export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
          period: this.filtriService.getCurrentPeriod()
        };
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        };
        result['language'] = this.translationService.getSelectedLanguage();

        this.PDFPreferences = result;

        this.table.button(0).trigger();
      };
    });
  }

  goToWareHouse(item: any) {
    const filter: any = {
      DateFilter: JSON.stringify({ Start: moment(item.Date).format('YYYY-MM-DD'), End: moment(item.Date).format('YYYY-MM-DD') }),
      OrderOrDocument: item.Number,
      ShowDisabled: true,
      ShowInvisible: true
    }

    localStorage.setItem('currentFilterCfg', JSON.stringify(filter))

    this.router.navigate(['/magazzino/acquisti']);
  }

  editSupplier(item: any) {
    const index = this.staticCollectionsService.fornitoriTutti$.findIndex((f: any) => f.Id == item.SupplierId);
    if (index < 0) return;

    let supplier = this.staticCollectionsService.fornitoriTutti$[index];
    supplier.SubjectType = 1;

    const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item: supplier }, width: '900px' });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      const _saveMessage = this.translate.instant('ANAGRAFICHE.FORNITORI.ACTION_EDIT.UPDATE_MESSAGE');
      this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Update);
      this.table.draw();
      await this.staticCollectionsService.refreshFornitori();
    });
  }

  editItem(itemId: any) {
    this.magazzinoService.itemsForUpdate(itemId, 'Purchase').toPromise()
      .then((result: any) => {
        const resultConfig = JSON.parse(JSON.stringify(result));
        const resultsRows: any = JSON.parse(JSON.stringify(result.Rows));
        delete resultConfig.Rows;
        this.magazzinoService.setCurrentConfig({
          insertType: InserimentoType.ACQUISTI,
          body: resultConfig,
          items: resultsRows,
          prePop: {
            Items: resultsRows
          }
        });

        // mi salvo la configurazione dei filtri nei cookie.
        localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));

        this.router.navigate(['/magazzino/inserimento-comune'], {
          queryParams: {
            inserimentoType: InserimentoType.ACQUISTI,
            referral: '/magazzino/acquisti',
            itemId: itemId,
          }
        });

      })
      .catch((err: any) => console.log);
  }

  deleteItem(item: any) {

    const _title: string = this.translate.instant('GESTIONE_FATTURE.DELETE_DIALOG.TITLE');
    const _description: string = this.translate.instant('GESTIONE_FATTURE.DELETE_DIALOG.DESC');
    const _waitDesciption: string = '';
    const _yesButton = this.translate.instant('GESTIONE_FATTURE.DELETE_DIALOG.BUTTON');
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm) {
        this.loaderService.show();
        this.gestioneFattureService.deleteInvoice(item.FileName).toPromise()
          .then(() => {
            const message = this.translate.instant('GESTIONE_FATTURE.DELETE_DIALOG.SUCCESS');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            this.table.draw();
          })
          .catch(() => {
            const message = item.IsPayed ? this.translate.instant('GESTIONE_FATTURE.DELETE_DIALOG.ERROR_PAYED') : this.translate.instant('GESTIONE_FATTURE.DELETE_DIALOG.ERROR');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
          })
          .finally(() => this.loaderService.hide());

      }
    })

  }

  filter(event: any) {
    if (event.keyCode == 13) {
      const v = this.ordStartsWith ? '#' + $(event.target).val() : $(event.target).val();
      this.performFilter(v, event.target.name);
    }
  }

  filterBySupplier(value: any) {
    if (!this.currentFilterCfg) {
      this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
    }
    this.currentFilterCfg['SupplierIds'] = [value];
    if (this.table) {
      this.table.draw();
    }
    else {
      this.filtriService.performFilter$.next(this.currentFilterCfg);
    }
  }

  filterByInvoiceNumber(event: any) {
    if (event.keyCode == 13) {
      const value = this.ordStartsWith ? '#' + $(event.target).val() : $(event.target).val();
      if (!this.currentFilterCfg) {
        this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
      }
      this.currentFilterCfg['Number'] = [value];
      if (this.table) {
        this.table.draw();
      }
      else {
        this.filtriService.performFilter$.next(this.currentFilterCfg);
      }
    }
  }

  performFilter(value, name) {
    if (!value) {
      value = '';
      delete this.currentFilterCfg[name];
    }

    if (this.showInitialImage) {
      this.showInitialImage = false;
      this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
      this.currentFilterCfg[name] = value;
      this.filtriService.performFilter$.next(this.currentFilterCfg);
    } else {
      this.currentFilterCfg[name] = value;
      const key = name + ":name";
      var column = this.table.column(key);
      if (column) {
        column.search(value).draw();
      }
      else {
        console.log("Controllare la colonna")
      }
    }
  }

  almoustOneSelected() {
    const almoustOne = $('#gestioneFattureTable tbody td:first-child i.bi-check-square');
    return almoustOne.length > 0;
  }

  async exportAsXLSX() {
    this.table.button(1).trigger();
    this.loaderService.show();
    setTimeout(() => {
      this.ref.detectChanges();
      this.loaderService.hide();
    }, 100);
  }
  /**
   * 
   * @param event 
   */

  importAcquisti(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.loaderService.show();
      this.magazzinoService.importAcquisti(formData).toPromise()
        .then((result) => {

          if (result.Result == 'Ok') {
            let message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTAZIONE_OK');
            message += ' - ' + result.Imported + '/' + result.Imported;
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 100000, true, false, 3000, 'top', 'snackbar-success');
          } else {
            let message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTAZIONE_OK');
            let tot = result.Imported + result.Discarded;
            message += ' - ' + result.Imported + '/' + tot + '\n';
            message += ' Scarti:\n';
            if (result.DiscardedDetails) {
              var details = result.DiscardedDetails.split(',');
              let i = 0;
              details.forEach((f: any) => {
                if (i < 25) message += " " + f + "\n";
                else if (i === 25) message += " .........";
                i++;
              });
            }

            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 3000, 'top', 'snackbar-warning');

          }

          event.target.value = ''
        })
        .catch((error) => {
          let message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTAZIONE_FAIL');
          this.layoutUtilsService.showActionNotification(message, MessageType.Error, 10000, true, false, 3000, 'top', 'snackbar-error');
          event.target.value = ''
        })
        .finally(() => this.loaderService.hide());
    }
  }

  showImportedPurchases() {
    const dialogRef = this.dialog.open(ImportedPurchasesComponent, {
      data: {},
      width: '100%',
      height: '100%'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success && res.body) {

      }
    });
  }

  async linkDDT(invoice: any) {

    const html = await this.gestioneMerciService.getXml(invoice.FileName).toPromise();

    const dialogRef = this.dialog.open(LinkDDTComponent, {
      data: {
        invoice: invoice,
        html: html
      },
      width: '100%',
      height: '100%'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success) {
        const message = this.translate.instant('GESTIONE_FATTURE.LINK_DDT_DLG.SUCCESS');
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
        this.table.draw();
      } else {
        const message = this.translate.instant('GESTIONE_FATTURE.LINK_DDT_DLG.FAILURE');
        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-success');
      }
    });
  }

  openPlDetail(invoice: any) {
    const dialogRef = this.dialog.open(PlDetailComponent, {
      data: {
        invoice: invoice,
        locale: this.locale
      },
      width: '1000px'
    });
  }

  timeDistribution(invoice: any) {
    const dialogRef = this.dialog.open(MbTimeDistributionComponent, {
      data: {
        invoice: invoice,
        readOnly: invoice.AlreadyDistributed,
        showSlitPurchases: true
      },
      panelClass: 'MbTimeDistributionComponent'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success && res.body) {

        let payload: any = [];

        res.body.forEach((item: any) => {
          payload.push({
            Date: item.date,
            Percentage: item.perc
          })
        });

        this.contoEconomicoService.SplitOnTime({
          FileName: invoice.FileName,
          SplitPurchase: res.SplitPurchase ? res.SplitPurchase : false,
          Splits: payload
        }).toPromise().then(() => {
          const _message: string = this.translate.instant('TIME_DISTRIBUTION.DISTRIBUTION_SUCCESS');
          this.layoutUtilsService.showActionNotification(_message, MessageType.Create);
          this.table.draw();
        });

      }
    });
  }


  filterByType(event: any) {
    if (!this.currentFilterCfg) {
      this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
    }
    this.currentFilterCfg['CreditNote'] = event;

    if (this.table) {
      this.table.draw();
    }
    else {
      this.filtriService.performFilter$.next(this.currentFilterCfg);
    }
  }

  filterByMode(event: any) {
    if (!this.currentFilterCfg) {
      this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
    }

    // DDT Mode
    if (!Number(event) && event !== 'NoPurchase' && event.indexOf('Payed') == -1) {
      this.currentFilterCfg['DDTMode'] = event;
      delete this.currentFilterCfg['Mode'];
      delete this.currentFilterCfg['Payed'];

      // Payment mode
    } else if (!Number(event) && event !== 'NoPurchase' && event.indexOf('Payed') >= 0) {
      this.currentFilterCfg['Payed'] = event == 'NotPayed' ? 'no' : 'yes';
      delete this.currentFilterCfg['Mode'];
      delete this.currentFilterCfg['DDTMode'];

    } else {
      this.currentFilterCfg['Mode'] = event;
      delete this.currentFilterCfg['DDTMode'];
      delete this.currentFilterCfg['Payed'];
    }

    if (this.table) {
      this.table.draw();
    } else {
      this.filtriService.performFilter$.next(this.currentFilterCfg);
    }
  }

  async sendAllToPL() {

    const _title: string = this.translate.instant('GESTIONE_FATTURE.SEND_ALL_TO_PL.TITLE');
    const _description: string = this.translate.instant('GESTIONE_FATTURE.SEND_ALL_TO_PL.DESC');
    const _yesButton = this.translate.instant('GESTIONE_FATTURE.SEND_ALL_TO_PL.BUTTON');
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, "", _yesButton, _noButton);
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm) {
        this.loaderService.show();
        this.contoEconomicoService.sendAll(this.currentFilterCfg).toPromise()
          .then((response: any) => {
            console.log('response', response);
            let message = this.translate.instant('GESTIONE_FATTURE.SEND_ALL_TO_PL.SUCCESS');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 3000, 'top', 'snackbar-error');
            this.table.draw();
          })
          .catch(() => {
            let message = this.translate.instant('GESTIONE_FATTURE.SEND_ALL_TO_PL.FAIL');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 10000, true, false, 3000, 'top', 'snackbar-error');
          })
          .finally(() => this.loaderService.hide())
      }
    });
  }
}
