import { Component, Input, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContoEconomicoDetailsComponent } from '../../conto-economico-details/conto-economico-details.component';
import { ContoEconomicoEditComponent } from '../../conto-economico-edit/conto-economico-edit.component';
import * as util from '@app/core/services/utilityfunctions';
import { RoleService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { FiltriService } from '@app/core/services/filtri.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import moment from 'moment';
import * as XLSX from '@sheet/core';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';
declare var window: any;

@Component({
  selector: 'kt-monthly-view',
  templateUrl: './monthly-view.component.html',
  styleUrls: ['./monthly-view.component.scss']
})
export class MonthlyViewComponent implements OnInit {
  view: string = 'MONTHLY';



  @Input() filters: any = {};
  @Output() onClickGraph = new EventEmitter<any>();
  locale!: string;
  showTotals: boolean = false;
  currentFilterCfg: any = {};
  utility: any;
  expandRows: boolean = false;
  lastRowsExpanded: any = [];

  PLData: any;

  filtriServiceSubscription!: Subscription;

  // Gesione del mese corrente
  currentMonth: number;
  // Array per memorizzare gli indici delle colonne precedenti
  hiddenColumns: number[] = [];
  // Flag per tracciare lo stato di visibilità delle colonne precedenti
  previousMonthsVisible: boolean = false;

  constructor(
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private roleService: RoleService,
    private switchGroupService: SwitchGroupService,
    private pdfService: PDFService,
    private translate: TranslateService,
    public filtriService: FiltriService,
    private translationService: TranslationService,
    public loaderService: LoaderService
  ) {
    this.utility = util;

    // Inizializza il mese corrente
    this.currentMonth = moment().month(); // 0 = Gennaio, 11 = Dicembre

    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
      moment.locale(this.locale)
    });
  }

  ngOnDestroy() {
    this.filtriServiceSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.checkScroll();
    this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
      async (filterConfig: any) => {
        // Se ho un filtro memorizzato lo recupero
        if (localStorage.getItem('currentFilterCfg')) {
          try {
            this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
            localStorage.removeItem('currentFilterCfg');
          } catch (error) {
            localStorage.removeItem('currentFilterCfg');
            return;
          }
          //return;
        } else {
          if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
            return;
          };
          if (filterConfig.constructor !== Object) {
            return;
          };

          if (JSON.stringify(filterConfig) == JSON.stringify(this.currentFilterCfg)) {
            return;
          }

          this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
        }

        //
        if (this.filters.filterCompanies && this.filters.filterCompanies.length > 0) {
          this.currentFilterCfg['Companies'] = this.filters.filterCompanies;
        } else {
          delete this.currentFilterCfg.Companies;
        }

        if (this.filters.filtercostCenters && this.filters.filtercostCenters.length > 0) {
          this.currentFilterCfg['CostCenterIds'] = this.filters.filtercostCenters;
        } else {
          delete this.currentFilterCfg.CostCenterIds;
        }

        //if (!this.currentFilterCfg.Period && !this.currentFilterCfg.DateFilter) {
        const currentYear = this.getYearByFilter();
        this.currentFilterCfg.DateFilter = { Start: `${currentYear}-01-01`, End: `${currentYear}-12-31` };
        //}

        if (!this.table) {
          this.initDataTable();
        } else {
          // Trick per cambiare l'anno nell'intestazione della colonna
          this.table.columns().every((index: number) => {
            const header = this.table.columns([index]).header().to$().text();
            const checkArrayCols = header.split(' ');
            // Se sono dalla colonna 2 in avant
            // Se l'intestazione è composta da 2 parole
            // Se la seconda parola è un numero
            if (index > 1 && checkArrayCols && checkArrayCols.length > 1 && !isNaN(checkArrayCols[1])) {
              checkArrayCols[1] = this.getYearByFilter()
              this.table.columns([index]).header().to$().text(checkArrayCols.join(' '));
            }

          })
          this.table.draw();
        }

      }
    );
  }

  checkHideRow(data: any): boolean {
    if (this.filters.ck_hide0) {
      // prendo il totaler per la riga
      const tot = data?.Columns[0]?.SubColumns;
      if (tot) {

        let hideRowCurrent = this.filters.ck_lordo ? tot.CurrentValue == 0 : tot.CurrentGrossValue == 0;
        let hideRowPrev = this.filters.ck_lordo ? tot.PreviousValue == 0 : tot.PreviousGrossValue == 0;

        // Se ho l'anno precedente e l'attuale
        const hideRow = hideRowCurrent && ((this.filters.ck_prevyear && hideRowPrev) || !this.filters.ck_prevyear);

        if (hideRow) {
          return true;
        }
      }
    }
    return false;
  }

  checkScroll() {
    const wHeight: any = $(window).height();
    const tHeight: any = $("#ceTable_m").height();
    if (wHeight < tHeight) {
      $("#scrollUp").show();
    } else {
      $("#scrollUp").hide();
    }
  }

  getPreviousMonthsColumnIndices(): number[] {
    const previousMonths: number[] = [];
    const monthlyStartCol = 7; // La prima colonna mensile inizia a 7
    for (let i = 0; i < this.currentMonth; i++) {
      const startCol = monthlyStartCol + i * 5; // 5 colonne per mese
      for (let j = 0; j < 5; j++) {
        // Se sono dalla colonna precedente in avanti e il flag è attivo
        // oppure se sono dalla colonna precedente in dietro (quindi sempre visibile)
        if ((j >= 2 && this.filters.ck_prevyear) || (j <= 1)) {
          previousMonths.push(startCol + j);
        }
      }
    }
    return previousMonths;
  }


  // Funzione helper per ottenere l'indice della colonna di partenza del mese corrente
  getCurrentMonthStartColumn(): number {
    const currentMonth = moment().month(); // 0 = Gennaio, 11 = Dicembre
    const monthlyStartCol = 7; // La prima colonna mensile inizia a 7
    const currentMonthStartColIndex = monthlyStartCol + (currentMonth * 5);
    return currentMonthStartColIndex;
  }


  getYearByFilter() {
    let currentYear = moment().year();
    if (this.filtriService.filterConfig.Period) {
      switch (this.filtriService.filterConfig.Period.toLowerCase()) {
        case 'onlyyesterday':
        case 'yesterday':
          currentYear = moment().subtract(1, 'day').year();
          break;
        case 'year':
          currentYear = moment().startOf('year').year();
          break;
        case 'month':
          currentYear = moment().subtract(1, 'month').year();
          break;
        case 'week':
          currentYear = moment().startOf('week').year();
          break;
      }
    } else if (this.filtriService.filterConfig.DateFilter) {
      currentYear = moment(this.filtriService.filterConfig.DateFilter.Start, 'YYYY-MM-DD').year();
    }

    return currentYear;

    //return moment().startOf('year').year();
  }

  clearFilters(actionFilter: boolean = true, redrawTable: boolean = true) {
    this.filtriService.clearFilters$.next(actionFilter);
    this.showTotals = false;
    const currentYear = this.getYearByFilter();
    this.currentFilterCfg = {
      DateFilter: { Start: `${currentYear}-01-01`, End: `${currentYear}-12-31` },
      fromLocal: true,
      TimeInterval: 'none',
      DayOfWeek: 0
    };
    this.lastRowsExpanded = [];
    this.ref.detectChanges();
    this.filters.ck_prevyear
    if (redrawTable) this.table.draw();
  }

  training: number = 0;
  showTraining(show: boolean = false) {
    this.training = show ? 2 : 0;
    // 0/3 fa vedere tutto insieme
    // 1 Non fa vedere training
    // 2 Fa vedere solo training
    this.table.draw();
  }

  getFixedCols() {
    return this.showTotals ? 0 : (this.filters.ck_prevyear ? 7 : 4);
  }

  generateColumnDefs(): any[] {
    const columnDefs: any[] = [];

    // 1. Colonna 0: Azioni (espansione)
    columnDefs.push({
      targets: 0,
      className: 'parent-row actions',
      width: '20px',
      orderable: false,
      visible: !this.showTotals,
      render: (data, type, p, meta) => {
        return !p.IsTotal && p.Children && p.Children.length > 0 ? '<i class="bi expander pointer"></i>' : '';
      }
    });

    // 2. Colonna 1: Nome
    columnDefs.push({
      targets: 1,
      data: 'Name',
      name: 'Name',
      render: (data, type, p) => {
        let classClickGraph: string = !this.showTotals && !p.IsCover && !p.IsTotal ? 'clickGraph' : '';
        return `<div class="overflow-ellipses exportText ${classClickGraph}" title="${data}">${data}</div>`;
      }
    });

    // 3. Colonne 2-6: Totali
    const totaliColumns = [
      {
        targets: 2,
        className: 'text-right max-width-100',
        render: (data, type, p) => {
          const values = p.Columns[0].SubColumns;
          return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">Tot ${this.getYearByFilter()}</div>`
      },
      {
        targets: 3,
        className: 'text-right  max-width-60' + (!this.filters.ck_prevyear ? ' last-fixed-col-left' : ''),
        render: (data, type, p) => {
          const values = p.Columns[0].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">%</div>`
      },
      {
        targets: 4,
        className: 'text-right max-width-100',
        visible: this.filters.ck_prevyear,
        render: (data, type, p) => {
          const values = p.Columns[0].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">Tot ${this.getYearByFilter() - 1}</div>`
      },
      {
        targets: 5,
        className: 'text-right max-width-60',
        visible: this.filters.ck_prevyear,
        render: (data, type, p) => {
          const values = p.Columns[0].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">${this.filters.ck_abs ? '% abs' : '% rel'}</div>`
      },
      {
        targets: 6,
        className: 'text-right max-width-100' + (this.filters.ck_prevyear ? ' last-fixed-col-left' : ''),
        visible: this.filters.ck_prevyear,
        render: (data, type, p) => {
          const values = p.Columns[0].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">Diff. Tot Gen</div>`
      }
    ];

    columnDefs.push(...totaliColumns);

    // 4. Definizione delle colonne mensili dinamicamente
    const mesi = [
      { name: 'Gen', index: 1 },
      { name: 'Feb', index: 2 },
      { name: 'Mar', index: 3 },
      { name: 'Apr', index: 4 },
      { name: 'Mag', index: 5 },
      { name: 'Giu', index: 6 },
      { name: 'Lug', index: 7 },
      { name: 'Ago', index: 8 },
      { name: 'Set', index: 9 },
      { name: 'Ott', index: 10 },
      { name: 'Nov', index: 11 },
      { name: 'Dic', index: 12 }
    ];

    mesi.forEach(mese => {
      const baseTarget = 7 + (mese.index - 1) * 5; // Calcola il target di partenza per ogni mese

      // Determina se aggiungere la classe 'zebra' (es. per mesi pari)
      const zebraClass = (mese.index % 2 === 0) ? ' zebra' : '';

      // 4.1. Colonna Nome Mese
      columnDefs.push({
        targets: baseTarget,
        className: 'text-right max-width-100' + zebraClass + this.checkCurrentMonth(mese.index),
        render: (data, type, p) => {
          const values = p.Columns[mese.index].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">${this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', mese.index - 1).format('MMM YYYY'))}</div>`
      });

      // 4.2. Colonna Percentuale
      columnDefs.push({
        targets: baseTarget + 1,
        className: 'text-right max-width-60' + zebraClass + this.checkCurrentMonth(mese.index),
        render: (data, type, p) => {
          const values = p.Columns[mese.index].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">${this.filters.ck_abs ? '% abs' : '% rel'}</div>`
      });

      // 4.3. Colonna Precedente
      columnDefs.push({
        targets: baseTarget + 2,
        className: 'text-right max-width-100' + zebraClass + this.checkCurrentMonth(mese.index),
        visible: this.filters.ck_prevyear,
        render: (data, type, p) => {
          const values = p.Columns[mese.index].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">${this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', mese.index - 1).format('MMM YYYY'))}</div>`
      });

      // 4.4. Colonna Precedente Percentuale
      columnDefs.push({
        targets: baseTarget + 3,
        className: 'text-right max-width-100' + zebraClass + this.checkCurrentMonth(mese.index),
        visible: this.filters.ck_prevyear,
        render: (data, type, p) => {
          const values = p.Columns[mese.index].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">${this.filters.ck_abs ? '% abs' : '% rel'}</div>`
      });

      // 4.5. Colonna Differenza
      columnDefs.push({
        targets: baseTarget + 4,
        className: 'text-right max-width-100' + zebraClass + this.checkCurrentMonth(mese.index),
        visible: this.filters.ck_prevyear,
        render: (data, type, p) => {
          const values = p.Columns[mese.index].SubColumns;
          return `<div class="overflow-ellipses exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
        },
        title: `<div class="overflow-ellipses exportText">Diff ${mese.name}</div>`
      });
    });

    // 5. Colonna di fallback per evitare avvisi su campi null
    columnDefs.push({
      defaultContent: '-',
      targets: '_all'
    });

    return columnDefs;
  }

  forceRefreshColumnsVisibility: boolean = false;
  performShowHide0() {
    this.forceRefreshColumnsVisibility = true;
    this.table.draw();
  }

  table: any;
  PDFPreferences: any;
  initDataTable() {
    if ($.fn.dataTable.isDataTable('#ceTable_m')) {
      $('#ceTable_m').DataTable().destroy();
    }

    setTimeout(() => {
      const currentLogin = this.switchGroupService.getCurrentGroup();

      const dtOptions: any = {
        drawCallback: () => {
          if (this.lastRowsExpanded.length >= 0) {
            this.lastRowsExpanded.forEach((row: any) => {
              $(`#ceTable_m tbody tr:nth-child(${row + 1}) td.parent-row`).trigger('click');
            })
          }

          this.checkScroll();

          if (this.filters.PLCategoryName) {
            setTimeout(() => {
              // Cerco il primo elemento che contiene PLCategoryName
              const riga = $(`td[data-categoryname="${this.filters.PLCategoryName}" i]`).closest('tr');
              if (riga.length > 0) riga[0].scrollIntoView();
            }, 100);
          }

          if (this.showTotals) {
            $('tr.children').hide();
          }

          // Calcola gli indici delle colonne mensili precedenti
          const hiddenColumns = this.getPreviousMonthsColumnIndices();

          // Imposta la visibilità delle colonne in base al flag
          hiddenColumns.forEach(col => {
            this.table.column(col).visible(this.previousMonthsVisible);
          });

          if (this.forceRefreshColumnsVisibility) {
            this.forceRefreshColumnsVisibility = false;
            this.refreshColumnsVisibility();
          }

          this.loaderService.hide();

          setTimeout(() => {
            this.table.columns.adjust().fixedColumns().left(this.getFixedCols());
          }, 100);

        },
        createdRow: (row, data, dataIndex) => {
          if (data.IsCover) {
            $(row).addClass('rowCover');
            if (data.IsSectionTotal) {
              $(row).addClass('rowCoverTotal');
            }
          }
          if (data.IsTotal) {
            $(row).addClass('rowTotal');
            if (data.IsSection) {
              $(row).addClass('rowSection');

              if(this.showTotals) {
                $(row).addClass('showTotals');
              }
            }
          } else {
            $(row).attr('data-row', JSON.stringify(data));
          }
        },
        rowCallback: (row, data, index) => {
          if (!this.filters.ck_showCover && data.ItemType == 'GenericCover') {
            $(row).hide();
          }

          if (this.showTotals && !(data.IsTotal && data.IsSection)) {
            $(row).hide();
          }

          const hideRow = !this.showTotals && this.checkHideRow(data);
          if (hideRow) {
            $(row).hide();
          }

          // Controllo se ho effettuato una ricerca per terzo livello
          if (this.filters.PLCategoryName && data.Children.length == 0 && !data.IsCover && !data.IsTotal) {
            $(row).hide();
          } else if (this.filters.PLCategoryName && data.Children.length > 0 && data.ItemType !== 'GenericCover') {
            this.table.row($(row)).child(this.format(data, $(row))).show();
          }
        },
        destroy: true,
        paging: false,
        searching: true,
        dom: 'lrtip',
        ordering: false,
        scrollX: true,
        scrollY: 'calc(100vh - 24rem)',
        scrollCollapse: true,
        autoWidth: true,
        order: [],
        processing: true,
        serverSide: true,
        fixedColumns: {
          leftColumns: this.filters.ck_prevyear ? 7 : 4
        },
        ajax: {
          url: currentLogin.endpointURL + "/api/PL/Monthly",
          type: "POST",
          data: (d: any) => {
            d.filters = this.currentFilterCfg;
            d.PLCategoryName = this.filters.PLCategoryName;
            d.filters.Training = this.training;
            return JSON.stringify(d);
          },
          headers: {
            "Authorization": "Bearer " + currentLogin.access_token,
            "Content-Type": "application/json"
          },
          dataSrc: (response) => {
            // Se sto filtrando per nome categoria, non posso vedere tutto collassato
            if (this.filters.PLCategoryName) {
              this.showTotals = false;
            }
            this.PLData = response.data;
            return response.data;
          }
        },

        columnDefs: this.generateColumnDefs(),
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),

        },
        buttons: [
          {
            className: 'toolbarButton',
            extend: 'pdf',
            orientation: 'landscape',
            title: this.PDFPreferences?.title,
            pageSize: 'A4',
            download: 'open',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.actions)'
            },
            customize: (doc: any) => {

              const imageLogoB64 = $('#kt_header .logoimg').attr('src');
              const size = {
                width: $('#kt_header .logoimg').width(),
                height: $('#kt_header .logoimg').height()
              };

              const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], '', imageLogoB64, size);
              doc.content[0] = docDefinition.content[0];
              doc.content[1].layout = docDefinition.content[1].layout;
              doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
              //doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
              //doc.defaultStyle = docDefinition.defaultStyle;
              doc.footer = docDefinition.footer;
              doc.header = docDefinition.header;
              doc.pageMargins = docDefinition.pageMargins;
              doc.pageOrientation = 'landscape'; //docDefinition.pageOrientation;
              doc.pageSize = this.filters.ck_prevyear ? 'A2' : 'A3'; //docDefinition.pageSize;

              doc.styles = docDefinition.styles;

              Object.keys(doc.styles).forEach(key => {
                if (key.toLowerCase().indexOf('table') >= 0) {
                  doc.styles[key].fontSize = this.filters.ck_prevyear ? 3.5 : 7;
                }
              })

              var rowData = this.table.rows({ order: 'applied', search: 'applied' }).data();

              // export row child
              //http://live.datatables.net/hepopote/1/edit

              if (this.expandRows) {

                var headerLines = 0;  // Offset for accessing rowData array

                var newBody: any = []; // this will become our new body (an array of arrays(lines))
                //Loop over all lines in the table
                doc.content[1].table.body.forEach((line, i) => {

                  if (line[0].style !== 'tableHeader' && line[0].style !== 'tableFooter') {
                    line[0]['style'] = rowData[i - headerLines].IsTotal ? 'tableBodyTotal' : 'tableBodyParent';
                    for (let index = 1; index < line.length; index++) {
                      line[index]['alignment'] = 'right';
                      line[index]['style'] = rowData[i - headerLines].IsTotal ? 'tableBodyTotal' : 'tableBodyParent';
                    }
                  }

                  // Remove detail-control column
                  newBody.push(line);

                  if (line[0].style !== 'tableHeader' && line[0].style !== 'tableFooter') {

                    var data = rowData[i - headerLines];
                    // Append child data, matching number of columns in table
                    if (data.Children && data.Children.length > 0) {

                      data.Children.forEach(child => {

                        const columns = child.Columns;

                        let columnsValues: any = [
                          { text: '- ' + child.Name, style: 'tableBodyStyle' },
                        ];

                        for (var col = 1; col < 66; col++) {

                          const month = Math.floor((col - 1) / 5);
                          if (this.table.column(col + 1).visible()) {
                            let text = '';
                            if ((col - 1) % 5 == 0) {
                              text = this.getValue(columns[month].SubColumns);
                            } else if ((col - 1) % 5 == 1) {
                              text = this.getPerc(columns[month].SubColumns);
                            } else if ((col - 1) % 5 == 2) {
                              text = this.getValue(columns[month].SubColumns, true);
                            } else if ((col - 1) % 5 == 3) {
                              text = this.getPerc(columns[month].SubColumns, true);
                            } else if ((col - 1) % 5 == 4) {
                              text = this.getDiff(columns[month].SubColumns);
                            }

                            columnsValues.push({ text: text, alignment: 'right', style: 'tableBodyStyle' });
                          }
                        }

                        newBody.push(columnsValues);

                      })
                    }

                  } else {
                    headerLines++;
                  }

                });

                doc.content[1].table.body = newBody;
              } else {
                // Sostituisco il text danger con colore rosso
                doc.content[1].table.body.forEach((line, i) => {
                  if (i > 0 && rowData[i - 1].IsTotal) {
                    line.forEach(col => col.style = 'tableBodyTotal');
                  }
                });
              }


            }
          },
          {
            className: 'toolbarButton',
            extend: 'excelHtml5',
            //autoFilter: true,
            //sheetName: 'Exported data',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.actions)',
              format: {
                body: (data, row, column, node) => {
                  if (data.indexOf('exportText') >= 0) {
                    var parser = new DOMParser();
                    var htmlDoc = parser.parseFromString(data, 'text/html');
                    const htmlValue = $(htmlDoc).find('.exportText').text();
                    // dalla colonna 1 in avanti formatto
                    const value = column >= 1 ? this.utility.parseNumber(htmlValue, this.locale) : htmlValue;
                    return value;
                  } else {
                    // dalla colonna 1 in avanti formatto
                    const value = column >= 1 ? this.utility.parseNumber(data, this.locale) : data;
                    return value;
                  }
                }
              },
            },
            customize: async (xlsx: any) => {

              XLSX.SSF.setlocale("de-DE");

              var sSh = xlsx.xl['styles.xml'];
              var lastXfIndex = $('cellXfs xf', sSh).length - 1;
              // Bold Text - Yellow
              var s1 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"0\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" />';
              // Bold Text - Yellow - Alignment Right
              var s2 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';
              // Bold Text - Alignment Right
              var s3 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"0\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';

              sSh.childNodes[0].childNodes[5].innerHTML = sSh.childNodes[0].childNodes[5].innerHTML + s1 + s2 + s3;
              const fillYellow = '<fill xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\"><patternFill patternType=\"solid\"><fgColor rgb=\"FFF1E8C1\" /><bgColor indexed=\"64\" /></patternFill></fill>';
              sSh.childNodes[0].childNodes[2].innerHTML = sSh.childNodes[0].childNodes[2].innerHTML + fillYellow;
              var boldTextYellowFillIndex = lastXfIndex + 1;
              var boldTextYellowFillAlignRightIndex = lastXfIndex + 2;
              var boldTextAlignRightIndex = lastXfIndex + 3;

              // Get sheet.
              var sheet = xlsx.xl.worksheets['sheet1.xml'];

              // Get a clone of the sheet data.        
              var sheetData = $('sheetData', sheet).clone();

              // Clear the current sheet data for appending rows.
              $('sheetData', sheet).empty();

              // Row index from last column.
              var DT_row = 0;        // Row count in Excel sheet.

              var rowCount = 1;

              const numToAlpha = (num: number) => {
                var alpha = '';
                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                  alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                }
                return alpha;
              }

              // Itereate each row in the sheet data.
              $(sheetData).children().each((index, element) => {

                // Don't process row if its the header row.
                if (index > 1) {

                  // Get row
                  var row: any = $(element);

                  // Set the Excel row attr to the current Excel row count.
                  row.attr('r', rowCount);

                  // Iterate each cell in the row to change the rwo number.
                  row.children().each((index, element) => {
                    var cell = $(element);

                    // Set each cell's row value.
                    var rc: any = cell.attr('r');
                    rc = rc.replace(/\d+$/, "") + rowCount;
                    cell.attr('r', rc);

                  });

                  // Get the row HTML and append to sheetData.
                  row = row[0].outerHTML;
                  $('sheetData', sheet).append(row);
                  // Get the child data - could be any data attached to the row.
                  const rowData = this.table.row(DT_row, { search: 'none', order: 'index' }).data();

                  // Applico lo style ai parent
                  //https://datatables.net/reference/button/excelHtml5
                  if (rowData && rowData.IsTotal && rowData.IsSection) {
                    // Imposto tutta la riga con lo stile boldTextYellowFillAlignRightIndex
                    $(`row:nth-child(${rowCount}) c`, sheet).attr('s', boldTextYellowFillAlignRightIndex);
                    // Imposto la prima cella con lo stile boldTextYellowFillIndex
                    $(`row c[r="A${rowCount}"]`, sheet).attr('s', boldTextYellowFillIndex);
                  } else if (rowData && rowData.IsTotal) {
                    // Imposto la prima cella con lo stile bold
                    $(`row c[r="A${rowCount}"]`, sheet).attr('s', '2');
                    // Imposto il resto della riga con lo stile grassetto
                    $(`row:nth-child(${rowCount}) c:gt(0)`, sheet).attr('s', boldTextAlignRightIndex);
                  } else if (rowData) {
                    // Imposto tutta la riga con l'allineamento a destra
                    $(`row:nth-child(${rowCount}) c`, sheet).attr('s', '64');
                    // Imposto la prima cella con l'allineamento a sinistra
                    $(`row c[r="A${rowCount}"]`, sheet).attr('s', '50');
                  } else {
                    return;
                  }

                  rowCount++;

                  if (this.expandRows) {
                    const childData = rowData && rowData.Children ? rowData.Children : [];
                    // The child data is an array of rows
                    for (let c = 0; c < childData.length; c++) {
                      // Get row data.
                      const child = childData[c];

                      let columnsValues: any = [];
                      const columns = child.Columns;

                      // uso effectiveColIndex perchè quando salto una colonna perchè è 
                      // inivisibile, in realtà sull'excel non la salto, e la numerazione deve essere sequenziale
                      let effectiveColIndex = 1;
                      for (var col = 1; col < 66; col++) {
                        const month = Math.floor((col - 1) / 5);
                        if (this.table.column(col + 1).visible()) {
                          let text = '';
                          if ((col - 1) % 5 == 0) {
                            text = this.getValueExcel(columns[month].SubColumns);
                          } else if ((col - 1) % 5 == 1) {
                            text = this.getPercExcel(columns[month].SubColumns);
                          } else if ((col - 1) % 5 == 2) {
                            text = this.getValueExcel(columns[month].SubColumns, true);
                          } else if ((col - 1) % 5 == 3) {
                            text = this.getPercExcel(columns[month].SubColumns, true);
                          } else if ((col - 1) % 5 == 4) {
                            text = this.getDiffExcel(columns[month].SubColumns);
                          }

                          columnsValues.push(`<c t="n" r="${numToAlpha(effectiveColIndex++)}${rowCount}" s="64"><v>${text}</v></c>`);
                        }
                      }

                      const childRow = `<row r="${rowCount}">
                                          <c t="inlineStr" r="A${rowCount}" s="52"><is><t>${child.Name.replace('&', 'o')}</t></is></c>
                                          ${columnsValues.join(' ')}
                                        </row>`;

                      // Append row to sheetData.
                      try {
                        $('sheetData', sheet).append(childRow);
                        // Allineo la child row a destra
                        //$('row:nth-child(' + rowCount + ') c', sheet).attr('s', '52');
                        rowCount++; // Inc excelt row counter.                                           
                      } catch (error) {
                        console.log(error, childRow);
                      }
                    }
                  }

                  DT_row++;

                  // Just append the header row and increment the excel row counter.
                } else {
                  var row: any = $(element);
                  row = row[0].outerHTML;
                  $('sheetData', sheet).append(row);
                  rowCount++;
                }
              });

              console.log($('sheetData', sheet)[0].innerHTML);

            }
          }
        ]
      };

      this.table = $('#ceTable_m').DataTable(dtOptions);
      this.loaderService.show();
      this.ref.detectChanges();
      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });

      // Add event listener for opening and closing details
      $('#ceTable_m tbody').off('click');

      $('#ceTable_m tbody').on('click', 'td.parent-row', (event: any) => {
        const tr = $(event.target).closest('tr');
        const row = this.table.row(tr);

        if (row.child.isShown()) {
          this.lastRowsExpanded = this.lastRowsExpanded.filter((row: any) => row != tr.index());
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('shown');
        } else {

          // Devo fare così prerchè con lefixedColumns non riesce a prendere i dati,
          const rowData = tr.data('row');//row.data();
          // Open this row
          if (rowData) {
            if (!this.lastRowsExpanded.includes(tr.index()))
              this.lastRowsExpanded.push(tr.index());

            row.child(this.format(rowData, tr)).show();
            tr.addClass('shown');
          }
        }

        this.table.columns.adjust().fixedColumns().left(this.getFixedCols());
      });

      $('#ceTable_m tbody').on('click', 'a.add_ce', (event: any) => {
        const tr = $(event.target).closest('tr');
        //const row = this.table.row(tr);
        const data = tr.data('row');

        if (data) {
          this.addCE(data);
        }
      });

      $('#ceTable_m tbody').on('click', 'a.detail_ce', (event: any) => {
        const tr = $(event.target).closest('tr');
        const childrenId = tr.attr('id');
        const parentId = tr.data('rowid');
        const month = $(event.target).closest('a')?.data('month');
        const PLParentItem = this.PLData.find((x: any) => x.Id == parentId);
        const PLItem = PLParentItem.Children.find((x: any) => x.Id == childrenId);

        if (PLItem) {
          this.detailCE(PLItem, month);
        }
      });

      $('#ceTable_m tbody').on('click', '.clickGraph', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data = tr.data('row');
        if (data) {
          this.onClickGraph.emit(data);
        }
      });

    }, 100);

  }

  checkCurrentMonth(month: number) {
    return this.filters.currentMonth == month ? ' current-month ' : '';
  }

  filterByPLName($event: KeyboardEvent) {
    if ($event.code == 'Enter') {
      this.table.draw();
    }
  }

  refreshColumnsVisibility(event?: any, type?: any) {

    if (type) {
      this.forceRefreshColumnsVisibility = true;
      this.table.draw();
      return;
    }

    this.loaderService.show();
    this.ref.detectChanges();

    setTimeout(() => {
      const numColsBeforeMonths = this.showTotals ? 7 : 6; // Colonne fisse prima dei mesi (0-6)
      const numColsPerMonth = 5;      // Per ogni mese: 5 colonne
      const numMonths = 12;           // Gennaio a Dicembre
      const totalCols = numColsBeforeMonths + (numColsPerMonth * numMonths);

      if (this.showTotals) {
        // 1. Gestisci la visibilità delle prime 7 colonne (colonne fisse prima dei mesi)
        this.table.column(0).visible(true);  // Colonna 0: sempre visibile
        this.table.column(1).visible(true);  // Colonna 1: sempre visibile
        this.table.column(2).visible(true);  // Colonna 2: sempre visibile
        this.table.column(3).visible(true);  // Colonna 3: sempre visibile

        // Colonne 4, 5, 6 visibili solo se il filtro ck_prevyear è attivo
        this.table.column(4).visible(this.filters.ck_prevyear);
        this.table.column(5).visible(this.filters.ck_prevyear);
        this.table.column(6).visible(this.filters.ck_prevyear);

        // Gestione del title per la colonna 5
        const titleCol5 = this.filters.ck_abs ? '% abs' : '% rel';
        this.table.column(5).header().innerHTML = `<div class="overflow-ellipses-60 exportText">${titleCol5}</div>`;
      } else {
        // 1. Gestisci la visibilità delle prime 7 colonne (colonne fisse prima dei mesi)
        this.table.column(0).visible(true);  // Colonna 0: visibile se non sono visibili i totali
        this.table.column(1).visible(true);  // Colonna 1: sempre visibile
        this.table.column(2).visible(true);  // Colonna 2: sempre visibile

        // Colonne 4, 5, 6 visibili solo se il filtro ck_prevyear è attivo
        this.table.column(3).visible(this.filters.ck_prevyear);
        this.table.column(4).visible(this.filters.ck_prevyear);
        this.table.column(5).visible(this.filters.ck_prevyear);

        // Gestione del title per la colonna 5
        const titleCol5 = this.filters.ck_abs ? '% abs' : '% rel';
        this.table.column(4).header().innerHTML = `<div class="overflow-ellipses-60 exportText">${titleCol5}</div>`;
      }

      // 2. Nascondi tutte le colonne dei mesi
      for (let i = numColsBeforeMonths; i < totalCols; i++) {
        this.table.column(i).visible(false);
      }

      // 3. Mostra le colonne per ogni mese
      for (let i = 0; i < numMonths; i++) {
        const baseCol = numColsBeforeMonths + i * numColsPerMonth; // Colonna di base per ogni mese

        // Colonna 1 e 2 del mese: sempre visibili
        this.table.column(baseCol).visible(true);       // Nome del mese
        this.table.column(baseCol + 1).visible(true);   // Percentuale corrente

        // Gestione del title per la colonna #2 (Percentuale corrente)
        const titleCol2 = this.filters.ck_abs ? '% abs' : '% rel';
        this.table.column(baseCol + 1).header().innerHTML = `<div class="overflow-ellipses-60 exportText">${titleCol2}</div>`;

        // Colonne 3, 4 e 5 del mese: visibili solo se ck_prevyear è attivo
        this.table.column(baseCol + 2).visible(this.filters.ck_prevyear); // Dati dell'anno precedente
        this.table.column(baseCol + 3).visible(this.filters.ck_prevyear); // Percentuale dell'anno precedente

        // Gestione del title per la colonna #4 (Percentuale anno precedente)
        const titleCol4 = this.filters.ck_abs ? '% abs' : '% rel';
        this.table.column(baseCol + 3).header().innerHTML = `<div class="overflow-ellipses-60 exportText">${titleCol4}</div>`;

        this.table.column(baseCol + 4).visible(this.filters.ck_prevyear); // Differenza
      }

      // Calcola gli indici delle colonne mensili precedenti
      const hiddenColumns = this.getPreviousMonthsColumnIndices();

      // Imposta la visibilità delle colonne in base al flag
      hiddenColumns.forEach(col => {
        this.table.column(col).visible(this.previousMonthsVisible);
      });

      // 4. Aggiorna le colonne fisse dopo aver modificato la visibilità
      this.table.fixedColumns().left(this.getFixedCols()).draw(false);

      // 5. Gestione delle classi CSS per le colonne fisse
      $('table td').removeClass('last-fixed-col-left');

      // Imposta la colonna che ha l'ultima classe fissa in base al filtro ck_prevyear
      if (this.filters.ck_prevyear) {
        $('table td.dtfc-fixed-left:nth-child(7)').addClass('last-fixed-col-left');
      } else {
        $('table td.dtfc-fixed-left:nth-child(4)').addClass('last-fixed-col-left');
      }

      this.loaderService.show();
      this.ref.detectChanges();
    });
  }

  expandAll: boolean = false;
  expandAllRows() {
    this.expandAll = !this.expandAll;
    this.lastRowsExpanded = [];
    const trList = $('#ceTable_m tbody tr');
    trList.each((index: number, tr: any) => {
      const row = this.table.row(tr);

      if (!this.expandAll) {
        // This row is already open - close it
        row.child.hide();
        $(tr).removeClass('shown');
      } else {

        if (!this.lastRowsExpanded.includes(index))
          this.lastRowsExpanded.push(index);

        // Open this row
        const data = $(tr).data('row');
        if (data) {
          row.child(this.format(row.data(), $(tr))).show();
          $(tr).addClass('shown');
        }
      }
    });

    setTimeout(() => {
      this.table.fixedColumns().left(this.getFixedCols());
      this.table.columns.adjust();
      setTimeout(() => {
        this.table.fixedColumns().left(this.getFixedCols());
      }, 1000);
    }, 100);
  }

  getValue(values: any, prec: boolean = false) {
    if (this.filters.ck_lordo) {
      return prec ? this.utility.formatNumber(values.PreviousGrossValue, this.locale) : this.utility.formatNumber(values.CurrentGrossValue, this.locale)
    } else {
      return prec ? this.utility.formatNumber(values.PreviousValue, this.locale) : this.utility.formatNumber(values.CurrentValue, this.locale)
    }
  }

  getPerc(values: any, prec: boolean = false) {
    if (this.filters.ck_lordo) {
      if (this.filters.ck_abs) {
        if (this.filters.percTotale) {
          return prec ? this.utility.formatNumber(values.PreviousValuePercAbsGrossTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsGrossTotal, this.locale)
        }
        else {
          return prec ? this.utility.formatNumber(values.PreviousValuePercAbsGross, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsGross, this.locale)
        }

      } else {
        if (this.filters.percTotale) {
          return prec ? this.utility.formatNumber(values.PreviousValuePercRelGrossTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelGrossTotal, this.locale)
        }
        else {
          return prec ? this.utility.formatNumber(values.PreviousValuePercRelGross, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelGross, this.locale)
        }

      }
    } else {
      if (this.filters.ck_abs) {
        if (this.filters.percTotale) {
          return prec ? this.utility.formatNumber(values.PreviousValuePercAbsTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsTotal, this.locale)
        }
        else {
          return prec ? this.utility.formatNumber(values.PreviousValuePercAbs, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbs, this.locale)
        }

      } else {
        if (this.filters.percTotale) {
          return prec ? this.utility.formatNumber(values.PreviousValuePercRelTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelTotal, this.locale)
        }
        else {
          return prec ? this.utility.formatNumber(values.PreviousValuePercRel, this.locale) : this.utility.formatNumber(values.CurrentValuePercRel, this.locale)
        }

      }
    }
  }

  getDiff(values: any) {
    if (this.filters.ck_lordo) {
      if (this.filters.scostamento) {
        return this.utility.formatNumber(values.PreviousGrossDeviation, this.locale)
      } else {
        return this.utility.formatNumber(values.PreviousGrossDelta, this.locale)
      }
    } else {
      if (this.filters.scostamento) {
        return this.utility.formatNumber(values.PreviousDeviation, this.locale)
      } else {
        return this.utility.formatNumber(values.PreviousDelta, this.locale)
      }
    }
  }

  getValueExcel(values: any, prec: boolean = false) {
    if (this.filters.ck_lordo) {
      return prec ? this.utility.parseNumber(values.PreviousGrossValue, this.locale, 2) : this.utility.parseNumber(values.CurrentGrossValue, this.locale, 2)
    } else {
      return prec ? this.utility.parseNumber(values.PreviousValue, this.locale, 2) : this.utility.parseNumber(values.CurrentValue, this.locale, 2)
    }
  }

  getPercExcel(values: any, prec: boolean = false) {
    if (this.filters.ck_lordo) {
      if (this.filters.ck_abs) {
        if (this.filters.percTotale) {
          return prec ? this.utility.parseNumber(values.PreviousValuePercAbsGrossTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsGrossTotal, this.locale, 2)
        }
        else {
          return prec ? this.utility.parseNumber(values.PreviousValuePercAbsGross, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsGross, this.locale, 2)
        }

      } else {
        if (this.filters.percTotale) {
          return prec ? this.utility.parseNumber(values.PreviousValuePercRelGrossTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelGrossTotal, this.locale, 2)
        }
        else {
          return prec ? this.utility.parseNumber(values.PreviousValuePercRelGross, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelGross, this.locale, 2)
        }

      }
    } else {
      if (this.filters.ck_abs) {
        if (this.filters.percTotale) {
          return prec ? this.utility.parseNumber(values.PreviousValuePercAbsTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsTotal, this.locale, 2)
        }
        else {
          return prec ? this.utility.parseNumber(values.PreviousValuePercAbs, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbs, this.locale, 2)
        }

      } else {
        if (this.filters.percTotale) {
          return prec ? this.utility.parseNumber(values.PreviousValuePercRelTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelTotal, this.locale, 2)
        }
        else {
          return prec ? this.utility.parseNumber(values.PreviousValuePercRel, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRel, this.locale, 2)
        }

      }
    }
  }

  getDiffExcel(values: any) {
    if (this.filters.ck_lordo) {
      if (this.filters.scostamento) {
        return this.utility.parseNumber(values.PreviousGrossDeviation, this.locale, 2)
      } else {
        return this.utility.parseNumber(values.PreviousGrossDelta, this.locale, 2)
      }
    } else {
      if (this.filters.scostamento) {
        return this.utility.parseNumber(values.PreviousDeviation, this.locale, 2)
      } else {
        return this.utility.parseNumber(values.PreviousDelta, this.locale, 2)
      }
    }
  }

  /* Formatting function for row details - modify as you need */
  format(row: any, trElement: any) {
    if (!row) return;

    let subRows: string = '';

    const col0Style = trElement.find('td:nth-child(1)').attr('style');
    const col1Style = trElement.find('td:nth-child(2)').attr('style');
    const col2Style = trElement.find('td:nth-child(3)').attr('style');
    const col3Style = trElement.find('td:nth-child(4)').attr('style');
    const col4Style = trElement.find('td:nth-child(5)').attr('style');
    const col5Style = trElement.find('td:nth-child(6)').attr('style');
    const col6Style = trElement.find('td:nth-child(7)').attr('style');

    row.Children.forEach((child: any) => {

      const hideRow = this.checkHideRow(child);
      if (hideRow) {
        return;
      }

      const columns = child.Columns;

      subRows += `<tr id='${child.Id}' data-rowid='${row.Id}' class="children" data-row='${JSON.stringify(columns[0].SubColumns)}'>`;
      // Icona +/-
      subRows += '<td class="dtfc-fixed-left" style="' + col0Style + '"></td>';
      // Nome sottocategoria CE
      subRows += `<td class="text-right dtfc-fixed-left" style="${col1Style}" data-categoryname="${child.Name}">
                            <div class="display-flex-space-between">
                                <div class="actions">
                                    <a href="javascript:;" class="detail_ce"><i class="bi bi-search"></i></a>
                                </div> 
                                <span class="clickGraph">
                                    ${child.Name}
                                </span>
                            </div>
                        </td>`;
      // Totale
      subRows += '<td class="text-right dtfc-fixed-left" style="' + col2Style + '">' + this.getValue(columns[0].SubColumns) + ' </td>';
      // Totale %
      if (this.table.column(6).visible()) {
        subRows += '<td class="text-right" style="' + col3Style + '">' + this.getPerc(columns[0].SubColumns) + ' </td>';
      } else {
        subRows += '<td class="text-right last-fixed-col-left" style="' + col3Style + '">' + this.getPerc(columns[0].SubColumns) + ' </td>';
      }
      //Totale prec
      if (this.table.column(4).visible()) subRows += '<td class="text-right dtfc-fixed-left" style="' + col4Style + '">' + this.getValue(columns[0].SubColumns, true) + '</td>';
      //Totale prec %
      if (this.table.column(5).visible()) subRows += '<td class="text-right dtfc-fixed-left" style="' + col5Style + '">' + this.getPerc(columns[0].SubColumns, true) + '</td>';
      // Diff Totale
      if (this.table.column(6).visible()) subRows += '<td class="text-right last-fixed-col-left" style="' + col6Style + '">' + this.getDiff(columns[0].SubColumns) + ' </td>';


      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach((month: number) => {

        // Se il mese è minore di quello corrente e non voglio visualizzare i mesi precedenti, non lo stampo
        if (month < this.currentMonth && !this.previousMonthsVisible) {
          return;
        }

        subRows += '<td class="text-right">';
        subRows += '    <div class="display-flex-space-between">';
        subRows += '        <div class="actions">';
        subRows += '            <a href="javascript:;" class="detail_ce" data-month="' + (month + 1) + '"><i class="bi bi-search"></i></a>';
        subRows += '        </div> ';
        subRows += this.getValue(columns[month + 1].SubColumns);
        subRows += '    </div>';
        subRows += '</td>';

        subRows += '<td class="text-right">' + this.getPerc(columns[month + 1].SubColumns) + ' </td>';
        if (this.table.column((month * 5) + 9).visible()) subRows += '<td class="text-right">' + this.getValue(columns[month + 1].SubColumns, true) + '</td>';
        if (this.table.column((month * 5) + 10).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[month + 1].SubColumns, true) + '</td>';
        if (this.table.column((month * 5) + 11).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[month + 1].SubColumns) + ' </td>';
      });

      subRows += '</tr>';
    });

    return $(subRows);
  }

  async addCE(item: any) {
    this.dialog
      .open(ContoEconomicoEditComponent, {
        width: '800px',
        data: {
          item: item
        }
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          this.table.draw();
          setTimeout(() => {
            const element: any = document.getElementById(item.Id);
            element.scrollIntoView();
            this.ref.detectChanges();
          }, 500);
        }
      });
  }

  async detailCE(item: any, month: number = 0) {

    let filter = { ...this.currentFilterCfg };

    if (month) {
      delete filter.Period;
      filter.DateFilter = {
        Start: moment().set('year', this.getYearByFilter()).set('month', month - 1).startOf('month').format('YYYY-MM-DD'),
        End: moment().set('year', this.getYearByFilter()).set('month', month - 1).endOf('month').format('YYYY-MM-DD')
      };
    } else {
      delete filter.DateFilter;
      filter.Period = 'year';
    }

    this.dialog
      .open(ContoEconomicoDetailsComponent, {
        data: {
          item: item,
          currentFilterCfg: filter,
          locale: this.locale,
          training: this.training
        },
        width: '1200px'
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          this.table.draw();
          setTimeout(() => {
            const element: any = document.getElementById(item.Id);
            element.scrollIntoView();
            this.ref.detectChanges();
          }, 500);
        }
      });
  }

  exportAsXLSX(expandRows: any) {
    this.expandRows = expandRows === true;
    this.table.button(1).trigger();
  }

  exportAsPDF(PDFPreferences: any) {
    this.PDFPreferences = PDFPreferences;
    this.expandRows = PDFPreferences.explode;
    this.table.button(0).trigger();
  }

  performShowTotals(forcedValue: boolean = false) {
    this.showTotals = forcedValue ? forcedValue : !this.showTotals;
    if (this.table) this.table.column(0).visible(!this.showTotals);
    if (!forcedValue) { // Fa già l'initDatatable dal SUbscription del filterService
      this.table.draw();
    }
  }

  togglePreviousMonths() {
    this.previousMonthsVisible = !this.previousMonthsVisible;
    this.refreshColumnsVisibility();
  }
}

