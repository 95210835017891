import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

// RXJS
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

// Pagination
import { PageEvent } from '@angular/material/paginator';

// Utilities
import * as util from '@app/core/services/utilityfunctions';
import { SwitchGroupService } from '@app/core/_base/layout';
import { EditMerciComponent } from '../edit-merci/edit-merci.component';
import { InsertNotesComponent } from '../../gestione-ricette/edit-ricetta/insert-notes/insert-notes.component';
import _ from 'lodash';
import { BulkActionsService } from '@app/core/services/bulk-actions.service';
import { RecipesFromGoodsComponent } from '../recipes-from-goods/recipes-from-goods.component';
import { AddBrandsDlgComponent } from '../../gestione-ricette/add-brands-dlg/add-brands-dlg.component';
import { SetCategoryDlgComponent } from '../../gestione-ricette/set-category-dlg/set-category-dlg.component';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { SetSupplierComponent } from '../set-supplier/set-supplier.component';
import { SetVATDlgComponent } from '../set-vatdlg/set-vatdlg.component';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-manutenzione-merci',
    templateUrl: './manutenzione-merci.component.html',
    styleUrls: ['./manutenzione-merci.component.scss']
})
export class ManutenzioneMerciComponent implements OnInit {

    expandAll: boolean = false;
    
    onChildCreation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    pagination: any;
    utility: any;
    currentFilterCfg: any;
    locale!: string;
    lastRowsExpanded: any = [];
    ProductOrCode: any;
    CategoryIds: any;
    SupplierIds: any;
    bulkSelect!: string;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private gestioneMerciService: GestioneMerciService,
        private translate: TranslateService,
        public staticCollectionsService: StaticCollectionsService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        private ref: ChangeDetectorRef,
        private dialog: MatDialog,
        private bulkActionsService: BulkActionsService,
        private anagraficheService: AnagraficheService,
        public loaderService: LoaderService
    ) {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.utility = util;
        this.initFilter();
    }

    ngOnDestroy() {
    }

    ngOnInit() {
        this.pagination = {
            pageSize: 10,
            TotalRows: 0,
            pageIndex: 0
        };

        this.initTable();

        this.onChildCreation$.subscribe((onCreation: Boolean) => {
            $('.openPopupComplement').off('click');
            if (onCreation) {
                $('.openPopupComplement').on('click', (event: any) => {
                    const alias: any = $(event.target).closest('a').attr('title');
                    const dialogRef = this.dialog.open(InsertNotesComponent, {
                        data: {
                            note: alias,
                            title: this.translate.instant('GESTIONE_MERCI.EDIT.PARAMETERS.POPUP_NOTA.TITLE'),
                            subtitle: this.translate.instant('GESTIONE_MERCI.EDIT.PARAMETERS.POPUP_NOTA.SUBTITLE')
                        },
                        width: '600px',
                        panelClass: 'InsertNotesComponent'
                    });
                    dialogRef.afterClosed().subscribe((res: any) => {
                        if (_.isEmpty(res) || !res) {
                            return;
                        }
                        if (res.success) {
                            $(event.target).closest('a').attr('title', res.Note);
                            if (res.Note.length > 0) {
                                if (!$(event.target).closest('i').hasClass('active')) {
                                    $(event.target).closest('i').addClass('active')
                                }
                            } else {
                                $(event.target).closest('i').removeClass('active');
                            }
                            this.ref.detectChanges();
                        }
                    });

                });
            }

        });

        console.log(this.staticCollectionsService.categorieMerci$);
    }

    initFilter() {
        this.currentFilterCfg = {
            EnableState: 2,
            FBType: 6,
            ShowDisabled: false,
            ShowInvisible: false,
            VisibleState: 1
        };
    }

    clearFilters() {
        this.CategoryIds = null;
        this.ProductOrCode = null;
        this.SupplierIds = null;
        this.initFilter();
        this.table.draw();
    }

    manageProductOrCodeKeyDown(event: KeyboardEvent) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            this.filter(this.ProductOrCode, 'FreeSearch');
        }
    }

    selectCategories(event: any) {
        this.filter(event, 'CategoryIds');
    }

    selectSupplier(event: any) {
        this.filter(event ? event : [], 'SupplierIds');
    }

    filter(event: any, type: string) {
        if (!this.currentFilterCfg) {
            this.initFilter();
        }
        delete this.currentFilterCfg.FBType;
        this.currentFilterCfg[type] = event;
        this.table.draw();
    }

    hoverglassActive: boolean = false;
    initTable() {

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            let dtOptions: any = {
                destroy: true,
                paging: true,
                searching: true,
                dom: 'rt<"paginator">',
                ordering: true,
                scrollY: 'calc(100vh - 350px)',
                scrollX: false,
                scrollCollapse: true,
                autoWidth: true,
                order: [[1, 'desc']],
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Entity/DataGoodsPage",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.ref.detectChanges();

                        response.data.forEach((merce: any) => {
                            merce.Suppliers.forEach(e => {
                                if (e.Variations && e.Variations.length > 0) {
                                    e.Variations.forEach(v => {
                                        v.OtherUnitRatio = v.OtherUnitRatio ? util.formatNumber(v.OtherUnitRatio, this.locale, 3) : 0;
                                        v.SecondaryUnitRatio = v.SecondaryUnitRatio ? util.formatNumber(v.SecondaryUnitRatio, this.locale, 3) : 0;
                                        v.Price = v.Price ? util.formatNumber(v.Price, this.locale, 3) : 0;
                                        v.S1 = v.S1 ? util.formatNumber(v.S1, this.locale, 2) : 0;
                                        v.S2 = v.S2 ? util.formatNumber(v.S2, this.locale, 2) : 0;
                                        v.S3 = v.S3 ? util.formatNumber(v.S3, this.locale, 2) : 0;
                                        v.S4 = v.S4 ? util.formatNumber(v.S4, this.locale, 2) : 0;
                                        v.QxC = v.QxC ? util.formatNumber(v.QxC, this.locale, 2) : 0;
                                        v.SupplierId = e.Id;
                                    });
                                }
                            });
                        })

                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: [0], width: '20px', className: 'parent-row', render: (data) => {
                            return `<i class="fas expander pointer "></i>`;
                        }, orderable: false
                    },
                    {
                        targets: [1], width: '100px', data: 'Code', name: 'Code', render: (data) => {
                            return `<div class="overflow-ellipses" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }
                    },
                    {
                        targets: [2], data: 'Name', name: 'Name', render: (data, x, item) => {
                            return `<input class="form-control inputController" value="${data}"/>`;
                        }
                    },
                    {
                        targets: [3], data: 'AlternateName', name: 'AlternateName', render: (data) => {
                            return `<input class="form-control inputController" value="${data}"/>`;
                        }
                    },
                    {
                        targets: [4], width: '50px', data: 'VAT', name: 'VAT', className: 'text-right', render: (data) => {
                            return `<input class="form-control inputNumberController" value="${this.utility.formatNumber(data, this.locale)}"/>`;
                        }
                    },
                    {
                        targets: [5], width: '150px', data: 'Category', name: 'Category', render: (data, type, p, meta) => {

                            const optionsCategory = (categoryId: string) => {
                                let options = "";
                                this.staticCollectionsService.categorieMerci$.forEach(category => {
                                    options += `<option value="${category.Id}" ${categoryId == category.Id ? 'selected' : ''}>${category.Name}</option>`;
                                });
                                return options;
                            };
                            return `
                            <select class="form-control">
                                ${optionsCategory(p.CategoryId)}
                            </select>
                            `;

                        }
                    },
                    {
                        targets: [6], width: '50px', data: 'IsRecipeUsable', className: 'text-center', name: 'IsRecipeUsable', render: (data) => {
                            let icon = '';
                            if (data) {
                                icon = 'bi bi-check-square';
                            } else {
                                icon = 'bi bi-square';
                            }
                            return `<i class="${icon}"></i>`;
                        }
                    },
                    {
                        targets: [7], width: '50px', data: 'WastePercentage', className: 'text-right', name: 'Waste', render: (data) => {
                            return `<input class="form-control inputNumberController" value="${this.utility.formatNumber(data, this.locale)}"/>`;
                        }
                    },
                    {
                        targets: [8], width: '50px', data: 'Unit', name: 'Unit', render: (data, type, p, meta) => {

                            const optionsUnit = (unitId: string) => {
                                let options = "";
                                this.staticCollectionsService.unit$.forEach(unit => {
                                    options += `<option value="${unit.Id}" ${unitId == unit.Id ? 'selected' : ''}>${unit.Name}</option>`;
                                });
                                return options;
                            };

                            return `
                                <select class="form-control">
                                    ${optionsUnit(p.UnitId)}
                                </select>
                            `;
                        }
                    },
                    {
                        targets: [9], width: '70px', orderable: false, className: 'text-center', render: (data, type, p, meta) => {

                            return `<div class="d-flex justify-content-end align-items-center flex-align-center">
                                    <i class="bi bi-square text-primary bulkSelection pointer"></i>
                                    <div class="h_spacer_half"></div>
                                    <i class="bi bi-pencil-square text-primary pointer editItem"></i>
                                    <div class="h_spacer_half"></div>
                                    <i class="bi bi-floppy text-primary pointer saveItem"></i>
                                </div>
                            `;
                        }
                    }
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
                }
            };

            this.table = ($('#tableManutenzioneMerci') as any).DataTable(dtOptions);
            // Mi collego all'evento di loading della tabella
            this.table.off('processing.dt');
            this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
                if (processing) {
                    this.loaderService.show();
                } else {
                    this.loaderService.hide();
                }
                this.ref.detectChanges();
            });

            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });

            //this.table.columns.adjust().draw();
            setTimeout(() => {

                $('#tableManutenzioneMerci tbody').off('click');

                $('#tableManutenzioneMerci tbody').on('click', 'i.bulkSelection', (event: any) => {
                    const fa = $(event.target);
                    if (fa.hasClass('bi bi-check-square')) {
                        fa.removeClass('bi bi-check-square');
                        fa.addClass('bi bi-square');
                    } else {
                        fa.removeClass('bi bi-square');
                        fa.addClass('bi bi-check-square');
                    }
                });

                $('#tableManutenzioneMerci tbody').on('click', 'i.saveItem', (event: any) => {
                    const tr = $(event.target).closest('tr');
                    const data: any = this.table.row(tr).data();
                    if (data) {
                        this.saveItem(data, tr);
                    }
                });

                $('#tableManutenzioneMerci tbody').on('click', 'i.editItem', (event: any) => {
                    const tr = $(event.target).closest('tr');
                    const data: any = this.table.row(tr).data();
                    if (data) {
                        this.editItem(data);
                    }
                });

                $('#tableManutenzioneMerci tbody').on('click', 'td.parent-row', (event: any) => {
                    const tr = $(event.target).closest('tr');
                    const row = this.table.row(tr);

                    if (row.child.isShown()) {
                        this.lastRowsExpanded = this.lastRowsExpanded.filter((row: any) => row != tr.index());
                        // This row is already open - close it
                        row.child.hide();
                        tr.removeClass('shown');
                        this.onChildCreation$.next(false);
                    } else {

                        if (!this.lastRowsExpanded.includes(tr.index()))
                            this.lastRowsExpanded.push(tr.index());

                        // Open this row
                        row.child(this.format(row.data())).show();
                        tr.addClass('shown');
                        this.onChildCreation$.next(true);
                    }
                });

                $('.childTable tbody').off('click');
                $('.childTable tbody').on('click', '.inputNumberController', (event: any) => {
                    $(event.target).focus();
                    $(event.target).select();
                });
                $('.childTable tbody').off('blur');
                $('.childTable tbody').on('blur', '.inputNumberController', (event: any) => {
                    // Tengo solo i numeri
                    //const code = event.target.value.replace(/[^\d.-]/g, '');
                    const decSeparator = this.utility.getSeparator(this.locale);
                    if (decSeparator == ",") {
                        // In ogni caso converto la virgola in punto per avere una stringa da formattare
                        event.target.value = event.target.value.indexOf(',') >= 0 && event.target.value.indexOf('.') ? event.target.value.replace('.', '').replace(',', '.') : (
                            event.target.value.indexOf(',') >= 0 ? event.target.value.replace(',', '.') : event.target.value
                        );
                    } else {
                        event.target.value = event.target.value.replace(',', '');
                    }
                    event.target.value = this.utility.formatNumber(parseFloat(event.target.value), this.locale);
                });
            }, 1000);

            this.bulkSelect = "";

            this.ref.detectChanges();
        }, 100);
    }

    format(merce: any) {

        if (!merce) {
            console.error('Attenzione merce null');
            return;
        }

        const optionsUnit = (variationId: string) => {
            let options = "";
            this.staticCollectionsService.orderUnit$.forEach(unit => {
                options += `<option value="${unit.Id}" ${variationId == unit.Id ? 'selected' : ''}>${unit.Name}</option>`;
            });
            return options;
        };

        let trs = '';
        merce.Suppliers.forEach(supplier => {
            if (supplier.IsDisabled) return;
            // Se è attivo il filtro per fornitori allora visualizzo solo quelli selezionati
            if (this.SupplierIds && this.SupplierIds.length > 0) {
                const exists = this.SupplierIds.find(supId => supId === supplier.Id);
                if (!exists) return;
            }
            supplier.Variations.forEach(variation => {
                trs += `
                    <tr data-supplierid="${supplier.Id}" data-variationcode="${variation.Code}" data-merceid="${merce.Id}">
                        <td class="supNameCol"><div class="overflow-ellipses-100" data-toggle="tooltip" title="${supplier.Name}">${supplier.Name}</div></td>
                        <td class="varCodeCol"><div class="overflow-ellipses-100" data-toggle="tooltip" title="${variation.Code}">${variation.Code}</div></td>                        
                        <td class="varPriceCol hasInput">
                            <input type="text" class="form-control text-right inputNumberController" value="${this.utility.formatNumber(variation.Price, this.locale)}">
                        </td>
                        <td class="varSecUnitCol hasInput">
                            <select class="form-control" value="${variation.SecondaryUnit}">
                                ${optionsUnit(variation.SecondaryUnitId)}
                            </select>
                        </td>
                        <td class="varSecUnitRatioCol hasInput">
                            <input type="text" class="form-control text-right inputNumberController" value="${this.utility.formatNumber(variation.SecondaryUnitRatio, this.locale)}">
                        </td>
                        <td class="varOtherUnitCol hasInput">
                            <select class="form-control" value="${variation.SecondaryUnit}">
                                ${optionsUnit(variation.OtherUnitId)}
                            </select>
                        </td>
                        <td class="varOtherUnitRatioCol hasInput">
                            <input type="text" class="form-control text-right inputNumberController" value="${this.utility.formatNumber(variation.OtherUnitRatio, this.locale)}">
                        </td>                        
                        <td class="varQxCCol hasInput">
                            <input type="text" class="form-control text-right inputNumberController" value="${this.utility.formatNumber(variation.QxC, this.locale)}">
                        </td>
                        <td class="varS1Col hasInput">
                            <input type="text" class="form-control text-right inputNumberController" value="${this.utility.formatNumber(variation.S1, this.locale)}">
                        </td>
                        <td class="varS2Col hasInput">
                            <input type="text" class="form-control text-right inputNumberController" value="${this.utility.formatNumber(variation.S2, this.locale)}">
                        </td>
                        <td class="varS3Col hasInput">
                            <input type="text" class="form-control text-right inputNumberController" value="${this.utility.formatNumber(variation.S3, this.locale)}">
                        </td>
                        <td class="varS4Col hasInput">
                            <input type="text" class="form-control text-right inputNumberController" value="${this.utility.formatNumber(variation.S4, this.locale)}">
                        </td>
                        <td class="varAliasCol hasInput">
                            <a href="javascript:;" class="openPopupComplement" data-toggle="tooltip" title="${variation.Alias.trim() ? variation.Alias.trim() : this.translate.instant('GESTIONE_MERCI.EDIT.PARAMETERS.TOOLTIP_ALIAS')}">
                                <i class="bi bi-sticky pointer ${variation.Alias.trim() ? 'active' : ''}"></i>
                            </a>
                        </td>
                    </tr>
                `;

            });
        });

        let table = `
            <table class="childTable" id="variationmerceid${merce.Id}">
                <thead>
                    <tr>
                        <th>${this.translate.instant('GESTIONE_MERCI.COLUMNS.SUPPLIER')}</th>
                        <th>${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.CODICE')}</th>
                        <th><i class="fas fa-info-circle" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.PREZZO_UNITA_MAGAZZINO_TOOLTIP')}"></i>&nbsp;${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.PREZZO_UNITA_MAGAZZINO')}</th>
                        <th><i class="fas fa-info-circle" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.UNITA_MAGAZZINO_TOOLTIP')}"></i>&nbsp;${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.UNITA_MAGAZZINO')}</th>
                        <th><i class="fas fa-info-circle" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.RAPPORTO_UM_UB_TOOLTIP')}"></i>&nbsp;${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.RAPPORTO_UM_UB')}</th>
                        <th><i class="fas fa-info-circle" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.UNITA_ORDINE_TOOLTIP')}"></i>&nbsp;${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.UNITA_ORDINE')}</th>
                        <th><i class="fas fa-info-circle" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.RAPPORTO_UO_UM_TOOLTIP')}"></i>&nbsp;${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.RAPPORTO_UO_UM')}</th>                        
                        <th>${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.QxC')}</th>
                        <th>${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.S1')}</th>
                        <th>${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.S2')}</th>
                        <th>${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.S3')}</th>
                        <th>${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.S4')}</th>
                        <th class="varAliasCol">${this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.COLONNE.ALIAS')}</th>
                    </tr>
                </thead>
                <tbody>
                    ${trs}
                </tbody>
            </table>
        `;

        return table;
    }

    table: any;

    changePagination(event: PageEvent) {
        if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        } else if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.table.page.len(this.pagination.pageSize).draw();
        }
    }

    setGoodsVisibility(row: any, company: string) {
        var visibility = !row.Infos[company];
        this.gestioneMerciService.setGoodsVisibility({
            Id: row.Id,
            Company: company,
            IsVisible: visibility
        }).toPromise()
            .then((result: any) => {
                const message = this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI_AGGIORNATA');
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            });
    }

    checKeypress(event: any, row: any, companyName: string) {
        // Se preme barra spazio
        if (event.keyCode == 32) {
            this.setGoodsVisibility(row, companyName);
        }
    }

    copyDown(merce: any, rowMerce: any) {

    }

    saveItem(merce: any, rowMerce: any) {
        const tableVariations = $(`#variationmerceid${merce.Id}`);
        tableVariations.find('tbody tr').each((index, row) => {
            const supplierId = $(row).data('supplierid');
            const variationCode = $(row).data('variationcode');

            const indexSupplier = merce.Suppliers.findIndex(supplier => supplier.Id == supplierId);
            const indexVariation = merce.Suppliers[indexSupplier].Variations.findIndex(variation => variation.Code == variationCode);

            merce.Suppliers[indexSupplier].Variations[indexVariation].Price = this.utility.parseNumber($(row).find('td:nth-child(3) input').val(), this.locale, 3);
            merce.Suppliers[indexSupplier].Variations[indexVariation].SecondaryUnit = $(row).find('td:nth-child(4) select option:selected').text();
            merce.Suppliers[indexSupplier].Variations[indexVariation].SecondaryUnitId = this.utility.parseNumber($(row).find('td:nth-child(4) select').val(), this.locale, 0);
            merce.Suppliers[indexSupplier].Variations[indexVariation].SecondaryUnitRatio = this.utility.parseNumber($(row).find('td:nth-child(5) input').val(), this.locale);
            merce.Suppliers[indexSupplier].Variations[indexVariation].OtherUnit = $(row).find('td:nth-child(6) select option:selected').text();
            merce.Suppliers[indexSupplier].Variations[indexVariation].OtherUnitId = this.utility.parseNumber($(row).find('td:nth-child(6) select').val(), this.locale, 0);
            merce.Suppliers[indexSupplier].Variations[indexVariation].OtherUnitRatio = this.utility.parseNumber($(row).find('td:nth-child(7) input').val(), this.locale);
            merce.Suppliers[indexSupplier].Variations[indexVariation].QxC = this.utility.parseNumber($(row).find('td:nth-child(8) input').val(), this.locale);
            merce.Suppliers[indexSupplier].Variations[indexVariation].S1 = this.utility.parseNumber($(row).find('td:nth-child(9) input').val(), this.locale);
            merce.Suppliers[indexSupplier].Variations[indexVariation].S2 = this.utility.parseNumber($(row).find('td:nth-child(10) input').val(), this.locale);
            merce.Suppliers[indexSupplier].Variations[indexVariation].S3 = this.utility.parseNumber($(row).find('td:nth-child(11) input').val(), this.locale);
            merce.Suppliers[indexSupplier].Variations[indexVariation].S4 = this.utility.parseNumber($(row).find('td:nth-child(12) input').val(), this.locale);
            merce.Suppliers[indexSupplier].Variations[indexVariation].Alias = $(row).find('td:nth-child(13) .openPopupComplement').attr('title');

            this.copyAllDataToCompanies(merce.Suppliers[indexSupplier].Variations[indexVariation], merce);
        });



        merce.Name = rowMerce.find('td:nth-child(3) input').val();
        merce.AlternateName = rowMerce.find('td:nth-child(4) input').val();
        merce.VAT = this.utility.parseNumber(rowMerce.find('td:nth-child(5) input').val(), this.locale);
        merce.Category = rowMerce.find('td:nth-child(6) select option:selected').text();
        merce.CategoryId = this.utility.parseNumber(rowMerce.find('td:nth-child(6) select').val(), this.locale, 0);
        merce.WastPercentage = this.utility.parseNumber(rowMerce.find('td:nth-child(8) input').val(), this.locale);
        merce.Unit = rowMerce.find('td:nth-child(9) select option:selected').text();
        merce.UnitId = this.utility.parseNumber(rowMerce.find('td:nth-child(9) select').val(), this.locale, 0);

        // Prova controllo coerenza dati
        if (!this.checkErrorBeforeSave(merce)) {
            return;
        }

        if (!merce.Brands) {
            merce['Brands'] = []
        }

        const _title: string = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((expand: any) => {
            if (expand) {
                this.loaderService.show();
                this.fixNumericFields(merce)
                this.clearFromIds(merce)

                this.gestioneMerciService.updateGoods(merce, this.data.linkedGoodId).subscribe(
                    (result: any) => {
                        this.loaderService.hide();

                        let message: string = '';
                        if (result.SavedId) {
                            message = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        } else {
                            message = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }

                    }, (error: any) => {
                        console.log('updateGoods', error);
                        this.loaderService.hide();
                    }
                );
            }
        });
    }

    editItem(merce: any) {
        const dialogRef = this.dialog.open(EditMerciComponent, {
            data: {
                merce: merce
            },
            width: '100%',
            height: '100%'
        });
    }

    expandAllRows() {
        this.expandAll = !this.expandAll;
        this.lastRowsExpanded = [];
        const trList = $('#tableManutenzioneMerci tbody tr');
        trList.each((index: number, tr: any) => {
            const row = this.table.row(tr);

            if (!this.expandAll) {
                // This row is already open - close it
                row.child.hide();
                $(tr).removeClass('shown');
                this.onChildCreation$.next(false);

            } else {

                if (!this.lastRowsExpanded.includes(index))
                    this.lastRowsExpanded.push(index);

                // Open this row
                row.child(this.format(row.data())).show();
                $(tr).addClass('shown');
                this.onChildCreation$.next(true);
            }
        });


    }

    copyAllDataToCompanies(variation: any, merce: any) {
        merce.Prices.forEach((price: any) => {

            // Aggiunto perchè con nuova merce il controllo sottostante 
            // non permette di proseguire in quanto non è presente l'id del Supplier.
            if (!price.SupplierId) {
                price.SupplierId = variation.SupplierId;
            }

            // http://mantis.fbmanager.com/view.php?id=855 nel doc allegato
            if (price.SupplierId != variation.SupplierId) return;

            price.OtherUnit = variation.OtherUnit;
            price.OtherUnitId = variation.OtherUnitId;
            price.OtherUnitRatio = variation.OtherUnitRatio;
            price.SecondaryUnit = variation.SecondaryUnit;
            price.SecondaryUnitId = variation.SecondaryUnitId;
            price.SecondaryUnitRatio = variation.SecondaryUnitRatio;
            price.PriceUM = this.utility.parseNumber(variation.Price, this.locale, 4);

            price.SupplierId = variation.SupplierId;

            this.changeRowEvent({
                item: price,
                type: 'PriceUM'
            }, merce);
        });
    }

    changeRowEvent(model: any, merce: any) {
        switch (model.type) {
            case 'Price':
            case 'OtherUnit':
            case 'SecondaryUnit':
            case 'OtherUnitRatio':
            case 'SecondaryUnitRatio':
                {
                    const price = util.parseNumber(model.item.Price, this.locale, 3);
                    const secondaryUnitRatio = util.parseNumber(model.item.SecondaryUnitRatio, this.locale);
                    const otherUnitRatio = util.parseNumber(model.item.OtherUnitRatio, this.locale);

                    model.item.PriceUM = this.utility.parseNumber(price * secondaryUnitRatio, this.locale, 4);
                    model.item.PriceUO = this.utility.parseNumber(price * secondaryUnitRatio * otherUnitRatio, this.locale, 4);

                    const index = merce.Prices.findIndex((el: any) => el.Id == model.item.Id);
                    merce.Prices[index].PriceUM = model.item.PriceUM;
                    merce.Prices[index].PriceUO = model.item.PriceUO;

                    break;
                }

            case 'PriceUM':
                {
                    const priceUM = util.parseNumber(model.item.PriceUM, this.locale);
                    const secondaryUnitRatio = util.parseNumber(model.item.SecondaryUnitRatio, this.locale);
                    const otherUnitRatio = util.parseNumber(model.item.OtherUnitRatio, this.locale);

                    // secondaryUnitRatio => rapp uM / UB

                    model.item.Price = this.utility.parseNumber(priceUM / secondaryUnitRatio, this.locale, 4);
                    model.item.PriceUO = this.utility.parseNumber(priceUM * otherUnitRatio, this.locale, 4);
                    break;
                }

            case 'PriceUO':
                {
                    const priceUO = util.parseNumber(model.item.PriceUO, this.locale);
                    const secondaryUnitRatio = util.parseNumber(model.item.SecondaryUnitRatio, this.locale);
                    const otherUnitRatio = util.parseNumber(model.item.OtherUnitRatio, this.locale);

                    model.item.PriceUM = this.utility.parseNumber(priceUO / otherUnitRatio, this.locale, 4);
                    model.item.Price = this.utility.parseNumber(priceUO / secondaryUnitRatio / otherUnitRatio, this.locale, 4);
                    break;
                }

        }

    }

    checkErrorBeforeSave(merce: any) {

        if (!merce.AlternateName) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_NOME_MERCE');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (!merce.Name) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_PRODOTTO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (!merce.GroupId) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_GROUP');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (!merce.CategoryId) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_CATEGORY');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (merce.Prices.filter((price: any) => !price.SupplierId).length > 0) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_SUPPLIERS_DEFAULT');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (!merce.UnitId) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_UNIT');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        // Controllo che tutte le unità di misura siamo compilate (merce.Suppliers, merce.Prices)         
        const suppliersHasUnits = merce.Suppliers.every((s: any) => s.Variations.every(v => v.OtherUnitId >= 0));
        const suppliersHasSecondaryUnits = merce.Suppliers.every((s: any) => s.Variations.every(v => v.SecondaryUnitId >= 0));
        const pricesHasUnits = merce.Prices.every(p => p.OtherUnitId >= 0);
        const pricesHasSecondaryUnits = merce.Prices.every(p => p.SecondaryUnitId >= 0);

        if (!suppliersHasUnits || !suppliersHasSecondaryUnits) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_SUPPLIERS_UNITS');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tableVariations tbody .select2Component').filter((i, e) => {
                return $(e).find('option:selected').text().trim() == "---";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        if (!pricesHasUnits || !pricesHasSecondaryUnits) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_PRICES_UNITS');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tablePuntiVendita tbody .select2Component').filter((i, e) => {
                return $(e).find('option:selected').text().trim() == "---";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        // Verifico anche il rapporto UO/UM e rapporto UM/UB
        const suppliersHasRatioUOUM = merce.Suppliers.every((s: any) => s.Variations.every(v => this.utility.parseNumber(v.OtherUnitRatio, this.locale) > 0));
        if (!suppliersHasRatioUOUM) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_OTHER_UNIT_RATIO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tableVariations tbody .OtherUnitRatio').filter((i, e) => {
                return $(e).text() == "";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        const suppliersHasRatioUMUB = merce.Suppliers.every((s: any) => s.Variations.every(v => this.utility.parseNumber(v.SecondaryUnitRatio, this.locale) > 0));
        if (!suppliersHasRatioUMUB) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_SECONDARY_UNIT_RATIO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tableVariations tbody .SecondaryUnitRatio').filter((i, e) => {
                return $(e).text() == "";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        return true

    }

    fixNumericFields(merce) {
        merce.WastPercentage = merce.WastPercentage ? util.parseNumber(merce.WastPercentage, this.locale) : 0;
        merce.ProductionRatio = merce.ProductionRatio ? util.parseNumber(merce.ProductionRatio, this.locale) : 0;
        if (!merce.Prices) merce['Prices'] = [];
        merce.Prices.forEach(e => {
            e.OtherUnitRatio = e.OtherUnitRatio ? util.parseNumber(e.OtherUnitRatio, this.locale) : 0;
            e.SecondaryUnitRatio = e.SecondaryUnitRatio ? util.parseNumber(e.SecondaryUnitRatio, this.locale) : 0;

            e.Price = e.Price ? util.parseNumber(e.Price, this.locale) : 0;
            e.PriceUM = e.PriceUM ? util.parseNumber(e.PriceUM, this.locale) : 0;
            e.PriceUO = e.PriceUO ? util.parseNumber(e.PriceUO, this.locale) : 0;
        });

        if (!merce.Suppliers) merce['Suppliers'] = [];
        merce.Suppliers.forEach(e => {
            e.Variations.forEach(v => {
                v.OtherUnitRatio = v.OtherUnitRatio ? util.parseNumber(v.OtherUnitRatio, this.locale) : 0;
                v.SecondaryUnitRatio = v.SecondaryUnitRatio ? util.parseNumber(v.SecondaryUnitRatio, this.locale) : 0;
                v.Price = v.Price ? util.parseNumber(v.Price, this.locale) : 0;
                v.S1 = v.S1 ? util.parseNumber(v.S1, this.locale) : 0;
                v.S2 = v.S2 ? util.parseNumber(v.S2, this.locale) : 0;
                v.S3 = v.S3 ? util.parseNumber(v.S3, this.locale) : 0;
                v.S4 = v.S4 ? util.parseNumber(v.S4, this.locale) : 0;
                v.QxC = v.QxC ? util.parseNumber(v.QxC, this.locale) : 0;
            });
        });
    }

    private clearFromIds(merce) {
        merce.Suppliers.forEach((element: any) => {
            delete element.$id;
        });

        merce.Prices.forEach((element: any) => {
            delete element.$id;
        });
    }

    almoustOneSelected() {
        const almoustOne = $('#tableManutenzioneMerci tbody td:last-child i.bi.bi-check-square');
        return almoustOne.length > 0;
    }

    bulkSelection(event: any) {
        //if (!this.listaFiltered || this.listaFiltered.length == 0 || !event || !event.value) return;
        let _title: string = '';
        switch (event.value.toLowerCase()) {
            case 'setcategory':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_CATEGORY');
                break;
            case 'setdisabled':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_DISABLED');
                break;
            case 'setenabled':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_ENABLED');
                break;
            case 'setinvisible':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_INVISIBLE');
                break;
            case 'setvisible':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_VISIBLE');
                break;
            case 'setfresh':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_FRESH');
                break;
            case 'setnotfresh':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_NOT_FRESH');
                break;
            case 'setfinishedproduct':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_FINISHED');
                break;
            case 'setnotfinishedproduct':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_NOT_FINISHED');
                break;
            case 'setfoodcostbylist':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.FOOD_COST_CALC_PAM');
                break;
            case 'setfoodcostbyaverage':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.FOOD_COST_CALC_PMP');
                break;
            case 'setfoodcostbylast':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.FOOD_COST_CALC_UP');
                break;
            case 'setvat':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_VAT');
                break;
            case 'delete':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.DELETE');
                break;
            case 'recipefromgood':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.RECIPE_FROM_GOOD');
                break;
            case 'isrecipeusable':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_RECIPE_USABLE');
                break;
            case 'isrecipeunusable':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_RECIPE_UNUSABLE');
                break;
            case 'setalertprice':
                _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_PRICE_ALERT');
                break;
        }

        const _description: string = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.CONFIRM_ASK');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('COMMONS.CONFIRM');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async (ok: any) => {
            if (ok) {
                this.performBulkSelection(event);
                //} else {
                //    this.bulkSelect = "";
                //    this.ref.detectChanges();
            }
            this.bulkSelect = "";
            this.ref.detectChanges();
            //this.listaFiltered.forEach((item: any) => item['selected'] = false);
        });

    }

    async performBulkSelection(event: any) {
        //const selectedItems = this.listaFiltered.filter((item: any) => item.selected);

        let selectedItems: any = [];
        $('#tableManutenzioneMerci tbody td:last-child i.bi.bi-check-square').each((index: number, el: any) => {

            const tr = $(el).closest('tr');
            const data: any = this.table.row(tr).data();
            selectedItems.push(data);

        });

        switch (event.value.toLowerCase()) {
            case 'recipefromgood': {

                // Verifico che tutte le merci selezionate abbiano la stessa nità di base
                let units: any = [];
                let i: number = 0;
                while (i < selectedItems.length) {
                    const index = units.findIndex(u => u.unit == selectedItems[i].Unit)
                    if (index >= 0) {
                        units[index].count++;
                    } else {
                        units.push({
                            count: 1,
                            unit: selectedItems[i].Unit,
                            unitId: selectedItems[i].UnitId,
                        });
                    }
                    i++;
                }

                if (units.length >= 2) {

                    const _title: string = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.RECIPE_FROM_GOOD_NOT_SAME_UNIT');
                    const _yesButton = this.translate.instant('COMMONS.OK');
                    //const _noButton = this.translate.instant('COMMONS.CANCEL');
                    //const _yesButton2 = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.RECIPE_FROM_GOOD_CONTINUA_ANYWAY');

                    let _description = '';

                    selectedItems.forEach(item => {
                        _description += `${item.Name} (${item.Unit})\n`;
                    })

                    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, '', _yesButton);
                    //dialogRef.afterClosed().subscribe(async (ok: any) => {

                    //});

                } else {
                    const dialogRef = this.dialog.open(RecipesFromGoodsComponent, {
                        width: '400px',
                        data: {
                            baseUnitId: units[0].unitId
                        }

                    });
                    dialogRef.afterClosed().subscribe(async (res: any) => {
                        if (res && res.baseUnitId) {
                            await this.bulkActionsService.massiveActions({
                                "Action": "Recipe_FromGood",
                                "EntityIds": selectedItems.map((item: any) => item.Id),
                                "RecipeFromGood": res
                            }).toPromise();
                            //this.refreshTable();
                            const message = this.translate.instant('GESTIONE_RICETTE.SAVE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        }
                    });
                }

            }
                break;
            case 'addbrands': {
                const brands = await this.anagraficheService.getBrands().toPromise();
                const dialogRef = this.dialog.open(AddBrandsDlgComponent, {
                    data: {
                        brandsList: brands
                    },
                    width: '400px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Goods_AddBrands",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Targets": res
                    }).toPromise();
                    this.table.draw();
                });
            }
                break;
            case 'setcategory': {
                const categorieProdotti = await this.gestioneMerciService.getGoodsCategories().toPromise();
                const dialogRef = this.dialog.open(SetCategoryDlgComponent, {
                    data: {
                        categorieProdotti: categorieProdotti
                    },
                    width: '400px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Goods_SetCategory",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res
                    }).toPromise();
                    this.table.draw();
                });
            }
                break;
            case 'addsupplier': {
                const suppliers = await this.gestioneMerciService.getSuppliers().toPromise();
                const dialogRef = this.dialog.open(SetSupplierComponent, {
                    data: {
                        suppliers: suppliers
                    },
                    width: '600px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Goods_AddSupplier",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res,
                        "Content": res.companies.join(",")
                    }).toPromise();
                    this.table.draw();
                });
            }
                break;

            case 'isrecipeusable': {
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetUsableInRecipe",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
            }
                break;

            case 'isrecipeunusable': {
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetUsableInRecipe",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
            }
                break;

            case 'setalertprice': {
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetAlertPrice",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
            }
                break;

            case 'setdisabled':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetDisabled",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
                
            case 'setenabled':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetDisabled",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
                break;
            case 'setinvisible':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetInvisible",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
            case 'setvisible':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetInvisible",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
                break;
            case 'setfresh':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFresh",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
            case 'setnotfresh':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFresh",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
                break;
            case 'setfinishedproduct':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFinishedProduct",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
            case 'setnotfinishedproduct':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFinishedProduct",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
                break;
            case 'setfoodcostbylist':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFoodCostByList",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                }).toPromise();
                this.table.draw();
                break;
            case 'setfoodcostbyaverage':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFoodCostByAverage",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                }).toPromise();
                this.table.draw();
                break;
            case 'setfoodcostbylast':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFoodCostByLast",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                }).toPromise();
                this.table.draw();
                break;
            case 'setvat': {
                const dialogRef = this.dialog.open(SetVATDlgComponent, {
                    width: '300px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Goods_SetVAT",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res
                    }).toPromise();
                    this.table.draw();
                });
            }
                break;
            case 'delete': {
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_Delete",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                }).toPromise();
                this.table.draw();
                break;
            }
                break;
        }
    }
}

