// Angular
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { AnagraficheService } from '@app/core/services/anagrafiche.service';

// Translate Module
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

export enum InfoType {
    name = 'name',
    id = 'id',
    initiallyChecked = 'initiallyChecked',
    percentage = 'percentage'
}

/**
 * Node for to-do name
 */
export class PLNode {
    children!: PLNode[];
    name!: string;
    id?: string;
    initiallyChecked: boolean = false;
    percentage!: number;
}

@Component({
    selector: 'kt-categorie-merci-edit',
    templateUrl: './categorie-merci-edit.component.html',
    styleUrls: ['./categorie-merci-edit.component.scss']
})
export class CategorieMerciEditComponent implements OnInit, OnDestroy {

    // Public properties
    content: any;
    contentForm!: FormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    editMode: boolean = false;

    treeControl = new NestedTreeControl<PLNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<PLNode>();
    plStructure: any;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private anagraficheService: AnagraficheService,
        private translate: TranslateService
    ) {
    }

    async ngOnInit() {
        this.content = this.data.item;
        this.editMode = this.content.Id && this.content.Id > 0;
        this.createForm();

        this.plStructure = await this.anagraficheService.getPLStructure().toPromise();

        // Ottenuto la strutura la mando alla libreria per la tree view

        let structure = {};
        this.plStructure.forEach(node => {
            const parentStructure = this.createStructure(node);
            structure[`${node.Name}#${node.Id}`] = {};
            Object.keys(parentStructure).forEach(child => {
                parentStructure[child].forEach(child => {
                    for (const [key, value] of Object.entries(child)) {
                        structure[`${node.Name}#${node.Id}`][key] = value
                    }
                })
            })
        });

        this.dataSource.data = this.initialize(structure);

        Object.keys(this.dataSource.data).forEach((x: any) => {
            // imposto il parent di ogni nodo
            //this.setParent(this.dataSource.data[x], null);

            // vado a verificare se inizialmente c'è qualche nodo che deve essere flaggato.
            this.checkInitliallyChecked(this.dataSource.data[x])
        });

        console.log('this.dataSource.data', this.dataSource.data);

        $('body').on('keyup', (e: any) => {
            if (e.code == 'F2') {
                e.preventDefault();
                e.stopPropagation();
                this.onSubmit();
            }
        });


    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        $('body').off('keyup');
    }

    closeDialog() {
        this.dialogRef.close(null);
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    createForm() {
        this.contentForm = this.fb.group({
            Name: [this.content.Name, Validators.required],
            //Code: [this.content.Code, null],
            PLCode: [this.content.PLCode, null],
            //InventoryCode: [this.content.InventoryCode, null],
            FBType: [this.content.FBType, Validators.required],
            IsExportable: [this.content.IsExportable, null],
            IsImport: [this.content.IsImport, null],
            IsDefault: [this.content.IsDefault, null]
        });
    }

    /**
 * Returns page title
 */
    getTitle(): string {
        if (this.editMode) {
            return this.translate.instant('COMMONS.EDIT') + ` '${this.content.Name}'`;
        }
        return this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.NEW_ENTITY');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.contentForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    /** ACTIONS */

    /**
     * Returns prepared customer
     */
    prepareItem(): any {
        const controls = this.contentForm.controls;

        this.content.Name = controls['Name'].value;
        //this.content.Code = controls['Code'].value;
        this.content.PLCode = controls['PLCode'].value;
        //this.content.InventoryCode = controls['InventoryCode'].value;
        this.content.FBType = controls['FBType'].value;
        this.content.IsExportable = controls['IsExportable'].value;
        this.content.IsImport = controls['IsImport'].value;
        this.content.IsDefault = controls['IsDefault'].value;
        //this.content.LinkedProfittAndLossIds = this.content.LinkedProfittAndLossIds;

        let prepareJson = {
            Name: controls['Name'].value,
            //Code: controls['Code'].value,
            PLCode: controls['PLCode'].value,
            //InventoryCode: controls['InventoryCode'].value,
            FBType: controls['FBType'].value,
            IsExportable: controls['IsExportable'].value,
            IsImport: controls['IsImport'].value,
            IsDefault: controls['IsDefault'].value,
            LinkedProfittAndLossIds: this.content.LinkedProfittAndLossIds
        };
        if (this.editMode) {
            prepareJson['Id'] = this.content.Id;
        }
        return prepareJson;
    }

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.contentForm.controls;
        /** check form */
        if (this.contentForm.invalid) {
            Object.keys(controls).forEach(controlName => {
                controls[controlName].markAsTouched();
                console.log(controlName);
            });
            this.hasFormErrors = true;
            return;
        }

        const editedItem = this.prepareItem();
        console.log('prepareItem', editedItem);
        if (this.editMode) {
            this.updateEntityItem(editedItem);
        } else {
            this.createEntityItem(editedItem);
        }
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    updateEntityItem(item: any) {
        this.anagraficheService.putEntity('GoodsCategory', item).subscribe(
            (ret: any) => {
                console.log('updateEntityItem', ret);
                this.dialogRef.close({ item, isEdit: true });
            }, (error: any) => {
                this.dialogRef.close(null);
            }
        )
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    createEntityItem(item: any) {
        this.anagraficheService.postEntity('GoodsCategory', item).subscribe(
            (ret: any) => {
                console.log('createEntityItem', ret);
                this.dialogRef.close({ item: ret, isEdit: false });
            }, (error: any) => {
                this.dialogRef.close(null);
            }
        )
    }

    /************************************************************************************************************************
 
$$$$$$$$\ $$$$$$$\  $$$$$$$$\ $$$$$$$$\       $$\    $$\ $$$$$$\ $$$$$$$$\ $$\      $$\ 
\__$$  __|$$  __$$\ $$  _____|$$  _____|      $$ |   $$ |\_$$  _|$$  _____|$$ | $\  $$ |
   $$ |   $$ |  $$ |$$ |      $$ |            $$ |   $$ |  $$ |  $$ |      $$ |$$$\ $$ |
   $$ |   $$$$$$$  |$$$$$\    $$$$$\          \$$\  $$  |  $$ |  $$$$$\    $$ $$ $$\$$ |
   $$ |   $$  __$$< $$  __|   $$  __|          \$$\$$  /   $$ |  $$  __|   $$$$  _$$$$ |
   $$ |   $$ |  $$ |$$ |      $$ |              \$$$  /    $$ |  $$ |      $$$  / \$$$ |
   $$ |   $$ |  $$ |$$$$$$$$\ $$$$$$$$\          \$  /   $$$$$$\ $$$$$$$$\ $$  /   \$$ |
   \__|   \__|  \__|\________|\________|          \_/    \______|\________|\__/     \__|
 
************************************************************************************************************************/

    createStructure(structure: any, level: number = 0) {
        let node = {};
        const initiallyChecked = this.content.LinkedProfittAndLossIds && this.content.LinkedProfittAndLossIds.filter((item: any) => item.Id == structure.Id).length > 0;
        const key = `${structure.Name}#${structure.Id}#${initiallyChecked}`;

        node[key] = [];
        if (structure.Children && structure.Children.length > 0) {
            structure.Children.forEach(child => {
                node[key].push(this.createStructure(child, level + 1));
            });
            //return Object.values(node[structure.Name]);

            let result = {};
            result[key] = Object.values(node[key]);
            return result;

        } else {
            return key;
        }
    }

    initialize(treeData) {
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        const data = this.buildFileTree(treeData, 0);

        // Notify the change.
        return data;
    }

    getNodeInfos(node: string, infotype: InfoType) {
        const arr = node.split('#');
        let infos: any = {
            name: '',
            id: '',
            initiallyChecked: false,
            percentage: 100
        }
        if (arr.length > 1) {
            infos.name = arr[0];
            infos.id = arr[1];
            infos.initiallyChecked = arr[2] == 'true';
            infos.percentage = 100//arr[3];
        } else {
            infos.name = arr[0];
        }
        return infos[infotype];
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `TodoItemNode`.
     */
    buildFileTree(obj: { [key: string]: any }, level: number): PLNode[] {
        return Object.keys(obj).reduce<PLNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new PLNode();
            node.name = this.getNodeInfos(key, InfoType.name);
            node.id = this.getNodeInfos(key, InfoType.id);
            node.initiallyChecked = this.getNodeInfos(key, InfoType.initiallyChecked);
            node.percentage = this.getNodeInfos(key, InfoType.percentage);

            if (value != null) {
                if (typeof value === 'object') {
                    node.children = this.buildFileTree(value, level + 1);
                } else {
                    node.name = this.getNodeInfos(value, InfoType.name);
                    node.id = this.getNodeInfos(value, InfoType.id);
                    node.initiallyChecked = this.getNodeInfos(value, InfoType.initiallyChecked);
                    node.percentage = this.getNodeInfos(value, InfoType.percentage);
                }
            }

            return accumulator.concat(node);
        }, []);
    }

    hasChild = (_: number, node: PLNode) =>
        !!node.children && node.children.length > 0;

    setParent(data, parent) {
        data.parent = parent;
        if (data.children) {
            data.children.forEach((x: any) => {
                this.setParent(x, data);
            });
        }
    }

    checkAllParents(node) {
        if (node.parent) {
            const descendants = this.treeControl.getDescendants(node.parent);
            node.parent.selected = descendants.every((child: any) => child.selected);
            node.parent.indeterminate = descendants.some((child: any) => child.selected);
            this.checkAllParents(node.parent);
        }
    }

    expandAllParents(node) {
        if (node.parent) {
            this.treeControl.expand(node.parent);
            this.expandAllParents(node.parent);
        }
    }

    checkInitliallyChecked(node) {
        if (node.initiallyChecked) {
            this.todoItemSelectionToggle(true, node);
            this.expandAllParents(node);
        } else if (node.children) {
            node.children.forEach((x: any) => {
                this.checkInitliallyChecked(x);
            });
        }
    }

    updatePercentage(event: any, node: any) {
        const index = this.content.LinkedProfittAndLossIds.findIndex((item: any) => item.Id == node.id);
        this.content.LinkedProfittAndLossIds[index].Percentage = event;
        //this.calcDistribution();
    }

    todoItemSelectionToggle(checked, node) {
        node.selected = checked;
        if (node.children) {
            node.children.forEach((x: any) => {
                this.todoItemSelectionToggle(checked, x);
            });
        } else {
            if (checked) {

                // Verifico che non ci siano altri node selezionati
                this.resetAllChecked();

                // Verifico che non ci siano altri nodi selezionati all'interno dello stesso parent.
                //if (this.content.LinkedProfittAndLossIds && this.content.LinkedProfittAndLossIds.length > 0) {
                //    node.parent.children.forEach(child => {
                //        child.selected = child.id == node.id;
                //        this.content.LinkedProfittAndLossIds = this.content.LinkedProfittAndLossIds.filter(content => content.Id != child.id);
                //    });
                //}                

                node.selected = checked;

                this.content.LinkedProfittAndLossIds = [];
                this.content.LinkedProfittAndLossIds.push({
                    Id: node.id,
                    Name: node.name,
                    Percentage: 100//node.percentage ? node.percentage : 100
                });

                //if (!this.content.LinkedProfittAndLossIds || !this.content.LinkedProfittAndLossIds.find((item: any) => item.Id == node.id)) {
                //    if (!this.content.LinkedProfittAndLossIds) {
                //        this.content.LinkedProfittAndLossIds = [];
                //    }
                //    this.content.LinkedProfittAndLossIds.push({
                //        Id: node.id,
                //        Name: node.name,
                //        Percentage: 100//node.percentage ? node.percentage : 100
                //    });
                //}
            } else if (this.content.LinkedProfittAndLossIds) {
                this.content.LinkedProfittAndLossIds = this.content.LinkedProfittAndLossIds.filter((item: any) => item.Id != node.id);
            }
            //this.calcDistribution();
        }
        this.checkAllParents(node);
    }

    resetAllChecked() {
        Object.keys(this.dataSource.data).forEach((x: any) => {
            this.resetNode(this.dataSource.data[x])
        });
        console.log(this.dataSource.data);
    }

    resetNode(node) {
        node.selected = false;
        node.indeterminate = false;
        if (node.children) {
            node.children.forEach((x: any) => {
                this.resetNode(x);
            });
        }
    }

    //totDistribution: number = 0;
    //calcDistribution() {
    //    this.totDistribution = 0;
    //    if (!this.content || !this.content.LinkedProfittAndLossIds || this.content.LinkedProfittAndLossIds.length == 0) return;    
    //    this.content.LinkedProfittAndLossIds.forEach((node: any) => {
    //        this.totDistribution += node.Percentage ? parseFloat(node.Percentage) : 0;
    //    });        
    //}

    scrollToNode(node: any) {
        console.log('scrollToNode', node);
        const nodeEl = $(`kt-fornitori-edit .mat-checkbox-label:contains('${node.Name}')`).closest('.mat-tree-node');
        nodeEl[0].scrollIntoView({behavior: 'smooth'});
    }
}
