// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
// Layout
import { TranslationService } from '@app/core/_base/layout';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
// Charts
//import { Chart } from 'chart.js/dist/chart.min.js';
import Chart from 'chart.js/auto';

import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

import * as util from '@app/core/services/utilityfunctions';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { EditMerciComponent } from '@app/views/pages/gestione-dati/gestione-merci/edit-merci/edit-merci.component';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
  selector: 'kt-multiple-suppliers',
  templateUrl: './multiple-suppliers.component.html',
  styleUrls: ['./multiple-suppliers.component.scss']
})
export class MultipleSuppliersComponent implements OnInit {
  @Input() presetProduct!: string;
  @Input() hideSearch: boolean = false;
  @Output() onClose = new EventEmitter<void>();
  // Public properties
  data: any;


  originalData: any;

  @ViewChild('chart', { static: true }) chart!: ElementRef;
  graphData: any;
  myChart: any;

  columnsList: any;
  locale!: string;
  utility: any;
  showTableResult: boolean = false;
  showMax25Text: boolean = false;
  showAll: boolean = false;
  exportTableRows: any;

  prodotto: any;
  selectedCategory: any;
  selectedGoodId!: string;

  suppliersList: any;

  switchGroupSubcription!: Subscription;

  totMin: number = 0;
  totAvg: number = 0;
  totAvgPerc: number = 0;
  totMax: number = 0;
  totAvgBase: number = 0;
  unitBase: string = '';
  unitMag: string = '';
  absoluteMin: number = 0;
  absoluteMax: number = 0;

  constructor(
    private filtriService: FiltriService,
    private translate: TranslateService,
    private translationService: TranslationService,
    private magazzinoService: MagazzinoService,
    private switchGroupService: SwitchGroupService,
    private excelService: ExcelService,
    private pdfService: PDFService,
    private layoutUtilsService: LayoutUtilsService,
    private ref: ChangeDetectorRef,
    public staticCollectionsService: StaticCollectionsService,
    private gestioneMerciService: GestioneMerciService,
    private dialog: MatDialog,
    private router: Router,
    public loaderService: LoaderService
  ) {
    this.utility = util;
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
      if (this.myChart) {
        this.initChartJS();
      }
    });
  }

  async ngOnInit() {
    await this.staticCollectionsService.fillStaticCollection(false);
    if (this.presetProduct) {
      this.selectedGoodId = this.presetProduct;
      this.updateGraph(this.presetProduct);
    } else {
      this.updateGraph();
    }


    this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
      async (event: any) => {
        if (event.change) {
          await this.staticCollectionsService.fillStaticCollection(true);
          this.selectedCategory = undefined;
          this.selectedGoodId = '';
          this.updateGraph();
        }
      }
    );
  }

  exit() {
    this.onClose.emit();
  }

  async updateGoodsList() {
    this.updateGraph("0", {
      Period: 'YTD',
      CategoryIds: [this.selectedCategory]
    })
  }

  manageNewData(data: any) {
    this.data = JSON.parse(JSON.stringify(data));

    this.refreshGraph();

    if (this.showTableResult) {
      this.showTableResult = false; // Forzatura per farlo andare
      this.prepareShowTableResult();
    }
  }

  showAllData() {
    this.showAll = !this.showAll;
    this.manageNewData(this.originalData);
  }

  prepareShowTableResult() {
    this.showTableResult = !this.showTableResult;
    this.exportTableRows = [];
    if (this.showTableResult) {

      this.data.Items.forEach((row: any) => {

        let record: any = {
          date: row.XLabel
        };

        this.suppliersList.forEach((supplier: any) => {

          const index = row.Items.findIndex((item: any) => item.Supplier.Id == supplier.Id);
          if (index >= 0) {
            record[supplier.Id] = {
              Min: this.utility.formatNumber(row.Items[index].Min, this.locale),
              Avg: this.utility.formatNumber(row.Items[index].Min, this.locale),
              Max: this.utility.formatNumber(row.Items[index].Max, this.locale),
              AvgBase: row.Items[index].UnitBase + ' ' + this.utility.formatNumber(row.Items[index].AvgBase, this.locale, 2),
              UnitMag: row.Items[index].Unit
            }
          } else {
            record[supplier.Id] = {
              Min: '-',
              Avg: '-',
              Max: '-',
              AvgBase: '-',
              UnitMag: '-'
            };
          }
        })
        this.exportTableRows.push(record);;

      });

    }
  }

  refreshGraph() {
    let labels: any = [];
    let suppliers: any = {};

    let elencoDati = JSON.parse(JSON.stringify(this.data));

    this.suppliersList = [];
    elencoDati.Items.forEach((record: any) => {
      record.Items.forEach((item: any) => {
        const alreadyExists = this.suppliersList.find((supplier: any) => supplier.Id == item.Supplier.Id)
        if (!alreadyExists) {
          this.suppliersList.push(item.Supplier)
        }
      })
    });

    // Aggiorno anche quì la columnsList che dipende dai fornitori
    this.columnsList = [
      { label: 'date', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
    ];
    this.suppliersList.forEach((supplier: any) => {
      this.columnsList.push({ label: supplier.Name, enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } })
      this.columnsList.push({ label: supplier.Name + 'Avg', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } })
      this.columnsList.push({ label: supplier.Name + 'Max', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } })
      this.columnsList.push({ label: supplier.Name + 'AvgBase', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } })
      this.columnsList.push({ label: supplier.Name + 'UnitMag', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } })
    });

    elencoDati.Items.forEach((record: any) => {
      labels.push(record.XLabel);

      this.suppliersList.forEach((supplier: any) => {

        if (!suppliers[supplier.Id]) {
          suppliers[supplier.Id] = [];
        }

        const index = record.Items.findIndex((item: any) => item.Supplier.Id == supplier.Id);
        if (index >= 0) {
          suppliers[supplier.Id].push({
            Supplier: supplier,
            Avg: this.utility.parseNumber(record.Items[index].Avg, this.locale, 2),
            Min: this.utility.parseNumber(record.Items[index].Min, this.locale, 2),
            Max: this.utility.parseNumber(record.Items[index].Max, this.locale, 2),
            AvgBase: this.utility.parseNumber(record.Items[index].AvgBase, this.locale, 2),
            Quantity: this.utility.parseNumber(record.Items[index].Quantity, this.locale, 2),
            UnitBase: record.Items[index].UnitBase,
            UnitMag: record.Items[index].Unit
          });
        } else {
          suppliers[supplier.Id].push({
            Supplier: supplier,
            Avg: 0,
            Min: 0,
            Max: 0,
            AvgBase: 0,
            Quantity: 0,
            UnitBase: record.Items[0].UnitBase,
            UnitMag: record.Items[0].Unit
          });
        }

      })

    });

    let datasets: any = [];
    let colorCounter = 0;
    Object.keys(suppliers).forEach((key: any) => {
      const supplierColor = this.utility.generateRaimbow(colorCounter++,);
      const item = suppliers[key];
      datasets.push({
        type: 'line',
        fill: false,
        label: item[0].Supplier.Name,
        backgroundColor: supplierColor,
        borderColor: supplierColor,
        data: item.map((supplier: any) => supplier.Avg),
        supplier: item[0].Supplier,
        cubicInterpolationMode: 'monotone',
        AvgDiff: [],
        Avg: item.map((el: any) => el.Avg),
        Min: item.map((el: any) => el.Min),
        Max: item.map((el: any) => el.Max),
        AvgBase: item.map((el: any) => el.AvgBase),
        Quantity: item.map((el: any) => el.Quantity),
        UnitBase: item[0].UnitBase,
        UnitMag: item[0].UnitMag
      })

    });

    this.graphData = {
      labels: labels,
      datasets: datasets
    };

    this.initChartJS();
  }

  selectNewGood(event: any) {
    let filter: any = { Period: 'YTD' };
    if (this.selectedCategory) {
      filter.CategoryIds = [this.selectedCategory]
    }
    this.updateGraph(event, filter)
  }

  /** Init chart */
  initChartJS() {
    // this.firstLoad = false;
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    if (this.myChart) {
      this.myChart.destroy();
    }

    // Serve per esportazione in PDF per background bianco, altrimenti rimane nero.
    const bgColor = {
      id: 'bgColor',
      beforeDraw: (chart: any, options: any) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    }

    this.myChart = new Chart(this.chart.nativeElement, {
      type: 'line',
      data: this.graphData,
      plugins: [
        bgColor,
        {
          id: 'afterDrawBottom',
          afterDraw: (chart: any) => {
            // I dati che mi servono (MIN MAX AVG) sono ripetuti per tutti i dataset, allora prendo il primo
            const datasets = chart.config.data.datasets.filter((d: any) => !d.hidden);

            if (datasets.length == 0) return;

            console.log('datasets', datasets);

            this.totMin = 0;
            this.totAvg = 0;
            this.totAvgPerc = 0;
            this.totMax = 0;
            this.totAvgBase = 0;
            let totQty = 0, setQty = 0;
            let minAvg = 999999999999, maxAvg = 0;
            this.absoluteMin = 999999999999;
            this.absoluteMax = 0;
            datasets.forEach((set: any) => {
              setQty = set.Quantity.reduce((partial: any, value: any) => partial + value, 0);
              set.Min.forEach((value: number, index: number) => {
                this.totMin += value * set.Quantity[index];
                if (value < this.absoluteMin && value > 0) {
                  this.absoluteMin = value;
                }
              });
              set.Avg.forEach((value: number, index: number) => {
                this.totAvg += value * set.Quantity[index];
                if (value > maxAvg) {
                  maxAvg = value;
                }
                if (value < minAvg && value > 0) {
                  minAvg = value;
                }
              });
              set.Max.forEach((value: number, index: number) => {
                this.totMax += value * set.Quantity[index];
                if (value > this.absoluteMax) {
                  this.absoluteMax = value;
                }
              });
              set.AvgBase.forEach((value: number, index: number) => {
                this.totAvgBase += value * set.Quantity[index];
              });
              totQty += setQty;
            });

            if (this.absoluteMin == 999999999999) this.absoluteMin = 0;

            this.totMin = this.totMin / totQty;
            this.totAvg = this.totAvg / totQty;

            this.totAvgPerc = minAvg > 0 ? (maxAvg - minAvg) / minAvg * 100 : 0;

            this.totMax = this.totMax / totQty;
            this.totAvgBase = this.totAvgBase / totQty;
            this.unitBase = datasets[0].UnitBase;
            this.unitMag = datasets[0].Unit;

            this.ref.detectChanges();

            // Scorro tutti i mesi
            let oldAvg = 0;
            for (let index = 0; index < chart.config.data.datasets[0].data.length; index++) {

              // Calcolo i calori per ogni mese su tutti i datasets
              let Min = 0;
              let Avg = 0;
              let Max = 0;
              let AvgBase = 0;

              let totQuantity = 0;
              datasets.forEach((set: any) => {
                totQuantity += set.Quantity[index];
              });

              datasets.forEach((set: any) => {
                Min += set.Min[index] * set.Quantity[index] / totQuantity;
                Avg += set.Avg[index] * set.Quantity[index] / totQuantity;
                Max += set.Max[index] * set.Quantity[index] / totQuantity;
                AvgBase += set.AvgBase[index] * set.Quantity[index] / totQuantity;
              });

              let AvgDiff = 0;

              if (index > 0 && Avg != oldAvg) {
                AvgDiff = ((Avg - oldAvg) / oldAvg) * 100;
              }

              chart.config.data.datasets.forEach((dataset: any) => {

                dataset.AvgDiff[index] = AvgDiff;

              });

              // Per ogni mese vado a prendere MIN MAX AVG 
              let x = chart.scales['x'].getPixelForTick(index);
              let y = chart.scales['y'].bottom;
              const ctx = this.chart.nativeElement.getContext('2d');

              ctx.fillStyle = "#505050";
              ctx.font = `500 1rem 'Barlow Condensed'`;


              ctx.fillStyle = '#000000';

              let text = datasets[0].UnitMag;
              let textWidth = ctx.measureText(text).width;
              ctx.fillText(`${text}`, x - (textWidth / 2), y + 18);

              text = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.MIN') + ': ' + this.utility.formatNumber(Min, this.locale, 2, true, true);
              textWidth = ctx.measureText(text).width;
              ctx.fillText(`${text}`, x - (textWidth / 2), y + 36);

              text = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.AVG') + ': ' + this.utility.formatNumber(Avg, this.locale, 2, true, true);
              textWidth = ctx.measureText(text).width;
              ctx.fillText(`${text}`, x - (textWidth / 2), y + 54);

              if (AvgDiff != 0) {

                if (AvgDiff > 0) {
                  ctx.fillStyle = "#fd397a";
                } else {
                  ctx.fillStyle = "#a6c719";
                }
                ctx.fillText(this.utility.formatNumber(AvgDiff, this.locale, 2) + '%', x + (textWidth / 2) + 5, y + 54);

                ctx.fillStyle = '#000000';
              }

              oldAvg = Avg;
              text = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.MAX') + ': ' + this.utility.formatNumber(Max, this.locale, 2, true, true);
              textWidth = ctx.measureText(text).width;
              ctx.fillText(`${text}`, x - (textWidth / 2), y + 72);

              text = datasets[0].UnitBase + ': ' + this.utility.formatNumber(AvgBase, this.locale, 2);
              textWidth = ctx.measureText(text).width;
              ctx.fillText(`${text}`, x - (textWidth / 2), y + 90);

            }

          }
        }
      ],
      options: {
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            bodyFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            titleFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            footerFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            //intersect: false,
            //mode: 'nearest',
            padding: 10,
            caretPadding: 10,
            callbacks: {
              beforeTitle: (tooltipItem: any) => {
                return tooltipItem[0].dataset.label;
              },
              title: (tooltipItem: any) => {
                return tooltipItem[0].label
                // return data.labels[tooltipItem[0].index];
              },
              label: (tooltipItem: any) => {

                let avgDiffText = "";
                if (tooltipItem.dataset.AvgDiff[tooltipItem.dataIndex] != 0) {
                  avgDiffText = "(" + this.utility.formatNumber(tooltipItem.dataset.AvgDiff[tooltipItem.dataIndex], this.locale, 2) + '%)';
                }

                var multistringText: any = [];
                const valueText = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.VALUE') + ' ' + this.utility.formatNumber(parseFloat(tooltipItem.raw), this.locale, 2, true, true);
                const minText = `${this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.MIN')}: ${tooltipItem.dataset.Min[tooltipItem.dataIndex]}`;
                const avgText = `${this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.AVG')}: ${tooltipItem.dataset.Avg[tooltipItem.dataIndex]} ${avgDiffText}`;
                const maxText = `${this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.MAX')}: ${tooltipItem.dataset.Max[tooltipItem.dataIndex]}`;
                const unitText = `${tooltipItem.dataset.UnitBase}: ${tooltipItem.dataset.AvgBase[tooltipItem.dataIndex]}`;
                multistringText.push(tooltipItem.dataset.UnitMag);
                multistringText.push(valueText);
                multistringText.push(minText);
                multistringText.push(avgText);
                multistringText.push(maxText);
                multistringText.push(unitText);

                return multistringText;
              }
            }
          },
          legend: {
            labels: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              }
            },
            display: true,
            position: 'bottom',
            onClick: (e: any, legendItem: any) => {
              var index = legendItem.datasetIndex;
              var ci = this.myChart;
              var meta = ci.getDatasetMeta(index);

              // See controller.isDatasetVisible comment
              meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

              this.myChart.config.data.datasets[index].hidden = meta.hidden;

              this.totMin = 0;
              this.totAvg = 0;
              this.totMax = 0;
              this.totAvgBase = 0;

              // We hide a dataset ... rerender the chart
              ci.update();

              this.ref.detectChanges();
            }
          },
        }
        ,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            grid: {
              display: false
            },
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: 95,
            },
          },
          y: {
            display: true,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: 10,
              callback: (value: any, index: any, values: any) => {
                if (parseFloat(value) > 0)
                  return this.utility.kFormatter(value, this.locale, true, true);
              },
            },
            title: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('ANALISI_PREZZI_FORNITORI.ASSE_Y') + ' [ € ]'
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });

    this.ref.detectChanges();
  }

  printChart() {
    this.myChart.update();
    setTimeout(() => {
      var canvas = <HTMLCanvasElement>document.getElementById('ppfgraph');

      const merce = this.data.Goods.find((good: any) => good.Id == this.selectedGoodId);

      this.pdfService.chartToPdf(canvas, this.translate.instant('ANALISI_PREZZI_FORNITORI.TITLE') + ': ' + merce?.Name);
    }, 100);
  }

  updateGraph(ProductId: string = "0", filter: any = { Period: 'YTD' }) {
    this.loaderService.show();
    if (filter) {
      // Serve per quando si cambia la categoria,
      // cancella il componenente delle merci
      // per poi ricrearlo con la lista aggiornata dei prodotti della categoria
      if (ProductId == '0') {
        this.data = undefined;
      }
      this.magazzinoService.getWarehousePricesGraph(filter, ProductId).toPromise()
        .then(async (ret: any) => {
          console.log('getWarehousePricesGraph', ret);
          this.data = ret;

          if (!this.data || this.data.Items.length == 0) return;

          const filter: any = {
            FBType: 6,
            EnableState: 'yes'
          };

          if (this.selectedCategory) {
            filter['CategoryIds'] = [this.selectedCategory];
          }

          // Solo quando cambio la categoria forzo il selectedGoodId
          if (ProductId == '0') {
            setTimeout(() => {
              this.selectedGoodId = this.data.Good.Id;
              this.ref.detectChanges();
            }, 500);
          }

          this.originalData = JSON.parse(JSON.stringify(this.data));
          this.manageNewData(this.data);

        })
        .finally(() => this.loaderService.hide());
    } else {
      alert('Missing date filter');
    }

  }

  /******************************************************************************
    ______                       _    __   ___       _______   __
   |  ____|                     | |   \ \ / / |     / ____\ \ / /
   | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
   |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
   | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
   |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
       | |
       |_|
  ******************************************************************************/

  exportAsXLSX() {
    this.loaderService.show();

    let xlsx: any[] = [];
    let merges: any[] = [];
    let i: number = 0;

    let rowsStyles: any = [];

    // FACCIO PRIMA IL BODY
    let xlsxBody: any[] = [];
    this.data.Items.forEach((row: any) => {
      rowsStyles.push({
        sz: 10
      });

      let columns: any = {};

      columns[`${this.translate.instant('ANALISI_PREZZI_FORNITORI.XLS_COLUMNS.DATE')}`] = row.XLabel;

      this.suppliersList.forEach((supplier: any) => {
        let cellValue = '-';
        const index = row.Items.findIndex((item: any) => item.Supplier.Id == supplier.Id);
        if (index >= 0) {
          columns[supplier.Name] = this.utility.formatNumber(row.Items[index].Min, this.locale);
          columns[supplier.Name + 'Avg'] = this.utility.formatNumber(row.Items[index].Avg, this.locale);
          columns[supplier.Name + 'Max'] = this.utility.formatNumber(row.Items[index].Max, this.locale);
          columns[supplier.Name + 'AvgBase'] = row.Items[index].UnitBase + ': ' + this.utility.formatNumber(row.Items[index].AvgBase, this.locale);
          columns[supplier.Name + 'UnitMag'] = row.Items[index].Unit;
        } else {
          columns[supplier.Name] = '-';
          columns[supplier.Name + 'Avg'] = '-';
          columns[supplier.Name + 'Max'] = '-';
          columns[supplier.Name + 'AvgBase'] = '-';
          columns[supplier.Name + 'UnitMag'] = '-';
        }
      });


      xlsxBody.push(columns);
      i++;
    });

    // POI FACCIO la seconda riga di intestazione
    let columns: any = {};

    columns[`${this.translate.instant('ANALISI_PREZZI_FORNITORI.XLS_COLUMNS.DATE')}`] = this.translate.instant('ANALISI_PREZZI_FORNITORI.XLS_COLUMNS.DATE');
    this.suppliersList.forEach((supplier: any, index: number) => {
      columns[supplier.Name] = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.MIN');
      columns[supplier.Name + 'Avg'] = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.AVG');
      columns[supplier.Name + 'Max'] = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.MAX');
      columns[supplier.Name + 'AvgBase'] = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.AVGBASE');
      columns[supplier.Name + 'UnitMag'] = this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.UNIT_MAG');
      // Aggiungo anche il merge sulla prima riga
      merges.push({ s: { c: (index * 5) + 1, r: 0 }, e: { c: (index * 5) + 5, r: 0 } })
    });
    xlsx.push(columns);

    xlsx = xlsx.concat(xlsxBody);

    this.loaderService.hide();

    // p.s. nella prima colonna di ogni fornitore non metto la dicitura supplier.Name + 'Min' ma solo supplier.Name
    // perchè altrimenti facendo il merce delle colonne come intestazione vedo SUPPLIERNAMEMin con il min tra le palle.
    this.excelService.exportAsExcelFile(xlsx, this.translate.instant('ANALISI_PREZZI_FORNITORI.EXPORT_TITLE'), merges, this.columnsList, 2, rowsStyles, true);
  }

  /******************************************************************************
    ______                       _     _____  _____  ______
   |  ____|                     | |   |  __ \|  __ \|  ____|
   | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
   |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
   | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
   |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
         | |
         |_|
  ******************************************************************************/

  exportAsPDF() {

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
    };

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result['header'] = {
          export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.EXPORT_TITLE') }),
          period: this.filtriService.getCurrentPeriod()
        }
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        }
        result['language'] = this.translationService.getSelectedLanguage();
        result['table'].headerRows = 2;
        result['orientation'] = 'landscape';
        let columnsTot = this.columnsList.filter((item: any) => item.enabled).length;

        let pdfTableBody = this.getPDFTableBody();

        this.pdfService.makePdf(result, pdfTableBody, Array(columnsTot).fill('auto'));
      };
    });

  }

  getPDFTableBody() {

    let body: any = [];
    let tmpRow: any = [];

    // aggiungo intestazione
    // Header
    tmpRow = [
      { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
    ];

    this.suppliersList.forEach((supplier: any) => {
      tmpRow.push({ colSpan: 5, visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${supplier.Name}`, style: 'tableHeaderStyle' })
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${supplier.Name}`, style: 'tableHeaderStyle' })
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${supplier.Name}`, style: 'tableHeaderStyle' })
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${supplier.Name}`, style: 'tableHeaderStyle' })
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${supplier.Name}`, style: 'tableHeaderStyle' })
    });

    body.push(tmpRow.filter((item: any) => item.visible));

    // Sub
    tmpRow = [
      { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_PREZZI_FORNITORI.XLS_COLUMNS.DATE')}`, style: 'tableHeaderStyle' },
    ];

    this.suppliersList.forEach((supplier: any) => {
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.MIN')}`, style: 'tableHeaderStyle' })
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.AVG')}`, style: 'tableHeaderStyle' })
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.MAX')}`, style: 'tableHeaderStyle' })
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.AVGBASE')}`, style: 'tableHeaderStyle' })
      tmpRow.push({ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_PREZZI_FORNITORI.TOOLTIP.UNIT_MAG')}`, style: 'tableHeaderStyle' })
    });

    body.push(tmpRow.filter((item: any) => item.visible));


    let i: number = 0;
    let totFc: number = 0;
    let bodyTmp: any = [];
    this.data.Items.forEach((item: any) => {

      tmpRow = [
        { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.XLabel, style: 'tableBodyStyle' },
      ];

      this.suppliersList.forEach((supplier: any) => {
        tmpRow.push({ visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: `${this.utility.formatNumber(item.Min, this.locale)}`, style: 'tableBodyStyle' })
        tmpRow.push({ visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: `${this.utility.formatNumber(item.Avg, this.locale)}`, style: 'tableBodyStyle' })
        tmpRow.push({ visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: `${this.utility.formatNumber(item.Max, this.locale)}`, style: 'tableBodyStyle' })
        tmpRow.push({ visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: `${item.UnitBase}: ${this.utility.formatNumber(item.AvgBase, this.locale)}`, style: 'tableBodyStyle' })
        tmpRow.push({ visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: `${item.Unit}`, style: 'tableBodyStyle' })
      });

      bodyTmp.push(tmpRow.filter((item: any) => item.visible));
      i++;

    });

    body = body.concat(bodyTmp);

    return body;
  }


  /*************************************** */
  async showMerce() {
    const merce = await this.gestioneMerciService.getSingleGoods(this.selectedGoodId).toPromise();

    this.dialog
      .open(EditMerciComponent, {
        data: {
          merce: merce,
          readOnly: true
        },
        width: '100%',
        height: '100%'
      });
  }

  redirectToPurchases() {
    const filter: any = {
      Period: "YTD",
      ProductOrCode: '#' + this.data.Good.Code + '#',
      ShowDisabled: true,
      ShowInvisible: true,
    }
    localStorage.setItem('currentFilterCfg', JSON.stringify(filter))
    this.router.navigate(['/magazzino/acquisti']);

    if (this.hideSearch) {
      this.onClose.emit();
    }

  }
}
