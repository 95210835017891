import { Component, Input, OnInit } from '@angular/core';
import * as util from '@app/core/services/utilityfunctions';
import { environment } from '@env/environment';

@Component({
    selector: 'kt-mb-spinner',
    templateUrl: './mb-spinner.component.html',
    styleUrls: ['./mb-spinner.component.scss']
})
export class MbSpinnerComponent implements OnInit {

    @Input() gif: boolean = false;
    @Input() position: string = 'fixed'
    utility: any = util;
    env: any = environment;

    constructor() { }

    ngOnInit(): void {
    }

}
