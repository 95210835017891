import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from '@sheet/core';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import xml2js from 'xml2js';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ExcelService {

    constructor(private _http: HttpClient) { }

    numToAlpha(num: any) {
        var alpha = '';

        for (; num >= 0; num = parseInt((num / 26).toString()) - 1) {
            alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
        }

        return alpha[0];
    }

    public singleSheet(json: any[], merges: any = [], columnsInfo: any = [], headerRows: number = 1, rowsStyles: any = [], alternateRows: boolean = false, origin: any = 'A1')/*: XLSX.WorkSheet*/ {
        let objectMaxLength: any = [];
        for (let i = 0; i < json.length; i++) {
            let value = <any>Object.values(json[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] =
                        value[j] && objectMaxLength[j] && objectMaxLength[j] >= value[j].length
                            ? objectMaxLength[j]
                            : (value[j] ? value[j].length * 0.7 : 0);
                }
            }
            let key = <any>Object.keys(json[i]);
            for (let j = 0; j < key.length; j++) {
                objectMaxLength[j] =
                    objectMaxLength[j] >= key[j].length
                        ? objectMaxLength[j]
                        : key[j].length;
            }
        }
        let wscols: any = []
        let index: number = 0;
        objectMaxLength.forEach(col => {
            wscols.push({
                width: columnsInfo && columnsInfo.length > 0 && columnsInfo[index] && columnsInfo[index].enabled ? (columnsInfo && columnsInfo[index] && columnsInfo[index].width ? columnsInfo[index].width : col) : col,
                hidden: columnsInfo && columnsInfo.length > 0 && columnsInfo[index] && !columnsInfo[index].enabled
            });
            index++;
        });

        let worksheet: any = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_json(worksheet, json, { origin: origin });

        worksheet["!merges"] = merges;
        worksheet["!cols"] = wscols;

        var range = XLSX.utils.decode_range(worksheet['!ref']);
        if (alternateRows) {
            const limiteSuperiore = this.numToAlpha(range.e.c);
            const limiteSuperioreAlpha = `${limiteSuperiore}${range.e.r}`;
            worksheet['!condfmt'] = [
                {
                    ref: `A2:${limiteSuperioreAlpha}`, // change to the worksheet range
                    t: "formula",
                    f: "MOD(ROW(),2)=1", // no initial =
                    s: { bgColor: { rgb: 'ECECEC' } } // light gray background
                }
            ];
        }

        XLSX.SSF.setlocale("de-DE"); // Per avere formattazione come ita, sono uguali

        for (var i = range.s.r + 1; i <= range.e.r; ++i) {

            for (var colNum = range.s.c; colNum <= range.e.c; colNum++) {

                var ref = XLSX.utils.encode_cell({ r: i, c: colNum });
                if (!worksheet[ref]) continue;

                // Di default metto l'allineamento a sinistra.
                worksheet[ref].s = {
                    alignment: {
                        horizontal: 'left'
                    }
                };

                // Gli stili sulla singola colonna prevalgono sulla riga
                if (columnsInfo && columnsInfo.length > 0 && columnsInfo[colNum]) {
                    // worksheet[ref].s = columnsInfo[colNum].style;

                    // Così non sovrascrivo le impostazioni precedenti della riga
                    if (columnsInfo[colNum].style) {
                        Object.keys(columnsInfo[colNum].style).forEach(key => {
                            worksheet[ref].s[key] = columnsInfo[colNum].style[key];
                        });
                    }

                    if (columnsInfo[colNum].format) {
                        worksheet[ref].z = XLSX.SSF.normalize(columnsInfo[colNum].format);
                    } else {
                        worksheet[ref].z = '@'; // preserva gli zeri davanti
                    }
                }

                // Imposto lo stile della riga, ha priorità rispetto alla colonna
                // tranne l'allineamento che non lo considero
                if (rowsStyles[i]) {
                    // worksheet[ref].s = rowsStyles[i];
                    // Così non sovrascrivo le impostazioni precedenti della riga
                    Object.keys(rowsStyles[i]).forEach(key => {
                        if (key !== 'alignment') {
                            worksheet[ref].s[key] = rowsStyles[i][key];
                        }
                    });
                }
            }
        }

        this.colorHeader(worksheet, headerRows);

        return worksheet;
    }

    public exportAsExcelFile(json: any[], excelFileName: string, merges: any = [], columnsInfo: any = [], headerRows: number = 1, rowsStyles: any = [], alternateRows: boolean = false): void {
        const worksheet = this.singleSheet(json, merges, columnsInfo, headerRows, rowsStyles, alternateRows);

        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: [`data`] };
        const excelBuffer: any = XLSX.write(workbook, { bookSST: true, bookType: 'xlsx', type: 'array', cellStyles: true });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    public exportInventoryLayout(json: any[], excelFileName: string, merges: any = [], columnsInfo: any = [], headerRows: number = 1, rowsStyles: any = [], alternateRows: boolean = false, sheetName: string, note: string = '', print: any = undefined, blockedGoods: any = undefined): void {
        // Imposto il parametro ORGINI come A2, così inizia a scrivere dalla riga 2 in avanti, e io alla riga A1 inserisco la nota.

        const worksheet: any = this.singleSheet(json, merges, columnsInfo, headerRows, rowsStyles, alternateRows, 'A2');

        XLSX.utils.sheet_add_aoa(worksheet, [
            ["", note]
        ], { origin: "A1" });

        // Imposto lo stile alla nota, uguale all'header, inizia dalla colonna 1, perchè la 0 è nascosta.
        worksheet[XLSX.utils.encode_cell({ c: 1, r: 0 })]['s'] = {
            fgColor: { rgb: 'b7b7b7' },
            bold: true,
            sz: 11,
            name: 'Calibri',
            alignment: {
                horizontal: 'center'
            }
        }

        const isLayoutType2 = columnsInfo.length > 6;

        const endMerges = isLayoutType2 ? 7 : 5

        worksheet['!merges'] = [{ s: { r: 0, c: 1 }, e: { r: 0, c: endMerges } }];
        // Se volessi tenere una colonna fissa e le altre che scorrono
        //worksheet["!freeze"] = "B3";


        const workbook: XLSX.WorkBook = XLSX.utils.book_new();

        const range: any = XLSX.utils.decode_range(worksheet['!ref']);

        /* enable worksheet protection */
        worksheet["!protect"] = {
            password: '@AdRstSe1964!'
        };

        //file:///Users/andreadompe/Repositories/MyBusiness/node_modules/@sheet/core/README.html?#user-content-print-options
        if (print) {
            worksheet["!print"] = print;
        }

        /* enable editing for cells E3:E... */
        const startEnabled1 = `E3`;
        const endEnabled1 = `E${range.e.r + 1}`;
        XLSX.utils.sheet_set_range_style(worksheet, `${startEnabled1}:${endEnabled1}`, { editable: true, fgColor: { rgb: 'ffdd45' } });
        // Se layout tipo 2
        if (isLayoutType2) {
            const startEnabled2 = `G3`;
            const endEnabled2 = `G${range.e.r + 1}`;
            XLSX.utils.sheet_set_range_style(worksheet, `${startEnabled2}:${endEnabled2}`, { editable: true, fgColor: { rgb: 'ffdd45' } });
        }

        // Check per celle con unità di misura bloccate
        if(blockedGoods){
        blockedGoods.forEach((blockedGood: any) => {
            if(blockedGood.Lock_Inventory_UnitBase) {
                XLSX.utils.sheet_set_range_style(worksheet, `E${blockedGood.index+3}:F${blockedGood.index+3}`, { editable: false, fgColor: { rgb: 'f2f3f8' } });
            }
            if (isLayoutType2 && blockedGood.Lock_Inventory_Unit) {
                XLSX.utils.sheet_set_range_style(worksheet, `G${blockedGood.index+3}:H${blockedGood.index+3}`, { editable: false, fgColor: { rgb: 'f2f3f8' } });
            }
        });
    }

        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

        const excelBuffer: any = XLSX.write(workbook, { bookSST: true, bookType: 'xlsx', type: 'array', cellStyles: true });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    public exportAsMultiSheetsFile(sheets: any[], excelFileName: string, merges: any = [], columnsInfo: any = [], headerRows: number = 1, rowsStyles: any = [], alternateRows: boolean = false): void {
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();

        sheets.forEach((sheet: any) => {
            if (sheet.Rows.length == 0) return;
            let worksheet: any = this.singleSheet(sheet.Rows, merges, columnsInfo, headerRows, rowsStyles, alternateRows);

            const range: any = XLSX.utils.decode_range(worksheet['!ref']);
            const startDisabled1 = `A1`;
            const endDisabled1 = `C${range.e.r}`;
            const startDisabled2 = `E1`;
            const endDisabled2 = `E${range.e.r}`;
            const startDisabled3 = `G1`;
            const endDisabled3 = `G${range.e.r}`;

            // Per l'intestazione
            const startDisabled4 = `A1`;
            const endDisabled4 = `G1`;

            worksheet["!protect"] = {
                password: '@AdRstSe1964!'
            }; /* enable worksheet protection */

            /* hide formulae for cells A1:B1 */
            XLSX.utils.sheet_set_range_style(worksheet, `${startDisabled1}:${endDisabled1}`, { editable: false });
            XLSX.utils.sheet_set_range_style(worksheet, `${startDisabled2}:${endDisabled2}`, { editable: false });
            XLSX.utils.sheet_set_range_style(worksheet, `${startDisabled3}:${endDisabled3}`, { editable: false });
            XLSX.utils.sheet_set_range_style(worksheet, `${startDisabled4}:${endDisabled4}`, { editable: false });

            /* enable editing for cells A1:A2 */
            const startEnabled1 = `D2`;
            const endEnabled1 = `D${range.e.r}`;
            const startEnabled2 = `F2`;
            const endEnabled2 = `F${range.e.r}`;
            XLSX.utils.sheet_set_range_style(worksheet, `${startEnabled1}:${endEnabled1}`, { editable: true, fgColor: { rgb: 'ffdd45' } });
            XLSX.utils.sheet_set_range_style(worksheet, `${startEnabled2}:${endEnabled2}`, { editable: true, fgColor: { rgb: 'ffdd45' } });

            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.Name);
        });

        const excelBuffer: any = XLSX.write(workbook, { bookSST: true, bookType: 'xlsx', type: 'array', cellStyles: true });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    private colorHeader(sheet, headerRows) {
        var range = XLSX.utils.decode_range(sheet['!ref']);
        for (let R = 0; R < headerRows; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
                if (cell) {
                    sheet[XLSX.utils.encode_cell({ c: C, r: R })]['s'] = {
                        fgColor: { rgb: 'b7b7b7' },
                        bold: true,
                        sz: 11,
                        name: 'Calibri'
                    }
                }
            }
        }
        return sheet;
    }

    async readFile(file: File, sheetName: string = '', rowToSkip: number = 0) {
        return new Promise<any>((resolve, reject) => {

            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e: any) => {
                var data = new Uint8Array(e.target.result);
                try {

                    //XLSX.SSF.setlocale("it-IT");
                    var workbook = XLSX.read(data, { type: 'array' });

                    //http://mantis.fbmanager.com/view.php?id=807
                    const sheetIndex = 0; //workbook.SheetNames.findIndex((sheet: any) => sheet.toLowerCase() == sheetName.toLowerCase());
                    const worksheet: any = workbook.Sheets[workbook.SheetNames[sheetIndex]];

                    // Siccome ho messo come prima riga una nota,
                    // la rimuovo altrimenti fa casino con la conversione

                    const range: any = XLSX.utils.decode_range(worksheet['!ref']);
                    range.s.r = rowToSkip; // <-- zero-indexed, so setting to 1 will skip row 0
                    worksheet['!ref'] = XLSX.utils.encode_range(range);

                    let json = XLSX.utils.sheet_to_json(worksheet);
                    resolve(json);
                } catch (error) {
                    console.log(error);
                    resolve(false);
                }
            };

        });
    }

    parseXML(data) {
        return new Promise(resolve => {
            var k: string | number,
                arr: any = [],
                parser = new xml2js.Parser(
                    {
                        trim: true,
                        explicitArray: true
                    });
            parser.parseString(data, function (err, result) {
                var obj = result.Employee;
                for (k in obj.emp) {
                    var item = obj.emp[k];
                    arr.push({
                        id: item.id[0],
                        name: item.name[0],
                        gender: item.gender[0],
                        mobile: item.mobile[0]
                    });
                }
                resolve(arr);
            });
        });
    }

    async getNewStyle() {
        const data = await this._http.get('/assets/newStyle.xml',
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'text/xml')
                    .append('Access-Control-Allow-Methods', 'GET')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
                responseType: 'text'
            }).toPromise();

        return data;
    }
}
