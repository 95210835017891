import { Component, ChangeDetectorRef, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import * as util from '@app/core/services/utilityfunctions';
import { BehaviorSubject } from 'rxjs';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

declare var $: any;

@Component({
	selector: 'kt-popup-dettaglio',
	templateUrl: './popup-dettaglio.component.html',
	styleUrls: ['./popup-dettaglio.component.scss']
})
export class PopupDettaglioComponent implements OnInit {

	viewLoading: boolean = false;
	utility: any;
	locale!: string;
	totalGoodsQuantity: number = 0;
	totalQuantity: number = 0;
	table: any;
	columnsList: any;
	hoverglassActive: boolean = false;
	

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private ref: ChangeDetectorRef,
		public dialogRef: MatDialogRef<any>,
		private translate: TranslateService,
		private translationService: TranslationService,
		private excelService: ExcelService,
		private pdfService: PDFService,
        public loaderService: LoaderService
	) {

		this.utility = util;

		this.translationService.performSwitchLanguage.subscribe((lang) => {
			this.locale = lang;
		});

		this.columnsList = [
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.CODICE'), enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.CATEGORIA'), enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.PRODOTTO'), enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.QTA_VENDUTA'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.UNITA'), enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.QTA_MERCE'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.QTA_RICETTA'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.UNITA'), enabled: true, style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
			{ label: this.translate.instant('ANALISI_CONSUMI.CONSUMI.COLUMNS.PERCENT'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true }
		];

	}

	ngOnInit() {
		// In This.data c'è quello che gli hai passato
		console.log(this.data);
		this.data.Details.forEach((element: any) => {
			this.totalGoodsQuantity += element.GoodsQuantity;
			this.totalQuantity += element.Quantity;
		});
		this.initDataTable();
	}

	initDataTable() {
		//this.hoverglassActive = true;

		setTimeout(() => {
			console.log('initDataTable');
			let dtOptions: any = {
				initComplete: () => {
					this.ref.detectChanges();
				},
				destroy: true,
				language: {
					emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
					zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
				},
				paging: false,
				searching: false,
				ordering: true,
				orderCellsTop: true,
				scrollY: '350px',
				//scrollX: false,
				scrollCollapse: true,
				columnDefs: [
					{ targets: [0], width:'100px', className:'max-width-100' },
                    { targets: [1], width:'100px', className:'max-width-150' },
                    { targets: [2], className:'max-width-250 min-width-200' },
                    { targets: [3], width:'60px', className:'max-width-60' },
                    { targets: [4], width:'60px', className:'max-width-60' },
                    { targets: [5], width:'60px', className:'max-width-60' },
                    { targets: [6], width:'60px', className:'max-width-60' },
                    { targets: [7], width:'60px', className:'max-width-60' },
                    { targets: [8], width:'60px', className:'max-width-60' },
				],
				autoWidth: false
			};
			this.table = $('#tabledett').DataTable(dtOptions);
			this.table.columns.adjust().draw();
            // Mi collego all'evento di loading della tabella
            this.table.off('processing.dt');
            this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
                if (processing) {
                    this.loaderService.show();
                } else {
                    this.loaderService.hide();
                }
                this.ref.detectChanges();
            });

		}, 100);

	}




	cancel() {
		this.dialogRef.close(null);
	}

	ok(item: any) {
		this.dialogRef.close({
			return: 'OGGETTO DI RITORNO'
		});
	}

	exportAsXLSX() {
		this.loaderService.show();
		let xlsx: any[] = [];
		let merges: any[] = [];
		let i: number = 0;
		let sortedList = this.data.Details;
		sortedList.forEach((item: any) => {
			//this.consumptionListFiltered.forEach((item: any, index: number) => {

			let columns: any = {};


			this.columnsList.forEach((element: any, index: number) => {
				if (index === 0 && element.enabled) {
					columns[`${element.label}`] = item.Code ? item.Code.toUpperCase() : '';
				}
				if (index === 1 && element.enabled) {
					columns[`${element.label}`] = item.Category ? item.Category.toUpperCase() : '';
				}
				if (index === 2 && element.enabled) {
					columns[`${element.label}`] = item.Product ? item.Product.toUpperCase() : '';;
				}
				if (index === 3 && element.enabled) {
					columns[`${element.label}`] = item.Quantity ? this.utility.formatNumber(item.Quantity, this.locale) : '';
				}
				if (index === 4 && element.enabled) {
					columns[`${element.label}`] = item.Unit ? item.Unit : '';
				}
				if (index === 5 && element.enabled) {
					columns[`${element.label}`] = item.GoodsQuantity ? this.utility.formatNumber(item.GoodsQuantity, this.locale) : '';
				}
				if (index === 6 && element.enabled) {
					columns[`${element.label}`] = item.GodsQuantityInRecipe ? this.utility.formatNumber(item.GodsQuantityInRecipe, this.locale) : '';
				}
				if (index === 7 && element.enabled) {
					columns[`${element.label}`] = item.GoodsUnit ? item.GoodsUnit : '';;
				}
				if (index === 8 && element.enabled) {
					columns[`${element.label}`] = item.Percent ? this.utility.formatNumber(item.Percent, this.locale) : '';
				}

			});

			xlsx.push(columns);
			i++;
		});
		this.loaderService.hide();
		this.excelService.exportAsExcelFile(xlsx, 'detail', merges, this.columnsList, 1, [], true);
	}

	exportAsPDF() {

	}

}
