import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { TranslationService } from '@app/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

interface Week {
    start: moment.Moment;
    end: moment.Moment;
    year: number;
    weekNumber: number;
}

interface WeekSelection {
    startDate: moment.Moment;
    endDate: moment.Moment;
}

interface SpecificDate {
    list: string[];
}

@Component({
    selector: 'mb-daterangepicker',
    templateUrl: './mb-daterangepicker.component.html',
    styleUrls: ['./mb-daterangepicker.component.scss'],
})
export class MbDaterangepickerComponent implements OnInit {

    @ViewChild('datePicker') datePicker!: ElementRef;

    private pickerDirective!: DaterangepickerDirective;
    @ViewChild(DaterangepickerDirective, { static: false }) set _serPickerDirective(content: DaterangepickerDirective) {
        if (content) { // initially setter gets called with undefined
            this.pickerDirective = content;
            this.pickerDirective.picker.rightCalendar.month = moment();
            this.pickerDirective.picker.leftCalendar.month = moment().subtract(1, 'month');
            this.pickerDirective.drops = 'right';
            this.pickerDirective.picker.updateView();
        }
    }

    private specificDatesCalendar!: MatCalendar<Date>;
    @ViewChild('calendar', { static: false }) set _setSpecificDatesCalendar(content: MatCalendar<Date>) {
        if (content) { // initially setter gets called with undefined
            this.specificDatesCalendar = content;
        }
    }

    @Output() selectedDates = new EventEmitter<string>();
    @Output() selectedCustomDates = new EventEmitter<any>();
    @Output() selectedMonths = new EventEmitter<void>();
    @Output() selectedWeeks = new EventEmitter<WeekSelection>();
    @Output() selectedSpecificDates = new EventEmitter<SpecificDate>();
    @Input() parameters: any = {
        inputMode: true,
        showCustomRanges: true,
        showCustomRangeLabel: true,
        showMonthsSelection: false,
        showWeeksSelection: false,
        showSpecifiCates: false,
        opens: 'right',
        drops: 'down',
        singleDatePicker: false
    };

    @Input() selected: any;

    ranges: any = [];
    locale: any = {
        customRangeLabel: this.translate.instant('FILTRI.SELECT_DATA_RANGE'),
        firstDay: 1
    }

    changeLanguageNeeded: boolean = true;

    ONLYYESTERDAY: string = this.translate.instant('FILTRI.PERIOD_TYPE.ONLYYESTERDAY');
    YESTERDAY: string = this.translate.instant('FILTRI.PERIOD_TYPE.YESTERDAY');
    DAY: string = this.translate.instant('FILTRI.PERIOD_TYPE.DAY');
    PREVIOUS_YEAR: string = this.translate.instant('FILTRI.PERIOD_TYPE.PREVIOUS_YEAR');
    CURRENT_YEAR: string = this.translate.instant('FILTRI.PERIOD_TYPE.CURRENT_YEAR');
    YTD: string = this.translate.instant('FILTRI.PERIOD_TYPE.YTD');
    MTD: string = this.translate.instant('FILTRI.PERIOD_TYPE.MTD');
    YEAR: string = this.translate.instant('FILTRI.PERIOD_TYPE.YEAR');
    MONTH: string = this.translate.instant('FILTRI.PERIOD_TYPE.MONTH');
    WEEK: string = this.translate.instant('FILTRI.PERIOD_TYPE.WEEK');

    weeks: Week[] = [];
    firstClick: moment.Moment | null = null;

    constructor(
        private translate: TranslateService,
        private translationService: TranslationService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.changeLanguageNeeded = false;
            moment.locale(lang);
            this.locale.monthNames = moment.monthsShort();
            this.locale.daysOfWeek = moment.weekdaysShort();

            setTimeout(() => {
                this.changeLanguageNeeded = true;
                this.changeDetectorRef.detectChanges();
            }, 100);

        });

    }

    ngOnInit(): void {
        if (this.parameters && this.parameters.showCustomRanges) {

            this.ranges = {
                [this.ONLYYESTERDAY]: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                [this.YESTERDAY]: [moment().subtract(1, 'days').startOf('day'), moment().endOf('day')],
                [this.DAY]: [moment().startOf('day'), moment().endOf('day')],
                [this.PREVIOUS_YEAR]: [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')],
                [this.CURRENT_YEAR]: [moment().startOf('year'), moment().endOf('year')],
                [this.YTD]: [moment().subtract(1, 'years').startOf('day'), moment().endOf('day')],
                [this.MTD]: [moment().subtract(1, 'months').startOf('day'), moment().endOf('day')],
                [this.YEAR]: [moment().startOf('year'), moment().endOf('day')],
                [this.MONTH]: [moment().startOf('month'), moment().endOf('day')],
                [this.WEEK]: [moment().startOf('week'), moment().endOf('day')],
            }

            if (this.parameters.showMonthsSelection) {
                setTimeout(() => {
                    const MONTH_RANBGE = this.translate.instant('FILTRI.PERFORM_MONTH_RANGE');
                    const selezioneMesi = `<li class="selectMonths"><button type="button">${MONTH_RANBGE}</button></li>`

                    // Aggiungo la selezioneMesi dopo il terzo elemento della lista ranges li
                    $('.ranges ul li:nth-child(3)').after(selezioneMesi);

                    $('.ranges ul li.selectMonths').on('click', 'button', () => {
                        this.selectedMonths.emit();
                    });
                }, 1000);
            }

            if (this.parameters.showWeeksSelection) {
                setTimeout(() => {
                    const WEEK_RANGE = this.translate.instant('FILTRI.PERFORM_WEEK_RANGE');
                    $('.ranges ul li:nth-child(4)').after(`<li class="selectWeeks"><button type="button">${WEEK_RANGE}</button></li>`);

                    $('.ranges ul li.selectWeeks').on('click', 'button', () => {
                        this.restoreCalendars();
                        // Verifico che non ci sia già il container per la selezione delle settimane
                        const calendarweek = document.querySelector('.calendarweek');
                        if (!calendarweek) this.gestioneSelezioneSettimane();
                    });
                }, 1000);
            }

            if (this.parameters.showSpecifiCates) {
                setTimeout(() => {
                    const SPECIFICATES = this.translate.instant('FILTRI.PERFORM_SPECIFICATES');
                    $('.ranges ul li:nth-child(5)').after(`<li class="selectSpecificates"><button type="button">${SPECIFICATES}</button></li>`);

                    $('.ranges ul li.selectSpecificates').on('click', 'button', () => {
                        this.restoreCalendars();
                        // Verifico che non ci sia già il container per la selezione delle date specifiche
                        const specificDates = document.querySelector('.specificDates');
                        if (!specificDates) this.gestioneSelezioneDateSpecifiche();
                    });
                }, 1000);
            }

            setTimeout(() => {
                const calendarLeft = document.querySelector('.calendar.left');

                const newText1 = document.createElement('p');
                newText1.innerHTML = this.translate.instant('FILTRI.DATE_RANGE_HINT1');
                newText1.classList.add('text-muted');

                const newInput1 = document.createElement('input');
                newInput1.setAttribute('type', 'text');
                newInput1.setAttribute('placeholder', this.translate.instant('FILTRI.DATE_START_PLACEHOLDER'));
                newInput1.classList.add('form-control');

                const newInput1Container = document.createElement('div');
                newInput1Container.classList.add('input-container');

                newInput1Container?.appendChild(newText1);
                newInput1Container.appendChild(newInput1);

                calendarLeft?.appendChild(newInput1Container);

                const calendarRight = document.querySelector('.calendar.right');

                const newText2 = document.createElement('p');
                newText2.innerHTML = this.translate.instant('FILTRI.DATE_RANGE_HINT2');
                newText2.classList.add('text-muted');

                const newInput2 = document.createElement('input');
                newInput2.setAttribute('type', 'text');
                newInput2.setAttribute('placeholder', this.translate.instant('FILTRI.DATE_END_PLACEHOLDER'));
                newInput2.classList.add('form-control');

                const newInput2Container = document.createElement('div');
                newInput2Container.classList.add('input-container');
                newInput2Container.appendChild(newText2);
                newInput2Container.appendChild(newInput2);

                calendarRight?.appendChild(newInput2Container);

                this.changeDetectorRef.detectChanges();

                this.applyDateMask(newInput1);
                this.applyDateMask(newInput2);

                $('.calendar.left .available').on('click', (ev: any) => {
                    const selectedDate = this.pickerDirective.picker.startDate;
                    newInput1.value = selectedDate.format('DD/MM/YYYY');
                });

                $('.calendar.right .available').on('click', (ev: any) => {
                    const selectedDate = this.pickerDirective.picker.endDate;
                    newInput2.value = selectedDate.format('DD/MM/YYYY');
                });


                const handleKeyPress = (event: KeyboardEvent) => {
                    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                        this.onDateInputChange(newInput1, newInput2);
                    }
                };

                newInput1.addEventListener('keypress', handleKeyPress);
                newInput2.addEventListener('keypress', handleKeyPress);



            }, 1000);

        }

    }

    daysSelected: any[] = [];
    isSelected = (date: any, view: 'month' | 'year' | 'multi-year'): string => {
        if (!date || !this.daysSelected || this.daysSelected.length === 0) return '';
    
        const formattedDate =
            date.year() +
            "-" +
            ("00" + (date.month() + 1)).slice(-2) +
            "-" +
            ("00" + date.date()).slice(-2);
    
        return this.daysSelected.includes(formattedDate) ? 'selected' : '';
    };

    select(event: any, calendar: any) {
        const date =
            event.year() +
            "-" +
            ("00" + (event.month() + 1)).slice(-2) +
            "-" +
            ("00" + event.date()).slice(-2);
        const index = this.daysSelected.findIndex(x => x == date);
        if (index < 0) this.daysSelected.push(date);
        else this.daysSelected.splice(index, 1);

        calendar.updateTodaysDate();
    }

    gestioneSelezioneDateSpecifiche() {
        const mdDrppicker = document.querySelector('.md-drppicker');
        const calendarLeftContainer = document.querySelector('.md-drppicker .calendar.left');
        const calendarRightContainer = document.querySelector('.md-drppicker .calendar.right');

        if (calendarLeftContainer) calendarLeftContainer.setAttribute('style', 'display: none');
        if (calendarRightContainer) calendarRightContainer.setAttribute('style', 'display: none');

        const specificDatesContainer = document.createElement('div');
        specificDatesContainer.classList.add('specificDates');

        const hintText = document.createElement('p');
        hintText.innerHTML = this.translate.instant('FILTRI.SPECIFIC_DATES_HINT1');
        specificDatesContainer.appendChild(hintText);

        const specificContainer = document.createElement('div');
        specificContainer.classList.add('specificContainer');
        specificContainer.style.overflowY = 'auto';

        // spostare #matCalendar dentro a specificContainer
        const matCalendar = document.querySelector('#matCalendar');
        if (matCalendar) {
            specificContainer.appendChild(matCalendar);
            // rendere visibile #matCalendar
            matCalendar?.setAttribute('style', 'display: block');

            specificDatesContainer.appendChild(specificContainer);

            // Aggiungo un div con classe display-flex-center per i bottoni
            const buttonsContainer = document.createElement('div');
            buttonsContainer.classList.add('display-flex-center');

            const resetButton = document.createElement('button');
            resetButton.classList.add('btn', 'btn-danger');
            resetButton.innerHTML = "<i class='bi bi-arrow-counterclockwise'></i>";
            // aggiungo il tooltip
            resetButton.setAttribute('data-toggle', 'tooltip');
            resetButton.setAttribute('title', this.translate.instant('FILTRI.WEEK_RANGE_RESET'));
            resetButton.addEventListener('click', () => this.resetSpecificDatesSelection());

            buttonsContainer.appendChild(resetButton);

            const confirmButton = document.createElement('button');
            confirmButton.classList.add('btn', 'btn-default');
            confirmButton.innerHTML = "<i class='bi bi-check'></i>";
            // aggiungo il tooltip
            confirmButton.setAttribute('data-toggle', 'tooltip');
            confirmButton.setAttribute('title', this.translate.instant('FILTRI.SPECIFIC_DATES_CONFIRM'));
            confirmButton.addEventListener('click', () => this.confirmSpecificDatesSelection());

            buttonsContainer.appendChild(confirmButton);

            const restoreButton = document.createElement('button');
            restoreButton.classList.add('btn', 'btn-default');
            restoreButton.innerHTML = "<i class='bi bi-calendar-event'></i>";
            // agiungo il tooltip
            restoreButton.setAttribute('data-toggle', 'tooltip');
            restoreButton.setAttribute('title', this.translate.instant('FILTRI.DATE_RANGE_RESTORE'));
            restoreButton.addEventListener('click', () => this.restoreCalendars());
            buttonsContainer.appendChild(restoreButton);

            specificDatesContainer.appendChild(buttonsContainer);

            if (mdDrppicker) {
                mdDrppicker.appendChild(specificDatesContainer);
            }
        }


    }

    gestioneSelezioneSettimane() {
        const mdDrppicker = document.querySelector('.md-drppicker');
        const calendarLeftContainer = document.querySelector('.md-drppicker .calendar.left');
        const calendarRightContainer = document.querySelector('.md-drppicker .calendar.right');

        if (calendarLeftContainer) calendarLeftContainer.setAttribute('style', 'display: none');
        if (calendarRightContainer) calendarRightContainer.setAttribute('style', 'display: none');

        const calendarWeekContainer = document.createElement('div');
        calendarWeekContainer.classList.add('calendarweek');

        const hintText = document.createElement('p');
        hintText.innerHTML = this.translate.instant('FILTRI.WEEK_RANGE_HINT1');
        calendarWeekContainer.appendChild(hintText);

        const weeksContainer = document.createElement('div');
        weeksContainer.classList.add('weeksContainer');
        weeksContainer.style.overflowY = 'auto';

        this.generateWeeks();

        // Aggiungi l'anno più vecchio prima di iniziare il ciclo
        const firstYearElement = document.createElement('p');
        firstYearElement.textContent = this.weeks[0].year.toString();
        weeksContainer.appendChild(firstYearElement);

        let currentYear = this.weeks[0].year;

        this.weeks.forEach((week, index) => {
            if (week.year !== currentYear) {
                const yearElement = document.createElement('p');
                yearElement.textContent = week.year.toString();
                weeksContainer.appendChild(yearElement);
                currentYear = week.year;
            }

            const weekElement = document.createElement('div');
            weekElement.classList.add('week-number');
            weekElement.textContent = week.weekNumber.toString();
            weekElement.style.display = 'inline-block';
            weekElement.style.border = '1px solid black';
            weekElement.style.borderRadius = '50%';
            weekElement.style.width = '40px';
            weekElement.style.height = '40px';
            weekElement.style.lineHeight = '40px';
            weekElement.style.textAlign = 'center';
            weekElement.style.margin = '5px';
            weekElement.style.cursor = 'pointer';
            weekElement.setAttribute('data-toggle', 'tooltip');
            weekElement.setAttribute('title', `${week.start.format('DD/MM/YYYY')} - ${week.end.format('DD/MM/YYYY')}`);

            // Se la settimana è quella corrente aggiungi la classe active
            if (week.start.isSameOrBefore(moment()) && week.end.isSameOrAfter(moment())) {
                weekElement.classList.add('currentWeek');
            }

            weekElement.addEventListener('click', () => this.selectWeek(week, weekElement));
            weekElement.addEventListener('mouseover', () => this.previewWeekSelection(week, weekElement));
            weekElement.addEventListener('mouseout', () => this.clearPreview());

            weeksContainer.appendChild(weekElement);
        });

        calendarWeekContainer.appendChild(weeksContainer);

        // Aggiungo un div con classe display-flex-center per i bottoni
        const buttonsContainer = document.createElement('div');
        buttonsContainer.classList.add('display-flex-center');

        const resetButton = document.createElement('button');
        resetButton.classList.add('btn', 'btn-default');
        resetButton.innerHTML = "<i class='bi bi-arrow-counterclockwise'></i>&nbsp;" + this.translate.instant('FILTRI.WEEK_RANGE_RESET');
        resetButton.addEventListener('click', () => this.resetWeekSelection());
        buttonsContainer.appendChild(resetButton);

        const restoreButton = document.createElement('button');
        restoreButton.classList.add('btn', 'btn-default');
        restoreButton.innerHTML = "<i class='bi bi-calendar-event'></i>&nbsp;" + this.translate.instant('FILTRI.DATE_RANGE_RESTORE');
        restoreButton.addEventListener('click', () => this.restoreCalendars());
        buttonsContainer.appendChild(restoreButton);

        calendarWeekContainer.appendChild(buttonsContainer);

        if (mdDrppicker) {
            mdDrppicker.appendChild(calendarWeekContainer);
        }

        // Scroll to the bottom of weeksContainer to show the current year
        weeksContainer.scrollTop = weeksContainer.scrollHeight;
    }

    generateWeeks() {
        const startYear = moment().year() - 2; // Start from two years ago
        const endYear = moment().year();
        const weeks: Week[] = [];

        for (let year = startYear; year <= endYear; year++) {
            const start = moment().year(year).startOf('year');
            const end = moment().year(year).endOf('year');
            let current = start.clone();
            let weekNumber = 1;

            while (current.isBefore(end)) {
                weeks.push({
                    start: current.clone().startOf('week'),
                    end: current.clone().endOf('week'),
                    year: year,
                    weekNumber: weekNumber++
                });
                current.add(1, 'week');
            }
        }

        this.weeks = weeks;
    }

    selectWeek(week: Week, weekElement: HTMLElement) {
        if (!this.firstClick) {
            this.firstClick = week.start;
            weekElement.classList.add('active');
        } else {
            if (week.start.isBefore(this.firstClick)) {
                return;
            } else {
                const startDate = this.firstClick.isBefore(week.start) ? this.firstClick : week.start;
                const endDate = this.firstClick.isAfter(week.end) ? this.firstClick : week.end;
                this.selectedWeeks.emit({ startDate, endDate });
                this.firstClick = null;
                this.resetWeekSelection(true);
            }
        }
    }

    previewWeekSelection(week: Week, weekElement: HTMLElement) {
        if (this.firstClick) {
            const startDate = this.firstClick.isBefore(week.start) ? this.firstClick : week.start;
            const endDate = this.firstClick.isAfter(week.end) ? this.firstClick : week.end;

            this.weeks.forEach((w, index) => {
                const element = document.querySelectorAll('.week-number')[index];
                if (w.start.isSameOrAfter(startDate) && w.end.isSameOrBefore(endDate)) {
                    element.classList.add('preview');
                } else {
                    element.classList.remove('preview');
                }
            });
        }
    }

    clearPreview() {
        const weekElements = document.querySelectorAll('.week-number');
        weekElements.forEach(weekElement => {
            weekElement.classList.remove('preview');
        });
    }

    resetWeekSelection(hide: boolean = false) {
        const weekElements = document.querySelectorAll('.week-number');
        weekElements.forEach((weekElement: any) => {
            weekElement.classList.remove('active');
            weekElement.classList.remove('preview');
            weekElement.style.backgroundColor = '';
            weekElement.style.color = '';
        });

        this.firstClick = null;
        if (hide) {
            this.restoreCalendars();
            this.pickerDirective.hide();
        }
    }

    resetSpecificDatesSelection(hide: boolean = false) {
        this.daysSelected = [];
        this.specificDatesCalendar.updateTodaysDate();
        if (hide) {
            this.restoreCalendars();
            this.pickerDirective.hide();
        }
    }

    confirmSpecificDatesSelection() {
        this.selectedSpecificDates.emit({ list: this.daysSelected });
        this.restoreCalendars();
        this.pickerDirective.hide();
    }

    restoreCalendars() {
        const calendarLeftContainer = document.querySelector('.md-drppicker .calendar.left');
        const calendarRightContainer = document.querySelector('.md-drppicker .calendar.right');
        const calendarWeekContainer = document.querySelector('.calendarweek');

        if (calendarLeftContainer) calendarLeftContainer.setAttribute('style', 'display: block');
        if (calendarRightContainer) calendarRightContainer.setAttribute('style', 'display: block');
        if (calendarWeekContainer) calendarWeekContainer.remove();

        // Date specifiche
        const specificDatesContainer = document.querySelector('.specificDates');
        if (specificDatesContainer) {
            this.daysSelected = [];
            this.specificDatesCalendar.updateTodaysDate();
            // Sposto #matCalendar fuori da weeksContainer e lo nascondo
            const matCalendar = document.querySelector('#matCalendar');
            if (matCalendar) {
                matCalendar?.setAttribute('style', 'display: none');
                // appendo #matCalendar a mb-daterangepicker
                this.datePicker.nativeElement.appendChild(matCalendar);
            }
            specificDatesContainer.remove();
        }


    }

    onDateInputChange(input1: HTMLInputElement, input2: HTMLInputElement) {
        const startDate = moment(input1.value, 'DD/MM/YYYY', true);
        const endDate = moment(input2.value, 'DD/MM/YYYY', true);

        if (startDate.isValid() && endDate.isValid()) {
            this.pickerDirective.picker.setStartDate(startDate);
            this.pickerDirective.picker.setEndDate(endDate);
            this.datesUpdated({ startDate, endDate });
            this.restoreCalendars();
            this.pickerDirective.hide();
        }
    }

    datesUpdated(event: any) {
        if (!event.startDate || !event.endDate) return;

        let selection = '';

        if (!this.ranges || this.ranges.length == 0) {
            selection = 'CUSTOM';
        } else {
            if (event.startDate.isSame(this.ranges[this.ONLYYESTERDAY][0]) && event.endDate.isSame(this.ranges[this.ONLYYESTERDAY][1])) {
                selection = 'OnlyYesterday';
            } else if (event.startDate.isSame(this.ranges[this.YESTERDAY][0]) && event.endDate.isSame(this.ranges[this.YESTERDAY][1])) {
                selection = 'Yesterday';
            } else if (event.startDate.isSame(this.ranges[this.MTD][0]) && event.endDate.isSame(this.ranges[this.MTD][1])) {
                selection = 'MTD';
            } else if (event.startDate.isSame(this.ranges[this.YTD][0]) && event.endDate.isSame(this.ranges[this.YTD][1])) {
                selection = 'YTD';
            } else if (event.startDate.isSame(this.ranges[this.DAY][0]) && event.endDate.isSame(this.ranges[this.DAY][1])) {
                selection = 'DAY';
            } else if (event.startDate.isSame(this.ranges[this.PREVIOUS_YEAR][0]) && event.endDate.isSame(this.ranges[this.PREVIOUS_YEAR][1])) {
                selection = 'PreviousYear';
            } else if (event.startDate.isSame(this.ranges[this.CURRENT_YEAR][0]) && event.endDate.isSame(this.ranges[this.CURRENT_YEAR][1])) {
                selection = 'CurrentYear';
            } else if (event.startDate.isSame(this.ranges[this.YTD][0]) && event.endDate.isSame(this.ranges[this.YTD][1])) {
                selection = 'YTD';
            } else if (event.startDate.isSame(this.ranges[this.MTD][0]) && event.endDate.isSame(this.ranges[this.MTD][1])) {
                selection = 'MTD';
            } else if (event.startDate.isSame(this.ranges[this.DAY][0]) && event.endDate.isSame(this.ranges[this.DAY][1])) {
                selection = 'DAY';
            } else if (event.startDate.isSame(this.ranges[this.YEAR][0]) && event.endDate.isSame(this.ranges[this.YEAR][1])) {
                selection = 'YEAR';
            } else if (event.startDate.isSame(this.ranges[this.MONTH][0]) && event.endDate.isSame(this.ranges[this.MONTH][1])) {
                selection = 'MONTH';
            } else if (event.startDate.isSame(this.ranges[this.WEEK][0]) && event.endDate.isSame(this.ranges[this.WEEK][1])) {
                selection = 'WEEK';
            } else {
                selection = 'CUSTOM';
            }
        }

        if (selection == 'CUSTOM') {
            this.selectedCustomDates.emit(event);
        } else {
            this.selectedDates.emit(selection);
        }
    }

    openCalendar() {
        this.pickerDirective.open();
    }

    applyDateMask(inputElement: HTMLInputElement) {
        inputElement.addEventListener('input', () => {
            let value = inputElement.value.replace(/[^0-9]/g, '');
            let formattedValue = '';

            if (value.length > 2) {
                formattedValue += value.slice(0, 2) + '/';
            } else {
                formattedValue += value;
            }

            if (value.length > 4) {
                formattedValue += value.slice(2, 4) + '/';
            } else if (value.length > 2) {
                formattedValue += value.slice(2);
            }

            if (value.length > 6) {
                formattedValue += value.slice(4, 8);
            } else if (value.length > 4) {
                formattedValue += value.slice(4);
            }

            inputElement.value = formattedValue;
        });
    }

}
