// show-from-year-image.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowFromYearImageService {
  private navbarHeight$ = new BehaviorSubject<number>(0);

  constructor() {
    // Attiva l'aggiornamento dell'altezza della navbar
    fromEvent(window, 'resize')
      .pipe(debounceTime(100)) // debounce per ridurre i refresh
      .subscribe(() => {
        this.updateNavbarHeight();
      });

    // Aggiorna all'avvio
    setTimeout(() => {
        this.updateNavbarHeight();
    }, 1000);    
  }

  private updateNavbarHeight() {
    const headerElement = document.getElementById('kt_header');
    const matToolbarElement = document.getElementById('matToolBar');

    const navbarHeight =
      (headerElement?.offsetHeight || 0) +
      (matToolbarElement?.offsetHeight || 0);

    this.navbarHeight$.next(navbarHeight);
    document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
  }
}
