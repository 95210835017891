import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import * as util from '@app/core/services/utilityfunctions';

@Directive({
  selector: '[ktFormatNumberDecimal]'
})
export class FormatNumberDecimalDirective implements OnInit {

    @Input() locale!: string

    private el: HTMLInputElement;
    private originalValue: any;

    constructor(
        private elementRef: ElementRef
      ) {
        this.el = this.elementRef.nativeElement;
      }

      ngOnInit() {
        this.originalValue = this.el.value;
      }

      @HostListener("focus", ["$event.target.value"])
      onFocus(value) {
      }
    
      @HostListener("blur", ["$event.target.value"])
      onBlur(value) {
        this.originalValue = this.el.value;
        console.log('originalValue', this.originalValue);
        this.el.value = util.formatNumber(this.el.value, this.locale);
        console.log('this.el.value', this.el.value);
      }

}
