// Angular
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
// Layout
import { LayoutConfigService, TranslationService } from '@app/core/_base/layout';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
// Charts
import { Chart } from 'chart.js/dist/chart.min.js';

import { FiltriService } from '@app/core/services/filtri.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import * as util from '@app/core/services/utilityfunctions';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import moment from 'moment';

@Component({
  selector: 'kt-incidenza-ricavi-costi',
  templateUrl: './incidenza-ricavi-costi.component.html',
  styleUrls: ['./incidenza-ricavi-costi.component.scss']
})
export class IncidenzaRicaviCostiComponent implements OnInit {

  utility: any;

  // Public properties
  @Input() data: any;
  @ViewChild('chart', { static: true }) chart!: ElementRef;
  totPrec!: number;
  totAtt!: number;
  totFCAtt!: number
  //totIncidencePerc!: number;
  myChart: any;
  childrenShown: any;

  graphData: any;
  grouping!: string;
  timeSlot!: string;
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  showCompPeriods: boolean = false;
  showTotIncidence: boolean = false;
  showOnlyCompData: boolean = false;

  currentData: any;
  isInside: boolean = false;

  columnsList: any;
  locale!: string;

  optionList: any;

  sortData: boolean = true;

  listCosts: any;

  maxValuesPerPage: number = 20;
  maxValuesPerPageTmp: number;
  excludePagination: boolean = false;
  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private filtriService: FiltriService,
    private translate: TranslateService,
    private translationService: TranslationService,
    private excelService: ExcelService,
    private pdfService: PDFService,
    private layoutUtilsService: LayoutUtilsService
  ) {

    this.maxValuesPerPageTmp = this.maxValuesPerPage;

    this.utility = util;
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;

      moment.locale(lang);

      if (this.myChart) {
        this.initChartJS();
      }

    });

    this.columnsList = [
      { label: '', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];

  }

  /**
* On init
*/
  ngOnInit() {

    if (!this.data || this.data.length == 0) return;

    this.grouping = this.filtriService.filterConfig['Grouping'] ? this.filtriService.filterConfig['Grouping'] : 'Company';
    this.timeSlot = 'h1';

    this.currentData = this.data;
    this.isInside = false;

    this.refreshGraph(this.currentData, this.isInside);

    this.optionList = [
      { value: 'Company', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.COMPANY') },
      { value: 'Category', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.CATEGORY') },
      { value: 'Product', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.PRODUCT') },
      { value: 'Month', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.MONTH') },
      { value: 'Week', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.WEEK') },
      { value: 'WeekDay', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.WEEKDAYS') },
      { value: 'TimeSlot', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.TIMESLOT') },
      { value: 'CostCenters', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.COSTCENTERS') },
      { value: 'Date', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.DATE') },
      { value: 'Year', label: this.translate.instant('MAINGRAPH.GROUPING_TYPE.YEAR') }
    ]
  }

  /**
   * @ Lifecycle sequence(s: any) => https://angular.io/guide/lifecycle-hooks
   */

  getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  refreshGraph(data: any, isInside: boolean = false) {
    const whatType = this.whatType();

    let labels: any = [];
    let dataActualCost: any = [];
    let dataActualIncidence: any = [];

    let elencoDati = JSON.parse(JSON.stringify(data));

    let datasets: any = [];

    //this.totIncidencePerc = 0;

    // Recupero ricavi tot
    const incomesTot = elencoDati.filter((row: any) => row.IsTotal == true && row.ItemType == 'Income' && (row.Children.length == 0 || !row.Children));
    this.totAtt = incomesTot && incomesTot.length > 1 ? incomesTot[2][whatType] : 0;

    this.listCosts = elencoDati.filter((row: any) => row.IsTotal == false && row.Type.toLowerCase().includes('cost') && (row.Children && row.Children.length >= 0) && row[whatType] > 0);

    if (this.sortData) {
      this.listCosts = this.listCosts.sort((a: any, b: any) => {
        return a[whatType] > b[whatType] ? -1 : (a[whatType] < b[whatType] ? 1 : 0);
      });
    }

    this.listCosts.forEach((record: any) => {
      labels.push(record.Name);
      if (record[whatType]) {
        dataActualCost.push(this.utility.parseNumber(record[whatType], this.locale, 2));
        const incidence = this.totAtt > 0 ? this.utility.parseNumber((record[whatType] / this.totAtt) * 100, this.locale, 2) : 0;
        dataActualIncidence.push(incidence);
      }
      //this.totIncidencePerc += this.totAtt > 0 ? (record[whatType] / this.totAtt) * 100 : 0;
    });

    datasets = [
      {
        type: 'line',
        borderWidth: 2,
        fill: false,
        label: this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.INCIDENCE_CURR'),
        backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset4'),
        borderColor: this.layoutConfigService.getConfig('colors.graph.dataset4'),
        data: dataActualIncidence,
        yAxisID: "y-axis-Incidence",
        tipo: 'incidencecurr',
        cubicInterpolationMode: 'monotone',
        hidden: isInside
      },
      {
        type: 'bar',
        label: this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.COST_CURR'),
        backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset1'),
        data: dataActualCost,
        yAxisID: "y-axis-cost",
        tipo: 'costcurr',

      }
    ]

    this.graphData = {
      labels: labels,
      datasets: datasets
    };

    this.totFCAtt = 0;

    this.graphData.datasets.forEach((dataset: any) => {
      if (dataset.tipo.toLowerCase().includes('incidencecurr')) {
        dataset.data.forEach((data: any) => {
          this.totFCAtt += parseFloat(data);
        });
      }
    });

    this.initChartJS();
  }

  formatLabel(groupby, value: any): string {
    let label = value;
    switch (groupby) {
      case 'Week':
        label = 'W' + value;
        break;
    }

    return label;
  }

  excludeGraphPagination(event: any) {
    this.excludePagination = event.checked;
    if (this.excludePagination) {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.myChart.config.data.labels.length - 1;
      this.myChart.config.options.scales.x.ticks.padding = 0;
    } else {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
      this.myChart.config.options.scales.x.ticks.padding = 35;
    }
    this.myChart.update();
  }

  onKeyUpMaxValues(event: any) {
    if (event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.maxValuesPerPage = this.maxValuesPerPageTmp;
      this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
      this.myChart.update();
    }
  }

  /** Init chart */
  initChartJS() {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    if (this.myChart) {
      this.myChart.destroy();
    }

    // Serve per esportazione in PDF per background bianco, altrimenti rimane nero.
    const bgColor = {
      id: 'bgColor',
      beforeDraw: (chart: any, options: any) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    }

    const moveChart = {
      id: 'moveChart',
      afterEvent: (chart: any, args: any) => {
        if (this.excludePagination) return;

        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        canvas.addEventListener('mousemove', (event) => {
          const x = args.event.x;
          const y = args.event.y;

          if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const leftEnabled = chart.config.options.scales.x.min > 0
            canvas.style.cursor = leftEnabled ? 'pointer' : 'not-allowed';
          } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const dataLength = chart.config.data.labels.length;
            const rightEnabled = chart.config.options.scales.x.max < dataLength - 1;
            canvas.style.cursor = rightEnabled ? 'pointer' : 'not-allowed';
          } else {
            canvas.style.cursor = 'default';
          }
        });
      },
      afterDraw: (chart: any, args: any, pluginOptions: any) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        if (this.excludePagination) {

          // Scrivo il totale dei risultati
          ctx.beginPath();
          const rect = canvas.getBoundingClientRect();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem "Barlow Condensed"`;
          const pagination = `${chart.config.data.labels.length} ${this.translate.instant('COMMONS.RESULTS')}`;
          const textWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textWidth, top - 12);
          ctx.closePath();

        } else {

          class CircleChevron {
            //constructor(x1, y1) {}

            draw(ctx, x1, pixel, enabled: boolean = true) {
              const angle = Math.PI / 180;

              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = 'rgba(102, 102, 102, .5)';
              ctx.fillStyle = 'white';
              ctx.arc(x1, height / 2 + top, 10, angle * 0, angle * 360, false);
              ctx.stroke();
              ctx.fill();
              ctx.closePath();

              // chevron Arrow
              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = enabled ? '#5867DD' : '#CCCCCC';
              ctx.moveTo(x1 + pixel, height / 2 + top - 5.5);
              ctx.lineTo(x1 - pixel, height / 2 + top);
              ctx.lineTo(x1 + pixel, height / 2 + top + 5.5);
              ctx.stroke();
              ctx.closePath();
            }
          }

          const leftEnabled = chart.config.options.scales.x.min > 0
          let drawCircleLeft = new CircleChevron();
          drawCircleLeft.draw(ctx, left, 3, leftEnabled);

          const dataLength = chart.config.data.labels.length;
          const rightEnabled = chart.config.options.scales.x.max < dataLength - 1;
          let drawCircleRight = new CircleChevron();
          drawCircleRight.draw(ctx, right, -3, rightEnabled);

          // Scrivo la paginazione
          ctx.beginPath();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem  "Barlow Condensed"`;
          const pagination = `${this.translate.instant('EXPORT_PDF.PAGE')} ${Math.ceil(chart.config.options.scales.x.min / this.maxValuesPerPage) + 1} ${this.translate.instant('EXPORT_PDF.OF')} ${Math.ceil(dataLength / this.maxValuesPerPage)}`;
          const textPaginationWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textPaginationWidth, top - 12);
          ctx.closePath();

          // Scrivo l'indicazione per cliccare sul grafico
          ctx.beginPath();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem  "Barlow Condensed"`;
          const hint = this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.CLICK_ON_CHART')
          ctx.fillText(hint, left, top - 12);
          ctx.closePath();
        }
      }
    }

    const options = {
      type: 'bar',
      data: this.graphData,
      plugins: [
        bgColor,
        moveChart,
        {
          afterDraw: (chart: any) => {
            const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
            /************************************/
            // Disegno l'andamento
            const revAttuale = chart.config.data.datasets.filter((record: any) => record.tipo === 'costcurr');
            if (!revAttuale[0] || revAttuale[0].data.length > 30) return;
            for (let index = 0; index < revAttuale[0].data.length; index++) {
              let x = chart.scales['x'].getPixelForTick(index);
              let y = chart.scales['y-axis-cost'].bottom;
              ctx.fillStyle = "#505050";

              const tRevAttuale = parseFloat(revAttuale[0].data[index]);
              const incidenza = this.totAtt > 0 ? tRevAttuale / this.totAtt * 100 : 0;
              ctx.font = `500 1rem  "Barlow Condensed"`;

              const incidenceLabel = this.utility.formatNumber(incidenza, this.locale, 0);
              const textWidth1 = ctx.measureText(incidenceLabel).width;
              ctx.fillText(`${incidenceLabel}%`, x - (textWidth1 / 2), y + 15);

            }

          }
        }],
      options: {
        name: 'MainGraph',
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            bodyFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            titleFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            footerFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            mode: 'index',
            padding: 10,
            caretPadding: 10,
            callbacks: {
              title: (tooltipItem) => {
                return tooltipItem[0].label;
              },
              label: (tooltipItem, a, v) => {
                this.graphData
                const value = parseFloat(tooltipItem.raw);

                if (this.graphData.datasets[tooltipItem.datasetIndex].tipo == 'costcurr') {
                  return this.utility.formatNumber(value, this.locale, 0, true, true);
                } else if (this.graphData.datasets[tooltipItem.datasetIndex].tipo == 'incidencecurr') {
                  return this.utility.formatNumber(value, this.locale, 0) + '%';
                }

              }
            }
          },
          legend: {
            labels: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              }
            },
            display: true,
            position: 'bottom'
          }
        },
        // https://dev.to/giselamd/creating-a-custom-chart-js-legend-style-50i5
        legendCallback: (chart) => {
          const renderLabels = (chart) => {
            const { data } = chart;
            return data.datasets
              .map(
                (_, i) => {
                  let backgroundColor;
                  let label;
                  backgroundColor = data.datasets[i].backgroundColor;
                  label = data.datasets[i].label;

                  return `<li>
								<div id="legend-${i}-item" class="legend-item">
								  <span style="background-color:
									${backgroundColor}">
									&nbsp;&nbsp;&nbsp;&nbsp;
								  </span>
								  ${label &&
                    `<span class="label">&nbsp;${label}</span>`
                    }
								</div>
							</li>
						  `})
              .join("");
          };
          return `
					  <ul class="chartjs-legend display-flex-center flex-align-center flex-wrap list-style-none">
						${renderLabels(chart)}
					  </ul>`;
        },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          x: {
            min: 0,
            max: this.maxValuesPerPage - 1,
            grid: {
              display: false
            },
            stacked: false,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: 15,
              callback: (value: any, index: number, values: any) => {
                const overrideValue = this.graphData.labels[index];
                return overrideValue;
              },
            },
          },
          "y-axis-cost": {
            id: "y-axis-cost",
            position: 'left',
            display: true,
            stacked: false,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                if (parseFloat(value) > 0)
                  return this.utility.kFormatter(value, this.locale);
              },
              precision: 0,
              integerSteps: true
            },
            title: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.COST_Y')
            }
          },
          "y-axis-Incidence": {
            id: "y-axis-Incidence",
            position: 'right',
            display: true,
            stacked: false,
            grid: {
              display: true
            },
            beginAtZero: true,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                return value + '%';
              }
            },
            title: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.INCIDENCE_Y')
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 18,
            bottom: 0
          }
        },
        onHover: (event: any, chartElement: any) => {
          event.native.target.style.cursor = !this.isInside && chartElement[0] ? 'pointer' : 'default';
        },
        onClick: (event: any, element: any, chart: any) => {
          const record = this.listCosts[element[0]['index']];
          if (record.Children && record.Children.length > 0) {
            console.log('Children', record.Children);
            this.isInside = true;
            this.currentData = record.Children;
            this.refreshGraph(this.currentData, this.isInside);
            $('#goBackButton').show();
          }
        }
      }
    };

    this.myChart = new Chart(this.chart.nativeElement, options);

    const moveScroll = () => {
      this.myChart.canvas.addEventListener('click', (event: any) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = this.myChart;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
          this.myChart.config.options.scales.x.min -= this.maxValuesPerPage;
          this.myChart.config.options.scales.x.max -= this.maxValuesPerPage;
          if (this.myChart.config.options.scales.x.min <= 0) {
            this.myChart.config.options.scales.x.min = 0
            this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
          }
          this.myChart.update();
        } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {

          this.myChart.config.options.scales.x.min += this.maxValuesPerPage;
          this.myChart.config.options.scales.x.max += this.maxValuesPerPage;

          const dataLength = this.myChart.config.data.labels.length;
          if (this.myChart.config.options.scales.x.max >= dataLength) {
            this.myChart.config.options.scales.x.min = dataLength - this.maxValuesPerPage;
            this.myChart.config.options.scales.x.max = dataLength - 1;
          }
          this.myChart.update();
        }

      });
    }

    this.myChart.ctx.onclick = moveScroll();

    // https://dev.to/giselamd/creating-a-custom-chart-js-legend-style-50i5
    // document.getElementById('chart-legends').innerHTML = this.myChart.generateLegend();
    // bindChartEvents(this.myChart, document);
  }

  goBack() {
    $('#goBackButton').hide();
    this.currentData = this.data;
    this.isInside = false;
    this.refreshGraph(this.currentData, this.isInside);
  }

  getTotAttuale() {
    return this.utility.formatNumber(this.totAtt, this.locale, 2, true, true);
  }

  getTotPrecedente() {
    return this.utility.formatNumber(this.totPrec, this.locale, 2, true, true);
  }

  getTotAndamento() {
    const totAndamento = Math.round(((this.totAtt - this.totPrec) / this.totPrec) * 100)
    return (totAndamento !== Infinity) ? totAndamento : undefined;
  }

  showComparePeriods() {
    this.showCompPeriods = !this.showCompPeriods;
    this.refreshGraph(this.currentData, this.isInside);
  }

  showTotalIncidence() {
    this.showTotIncidence = !this.showTotIncidence;
    this.refreshGraph(this.currentData, this.isInside);
  }

  showOnlyCompareData() {
    this.showOnlyCompData = !this.showOnlyCompData;
    this.refreshGraph(this.currentData, this.isInside);
  }

  /******************************************************************************
    ______                       _    __   ___       _______   __
   |  ____|                     | |   \ \ / / |     / ____\ \ / /
   | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
   |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
   | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
   |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
  ******************************************************************************/

  exportAsXLSX() {
    this.loadingSubject.next(true);

    let xlsx: any[] = [];
    let merges: any[] = [];
    let i: number = 0;

    let rowsStyles: any = [];

    // FACCIO PRIMA IL BODY, mi serve per calcolare FC % TOT
    let xlsxBody: any[] = [];
    let totFc: number = 0;
    this.listCosts.forEach((item: any) => {
      rowsStyles.push({
        sz: 10
      });

      let columns: any = {};

      const currValue = item[this.whatType()];

      columns[`${this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.XLS_COLUMNS.COST_NAME')}`] = item.Name.toUpperCase();
      columns[`${this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.XLS_COLUMNS.COST_CURR')}`] = this.utility.formatNumberExcel(currValue, 2);
      columns[`${this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.XLS_COLUMNS.INCIDENCE')}`] = this.utility.formatNumberExcel(currValue / this.totAtt * 100, 2);

      xlsxBody.push(columns);
      i++;
    });

    xlsx = xlsx.concat(xlsxBody);

    this.loadingSubject.next(false);

    this.columnsList = [
      { label: '', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];

    this.excelService.exportAsExcelFile(xlsx, this.translate.instant('MAINGRAPH.EXPORT_TITLE'), merges, this.columnsList, 1, rowsStyles, false);
  }

  /******************************************************************************
    ______                       _     _____  _____  ______
   |  ____|                     | |   |  __ \|  __ \|  ____|
   | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
   |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
   | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
   |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
               | |
               |_|
  ******************************************************************************/

  exportAsPDF() {

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
    };

    this.columnsList = [
      { label: '', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result['header'] = {
          export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.EXPORT_TITLE') }),
          period: this.filtriService.getCurrentPeriod()
        }
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        }
        result['language'] = this.translationService.getSelectedLanguage();
        result['table'].headerRows = 1;
        result['orientation'] = 'portrait';
        let columnsTot = this.columnsList.filter((item: any) => item.enabled).length;

        let pdfTableBody;
        //switch (this.grouping) {
        //    case 'Company':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.COMPANY');
        //        break;
        //    case 'Category':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.CATEGORY');
        //        break;
        //    case 'Product':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.PRODUCT');
        //        break;
        //    case 'Month':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.MONTH');
        //        break;
        //    case 'Week':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.WEEK');
        //        break;
        //    case 'WeekDay':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.WEEKDAYS');
        //        break;
        //    case 'TimeSlot':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.TIMESLOT');
        //        break;
        //    case 'CostCenters':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.COSTCENTER');
        //        break;
        //    case 'Date':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.DATE');
        //        break;
        //    case 'Year':
        //        pdfTableBody = this.getPDFTableBody('MAINGRAPH.GROUPING_TYPE.YEAR');
        //        break;
        //}

        pdfTableBody = this.getPDFTableBody('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.XLS_COLUMNS.COST_NAME');

        this.pdfService.makePdf(result, pdfTableBody, Array(columnsTot).fill('auto'));
      };
    });

  }

  getPDFTableBody(columnName: string) {

    let body: any = [];
    let tmpRow: any = [];

    tmpRow = [
      { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.XLS_COLUMNS.COST_NAME')}`, style: 'tableHeaderStyle' },
      { visible: this.columnsList[1].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.XLS_COLUMNS.COST_CURR')}`, style: 'tableHeaderStyle' },
      { visible: this.columnsList[2].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.XLS_COLUMNS.INCIDENCE')}`, style: 'tableHeaderStyle' },
    ];
    body.push(tmpRow.filter((item: any) => item.visible));


    let i: number = 0;
    let totFc: number = 0;
    let bodyTmp: any = [];
    this.listCosts.forEach((item: any) => {
      const currValue = item[this.whatType()];

      tmpRow = [
        { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Name.toUpperCase(), style: 'tableBodyStyle' },
        { visible: this.columnsList[1].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.utility.formatNumber(currValue, this.locale, 2), style: 'tableBodyStyle' },
        { visible: this.columnsList[2].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.utility.formatNumber(currValue / this.totAtt * 100, this.locale, 2), style: 'tableBodyStyle' },
      ];

      bodyTmp.push(tmpRow.filter((item: any) => item.visible));
      i++;

      //totFc += foodCostPerc;
    });


    body = body.concat(bodyTmp);

    return body;
  }

  showTableResult: boolean = false;
  exportTableRows: any;
  prepareShowTableResult() {
    //this.childrenShown = Array(this.data.Items.length).fill(false);
    this.showTableResult = !this.showTableResult;
    this.exportTableRows = [];
    if (this.showTableResult) {

      this.listCosts.forEach((item: any) => {
        const currValue = item[this.whatType()];

        this.exportTableRows.push([
          item.Name,
          this.utility.formatNumber(currValue, this.locale, 2),
          this.utility.formatNumber(currValue / this.totAtt * 100, this.locale, 2),
        ]);
      });
    }

  }

  getFillRows() {
    return this.data && this.data.Items && this.data.Items.length > 0 ? this.data.Items.filter((item: any) => item.ActualValue.NetRevenue > 0 && item.ActualValue.FoodCost > 0).length : undefined;
  }

  printChart() {
    this.myChart.update();
    setTimeout(() => {
      var canvas = <HTMLCanvasElement>document.getElementById('incidenza_ricavi_costi_chart');
      this.pdfService.chartToPdf(canvas, this.translate.instant('CONTOECONOMICO.DASHBOARD.SVILUPPO_RICAVI.TITLE'));
    }, 100);
  }

  sort() {
    this.sortData = !this.sortData;
    this.refreshGraph(this.currentData, this.isInside);
  }

  revenueType: string = 'Nett';

  setRevenueType() {
    this.revenueType = this.revenueType == 'Gross' ? 'Nett' : 'Gross';
    this.refreshGraph(this.currentData, this.isInside);
  }

  getRevenueTypeTooltip() {
    if (this.revenueType == 'Gross') {
      return this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.GROSS');
    } else {
      return this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.NETT');
    }
  }

  whatType() {
    if (this.revenueType == 'Gross') {
      return 'CurrentGrossValue'
    } else {
      return 'CurrentValue';
    }
  }

  getTitle() {
    if (this.revenueType == 'Gross') {
      return this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.TITLE_GROSS');
    } else {
      return this.translate.instant('CONTOECONOMICO.DASHBOARD.INCIDENZA_RICAVI_COSTI.TITLE_NETT');
    }
  }
}

