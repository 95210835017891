import { Component, Input, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContoEconomicoDetailsComponent } from '../../conto-economico-details/conto-economico-details.component';
import { ContoEconomicoEditComponent } from '../../conto-economico-edit/conto-economico-edit.component';
import * as util from '@app/core/services/utilityfunctions';
import { RoleService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { FiltriService } from '@app/core/services/filtri.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import moment from 'moment';
import * as XLSX from '@sheet/core';

declare var window: any;
declare var $: any;
declare var DataTable: any;

//import * as $ from 'jquery';
import 'datatables.net';
import "datatables.net-fixedcolumns";

import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
  selector: 'kt-companies-view',
  templateUrl: './companies-view.component.html',
  styleUrls: ['./companies-view.component.scss']
})
export class CompaniesViewComponent implements OnInit {
  view: string = 'COMPANIES';



  @Input() companiesList: any = {};
  @Input() filters: any = {};
  @Output() onClickGraph = new EventEmitter<any>();

  filteredCompanyist: any
  locale!: string;
  showTotals: boolean = false;
  currentFilterCfg: any = {};
  utility: any;
  expandRows: boolean = false;
  lastRowsExpanded: any = [];
  PLData: any = [];

  filtriServiceSubscription!: Subscription;

  constructor(
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private roleService: RoleService,
    private switchGroupService: SwitchGroupService,
    private pdfService: PDFService,
    private translate: TranslateService,
    public filtriService: FiltriService,
    private translationService: TranslationService,
    public loaderService: LoaderService
  ) {
    this.utility = util;

    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
      moment.locale(this.locale)
    });
  }

  ngOnDestroy() {
    this.filtriServiceSubscription.unsubscribe();
  }

  ngOnInit(): void {
    //console.log(this.companiesList);
    this.filteredCompanyist = this.companiesList.filter((x: any) => !x.ExcludeCE);
    this.checkScroll();
    this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
      async (filterConfig: any) => {
        // Se ho un filtro memorizzato lo recupero
        if (localStorage.getItem('currentFilterCfg')) {
          try {
            this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
            localStorage.removeItem('currentFilterCfg');
          } catch (error) {
            localStorage.removeItem('currentFilterCfg');
            return;
          }
          //return;
        } else {
          if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
            return;
          };
          if (filterConfig.constructor !== Object) {
            return;
          };

          if (JSON.stringify(filterConfig) == JSON.stringify(this.currentFilterCfg)) {
            return;
          }

          this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
        }


        if (this.filters.filterCompanies && this.filters.filterCompanies.length > 0) {
          this.currentFilterCfg['Companies'] = this.filters.filterCompanies;
        } else {
          delete this.currentFilterCfg.Companies;
        }

        if (this.filters.filtercostCenters && this.filters.filtercostCenters.length > 0) {
          this.currentFilterCfg['CostCenterIds'] = this.filters.filtercostCenters;
        } else {
          delete this.currentFilterCfg.CostCenterIds;
        }

        if (!this.table) {
          this.initDataTable();
        } else {
          this.table.draw();
        }

      }
    );
  }

  checkScroll() {
    const wHeight: any = $(window).height();
    const tHeight: any = $("#ceTable_c").height();
    if (wHeight < tHeight) {
      $("#scrollUp").show();
    } else {
      $("#scrollUp").hide();
    }
  }

  clearFilters(actionFilter: boolean = true, redrawTable: boolean = true) {
    this.filtriService.clearFilters$.next(actionFilter);
    this.showTotals = false;
    this.currentFilterCfg = {
      Period: 'year',
      fromLocal: true,
      TimeInterval: 'none',
      DayOfWeek: 0
    };
    this.lastRowsExpanded = [];
    this.ref.detectChanges();
    this.filters.ck_prevyear
    if (redrawTable) this.table.draw();
  }

  training: number = 0;
  showTraining(show: boolean = false) {
    this.training = show ? 2 : 0;
    // 0/3 fa vedere tutto insieme
    // 1 Non fa vedere training
    // 2 Fa vedere solo training
    this.table.draw();
  }

  table: any;
  PDFPreferences: any;
  columnDefsObj: any;
  initDataTable() {
    if ($.fn.dataTable.isDataTable('#ceTable_c')) {
      $('#ceTable_c').DataTable().clear().destroy();
    }

    setTimeout(() => {
      const currentLogin = this.switchGroupService.getCurrentGroup();

      const columnDefs = () => {
        this.columnDefsObj = [
          // Serve per evitare di riceve avvisi quando arrivano campi a null
          {
            defaultContent: '',
            targets: '_all'
          },
          {
            targets: 0,
            className: 'parent-row actions',
            width: '20px',
            orderable: false,
            visible: !this.showTotals,
            render: (data, type, p) => {
              return !p.IsTotal && p.Children && p.Children.length > 0 ? '<i class="bi expander pointer"></i>' : '';
            }
          },
          {
            targets: 1, data: 'Name', name: 'Name',
            width: '150px',
            className: 'nameCol',
            render: (data, type, p) => {
              let classClickGraph: string = !this.showTotals && !p.IsCover && !p.IsTotal ? 'clickGraph' : '';
              return `<div class="overflow-ellipses exportText ${classClickGraph}" title="${data}">${data}</div>`;
            }
          },
          {   // Totals
            targets: 2,
            width: '150px',
            className: 'text-right',
            render: (data, type, p) => {
              const values = p.Columns[0].SubColumns;
              return this.getValue(values);
            }
          },
          { // Totals %
            targets: 3,
            width: '90px',
            className: 'text-right' + (!this.filters.ck_prevyear ? ' last-fixed-col-left' : ''),
            render: (data, type, p) => {
              const values = p.Columns[0].SubColumns;
              return this.getPerc(values);
            }
          },
          {   // Totals Prev
            targets: 4,
            width: '150px',
            className: 'text-right',
            visible: this.filters.ck_prevyear,
            render: (data, type, p) => {
              const values = p.Columns[0].SubColumns;
              return this.getValue(values, true);
            }
          },
          {   // Totals Prev %
            targets: 5,
            width: '90px',
            className: 'text-right',
            visible: this.filters.ck_prevyear,
            render: (data, type, p) => {
              const values = p.Columns[0].SubColumns;
              return this.getPerc(values, true);
            }
          },
          {   // Totals Diff
            targets: 6,
            width: '150px',
            className: 'text-right' + (this.filters.ck_prevyear ? ' last-fixed-col-left' : ''),
            visible: this.filters.ck_prevyear,
            render: (data, type, p) => {
              const values = p.Columns[0].SubColumns;
              return this.getDiff(values);
            }
          }];

        let colIndex = 7;

        this.filteredCompanyist.forEach((company: any, counter: number) => {

          const zebra = counter % 2 == 0 ? '' : ' zebra'
          const classCompanyName = company.Name;

          this.columnDefsObj.push({
            targets: colIndex++,
            width: '150px',
            className: 'text-right' + zebra,
            companyName: classCompanyName,
            render: (data, type, p) => {
              const index = p.Columns.findIndex((c: any) => c.Group == company.Name);
              const values = p.Columns[index].SubColumns;
              return this.getValue(values);
            }
          });
          this.columnDefsObj.push({
            targets: colIndex++,
            width: '90px',
            className: 'text-right' + zebra,
            companyName: classCompanyName,
            render: (data, type, p) => {
              const index = p.Columns.findIndex((c: any) => c.Group == company.Name);
              const values = p.Columns[index].SubColumns;
              return this.getPerc(values);
            }
          });
          this.columnDefsObj.push({
            targets: colIndex++,
            width: '150px',
            className: 'text-right' + zebra,
            companyName: classCompanyName,
            visible: this.filters.ck_prevyear,
            render: (data, type, p) => {
              const index = p.Columns.findIndex((c: any) => c.Group == company.Name);
              const values = p.Columns[index].SubColumns;
              return this.getValue(values, true);
            }
          });
          this.columnDefsObj.push({
            targets: colIndex++,
            width: '90px',
            className: 'text-right' + zebra,
            companyName: classCompanyName,
            visible: this.filters.ck_prevyear,
            render: (data, type, p) => {
              const index = p.Columns.findIndex((c: any) => c.Group == company.Name);
              const values = p.Columns[index].SubColumns;
              return this.getPerc(values, true);
            }
          });
          this.columnDefsObj.push({
            targets: colIndex++,
            width: '150px',
            className: 'text-right' + zebra,
            companyName: classCompanyName,
            visible: this.filters.ck_prevyear,
            render: (data, type, p) => {
              const index = p.Columns.findIndex((c: any) => c.Group == company.Name);
              const values = p.Columns[index].SubColumns;
              return this.getDiff(values);
            }
          });

        });

        return this.columnDefsObj
      }

      const dtOptions: any = {
        drawCallback: () => {
          if (this.lastRowsExpanded.length >= 0) {
            this.lastRowsExpanded.forEach((row: any) => {
              $(`#ceTable_c tbody tr:nth-child(${row + 1}) td.parent-row`).trigger('click');
            })
          }

          this.checkScroll();

          if (this.filters.PLCategoryName) {
            setTimeout(() => {
              // Cerco il primo elemento che contiene PLCategoryName
              const riga = $(`td[data-categoryname="${this.filters.PLCategoryName}" i]`).closest('tr');
              if (riga.length > 0) riga[0].scrollIntoView();
            }, 100);
          }

          if (this.showTotals) {
            $('tr.children').hide();
          }

          setTimeout(() => {
            this.table.columns.adjust().fixedColumns().left(this.getFixedCols());
          }, 100);

        },
        createdRow: (row, data, dataIndex) => {
          if (data.IsCover) {
            $(row).addClass('rowCover');
            if (data.IsSectionTotal) {
              $(row).addClass('rowCoverTotal');
            }
          }
          if (data.IsTotal) {
            $(row).addClass('rowTotal');
            if (data.IsSection) {
              $(row).addClass('rowSection');

              if(this.showTotals) {
                $(row).addClass('showTotals');
              }
            }
          } else {
            $(row).attr('data-row', JSON.stringify(data));
          }
        },
        rowCallback: (row, data, index) => {
          if (!this.filters.ck_showCover && data.ItemType == 'GenericCover') {
            $(row).hide();
          }

          const hideRow = !this.showTotals && this.checkHideRow(data);
          if (hideRow) {
            $(row).hide();
          }

          // Controllo se ho effettuato una ricerca per terzo livello
          if (this.filters.PLCategoryName && data.Children.length == 0 && !data.IsCover && !data.IsTotal) {
            $(row).hide();
          } else if (this.filters.PLCategoryName && data.Children.length > 0 && data.ItemType !== 'GenericCover') {
            this.table.row($(row)).child(this.format(data)).show();
          }
        },
        destroy: true,
        paging: false,
        searching: true,
        dom: 'lrtip',
        ordering: false,
        scrollX: true,
        scrollY: 'calc(100vh - 24rem)',
        scrollCollapse: true,
        autoWidth: true,
        order: [],
        processing: true,
        serverSide: true,
        fixedColumns: {
          leftColumns: this.filters.ck_prevyear ? 7 : 4
        },
        ajax: {
          url: currentLogin.endpointURL + "/api/PL/ByStore",
          type: "POST",
          data: (d: any) => {
            d.filters = this.currentFilterCfg;
            d.PLCategoryName = this.filters.PLCategoryName;
            d.filters.Training = this.training;
            return JSON.stringify(d);
          },
          headers: {
            "Authorization": "Bearer " + currentLogin.access_token,
            "Content-Type": "application/json"
          },
          dataSrc: (response) => {
            // Se sto filtrando per nome categoria, non posso vedere tutto collassato
            if (this.filters.PLCategoryName) {
              this.showTotals = false;
            }

            if (this.showTotals && !this.expandRows) {
              // Only include total rows
              response.data = response.data.filter((row: any) => row.IsTotal && row.IsSection);
            }

            this.PLData = response.data;
            return response.data;
          }
        },
        columnDefs: columnDefs(),
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        buttons: [
          {
            className: 'toolbarButton',
            extend: 'pdf',
            orientation: 'landscape',
            title: this.PDFPreferences?.title,
            pageSize: 'A4',
            download: 'open',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.actions)'
            },
            action: function (e, dt, node, config) {
              var self = this;
              var currentPageLen = dt.page.len();
              var currentPage = dt.page.info().page;
              var totRecords = dt.page.info().recordsTotal
              dt.one('draw', () => {
                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                setTimeout(function () {
                  dt.page.len(currentPageLen).draw(); //set page length
                  dt.page(currentPage).draw('page'); //set current page
                });
              });
              dt.page.len(totRecords).draw();
            },
            customize: (doc: any) => {

              const imageLogoB64 = $('#kt_header .logoimg').attr('src');
              const size = {
                width: $('#kt_header .logoimg').width(),
                height: $('#kt_header .logoimg').height()
              };

              const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], '', imageLogoB64, size);
              doc.content[0] = docDefinition.content[0];
              doc.content[1].layout = docDefinition.content[1].layout;
              doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
              //doc.content[1].table['widths']=docDefinition.content[1].table['widths'];
              //doc.defaultStyle=docDefinition.defaultStyle;
              doc.footer = docDefinition.footer;
              doc.header = docDefinition.header;
              doc.pageMargins = docDefinition.pageMargins;
              doc.pageOrientation = 'landscape'; //docDefinition.pageOrientation;
              doc.pageSize = this.filters.ck_prevyear ? 'A2' : 'A3'; //docDefinition.pageSize;

              doc.styles = docDefinition.styles;

              Object.keys(doc.styles).forEach(key => {
                if (key.toLowerCase().indexOf('table') >= 0) {
                  doc.styles[key].fontSize = this.filters.ck_prevyear ? 3.5 : 7;
                }
              })

              // export row child
              //http://live.datatables.net/hepopote/1/edit

              if (this.expandRows) {

                var rowData = this.table.rows({ order: 'applied', search: 'applied' }).data();

                var headerLines = 0;  // Offset for accessing rowData array

                var newBody: any = []; // this will become our new body (an array of arrays(lines))

                //Loop over all lines in the table
                doc.content[1].table.body.forEach((line, i) => {

                  if (line[0].style !== 'tableHeader' && line[0].style !== 'tableFooter') {
                    line[0]['style'] = rowData[i - headerLines].IsTotal ? 'tableBodyTotal' : 'tableBodyParent';
                    for (let index = 1; index < line.length; index++) {
                      line[index]['alignment'] = 'right';
                      line[index]['style'] = rowData[i - headerLines].IsTotal ? 'tableBodyTotal' : 'tableBodyParent';
                    }
                  }

                  // Remove detail-control column
                  newBody.push(line);

                  if (line[0].style !== 'tableHeader' && line[0].style !== 'tableFooter') {

                    var data = rowData[i - headerLines];
                    // Append child data, matching number of columns in table
                    if (data.Children && data.Children.length > 0) {

                      data.Children.forEach(child => {

                        const columns = child.Columns;

                        let columnsValues: any = [
                          { text: '- ' + child.Name, style: 'tableBodyStyle' },
                        ];


                        const mod = 5;
                        const totColumns = mod + (this.filteredCompanyist.length * mod);

                        for (var col = 1; col < totColumns; col++) {
                          const month = Math.floor((col - 1) / mod);
                          if (this.table.column(col + 1).visible()) {
                            let text = '';
                            if ((col - 1) % mod == 0) {
                              text = this.getValue(columns[month].SubColumns);
                            } else if ((col - 1) % mod == 1) {
                              text = this.getPerc(columns[month].SubColumns);
                            } else if ((col - 1) % mod == 2) {
                              text = this.getValue(columns[month].SubColumns, true);
                            } else if ((col - 1) % mod == 3) {
                              text = this.getPerc(columns[month].SubColumns, true);
                            } else if ((col - 1) % mod == 4) {
                              text = this.getDiff(columns[month].SubColumns);
                            }

                            columnsValues.push({ text: text, alignment: 'right', style: 'tableBodyStyle' });
                          }
                        }

                        newBody.push(columnsValues);

                      })
                    }

                  } else {
                    headerLines++;
                  }

                });

                doc.content[1].table.body = newBody;
              } else {

                //Aggiungo la prima riga alla tabella
                //doc.content[1].table.body.unshift(firstLine);
              }

              this.expandRows = false;
            }
          },
          {
            className: 'toolbarButton',
            extend: 'excelHtml5',
            //autoFilter: true,
            //sheetName: 'Exported data',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.actions)',
              format: {
                body: (data, row, column, node) => {
                  if (data.indexOf('exportText') >= 0) {
                    var parser = new DOMParser();
                    var htmlDoc = parser.parseFromString(data, 'text/html');
                    return $(htmlDoc).find('.exportText').text();
                  } else {
                    // dalla colonna 1 in avanti formatto
                    const value = column >= 1 ? this.utility.parseNumber(data, this.locale) : data;
                    return value;
                  }
                }
              },
            },
            customize: async (xlsx: any) => {

              XLSX.SSF.setlocale("de-DE");

              var sSh = xlsx.xl['styles.xml'];
              var lastXfIndex = $('cellXfs xf', sSh).length - 1;
              // Bold Text - Yellow
              var s1 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"0\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" />';
              // Bold Text - Yellow - Alignment Right
              var s2 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';
              // Bold Text - Alignment Right
              var s3 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"0\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';
              sSh.childNodes[0].childNodes[5].innerHTML = sSh.childNodes[0].childNodes[5].innerHTML + s1 + s2 + s3;
              const fillYellow = '<fill xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\"><patternFill patternType=\"solid\"><fgColor rgb=\"FFF1E8C1\" /><bgColor indexed=\"64\" /></patternFill></fill>';
              sSh.childNodes[0].childNodes[2].innerHTML = sSh.childNodes[0].childNodes[2].innerHTML + fillYellow;
              var boldTextYellowFillIndex = lastXfIndex + 1;
              var boldTextYellowFillAlignRightIndex = lastXfIndex + 2;
              var boldTextAlignRightIndex = lastXfIndex + 3;

              // Get sheet.
              var sheet = xlsx.xl.worksheets['sheet1.xml'];

              // Get a clone of the sheet data.        
              var sheetData = $('sheetData', sheet).clone();

              // Clear the current sheet data for appending rows.
              $('sheetData', sheet).empty();

              // Row index from last column.
              var DT_row = 0;        // Row count in Excel sheet.

              var rowCount = 1;

              const numToAlpha = (num: number) => {
                var alpha = '';
                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                  alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                }
                return alpha;
              }

              // Iterate each row in the sheet data.
              $(sheetData).children().each((index, element) => {

                // Don't process row if its the header row.
                if (index > 1) {

                  // Get row
                  var row: any = $(element);

                  // Set the Excel row attr to the current Excel row count.
                  row.attr('r', rowCount);

                  // Iterate each cell in the row to change the rwo number.
                  row.children().each((index, element) => {
                    var cell = $(element);

                    // Set each cell's row value.
                    var rc: any = cell.attr('r');
                    rc = rc.replace(/\d+$/, "") + rowCount;
                    cell.attr('r', rc);

                  });

                  // Get the row HTML and append to sheetData.
                  row = row[0].outerHTML;
                  $('sheetData', sheet).append(row);
                  // Get the child data - could be any data attached to the row.
                  const rowData = this.table.row(DT_row, { search: 'none', order: 'index' }).data();

                  // Applico lo style ai parents
                  //https://datatables.net/reference/button/excelHtml5
                  if (rowData && rowData.IsTotal && rowData.IsSection) {
                    // Imposto tutta la riga con lo stile boldTextYellowFillAlignRightIndex
                    $(`row:nth-child(${rowCount}) c`, sheet).attr('s', boldTextYellowFillAlignRightIndex);
                    // Imposto la prima cella con lo stile boldTextYellowFillIndex
                    $(`row c[r="A${rowCount}"]`, sheet).attr('s', boldTextYellowFillIndex);
                  } else if (rowData && rowData.IsTotal) {
                    // Imposto la prima cella con lo stile bold
                    $(`row c[r="A${rowCount}"]`, sheet).attr('s', '2');
                    // Imposto il resto della riga con lo stile grassetto
                    $(`row:nth-child(${rowCount}) c:gt(0)`, sheet).attr('s', boldTextAlignRightIndex);
                  } else if (rowData) {
                    // Imposto tutta la riga con l'allineamento a destra
                    $(`row:nth-child(${rowCount}) c`, sheet).attr('s', '64');
                    // Imposto la prima cella con l'allineamento a sinistra
                    $(`row c[r="A${rowCount}"]`, sheet).attr('s', '50');
                  } else {
                    return;
                  }

                  rowCount++;

                  if (this.expandRows) {
                    const childData = rowData && rowData.Children ? rowData.Children : [];
                    // The child data is an array of rows
                    for (let c = 0; c < childData.length; c++) {
                      // Get row data.
                      const child = childData[c];

                      let columnsValues: any = [];
                      const columns = child.Columns;

                      // uso effectiveColIndex perchè quando salto una colonna perchè è 
                      // inivisibile, in realtà sull'excel non la salto, e la numerazione deve essere sequenziale
                      let effectiveColIndex = 1;
                      const mod = 5;
                      const totColumns = mod + (this.filteredCompanyist.length * mod);

                      for (var col = 1; col < totColumns; col++) {
                        const month = Math.floor((col - 1) / 5);
                        if (this.table.column(col + 1).visible()) {
                          let text = '';
                          if ((col - 1) % mod == 0) {
                            text = this.getValueExcel(columns[month].SubColumns);
                          } else if ((col - 1) % mod == 1) {
                            text = this.getPercExcel(columns[month].SubColumns);
                          } else if ((col - 1) % mod == 2) {
                            text = this.getValueExcel(columns[month].SubColumns, true);
                          } else if ((col - 1) % mod == 3) {
                            text = this.getPercExcel(columns[month].SubColumns, true);
                          } else if ((col - 1) % mod == 4) {
                            text = this.getDiffExcel(columns[month].SubColumns);
                          }

                          columnsValues.push(`<c t="n" r="${numToAlpha(effectiveColIndex++)}${rowCount}" s="64"><v>${text}</v></c>`);
                        }
                      }

                      const childRow = `<row r="${rowCount}">
                                          <c t="inlineStr" r="A${rowCount}" s="52"><is><t>${child.Name.replace('&', 'o')}</t></is></c>
                                          ${columnsValues.join(' ')}
                                        </row>`;

                      // Append row to sheetData.
                      try {
                        $('sheetData', sheet).append(childRow);
                        // Allineo la child row a destra
                        //$('row:nth-child(' + rowCount + ') c', sheet).attr('s', '52');
                        rowCount++; // Inc excelt row counter.                                           
                      } catch (error) {
                        console.log(error, childRow);
                      }
                    }
                  }

                  DT_row++;

                  // Just append the header row and increment the excel row counter.
                } else {
                  var row: any = $(element);
                  row = row[0].outerHTML;
                  $('sheetData', sheet).append(row);
                  rowCount++;
                }
              });

              this.expandRows = false
            },
            action: function (e, dt, node, config) {
              var self = this;
              var currentPageLen = dt.page.len();
              var currentPage = dt.page.info().page;
              var totRecords = dt.page.info().recordsTotal
              dt.one('draw', () => {
                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                setTimeout(function () {
                  dt.page.len(currentPageLen).draw(); //set page length
                  dt.page(currentPage).draw('page'); //set current page
                });
              });
              dt.page.len(totRecords).draw();
            }
          }
        ]
      };

      this.loaderService.show();
      this.ref.detectChanges();
      this.table = $('#ceTable_c').DataTable(dtOptions);

      // Manually initialize the Buttons extension
      new $.fn.dataTable.Buttons(this.table, {
        buttons: dtOptions.buttons
      });

      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });

      if (this.currentFilterCfg['Companies'] && this.currentFilterCfg['Companies'].length > 0) {
        this.refreshColumnsVisibility(this.currentFilterCfg['Companies'], 'companies');
      }

      // Add event listener for opening and closing details
      $('#ceTable_c tbody').off('click');

      $('#ceTable_c tbody').on('click', 'td.parent-row', (event: any) => {
        const tr = $(event.target).closest('tr');
        const row = this.table.row(tr);

        if (row.child.isShown()) {
          this.lastRowsExpanded = this.lastRowsExpanded.filter((row: any) => row != tr.index());
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('shown');
        } else {

          // Devo fare così perchè con le fixedColumns non riesce a prendere i dati,
          const rowData = tr.data('row');//row.data();
          // Open this row
          if (rowData) {
            if (!this.lastRowsExpanded.includes(tr.index()))
              this.lastRowsExpanded.push(tr.index());

            row.child(this.format(rowData)).show();
            tr.addClass('shown');
          }
        }
        if (!this.showTotals) {
          this.table.columns.adjust().fixedColumns().left(this.getFixedCols());
        } else {
          this.table.columns.adjust();
        }
      });

      $('#ceTable_c tbody').on('click', 'a.add_ce', (event: any) => {
        const tr = $(event.target).closest('tr');
        //const row=this.table.row(tr);
        const data = tr.data('row');

        if (data) {
          this.addCE(data);
        }
      });

      $('#ceTable_c tbody').on('click', 'a.detail_ce', (event: any) => {
        const tr = $(event.target).closest('tr');
        const childrenId = tr.attr('id');
        const parentId = tr.data('rowid');
        const company = $(event.target).closest('a')?.data('company');
        const PLParentItem = this.PLData.find((x: any) => x.Id == parentId);
        const PLItem = PLParentItem.Children.find((x: any) => x.Id == childrenId);

        if (PLItem) {
          this.detailCE(PLItem, company);
        }
      });

      $('#ceTable_c tbody').on('click', '.clickGraph', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data = tr.data('row');
        if (data) {
          this.onClickGraph.emit(data);
        }
      });

    }, 1000);

  }

  filterByPLName($event: KeyboardEvent) {
    if ($event.code == 'Enter') {
      this.table.draw();
    }
  }

  getFixedCols() {
    return this.filters.ck_prevyear ? (this.showTotals ? 6 : 7) : (this.showTotals ? 3 : 4);
  }

  refreshColumnsVisibility(event: any, filter: string) {
    const fixedCols = this.getFixedCols();

    if (this.filters.ck_prevyear) {

      let columns = [4, 5, 6];
      this.filteredCompanyist.forEach((company: any, counter: number) => {
        columns.push((counter * 5) + 9);
        columns.push((counter * 5) + 10);
        columns.push((counter * 5) + 11);
      })

      this.table.columns(columns).visible(this.filters.ck_prevyear);

      if (this.expandAll) {
        this.expandAllRows();
        setTimeout(() => {
          this.expandAllRows();
        }, 100);
      }
    }

    if (this.filters.ck_prevyear.ck_abs) {
      this.table.rows().invalidate().draw();
      let text = '% rel';
      if (event) {
        text = '% abs';
      }
      let columns = [3, 5];
      this.filteredCompanyist.forEach((company: any, counter: number) => {
        columns.push((counter * 5) + 8);
        columns.push((counter * 5) + 10);
      })
      this.table.columns(columns).header().to$().text(text);

    }

    if (this.filters.filterCompanies && this.filters.filterCompanies.length > 0) {
      // Cerco in columnDefsObj tutte le colonne che non abbiano la companyName come quella nel filtro
      const colsToHide = this.columnDefsObj.filter((colDef: any) => {
        return colDef.companyName && !this.filters.filterCompanies.includes(colDef.companyName);
      }).map((col: any) => col.targets);

      const colsToShow = this.columnDefsObj.filter((colDef: any) => {
        let show = colDef.companyName && this.filters.filterCompanies.includes(colDef.companyName);
        // Verifico che se non è spuntata la checkbox per l'anno precedente devo tenerli nascosti
        if (!this.filters.ck_prevyear) {
          const mod = (colDef.targets - 1) % 5;
          if (![1, 2].includes(mod)) {
            show = false
          }
        }
        return show

      }).map((col: any) => col.targets);

      if (colsToHide && colsToHide.length > 0) {
        this.table.columns(colsToHide).visible(false);
      }

      if (colsToShow && colsToShow.length > 0) {
        this.table.columns(colsToShow).visible(true);
      }

    } else {
      this.table.rows().invalidate().draw();
    }

    $('table td').removeClass('last-fixed-col-left');
    if (this.filters.ck_prevyear) {
      $('table td.dtfc-fixed-left:nth-child(7)').addClass('last-fixed-col-left');
    } else {
      $('table td.dtfc-fixed-left:nth-child(4)').addClass('last-fixed-col-left');
    }

    if (!this.showTotals) {
      this.table.columns.adjust().fixedColumns().left(this.getFixedCols());
    } else {
      this.table.columns.adjust();
    }
  }

  expandAll: boolean = false;
  expandAllRows() {
    this.expandAll = !this.expandAll;
    this.lastRowsExpanded = [];
    const trList = $('#ceTable_c tbody tr');
    trList.each((index: number, tr: any) => {
      const row = this.table.row(tr);

      if (!this.expandAll) {
        // This row is already open - close it
        row.child.hide();
        $(tr).removeClass('shown');
      } else {

        if (!this.lastRowsExpanded.includes(index))
          this.lastRowsExpanded.push(index);

        // Open this row
        const data = $(tr).data('row');
        if (data) {
          row.child(this.format(row.data())).show();
          $(tr).addClass('shown');
        }
      }
    });

    if (!this.showTotals) {
      this.table.columns.adjust().fixedColumns().left(this.getFixedCols());
    } else {
      this.table.columns.adjust();
    }

    setTimeout(() => {
      this.refreshColumnsVisibility('', '');
    }, 100);

  }

  getValue(values: any, prev: boolean = false) {
    if (this.filters.ck_lordo) {
      return prev ? this.utility.formatNumber(values.PreviousGrossValue, this.locale) : this.utility.formatNumber(values.CurrentGrossValue, this.locale)
    } else {
      return prev ? this.utility.formatNumber(values.PreviousValue, this.locale) : this.utility.formatNumber(values.CurrentValue, this.locale)
    }
  }

  getPerc(values: any, prev: boolean = false) {
    if (this.filters.ck_lordo) {
      if (this.filters.ck_abs) {
        if (this.filters.percTotale) {
          return prev ? this.utility.formatNumber(values.PreviousValuePercAbsGrossTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsGrossTotal, this.locale)
        }
        else {
          return prev ? this.utility.formatNumber(values.PreviousValuePercAbsGross, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsGross, this.locale)
        }

      } else {
        if (this.filters.percTotale) {
          return prev ? this.utility.formatNumber(values.PreviousValuePercRelGrossTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelGrossTotal, this.locale)
        }
        else {
          return prev ? this.utility.formatNumber(values.PreviousValuePercRelGross, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelGross, this.locale)
        }

      }
    } else {
      if (this.filters.ck_abs) {
        if (this.filters.percTotale) {
          return prev ? this.utility.formatNumber(values.PreviousValuePercAbsTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsTotal, this.locale)
        }
        else {
          return prev ? this.utility.formatNumber(values.PreviousValuePercAbs, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbs, this.locale)
        }

      } else {
        if (this.filters.percTotale) {
          return prev ? this.utility.formatNumber(values.PreviousValuePercRelTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelTotal, this.locale)
        }
        else {
          return prev ? this.utility.formatNumber(values.PreviousValuePercRel, this.locale) : this.utility.formatNumber(values.CurrentValuePercRel, this.locale)
        }

      }
    }
  }

  getDiff(values: any) {
    if (this.filters.ck_lordo) {
      if (this.filters.scostamento) {
        return this.utility.formatNumber(values.PreviousGrossDeviation, this.locale)
      } else {
        return this.utility.formatNumber(values.PreviousGrossDelta, this.locale)
      }
    } else {
      if (this.filters.scostamento) {
        return this.utility.formatNumber(values.PreviousDeviation, this.locale)
      } else {
        return this.utility.formatNumber(values.PreviousDelta, this.locale)
      }
    }
  }

  getValueExcel(values: any, prev: boolean = false) {
    if (this.filters.ck_lordo) {
      return prev ? this.utility.parseNumber(values.PreviousGrossValue, this.locale, 2) : this.utility.parseNumber(values.CurrentGrossValue, this.locale, 2)
    } else {
      return prev ? this.utility.parseNumber(values.PreviousValue, this.locale, 2) : this.utility.parseNumber(values.CurrentValue, this.locale, 2)
    }
  }

  getPercExcel(values: any, prev: boolean = false) {
    if (this.filters.ck_lordo) {
      if (this.filters.ck_abs) {
        if (this.filters.percTotale) {
          return prev ? this.utility.parseNumber(values.PreviousValuePercAbsGrossTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsGrossTotal, this.locale, 2)
        }
        else {
          return prev ? this.utility.parseNumber(values.PreviousValuePercAbsGross, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsGross, this.locale, 2)
        }

      } else {
        if (this.filters.percTotale) {
          return prev ? this.utility.parseNumber(values.PreviousValuePercRelGrossTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelGrossTotal, this.locale, 2)
        }
        else {
          return prev ? this.utility.parseNumber(values.PreviousValuePercRelGross, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelGross, this.locale, 2)
        }

      }
    } else {
      if (this.filters.ck_abs) {
        if (this.filters.percTotale) {
          return prev ? this.utility.parseNumber(values.PreviousValuePercAbsTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsTotal, this.locale, 2)
        }
        else {
          return prev ? this.utility.parseNumber(values.PreviousValuePercAbs, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbs, this.locale, 2)
        }

      } else {
        if (this.filters.percTotale) {
          return prev ? this.utility.parseNumber(values.PreviousValuePercRelTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelTotal, this.locale, 2)
        }
        else {
          return prev ? this.utility.parseNumber(values.PreviousValuePercRel, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRel, this.locale, 2)
        }

      }
    }
  }

  getDiffExcel(values: any) {
    if (this.filters.ck_lordo) {
      if (this.filters.scostamento) {
        return this.utility.parseNumber(values.PreviousGrossDeviation, this.locale, 2)
      } else {
        return this.utility.parseNumber(values.PreviousGrossDelta, this.locale, 2)
      }
    } else {
      if (this.filters.scostamento) {
        return this.utility.parseNumber(values.PreviousDeviation, this.locale, 2)
      } else {
        return this.utility.parseNumber(values.PreviousDelta, this.locale, 2)
      }
    }
  }

  checkHideRow(data: any): boolean {
    if (this.filters.ck_hide0) {
      // prendo il totaler per la riga
      const tot = data?.Columns[0]?.SubColumns;
      if (tot) {

        let hideRowCurrent = this.filters.ck_lordo ? tot.CurrentValue == 0 : tot.CurrentGrossValue == 0;
        let hideRowPrev = this.filters.ck_lordo ? tot.PreviousValue == 0 : tot.PreviousGrossValue == 0;

        // Se ho l'anno precedente e l'attuale
        const hideRow = hideRowCurrent && ((this.filters.ck_prevyear && hideRowPrev) || !this.filters.ck_prevyear);

        if (hideRow) {
          return true;
        }
      }
    }
    return false;
  }

  /* Formatting function for row details - modify as you need */
  format(row: any) {
    if (!row) return;
    let subRows: string = '';

    const col0Style = '';
    const col1Style = '';
    const col2Style = '';
    const col3Style = '';
    const col4Style = '';
    const col5Style = '';
    const col6Style = '';

    row.Children.forEach((child: any) => {

      const hideRow = this.checkHideRow(child);
      if (hideRow) {
        return;
      }

      const columns = child.Columns;

      subRows += `<tr id='${child.Id}' data-rowid='${row.Id}' class="children" data-row='${JSON.stringify(columns[0].SubColumns)}'>`;
      // Icona +/-
      subRows += '<td class="dtfc-fixed-left" style="' + col0Style + '"></td>';
      // Nome sottocategoria CE
      subRows += `<td class="text-right dtfc-fixed-left" style="${col1Style}" data-categoryname="${child.Name}">
                            <div class="display-flex-space-between">
                                <div class="actions">
                                    <a href="javascript:;" class="detail_ce"><i class="bi bi-search"></i></a>
                                </div> 
                                <span class="clickGraph">
                                    ${child.Name}
                                </span>
                            </div>
                        </td>`;
      // Totale
      subRows += '<td class="text-right dtfc-fixed-left" style="' + col2Style + '">' + this.getValue(columns[0].SubColumns) + ' </td>';
      // Totale %
      if (this.table.column(6).visible()) {
        subRows += '<td class="text-right" style="' + col3Style + '">' + this.getPerc(columns[0].SubColumns) + ' </td>';
      } else {
        subRows += '<td class="text-right last-fixed-col-left" style="' + col3Style + '">' + this.getPerc(columns[0].SubColumns) + ' </td>';
      }
      //Totale prev
      if (this.table.column(4).visible()) {
        subRows += '<td class="text-right dtfc-fixed-left" style="' + col4Style + '">' + this.getValue(columns[0].SubColumns, true) + '</td>';
      }
      //Totale prev %
      if (this.table.column(5).visible()) {
        subRows += '<td class="text-right dtfc-fixed-left" style="' + col5Style + '">' + this.getPerc(columns[0].SubColumns, true) + '</td>';
      }
      // Diff Totale
      if (this.table.column(6).visible()) {
        subRows += '<td class="text-right last-fixed-col-left" style="' + col6Style + '">' + this.getDiff(columns[0].SubColumns) + ' </td>';
      }

      this.filteredCompanyist.forEach((company: any, counter: number) => {
        const index = columns.findIndex((c: any) => c.Group == company.Name);
        const values = columns[index].SubColumns;
        // Verifico se le colonne sono visibili (es. filtro per company, filtro per anno precedente)
        if (this.table.column((counter * 5) + 7).visible()) {
          subRows += '<td class="text-right">';
          subRows += '    <div class="display-flex-space-between">';
          subRows += '        <div class="actions">';
          subRows += '            <a href="javascript:;" class="detail_ce" data-company="' + company.Name + '"><i class="bi bi-search"></i></a>';
          subRows += '        </div> ';
          subRows += this.getValue(values);
          subRows += '    </div>';
          subRows += '</td>';
        }
        if (this.table.column((counter * 5) + 8).visible()) {
          subRows += '<td class="text-right">' + this.getPerc(values) + ' </td>';
        }
        if (this.table.column((counter * 5) + 9).visible()) {
          subRows += '<td class="text-right">' + this.getValue(columns[index].SubColumns, true) + '</td>';
        }
        if (this.table.column((counter * 5) + 10).visible()) {
          subRows += '<td class="text-right">' + this.getPerc(columns[index].SubColumns, true) + '</td>';
        }
        if (this.table.column((counter * 5) + 11).visible()) {
          subRows += '<td class="text-right">' + this.getDiff(columns[index].SubColumns) + ' </td>';
        }
      });

      subRows += '</tr>';
    });

    return $(subRows);
  }

  async addCE(item: any) {
    this.dialog
      .open(ContoEconomicoEditComponent, {
        width: '800px',
        data: {
          item: item
        }
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          this.table.draw();
          setTimeout(() => {
            const element: any = document.getElementById(item.Id);
            element.scrollIntoView();
            this.ref.detectChanges();
          }, 500);
        }
      });
  }

  async detailCE(item: any, company: string = '') {

    let filter = { ...this.currentFilterCfg };

    if (company) {
      filter['Companies'] = [company];
    } else {
      delete filter['Companies'];
    }

    this.dialog
      .open(ContoEconomicoDetailsComponent, {
        data: {
          item: item,
          currentFilterCfg: filter,
          locale: this.locale,
          training: this.training
        },
        width: '1200px'
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          this.table.draw();
          setTimeout(() => {
            const element: any = document.getElementById(item.Id);
            element.scrollIntoView();
            this.ref.detectChanges();
          }, 500);
        }
      });
  }

  exportAsXLSX(expandRows: any) {
    this.expandRows = expandRows === true;
    this.table.button(1).trigger();
  }

  exportAsPDF(PDFPreferences: any) {
    this.PDFPreferences = PDFPreferences;
    this.expandRows = PDFPreferences.explode;
    this.table.button(0).trigger();
  }

  performShowTotals(forcedValue: boolean = false) {
    this.showTotals = forcedValue ? forcedValue : !this.showTotals;
    if(this.table) this.table.column(0).visible(!this.showTotals);
    if (!forcedValue) { // Fa già l'initDatatable dal Subscription del filterService
      this.table.draw();
    }
  }
}

