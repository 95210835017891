import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export class PLNode {
  name: string = '';
  id: string = '';
  selected: boolean = false;
  indeterminate: boolean = false;
  percentage: number = 0;
  children: PLNode[] = [];
  parent?: PLNode;

  constructor(data: any) {
    this.name = data.Name;
    this.id = data.Id;
    this.selected = data.selected || false;
    this.indeterminate = data.indeterminate || false;
    this.percentage = data.percentage || 0;
  }
}

@Component({
  selector: 'ce-categories',
  templateUrl: './ce-categories.component.html',
  styleUrls: ['./ce-categories.component.scss']
})
export class CeCategoriesComponent implements OnInit {
  // Input: array di dati grezzi provenienti dall’API
  @Input() rawTreeData: any[] = [];
  // Output: emette l’elenco (piatto) dei nodi selezionati
  @Output() selectionChanged = new EventEmitter<PLNode[]>();

  treeData: PLNode[] = [];
  // Set per tenere traccia dei nodi espansi
  expandedNodes = new Set<string>();

  constructor() {
    console.log('CeCategoriesComponent constructor');
  }

  ngOnInit(): void {
    // Costruisci l'albero e imposta i parent
    this.treeData = this.buildTree(this.rawTreeData);
    this.treeData.forEach(node => this.setParent(node, null));
    // Aggiorna subito lo stato dei genitori in base ai figli (tri-state)
    this.recursiveUpdateParents(this.treeData);
    // Espandi automaticamente tutti i rami che portano a nodi selezionati o in stato indeterminato
    this.expandSelectedNodes(this.treeData);
  }

  // Converte l'array di oggetti ricevuto dall'API in una struttura ricorsiva di PLNode
  buildTree(items: any[]): PLNode[] {
    return items.map(item => {
      const node = new PLNode(item);
      if (item.Children && item.Children.length > 0) {
        node.children = this.buildTree(item.Children);
      }
      return node;
    });
  }

  // Imposta ricorsivamente il parent per ogni nodo
  setParent(node: PLNode, parent: PLNode | null): void {
    node.parent = parent || undefined;
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => this.setParent(child, node));
    }
  }

  // Funzione ricorsiva per aggiornare lo stato dei genitori in base ai figli
  recursiveUpdateParents(nodes: PLNode[]): void {
    nodes.forEach(node => {
      if (node.parent) {
        this.updateParent(node.parent);
      }
      if (node.children && node.children.length > 0) {
        this.recursiveUpdateParents(node.children);
      }
    });
  }

  // Aggiorna lo stato (selected/indeterminate) del nodo genitore in base allo stato dei figli
  updateParent(node: PLNode | undefined): void {
    if (!node) return;
    const total = node.children.length;
    const selectedCount = node.children.filter(child => child.selected).length;
    const indeterminateCount = node.children.filter(child => child.indeterminate).length;

    if (selectedCount === total) {
      node.selected = true;
      node.indeterminate = false;
    } else if (selectedCount === 0 && indeterminateCount === 0) {
      node.selected = false;
      node.indeterminate = false;
    } else {
      node.selected = false;
      node.indeterminate = true;
    }
    // Aggiorna ricorsivamente il genitore
    this.updateParent(node.parent);
  }

  // Funzione per espandere tutti i genitori di un nodo dato
  expandAllParents(node: PLNode | undefined): void {
    if (!node) return;
    if (node.parent) {
      this.expandedNodes.add(node.parent.id);
      this.expandAllParents(node.parent);
    }
  }

  // Itera l'albero e, se un nodo è selezionato o in stato indeterminato, espande i suoi genitori
  expandSelectedNodes(nodes: PLNode[]): void {
    nodes.forEach(node => {
      if (node.selected || node.indeterminate) {
        this.expandAllParents(node);
      }
      if (node.children && node.children.length > 0) {
        this.expandSelectedNodes(node.children);
      }
    });
  }

  // Toggle per espandere o collassare un nodo
  toggleExpand(node: PLNode): void {
    if (this.expandedNodes.has(node.id)) {
      this.expandedNodes.delete(node.id);
    } else {
      this.expandedNodes.add(node.id);
    }
  }

  isExpanded(node: PLNode): boolean {
    return this.expandedNodes.has(node.id);
  }

  // Gestione del cambio di selezione (checkbox)
  onToggleSelection(event: any, node: PLNode): void {
    const checked = event.target?.checked;
    node.selected = checked;
    if (node.children && node.children.length > 0) {
      this.toggleSelectChildren(node, checked);
    }
    this.updateParent(node.parent);
    this.emitSelection();
  }

  // Propaga la selezione a tutti i figli
  toggleSelectChildren(node: PLNode, selected: boolean): void {
    node.children.forEach(child => {
      child.selected = selected;
      child.indeterminate = false;
      if (child.children && child.children.length > 0) {
        this.toggleSelectChildren(child, selected);
      }
    });
  }

  // Quando il valore della percentuale viene modificato, lo salva nel nodo
  onPercentageChange(newVal: any, node: PLNode): void {
    node.percentage = parseFloat(newVal) || 0;
    this.emitSelection();
  }

  // Raccoglie ricorsivamente tutti i nodi selezionati
  getSelectedNodes(nodes: PLNode[]): PLNode[] {
    let result: PLNode[] = [];
    nodes.forEach(node => {
      if (node.selected) {
        result.push(node);
      }
      if (node.children && node.children.length > 0) {
        result = result.concat(this.getSelectedNodes(node.children));
      }
    });
    return result;
  }

  // Emette l’elenco dei nodi selezionati tramite l’output selectionChanged
  emitSelection(): void {
    const selectedNodes: PLNode[] = this.getSelectedNodes(this.treeData);
    this.selectionChanged.emit(selectedNodes);
  }

  // Nuova funzione per effettuare lo scroll verso un nodo identificato dall'id
  scrollToNodeById(id: string): void {
    const element = document.getElementById("node-" + id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
