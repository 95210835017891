import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { RoleService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { ContoEconomicoService } from '../conto-economico.service';
import * as util from '@app/core/services/utilityfunctions';
import { TranslateService } from '@ngx-translate/core';
import { ContoEconomicoEditComponent } from '../conto-economico-edit/conto-economico-edit.component';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import moment from 'moment';
import { PDFService } from '@app/core/services/pdf.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

declare var window: any;

@Component({
  selector: 'kt-conto-economico-details',
  templateUrl: './conto-economico-details.component.html',
  styleUrls: ['./conto-economico-details.component.scss']
})
export class ContoEconomicoDetailsComponent implements OnInit {

  plItem: any;
  currentFilterCfg: any;
  utility: any;
  locale!: string;
  table: any;
  totRows!: number;
  PDFPreferences: any;

  SendingId: any = null;

  totAmount!: number;
  totDiscount!: number;
  totVat!: number;

  filterManual: number = 0;

  showColumnsFilters: boolean = false;
  filters: any = {
    Company: undefined,
    Date: '',
    Supplier: undefined,
    CostCenter: undefined,
    Document: '',
    Amount: '',
    Vat: '',
    Discount: '',
    Description: ''
  }

  pagination: any = {
    TotalRows: 0,
    pageSize: 10,
    pageIndex: 0
  }

  constructor(
    private contoEconomicoService: ContoEconomicoService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public staticCollectionsService: StaticCollectionsService,
    private switchGroupService: SwitchGroupService,
    private localizedDateTimePipe: LocalizedDateTimePipe,
    private translate: TranslateService,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private roleService: RoleService,
    private translationService: TranslationService,
    private pdfService: PDFService,
    private router: Router,
    private magazzinoService: MagazzinoService,
    private ref: ChangeDetectorRef,
    private gestioneMerciService: GestioneMerciService,
    public loaderService: LoaderService
  ) {
    this.utility = util;
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.plItem = this.data.item;
    this.currentFilterCfg = this.data.currentFilterCfg;
    this.locale = this.data.locale;
    this.SendingId = this.data.SendingId;

    this.initDataTable();

    $.fn.dataTable.ext.order['localized_date'] = (settings, col) => {
      if (!this.table) return false;
      return this.table.column(col, { order: 'index' }).nodes().map(function (td, i) {
        const date: any = $(td).html();
        const rest = moment(date, 'DD/MM/YYYY').unix();
        return rest;
      });
    }
  }

  changePagination(event: any) {
    if (this.pagination.pageSize != event.pageSize) {
      this.pagination.pageSize = event.pageSize;
      this.pagination.pageIndex = 0;
      this.table.page.len(this.pagination.pageSize).draw();
      this.table.page(0).draw('page');
    } else if (this.pagination.pageIndex != event.pageIndex) {
      this.pagination.pageIndex = event.pageIndex;
      this.table.page(this.pagination.pageIndex).draw('page');
    }
  }

  filter() {
    this.table.draw();
  }

  getTitle() {
    return this.plItem.Name;
  }

  exportAsXLSX() {
    const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
    const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
    const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
    const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
    const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(expand => {
      if (expand) this.table.button(1).trigger();
    });
  }

  exportAsPDF(expand: boolean = false) {

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
    };

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {

      if (result) {
        result['header'] = {
          export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
          period: ''
        };
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        };
        result['language'] = this.translationService.getSelectedLanguage();

        this.PDFPreferences = result;

        this.table.button(0).trigger();
      };
    });
  }

  performShowFiltersColumns(event: any) {
    if (!event.checked) {
      $('.dataTables_scrollHeadInner thead tr:nth-child(2)').hide();
    } else {
      $('.dataTables_scrollHeadInner thead tr:nth-child(2)').show();
    }
    setTimeout(() => {
      this.table.columns.adjust();
    }, 100);
  }

  clearFilters() {
    this.filters = {
      Company: undefined,
      Date: '',
      Supplier: undefined,
      CostCenter: undefined,
      Document: '',
      Amount: '',
      Vat: '',
      Discount: '',
      Description: ''
    }
    this.filter();
  }

  firstTime: boolean = true;
  initDataTable() {
    if ($.fn.dataTable.isDataTable('#pl_detail')) {
      $('#pl_detail').DataTable().destroy();
    }

    setTimeout(() => {
      const currentLogin = this.switchGroupService.getCurrentGroup();

      const dtOptions: any = {
        orderCellsTop: true,
        destroy: true,
        paging: true,
        searching: true,
        //dom: 'rtlp',
        dom: 'rt<"paginator">',
        ordering: true,
        scrollX: true,
        //scrollY: 'calc(100vh - 300px)',
        scrollY: '300px',
        scrollCollapse: true,
        autoWidth: false,
        order: [[1, 'desc']],
        processing: true,
        serverSide: true,
        drawCallback: () => {
          if (!this.showColumnsFilters) {
            $('.dataTables_scrollHeadInner thead tr:nth-child(2)').hide();
          } else {
            $('.dataTables_scrollHeadInner thead tr:nth-child(2)').show();
          }
        },
        rowCallback: (row, data, index) => {
          if ((this.filterManual === 1 && !data.IsManual) || (this.filterManual === 2 && data.IsManual)) {
            $(row).hide();
          } else if (this.filters.Company && data.Store !== this.filters.Company) {
            $(row).hide();
          } else if (this.filters.Date && moment(data.Date).format('yyyymmdd') !== moment(this.filters.Date).format('yyyymmdd')) {
            $(row).hide();
          } else if (this.filters.Supplier && data.SupplierId != this.filters.Supplier) {
            $(row).hide();
          } else if (this.filters.CostCenter && data.CostCenterId != this.filters.CostCenter) {
            $(row).hide();
          } else if (this.filters.Document && data.DocNum.toLowerCase().indexOf(this.filters.Document) == -1) {
            $(row).hide();
          } else if (this.filters.Amount && this.utility.parseNumber(data.Amount, this.locale, 2).toString().indexOf(this.utility.parseNumber(this.filters.Amount, this.locale, 2).toString()) == -1) {
            $(row).hide();
          } else if (this.filters.Discount && this.utility.parseNumber(data.Discount, this.locale, 2).toString().indexOf(this.utility.parseNumber(this.filters.Discount, this.locale, 2).toString()) == -1) {
            $(row).hide();
          } else if (this.filters.Vat && this.utility.parseNumber(data.VAT, this.locale, 2).toString().indexOf(this.utility.parseNumber(this.filters.Vat, this.locale, 2).toString()) == -1) {
            $(row).hide();
          } else if (this.filters.Description && data.Description.toLowerCase().indexOf(this.filters.Description) == -1) {
            $(row).hide();
          } else {
            this.totAmount += data.Amount;
            this.totDiscount += data.Discount;
            this.totVat += data.VAT;
            this.totRows++;
          }
        },
        ajax: {
          url: currentLogin.endpointURL + "/api/PL/DetailTable",
          type: "POST",
          data: (d: any) => {
            this.totAmount = 0;
            this.totDiscount = 0;
            this.totVat = 0;
            this.totRows = 0;
            d.filters = this.currentFilterCfg;
            d.filters.Training = this.data.training;
            d.filters.IsCover = this.plItem.IsCover;
            if (this.firstTime) {
              this.firstTime = false;
              d.order = [{ column: 1, dir: "desc" }]
            }
            if (this.SendingId) {
              d.SendingId = this.SendingId;
            } else {
              d.StructureId = this.plItem.Id;
            }
            if (this.filters.Date) {
              d.filters.DateFilter = {
                Start: moment(new Date(Date.UTC(this.filters.Date.year, this.filters.Date.month - 1, this.filters.Date.day))).format('YYYY-MM-DD'),
                End: moment(new Date(Date.UTC(this.filters.Date.year, this.filters.Date.month - 1, this.filters.Date.day))).format('YYYY-MM-DD')
              }
              delete d.filters.Period;
            }
            return JSON.stringify(d);
          },
          headers: {
            "Authorization": "Bearer " + currentLogin.access_token,
            "Content-Type": "application/json"
          },
          dataSrc: (response) => {
            this.pagination.TotalRows = response.recordsFiltered;
            this.ref.detectChanges();
            return response.data;
          }
        },
        columnDefs: [
          {
            targets: 0, data: 'Store', name: 'Store', render: (data, type, p, meta) => {
              return data ? `<div class="overflow-ellipses exportText" title="${data}">${data}</div>` : '';
            }
          },
          {
            targets: 1, data: 'Date', name: 'Date', render: (data, type, p, meta) => {
              return this.localizedDateTimePipe.transform(data, undefined, true);
            },
            orderDataType: 'localized_date',
            width: '100px'
          },
          {
            targets: 2, data: 'SupplierName', name: 'SupplierName', render: (data, type, p, meta) => {
              return data ? `<div class="overflow-ellipses-110 exportText" title="${data}">${data}</div>` : '';
            }, width: '100px', visible: !this.plItem.IsCover
          },
          {
            targets: 3, data: 'PLCategory', name: 'PLCategory', render: (data, type, p, meta) => {
              return data ? `<div class="overflow-ellipses-100 exportText" title="${data}">${data}</div>` : '';
            }, width: '100px', visible: (this.SendingId && this.SendingId.length > 0) ? true : false
          },
          {
            targets: 4, data: 'CostCenterId', name: 'CostCenterId',
            render: (data, type, p, meta) => {
              const cc = this.staticCollectionsService.costCenters$.find((c: any) => c.Id == data);
              if (cc) {
                return cc.Name;
              } else {
                return '';
              }
            }
          },
          {
            targets: 5, data: 'DocNum', name: 'DocNum', render: (data, type, p, meta) => {
              return data ? `<div class="overflow-ellipses exportText" title="${data}">${data}</div>` : '';
            }, visible: !this.plItem.IsCover
          },
          {
            targets: 6, data: 'Amount', name: 'Amount',
            class: 'text-right',
            render: (data) => {
              return this.utility.formatNumber(data, this.locale);
            }
          },
          {
            targets: 7, data: 'Discount', name: 'Discount',
            class: 'text-right',
            render: (data) => {
              return this.utility.formatNumber(data, this.locale);
            }, visible: !this.plItem.IsCover
          },
          {
            targets: 8, data: 'VAT', name: 'VAT',
            class: 'text-right',
            render: (data) => {
              return this.utility.formatNumber(data, this.locale);
            }, visible: !this.plItem.IsCover
          },

          { targets: 9, data: 'Description', name: 'Description', visible: false },

          {
            targets: 10,
            class: 'text-right actions',
            render: (data, type, p, meta) => {

              const addCe = p && p.IsManual && this.roleService.hasPermission('EditCE') ? '&nbsp;<a href="javascript:;" class="text-primary edit_item"><i class="bi bi-pencil-square"></i></a>' : '';
              const deleteCe = p && p.IsManual && this.roleService.hasPermission('DeleteCE') ? '&nbsp;<a href="javascript:;" class="text-danger delete_item"><i class="bi bi-trash"></i></a>' : '';

              let goToWareHouse = '';
              // Se ho un acquisto associato
              if (p.HasPurchases != '') {
                goToWareHouse = `<a href="javascript:;" class="ms-1 goToWareHouse"><i class="actionBtn bi bi-basket text-primary pointer" name="goToWareHouse" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.GO_TO_PURCHASES')}"></i></a>`;
              }

              let openInvoiceXml = '', goToInvoices = '';
              // Se ho un documento valido
              if (p.DocumentFileName) {
                if (p.DocNum) {
                  goToInvoices = `<a href="javascript:;" class="ms-1 goToInvoices"><i class="actionBtn bi bi-receipt text-primary pointer" name="goToInvoices" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.GO_TO_INVOICE')}"></i></a>`;
                }
                openInvoiceXml = `<a href="javascript:;" class="ms-1 openInvoiceXml"><i class="actionBtn fas bi bi-eye text-primary pointer" name="openInvoiceXml" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.VIEW_INVOICE')}"></i></a>`;
              }

              const notes = `<i class="ms-1 bi bi-sticky pointer ${p.EventNotes && p.EventNotes.length > 0 ? 'active' : ''}" data-toggle="tooltip" title="${p?.EventNotes}"></i>`;

              return `       
                                ${goToWareHouse}
                                ${goToInvoices}
                                ${openInvoiceXml}
                                ${addCe}
                                ${deleteCe}   
                                ${notes}                            
                            `;
            },
            width: '50px'
          },

          // Serve per evitare di riceve avvisi quando arrivano campi a null
          {
            defaultContent: '-',
            targets: '_all'
          }
        ],
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        buttons: [
          {
            className: 'toolbarButton',
            extend: 'pdfHtml5',
            orientation: 'landscape',
            title: this.PDFPreferences?.title,
            pageSize: 'A4',
            download: 'open',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.actions)',
            },
            customize: (doc: any) => {

              const imageLogoB64 = $('#kt_header .logoimg').attr('src');
              const size = {
                width: $('#kt_header .logoimg').width(),
                height: $('#kt_header .logoimg').height()
              };

              let widths: any = [], languages: any = [];
              if (this.plItem.IsCover) {
                widths = ['auto', '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
                languages = [
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.STORE'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.DATE'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.CATEGORY_NAME'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.COST_CENTER'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.NUM_DOC'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.AMOUNT'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.DISCOUNT'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.NOTES')
                ]
              } else {
                widths = ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
                languages = [
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.STORE'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.DATE'),
                  this.translate.instant('GESTIONE_FATTURE.COLUMNS.SUPPLIER'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.CATEGORY_NAME'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.COST_CENTER'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.NUM_DOC'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.AMOUNT'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.DISCOUNT'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.VAT'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.NOTES')
                ]
              }

              const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, widths, '', imageLogoB64, size);
              doc.content[0] = docDefinition.content[0];
              doc.content[1].layout = docDefinition.content[1].layout;
              doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
              doc.footer = docDefinition.footer;
              doc.header = docDefinition.header;
              doc.pageMargins = docDefinition.pageMargins;
              doc.pageOrientation = docDefinition.pageOrientation;
              doc.pageSize = docDefinition.pageSize;

              doc.styles = docDefinition.styles;

              // Shif della riga con indice uno in avanti e inseriemnto di una riga vuota
              doc.content[1].table.body.splice(1, 0, [
                { text: this.translate.instant('COMMONS.TOTALS'), colSpan: 5, alignment: 'left', style: 'tableHeader' },
                {},
                {},
                {},
                {},
                { text: this.utility.parseNumber(this.totAmount, this.locale), alignment: 'right', style: 'tableHeader' },
                { text: this.utility.parseNumber(this.totDiscount, this.locale), alignment: 'right', style: 'tableHeader' },
                { text: this.utility.parseNumber(this.totVat, this.locale), alignment: 'right', style: 'tableHeader' }
              ]);

              doc.content[1].table.body.forEach((line, i) => {
                const length = doc.content[1].table.body[i].length;
                doc.content[1].table.body[i][length - 3].alignment = 'right';
                doc.content[1].table.body[i][length - 2].alignment = 'right';
                doc.content[1].table.body[i][length - 1].alignment = 'right';

                // Aggiungo l'ultima colonna per stampare il campo Notes
                if (i == 0) {
                  doc.content[1].table.body[i].push({ text: this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.NOTES'), alignment: 'left', style: 'tableHeader' });
                } else if (i == 1) {
                  doc.content[1].table.body[i].push({ text: '', alignment: 'left', style: 'tableHeader' });
                } else {
                  const bodyStyle = i % 2 != 0 ? 'tableBodyEven' : 'tableBodyOdd';
                  // Siccome non hoil campo notes dentro a  doc.content[1].table.body devo prenderlo dalla tabella
                  const note = this.table.row(i - 1).data()?.EventNotes;
                  doc.content[1].table.body[i].push({ text: note, alignment: 'left', style: bodyStyle });
                }
              });
            }
          },
          {
            className: 'toolbarButton',
            extend: 'excelHtml5',
            exportOptions: {
              modifier: {
                order: 'current',
                page: 'all',
                selected: null,
              },
              columns: ':visible:not(.actions)',
              format: {
                header: (data, column, row) => {
                  if (this.SendingId && this.SendingId.length > 0 && column > 6) {
                    return this.utility.parseNumber(data, this.locale, 2)
                  } else if (!(this.SendingId && this.SendingId.length > 0) && column > 5) {
                    return this.utility.parseNumber(data, this.locale, 2)
                  } else {
                    return data;
                  }
                },
                body: (data, row, column, node) => {
                  let value = '';
                  if (data.indexOf('exportText') >= 0) {
                    var parser = new DOMParser();
                    var htmlDoc = parser.parseFromString(data, 'text/html');
                    value = $(htmlDoc).find('.exportText').text();
                  } else {
                    value = data
                  }

                  return column >= 5 ? this.utility.parseNumber(value, this.locale, 2) : value;
                }
              },
              orthogonal: 'export'
            },
            customize: async (xlsx: any) => {
              // Get sheet.
              var sheet = xlsx.xl.worksheets['sheet1.xml'];

              const totColumns = this.SendingId && this.SendingId.length > 0 ? 9 : 8;

              const numToAlpha = (num: number) => {
                var alpha = '';
                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                  alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                }
                return alpha;
              }

              let languages: any = [];
              if (this.plItem.IsCover) {
                languages = [
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.STORE'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.DATE'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.CATEGORY_NAME'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.COST_CENTER'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.NUM_DOC'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.AMOUNT'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.DISCOUNT'),
                ]
              } else {
                languages = [
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.STORE'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.DATE'),
                  this.translate.instant('GESTIONE_FATTURE.COLUMNS.SUPPLIER'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.CATEGORY_NAME'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.COST_CENTER'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.NUM_DOC'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.AMOUNT'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.DISCOUNT'),
                  this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.VAT')
                ]
              }

              // Faccio un clone della prima riga che contiene i totali
              // e gli inserisco i titoli delle colonne      
              let header = '<row r="2">';
              let indexCol = 0;
              for (let index = 0; index < totColumns; index++, indexCol++) {
                let text = '';

                if (indexCol > 2 && !(this.SendingId && this.SendingId.length > 0)) {
                  indexCol = index + 1;
                }

                text = languages[indexCol];

                header += `<c t="inlineStr" r="${numToAlpha(index)}2" s="2"><is><t xml:space="preserve">${text}</t></is></c>`;
              }

              // Aggiungere una colonna per stampare il campo Notes
              header += `<c t="inlineStr" r="${numToAlpha(indexCol - 1)}2" s="2"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DETAILS.COLUMNS.NOTES')}</t></is></c>`;

              header += '</row>';

              var sheetData = $('sheetData', sheet).clone();

              // Clear the current sheet data for appending rows.
              $('sheetData', sheet).empty();

              // Sposto in avanti la numerazione delle righe, siccome la riga 2 c'erano i totali, 
              // io ho messo gli headers, allora sposto tutto avanti di 1
              let rowCount = 3;
              sheetData.children().each((indexRow: number, row: any) => {
                const row$ = $(row);
                if (indexRow == 0) {
                  $('sheetData', sheet).append(row$[0].outerHTML);
                  $('sheetData', sheet).append(header);
                  return;
                } else if (indexRow == 1) {
                  // Aggiungo i totali nelle colonne F, G, H
                  const totalRow = row$[0].outerHTML;
                  const totalRow$ = $(totalRow);
                  totalRow$.attr('r', rowCount);
                  totalRow$.children().each((indexCol, col) => {
                    var cell = $(col);
                    // Set each cell's row value.
                    var rc: any = cell.attr('r');
                    rc = rc.replace(/\d+$/, "") + rowCount;
                    cell.attr('r', rc);

                    if (indexCol == 5) {
                      cell.find('v').text(this.utility.parseNumber(this.totAmount, this.locale));
                    } else if (indexCol == 6) {
                      cell.find('v').text(this.utility.parseNumber(this.totDiscount, this.locale));
                    } else if (indexCol == 7) {
                      cell.find('v').text(this.utility.parseNumber(this.totVat, this.locale));
                    } else {
                      cell.find('t').text('');
                    }
                  });

                  $('sheetData', sheet).append(totalRow$[0].outerHTML);
                  rowCount++;
                  return;
                }
                row$.attr('r', rowCount);

                // Iterate each cell in the row to change the rwo number.
                row$.children().each((indexCol, col) => {
                  var cell = $(col);
                  // Set each cell's row value.
                  var rc: any = cell.attr('r');
                  rc = rc.replace(/\d+$/, "") + rowCount;
                  cell.attr('r', rc);
                });

                // Aggiungere la colonna Notes
                const notes = row$.attr('EventNotes') ? row$.attr('EventNotes') : '';
                row$.append(`<c t="inlineStr" r="${numToAlpha(indexCol - 1)}${rowCount}" s="2"><is><t xml:space="preserve">${notes}</t></is></c>`);

                $('sheetData', sheet).append(row$[0].outerHTML);

                rowCount++;
              });

              // Nelle colonne F/G/H, dalla riga 3 in avanti importi lo stile 64
              if (this.plItem.IsCover) {
                $(`row c[r^="E3"]`, sheet).attr('s', '64');
                $(`row c[r^="F3"]`, sheet).attr('s', '64');
              } else {
                $(`row c[r^="F3"]`, sheet).attr('s', '64');
                $(`row c[r^="G3"]`, sheet).attr('s', '64');
                $(`row c[r^="H3"]`, sheet).attr('s', '64');
              }

              // stampo in console il file xml
              console.log(sheetData[0].outerHTML);
            }
          }
        ]

      };

      this.table = $('#pl_detail').DataTable(dtOptions);

      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });

      // Add event listener for opening and closing details
      $('#pl_detail tbody').off('click');

      $('#pl_detail tbody').on('click', 'a.edit_item', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data: any = this.table.row(tr).data();
        console.log(data);

        if (data) {
          this.editRow(data);
        }
      });

      $('#pl_detail tbody').on('click', 'a.delete_item', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data: any = this.table.row(tr).data();
        console.log(data);

        if (data) {
          this.deleteRow(data);
        }
      });


      $('#pl_detail tbody').on('click', 'a.goToWareHouse', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data: any = this.table.row(tr).data();
        console.log(data);

        if (data) {

          const filter: any = {
            DateFilter: JSON.stringify({ Start: moment(data.Date).format('YYYY-MM-DD'), End: moment(data.Date).format('YYYY-MM-DD') }),
            OrderOrDocument: data.DocumentNumber,
            ShowDisabled: true,
            ShowInvisible: true
          }

          localStorage.setItem('currentFilterCfg', JSON.stringify(filter))

          this.router.navigate(['/magazzino/acquisti']);

          this.dialogRef.close();

        }
      });

      $('#pl_detail tbody').on('click', 'a.goToInvoices', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data: any = this.table.row(tr).data();
        console.log(data);

        if (data) {

          const filter: any = {
            DateFilter: JSON.stringify({ Start: moment(data.Date).format('YYYY-MM-DD'), End: moment(data.Date).format('YYYY-MM-DD') }),
            Number: data.DocNum,
            //ShowDisabled: true,
            //ShowInvisible: true
          }

          localStorage.setItem('currentFilterCfg', JSON.stringify(filter))

          this.router.navigate(['/gestione-dati/gestione-fatture']);

          this.dialogRef.close();

        }
      });

      $('#pl_detail tbody').on('click', 'a.openInvoiceXml', (event: any) => {
        const tr = $(event.target).closest('tr');
        const data: any = this.table.row(tr).data();

        if (data) {

          this.showXml({
            FileName: data.DocumentFileName
          });

        }
      });

    }, 100);
  }

  popupXml: any;
  async showXml(merce: any) {
    this.gestioneMerciService.openXmlHighlightedByFileName(merce.FileName, "", "")
      .then(() => console.log)
      .catch((error: any) => {
        this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
      })
  }

  async editRow(item: any) {
    this.dialog
      .open(ContoEconomicoEditComponent, {
        width: '800px',
        data: {
          item: item,
          isEdit: true
        }
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          this.updatedRow = true;
          this.table.draw();
          setTimeout(() => {
            const element: any = document.getElementById(item.Id);
            element.scrollIntoView();
            this.ref.detectChanges();
          }, 500);
        }
      });
  }

  updatedRow: boolean = false;
  async deleteRow(item: any) {
    const _title: string = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.TITLE');
    const _description: string = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.DESC');
    const _waitDesciption: string = '';
    const _yesButton = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.BUTTON');
    const _noButton = this.translate.instant('COMMONS.CANCEL');
    const _deleteMessage = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.SUCCESS');
    const _errorMessage = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.ERROR');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(async (confirm: any) => {
      console.log(confirm);
      if (confirm) {
        this.contoEconomicoService.deletePL(item.Id).toPromise()
          .then(() => {
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
            this.table.draw();
            this.updatedRow = true;
          })
          .catch(() => this.layoutUtilsService.showActionNotification(_errorMessage, MessageType.Delete));
      }
    });
  }
}
