import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, RoleService, SplashScreenService, TranslationService } from './core/_base/layout';

import { NavigationService } from '@app/core/services/navigation.service';
import { MenuConfigService } from '@app/core/_base/layout/services/menu-config.service';
import { LoaderService } from './core/services/loader.service';
import { ShowFromYearImageService } from './core/services/show-from-year-image.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[kt-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    // Public properties
    title = 'Metronic';
    loader: boolean = false;
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param translationService: TranslationService
     * @param router: Router
     * @param layoutConfigService: LayoutCongifService
     * @param splashScreenService: SplashScreenService
     */
    constructor(private translationService: TranslationService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private menuConfigService: MenuConfigService,
        private layoutConfigService: LayoutConfigService,
        private splashScreenService: SplashScreenService,
        private navigationService: NavigationService,
        private roleService: RoleService,
        public loaderService: LoaderService,
        private showFromYearImageService: ShowFromYearImageService
    ) {

        // register translations
        //this.translationService.loadTranslations(itLang, enLang, frLang);
        //localStorage.removeItem('layoutConfig');
        let currentUser;

        try {
            currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
        } catch (error) {
            currentUser = '';
        }

        const savedIndexGroup = localStorage.getItem('currentIndexGroup');
        let _currentIndexGroup = 0;
        if (savedIndexGroup) {
            _currentIndexGroup = parseInt(savedIndexGroup);
        }

        const currentGroup = currentUser && currentUser.Logins ? currentUser.Logins[0] : null;
        if (currentGroup && currentGroup.Groups && currentGroup.Groups.length > 0) {
            this.roleService.checkWritePermission(currentGroup.Roles[0]);
        }


        //if (this.roleService.isSuperAdmin()) {
        //    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        //    var s1 = document.createElement("script");
        //    s1.async = true;
        //    s1.src = 'https://embed.tawk.to/65c21e268d261e1b5f5ce85d/1hlv4bhn1';
        //    s1.charset = 'UTF-8';
        //    s1.setAttribute('crossorigin', '*');
        //    $('body').append(s1);
        //}

    }

    /**
     * @ Lifecycle sequence(s: any) => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {

        // enable/disable loader
        this.loader = this.layoutConfigService.getConfig('loader.enabled');

        const routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('kt-page--loaded');
                }, 500);

                if (localStorage.getItem('currentUser')) {
                    this.navigationService.navigationHistoryChanged.next(event.url);
                }
            }
        });
        this.unsubscribe.push(routerSubscription);

        const subscr = this.menuConfigService.onConfigUpdated$.subscribe(layoutConfig => {
            setTimeout(() => {
                this.ref.detectChanges();
            }, 1000);
        });
        this.unsubscribe.push(subscr);
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.unsubscribe.forEach(sb => sb.unsubscribe());
    }
}
