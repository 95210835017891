import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';

// RXJS
import { BehaviorSubject, lastValueFrom } from 'rxjs';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

// Utilities
import * as util from '@app/core/services/utilityfunctions';
import { SwitchGroupService } from '@app/core/_base/layout';
import _ from 'lodash';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { MergeOrderUnitDialogComponent } from '../merge-order-unit-dialog/merge-order-unit-dialog.component';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';
import { CategorieMerciEditComponent } from '@app/views/pages/anagrafiche/categorie-merci/categorie-merci-edit/categorie-merci-edit.component';

declare var window: any;

@Component({
  selector: 'kt-bulk-edit-new-merci',
  templateUrl: './bulk-edit-new-merci.component.html',
  styleUrls: ['./bulk-edit-new-merci.component.scss']
})
export class BulkEditNewMerciComponent implements OnInit {

  expandAll: boolean = false;

  onChildCreation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  utility: any;
  locale!: string;
  lastRowsExpanded: any = [];
  ProductOrCode: any;
  CategoryIds: any;
  SupplierIds: any;
  allList: boolean = false;

  merciList: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gestioneMerciService: GestioneMerciService,
    private translate: TranslateService,
    public staticCollectionsService: StaticCollectionsService,
    private layoutUtilsService: LayoutUtilsService,
    private translationService: TranslationService,
    private switchGroupService: SwitchGroupService,
    private dialog: MatDialog,
    private anagraficheService: AnagraficheService,
    public loaderService: LoaderService,
    private ref: ChangeDetectorRef,
  ) {
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });
    this.utility = util;
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    this.merciList = JSON.parse(JSON.stringify(this.data.selection));
    this.checkUnitInMerciList();
    this.initTable();
  }

  addEntityItem() {
    const newEntityItem = {
      Id: ''
    };
    this.editEntityItem(newEntityItem);
  }

  editEntityItem(item: any) {
    let saveMessageTranslateParam = 'ANAGRAFICHE.CATEGORIE_MERCI.ACTION_EDIT.';
    saveMessageTranslateParam += item.Id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
    const _saveMessage = this.translate.instant(saveMessageTranslateParam);
    const _messageType = item.Id.length > 0 ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(CategorieMerciEditComponent, { data: { item }, width: '600px' });
    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
      this.loaderService.show();
      await this.staticCollectionsService.fillStaticCollection(true);
      this.loaderService.hide();
      this.ref.detectChanges();

      this.merciList.forEach((merce: any) => {
        if (!merce.GoodsCategoryId || merce.GoodsCategoryId == 0) {
          merce.GoodsCategoryId = res.item.Id;
        }
      });
    });
  }

  // https://mantis.fbmanager.com/view.php?id=1529
  checkUnitInMerciList() {
    this.merciList.forEach((merce: any) => {
      let find = merce.Unit ? this.staticCollectionsService.orderUnit$.find((unit: any) => {
        return unit.Name.toLowerCase() == merce.Unit.toLowerCase().trim() || (unit.Alternate && unit.Alternate.toLowerCase().includes(merce.Unit.toLowerCase().trim()))
      }) : null;
      merce.newUnit = !find && merce.Unit && merce.Unit.length > 0;
      merce.SecondaryUnitId = find ? find.Id : '';
      merce.OtherUnitId = find ? find.Id : '';

      if (!merce.OtherUnitRatio) merce.OtherUnitRatio = 1;
    });
  }

  cleanRow(merce: any) {
    merce.SecondaryUnitId = '';
    merce.OtherUnitId = '';
    merce.OtherUnitRatio = '';
  }

  cleanAllRows() {
    this.merciList.forEach((merce: any) => {
      this.cleanRow(merce);
    });
  }

  initTable() {
    setTimeout(() => {
      const currentLogin = this.switchGroupService.getCurrentGroup();

      let dtOptions: any = {
        destroy: true,
        paging: false,
        searching: false,
        ordering: false,
        scrollY: '400px',
        scrollX: false,
        scrollCollapse: true,
        autoWidth: true,
        order: [[1, 'desc']],
        columnDefs: [
          {
            targets: [0], className: 'max-width-250'
          },
          {
            targets: [1], width: '150px'
          },
          {
            targets: [2], className: 'max-width-250'
          },
          {
            targets: [3], width: '100px'
          },
          {
            targets: [4], width: '100px'
          },
          {
            targets: [5], width: '100px'
          },
          {
            targets: [6], width: '100px'
          },
          {
            targets: [7], width: '100px'
          },
          {
            targets: [8], width: '100px'
          },
          {
            targets: [9], width: '50px', className: 'max-width-50'
          },
          {
            targets: [10], width: '50px', className: 'text-center max-width-50'
          }
        ],
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        }
      };

      this.table = ($('#tableBulkNewMerci') as any).DataTable(dtOptions);
      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });

      this.table.on('page.dt', function () {
        $('.dataTables_scrollBody').animate({
          scrollTop: 0
        }, 'slow');
      });

      window.table = this.table;

      //this.table.columns.adjust().draw();
      setTimeout(() => {
        $('.childTable tbody').off('click');
        $('.childTable tbody').on('click', '.inputNumberController', (event: any) => {
          $(event.target).focus();
          $(event.target).select();
        });
      }, 1000);

      this.ref.detectChanges();
    }, 100);
  }

  addNewUnit(merce: any) {

    const dialogRef = this.dialog.open(MergeOrderUnitDialogComponent, {
      //width: '90%',
      data: {
        merce: merce
      }
    }).afterClosed().subscribe(async (result: any) => {
      if (result && result.success) {
        if (result.action == 'addNewUnit') {
          const _successMessage = this.translate.instant('BULK_EDIT_NEW_MERCI.ADD_NEW_UNIT_DIALOG.MESSAGE');
          lastValueFrom(this.anagraficheService.postEntity('OrderUnit', {
            Name: merce.Unit,
            AlternateName: merce.Unit
          }))
            .then((response: any) => {
              this.layoutUtilsService.showActionNotification(_successMessage, MessageType.Create, 3000, true, false, 3000, 'top', 'snackbar-succes');
              this.staticCollectionsService.orderUnit$.push(response);
              merce.newUnit = false;
              this.checkUnitInMerciList();
              this.table.draw();
            })
            .catch((error: any) => {
              this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
            });
        } else if (result.action == 'mergeUnit') {
          if (!result.orderUnitDestination.Alternate) {
            result.orderUnitDestination.Alternate = merce.Unit;
          } else {
            result.orderUnitDestination.Alternate += ',' + merce.Unit;
          }

          const _successMessage = this.translate.instant('BULK_EDIT_NEW_MERCI.ADD_NEW_UNIT_DIALOG.MESSAGE');
          lastValueFrom(this.anagraficheService.putEntity('OrderUnit', result.orderUnitDestination))
            .then((response: any) => {
              this.layoutUtilsService.showActionNotification(_successMessage, MessageType.Create, 3000, true, false, 3000, 'top', 'snackbar-succes');
              const orderUnitIndex = this.staticCollectionsService.orderUnit$.findIndex((unit: any) => unit.Id == result.orderUnitDestination.Id);
              if (orderUnitIndex >= 0) {
                this.staticCollectionsService.orderUnit$[orderUnitIndex] = result.orderUnitDestination;
              }
              merce.newUnit = false;
              this.checkUnitInMerciList();
              this.table.draw();
            })
            .catch((error: any) => {
              this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
            });

        }
      }
    });

  }

  findUnitIdByOrderUnitName(orderUnitId: string) {
    // Cerco il pz nell'unità base
    const pz = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() == 'pz');

    const orderUnit = this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Id == orderUnitId)
    const unit = orderUnit ? this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase().trim() == orderUnit.Name.toLowerCase().trim()) : '';
    // https://mantis.fbmanager.com/view.php?id=1543
    // Se non trovo nulla imposto pz.
    return unit ? unit.Id : pz.Id;
  }

  table: any;
  performCheck(): boolean {
    let canSave: boolean = true;

    this.merciList.forEach((merce: any) => {
      if (!merce.GoodsCategoryId) {
        $(`tr[data-code="${merce.Code}"] .categoryid`).addClass('invalid');

        canSave = false;
      }
      if (!merce.Description) {
        $(`tr[data-code="${merce.Code}"] .description`).addClass('invalid');
        canSave = false;
      }
      if (!merce.Price) {
        $(`tr[data-code="${merce.Code}"] .price`).addClass('invalid');
        canSave = false;
      }
      if (!merce.SecondaryUnitId) {
        $(`tr[data-code="${merce.Code}"] .secondaryunitid`).addClass('invalid');
        canSave = false;
      }
      if (!merce.SecondaryUnitRatio) {
        $(`tr[data-code="${merce.Code}"] .secondaryunitratio`).addClass('invalid');
        canSave = false;
      }
      if (!merce.OtherUnitId) {
        $(`tr[data-code="${merce.Code}"] .orderunitid`).addClass('invalid');
        canSave = false;
      }
      if (!merce.OtherUnitRatio) {
        $(`tr[data-code="${merce.Code}"] .orderunitratio`).addClass('invalid');
        canSave = false;
      }
      if (!merce.UnitId) {
        $(`tr[data-code="${merce.Code}"] .unitid`).addClass('invalid');
        canSave = false;
      }
    });

    return canSave;
  }

  ubAsUm: boolean = false;
  performUbAsUm() {

    this.merciList.forEach((merce: any) => {
      merce.SecondaryUnitRatio = this.ubAsUm ? merce.OtherUnitRatio : '';
      merce.UnitId = this.ubAsUm ? this.findUnitIdByOrderUnitName(merce.SecondaryUnitId) : '';
    });
  }

  onCategoryChange(event: any, item: any) {
    if (this.allList && item) {
      this.merciList.forEach((e: any) => {
        e.GoodsCategoryId = item.GoodsCategoryId;
      });
      const index = $(event.target).closest('td').index();
      $(`#tableBulkNewMerci td:nth-child(${index + 1}) select`).removeClass('invalid');
    }

    if (this.ubAsUm) {
      item.SecondaryUnitRatio = item.OtherUnitRatio;
      item.UnitId = this.findUnitIdByOrderUnitName(item.SecondaryUnitId);
    }

    $(event.target).removeClass('invalid');
  }

  onUnitChange(event: any, item: any, type: string) {
    item[type] = parseInt(item[type]);
    if (this.allList && item) {
      const unit = type == 'UnitId' ? this.staticCollectionsService.unit$.find((unit: any) => unit.Id == item[type]) : this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Id == item[type]);
      this.merciList.forEach((e: any) => {
        e[type] = unit.Id;
      });
      const index = $(event.target).closest('td').index();
      $(`#tableBulkNewMerci td:nth-child(${index + 1}) select`).removeClass('invalid');
    }

    if (this.ubAsUm) {
      item.SecondaryUnitRatio = item.OtherUnitRatio;
      item.UnitId = this.findUnitIdByOrderUnitName(item.SecondaryUnitId);
    }

    $(event.target).removeClass('invalid');
  }

  onCheckboxChange(event: any, item: any, type: string) {
    if (this.allList && item) {
      this.propagateAllList(type, item);
    }

    if (this.ubAsUm) {
      item.SecondaryUnitRatio = item.OtherUnitRatio;
      item.UnitId = this.findUnitIdByOrderUnitName(item.SecondaryUnitId);
    }
  }

  onInputChange(event: any, item: any, type: string) {
    if (event.keyCode !== 13) {
      if (this.allList && item) {
        this.propagateAllList(type, item);

        const index = $(event.target).closest('td').index();
        $(`#tableBulkNewMerci td:nth-child(${index + 1}) input`).removeClass('invalid');
      }

      if (this.ubAsUm) {
        item.SecondaryUnitRatio = item.OtherUnitRatio;
        item.UnitId = this.findUnitIdByOrderUnitName(item.SecondaryUnitId);
      }
    }
    $(event.target).removeClass('invalid');
  }

  propagateAllList(type: any, item: any) {
    this.merciList.forEach((e: any) => {
      e[type] = item[type];
    });
  }

  popupXml: any;
  async showXml(merce: any) {

    this.gestioneMerciService.openXmlHighlightedByFileName(merce.FileName, merce.Description, merce.Code)
      .then(() => console.log)
      .catch((error: any) => {
        this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
      })

  }

  save() {
    const _title: string = this.translate.instant('BULK_EDIT_NEW_MERCI.SAVE_DIALOG.TITLE');
    const _description: string = this.translate.instant('BULK_EDIT_NEW_MERCI.SAVE_DIALOG.DESCRIPTION');
    const _waitDesciption: string = this.translate.instant('BULK_EDIT_NEW_MERCI.SAVE_DIALOG.WAIT_DESCRIPTION');
    const _success = this.translate.instant('BULK_EDIT_NEW_MERCI.SAVE_DIALOG.MESSAGE');
    const _yesButton = this.translate.instant('BULK_EDIT_NEW_MERCI.SAVE_DIALOG.YESBUTTON');
    const _noButton = this.translate.instant('BULK_EDIT_NEW_MERCI.SAVE_DIALOG.NOBUTTON');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe(async (expand: any) => {
      if (expand) {

        const canSave = this.performCheck();
        if (!canSave) {
          const message = this.translate.instant('BULK_EDIT_NEW_MERCI.MISSING_DATA');
          this.layoutUtilsService.showActionNotification(message, MessageType.Error);
          return;
        }

        this.merciList.forEach((good: any) => {
          good.Price = this.utility.parseNumber(good.Price, this.locale, 4);
          good.OtherUnitRatio = this.utility.parseNumber(good.OtherUnitRatio, this.locale, 3);
          good.SecondaryUnitRatio = this.utility.parseNumber(good.SecondaryUnitRatio, this.locale, 3);
        });

        this.dialogRef.close({
          success: true,
          type: 'manual',
          selection: this.merciList
        });

      }
    });
  }
}

