import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, Subject } from 'rxjs';
import * as util from '@app/core/services/utilityfunctions';
import { TranslationService } from '@app/core/_base/layout';

@Component({
  selector: 'kt-map-structure',
  templateUrl: './map-structure.component.html',
  styleUrls: ['./map-structure.component.scss']
})
export class MapStructureComponent implements OnInit {
  changeVariationInput: Subject<any> = new Subject<any>();
  totDistribution: number = 0;
  utility: any;
  locale!: string;
  mode: string = 'perc';

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private translationService: TranslationService
  ) {
    this.utility = util;
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });
    this.changeVariationInput
      .pipe(
        debounceTime(500)
      ).subscribe((variation: any) => {
        this.updateDistribution();
      })
  }

  ngOnInit(): void {
    this.updateDistribution();
  }

  changeMode() {
    this.totDistribution = 0;
    this.data.PlItems.forEach((item: any) => {
      item.Absolute = item.Percentage = 0;
    });
  }

  calcAbsolute(item: any) {
    if (!item.Percentage) return '';
    const value = this.data.check.TotalAmount * this.utility.parseNumber(item.Percentage, this.locale) / 100;
    //return this.utility.formatNumber(value, this.locale, 2, true, true);
    return value;
  }

  calcPercentage(item: any) {
    if (!item.Absolute) return '';
    const value = this.utility.parseNumber(item.Absolute, this.locale) / this.data.check.TotalAmount * 100;
    return value;
  }

  updateDistribution() {
    this.totDistribution = 0;
    this.data.PlItems.forEach((item: any) => {
      if (this.mode == 'perc') {
        item.Absolute = this.calcAbsolute(item);
      } else {
        item.Percentage = this.calcPercentage(item);
      }
      if (item.Percentage && this.utility.parseNumber(item.Percentage, this.locale) > 0) {
        this.totDistribution += this.utility.parseNumber(item.Percentage, this.locale);
      }
    });
  }

  totDistributionOk() {
    return Math.abs(100 - this.totDistribution) < 1;
  }

  onChangeDistribution() {
    this.changeVariationInput.next(true);
  }

  calcValue(item: any) {
    if (this.mode == 'abs') return
    const value = this.data.check.TotalAmount * this.utility.parseNumber(item.Percentage, this.locale) / 100;
    return this.utility.formatNumber(value, this.locale, 2, true, true);
  }

}
