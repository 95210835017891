import { LayoutConfigModel } from '../_base/layout';

export class LayoutConfig {
  public defaults: LayoutConfigModel = {
    'demo': 'demo1',
    // == Base Layout
    'self': {
      'layout': 'fluid', // fluid|boxed
      'body': {
        'background-image': './assets/media/misc/bg-1.jpg',
      },
      'logo': {
        'dark': 'assets/media/logos/MyBusiness.png',
        'light': 'assets/media/logos/MyBusiness.png',
        'brand': 'assets/media/logos/MyBusiness.png',
        'green': 'assets/media/logos/MyBusiness.png',
        'small': 'assets/media/logos/MyBusinessSmall.png',
      },
    },
    // == Page Splash Screen loading
    'loader': {
      'enabled': true,
      'type': 'spinner-logo',
      //'logo': './assets/media/logos/logo-mini-md.png',
      'logo': 'assets/media/logos/MyBusiness.png',
      'message': 'Please wait...',
    },
    // == Colors for javascript
    'colors': {
      'graph': {
        'dataset1': '#0f455d', // Blu scuro
        'dataset2': '#1b7da9', // Blu
        'dataset3': '#32a9de', // Blu chiaro
        'dataset4': '#96d3ee', // Celestino
      },
      'state': {
        'brand': '#5d78ff',
        'dark': '#282a3c',
        'light': '#ffffff',
        'primary': '#5867dd',
        'success': '#a6c719',
        'info': '#36a3f7',
        'warning': '#ffb822',
        'danger': '#fd3995',
      },
      'base': {
        'label': [
          '#c5cbe3',
          '#a1a8c3',
          '#3d4465',
          '#3e4466',
        ],
        'shape': [
          '#f0f3ff',
          '#d9dffa',
          '#afb4d4',
          '#646c9a',
        ],
      },
    },
    'header': {
      'self': {
        'skin': 'light',
        'fixed': {
          'desktop': true,
          'mobile': true,
        },
      },
      'menu': {
        'self': {
          'display': false,
          'layout': 'default',
          'root-arrow': false,
        },
        'desktop': {
          'arrow': true,
          'toggle': 'click',
          'submenu': {
            'skin': 'light',
            'arrow': true,
          },
        },
        'mobile': {
          'submenu': {
            'skin': 'light',
            'accordion': true,
          },
        },
      },
    },
    'subheader': {
      'display': false,
      'layout': 'subheader-filtri',
      'fixed': true,
      'width': 'fluid',
      'style': 'solid',
    },
    'content': {
      'width': 'fluid',
    },
    'brand': {
      'self': {
        'skin': 'light',
      },
    },
    'aside': {
      'self': {
        'skin': 'light',
        'display': true,
        'fixed': true,
        'minimize': {
          'toggle': true,
          'default': false,
        },
      },
      'footer': {
        'self': {
          'display': true,
        },
      },
      'menu': {
        'dropdown': false,
        'scroll': false,
        'submenu': {
          'accordion': true,
          'dropdown': {
            'arrow': true,
            'hover-timeout': 500,
          },
        },
      },
    },
    'footer': {
      'self': {
        'width': 'fluid',
      },
    },
  };

  /**
   * Good place for getting the remote config
   */
  public get configs(): LayoutConfigModel {
    return this.defaults;
  }
}
