import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { RoleService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { ContoEconomicoService } from '@app/views/pages/amministrazione/conto-economico/conto-economico.service';
import * as util from '@app/core/services/utilityfunctions';
import { TranslateService } from '@ngx-translate/core';
import { ContoEconomicoEditComponent } from '@app/views/pages/amministrazione/conto-economico/conto-economico-edit/conto-economico-edit.component';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import moment from 'moment';
import { PDFService } from '@app/core/services/pdf.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-pl-detail',
    templateUrl: './pl-detail.component.html',
    styleUrls: ['./pl-detail.component.scss']
})
export class PlDetailComponent implements OnInit {

    currentFilterCfg: any;
    utility: any;
    locale!: string;
    table: any;
    totRows!: number;
    PDFPreferences: any;
    

    constructor(
        private contoEconomicoService: ContoEconomicoService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public staticCollectionsService: StaticCollectionsService,
        private switchGroupService: SwitchGroupService,
        private localizedDateTimePipe: LocalizedDateTimePipe,
        private translate: TranslateService,
        public dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private roleService: RoleService,
        private translationService: TranslationService,
        private pdfService: PDFService,
        private router: Router,
        private gestioneMerciService: GestioneMerciService,
        public loaderService: LoaderService,
        private ref: ChangeDetectorRef
    ) {
        this.utility = util;
    }

    ngOnInit(): void {
        this.locale = this.data.locale;

        $.fn.dataTable.ext.order['localized_date'] = (settings, col) => {
            if(!this.table) return;
            return this.table.column(col, { order: 'index' }).nodes().map(function (td, i) {
                const date: any = $(td).html();
                const rest = moment(date, 'DD/MM/YYYY').unix();
                return rest;
            });
        }

        this.initDataTable();
    }

    getTitle() {
        return this.data.invoice.FileName;
    }

    exportAsXLSX() {
        const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
        const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
        const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
        const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(expand => {
            if (expand) this.table.button(1).trigger();
        });
    }

    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: ''
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

    firstTime: boolean = true;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#pl_detail')) {
            $('#pl_detail').DataTable().destroy();
        }

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            const dtOptions: any = {
                destroy: true,
                paging: false,
                searching: true,
                dom: 'rtlp',
                ordering: true,
                scrollX: true,
                scrollY: '300px',
                scrollCollapse: true,
                autoWidth: true,
                //order: [[1, 'desc']],
                processing: true,
                data: this.data.invoice.PLSplits ? this.data.invoice.PLSplits : [],
                columnDefs: [
                    { targets: 0, data: 'Store', name: 'Store' },
                    {
                        targets: 1, data: 'Date', name: 'Date', render: (data, type, p, meta) => {
                            return this.localizedDateTimePipe.transform(data, undefined, true);
                        },
                        orderDataType: 'localized_date',
                        width: '70px'
                    },
                    {
                        targets: 2, render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses-100 exportText" title="${p.Structure?.Name}">${p.Structure?.Name}</div>`;
                        }, width: '100px'
                    },
                    {
                        targets: 3,
                        render: (data, type, p, meta) => {
                            return p.CostCenter?.Name
                        }
                    },
                    {
                        targets: 4, data: 'Amount', name: 'Amount',
                        class: 'text-right',
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 5, data: 'Discount', name: 'Discount',
                        class: 'text-right',
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 6, data: 'VAT', name: 'VAT',
                        class: 'text-right',
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    // Serve per evitare di riceve avvisi quando arrivano campi a null
                    {
                        defaultContent: '-',
                        targets: '_all'
                    }
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible',
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            console.log(doc.styles);

                            doc.styles = docDefinition.styles;

                            //var rowCount = document.getElementById("ProjectReport").rows.length;                    

                            doc.content[1].table.body.forEach(function (line, i) {
                                doc.content[1].table.body[i][4].alignment = 'right';
                                doc.content[1].table.body[i][5].alignment = 'right';
                                doc.content[1].table.body[i][6].alignment = 'right';
                            });

                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible',
                            format: {
                                body: (data, row, column, node) => {
                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        return '\0' + $(htmlDoc).find('.exportText').text();
                                    } else {
                                        // Aggiunto \0 per forzare il riconoscimento come stringa per mantenere la formattazione
                                        return '\0' + data
                                    }
                                }
                            },
                            orthogonal: 'export'
                        },
                        customize: async (xlsx: any) => {
                            var lastXfIndex = $('cellXfs xf', xlsx.xl['styles.xml']).length - 1;

                            //Aggiungo la formattazione alle info
                            xlsx.xl['styles.xml'].childNodes[0].childNodes[0].innerHTML += "<numFmt xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"170\" formatCode=\"#.##0,00\" />";

                            // Creo nuovo style con id 170, per formattare la cella e allineamento a destra
                            var s1 = '<xf xmlns= \"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId= \"170\" fontId=\"0\" fillId=\"0\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" ><alignment horizontal=\"right\"/></xf>';
                            xlsx.xl['styles.xml'].childNodes[0].childNodes[5].innerHTML += s1;

                            // Get sheet.
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];

                            //Imposto la formattazione e allineamento a dx
                            $('row c[r^="E"]', sheet).attr('s', lastXfIndex + 1);
                            $('row c[r^="F"]', sheet).attr('s', lastXfIndex + 1);
                            $('row c[r^="G"]', sheet).attr('s', lastXfIndex + 1);
                        }
                    }
                ]

            };

            this.table = $('#pl_detail').DataTable(dtOptions);
            this.table.order( [ 1, 'desc' ] ).draw();
            // Mi collego all'evento di loading della tabella
            this.table.off('processing.dt');
            this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
                if (processing) {
                    this.loaderService.show();
                } else {
                    this.loaderService.hide();
                }
                this.ref.detectChanges();
            });

            // Add event listener for opening and closing details
            $('#pl_detail tbody').off('click');

            $('#pl_detail tbody').on('click', 'a.edit_item', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                console.log(data);

                if (data) {
                    this.editRow(data);
                }
            });

            $('#pl_detail tbody').on('click', 'a.delete_item', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                console.log(data);

                if (data) {
                    this.deleteRow(data);
                }
            });


            $('#pl_detail tbody').on('click', 'a.goToWareHouse', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                console.log(data);

                if (data) {

                    const filter: any = {
                        DateFilter: JSON.stringify({ Start: moment(data.Date).format('YYYY-MM-DD'), End: moment(data.Date).format('YYYY-MM-DD') }),
                        FileName: data.DocumentFileName,
                        ShowDisabled: true,
                        ShowInvisible: true
                    }

                    localStorage.setItem('currentFilterCfg', JSON.stringify(filter))

                    this.router.navigate(['/magazzino/acquisti']);

                    this.dialogRef.close();

                }
            });

            $('#pl_detail tbody').on('click', 'a.openInvoiceXml', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();

                if (data) {

                    this.showXml({
                        FileName: data.DocumentFileName,
                        Name: data.DocumentFileName
                    });

                }
            });

        }, 100);
    }

    popupXml: any;
    async showXml(merce: any) {

      this.gestioneMerciService.openXmlHighlightedByFileName(merce.FileName, "", "")
      .then(() => console.log)
      .catch((error: any) => {
        this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
      })

    }

    async editRow(item: any) {
        this.dialog
            .open(ContoEconomicoEditComponent, {
                width: '800px',
                data: {
                    item: item,
                    isEdit: true
                }
            }).afterClosed().subscribe((res: any) => {
                //if (!res) {
                //    return;
                //}
                this.table.draw();
            });
    }

    async deleteRow(item: any) {
        const _title: string = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');
        const _deleteMessage = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.SUCCESS');
        const _errorMessage = this.translate.instant('CONTO_ECONOMICO.DETAILS.DELETE_DIALOG.ERROR');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async (confirm: any) => {
            console.log(confirm);
            if (confirm) {
                this.contoEconomicoService.deletePL(item.Id).toPromise()
                    .then(() => {
                        this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                        this.table.draw();
                    })
                    .catch(() => this.layoutUtilsService.showActionNotification(_errorMessage, MessageType.Delete));
            }

        });
    }
}

