import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
import { catchError, map, take, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { InserimentoOrdiniInterniPopupComponent } from './inserimento-ordini-popup/inserimento-ordini-popup.component';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import * as _ from 'lodash';
import * as util from '@app/core/services/utilityfunctions';
import { environment } from '@env/environment';
import { SendDdtComponent } from './send-ddt/send-ddt.component';
import { SendInnerMailComponent } from './send-inner-mail/send-inner-mail.component';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-ordini',
    templateUrl: './ordini-interni.component.html',
    styleUrls: ['./ordini-interni.component.scss', '../common-styles.scss']
})
export class OrdiniInterniComponent implements OnInit, AfterViewInit, OnDestroy {

    lista: any;
    listaFiltered: any;
    companies: any;
    currentCompanyFilter!: string;
    showInitialImage: boolean = true;

    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;
    // staticCollectionsSubcription!: Subscription;

    
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    currentFilterCfg: any;
    pagination: any;
    utility: any;
    // FILTRI
    categorieFiltri: any;
    fornitoriFiltri: any;
    currentUser: any;

    statiOrdini = [
        { id: 0, Name: 'Tutti' },
        { id: 1, Name: 'Evasi' },
        { id: 2, Name: 'Non Evasi' },
    ]
    statoSelezionato: any = 2;

    @ViewChild('OrderOrDocument', { static: true }) OrderOrDocument!: ElementRef;

    columnsList: any = [];
    locale!: string;

    constructor(
        public filtriService: FiltriService,
        private magazzinoService: MagazzinoService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutConfigService: LayoutConfigService,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public staticCollectionsService: StaticCollectionsService,
        public dialog: MatDialog,
        private router: Router,
        public roleService: RoleService,
        private anagraficheService: AnagraficheService,
        public loaderService: LoaderService
    ) {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.columnsList = [
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.DATE'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.COMPANY'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ORDER_NUMBER'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.SUPPLIER'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.NUM_ITEMS'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.TOTAL'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.MAIL_SENT'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ORDER_RECEIVED'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.EDI'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ACTIONS.TITLE'), enabled: true }
        ];
        this.utility = util;

        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
    }

    /**
     * After view init
     */
    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            async (event: any) => {
                if (event.change) {
                    this.loaderService.show();
                    await this.staticCollectionsService.fillStaticCollection(true);
                    this.clearFilters(false);
                    $('#table').DataTable().clear().destroy();
                    this.lista = [];
                    this.listaFiltered = [];
                    this.dataReady$.next(false);
                    this.loaderService.hide();
                }
            }
        );

        await this.staticCollectionsService.fillStaticCollection(false);
        this.loaderService.hide();
        this.initFiltri();
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};

        // Se l'url non include inserimento-comune allora svuoto la localstorage
        if (!this.router.url.includes('/magazzino/inserimento-ordini-interni')) {
            localStorage.removeItem('currentFilterCfg');
        }
    }

    getTitle() {
        return this.translate.instant('MAGAZZINO.ORDINI_INTERNI.TITLE');
    }

    clearFilters(actionFilter: boolean = true) {
        $('#select2Categorie').val('').trigger('change');
        $('#select2Fornitori').val('').trigger('change');

        if (this.OrderOrDocument) this.OrderOrDocument.nativeElement.value = '';

        this.filtriService.clearFilters$.next(actionFilter);
        $('#table').DataTable().clear().destroy();
        this.lista = [];
        this.listaFiltered = undefined;
        this.dataReady$.next(false);
        this.showInitialImage = true;
    }

    ngOnInit() {

        this.categorie = [];
        this.fornitori = [];
        document.body.classList.add('kt-aside--minimize');
        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
                        localStorage.removeItem('currentFilterCfg');
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                        return;
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                    }
                }


                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
                if (filterConfig.constructor !== Object) return;
                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                this.showInitialImage = false;

                this.pagination = {
                    page: 1,
                    pageSize: 15,
                    sort: 'Date',
                    sortMode: 'Desc',
                    TotalRows: 0
                };

                // Inizializzo EVENTUALMENTE le select
                setTimeout(() => {
                    if (this.currentFilterCfg && this.currentFilterCfg.CategoryIds && this.currentFilterCfg.CategoryIds.length > 0) {
                        $('#select2Categorie').val(this.currentFilterCfg.CategoryIds).trigger('change');
                        this.categorie = this.currentFilterCfg.CategoryIds;
                    }

                    if (this.currentFilterCfg && this.currentFilterCfg.SupplierIds && this.currentFilterCfg.SupplierIds.length > 0) {
                        $('#select2Fornitori').val(this.currentFilterCfg.SupplierIds).trigger('change');
                        this.fornitori = this.currentFilterCfg.SupplierIds;
                    }


                }, 1000)

                this.refreshTable();
            }, (error: any) => {

            }
        );



        fromEvent(this.OrderOrDocument.nativeElement, 'keyup')
            .pipe(
                // tslint:disable-next-line:max-line-length
                debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                distinctUntilChanged(), // This operator will eliminate duplicate values
                tap((ev: any) => {
                    if (ev.keyCode == 13) this.filter(this.OrderOrDocument.nativeElement.value, 'OrderOrDocument');
                })
            )
            .subscribe();
    }

    categorie: any;
    fornitori: any;

    initFiltri() {
        console.log('initFiltri');

        (<any>$('#select2Categorie')).select2({
            placeholder: this.translate.instant('FILTRI.CATEGORIES_PLACEHOLDER'),
            allowClear: true,
            closeOnSelect: false,
            multiple: true,
            width: '100%',
            containerCssClass: 'select2Multiple',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('#select2Categorie').on('select2:select', (e: any) => {
            /*
            this.categorie.push({
              Id: e.params.data.id,
              Name: e.params.data.text
            });
            */
            if (this.categorie.indexOf(e.params.data.id) < 0) {
                this.categorie.push(e.params.data.id);
                this.filter(this.categorie, 'CategoryIds');
            }

        });
        $('#select2Categorie').on('select2:clear', (e: any) => {
            if (this.categorie.length > 0) {
                this.categorie = [];
                this.filter(this.categorie, 'CategoryIds');
            }
        });
        $('#select2Categorie').on('select2:unselect', (e: any) => {
            if (this.categorie.indexOf(e.params.data.id) >= 0) {
                this.categorie = this.categorie.filter((categoria: any) => categoria !== e.params.data.id);
                this.filter(this.categorie, 'CategoryIds');
            }

        });
        /* FORNITORI */

        (<any>$('#select2Fornitori')).select2({
            placeholder: this.translate.instant('FILTRI.FORNITORE_PLACEHOLDER'),
            allowClear: true,
            closeOnSelect: false,
            multiple: true,
            width: '100%',
            containerCssClass: 'select2Multiple',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('#select2Fornitori').on('select2:select', (e: any) => {
            if (this.fornitori.indexOf(e.params.data.id) < 0) {
                this.fornitori.push(e.params.data.id);
                this.filter(this.fornitori, 'SupplierIds');
            }
        });
        $('#select2Fornitori').on('select2:clear', (e: any) => {
            if (this.fornitori.length > 0) {
                this.fornitori = [];
                this.filter(this.fornitori, 'SupplierIds');
            }
        });
        $('#select2Fornitori').on('select2:unselect', (e: any) => {
            if (this.fornitori.indexOf(e.params.data.id) >= 0) {
                this.fornitori = this.fornitori.filter((fornitore: any) => fornitore !== e.params.data.id);
                this.filter(this.fornitori, 'SupplierIds');
            }
        });


        setTimeout(() => {
            $('#select2Categorie').val('').trigger('change');
            $('#select2Fornitori').val('').trigger('change');

        }, 1000);
    }

    refreshTable() {
        //console.log('refreshTable');
        this.loaderService.show();
        this.filtriService.readOnly$.next(true);
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        //console.log(this.pagination);
        this.companies = [];
        this.dataReady$.next(false);
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = { Period: 'YEAR' };
        }
        this.currentFilterCfg["Internal"] = true;
        this.currentFilterCfg["Status"] = this.statoSelezionato;
        this.magazzinoService.getWarehouse('GetOrders', this.currentFilterCfg, this.pagination).toPromise()
            .then((ret: any) => {
                console.log('lista', ret);

                // TODO: Elaborare dati ricevuti
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }

                this.lista = ret;
                this.listaFiltered = JSON.parse(JSON.stringify(ret));
                this.initDataTable();
                this.loaderService.hide();
                this.filtriService.readOnly$.next(false);
                this.dataReady$.next(true);
            })
            .catch((error: any) => {
                this.loaderService.hide();
            });
    }

    initDataTable() {
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: 'calc(100vh - 400px)',
                scrollCollapse: true,
                aaSorting: [], // inizialmente nessun ordinamento
                columnDefs: [
                    { targets: [0], visible: this.columnsList[0].enabled },
                    { targets: [1], visible: this.columnsList[1].enabled },
                    { targets: [2], visible: this.columnsList[2].enabled },
                    { targets: [3], visible: this.columnsList[3].enabled },
                    { targets: [4], visible: this.columnsList[4].enabled },
                    { targets: [5], visible: this.columnsList[5].enabled },
                    { targets: [6], visible: this.columnsList[6].enabled },
                    { targets: [7], visible: this.columnsList[7].enabled },
                    { targets: [8], visible: this.columnsList[8].enabled },
                    { targets: [9], visible: this.columnsList[9].enabled }
                ],
                autoWidth: true
            };
            this.table = $('#table').DataTable(dtOptions);
            //this.table.columns.adjust().draw();
            // Mi collego all'evento di loading della tabella
            this.table.off('processing.dt');
            this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
                if (processing) {
                    this.loaderService.show();
                } else {
                    this.loaderService.hide();
                }
                this.ref.detectChanges();
            });
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
        }, 100);
    }

    changePagination(pagination: PageEvent) {
        this.pagination.page = pagination.pageIndex + 1;
        this.pagination.pageSize = pagination.pageSize;
        this.refreshTable();
    }

    sort(event: any, type: string) {
        const columnLabel = $(event.target).closest('th').text().trim();
        this.columnsList.forEach((element: any) => {
            let lbl = element.label;
            let t = typeof lbl;
            if (lbl && t == 'string' && lbl.toLowerCase() === columnLabel.toLowerCase()) {

                this.pagination.sort = type;

                if (element.sort && element.sort === 'ASC') {
                    element.sort = 'DESC';
                    this.pagination.sortMode = 'Desc';
                } else {
                    element.sort = 'ASC';
                    this.pagination.sortMode = 'Asc';
                }

                console.log('sortMode', columnLabel, this.pagination.sortMode);
                this.refreshTable();
            }
        });
    }

    filter(event: any, type: string) {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = { Period: 'YEAR' };
        }
        switch (type) {
            case 'ProductOrCode':
                this.currentFilterCfg['ProductOrCode'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'OrderOrDocument':
                this.currentFilterCfg['OrderOrDocument'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'CategoryIds':
                this.currentFilterCfg['CategoryIds'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'SupplierIds':
                this.currentFilterCfg['SupplierIds'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
        }
    }

    openPopup() {
        const newEndpoint = {};
        this.editPopup(newEndpoint);
    }

    editPopup(endpoint: any) {
        const dialogRef = this.dialog.open(InserimentoOrdiniInterniPopupComponent, {
            data: { endpoint },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success && res.body) {
                res.body['hideExcluded'] = true;
                res.body['hideInvisible'] = true;
                res.body['PriceMode'] = 'default';
                this.magazzinoService.setCurrentConfig(res);

                // mi salvo la configurazione dei filtri nei cookie.
                console.log('mi salvo la configurazione dei filtri nei cookie.');
                localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));


                this.router.navigate(['/magazzino/inserimento-ordini-interni'], {
                    queryParams: {
                        //inserimentoType: InserimentoType.ORDINI,
                        referral: '/magazzino/ordini-interni'
                    }
                });
            }

        });
    }

    refreshColumnsVisibility() {
        //console.log('refreshColumnsVisibility');
        for (let index = 0; index < this.columnsList.length; index++) {
            var column = this.table.column(index);
            if (column) column.visible(this.columnsList[index].enabled);
        }
    }

    openColumnsDialog() {
        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: { columnsList: this.columnsList },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            //console.log(res);
            if (res.success && res.columnsList) {
                this.columnsList = res.columnsList;
                this.refreshColumnsVisibility();
            }
        });
    }



    editItem(item: any, isDDTEdit: boolean = false) {

        if ((!isDDTEdit && (!this.roleService.canWrite() || item.IsReceived || item.IsMailSent)) ||
            (isDDTEdit && (!this.roleService.canWrite() || item.IsReceived || item.IsEDI))) return;

        this.magazzinoService.itemsForUpdate(item.Id, 'InnerOrder', item.Supplier.Id)
            .subscribe((result: any) => {
                //console.log('itemsForUpdate', result);

                const resultConfig = JSON.parse(JSON.stringify(result));
                const resultsRows: any = JSON.parse(JSON.stringify(result.Rows));
                delete resultConfig.Rows;
                resultConfig['hideExcluded'] = true;
                resultConfig['hideInvisible'] = true;
                resultConfig['PriceMode'] = 'default';
                this.magazzinoService.setCurrentConfig({
                    //insertType: InserimentoType.ORDINI,
                    body: resultConfig,
                    items: resultsRows,
                    prePop: {
                        Items: resultsRows
                    }
                });

                // mi salvo la configurazione dei filtri nei cookie.
                console.log('mi salvo la configurazione dei filtri nei cookie.');
                localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));

                this.router.navigate(['/magazzino/inserimento-ordini-interni'], {
                    queryParams: {
                        //inserimentoType: InserimentoType.ORDINI,
                        referral: '/magazzino/ordini-interni',
                        itemId: item.Id,
                        isDDTEdit: isDDTEdit
                    }
                });

            })
    }

    deleteItem(item: any) {

        if (!this.roleService.canWrite() || item.IsReceived || item.IsEDI || item.CanCreateDDT) return;

        const _title: string = this.translate.instant('MAGAZZINO.ORDINI_INTERNI.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ORDINI_INTERNI.DELETE_DIALOG.DESC_MULTI');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('MAGAZZINO.ORDINI_INTERNI.DELETE_DIALOG.BUTTON');

        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            //console.log(confirm);
            if (confirm) {
                const deleteItem = { ListId: item.Id, SupplierId: item.Supplier.Id };
                this.magazzinoService.delete('Order', deleteItem).subscribe(
                    (result: any) => {
                        //console.log(result);
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('MAGAZZINO.ORDINI_INTERNI.DELETE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            this.refreshTable();
                        } else {
                            message = this.translate.instant('MAGAZZINO.ORDINI_INTERNI.DELETE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )

            }
        });

    }

    showFromYearStart() {
        this.currentFilterCfg = { Period: 'YEAR' };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    showToday() {
        const today = new Date();
        const todayStr = today.toLocaleDateString('ja-JP');
        this.currentFilterCfg = { DateFilter: { Start: todayStr, End: todayStr } };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    /****************************************************************************
        ______                         _
         |  ____|                       | |
         | |__ ___  _ __ _ __ ___   __ _| |_
         |  __/ _ \| '__| '_ ` _ \ / _` | __|
         | | | (_) | |  | | | | | | (_| | |_
         |_|  \___/|_|  |_| |_| |_|\__,_|\__|
  
    ****************************************************************************/
    formatNumber(x: any, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
        return this.utility.formatNumber(x, this.locale, decimal, showZero, currency);
    }

    /****************************************************************************
           _____ _______ _____ ____  _   _  _____ 
     /\   / ____|__   __|_   _/ __ \| \ | |/ ____|
    /  \ | |       | |    | || |  | |  \| | (___  
   / /\ \| |       | |    | || |  | | . ` |\___ \ 
  / ____ \ |____   | |   _| || |__| | |\  |____) |
  /_/    \_\_____|  |_|  |_____\____/|_| \_|_____/                                                                                                 
  
  ****************************************************************************/


    downloadPDF(item: any) {
        if (!item.CanCreateDDT && !item.IsEDI) return;
        let lang = this.translationService.getSelectedLanguage();
        this.magazzinoService.getPrintDDT(item, lang).subscribe(
            (result: any) => {
                window.open(`${result.endpointURL}${result.link}`)
            }
        );
    }

    sendMail(item: any) {
        if (item.IsReceived || item.IsMailSent) return;
        let lang = this.translationService.getSelectedLanguage();

        const getOrderMailInfo$ = this.magazzinoService.getOrderMailInfo(item, lang);
        const getSupplierById$ = this.anagraficheService.getSupplierById(item.Supplier.Id);

        lastValueFrom(combineLatest([getOrderMailInfo$, getSupplierById$]))
            .then((result: any) => {
                const mailInfo: any = result[0];
                const supplier: any = result[1];
                if (mailInfo) {
                    mailInfo.ListId = item.Id;
                    mailInfo.SupplierId = item.Supplier.Id;

                    if (supplier.Agents && supplier.Agents.length > 0) {
                        const agent = supplier.Agents.find((agent: any) => agent.SupplierId == item.Supplier.Id && agent.Store == mailInfo.Company);
                        if (agent) {
                            mailInfo.Destination = agent.Email;
                        }
                    }
                    const dialogRef = this.dialog.open(SendInnerMailComponent, {
                        data: { mailInfo },
                        width: '600px'
                    });
                    dialogRef.afterClosed().subscribe((res: any) => {
                        if (_.isEmpty(res) || !res) {
                            return;
                        }
                        console.log(res);
                        if (res.success) {
                            item.IsMailSent = true;
                            this.refreshTable();
                            const message = this.translate.instant('MAGAZZINO.ORDINI.SEND_MAIL.SENT_MESSAGE');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        }

                    });

                }
            }
            );
    }

    sendDDT(item: any) {
        if (item.IsEDI || !item.IsMailSent) return;


        const dialogRef = this.dialog.open(SendDdtComponent, {
            data: { ListId: item.Id, SupplierId: item.Supplier.Id },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success) {
                item.IsEDI = true;
                this.refreshTable();
                const message = this.translate.instant('MAGAZZINO.ORDINI_INTERNI.SEND_DDT.SENT_MESSAGE');
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            }

        });



    }


    /******************************************************************************
          ______                       _    __   ___       _______   __
         |  ____|                     | |   \ \ / / |     / ____\ \ / /
         | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
         |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
         | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
         |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
           | |
           |_|
        ******************************************************************************/

    exportAsXLSX() {
        let pagination = JSON.parse(JSON.stringify(this.pagination));
        if (this.pagination.TotalRows) {

            if (pagination.TotalRows < environment.ExcelPaginationMaxSize)
                pagination.pageSize = pagination.TotalRows;
            else {
                pagination.pageSize = environment.ExcelPaginationMaxSize;
                const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
                let _description: string = this.translate.instant('EXPORT_XLSX.MAX5000');
                _description = _description.replace('{0}', '' + environment.ExcelPaginationMaxSize);
                const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

                const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


                const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
                dialogRef.afterClosed().subscribe(expand => {

                });
            }

        }
        else {
            const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
            const _description: string = this.translate.instant('EXPORT_XLSX.NO_ROWS');
            const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

            const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
            dialogRef.afterClosed().subscribe(expand => {
                return;
            });
            return;
        }



        this.loaderService.show();
        let xlsx: any[] = [];
        let merges: any[] = [];
        this.currentFilterCfg["Internal"] = true;
        this.magazzinoService.getWarehouse('GetOrders', this.currentFilterCfg, pagination).toPromise()
            .then((ret: any) => {

                // TODO: Elaborare dati ricevuti
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                    ret.Note = ret.Note ? ret.Note.trim() : '';
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }

                let printList = JSON.parse(JSON.stringify(ret));

                // Totali
                xlsx.push({
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.DATE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.COMPANY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ORDER_NUMBER')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.SUPPLIER')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.NUM_ITEMS')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.TOTAL')}`]: printList.Total ? this.utility.formatNumberExcel(printList.Total, 2) : '',
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.MAIL_SENT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ORDER_RECEIVED')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.EDI')}`]: '',
                });

                printList.Rows.forEach((item: any) => {
                    //this.listaFiltered.Rows.forEach((item: any) => {

                    xlsx.push({
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.DATE')}`]: item.Date ? (new Date(item.Date)).toLocaleDateString('it-IT') : '',
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.COMPANY')}`]: item.Company ? item.Company.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ORDER_NUMBER')}`]: item.OrderId ? item.OrderId : '',
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.SUPPLIER')}`]: item.Supplier ? item.Supplier.Name : '',
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.NUM_ITEMS')}`]: item.NumItems ? this.utility.formatNumberExcel(item.NumItems) : '',
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.TOTAL')}`]: item.Total ? this.utility.formatNumberExcel(item.Total, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.MAIL_SENT')}`]: item.IsMailSent ? 'true' : '',
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ORDER_RECEIVED')}`]: item.IsReceived ? 'true' : '',
                        [`${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.EDI')}`]: item.IsEDI ? 'true' : '',
                    });
                });
                this.loaderService.hide();
                this.excelService.exportAsExcelFile(xlsx, 'orders', merges, this.columnsList, 2, [], true);
            });

    }

    /******************************************************************************
      ______                       _     _____  _____  ______
     |  ____|                     | |   |  __ \|  __ \|  ____|
     | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
     |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
     | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
     |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
           | |
           |_|
    ******************************************************************************/

    exportPDFSheet() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.SHEET_TITLE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            companyLabel: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.COMPANY'),
            orderLabel: this.translate.instant('MAGAZZINO.ORDINI_INTERNI.ORDINAMENTO'),

            companies: this.companies
        };

        const dialogRef = this.layoutUtilsService.exportPdfSheet(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {

                this.loaderService.show();
                let lang = this.translationService.getSelectedLanguage();
                this.magazzinoService.getInventoryPDF(result.orderMode, result.company, lang).subscribe(
                    (resultOp: any) => {
                        //console.log(`${resultOp.endpointURL}${resultOp.link}`);
                        this.loaderService.hide();
                        window.open(`${resultOp.endpointURL}${resultOp.link}`, '_blank');
                    }
                );

            };
        });
    }

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;

                let widths: any = [];
                this.columnsList.filter((item: any) => item.enabled).forEach((element: any) => {
                    widths.push('auto')
                });

                widths.splice(widths.length - 1, 1); // tolgo la colonna actions
                widths[widths.length - 1] = '*';

                // Carico tutti i record della tabella per stampare l'excel
                const pagination = {
                    page: 1,
                    pageSize: this.pagination.TotalRows,
                    sort: 'Date',
                    sortMode: 'Desc',
                };

                this.magazzinoService.getWarehouse('GetOrders', this.currentFilterCfg, pagination).toPromise()
                    .then((ret: any) => {
                        console.log(ret);

                        this.pdfService.makePdf(result, this.getPDFTableBody(ret.Rows), widths)
                    })
                    .catch((error: any) => {
                        this.loaderService.hide();
                    });
            };
        });

    }

    getPDFTableBody(rows: any) {
        let body: any = [];
        let tmpRow: any = [];


        // aggiungo intestazione
        // totali Header
        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.DATE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.COMPANY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ORDER_NUMBER')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.SUPPLIER')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.NUM_ITEMS')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.TOTAL')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI.COLUMNS.MAIL_SENT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.ORDER_RECEIVED')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ORDINI_INTERNI.COLUMNS.EDI')}`, style: 'tableHeaderStyle' },
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.listaFiltered.Total ? this.formatNumber(this.listaFiltered.Total) : ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        let i: number = 0;
        let sortedList = this.utility.sortList(rows, 'table');

        sortedList.forEach((item: any) => {
            tmpRow = [
                { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Date ? (new Date(item.Date)).toLocaleDateString('it-IT') : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Company ? item.Company.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.OrderId ? item.OrderId : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Supplier ? item.Supplier.Name : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[4].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.NumItems ? this.formatNumber(item.NumItems) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[5].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Total ? this.formatNumber(item.Total) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[6].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.IsMailSent ? 'true' : '', style: 'tableBodyStyle' },
                { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.IsReceived ? 'true' : '', style: 'tableBodyStyle' },
                { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.IsEDI ? 'true' : '', style: 'tableBodyStyle' },
            ];
            body.push(tmpRow.filter((item: any) => item.visible));
            i++;
        });

        return body;
    }

}
