// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
// Layout
import { LayoutConfigService, TranslationService } from '@app/core/_base/layout';
// Charts
import { Chart } from 'chart.js/dist/chart.min.js';
import { FiltriService } from '@app/core/services/filtri.service';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import * as util from '@app/core/services/utilityfunctions';
import { PDFService } from '@app/core/services/pdf.service';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { ExcelService } from '@app/core/services/excel.service';
import { environment } from '@env/environment';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
  selector: 'kt-indicatori-performance',
  templateUrl: './indicatori-performance.component.html',
  styleUrls: ['./indicatori-performance.component.scss']
})
export class IndicatoriPerformanceComponent implements OnInit {

  // Public properties
  @Input() data: any;
  @ViewChild('chart', { static: true }) chart!: ElementRef;

  MONTHS: any = ['', 'GENNAIO', 'FEBBRAIO', 'MARZO', 'APRILE', 'MAGGIO', 'GIUGNO', 'LUGLIO', 'AGOSTO', 'SETTEMBRE', 'OTTOBRE', 'NOVEMBRE', 'DICEMBRE']
  MONTHS_SHORT: any = ['', 'GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC']
  WEEKDAY: any = ['', 'LUNEDI\'', 'MARTEDI\'', 'MERCOLEDI\'', 'GIOVEDI\'', 'VENERDI\'', 'SABATO', 'DOMENICA']

  graphData: any;
  dashboardSubcriber!: Subscription;
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  currentView: string = 'qty';
  myChart;
  utility: any;
  locale!: string;
  columnsList: any;

  sortData: boolean = true;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private filtriService: FiltriService,
    private translate: TranslateService,
    private translationService: TranslationService,
    private pdfService: PDFService,
    private layoutUtilsService: LayoutUtilsService,
    private excelService: ExcelService,
    public loaderService: LoaderService,
    private ref: ChangeDetectorRef
  ) {
    this.utility = util;
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
      if (this.myChart) {
        this.initChartJS();
      }
    });

    this.columnsList = [
      { label: '', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];
  }

  totAtt: number = 0;
  totPrec: number = 0;
  listIncome: any;
  refreshGraph() {
    let labels: any = [];
    let dataQuantita: any = [];
    let dataTotRevenue: any = [];
    let dataIncidence: any = [];
    let dataMDC: any = [];

    let elencoDati = JSON.parse(JSON.stringify(this.data));

    // Recupero ricavi tot
    const incomesTot = elencoDati.filter((row: any) => row.IsTotal == true && row.ItemType == 'Income' && row.IsSection && (row.Children.length == 0 || !row.Children));
    this.totAtt = incomesTot[0][this.whatType()];
    this.totPrec = incomesTot[0][this.whatType(true)];

    //SERGIO => IL FILTRO è QUì
    this.listIncome = elencoDati.filter((row: any) => row.IsTotal && row.IsSection);

    if (this.sortData) {
      this.listIncome = this.listIncome.sort((a: any, b: any) => {
        return a[this.whatType()] > b[this.whatType()] ? -1 : (a[this.whatType()] < b[this.whatType()] ? 1 : 0);
      });
    }

    this.listIncome.forEach((record: any) => {
      labels.push(record.Name);
      dataTotRevenue.push(record[this.whatType()]);
      dataIncidence.push(record['CurrentValuePercAbs']);
    });

    this.graphData = {
      labels: labels,
      datasets: [{
        type: 'line',
        label: this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.ASSE_Y2'),
        backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset4'),
        borderColor: this.layoutConfigService.getConfig('colors.graph.dataset4'),
        data: dataIncidence,
        yAxisID: "y-axis-incidence",
        cubicInterpolationMode: 'monotone'
      }, {
        type: 'bar',
        label: this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.ASSE_Y'),
        backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset1'),
        data: dataTotRevenue,
        yAxisID: "y-axis-revenue",
        tipo: 'revcurr'
      }]
    };

    this.initChartJS();
  }

  /**
   * On init
   */
  ngOnInit() {
    if (!this.data || this.data.length == 0) return;
    this.refreshGraph();
  }

  getPadding() {
    const padding = this.graphData && this.graphData.datasets && this.graphData.datasets.length > 2 && this.graphData.datasets[2].data.length > 21 ? 0 : 35;
    return padding;
  }

  /** Init chart */
  initChartJS() {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    if (this.myChart) {
      this.myChart.destroy();
    }

    // Serve per esportazione in PDF per background bianco, altrimenti rimane nero.
    const bgColor = {
      id: 'bgColor',
      beforeDraw: (chart: any, options: any) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    }

    this.myChart = new Chart(this.chart.nativeElement, {
      type: 'bar',
      data: this.graphData,
      plugins: [
        bgColor,
        {
          afterDraw: (chart: any) => {
            /************************************/
            // Disegno l'andamento
            const revAttuale = chart.config.data.datasets.filter((record: any) => record.tipo === 'revcurr');
            const incidence = chart.config.data.datasets.filter((record: any) => record.tipo != 'revcurr');
            if (!revAttuale[0] || revAttuale[0].data.length > 30) return;
            for (let index = 0; index < revAttuale[0].data.length; index++) {
              let x = chart.scales['x-axis-0'].getPixelForTick(index);
              let y = chart.scales['y-axis-revenue'].bottom;
              const ctx = this.chart.nativeElement.getContext('2d');

              ctx.fillStyle = "#505050";

              const tRevAttuale = parseFloat(revAttuale[0].data[index]);
              const incidenza = parseFloat(incidence[0].data[index]);
              ctx.font = `500 1rem  "Barlow Condensed"`;

              const incidenceLabel = this.utility.formatNumber(incidenza, this.locale, 0);
              const textWidth1 = ctx.measureText(incidenceLabel).width;
              ctx.fillText(`${incidenceLabel}%`, x - (textWidth1 / 2), y + 15);

              //const currencyLabel = this.utility.formatNumber(tRevAttuale, this.locale, 0, true);
              //const textWidth2 = ctx.measureText(currencyLabel).width;
              //ctx.fillText(`${this.utility.formatNumber(tRevAttuale, this.locale, 0, true, true)}`, x - (textWidth2 / 2), y + 30);
            }

          }
        }
      ],
      options: {
        name: 'TopSeller',
        title: {
          display: false,
        },
        plugins: {
          tooltip: {
            bodyFont: {
                            family: "Barlow Condensed",
              weight: '500',
                size: 14

            },
            titleFont: {
                            family: "Barlow Condensed",
              weight: '500',
                size: 14

            },
            footerFont: {
                            family: "Barlow Condensed",
              weight: '500',
                size: 14

            },
            mode: 'index',
            padding: 10,
            caretPadding: 10,
            callbacks: {
              label: (tooltipItem, data) => {
                const value = parseFloat(tooltipItem.raw);
                switch (tooltipItem.datasetIndex) {
                  case 0:
                    return this.utility.formatNumber(value, this.locale, 0, true, false) + '%';
                  case 1:
                    return this.utility.formatNumber(value, this.locale, 0, true, true);
                }
              }
            }
          },
          legend: {
            labels: {
              font: {
                              family: "Barlow Condensed",
              weight: '500',
                size: 14

              }
            },
            display: false,
            position: 'bottom'
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          "x-axis-0": {
            display: true,
            grid: {
              display: false
            },
            stacked: false,
            ticks: {
              font: {
                              family: "Barlow Condensed",
              weight: '500',
                size: 14

              },
              padding: 15,
            }
          },
          "y-axis-revenue": {
            id: "y-axis-revenue",
            position: 'left',
            display: true,
            stacked: false,
            ticks: {
              font: {
                              family: "Barlow Condensed",
              weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                if (parseFloat(value) > 0)
                  return this.utility.kFormatter(value, this.locale);
              }
            },
            title: {
              font: {
                              family: "Barlow Condensed",
              weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.ASSE_Y')
            },
            beginAtZero: true,
          },
          "y-axis-incidence": {
            id: "y-axis-incidence",
            position: 'right',
            display: true,
            stacked: false,
            ticks: {
              font: {
                              family: "Barlow Condensed",
              weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                return value + ' [ % ]';
              }
            },
            title: {
              font: {
                              family: "Barlow Condensed",
              weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.ASSE_Y2')
            },
            beginAtZero: true,
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  printChart() {
    this.myChart.update();
    setTimeout(() => {
      var canvas = <HTMLCanvasElement>document.getElementById('INDICATORI_PERFORMANCE');
      this.pdfService.chartToPdf(canvas, this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.TITLE'));
    }, 100);
  }

  getTotAttuale() {
    return this.utility.formatNumber(this.totAtt, this.locale, 2, true, true);
  }

  getTotPrecedente() {
    return this.utility.formatNumber(this.totPrec, this.locale, 2, true, true);
  }

  showTableResult: boolean = false;
  exportTableRows: any;
  prepareShowTableResult() {
    this.showTableResult = !this.showTableResult;
    this.exportTableRows = [];
    if (this.showTableResult) {

      this.listIncome.forEach((item: any) => {
        const currValue = item[this.whatType()];
        this.exportTableRows.push([
          item.Name,
          this.utility.formatNumber(currValue, this.locale, 2),
          this.utility.formatNumber(currValue / this.totAtt * 100, this.locale, 2),
        ]);
      });

      this.initDataTable();
    }

  }

  table: any;
  initDataTable() {
    if ($.fn.dataTable.isDataTable('#tableResult')) {
      $('#tableResult').DataTable().clear().destroy();
    }
    setTimeout(() => {
      let dtOptions: any = {
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },
        paging: false,
        searching: false,
        ordering: false,
        orderCellsTop: true,
        scrollY: '450px',
        scrollCollapse: true,
        columnDefs: [
          { targets: 0 }, // Name
          { targets: 1 }, // Cost Attuale
          { targets: 2 }, // Incidenza

        ],
        aaSorting: [],

        dom: 'frtip',
      };

      this.table = $('#tableResult').DataTable(dtOptions);
      // Mi collego all'evento di loading della tabella
      this.table.off('processing.dt');
      this.table.on('processing.dt', (e: any, settings: any, processing: any) => {
        if (processing) {
          this.loaderService.show();
        } else {
          this.loaderService.hide();
        }
        this.ref.detectChanges();
      });

    }, 250);

  }

  /******************************************************************************
   ______                       _     _____  _____  ______
  |  ____|                     | |   |  __ \|  __ \|  ____|
  | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
  |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
  | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
  |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
              | |
              |_|
  ******************************************************************************/

  exportAsPDF() {

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
    };

    this.columnsList = [
      { label: '', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result['header'] = {
          export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.EXPORT_TITLE') }),
          period: this.filtriService.getCurrentPeriod()
        }
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        }
        result['language'] = this.translationService.getSelectedLanguage();
        result['table'].headerRows = 1;
        result['orientation'] = 'portrait';
        let columnsTot = this.columnsList.filter((item: any) => item.enabled).length;

        let pdfTableBody;

        pdfTableBody = this.getPDFTableBody('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.XLS_COLUMNS.COST_NAME');

        this.pdfService.makePdf(result, pdfTableBody, Array(columnsTot).fill('auto'));
      };
    });

  }

  getPDFTableBody(columnName: string) {
    let body: any = [];
    let tmpRow: any = [];

    tmpRow = [
      { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.XLS_COLUMNS.COST_NAME')}`, style: 'tableHeaderStyle' },
      { visible: this.columnsList[1].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.XLS_COLUMNS.COST_CURR')}`, style: 'tableHeaderStyle' },
      { visible: this.columnsList[2].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.XLS_COLUMNS.INCIDENCE')}`, style: 'tableHeaderStyle' },
    ];
    body.push(tmpRow.filter((item: any) => item.visible));


    let i: number = 0;
    let totFc: number = 0;
    let bodyTmp: any = [];
    this.listIncome.forEach((item: any) => {

      const currValue = item[this.whatType()];

      tmpRow = [
        { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Name.toUpperCase(), style: 'tableBodyStyle' },
        { visible: this.columnsList[1].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.utility.formatNumber(currValue, this.locale, 2), style: 'tableBodyStyle' },
        { visible: this.columnsList[2].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.utility.formatNumber(currValue / this.totAtt * 100, this.locale, 2), style: 'tableBodyStyle' },
      ];

      bodyTmp.push(tmpRow.filter((item: any) => item.visible));
      i++;
    });

    body = body.concat(bodyTmp);

    return body;
  }

  /******************************************************************************
   ______                       _    __   ___       _______   __
  |  ____|                     | |   \ \ / / |     / ____\ \ / /
  | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
  |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
  | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
  |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
      | |
      |_|
  ******************************************************************************/

  exportAsXLSX() {
    this.loadingSubject.next(true);

    let xlsx: any[] = [];
    let merges: any[] = [];
    let i: number = 0;

    let rowsStyles: any = [];

    // FACCIO PRIMA IL BODY, mi serve per calcolare FC % TOT
    let xlsxBody: any[] = [];
    let totFc: number = 0;
    this.listIncome.forEach((item: any) => {
      rowsStyles.push({
        sz: 10
      });

      let columns: any = {};

      const currValue = item[this.whatType()];

      columns[`${this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.XLS_COLUMNS.COST_NAME')}`] = item.Name.toUpperCase();
      columns[`${this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.XLS_COLUMNS.COST_CURR')}`] = this.utility.formatNumberExcel(currValue, 2);
      columns[`${this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.XLS_COLUMNS.INCIDENCE')}`] = this.utility.formatNumberExcel(currValue / this.totAtt * 100, 2);

      xlsxBody.push(columns);
      i++;
    });

    // POI FACCIO I TOTALI
    xlsx = xlsx.concat(xlsxBody);

    this.loadingSubject.next(false);

    this.columnsList = [
      { label: '', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];

    this.excelService.exportAsExcelFile(xlsx, this.translate.instant('MAINGRAPH.EXPORT_TITLE'), merges, this.columnsList, 1, rowsStyles, false);
  }

  sort() {
    this.sortData = !this.sortData;
    this.refreshGraph();
  }

  revenueType: string = 'Nett';

  setRevenueType() {
    this.revenueType = this.revenueType == 'Gross' ? 'Nett' : 'Gross';
    this.refreshGraph();
  }

  getRevenueTypeTooltip() {
    if (this.revenueType == 'Gross') {
      return this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.GROSS');
    } else {
      return this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.NETT');
    }
  }

  whatType(previous: boolean = false) {
    if (!previous) {
      if (this.revenueType == 'Gross') {
        return 'CurrentGrossValue'
      } else {
        return 'CurrentValue';
      }
    } else {
      if (this.revenueType == 'Gross') {
        return 'PreviousGrossValue'
      } else {
        return 'PreviousValue';
      }
    }

  }

  getTitle() {
    if (this.revenueType == 'Gross') {
      return this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.TITLE_GROSS');
    } else {
      return this.translate.instant('CONTOECONOMICO.DASHBOARD.INDICATORI_PERFORMANCE.TITLE_NETT');
    }
  }
}


