import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FiltriService } from '@app/core/services/filtri.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

import { BehaviorSubject, Subscription, combineLatest, lastValueFrom } from 'rxjs';
import { ScadenziarioService } from './scadenziario.service';
import { ScadenziarioTabellaComponent } from './scadenziario-tabella/scadenziario-tabella.component';
import { ScadenziarioGraficoComponent } from './scadenziario-grafico/scadenziario-grafico.component';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-scadenziario',
    templateUrl: './scadenziario.component.html',
    styleUrls: ['./scadenziario.component.scss']
})
export class ScadenziarioComponent implements OnInit {

    public scadenziarioTabella!: ScadenziarioTabellaComponent;
    @ViewChild('tabella', { static: false }) set _scadenziarioTabella(content: ScadenziarioTabellaComponent) {
        if (content) { // initially setter gets called with undefined
            this.scadenziarioTabella = content;

            this.manageView();
        }
    }

    public scadenziarioGrafico!: ScadenziarioGraficoComponent;
    @ViewChild('grafico', { static: false }) set _scadenziarioGrafico(content: ScadenziarioGraficoComponent) {
        if (content) { // initially setter gets called with undefined
            this.scadenziarioGrafico = content;

            this.manageView();
        }
    }

    
    currentView: string = '';
    filtriServiceSubscription!: Subscription;
    currentFilterCfg: any = {
        Period: 'YTD',
        Type: 1, // supplier
        Status: 2 // not payed
    }

    banksList: any;
    bankOperationsList: any;
    paymentModalityList: any;
    scadenze: any;

    constructor(
        private scadenziarioService: ScadenziarioService,
        private filtriService: FiltriService,
        private staticCollectionsService: StaticCollectionsService,
        private ref: ChangeDetectorRef,
        public loaderService: LoaderService
    ) {
        //this.currentView = 'table';
    }

    ngOnDestroy(): void {
        this.filtriServiceSubscription.unsubscribe();
    }

    async ngOnInit() {
        document.body.classList.add('kt-aside--minimize');

        this.loaderService.show();

        await this.staticCollectionsService.fillStaticCollection(false);

        const values = await lastValueFrom(
            combineLatest([this.scadenziarioService.getBanks(), this.scadenziarioService.getBankOperations(), this.scadenziarioService.getPaymentModality()])
        );
        this.banksList = values[0];
        this.bankOperationsList = values[1];
        this.paymentModalityList = values[2];

        this.loaderService.hide();

        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
                    return
                };
                if (filterConfig.constructor !== Object) {
                    return
                };

                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                if (!this.currentFilterCfg.Type) this.currentFilterCfg.Type = 1;
                if (!this.currentFilterCfg.Status && this.currentFilterCfg.Status !== 0) this.currentFilterCfg.Status = 2;

                // Al primo avvio non ho la currentView impostata, allora la setto e lascio che sia il child ad autosettarsi
                if(!this.currentView) {
                    this.currentView = 'graph';
                    this.ref.detectChanges();
                } else if (this.scadenziarioGrafico || this.scadenziarioTabella) {
                    this.manageView();
                }

            }
        );

        this.currentView = 'graph';
    }

    manageView() {            
        switch (this.currentView) {
            case 'graph':
                this.scadenziarioGrafico.currentFilterCfg = this.currentFilterCfg;
                this.scadenziarioGrafico.getScadenze(true);
                break;

            case 'table': {
                this.scadenziarioTabella.currentFilterCfg = this.currentFilterCfg;
                if (this.scadenziarioTabella.table) {
                    this.scadenziarioTabella.table.draw()
                } else {
                    this.scadenziarioTabella.initDataTable();
                }
            }
        }
    }

    onLoading(event: any) {
        if(event) {
            this.loaderService.show();
        } else {
            this.loaderService.hide();
        }
    }

}
