import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { LayoutConfigService } from '@app/core/_base/layout';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';

import * as util from '@app/core/services/utilityfunctions';
// Material
import { MatDialog } from '@angular/material/dialog';

import { InserimentoAcquistiPopupComponent } from '@app/views/pages/magazzino/acquisti/inserimento-acquisti-popup/inserimento-acquisti-popup.component';
import { InserimentoInventariPopupComponent } from '@app/views/pages/magazzino/inventari/inserimento-inventari-popup/inserimento-inventari-popup.component';
import { InserimentoRettifichePopupComponent } from '@app/views/pages/magazzino/sprechi/inserimento-rettifiche-popup/inserimento-rettifiche-popup.component';
import { InserimentoTrasferimentiPopupComponent } from '@app/views/pages/magazzino/trasferimenti/inserimento-trasferimenti-popup/inserimento-trasferimenti-popup.component';
import { InserimentoGiacenzePopupComponent } from '@app/views/pages/magazzino/giacenze/inserimento-giacenze-popup/inserimento-giacenze-popup.component';
import { CercaMerceComponent } from './cerca-merce/cerca-merce.component';

import { RoleService } from '@app/core/_base/layout/services/role.service';
import * as _ from 'lodash';

import { EditNotesComponent } from '@app/views/pages/magazzino/components/edit-notes/edit-notes.component';
import { EditTagsComponent } from '@app/views/pages/magazzino/components/edit-tags/edit-tags.component';

import { ScreenSizeService } from '@app/core/services/screen-size.service';

import { FormControl } from '@angular/forms';

// Services
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { EditMerciComponent } from '@app/views/pages/gestione-dati/gestione-merci/edit-merci/edit-merci.component';
import moment from 'moment';
import { ExcelService } from '@app/core/services/excel.service';
import { LoaderService } from '@app/core/services/loader.service';

declare var $: any;

export enum InserimentoType {
  ACQUISTI = 'Purchase',
  GIACENZE = 'Stock',
  INVENTARI = 'Inventory',
  SPRECHI = 'Adjustment',
  TRASFERIMENTI = 'Transfert'
}

enum COLUMNS_INDEX {
  CODICE = 0,
  PRODOTTO = 1,
  QUANTITA_MAGAZZINO_UNITA_MAGAZZINO = 2,
  GIACENZE = 3,
  DIFFERENZA = 4,
  UNITA_MAGAZZINO = 5,
  QUANTITA_UNITA_BASE = 6,
  UNITA_BASE = 7,
  PREZZO_UNITA_MAGAZZINO = 8,
  SCONTI = 9,
  SCONTO = 10,
  TOTALE = 11,
  CENTRO_COSTO = 12,
  ACTIONS = 13
}

@Component({
  selector: 'kt-inserimento-comune',
  templateUrl: './inserimento-comune.component.html',
  styleUrls: ['./inserimento-comune.component.scss', '../common-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InserimentoComuneComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('SogliaVariazionePrezzoPerc', { static: true }) SogliaVariazionePrezzoPerc!: ElementRef;

  currentConfig: any;
  inserimentoType!: InserimentoType;
  referral!: string;
  isInventoryEditFromNew: boolean = false;

  // Filtri
  listaCategorie: any;
  categorieFiltro: any = '';
  // productOrCode!: string;
  hidePricesZero: boolean = false;
  qtyHigherZero: boolean = false;
  showOnlyEmptyCells: boolean = false;
  hideDisabled: boolean = false;
  sogliaVariazionePrezzoPerc: number = 15;
  groupByQty: boolean = true;

  // Tabella
  lista: any;
  //listaInfiniteScroll: any;
  listaFiltered: any;
  sumTotale!: number;
  canSave = false;
  table: any;
  locale!: string;
  allFlagged: boolean = false;
  // Input
  filterChanged: Subject<any> = new Subject<any>();

  switchGroupSubcription!: Subscription;

  showKeyboard: boolean = false;

  isAcquisto: boolean = false;
  itemId: any; // Modalità edit

  utility: any;

  restoreMap = {};

  enablePopup: boolean = false;

  isMobile: boolean = false;

  currentMobileItemSelected: any
  currentMobileItemTarget: any;
  amount: any;
  pagetList: any;

  pageStart!: 1;

  goods: any;

  public get COLUMNS_INDEX(): typeof COLUMNS_INDEX {
    return COLUMNS_INDEX;
  }

  public get INSERIMENTO_TYPE(): typeof InserimentoType {
    return InserimentoType;
  }

  performSwitchLanguageSubscription!: Subscription;
  screenSizeServiceSubscription!: Subscription;
  routeSubscription!: Subscription;

  totVisibleColumns: number = 0;

  warehouseWorker!: Worker;

  showRappZeroWarningIcon: boolean = false;

  constructor(
    private magazzinoService: MagazzinoService,
    private translate: TranslateService,
    private layoutConfigService: LayoutConfigService,
    private layoutUtilsService: LayoutUtilsService,
    private translationService: TranslationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public staticCollectionsService: StaticCollectionsService,
    public ref: ChangeDetectorRef,
    public dialog: MatDialog,
    public roleService: RoleService,
    private screenSizeService: ScreenSizeService,
    private switchGroupService: SwitchGroupService,
    private gestioneMerciService: GestioneMerciService,
    private gestioneRicetteService: GestioneRicetteService,
    private anagraficheService: AnagraficheService,
    private excelService: ExcelService,
    public loaderService: LoaderService
  ) {

    this.performSwitchLanguageSubscription = this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });
    this.utility = util;

    this.screenSizeServiceSubscription = this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 1023;
    });

    this.filterChanged
      .pipe(
        debounceTime(800),
        //distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      )
      .subscribe();
  }

  /*---------------------------------------------------------------------------------------------------------*/
  /**
   * checkSogliaVariazionePrezzo
   * @param item 
   */
  checkSogliaVariazionePrezzo(item: any) {

    const baseItem = this.lista.find((baseItem: any) => item.Id == baseItem.Id)
    if (baseItem) {
      const priceIniziale = util.parseNumber(baseItem.Price, this.locale);
      const priceAttuale = util.parseNumber(item.Price, this.locale);
      const formula = Math.abs((priceAttuale - priceIniziale) * 100 / priceIniziale);
      if (formula > this.sogliaVariazionePrezzoPerc) {
        const message = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.WARNING_SOGLIA_VARIAZIONE_PREZZO_PERC');
        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 1000, true, false, 3000, 'top', 'snackbar-error');
      }
    }
  }
  /*---------------------------------------------------------------------------------------------------------*/
  clearFilters() {
    this.hidePricesZero = false;
    this.qtyHigherZero = false;
    this.showOnlyEmptyCells = false;
    this.hideDisabled = false;
    $('#productOrCode').val('');
    this.categorieFiltro = '';

    this.groupByQty = true;
    if (this.inserimentoType === InserimentoType.ACQUISTI) {
      this.sogliaVariazionePrezzoPerc = 15;
    }

    // Svuoto la lista filtrata
    this.listaFiltered = this.lista;
    this.ref.detectChanges();

    this.initTable();
  }
  /*---------------------------------------------------------------------------------------------------------*/
  filterQty(type: string) {

    if (type === 'qtyHigherZero') {
      this.showOnlyEmptyCells = this.qtyHigherZero ? false : this.showOnlyEmptyCells;
      this.filter();
    } else if (type === 'showOnlyEmptyCells') {
      this.qtyHigherZero = this.showOnlyEmptyCells ? false : this.qtyHigherZero;
      this.filter();
    }
  }

  filter() {
    const productOrCode: any = $('#productOrCode').val();

    this.listaFiltered = this.lista.filter((item: any) => {

      let accept = true;

      if (this.hidePricesZero && this.utility.parseNumber(item.Price.toString(), this.locale) === 0) {
        accept = false;
        // il controllo su UniqueId l'ho messo per non far mai scomparire i nuovi prodotti appena messi
        // Visualizza merci con quantità >= 0.
      }

      // Richiesta di edoardo 20230206 su slack
      // il filtro deve visualizzare tutte quelle che hanno qtà e sono fleggate a 0
      // Per cui ho aggiunto anche !item.flag

      if (this.qtyHigherZero && item.UniqueId && !item.flag) {
        // RIchiesta del 31/12/2021 di Edoardo su slack "il primo visualizza solo le righe che hanno il doppio valore (qtà inserita in precedenza)"
        if (this.isInventoryEditFromNew && this.utility.parseNumber(item.QuantitaMagazzinoOld, this.locale) == 0) {
          accept = false;
        } else if (!this.isInventoryEditFromNew && this.utility.parseNumber(item.QuantitaMagazzino, this.locale) == 0) {
          accept = false;
        }
      }

      if (this.showOnlyEmptyCells) {

        if (!this.isInventoryEditFromNew && this.utility.parseNumber(item.QuantitaMagazzino, this.locale) !== 0 || item.flag) {
          accept = false;

          // Edoardo Scinetti  08/11/2021 12:30 PM SLACK
          // ho visto
          // ma dovrebbe visualizzare solo le righe che non hanno un valore già inserito (quelli in rosso)
          //} else if (this.isInventoryEditFromNew && (this.utility.parseNumber(item.QuantitaMagazzinoOld, this.locale) == 0 && !item.flag)) {

          // 2000704
          // Ricambiato perchè edoardo chiede di poter vedere le merci che non sono state ancora inserite
          // con messaggio slack 01/07/2022 02:00 PM
        } else if (this.isInventoryEditFromNew && (this.utility.parseNumber(item.QuantitaMagazzinoOld, this.locale) > 0 || item.flag)) {
          accept = false;
        }

      }

      if (this.hideDisabled && item.IsDisabled) {
        accept = false;
      }

      if (productOrCode && !item.Code.toLowerCase().includes(productOrCode.toLowerCase()) && !item.Name.toLowerCase().includes(productOrCode.toLowerCase())) {
        accept = false;

      }

      if (this.categorieFiltro && this.categorieFiltro.length > 0 && item.CategoryId && !this.categorieFiltro.includes(item.CategoryId.toString())) {
        accept = false;
      }

      return accept;

    });

    this.ref.detectChanges();

    this.initTable();
  }

  /*---------------------------------------------------------------------------------------------------------*/
  getRows(results: any) {
    let needFilter = false;
    if (!results || results.length == 0) {
      //TODO --> INSERIRE POPUP CON MESSAGGIO "Nessuna merce disponibile" per il fornitore selezionato
      this.router.navigate([`${this.referral}`], { queryParams: {} });
      return;
    }
    this.listaCategorie = [];
    let hasImport: any = undefined;
    results.forEach((item: any) => {
      // if (!item.QuantitaMagazzino) item.QuantitaMagazzino = util.formatNumber(0, this.locale);
      // if (!item.QuantitaUnitaBase) item.QuantitaUnitaBase = util.formatNumber(0, this.locale);

      // Calcolo lo sconto
      item['Sconto'] = this.utility.formatNumber(this.calcDiscount(item), this.locale);

      let exists = this.listaCategorie.find(category => category.Id === item.CategoryId);
      if (!exists) {
        this.listaCategorie.push({
          Id: item.CategoryId,
          Name: item.Category
        });
      }

      //this.inserimentoType === InserimentoType.ACQUISTI &&
      if (this.currentConfig.prePop) {
        let prepop: any = undefined;
        if (this.inserimentoType === this.INSERIMENTO_TYPE.INVENTARI || this.inserimentoType == this.INSERIMENTO_TYPE.SPRECHI || this.inserimentoType == this.INSERIMENTO_TYPE.TRASFERIMENTI) {
          prepop = this.currentConfig.prePop.Items.find(p => p.Id.toString() === item.Id.toString() && p.Code.toLowerCase() === item.Code.toLowerCase());
        } else {
          prepop = this.currentConfig.prePop.Items.find(p => p.ReferenceId ? (p.ReferenceId === item.ReferenceId && p.Code.toLowerCase() === item.Code.toLowerCase()) : p.Id.toString() === item.Id.toString());
        }

        if (prepop) {
          if (prepop.SecondaryDDTQuantity) {
            //ORDINE INTERNO
            let qm = prepop.SecondaryDDTQuantity ? prepop.SecondaryDDTQuantity : item.SecondaryQuantity;
            item.QuantitaMagazzino = qm;
            let qb = prepop.BaseDDTQuantity ? prepop.BaseDDTQuantity : item.BaseQuantity;
            item.QuantitaUnitaBase = qb;
            item.Sconto = this.utility.formatNumber(this.calcDiscount(prepop), this.locale);
            // item.Price = prepop.SecondaryPrice;

          } else {
            let qm = prepop.Quantity >= 0 ? prepop.Quantity : (item.QuantitaMagazzino >= 0 ? item.QuantitaMagazzino : 0);
            // let qm = prepop.Quantity ? prepop.Quantity : item.QuantitaMagazzino;
            item.QuantitaMagazzino = qm;

            let qb = prepop.BaseQuantity ? prepop.BaseQuantity : item.QuantitaUnitaBase;
            if (!qb) {
              item.QuantitaUnitaBase = prepop.SecondaryUnitRatio * qm;
            } else {
              item.QuantitaUnitaBase = qb;
            }

            if (this.isInventoryEditFromNew) {
              item.QuantitaMagazzinoOld = item.QuantitaMagazzino;
              item.QuantitaUnitaBaseOld = item.QuantitaUnitaBase;
              // item.QuantitaMagazzino = util.formatNumber(0, this.locale);
              // item.QuantitaUnitaBase = util.formatNumber(0, this.locale);
              item.QuantitaMagazzino = 0;
              item.QuantitaUnitaBase = 0;
              item.ReferenceId = prepop.ReferenceId;

              // http://mantis.fbmanager.com/view.php?id=1156
              if (this.utility.parseNumber(item.QuantitaMagazzinoOld, this.locale) === 0) {
                item.flag = true;
              }
            } else {
              // http://mantis.fbmanager.com/view.php?id=1156
              if (this.utility.parseNumber(item.QuantitaMagazzino, this.locale) === 0) {
                item.flag = true;
              }
            }
            item.Sconto = prepop.Discount !== undefined ? prepop.Discount * 100 : this.calcDiscount(prepop);

          }
          item.Tags = prepop.Tags ? prepop.Tags : [];
          item.Price = prepop.Price ? prepop.Price : item.Price; //Variazione prezzo sia per ordini interni che gli altri
          // Edoardo il su slack 07/04/2022 11:30
          // ho trovato un baco urgente sugli acquisti
          // se creo un acquisto richiamandolo da ordine mette il prezzo aggiungendo lo sconto che è errato
          //if (util.parseNumber(item.Sconto, this.locale) > 0) {
          //    if (util.parseNumber(item.Sconto, this.locale) >= 100) {
          //        item.Price = 0;
          //    } else {
          //        item.Price = item.Price / (1 - util.parseNumber(item.Sconto, this.locale) / 100);
          //    }
          //}
          let t = util.parseNumber(item.QuantitaMagazzino, this.locale) * util.parseNumber(item.Price, this.locale);
          if (util.parseNumber(item.Sconto, this.locale) > 0) {
            t = t * (1 - util.parseNumber(item.Sconto, this.locale) / 100);
          }
          item.Totale = t; // util.formatNumber(t, this.locale);
          item['CostCenter'] = item.CostCenterId
          this.qtyHigherZero = this.inserimentoType !== InserimentoType.INVENTARI ? true : false;
          this.showOnlyEmptyCells = false;
          this.groupByQty = true;
          needFilter = true;
        }
        else {

        }

      }

      if (this.currentConfig.body.import && this.currentConfig.body.import.length > 0) {

        hasImport = this.currentConfig.body.import.find((imp: any) => imp.GoodId == item.Id && imp.CodiceMerce == item.Code);
        if (hasImport) {
          //if(item.SecondaryUnitRatio===0) item.SecondaryUnitRatio=1;
          // Se sono presenti entrambi i valori di Quantità Base, e Quantità Magazzino
          if (hasImport.Quantity >= 0 && hasImport.BaseQuantity >= 0) {
            item.QuantitaMagazzino = hasImport.Quantity + (hasImport.BaseQuantity / item.SecondaryUnitRatio);
            item.QuantitaUnitaBase = item.SecondaryUnitRatio * item.QuantitaMagazzino;

          } else if (hasImport.Quantity >= 0) {
            item.QuantitaMagazzino = hasImport.Quantity;
            item.QuantitaUnitaBase = item.SecondaryUnitRatio * hasImport.Quantity;
          }
          else if (hasImport.BaseQuantity >= 0) {
            item.QuantitaMagazzino = hasImport.BaseQuantity / item.SecondaryUnitRatio;
            item.QuantitaUnitaBase = hasImport.BaseQuantity;
          }
          const sconto = this.utility.parseNumber(item.Sconto.toString(), this.locale, 3)

          if (sconto > 0) {
            if (sconto >= 100) {
              item.Price = 0;
            } else {
              item.Price = item.Price / (1 - sconto / 100);
            }
          }

          // Faccio questa preconversione, perchè altrimenti quando importo
          // tiene conto di troppi numeri dopo la virgola, mentre nel warehouse no,
          // quindi i valori posso differire. In questo modo sono identici.

          const qtaMag = util.formatNumber(item.QuantitaMagazzino, this.locale);
          const price = util.formatNumber(item.Price, this.locale, 3);

          let t = util.parseNumber(qtaMag, this.locale) * util.parseNumber(price, this.locale);
          if (util.parseNumber(item.Sconto, this.locale) > 0) {
            //const sconto = util.formatNumber(item.Sconto, this.locale, 3);
            t = t * (1 - util.parseNumber(sconto, this.locale) / 100);
          }
          item.Totale = t; // util.formatNumber(t, this.locale);

          item['flag'] = item.Totale == 0 && item.Price > 0;
        }

      }


      if (!this.currentConfig.prePop && (!this.currentConfig.body.import || this.currentConfig.body.import.length == 0)) {
        item.QuantitaMagazzino = undefined;
        item.QuantitaUnitaBase = undefined;
        item['CostCenter'] = this.currentConfig.body.CostCenterId ? this.currentConfig.body.CostCenterId : undefined;
      }


      // Formattazione

      item.Price4 = util.formatNumber(item.Price, this.locale, 4);
      item.QuantitaMagazzino4 = util.formatNumber(item.QuantitaMagazzino, this.locale, 4);
      item.QuantitaUnitaBase4 = util.formatNumber(item.QuantitaUnitaBase, this.locale, 4);
      item.Totale4 = util.formatNumber(item.Totale, this.locale, 4);
      item.Sconto4 = util.formatNumber(item.Sconto, this.locale, 4);

      item.Price = util.formatNumber(item.Price, this.locale, 3);
      item.QuantitaMagazzino = util.formatNumber(item.QuantitaMagazzino, this.locale, 3);
      item.QuantitaUnitaBase = util.formatNumber(item.QuantitaUnitaBase, this.locale, 3);
      item.Totale = util.formatNumber(item.Totale, this.locale);
      item.Sconto = util.formatNumber(item.Sconto, this.locale);

      item.InitialPrice = item.Price;
    });

    if (this.inserimentoType === InserimentoType.ACQUISTI && this.currentConfig.prePop) {
      this.lista = results.filter((item: any) => {
        return util.parseNumber(item.QuantitaMagazzino, this.locale) != 0;
      });
    } else {
      this.lista = results;
    }

    this.lista.forEach((element: any) => {
      element.UniqueId = element.Category + "|" + element.Id + "|" + element.Code + "|" + element.ReferenceId;
    });

    this.sumTotale = this.getSumTotale();

    if (needFilter && !this.currentConfig.isNewInvetoryInSameDay) {
      this.filter();
    } else {
      this.initTable();
    }
  }

  /*---------------------------------------------------------------------------------------------------------*/
  initInserimento() {
    this.loaderService.show();
    this.ref.detectChanges();
    let f = JSON.parse(JSON.stringify(this.currentConfig.body));
    if (this.inserimentoType === "Purchase" && this.currentConfig.prePop && this.currentConfig.prePop.OrderId) {
      f.hideExcluded = false;
      f.hideInvisible = false;
    }
    lastValueFrom(this.magazzinoService.populate(f))
      .then((results: any) => {
        this.getRows(results);
        this.loaderService.hide();
        this.ref.detectChanges();
      })
      .catch((error: any) => {
        console.log(error);
        this.loaderService.hide();
        this.ref.detectChanges();
        this.router.navigate([`${this.referral}`], { queryParams: {} });
      });

  }
  /*---------------------------------------------------------------------------------------------------------*/
  ngOnDestroy() {
    if (this.performSwitchLanguageSubscription) this.performSwitchLanguageSubscription.unsubscribe();
    if (this.screenSizeServiceSubscription) this.screenSizeServiceSubscription.unsubscribe();
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
    if (this.switchGroupSubcription) this.switchGroupSubcription.unsubscribe();
  }

  ngOnInit() {

    if (typeof Worker !== 'undefined') {
      this.warehouseWorker = new Worker(new URL('../../../../workers/warehouse.worker', import.meta.url));
      this.warehouseWorker.onmessage = (response: any) => {
        switch (response.data.operation) {
          case 'updateRowCalculation':


            // Se ho la lista dei filtrati attiva verifico che ci sia l'indice >= 0,
            // se l'indice è < 0 vuol dire che è nuovo elemento e lo devo inserire.

            if (this.listaFiltered && this.listaFiltered.length > 0) {
              // Aggiorno l'attuale lista filtrata, serve poi per i filtri
              // per non perdere i valori inseriti.
              if (response.data.indexListaFiltered && response.data.indexListaFiltered >= 0 && response.data.indexListaFiltered < this.listaFiltered.length) {
                this.listaFiltered[response.data.indexListaFiltered].QuantitaMagazzino = response.data.item.QuantitaMagazzino;
                this.listaFiltered[response.data.indexListaFiltered].QuantitaUnitaBase = response.data.item.QuantitaUnitaBase;
                this.listaFiltered[response.data.indexListaFiltered].Sconto = response.data.item.Sconto;
                this.listaFiltered[response.data.indexListaFiltered].Totale = response.data.item.Totale;
                this.listaFiltered[response.data.indexListaFiltered].Price = response.data.item.Price;

                this.listaFiltered[response.data.indexListaFiltered].QuantitaMagazzino4 = response.data.item.QuantitaMagazzino4;
                this.listaFiltered[response.data.indexListaFiltered].QuantitaUnitaBase4 = response.data.item.QuantitaUnitaBase4;
                this.listaFiltered[response.data.indexListaFiltered].Sconto4 = response.data.item.Sconto4;
                this.listaFiltered[response.data.indexListaFiltered].Totale4 = response.data.item.Totale4;
                this.listaFiltered[response.data.indexListaFiltered].Price4 = response.data.item.Price4;

                // Vuol dire che è un record appena aggiunto, lo devo inserire
              } else if (response.data.indexListaFiltered && response.data.indexListaFiltered == -1) {
                this.listaFiltered.push(response.data.item);
              }
            }

            // Aggiorno anche la lista originale, serve poi per i filtri
            // per non perdere i valori inseriti.	
            if (response.data.indexLista < this.lista.length) {
              if (response.data.indexLista >= 0) {
                this.lista[response.data.indexLista].QuantitaMagazzino = response.data.item.QuantitaMagazzino;
                this.lista[response.data.indexLista].QuantitaUnitaBase = response.data.item.QuantitaUnitaBase;
                this.lista[response.data.indexLista].Sconto = response.data.item.Sconto;
                this.lista[response.data.indexLista].Totale = response.data.item.Totale;
                this.lista[response.data.indexLista].Price = response.data.item.Price;

                this.lista[response.data.indexLista].QuantitaMagazzino4 = response.data.item.QuantitaMagazzino4;
                this.lista[response.data.indexLista].QuantitaUnitaBase4 = response.data.item.QuantitaUnitaBase4;
                this.lista[response.data.indexLista].Sconto4 = response.data.item.Sconto4;
                this.lista[response.data.indexLista].Totale4 = response.data.item.Totale4;
                this.lista[response.data.indexLista].Price4 = response.data.item.Price4;
              } else {
                this.lista.push(response.data.item);
              }
            }

            // Aggiorno il totale
            this.sumTotale = this.getSumTotale();

            // Controllo eventuale soglia variazione prezzo
            if (this.isAcquisto && response.data.sogliaVariazionePrezzo > this.sogliaVariazionePrezzoPerc) {
              const message = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.WARNING_SOGLIA_VARIAZIONE_PREZZO_PERC');
              this.layoutUtilsService.showActionNotification(message, MessageType.Error, 1000, true, false, 3000, 'top', 'snackbar-error');
            }

            this.ref.detectChanges();
            break;
        }
      };
    }

    this.currentConfig = this.magazzinoService.getCurrentConfig();

    this.routeSubscription = this.activatedRoute.queryParams.subscribe((params: any) => {
      this.referral = params.referral ? params.referral : '/';
      this.isInventoryEditFromNew = params.isEditFromNew === 'true';
      if (!this.currentConfig) {
        this.router.navigate([`${this.referral}`], { queryParams: {} });
        return;
      }

      if (params.inserimentoType) {

        this.inserimentoType = <InserimentoType>params.inserimentoType;

        this.isAcquisto = this.inserimentoType === InserimentoType.ACQUISTI;
        this.itemId = params.itemId;

        if (this.isAcquisto) {
          this.loaderService.show();
          this.ref.detectChanges();

          const filter = {};
          if (this.currentConfig) {

            filter['SupplierIds'] = [this.currentConfig.body.SupplierId];
            filter['OnlyVisibleForCompany'] = true;
            filter['Companies'] = [];
            filter['Companies'].push(this.currentConfig.body.Company);
          }
          filter['EnableState'] = 'yes';
          this.gestioneMerciService.getGoods(filter, false).toPromise().then(
            (goods: any) => {
              this.goods = goods;
              if (this.itemId && !this.isInventoryEditFromNew) {
                this.getRows(this.currentConfig.items);
                this.editPopup(this.currentConfig.body);

              } else {
                this.initInserimento();
              }
            });


        } else {

          if (this.itemId && !this.isInventoryEditFromNew) {
            this.getRows(this.currentConfig.items);
            this.editPopup(this.currentConfig.body);

          } else {
            this.initInserimento();
          }
        }


      } else {
        this.router.navigate([`${this.referral}`], { queryParams: {} });
        return;
      }

      //http://localhost:4200/magazzino/inserimento-comune?inserimentoType=ACQUISTI&referral=%2Fmagazzino%2Facquisti
    });

    $.fn.dataTable.ext.order['elementToOrder'] = (settings, col) => {
      const api = new $.fn.dataTable.Api(settings);
      return api.column(col, { order: 'index' }).nodes().map((td, i) => {
        return this.getElementToOrder(td);
      });
    };

    $.fn.dataTable.ext.order['elementToOrderFromInput'] = (settings, col) => {
      // Crea un'istanza dell'API di DataTables a partire dalle impostazioni
      const api = new $.fn.dataTable.Api(settings);
      return api.column(col, { order: 'index' }).nodes().map((td, i) => {
        // Verifico prima che non ci sia un elemento con la classe elementToOrder
        // Utile per le colonne che se non sono in modifica, non hanno l'input
        const elementToOrder = this.getElementToOrder(td);
        if (elementToOrder) {
          return elementToOrder;
        } else {
          const inputVal = $(td).find('input').val();
          const content: string = (inputVal !== undefined && inputVal !== null ? inputVal : "0") + "";
          return this.utility.parseNumber(content, this.locale);
        }
      });
    };

    $.fn.dataTable.ext.order['elementToOrderFromSelect'] = (settings, col) => {
      // Crea un'istanza dell'API di DataTables a partire dalle impostazioni
      const api = new $.fn.dataTable.Api(settings);
      return api.column(col, { order: 'index' }).nodes().map((td, i) => {
        // Verifico prima che non ci sia un elemento con la classe elementToOrder
        // Utile per le colonne che se non sono in modifica, non hanno l'input
        const elementToOrder = this.getElementToOrder(td);
        if (elementToOrder) {
          return elementToOrder;
        } else {
          const inputVal = $(td).find('select option:selected').text();
          const content: string = (inputVal !== undefined && inputVal !== null ? inputVal : "") + "";
          return content
        }
      });
    }

  }

  getElementToOrder(td: any) {
    // Crea un elemento temporaneo e restituisce il valore numerico contenuto
    const div = document.createElement('div');
    div.innerHTML = $(td).find('.elementToOrder').text();
    const content: string = div.textContent || div.innerText || "";
    return content;
  }

  /*---------------------------------------------------------------------------------------------------------*/
  createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }

  /*---------------------------------------------------------------------------------------------------------*/
  /**
   * After view init
   */
  ngAfterViewInit(): void {

    $('#productOrCode').keydown((ev: any) => {
      // this.filterProductOrCodeSubj.next(ev)
      const keyCode = ev.code;
      if (keyCode === 'Tab') {
        ev.preventDefault();
        ev.stopPropagation();
        let element = $('.td_quantitamagazzino').first();
        setTimeout(() => {
          $(element).find('input').select();
          $(element).find('input').focus();
          this.ref.detectChanges();
        }, 100);
      } else if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
        this.filterChanged.next(true);
      }

    });

    this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
      (event: any) => {
        if (event.change) {
          this.goBack();
        }
      }
    );

  }
  /*---------------------------------------------------------------------------------------------------------*/
  showMobileKeyboard() {
    this.showKeyboard = !this.showKeyboard && this.isMobile;
    this.ref.detectChanges();
  }
  /*---------------------------------------------------------------------------------------------------------*/
  onAction(event: any) {
    switch (event.type) {
      case 'esc':
        this.showKeyboard = false;
        break;
      case 'enter':
        this.showKeyboard = false;

        let item = this.listaFiltered.find((i: any) => i.Id === this.currentMobileItemSelected.Id);
        if (item) {
          item['QuantitaUnitaBase'] = event.value;

          this.warehouseWorker.postMessage({
            operation: 'updateRowCalculation',
            item: item,
            type: 'QuantitaUnitaBase',
            lista: this.lista,
            listaFiltered: this.listaFiltered,
            inserimentoType: this.inserimentoType,
            locale: this.locale
          });

          this.currentMobileItemTarget.text(event.value);

          this.currentMobileItemSelected = undefined;
          this.currentMobileItemTarget = undefined;
        }
        break;
    }
    this.ref.detectChanges();
  }
  /*---------------------------------------------------------------------------------------------------------*/
  checkGroupByQty() {
    this.table.column(COLUMNS_INDEX.GIACENZE).visible(this.groupByQty ? false : true);
    this.table.columns.adjust().draw();
  }

  initTable() {
    if (this.isMobile) {
      this.initResponsiveTable();
    } else {
      //if (this.table) {
      //  this.table.draw();
      //} else {
      this.initDataTable();
      //}
    }
  }
  /*---------------------------------------------------------------------------------------------------------*/
  initResponsiveTable() {
    setTimeout(() => {

      $('.tableScrollMobile td.hasInput').off();
      $('.tableScrollMobile td.hasInput').on('click', (ev) => {
        ev.preventDefault();
        //ev.stopPropagation();
        this.currentMobileItemSelected = $(ev.target).data('item');
        this.currentMobileItemTarget = $(ev.target);
        this.amount = this.currentMobileItemSelected['QuantitaUnitaBase'];
        this.showMobileKeyboard();
      });

      setTimeout(() => {
        this.loaderService.hide();
        this.ref.detectChanges();
      }, 5000);

    }, 2000);
  }
  /*---------------------------------------------------------------------------------------------------------*/
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.table) {
      this.table.columns.adjust();
    }
  }

  /*---------------------------------------------------------------------------------------------------------*/
  initDataTable() {
    if ($.fn.dataTable.isDataTable('#warehouseInsertTable')) {
      $('#warehouseInsertTable').DataTable().destroy();
    }

    setTimeout(() => {
      this.table = $('#warehouseInsertTable').DataTable({
        initComplete: (settings: any, json: any) => {
          this.loaderService.hide();
        },
        destroy: true,
        paging: true,
        searching: false,
        ordering: true,
        orderCellsTop: true,
        scroller: true,
        scrollCollapse: true,
        scrollY: "calc(100vh - 26rem)",
        scrollX: false,
        autoWidth: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
          processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
        },

        columnDefs: [
          { targets: [COLUMNS_INDEX.CODICE], width: '110px', orderDataType: 'elementToOrder' },
          { targets: [COLUMNS_INDEX.PRODOTTO], orderDataType: 'elementToOrder' },
          { targets: [COLUMNS_INDEX.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO], width: '90px', className: 'text-right', orderDataType: 'elementToOrderFromInput', type: 'num-html' },
          { targets: [COLUMNS_INDEX.GIACENZE], width: '85px', visible: !this.groupByQty, className: 'text-right', type: 'num-html' },
          { targets: [COLUMNS_INDEX.DIFFERENZA], width: '85px', visible: false, className: 'text-right', type: 'num-html' },
          { targets: [COLUMNS_INDEX.UNITA_MAGAZZINO], width: '60px', visible: this.inserimentoType !== InserimentoType.GIACENZE },
          { targets: [COLUMNS_INDEX.QUANTITA_UNITA_BASE], width: '90px', className: 'text-right', orderDataType: 'elementToOrderFromInput', type: 'num-html' },
          { targets: [COLUMNS_INDEX.UNITA_BASE], width: '60px', className: 'text-right' },
          { targets: [COLUMNS_INDEX.PREZZO_UNITA_MAGAZZINO], width: '90px', className: 'text-right', orderDataType: 'elementToOrderFromInput', type: 'num-html' },
          { targets: [COLUMNS_INDEX.SCONTI], width: '60px', className: 'text-right', orderable: false, visible: this.inserimentoType === InserimentoType.ACQUISTI },
          { targets: [COLUMNS_INDEX.SCONTO], width: '70px', className: 'text-right', orderDataType: 'elementToOrderFromInput', type: 'num-html', visible: this.inserimentoType !== InserimentoType.TRASFERIMENTI && this.inserimentoType !== InserimentoType.SPRECHI && this.inserimentoType !== InserimentoType.INVENTARI },
          { targets: [COLUMNS_INDEX.TOTALE], width: '80px', className: 'text-right', orderDataType: 'elementToOrderFromInput', type: 'num-html' },
          { targets: [COLUMNS_INDEX.CENTRO_COSTO], width: '160px', orderDataType: 'elementToOrderFromSelect', visible: this.inserimentoType !== InserimentoType.INVENTARI && this.inserimentoType !== InserimentoType.TRASFERIMENTI && this.inserimentoType !== InserimentoType.SPRECHI },
          { targets: [COLUMNS_INDEX.ACTIONS], width: '60px', className: 'text-right', orderable: false },
        ]
      });

      let element = $('.td_quantitamagazzino').first();
      setTimeout(() => {
        $(element).find('input').select();
        $(element).find('input').focus();
        this.ref.detectChanges();
      }, 100);

      $('.input_quantitamagazzino').off();
      $('.input_quantitamagazzino').on({
        keydown: (event: any) => {
          if (event.code === 'Enter' || event.code == 'NumpadEnter' || event.code == 'Tab') {
            event.preventDefault();
            event.stopPropagation();
            // Se la lista è solo di 1 pezzo allora scateno il calcolo dei totali anche con l'invio
            if (this.listaFiltered.length == 1) {
              this.onInputChange(this.listaFiltered[0], 'QuantitaMagazzino', event);
            }

            // sposto il focus nella riga successiva nella stessa colonna
            const currentTd = $(event.target).closest('td');
            const nextTr = currentTd.closest('tr').next('tr');
            if (nextTr.length > 0) {
              const nextInput = nextTr.find('.input_quantitamagazzino');
              if (nextInput.length > 0) {
                nextInput.focus();
                nextInput.select();
              }
            }
          } else if (event.code === 'Space') {
            event.preventDefault();
            event.stopPropagation();
            const currentTd = $(event.target).closest('td');
            const nextInput = currentTd.find('~ td.hasInput').first().find('input');
            // Se c'è ancora un campo successivo 
            if (nextInput.length > 0) {
              nextInput.focus();
              nextInput.select();
            } else {
              // Altrimenti verifico se c'è una riga successiva
              const nextTr = currentTd.closest('tr').next('tr');
              if (nextTr.length > 0) {
                const nextInput = nextTr.find('td.hasInput').first().find('input');
                if (nextInput.length > 0) {
                  nextInput.focus();
                  nextInput.select();
                }
              } else {
                // Altrimenti non faccio nulla
              }
            }
          }
        }
      });

      $('.input_quantitaunitabase').off();
      $('.input_quantitaunitabase').on({
        keydown: (event: any) => {
          if (event.code === 'Enter' || event.code == 'NumpadEnter' || event.code == 'Tab') {
            event.preventDefault();
            event.stopPropagation();

            // Se la lista è solo di 1 pezzo allora scateno il calcolo dei totali anche con l'invio
            if (this.listaFiltered.length == 1) {
              this.onInputChange(this.listaFiltered[0], 'QuantitaUnitaBase', event);
            }

            // sposto il focus nella riga successiva nella stessa colonna
            const currentTd = $(event.target).closest('td');
            const nextTr = currentTd.closest('tr').next('tr');
            if (nextTr.length > 0) {
              const nextInput = nextTr.find('td.hasInput').eq(currentTd.index()).find('input');
              if (nextInput.length > 0) {
                nextInput.focus();
                nextInput.select();
              }
            }

          } else if (event.code === 'Space') {
            event.preventDefault();
            event.stopPropagation();
            const currentTd = $(event.target).closest('td');
            const nextInput = currentTd.find('~ td.hasInput').first().find('input');
            // Se c'è ancora un campo successivo 
            if (nextInput.length > 0) {
              nextInput.focus();
              nextInput.select();
            } else {
              // Altrimenti verifico se c'è una riga successiva
              const nextTr = currentTd.closest('tr').next('tr');
              if (nextTr.length > 0) {
                const nextInput = nextTr.find('td.hasInput').first().find('input');
                if (nextInput.length > 0) {
                  nextInput.focus();
                  nextInput.select();
                }
              } else {
                // Altrimenti non faccio nulla
              }
            }
          }
        }
      });

      $('.input_price').off();
      $('.input_price').on({
        keydown: (event: any) => {
          const keyCode = event.keyCode;
          if (event.code === 'Enter' || event.code == 'NumpadEnter') {
            event.preventDefault();
            event.stopPropagation();
            if (!this.isMobile) {
              $('#productOrCode').first().select();
              $('#productOrCode').first().focus();
            }
          } else if (event.code === 'Space') {
            event.preventDefault();
            event.stopPropagation();
            const currentTd = $(event.target).closest('td');
            const nextInput = currentTd.find('~ td.hasInput').first().find('input');
            // Se c'è ancora un campo successivo 
            if (nextInput.length > 0) {
              nextInput.focus();
              nextInput.select();
            } else {
              // Altrimenti verifico se c'è una riga successiva
              const nextTr = currentTd.closest('tr').next('tr');
              if (nextTr.length > 0) {
                const nextInput = nextTr.find('td.hasInput').first().find('input');
                if (nextInput.length > 0) {
                  nextInput.focus();
                  nextInput.select();
                }
              } else {
                // Altrimenti non faccio nulla
              }
            }
          }
        }
      });

      $('.input_sconto').off();
      $('.input_sconto').on({
        keydown: (event: any) => {
          const keyCode = event.keyCode;
          if (event.code === 'Enter' || event.code == 'NumpadEnter') {
            event.preventDefault();
            event.stopPropagation();
            if (!this.isMobile) {
              $('#productOrCode').first().select();
              $('#productOrCode').first().focus();
            }
          } else if (event.code === 'Space') {
            event.preventDefault();
            event.stopPropagation();
            const currentTd = $(event.target).closest('td');
            const nextInput = currentTd.find('~ td.hasInput').first().find('input');
            // Se c'è ancora un campo successivo 
            if (nextInput.length > 0) {
              nextInput.focus();
              nextInput.select();
            } else {
              // Altrimenti verifico se c'è una riga successiva
              const nextTr = currentTd.closest('tr').next('tr');
              if (nextTr.length > 0) {
                const nextInput = nextTr.find('td.hasInput').first().find('input');
                if (nextInput.length > 0) {
                  nextInput.focus();
                  nextInput.select();
                }
              } else {
                // Altrimenti non faccio nulla
              }
            }

          }
        }
      });

      $('.input_totale').off();
      $('.input_totale').on({
        keydown: (event: any) => {
          const keyCode = event.keyCode;
          if (event.code === 'Enter' || event.code == 'NumpadEnter') {
            event.preventDefault();
            event.stopPropagation();
            if (!this.isMobile) {
              $('#productOrCode').first().select();
              $('#productOrCode').first().focus();
            }
          } else if (event.code === 'Space') {
            event.preventDefault();
            event.stopPropagation();
            const currentTd = $(event.target).closest('td');
            const nextInput = currentTd.find('~ td.hasInput').first().find('input');
            // Se c'è ancora un campo successivo 
            if (nextInput.length > 0) {
              nextInput.focus();
              nextInput.select();
            } else {
              // Altrimenti verifico se c'è una riga successiva
              const nextTr = currentTd.closest('tr').next('tr');
              if (nextTr.length > 0) {
                const nextInput = nextTr.find('td.hasInput').first().find('input');
                if (nextInput.length > 0) {
                  nextInput.focus();
                  nextInput.select();
                }
              } else {
                // Altrimenti non faccio nulla
              }
            }

          }
        }
      });

      this.loaderService.hide();
      this.ref.detectChanges();

    }, 1000);
  }

  getDiscount(item: any, key: string) {
    if (!this.goods || this.goods.length == 0) return;

    const good = this.goods.find((good: any) => good.Code == item.Code);
    return good && good[key] ? good[key] : 0;
  }

  /*---------------------------------------------------------------------------------------------------------*/
  onFilterChange(event: any) {
    if (event.code === 'Enter' || event.code == 'NumpadEnter') {
      this.filterChanged.next(true);
    }
  }
  /*---------------------------------------------------------------------------------------------------------*/
  /**
   * 
   * @param item 
   * @param type 
   * @param event 
   * @returns 
   * 
   * Siccome al caricamento viene settato il focus sul primo input,
   * quando andavo a selezionare "Visualizza solo merci quanità > 0"
   * questo scatenava tramite l'evento blur un aggiornamento sulla prima riga,
   * che però nel frattempo era stata sostiuita dall'elemento filtrato che andava
   * a prendere un valore che non era il suo.
   * Allora mi faccio passare l'event, tramite il quale prendo il valore cambiato dall'utente
   * e l'item dove c'è il valore originale, se sono uguali non faccio nulla, così non faccio casini.
   */
  onInputChange(item: any, type: string, event: any) {
    const changedValue = $(event.target).val();

    if (changedValue == item[type]) return;

    item[type] = changedValue;

    this.warehouseWorker.postMessage({
      operation: 'updateRowCalculation',
      item: item,
      type: type,
      lista: this.lista,
      listaFiltered: this.listaFiltered,
      inserimentoType: this.inserimentoType,
      locale: this.locale
    });
  }
  /*---------------------------------------------------------------------------------------------------------*/
  getSumTotale() {
    let tot: number = 0;
    if (this.lista && this.lista.length > 0) {
      const onlyValid = this.lista.filter((i: any) => util.parseNumber(i.Totale, this.locale) > 0);
      onlyValid.forEach(item =>
        tot += util.parseNumber(item.Totale, this.locale)
      );
    }
    return tot;
  }
  /*---------------------------------------------------------------------------------------------------------*/
  IsSaveDisabled() {
    let disabled = true;
    if (this.lista) {
      this.lista.forEach((x: any) => {
        if (x.flag || x.QuantitaUnitaBase) {
          disabled = false;
          return;
        }
      });
    }

    return disabled && !this.canSave;
  }
  /*---------------------------------------------------------------------------------------------------------*/
  cleanRow(item: any) {
    item.QuantitaUnitaBase = undefined;
    item.QuantitaMagazzino = undefined;
    //item.Sconto = undefined;
    item.Totale = undefined;
    item.Price = item.InitialPrice;

    this.warehouseWorker.postMessage({
      operation: 'updateRowCalculation',
      item: item,
      type: 'QuantitaUnitaBase',
      lista: this.lista,
      listaFiltered: this.listaFiltered,
      inserimentoType: this.inserimentoType,
      locale: this.locale
    });
  }
  /*---------------------------------------------------------------------------------------------------------*/
  saveAsk() {

    let ask = '', title = '';
    switch (this.inserimentoType) {
      case InserimentoType.ACQUISTI:
        if (!this.itemId) {
          ask = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.INSERIMENTO_ASK');
          title = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.TITLE');
        } else {
          ask = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.INSERIMENTO_ASK_UPDATE');
          title = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.TITLE_UPDATE');
        }
        break;
      case InserimentoType.GIACENZE:
        if (!this.itemId) {
          ask = this.translate.instant('MAGAZZINO.GIACENZE.INSERIMENTO_GIACENZE.INSERIMENTO_ASK');
          title = this.translate.instant('MAGAZZINO.GIACENZE.INSERIMENTO_GIACENZE.TITLE');
        } else {
          ask = this.translate.instant('MAGAZZINO.GIACENZE.INSERIMENTO_GIACENZE.INSERIMENTO_ASK_UPDATE');
          title = this.translate.instant('MAGAZZINO.GIACENZE.INSERIMENTO_GIACENZE.TITLE_UPDATE');
        }
        break;
      case InserimentoType.INVENTARI:
        if (!this.itemId) {
          ask = this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.INSERIMENTO_ASK');
          title = this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.TITLE');
        } else {
          ask = this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.INSERIMENTO_ASK_UPDATE');
          title = this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.TITLE_UPDATE');
        }
        break;
      case InserimentoType.SPRECHI:
        if (!this.itemId) {
          ask = this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.INSERIMENTO_ASK');
          title = this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.TITLE');
        } else {
          ask = this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.INSERIMENTO_ASK_UPDATE');
          title = this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.TITLE_UPDATE');
        }
        break;
      case InserimentoType.TRASFERIMENTI:
        if (!this.itemId) {
          ask = this.translate.instant('MAGAZZINO.TRASFERIMENTI.INSERIMENTO_TRASFERIMENTI.INSERIMENTO_ASK');
          title = this.translate.instant('MAGAZZINO.TRASFERIMENTI.INSERIMENTO_TRASFERIMENTI.TITLE');
        } else {
          ask = this.translate.instant('MAGAZZINO.TRASFERIMENTI.INSERIMENTO_TRASFERIMENTI.INSERIMENTO_ASK_UPDATE');
          title = this.translate.instant('MAGAZZINO.TRASFERIMENTI.INSERIMENTO_TRASFERIMENTI.TITLE_UPDATE');

        }
        break;

      default:
        break;
    }

    const dangerRow = [];

    if (dangerRow.length > 0) {
      this.lista = this.lista.sort((a: any, b: any) => {
        return (a.QuantitaMagazzino && !a.Totale) || (a.QuantitaUnitaBase && !a.Totale) ? -1 : 1
      });
      this.ref.detectChanges();

      const _title: string = this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.DANGER_TITLE');
      const _description: string = this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.DANGER_ASK');
      const _waitDesciption: string = '';
      const _yesButton = this.translate.instant('COMMONS.SAVE');
      const _noButton = this.translate.instant('COMMONS.CANCEL');

      const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
      dialogRef.afterClosed().subscribe((expand: any) => {
        if (expand) {

          this.save();
          this.enablePopup = true;
          this.ref.detectChanges();
        }
      });

    } else {

      const _title: string = title;
      const _description: string = ask;
      const _waitDesciption: string = '';
      const _yesButton = this.translate.instant('COMMONS.SAVE');
      const _noButton = this.translate.instant('COMMONS.CANCEL');

      const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
      dialogRef.afterClosed().subscribe((expand: any) => {
        if (expand) {
          this.save();
          this.enablePopup = true;
          this.ref.detectChanges();
        }
      });
    }
  }

  async saveLocalExcel() {
    const _title: string = this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.SAVE_LOCAL_EXCEL');
    const _description: string = this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.SAVE_LOCAL_EXCEL_DESCR');
    const _waitDesciption: string = '';
    const _yesButton = this.translate.instant('COMMONS.SAVE');
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    const salvaCopiaInLocale = await dialogRef.afterClosed().toPromise();
    if (salvaCopiaInLocale) {
      // this.table.button(0).trigger();
      this.saveBackupXLSX();
    }
  }

  saveBackupXLSX() {
    let xlsx: any[] = [];

    let backupList = this.lista.filter(item => {
      return this.utility.parseNumber(item.QuantitaMagazzino, this.locale) > 0 || item.QuantitaMagazzino > 0 || item.flag;
    });

    // Ordina backupList in base al campo Category e poi al campo Name
    backupList = backupList.sort((a: any, b: any) => {
      if (a.Category < b.Category) return -1;
      if (a.Category > b.Category) return 1;
      if (a.Name < b.Name) return -1;
      if (a.Name > b.Name) return 1;
      return 0;
    });

    let blockedGoods: any[] = [];

    backupList.forEach((item: any, index: number) => {
      xlsx.push({
        [`Id`]: item.Id ? item.Id : '',
        [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE')}`]: item.Code ? item.Code : '',
        [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO')}`]: item.Name ? item.Name : '',
        [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE')}`]: this.utility.parseNumber(item.QuantitaMagazzino, this.locale) > 0 ? '' : this.utility.formatNumberExcel(this.utility.parseNumber(item.QuantitaUnitaBase, this.locale)),
        [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE')}`]: item.Unit && !item.Lock_Inventory_UnitBase ? item.Unit : '',
        [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO')}`]: this.utility.formatNumberExcel(this.utility.parseNumber(item.QuantitaMagazzino, this.locale)),
        [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_MAGAZZINO')}`]: item.SecondaryUnit && !item.Lock_Inventory_Unit ? item.SecondaryUnit : ''
      });

      // Raccolgo tutte le info di tutte le merci con unità di misura bloccate
      if (item.Lock_Inventory_Unit || item.Lock_Inventory_UnitBase) {
        const blockedGood: any = { ...item };
        blockedGood.index = index;
        blockedGoods.push(blockedGood);
      }
    });

    let columnsList = [
      { label: 'Id', enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_MAGAZZINO'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
    ];

    let filename: string = this.currentConfig.body.Company + '_' + moment().format('YYYY_MM_DD')

    let printOptions: any = {
      props: {
        fit: { width: 1, height: 0 },
        paper: "A4",
        orientation: 'landscape'
      },
      margins: { left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3 }
    };
    this.excelService.exportInventoryLayout(xlsx, filename, [], columnsList, 1, [], false, this.currentConfig.body.Company, this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.NOTE'), printOptions, blockedGoods);
  }


  /*---------------------------------------------------------------------------------------------------------*/
  async save() {

    if (this.inserimentoType == InserimentoType.INVENTARI && this.roleService.hasPermission('LocalExportInventory')) {
      // Chiedo se vuole salvare anche il locale un excel.
      await this.saveLocalExcel();
    }

    // Verifico che non ci siano elementi con rapporto = 0
    if (this.inserimentoType == 'Inventory') {
      const rappZeroList = this.lista.filter((item: any) => {
        return item.SecondaryUnitRatio == 0
      });

      if (rappZeroList.length > 0) {
        // Se ho i privilegi di modifica
        if (this.roleService.hasPermission('CreateGoods')) {
          const _title: string = this.translate.instant('MAGAZZINO.INSERIMENTO_COMUNE.SECONDARY_UNIT_RATIO_WARNING.TITLE');
          const _description: string = this.translate.instant('MAGAZZINO.INSERIMENTO_COMUNE.SECONDARY_UNIT_RATIO_WARNING.DESCRIPTION');
          const _waitDesciption: string = '';
          const _yesButton = this.translate.instant('COMMONS.OK');

          const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
          await lastValueFrom(dialogRef.afterClosed());

          // Attivo la visualizzazione delle icone
          this.showRappZeroWarningIcon = true;

          this.lista = this.lista.sort((a: any, b: any) => {
            return a.SecondaryUnitRatio == 0 && a.SecondaryUnitRatio < b.SecondaryUnitRatio ? -1 : (
              a.SecondaryUnitRatio > 0 && a.SecondaryUnitRatio > b.SecondaryUnitRatio ? 1 : 0
            );
          });

          // Pulisco tutti i filtri
          this.clearFilters();
        }
        // Se non ho i privilegi di mofica
        else {
          const _title: string = this.translate.instant('MAGAZZINO.INSERIMENTO_COMUNE.CANT_WRITE_GENERIC_WARNING.TITLE');
          const _description: string = this.translate.instant('MAGAZZINO.INSERIMENTO_COMUNE.CANT_WRITE_GENERIC_WARNING.DESCRIPTION');
          const _waitDesciption: string = '';
          const _yesButton = this.translate.instant('COMMONS.OK');

          const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
          await dialogRef.afterClosed().toPromise();
        }
        return
      }
    }

    let body: any[] = [];

    const productList = this.lista.filter((item: any) => {
      return (item.QuantitaMagazzino && (util.parseNumber(item.QuantitaMagazzino, this.locale) !== 0)) || item.flag;
    });

    productList.forEach((item: any) => {

      let Price = item.Price4 ? item.Price4 : item.Price;
      let QuantitaMagazzino = item.QuantitaMagazzino4 ? item.QuantitaMagazzino4 : item.QuantitaMagazzino;
      let QuantitaUnitaBase = item.QuantitaUnitaBase4 ? item.QuantitaUnitaBase4 : item.QuantitaUnitaBase;
      let Sconto = item.Sconto4 ? item.Sconto4 : item.Sconto;

      let qMag = QuantitaMagazzino ? util.parseNumber(QuantitaMagazzino, this.locale) : 0;

      if (this.isInventoryEditFromNew) {

        let q1Old = item.QuantitaMagazzinoOld ? util.parseNumber(item.QuantitaMagazzinoOld, this.locale) : 0;
        QuantitaMagazzino = q1Old + qMag;
        qMag = QuantitaMagazzino;

        let q1Base = QuantitaUnitaBase ? util.parseNumber(QuantitaUnitaBase, this.locale) : 0;
        q1Old = item.QuantitaUnitaBaseOld ? util.parseNumber(item.QuantitaUnitaBaseOld, this.locale) : 0;
        QuantitaUnitaBase = q1Old + q1Base;

      }
      let itemTmp = {
        GoodId: Number(item.Id),
        Quantity: qMag,
        Price: util.parseNumber(Price, this.locale),
        Ratio: util.parseNumber(item.SecondaryUnitRatio, this.locale),
        Discount: Sconto ? util.parseNumber(Sconto, this.locale) / 100 : 0,
        //Company: this.currentConfig.body.Company,
        Date: this.currentConfig.body.Date,
        DocumentNumber: this.currentConfig.body.DocNumber,
        SupplierId: Number(this.currentConfig.body.SupplierId),
        OrderId: this.currentConfig.body.OrderId ? this.currentConfig.body.OrderId.OrderId : null,
        DepartmentId: this.currentConfig.body.DepartmentId ? Number(this.currentConfig.body.DepartmentId) : null,
        Note: item.Note ? item.Note : this.currentConfig.body.note,
        //CostCenter: undefined, 
        Unit: item.SecondaryUnit,
        CodiceMerce: item.Code,
        NomeMerce: item.Name,
        ReferenceId: item.ReferenceId
      };

      if (this.inserimentoType === InserimentoType.TRASFERIMENTI) {
        itemTmp['OriginCostCenter'] = this.staticCollectionsService.costCenters$.find((cc: any) => cc.Id.toString() === this.currentConfig.body.CostCenterIdOrigin.toString());
        itemTmp['CostCenter'] = this.staticCollectionsService.costCenters$.find((cc: any) => cc.Id.toString() === this.currentConfig.body.CostCenterIdDest.toString());
        itemTmp['OriginCompany'] = this.currentConfig.body.CompanyOrigin;
        itemTmp['Company'] = this.currentConfig.body.CompanyDest;

        if (itemTmp['OriginCostCenter'] && itemTmp['OriginCostCenter'].$id) delete itemTmp['OriginCostCenter'].$id;
        if (itemTmp['CostCenter'] && itemTmp['CostCenter'].$id) delete itemTmp['CostCenter'].$id;

      } else {
        itemTmp['Company'] = this.currentConfig.body.Company;
        itemTmp['WasteTypeId'] = this.currentConfig.body.SelectedWasteType;
        var costCenter;
        costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => cc.Id.toString() === this.currentConfig.body.CostCenterId);
        if (!costCenter) costCenter = this.staticCollectionsService.costCenters$[0];
        if (costCenter && costCenter.$id) delete costCenter.$id;
        if (costCenter && !costCenter.Name) {
          costCenter.Name = '---';
        }
        itemTmp['CostCenter'] = costCenter;
      }
      if (this.inserimentoType === InserimentoType.ACQUISTI) {

        itemTmp['Tags'] = [];
        if (item.Tags) {
          item.Tags.forEach(e => {
            itemTmp['Tags'].push(e.Id)
          });
        }
      }

      if (this.itemId) {
        itemTmp['Id'] = item.ReferenceId
      }
      body.push(itemTmp);
    });

    let action = '';
    switch (this.inserimentoType) {
      case InserimentoType.ACQUISTI:
        action = 'Purchase';
        break;
      case InserimentoType.GIACENZE:
        action = 'Stock';
        break;
      case InserimentoType.INVENTARI:
        action = 'Inventory';
        break;
      case InserimentoType.SPRECHI:
        action = 'Adjustment';
        break;
      case InserimentoType.TRASFERIMENTI:
        action = 'Transfert';
        break;

      default:
        break;
    }
    this.canSave = false;
    this.loaderService.show();
    this.ref.detectChanges();
    if (this.itemId) {
      lastValueFrom(this.magazzinoService.update(action, body))
        .then((result: any) => {
          let message: string = '';
          if (result.Msg === 'Ok') {
            this.canSave = true;
            message = this.translate.instant('MAGAZZINO.MODIFICHE.MODIFICA_OK');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            productList.forEach((element: any) => {

              this.cleanRow(element);
            });
            this.ref.detectChanges();
            this.restoreMap = {};
            if (this.inserimentoType === InserimentoType.INVENTARI) {
              //PER ORA ESCI SUGLI INVENTARI
              this.goBack();
            }
          } else {
            message = this.translate.instant('MAGAZZINO.MODIFICHE.MODIFICA_ERROR');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
          }
        })
        .catch((error: any) => {
          const message = this.translate.instant('MAGAZZINO.MODIFICHE.INSERIMENTO_ERROR');
          this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
        })
        .finally(() => {
          this.loaderService.hide();
          this.ref.detectChanges();
        });
    } else {
      lastValueFrom(this.magazzinoService.save(action, body))
        .then((result: any) => {
          let message: string = '';
          if (result.Msg === 'Ok') {
            this.canSave = true;
            message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_OK');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            productList.forEach((element: any) => {
              this.cleanRow(element);
            });
            this.ref.detectChanges();
            this.restoreMap = {};
            if (this.inserimentoType === InserimentoType.INVENTARI) {
              //PER ORA ESCI SUGLI INVENTARI
              this.goBack();
            }

          } else {
            message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_ERROR');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
          }
        })
        .catch((error: any) => {
          const message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_ERROR');
          this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
        })
        .finally(() => {
          this.loaderService.hide();
          this.ref.detectChanges();
        });
    }

  }
  /*---------------------------------------------------------------------------------------------------------*/
  getSaveCaption() {
    switch (this.inserimentoType) {
      case InserimentoType.ACQUISTI:
        return this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.SALVA');
        break;
      case InserimentoType.GIACENZE:
        return this.translate.instant('MAGAZZINO.GIACENZE.INSERIMENTO_GIACENZE.SALVA');
        break;
      case InserimentoType.INVENTARI:
        return this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.SALVA');
        break;
      case InserimentoType.SPRECHI:
        return this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.SALVA');
        break;
      case InserimentoType.TRASFERIMENTI:
        return this.translate.instant('MAGAZZINO.TRASFERIMENTI.INSERIMENTO_TRASFERIMENTI.SALVA');
        break;

      default:
        break;
    }
  }
  /*---------------------------------------------------------------------------------------------------------*/
  getPopupCaption() {
    switch (this.inserimentoType) {
      case InserimentoType.ACQUISTI:
        return this.translate.instant('MAGAZZINO.ACQUISTI.TOOLBAR.NEW');
        break;
      case InserimentoType.GIACENZE:
        return this.translate.instant('MAGAZZINO.GIACENZE.TOOLBAR.NEW');
        break;
      case InserimentoType.INVENTARI:
        return this.translate.instant('MAGAZZINO.INVENTARI.TOOLBAR.NEW');
        break;
      case InserimentoType.SPRECHI:
        return this.translate.instant('MAGAZZINO.SPRECHI.TOOLBAR.NEW');
        break;
      case InserimentoType.TRASFERIMENTI:
        return this.translate.instant('MAGAZZINO.TRASFERIMENTI.TOOLBAR.NEW');
        break;

      default:
        break;
    }
  }
  /*---------------------------------------------------------------------------------------------------------*/
  getGoBackCaption() {
    switch (this.inserimentoType) {
      case InserimentoType.ACQUISTI:
        return this.translate.instant('MAGAZZINO.ACQUISTI.TITLE');
        break;
      case InserimentoType.GIACENZE:
        return this.translate.instant('MAGAZZINO.GIACENZE.TITLE');
        break;
      case InserimentoType.INVENTARI:
        return this.translate.instant('MAGAZZINO.INVENTARI.TITLE');
        break;
      case InserimentoType.SPRECHI:
        return this.translate.instant('MAGAZZINO.SPRECHI.TITLE');
        break;
      case InserimentoType.TRASFERIMENTI:
        return this.translate.instant('MAGAZZINO.TRASFERIMENTI.TITLE');
        break;

      default:
        break;
    }
  }
  /*---------------------------------------------------------------------------------------------------------*/
  goBack() {
    this.router.navigate([`${this.referral}`], { queryParams: {} });
  }
  /*---------------------------------------------------------------------------------------------------------*/
  selectAllFlags(event: any) {

    const _title: string = this.translate.instant('MAGAZZINO.COMM_ALL_FLAGS');
    const _description: string = this.translate.instant('MAGAZZINO.COMM_ALL_FLAGS_DESC');
    const _waitDesciption: string = '';
    const _yesButton = this.translate.instant('COMMONS.CONFIRM');
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm) {

        this.listaFiltered.forEach((item: any) => {
          item.flag = event.checked;
          this.setFlag(item);
        });

        setTimeout(() => {
          this.ref.detectChanges();
        }, 100);
      }
    });
  }

  checkDisabled(item: any, model: string) {
    let IsDisabled = false;
    switch (this.inserimentoType) {
      case InserimentoType.INVENTARI:
        IsDisabled = item[`Lock_Inventory_${model}`];
        break;
      case InserimentoType.SPRECHI:
        IsDisabled = item[`Lock_Waste_${model}`];
        break;
      case InserimentoType.TRASFERIMENTI:
        IsDisabled = item[`Lock_Transfert_${model}`];
        break;
    }

    return IsDisabled || (!InserimentoType.ACQUISTI && item.IsDisabled);
  }
  /*---------------------------------------------------------------------------------------------------------*/
  openPopup() {
    const newEndpoint = {};
    this.editPopup(newEndpoint, true, true);
  }
  /*---------------------------------------------------------------------------------------------------------*/
  editPopup(endpoint: any, forceReload: boolean = false, clearTable: boolean = false) {
    let popup: any;
    switch (this.inserimentoType) {
      case InserimentoType.ACQUISTI:
        popup = InserimentoAcquistiPopupComponent;
        break;
      case InserimentoType.GIACENZE:
        popup = InserimentoGiacenzePopupComponent;
        break;
      case InserimentoType.INVENTARI:
        popup = InserimentoInventariPopupComponent;
        break;
      case InserimentoType.SPRECHI:
        popup = InserimentoRettifichePopupComponent;
        break;
      case InserimentoType.TRASFERIMENTI:
        popup = InserimentoTrasferimentiPopupComponent;
        break;

      default:
        break;
    }

    if (popup) {
      const dialogRef = this.dialog.open(popup, {
        data: { endpoint },
        width: this.isMobile ? '400px' : '600px'
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res && res.success && res.body) {
          this.magazzinoService.setCurrentConfig(res);
          this.currentConfig = res;
          if (forceReload || !this.itemId) {
            this.initInserimento();
          }
          this.magazzinoService.summaryUpdated$.next(true);
          this.ref.detectChanges();

        } else if (this.itemId) {
          this.magazzinoService.summaryUpdated$.next(true);
          this.ref.detectChanges();
        } else {
          return;
        }

        if (!this.isMobile) {
          $('#productOrCode').first().select();
          $('#productOrCode').first().focus();
        }

      });
    }
  }
  /*---------------------------------------------------------------------------------------------------------*/
  setFlag(item: any) {
    if (item.flag) {
      item.QuantitaMagazzino = this.utility.parseNumber(item.QuantitaMagazzino, this.locale) > 0 ? item.QuantitaMagazzino : 0;
      item.QuantitaUnitaBase = this.utility.parseNumber(item.QuantitaUnitaBase, this.locale) > 0 ? item.QuantitaUnitaBase : 0;
    } else {
      item.QuantitaMagazzino = this.utility.parseNumber(item.QuantitaMagazzino, this.locale) > 0 ? item.QuantitaMagazzino : undefined;
      item.QuantitaUnitaBase = this.utility.parseNumber(item.QuantitaUnitaBase, this.locale) > 0 ? item.QuantitaUnitaBase : undefined;
    }

    let t = util.parseNumber(item.QuantitaMagazzino, this.locale) * util.parseNumber(item.Price, this.locale);
    if (util.parseNumber(item.Sconto, this.locale) > 0) {
      t = t * (1 - util.parseNumber(item.Sconto, this.locale) / 100);
    }
    item.Totale = t; // util.formatNumber(t, this.locale);


    const indexLista = this.lista.findIndex((i: any) => i.Id == item.Id);
    this.lista[indexLista].flag = item.flag;
    this.lista[indexLista].QuantitaMagazzino = item.QuantitaMagazzino;
    this.lista[indexLista].QuantitaUnitaBase = item.QuantitaUnitaBase;
    this.lista[indexLista].Totale = item.Totale;

    this.ref.detectChanges();
  }
  /*---------------------------------------------------------------------------------------------------------*/
  insertNote(item: any) {
    const dialogRef = this.dialog.open(EditNotesComponent, {
      data: { item },
      width: '600px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success) {
        item.Note = res.Note;

        const indexLf = this.listaFiltered.findIndex((i: any) => i.Id == item.Id);
        this.listaFiltered[indexLf]['Note'] = res.Note;

        const indexL = this.lista.findIndex((i: any) => i.Id == item.Id);
        this.lista[indexL]['Note'] = res.Note;

        this.ref.detectChanges();
      }

    });
  }
  insertTags(item: any) {
    const dialogRef = this.dialog.open(EditTagsComponent, {
      data: { item },
      width: '600px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (_.isEmpty(res) || !res) {
        return;
      }
      if (res.success && res.Tags) {
        item.Tags = res.Tags;

        const indexLf = this.listaFiltered.findIndex((i: any) => i.Id == item.Id);
        this.listaFiltered[indexLf]['Tags'] = res.Tags;

        const indexL = this.lista.findIndex((i: any) => i.Id == item.Id);
        this.lista[indexL]['Tags'] = res.Tags;

        this.ref.detectChanges();
      }

    });
  }
  getTags(tags: any) {
    return tags && tags.length > 0 ? tags.map(t => t.Name).toString() : '';
  }
  /*---------------------------------------------------------------------------------------------------------*/
  getDifference(item: any) {
    if (item['QuantitaMagazzino'] && item['Stock']) {

      const QuantitaMagazzino = util.parseNumber(item['QuantitaMagazzino'], this.locale)
      const Stock = Number(item['Stock']);

      return QuantitaMagazzino - Stock;
    }
    return 0;
  }
  /*---------------------------------------------------------------------------------------------------------*/
  duplicateRow(item: any) {
    const newItem = JSON.parse(JSON.stringify(item));
    newItem.ReferenceId = moment().unix();//undefined;
    newItem.LockPriceChange = false;

    // Azzero le quantità
    newItem.QuantitaMagazzino = 0;
    newItem.QuantitaUnitaBase = 0;

    newItem.Sconto = 0;

    // Sostituisco l'attuale ingrediente con quello nuovo
    this.lista.push(newItem);

    this.table.columns.adjust().draw();

    if (this.table) {
      setTimeout(() => {
        var $scrollBody = $(this.table.table().node()).parent();
        $scrollBody.scrollTop($scrollBody.get(0).scrollHeight);
      }, 1000);
    }

    const message = this.translate.instant('MAGAZZINO.DUPLICA_RIGA_SUCCESS');
    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 1000, true, false, 3000, 'top', 'snackbar-success');
  }

  /***************************************************************************************************** */
  prodottiFiltrati!: Observable<any[]>;
  autocompleteDescription = new FormControl();

  showAutocompleteDescription() {

    const dialogRef = this.dialog.open(CercaMerceComponent, {
      data: {
        // Metto solo le merci che non ci sono ancora
        goods: this.goods.filter(({ Code: id1 }) => !this.lista.some(({ Code: id2 }) => id2 === id1)),
        supplierId: this.currentConfig.body.SupplierId,
        onlySearch: true,
        title: 'cercamerce2'
      },
      width: this.isMobile ? '400px' : '800px'
    });

    dialogRef.afterClosed().subscribe((goodId: any) => {
      if (goodId) {
        const good = this.goods.find(g => g.Id + '|' + g.Code == goodId);
        this.selectNewIngredient(good);
      }
    });

  }
  /***************************************************************************************************** */
  addNewEmptyIngredient() {
    // Se per caso ho già un ultima riga vuota non la metto
    if (this.listaFiltered &&
      this.listaFiltered[this.listaFiltered.length - 1] &&
      this.listaFiltered[this.listaFiltered.length - 1].Name.length === 0) return;

    let newEmpty: any = {
      Category: '',
      Code: '',
      AlternateName: '',
      Name: '',
      QuantitaUnitaBase: 0,
      QuantitaMagazzino: 0,
      Stock: 0,
      Unit: '',
      SecondaryUnit: '',
      SecondaryUnitRatio: 1,
      Quantity: 0,
      OrderUnit: '',
      OrderUnitRatio: 1,
      Price: 0,
      Totale: 0,
      Position: '',
      Note: ''
    }

    this.listaFiltered.push(newEmpty);
    this.lista.push(newEmpty);
  }
  /***************************************************************************************************** */
  /**
   * Selezione nuovo ingrediente 
   * @param event 
   * @param ingredient 
   */
  selectNewIngredient(ingredient: any) {
    if (!ingredient) return;
    ingredient['Sconto'] = this.calcDiscount(ingredient);

    ingredient['Sconto'] = this.utility.formatNumber(ingredient['Sconto'], this.locale);

    let newIngredient = ingredient;

    const price = newIngredient.Prices.find((company: any) => company.Azienda == this.currentConfig.body.Company);

    const newItemPrice = price ? this.utility.parseNumber(price.Price, this.locale) * this.utility.parseNumber(price.SecondaryUnitRatio, this.locale) : 0;

    const supplier = newIngredient.Suppliers.find((supplier: any) => supplier.Id == this.currentConfig.body.SupplierId);

    const SecondaryUnit = supplier ? supplier.Variations[0].SecondaryUnit : '';
    const SecondaryUnitId = supplier ? supplier.Variations[0].SecondaryUnitId : 0;
    const SecondaryUnitRatio = supplier ? supplier.Variations[0].SecondaryUnitRatio : 1;

    const newItem = {
      Category: newIngredient.Category,
      CategoryId: newIngredient.CategoryId,
      Code: newIngredient.Code,
      Name: newIngredient.Name,
      AlternateName: newIngredient.Name,
      CanEditTotal: newIngredient.CanEditTotal,
      Sconto: newIngredient.Sconto,
      Unit: newIngredient.Unit,
      UnitId: newIngredient.UnitId,
      SecondaryUnit: SecondaryUnit,
      SecondaryUnitId: SecondaryUnitId,
      SecondaryUnitRatio: SecondaryUnitRatio,
      OrderUnit: null,
      OrderUnitId: 0,
      Id: newIngredient.Id,
      Price: price ? this.utility.formatNumber(newItemPrice, this.locale, 2, true) : this.utility.formatNumber(0, this.locale, 2, true), //http://mantis.fbmanager.com/view.php?id=630
      UniqueId: newIngredient.Category + "|" + newIngredient.Id + "|" + newIngredient.Code + "|0",

      QuantitaMagazzino: this.utility.parseNumber(0, this.locale),
      QuantitaUnitaBase: this.utility.parseNumber(0, this.locale),
      Totale: this.utility.parseNumber(0, this.locale),

      OrderUnitRatio: newIngredient.OrderUnitRatio
    }

    this.listaFiltered.unshift(newItem);

    this.table.columns.adjust().draw();
  }
  /***************************************************************************************************** */
  /**
   * Gets base unit according to selected unit
   * @param newIngredient 
   */
  getBaseUnit(newIngredient: any): any {

    const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Id === newIngredient.UnitId);
    const baseUnit = this.staticCollectionsService.unit$.find((u: any) => u.Id === unit.MainUnitId)
    let u: any = unit;
    if (baseUnit.Name.toLowerCase() === 'kg' && unit.Name.toLowerCase() !== 'g') {
      u = this.staticCollectionsService.unit$.find((u: any) => u.Name.toLowerCase() === 'g');
    } else if (baseUnit.Name.toLowerCase() === 'lt' && unit.Name.toLowerCase() !== 'ml') {
      u = this.staticCollectionsService.unit$.find((u: any) => u.Name.toLowerCase() === 'ml');
    } else {
      u = unit;
    }

    return {
      Id: u.Id,
      Name: u.Name,
      BaseId: u.MainUnitId,
      //BaseUnitName: u.Name,
      A: u.A,
      B: u.B
    };

  }
  /***************************************************************************************************** */
  displayFn(product: any): string {
    if (!product) return '';
    return product.Name;
  }

  calcDiscount(item: any) {
    let scontoParziale = 0;
    if (item && item.S1 > 0) {
      scontoParziale = 1 - item.S1 / 100;
      if (item.S2 > 0) {
        scontoParziale *= (1 - item.S2 / 100);
        if (item.S3 > 0) {
          scontoParziale *= (1 - item.S3 / 100);
          if (item.S4 > 0) {
            scontoParziale *= (1 - item.S4 / 100);
          }
        }
      }
    }

    return (scontoParziale > 0) ? (1 - scontoParziale) * 100 : 0;
  }

  async editMerce(ingredient) {

    const merce = await this.gestioneMerciService.getSingleGoods(ingredient.Id).toPromise();

    this.dialog
      .open(EditMerciComponent, {
        data: {
          merce: merce
        },
        width: '100%',
        height: '100%'
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res && res.Prices && res.Prices.length > 0) {

          let filter = {
            FBType: 6,
          }
          if (this.currentConfig) {
            filter['SupplierIds'] = [this.currentConfig.body.SupplierId];
            filter['OnlyVisibleForCompany'] = true;
            filter['Companies'] = [];
            filter['Companies'].push(this.currentConfig.body.Company);
          }
          filter['EnableState'] = 'yes';
          this.loaderService.show();
          this.ref.detectChanges();
          this.gestioneMerciService.getGoods(filter, false).toPromise()
            .then(
              (goods) => {
                this.goods = goods;

                // recupero il good che mi serve
                const goodIndex = this.goods.findIndex(g => g.Id == ingredient.Id);

                if (goodIndex >= 0) {
                  // Copio gli sconti nell'ingredient, così si vedono subito in tabella.
                  ingredient.S1 = this.utility.parseNumber(this.goods[goodIndex].S1, this.locale);
                  ingredient.S2 = this.utility.parseNumber(this.goods[goodIndex].S2, this.locale);
                  ingredient.S3 = this.utility.parseNumber(this.goods[goodIndex].S3, this.locale);
                  ingredient.S4 = this.utility.parseNumber(this.goods[goodIndex].S4, this.locale);
                  ingredient['Sconto'] = this.calcDiscount(ingredient);

                  ingredient['Sconto'] = this.utility.formatNumber(ingredient['Sconto'], this.locale);
                }

                let priceInfo;

                if (this.showRappZeroWarningIcon && this.inserimentoType == InserimentoType.INVENTARI) {
                  priceInfo = res.Prices.find((price: any) => price.Azienda == this.currentConfig.body.Company);
                } else {
                  priceInfo = res.Prices.find((supplier: any) => supplier.SupplierId == this.currentConfig.body.SupplierId);
                }

                if (priceInfo) {
                  ingredient.OrderUnit = priceInfo.OtherUnit;
                  ingredient.OrderUnitId = priceInfo.OtherUnitId;
                  ingredient.OrderUnitRatio = this.utility.parseNumber(priceInfo.OtherUnitRatio, this.locale);
                  ingredient.Price = this.utility.parseNumber(priceInfo.PriceUM, this.locale);
                  ingredient.SecondaryUnit = priceInfo.SecondaryUnit;
                  ingredient.SecondaryUnitId = priceInfo.SecondaryUnitId;
                  ingredient.SecondaryUnitRatio = this.utility.parseNumber(priceInfo.SecondaryUnitRatio, this.locale);

                  if (this.utility.parseNumber(ingredient.Quantity, this.locale) > 0) {
                    ingredient.QuantitaMagazzino = this.utility.parseNumber(ingredient.Quantity.toString(), this.locale) * parseFloat(ingredient.OrderUnitRatio.toString());
                    ingredient.QuantitaUnitaBase = parseFloat(ingredient.QuantitaMagazzino.toString()) * parseFloat(ingredient.SecondaryUnitRatio.toString());
                    ingredient.Totale = this.utility.parseNumber(ingredient.Quantity.toString(), this.locale) * parseFloat(ingredient.Price.toString()) * parseFloat(ingredient.OrderUnitRatio.toString());
                    if (ingredient.Sconto > 0) {
                      ingredient.Totale = ingredient.Totale - (ingredient.Totale * ingredient.Sconto);
                    }
                  }
                }

                setTimeout(() => {
                  this.ref.detectChanges();
                }, 250);
              }
            ).finally(() => { this.loaderService.hide(); this.ref.detectChanges(); })

        }
      });

  }

  cercaMerce() {
    const dialogRef = this.dialog.open(CercaMerceComponent, {
      data: {
        company: this.currentConfig.body.Company,
        supplierId: this.currentConfig.body.SupplierId
      },
      width: this.isMobile ? '400px' : '800px'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {

        //Ricarico i goods
        this.loaderService.show();
        this.ref.detectChanges();
        let filter = {
          FBType: 6,

        }
        if (this.currentConfig) {

          // filter['SupplierIds'] = [this.currentConfig.body.SupplierId];
          filter['OnlyVisibleForCompany'] = true;
          filter['Companies'] = [];
          filter['Companies'].push(this.currentConfig.body.Company);
        }
        this.gestioneMerciService.getGoods(filter).toPromise().then(
          (goods: any) => {
            this.goods = goods;
            this.loaderService.hide();
            this.ref.detectChanges();
          });

      }
    });
  }

  async creaMerce() {

    const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name === 'kg');
    const supplier = await this.anagraficheService.getSupplierById(this.currentConfig.body.SupplierId);
    const newCode = await this.gestioneMerciService.getFirstAvailableGoodCode().toPromise();

    let newItem: any = {
      AlternateName: '',
      AvgPrice: '',
      BaseUnitId: unit.MainUnitId,
      A: unit.A,
      B: unit.B,
      Category: '',
      CategoryId: '',
      Code: newCode,
      MBCode: newCode,
      ExcludedByConsumption: false,
      Group: '',
      GroupId: '',
      InOut: 0,
      IsDisabled: false,
      IsFinished: false,
      IsFresh: false,
      LockPriceChange: false,
      Name: '',
      Note: '',
      OtherUnit: "",
      OtherUnitId: unit.Id,
      OtherUnitRatio: 1,
      Position: '',
      Price: '',
      PriceMode: "MasterPrice",
      Prices: [],
      ProductionRatio: 1,
      ProductionUnit: "Kg",
      ProductionUnitId: unit.Id,
      SecondaryUnit: "",
      SecondaryUnitId: unit.Id,
      SecondaryUnitRatio: 1,
      Suppliers: [supplier],
      Unit: 'Kg',
      UnitId: unit.Id,
      VAT: 10,
      WastPercentage: 0
    };

    const orderUnit = this.staticCollectionsService.orderUnit$[0];

    this.staticCollectionsService.aziende$.forEach((company: any) => {
      newItem.Prices.push({
        Alias: '',
        Azienda: company.Name,
        IsMaster: false,
        IsVisible: true,
        OtherUnit: '',
        OtherUnitId: orderUnit.Id,
        OtherUnitRatio: 1,
        Price: '',
        PriceUM: '',
        PriceUO: '',
        SecondaryUnit: '',
        SecondaryUnitId: orderUnit.Id,
        SecondaryUnitRatio: 1,
        SupplierId: this.currentConfig.body.SupplierId
      });
    });

    const dialogRef = this.dialog.open(EditMerciComponent, {
      data: {
        merce: newItem
      },
      width: '100%',
      height: '100%'
    });

    dialogRef.afterClosed().subscribe(async (res: any) => {
      if (res) {

        res.UniqueId = res.Category + "|" + res.Id + "|" + res.Code + "|0";

        //Ricarico i goods
        this.loaderService.show();
        this.ref.detectChanges();
        let filter = {
          FBType: 6,
        }
        if (this.currentConfig) {

          filter['SupplierIds'] = [this.currentConfig.body.SupplierId];
          filter['OnlyVisibleForCompany'] = true;
          filter['Companies'] = [];
          filter['Companies'].push(this.currentConfig.body.Company);
        }
        filter['EnableState'] = 'yes';
        this.goods = await this.gestioneMerciService.getGoods(filter).toPromise();

        this.selectNewIngredient(res);

        this.loaderService.hide();
        this.ref.detectChanges();
      }
    });
  }

  trackByIndex(index: any, item: any) {
    return index;
  }

  showTooltip(show: boolean, tooltip: string) {
    if (show) {
      this[tooltip].show();
    } else if (!show) {
      this[tooltip].hide();
    }
    setTimeout(() => {
      this.ref.detectChanges();
    }, 10);
  }

  getTranslate(string: string) {
    return this.translate.instant(string);
  }

  showActionTooltip(event: MouseEvent, show: boolean, tooltip: string = '') {
    if (show && !tooltip) return;

    if (show) {
      $('#tooltip').text(tooltip);
      const positionX = event.clientX - 150;
      const positionY = event.clientY - 50;
      $('#tooltip').css('top', `${positionY}px`);
      $('#tooltip').css('left', `${positionX}px`);
      $('#tooltip').show();
    } else {
      $('#tooltip').text();
      $('#tooltip').hide();
    }

    setTimeout(() => {
      this.ref.detectChanges();
    }, 10);
  }
}
