import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { EstrazioneDatiService } from '@app/core/services/estrazione-dati.service';
import { LoaderService } from '@app/core/services/loader.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'kt-estrazione-dati',
  templateUrl: './estrazione-dati.component.html',
  styleUrls: ['./estrazione-dati.component.scss']
})
export class EstrazioneDatiComponent implements OnInit {

  
  dateStart: any;
  dateEnd: any;
  selectedCompanies: any;
  withTax: boolean = false;

  constructor(
    public staticCollectionsService: StaticCollectionsService,
    private estrazioneDatiService: EstrazioneDatiService,
    private ref: ChangeDetectorRef,
    private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    public loaderService: LoaderService
  ) {
    document.body.classList.add('kt-aside--minimize');
  }

  async ngOnInit() {
    this.selectedCompanies = undefined;  
    this.loaderService.show();
    const ready = this.staticCollectionsService.collectionReady();
    await this.staticCollectionsService.fillStaticCollection(!ready);
    this.loaderService.hide();

    this.ref.detectChanges();
  }

  clearFilters() {
    this.dateStart = undefined;
    this.dateEnd = undefined;
    this.selectedCompanies = undefined;
    this.withTax = false;
  }

  exportTimeslot() {
    this.loaderService.show();

    const dateStartString = new Date(Date.UTC(this.dateStart.year, this.dateStart.month - 1, this.dateStart.day)).toISOString();
    const dateEndString = new Date(Date.UTC(this.dateEnd.year, this.dateEnd.month - 1, this.dateEnd.day)).toISOString();

    let payload: any = {
      DateFilter: {
        Start: dateStartString,
        End: dateEndString
      }
    };

    if (this.selectedCompanies) {
      payload.Companies = this.selectedCompanies
    }

    lastValueFrom(this.estrazioneDatiService.getTimeSlotReport(payload, this.withTax))
    .then((result) => {
      const _success = this.translate.instant('ESTRAZIONE_DATI.EXTRACT_SUCCESS');
      this.layoutUtilsService.showActionNotification(_success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

      this.clearFilters();

    })
    .catch((err: any) => {
      console.log(err);
      this.layoutUtilsService.showActionNotification(JSON.stringify(err), MessageType.Delete, 3000, true, false, 3000, 'top', 'snackbar-success');
    })
    .finally(() => {
      this.loaderService.hide();
    })

  }

  exportHistory() {
    this.loaderService.show();

    const dateStartString = new Date(Date.UTC(this.dateStart.year, this.dateStart.month - 1, this.dateStart.day)).toISOString();
    const dateEndString = new Date(Date.UTC(this.dateEnd.year, this.dateEnd.month - 1, this.dateEnd.day)).toISOString();

    let payload: any = {
      DateFilter: {
        Start: dateStartString,
        End: dateEndString
      }
    };

    if (this.selectedCompanies) {
      payload.Companies = this.selectedCompanies
    }

    lastValueFrom(this.estrazioneDatiService.getSellingReport(payload, this.withTax))
    .then((result) => {
      const _success = this.translate.instant('ESTRAZIONE_DATI.EXTRACT_SUCCESS');
      this.layoutUtilsService.showActionNotification(_success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

      this.clearFilters();
    })
    .catch((err: any) => {
      console.log(err);
      this.layoutUtilsService.showActionNotification(JSON.stringify(err), MessageType.Delete, 3000, true, false, 3000, 'top', 'snackbar-success');
    })
    .finally(() => {
      this.loaderService.hide();
    })

  }

}
