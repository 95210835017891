import { environment } from '@env/environment';
export class MenuConfig {


  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboards',
          root: true,
          alignment: 'left',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',

        },
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon-dashboard',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',
          // bullet: 'dot',
          // userPermission: 'Dashboard',
          roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser', 'User']
        },
        // Amministrazione
        {
          title: 'Amministrazione',
          root: true,
          icon: 'flaticon-profile-1',
          //page: '/',
          translate: 'MENU.AMMINISTRAZIONE',
          // bullet: 'dot',
          roles: ['SuperAdmin', 'CompanyAdmin'],
          submenu: [
            {
              title: 'Dashboard',
              translate: 'MENU.CONTOECONOMICO_DASHBOARD',
              page: '/amministrazione/dashboard',
              roles: ['SuperAdmin', 'CompanyAdmin']
            },
            {
              title: 'Conto Economico',
              translate: 'MENU.CONTOECONOMICO',
              page: '/amministrazione/conto-economico',
              roles: ['SuperAdmin', 'CompanyAdmin']
            },
            {
              title: 'Scadenziario',
              translate: 'MENU.AMMINISTRAZIONE_SCADENZIARIO',
              page: '/amministrazione/scadenziario',
              roles: ['SuperAdmin', 'CompanyAdmin'],
              userPermission: 'Schedule',
            }
          ]
        },
        //Analisi
        {
          title: 'Analisi',
          root: true,
          icon: 'flaticon-analytics',
          translate: 'MENU.ANALISI',
          // bullet: 'dot',
          roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser'],
          submenu: [
            {
              title: 'Product Mix',
              translate: 'MENU.PRODUCT_MIX',
              page: '/analisi/analisi-vendite',
              userPermission: 'ProductMix',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Analisi Food Cost',
              translate: 'MENU.ANALISI_FOODCOST',
              page: '/analisi/analisi-foodcost',
              userPermission: 'FoodCostAnalisys',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Analisi Consumi',
              translate: 'MENU.ANALISI_CONSUMI',
              page: '/analisi/analisi-consumi',
              userPermission: 'ConsumptionAnalysis',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Forecasting',
              translate: 'MENU.FORECASTING',
              //page: '/',
              userPermission: 'Forecasting',
              roles: ['SuperAdmin', 'CompanyAdmin', 'ReadonlyUser']
            },
            {
              title: 'Storico Vendite',
              translate: 'MENU.STORICO_VENDITE',
              page: '/storico-vendite',
              userPermission: 'SellingHistory',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Prezzi Fornitori',
              translate: 'MENU.PRICE_GRAPH',
              page: 'analisi/analisi-prezzi-fornitori',
              //userPermission: 'PriceGap',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Analisi Variazione Prezzi',
              translate: 'MENU.ANALISI_VARIAZIONE_PREZZI',
              page: 'analisi/variazione-prezzi',
              //userPermission: 'PriceGap',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Estrazione Dati',
              translate: 'MENU.ESTRAZIONE_DATI',
              page: 'analisi/estrazione-dati',
              //userPermission: 'PriceGap',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            }
          ]
        },
        // Gestione dati
        {
          title: 'Gestione Dati',
          root: true,
          icon: 'flaticon-file-1',
          //page: '/endpoints',
          translate: 'MENU.GESTIONE_VENDITE',
          // bullet: 'dot',
          roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser'],
          submenu: [
            {
              title: 'Gestione Fatture',
              translate: 'MENU.GESTIONE_FATTURE',
              page: '/gestione-dati/gestione-fatture',
              userPermission: 'InvoicesManagement',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Gestione Merci',
              translate: 'MENU.GESTIONE_VENDITE_MERCI',
              page: '/magazzino/gestione-merci',
              userPermission: 'Goods',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Gestione Ricette',
              translate: 'MENU.GESTIONE_VENDITE_RICETTE',
              userPermission: 'Recipe',
              page: '/gestione-ricette',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Vendite Manuali',
              translate: 'MENU.GESTIONE_VENDITE_MANUALI',
              page: '/vendite-manuali',
              userPermission: 'HandSellings',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Rettifiche Di Vendita',
              translate: 'MENU.GESTIONE_RETTIFICHE_VENDITA',
              page: '/rettifiche-vendita',
              queryParams: { type: 'rettifiche-vendita' },
              userPermission: 'SellingAdjustments',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Carico Prodotti Finiti',
              translate: 'MENU.CARICO_PRODOTTI_FINITI',
              page: '/prodotti-finiti',
              queryParams: { type: 'prodotti-finiti' },
              userPermission: 'FinishedProducts',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
            {
              title: 'Vendite manuali Inventario',
              translate: 'MENU.VENDITE_MANUALI_INVENTARIO',
              page: '/inventario',
              queryParams: { type: 'inventario' },
              userPermission: 'FinishedProducts',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'ReadonlyUser']
            },
          ]
        },
        // Magazzino
        {
          title: 'Gestione Magazzino',
          root: true,
          icon: 'flaticon-truck',
          //page: '/endpoints',
          translate: 'MENU.GESTIONE_MAGAZZINO',
          // bullet: 'dot',
          roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser'],
          submenu: [
            {
              title: 'Statistiche',
              translate: 'MENU.GESTIONE_STATISTICHE',
              userPermission: 'Statistics',
              page: '/magazzino/statistiche',
              roles: ['SuperAdmin', 'CompanyAdmin', 'ReadonlyUser', 'User', 'ReadonlyUser']
            },
            {
              title: 'Acquisti',
              translate: 'MENU.GESTIONE_ACQUISTI',
              page: '/magazzino/acquisti',
              userPermission: 'Purchase',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser']
            },
            {
              title: 'DDT',
              translate: 'MENU.GESTIONE_DDT',
              page: '/magazzino/ddt',
              userPermission: 'Ddt',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser']
            },
            {
              title: 'Ordini',
              translate: 'MENU.GESTIONE_ORDINI',
              page: '/magazzino/ordini',
              userPermission: 'Order',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser']
            },
            {
              title: 'Ordini Interni',
              translate: 'MENU.GESTIONE_ORDINI_INTERNI',
              page: '/magazzino/ordini-interni',
              userPermission: 'InnerOrder',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser']
            },
            {
              title: 'Ordini Semplificati',
              translate: 'MENU.GESTIONE_ORDINI_SEMPLIFICATI',
              page: '/magazzino/ordini-semplificati',
              userPermission: 'SimplifiedOrder',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser']
            },
            {
              title: 'Inventari',
              translate: 'MENU.GESTIONE_INVENTARI',
              page: '/magazzino/inventari',
              userPermission: 'Inventory',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser']
            },
            {
              title: 'Rettifiche/Sprechi',
              translate: 'MENU.GESTIONE_RETTIFICHE_SPRECHI',
              page: '/magazzino/sprechi',
              userPermission: 'Adjustment',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser']
            },
            {
              title: 'Trasferimenti',
              translate: 'MENU.GESTIONE_TRASFERIMENTI',
              page: '/magazzino/trasferimenti',
              userPermission: 'Transfert',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User', 'ReadonlyUser']
            },
            {
              title: 'Giacenze',
              translate: 'MENU.GESTIONE_GIACENZE',
              page: '/magazzino/giacenze',
              userPermission: 'Stock',
              roles: ['SuperAdmin', 'CompanyAdmin', 'ReadonlyUser']
            },
            {
              title: 'Giacenze Alla Data',
              translate: 'MENU.GESTIONE_ALL_DATA',
              userPermission: 'DateStock',
              //page: '/',
              roles: ['SuperAdmin', 'CompanyAdmin', 'ReadonlyUser']
            }
          ]
        },
        // Gestione accessi
        {
          title: 'Gestione Accessi',
          root: true,
          icon: 'flaticon-network',
          //page: '/endpoints',
          translate: 'MENU.GESTIONE_ACCESSI',
          // bullet: 'dot',
          roles: ['SuperAdmin'],
          submenu: [
            {
              title: 'Endpoints',
              translate: 'MENU.ENDPOINTS',
              page: '/endpoints',
              //roles: ['']
            },
            {
              title: 'Log Accessi',
              translate: 'MENU.LOG_ACCESSI',
              //page: '/',
              //roles: ['']
            },
          ]
        },
        // Anagrafiche
        {
          title: 'Anagrafiche',
          root: true,
          icon: 'flaticon-folder-1',
          translate: 'MENU.ANAGRAFICHE',
          // bullet: 'dot',
          roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager'],
          submenu: [
            {
              title: 'Centri Di Costo',
              translate: 'MENU.CENTRI_COSTO',
              page: '/centri-costo',
              roles: ['SuperAdmin', 'CompanyAdmin']
            },
            {
              title: 'Brands',
              translate: 'MENU.BRANDS',
              page: '/brands',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Categorie Merci',
              translate: 'MENU.CATEGORIE_MERCI',
              page: '/categorie-merci',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Anagrafiche',
              translate: 'MENU.FORNITORI_CLIENTI_DIPENDENTI',
              page: '/fornitori',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Categorie Prodotti',
              translate: 'MENU.CATEGORIE_PRODOTTI',
              page: '/categorie-prodotti',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Gruppi',
              translate: 'MENU.GRUPPI',
              page: '/gruppi',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Gruppi',
              translate: 'MENU.GRUPPI_MERCI',
              page: '/gruppi-merci',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Reparti',
              translate: 'MENU.REPARTI',
              page: '/reparti',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Razioni',
              translate: 'MENU.RAZIONI',
              page: '/razioni',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Unità Di Misura',
              translate: 'MENU.UNIT',
              page: '/unita-misura',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Unità Secondarie',
              translate: 'MENU.ORDERUNIT',
              page: '/unita-secondarie',
              roles: ['SuperAdmin', 'CompanyAdmin', 'StoreManager']
            },
            {
              title: 'Tipi Di Spreco',
              translate: 'MENU.WASTETYPES',
              page: '/wastetypes',
              roles: ['SuperAdmin', 'CompanyAdmin']
            },
          ]
        },
        // Configurazione
        {
          title: 'Configurazione',
          root: true,
          icon: 'flaticon-map',
          //page: '/',
          translate: 'MENU.CONFIGURAZIONE',
          // bullet: 'dot',
          roles: ['SuperAdmin', 'CompanyAdmin'],
          submenu: [
            {
              title: 'Utenti',
              translate: 'MENU.USERS',
              icon: 'flaticon-users-1',
              page: '/companyUsers',
              roles: ['SuperAdmin', 'CompanyAdmin']
            },
            {
              title: 'Punti Vendita',
              translate: 'MENU.STORES',
              icon: '	flaticon-confetti',
              page: '/stores',
              roles: ['SuperAdmin', 'CompanyAdmin']
            },
            {
              title: 'Impostazioni',
              translate: 'MENU.SETTINGS',
              icon: 'flaticon-interface-6',
              page: '/endpointSettings',
              roles: ['SuperAdmin', 'CompanyAdmin']
            }
          ]
        },
        // Manutenzione
        {
          title: 'Manutenzione',
          root: true,
          icon: 'flaticon-settings-1',
          //page: '/',
          translate: 'MENU.MANUTENZIONE',
          // bullet: 'dot',
          roles: ['SuperAdmin', 'CompanyAdmin'],
          submenu: [
            {
              title: 'Importazione/Esportazione',
              translate: 'MENU.IMPORT_EXPORT',
              page: '/manutenzione/importexport',
              roles: ['SuperAdmin', 'CompanyAdmin']
            },
            {
              title: 'Tasks',
              translate: 'MENU.TASKS',
              page: '/manutenzione/tasks',
              roles: ['SuperAdmin', 'CompanyAdmin']
            },
            {
              title: 'Logs',
              translate: 'MENU.LOGS',
              page: '/manutenzione/logs',
              roles: ['SuperAdmin']
            }
          ]
        }
      ]
    },
  };

  isSuperAdmin(usr) {

    return (usr.Logins[0].Roles.includes('SuperAdmin'))
  }

  public get configs(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');

    if (!environment.OrdiniSemplificati) {
      let gestioneMagazzino = this.defaults.aside.items.find((x: any) => x.title === 'Magazzino');
      gestioneMagazzino.submenu = gestioneMagazzino.submenu.filter((x: any) => x.title !== 'Gestione ordini semplificati')

    }

    this.defaults.aside.items.forEach(item => {


      this.setMenuPermission(currentUser, item);

      //VERIFICA I SOTTOMENU
      if (item.permission === false && item.submenu) {
        item.submenu.forEach(submenu => {
          this.setMenuPermission(currentUser, submenu);
        });
      }

    });

    return this.defaults;
  }

  setMenuPermission(currentUser, item) {

    function containsAny(source, target) {
      var result = source.filter(function (item) { return target.indexOf(item) > -1 });
      return (result.length > 0);
    }
    //VERIFICA RUOLO
    if (this.isSuperAdmin(currentUser) || !item.roles) {
      //L'utente è superadmin o non sono specificati ruoli per il menu corrente
      //allora sempre visibile NOTA: permission=false significa visibile
      item.permission = false;
    } else {
      if (item.roles && containsAny(item.roles, currentUser.Logins[0].Roles)) {
        //Se l'utente ha un ruolo tra quelli previsti
        //allora il menu è visibile
        item.permission = false;
      } else {
        //menu non visibile
        item.permission = true;
      }


    }

    //VERIFICA PERMESSI
    if (item.permission === false) {
      //Se il menu risultava visibile in base ai ruoli
      if (!item.userPermission || currentUser.Permissions[item.userPermission] || this.isSuperAdmin(currentUser)) {
        //Se il menu ha dei permessi specifici e corrispondono a quelli dell'utente
        //allora il menu è visibile
        item.permission = false;
      } else {
        //menu non visibile
        item.permission = true;
      }
    }
  }
}
