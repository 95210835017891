import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ContoEconomicoService } from '../conto-economico.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslateService } from '@ngx-translate/core';
import { PDFService } from '@app/core/services/pdf.service';
import * as util from '@app/core/services/utilityfunctions';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

// Charts
import { Chart } from 'chart.js/dist/chart.min.js';

import { isNumber } from 'lodash';

@Component({
  selector: 'kt-category-graph',
  templateUrl: './category-graph.component.html',
  styleUrls: ['./category-graph.component.scss']
})
export class CategoryGraphComponent implements OnInit {


  originalData: any;

  @ViewChild('chart', { static: true }) chart!: ElementRef;
  graphData: any;
  myChart: any;

  utility: any;
  item: any;
  filters: any;
  apiData: any;

  total: number = 0;
  avg: number = 0;

  maxValuesPerPage: number = 12;
  maxValuesPerPageTmp: number;
  excludePagination: boolean = false;

  constructor(
    private contoEconomicoService: ContoEconomicoService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public staticCollectionsService: StaticCollectionsService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private pdfService: PDFService,
    private ref: ChangeDetectorRef
  ) {
    this.utility = util;
    this.maxValuesPerPageTmp = this.maxValuesPerPage;
  }

  ngOnInit(): void {
    this.item = this.data.item;
    this.filters = this.data.filters;
    this.data.currentFilterCfg.Training = this.data.training;
    this.data.currentFilterCfg.PLCategoryId = this.data.item.Id;
    lastValueFrom(this.contoEconomicoService.ByCategory({
      filters: this.data.currentFilterCfg,
    })).then((response: any) => {
      this.apiData = response;
      this.refreshGraph();
    })
      .catch((err: any) => console.log)
      .finally();
  }

  getTitle() {
    return this.apiData ? this.apiData.Name : '';
  }

  refreshGraph() {

    const param = this.filters.ck_lordo ? 'GrossAmount' : 'Amount';

    // Rimuovo i valori a zero e inverto i valori perchè deve andare da destra verso sinistra
    let apiDataItems = this.apiData.Items.filter((item: any) => item[param] > 0);

    let labels: any = apiDataItems.map((item: any) => item.Name);
    let datasets: any = [];

    if (this.apiData.Children && this.apiData.Children.length > 0) {

      this.apiData.Children.forEach((child: any, colorCounter: number) => {
        const childItems = child.Items.filter((item: any) => {
          return labels.includes(item.Name)
        });
        if (childItems.length > 0) {
          datasets.push({
            type: 'bar',
            label: child.Name,
            backgroundColor: this.utility.generateRaimbow(colorCounter),
            data: childItems.map((item: any) => this.utility.parseNumber(item[param], this.data.locale, 2)),
          })
        }
      });

    } else if (apiDataItems.length > 0) {
      datasets.push({
        type: 'bar',
        label: this.apiData.Name,
        backgroundColor: this.utility.generateRaimbow(0),
        data: apiDataItems.map((item: any) => this.utility.parseNumber(item[param], this.data.locale, 2)),
      })
    }

    this.graphData = {
      labels: labels,
      datasets: datasets
    };

    console.log(this.graphData);

    this.initChartJS();
  }

  /** Init chart */
  initChartJS() {
    // this.firstLoad = false;
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    if (this.myChart) {
      this.myChart.destroy();
    }

    // Serve per esportazione in PDF per background bianco, altrimenti rimane nero.
    const bgColor = {
      id: 'bgColor',
      beforeDraw: (chart: any, options: any) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    }

    const moveChart = {
      id: 'moveChart',
      afterEvent: (chart: any, args: any) => {
        if (this.excludePagination) return;

        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        canvas.addEventListener('mousemove', (event) => {
          const x = args.event.x;
          const y = args.event.y;

          if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const leftEnabled = chart.config.options.scales.x.min > 0
            canvas.style.cursor = leftEnabled ? 'pointer' : 'not-allowed';
          } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const dataLength = chart.config.data.labels.length;
            const rightEnabled = chart.config.options.scales.x.max < dataLength - 1;
            canvas.style.cursor = rightEnabled ? 'pointer' : 'not-allowed';
          } else {
            canvas.style.cursor = 'default';
          }
        });
      },
      afterDraw: (chart: any, args: any, pluginOptions: any) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;

        if (this.excludePagination) {

          // Scrivo il totale dei risultati
          ctx.beginPath();
          const rect = canvas.getBoundingClientRect();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem "Barlow Condensed", sans-serif`;
          const pagination = `${chart.config.data.labels.length} ${this.translate.instant('COMMONS.RESULTS')}`;
          const textWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textWidth, top - 12);
          ctx.closePath();

        } else {

          class CircleChevron {
            //constructor(x1, y1) {}

            draw(ctx, x1, pixel, enabled: boolean = true) {
              const angle = Math.PI / 180;

              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = 'rgba(102, 102, 102, .5)';
              ctx.fillStyle = 'white';
              ctx.arc(x1, height / 2 + top, 10, angle * 0, angle * 360, false);
              ctx.stroke();
              ctx.fill();
              ctx.closePath();

              // chevron Arrow
              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = enabled ? '#5867DD' : '#CCCCCC';
              ctx.moveTo(x1 + pixel, height / 2 + top - 5.5);
              ctx.lineTo(x1 - pixel, height / 2 + top);
              ctx.lineTo(x1 + pixel, height / 2 + top + 5.5);
              ctx.stroke();
              ctx.closePath();
            }
          }

          const leftEnabled = chart.config.options.scales.x.min > 0
          let drawCircleLeft = new CircleChevron();
          drawCircleLeft.draw(ctx, left, 3, leftEnabled);

          const dataLength = chart.config.data.labels.length;
          const rightEnabled = chart.config.options.scales.x.max < dataLength - 1;
          let drawCircleRight = new CircleChevron();
          drawCircleRight.draw(ctx, right, -3, rightEnabled);

          // Scrivo la paginazione
          ctx.beginPath();
          const rect = canvas.getBoundingClientRect();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem "Barlow Condensed"`;
          const pagination = `${this.translate.instant('EXPORT_PDF.PAGE')} ${Math.ceil(chart.config.options.scales.x.min / this.maxValuesPerPage) + 1} ${this.translate.instant('EXPORT_PDF.OF')} ${Math.ceil(dataLength / this.maxValuesPerPage)}`;
          const textWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textWidth, top - 12);
          ctx.closePath();
        }
      }
    }

    this.myChart = new Chart(this.chart.nativeElement, {
      type: 'line',
      data: this.graphData,
      plugins: [
        moveChart,
        bgColor,
        {
          afterDraw: (chart: any) => {
            // Calcolo i totali dinamicamente, sia per dataset sia per numero elementi visualizzati
            const datasets = chart.config.data.datasets.filter((d: any) => !d.hidden);

            const min = chart.scales['x'].min;
            const max = chart.scales['x'].max;

            const endIteration = (min + this.maxValuesPerPage - 1) > max ? max - min : this.maxValuesPerPage;

            this.total = 0;

            for (let index = 0; index <= endIteration; index++) {
              datasets.forEach((dataset: any) => {
                this.total += isNumber(dataset.data[max - index]) ? dataset.data[max - index] : 0;
              })
            }

            this.avg = this.total / (endIteration + 1);
          }
        }
      ],
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            bodyFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            titleFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            footerFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            //intersect: false,
            //mode: 'nearest',
            padding: 10,
            caretPadding: 10,
            callbacks: {
              //beforeTitle: (tooltipItem) => {
              //  return tooltipItem[0].dataset.label;
              //},
              title: (tooltipItem) => {
                return tooltipItem[0].label
                // return data.labels[tooltipItem[0].index];
              },
              label: (tooltipItem) => {
                if (this.data.item.IsCover) {
                  return this.utility.formatNumber(tooltipItem.dataset.data[tooltipItem.dataIndex], this.data.locale, 0, true, false);
                } else {
                  return this.utility.formatNumber(tooltipItem.dataset.data[tooltipItem.dataIndex], this.data.locale, 2, true, true);
                }
              }
            }
          },
          legend: {
            labels: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              }
            },
            display: true,
            position: 'top',
            onClick: (e, legendItem) => {
              var index = legendItem.datasetIndex;
              var ci = this.myChart;
              var meta = ci.getDatasetMeta(index);

              // See controller.isDatasetVisible comment
              meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

              this.myChart.config.data.datasets[index].hidden = meta.hidden;

              // We hide a dataset ... rerender the chart
              ci.update();

              this.ref.detectChanges();
            }
          },
        }
        ,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            //min: 0,
            //max: this.maxValuesPerPage - 1,
            min: this.graphData.labels.length - this.maxValuesPerPage,
            max: this.graphData.labels.length,
            display: true,
            grid: {
              display: false
            },
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: 0,
              //callback: (value: any, index: number, values: any) => {
              //  const overrideValue = this.graphData.labels[index];
              //  if ((new Date(overrideValue)).toString().length > 0 && (new Date(overrideValue)).toString() !== 'Invalid Date') {
              //    const date = new Date(new Date(overrideValue).setHours(0, 0, 0, 0));
              //    return date.toLocaleDateString(localStorage.getItem('language'), {dateStyle: 'short'});
              //  } else {
              //    return overrideValue;
              //  }
              //}
            },
          },
          y: {
            display: true,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                if (this.data.item.IsCover) {
                  return this.utility.formatNumber(value, this.data.locale, 0, true, false);
                } else if (parseFloat(value) > 0) {
                  return this.utility.kFormatter(value, this.data.locale);
                }
              },
              precision: 0,
              integerSteps: true
            },
            title: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('CONTO_ECONOMICO.CATEGORY_GRAPH.ASSE_Y')
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 18,
            top: 0,
            bottom: 0
          }
        }
      }
    });

    const moveScroll = () => {
      this.myChart.canvas.addEventListener('click', (event: any) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = this.myChart;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
          this.myChart.config.options.scales.x.min -= this.maxValuesPerPage;
          this.myChart.config.options.scales.x.max -= this.maxValuesPerPage;
          if (this.myChart.config.options.scales.x.min <= 0) {
            this.myChart.config.options.scales.x.min = 0
            this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
          }
          this.myChart.update();
        } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {

          this.myChart.config.options.scales.x.min += this.maxValuesPerPage;
          this.myChart.config.options.scales.x.max += this.maxValuesPerPage;

          const dataLength = this.myChart.config.data.labels.length;
          if (this.myChart.config.options.scales.x.max >= dataLength) {
            this.myChart.config.options.scales.x.min = dataLength - this.maxValuesPerPage;
            this.myChart.config.options.scales.x.max = dataLength - 1;
          }
          this.myChart.update();
        }

      });
    }

    this.myChart.ctx.onclick = moveScroll();
  }

  excludeGraphPagination(event: any) {
    this.excludePagination = event.checked;
    if (this.excludePagination) {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.myChart.config.data.labels.length - 1;
      this.myChart.config.options.scales.x.ticks.padding = 0;
    } else {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
      this.myChart.config.options.scales.x.ticks.padding = 35;
    }
    this.myChart.update();
  }

  onKeyUpMaxValues(event: any) {
    if (event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.maxValuesPerPage = this.maxValuesPerPageTmp;
      this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
      this.myChart.update();
    }
  }

  printChart() {
    this.myChart.update();
    setTimeout(() => {
      var canvas = <HTMLCanvasElement>document.getElementById('categoryGraph');

      let header = this.translate.instant('CONTO_ECONOMICO.CATEGORY_GRAPH.TOTAL_CAPTION') + ' ' + this.utility.formatNumber(this.total, this.data.locale, 2, true, true);

      if (this.data.item.Children.length == 0) {
        header += '  -  ' + this.translate.instant('CONTO_ECONOMICO.CATEGORY_GRAPH.AVG_CAPTION') + ' ' + this.utility.formatNumber(this.avg, this.data.locale, 2, true, true);
      }

      this.pdfService.chartToPdf(canvas, this.getTitle(), undefined, header);
    }, 100);
  }

}
