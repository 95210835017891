// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
// Layout
import { LayoutConfigService, TranslationService } from '@app/core/_base/layout';
import { LayoutUtilsService } from '@app/core/_base/crud';
// Charts
import { Chart } from 'chart.js/dist/chart.min.js';

import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

import * as util from '@app/core/services/utilityfunctions';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { Key } from 'readline';
import { LoaderService } from '@app/core/services/loader.service';
import { environment } from '@env/environment';

declare var window: any;

@Component({
  selector: 'kt-storage-maingraph',
  templateUrl: './storagemaingraph.component.html',
  styleUrls: ['./storagemaingraph.component.scss']
})
export class StorageMainGraphComponent implements OnInit, OnDestroy {

  utility: any;
  currentFilterCfg: any;
  data: any;
  table: any;

  // Public properties
  @Input() entity!: string;
  @Input() entityLocalized!: string;

  originalData: any;
  @ViewChild('chart', { static: true }) chart!: ElementRef;
  maingraphType!: string;
  totPrec!: number;
  totAtt!: number;
  totFcPerc!: number;
  myChart: any;
  firstLoad: boolean = true;
  showAll: boolean = false;

  currentClickListener: any;

  LIMIT_PRINT_INFOS: number = 25;
  PADDING_WITH_INFOS: number = 35;
  DEFAULT_PAGINATION_RECORD: number = 20;

  MONTHS: any = ['', 'GENNAIO', 'FEBBRAIO', 'MARZO', 'APRILE', 'MAGGIO', 'GIUGNO', 'LUGLIO', 'AGOSTO', 'SETTEMBRE', 'OTTOBRE', 'NOVEMBRE', 'DICEMBRE']
  MONTHS_SHORT: any = ['', 'GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC']
  WEEKDAY: any = ['', 'LUNEDI\'', 'MARTEDI\'', 'MERCOLEDI\'', 'GIOVEDI\'', 'VENERDI\'', 'SABATO', 'DOMENICA']

  graphData: any;
  grouping: string = 'Supplier';
  timeSlot!: string;

  showTotIncidence: boolean = false;
  showOnlyCompData: boolean = false;

  columnsList: any;
  locale!: string;

  switchLanguageSubscription!: Subscription;
  filtriServiceSubscription!: Subscription;

  maxValuesPerPageTmp!: number;
  excludePagination: boolean = false;

  pagination: any = {
    arrayIndex: 0,
    maxPerPage: this.DEFAULT_PAGINATION_RECORD,
    totRecords: 0
  };

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private filtriService: FiltriService,
    private translate: TranslateService,
    private translationService: TranslationService,
    private magazzinoService: MagazzinoService,
    private excelService: ExcelService,
    private pdfService: PDFService,
    private layoutUtilsService: LayoutUtilsService,
    private ref: ChangeDetectorRef,
    public loaderService: LoaderService
  ) {
    this.maxValuesPerPageTmp = this.pagination.maxPerPage;

    this.utility = util;
    this.switchLanguageSubscription = this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
      if (this.myChart) {
        this.initChartJS();
      }
    });

    this.columnsList = [
      { label: '', enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
      { label: '', enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
    ];
  }

  ngOnDestroy() {
    this.myChart = undefined;
    if (this.switchLanguageSubscription) this.switchLanguageSubscription.unsubscribe();
    if (this.filtriServiceSubscription) this.filtriServiceSubscription.unsubscribe();
  }

  /**
   * On init
   */
  ngOnInit() {

    this.filtriServiceSubscription = this.filtriService.performFilter$
      .subscribe(
        (filterConfig: any) => {

          if ((filterConfig.constructor !== Object) || (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object)) {
            return
          };
          this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
          this.currentFilterCfg['Grouping'] = this.grouping;

          this.getData({
            model: this.entity,
            localized: this.entityLocalized
          });

        }
      );
  }

  getData(selector: any) {

    if (!this.currentFilterCfg) this.currentFilterCfg = {};

    this.loaderService.show();
    let lang = this.translationService.getSelectedLanguage();

    this.currentFilterCfg['Start'] = this.pagination.arrayIndex;
    this.currentFilterCfg['Length'] = this.pagination.maxPerPage;

    lastValueFrom(this.magazzinoService.getWarehouseMainGraph(selector.model, this.currentFilterCfg, lang))
      .then((ret: any) => {
        this.data = ret;

        if (!this.data || this.data.length == 0) return;

        this.pagination.totRecords = this.data.TotRows;
        // quando i risultati sono inferiori al maxPerPage, allora il maxPerPage diventa il totale dei risultati
        if (this.pagination.totRecords < this.pagination.maxPerPage) {
          this.maxValuesPerPageTmp = this.pagination.totRecords;
        }

        this.originalData = JSON.parse(JSON.stringify(this.data));

        this.maingraphType = 'GrossRevenue'; //GrossRevenue / NetRevenue

        this.timeSlot = 'h1';

        this.manageNewData(this.data);
      })
      .finally(() => {
        this.loaderService.hide();
      });
  }

  refreshGraph(maingraphType: string) {
    let labels: any = [];
    let dataActualRevenue: any = [];
    let dataPreviousRevenue: any = [];

    let elencoDati = JSON.parse(JSON.stringify(this.data.Items));

    if (this.showOnlyCompData) {
      elencoDati = elencoDati.filter((item: any) =>
        item.ActualValue && item.ActualValue.GrossRevenue > 0 &&
        item.PreviousValue && item.PreviousValue.GrossRevenue > 0
      );
    }

    this.totFcPerc = 0;
    elencoDati.forEach((record: any) => {

      switch (this.data.GroupedBy) {
        case 'Month':
          labels.push(this.MONTHS_SHORT[parseInt(record.Name)]);
          break;
        case 'WeekDay':
          labels.push(this.WEEKDAY[record.Name]);
          break;
        case 'Week':
          labels.push('W' + record.Name);
          break;
        case 'FBType':
          switch (record.Name) {
            case 'Food':
              labels.push(this.translate.instant('FILTRI.FB_TYPE.FOOD'));
              break;
            case 'Beverage':
              labels.push(this.translate.instant('FILTRI.FB_TYPE.BEVERAGE'));
              break;
            case 'Other':
              labels.push(this.translate.instant('FILTRI.FB_TYPE.OTHER'));
              break;
          }
          break;
        default:
          labels.push(record.Name);
      }

      if (record.ActualValue) {
        dataActualRevenue.push(parseFloat(record.ActualValue[maingraphType]).toFixed(2));
      }
      if (record.PreviousValue) {
        dataPreviousRevenue.push(parseFloat(record.PreviousValue[maingraphType]).toFixed(2));
      }

    });

    this.graphData = {
      labels: labels,
      datasets: [
        {
          type: 'bar',
          label: this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.REVENUE_PREV', { entity: this.entityLocalized }),
          backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset2'),
          data: dataPreviousRevenue,
          yAxisID: "y-axis-revenue",
          tipo: 'revprev',
          hidden: this.firstLoad || this.showTotIncidence
        },
        {
          type: 'bar',
          label: this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.REVENUE_CURR', { entity: this.entityLocalized }),
          backgroundColor: this.layoutConfigService.getConfig('colors.graph.dataset1'),
          data: dataActualRevenue,
          yAxisID: "y-axis-revenue",
          tipo: 'revcurr'
        }
      ]
    };

    this.totPrec = this.data.Totals.PreviousValue.NetRevenue;
    this.totAtt = this.data.Totals.ActualValue.NetRevenue,

      this.initChartJS();
  }

  manageNewData(data: any) {
    this.data = JSON.parse(JSON.stringify(data));

    // http://mantis.fbmanager.com/view.php?id=1075
    // SOLO per raggruppamento per mesi NON ORDINA

    if (this.grouping !== 'Month') {
      this.data.Items = this.data.Items.sort((a, b) => {
        return a.ActualValue[this.maingraphType] > b.ActualValue[this.maingraphType] ? -1 : (a.ActualValue[this.maingraphType] < b.ActualValue[this.maingraphType] ? 1 : 0);
      });
    } else {
      this.data.Items = this.data.Items.sort((a, b) => {
        return Number(a.Name) > Number(b.Name) ? 1 : (Number(a.Name) < Number(b.Name) ? -1 : 0);
      });
    }

    this.refreshGraph(this.maingraphType);

    if (this.showTableResult) {
      this.showTableResult = false; // Forzatura per farlo andare
      this.prepareShowTableResult();
    }

  }

  showAllData() {
    this.showAll = !this.showAll;
    this.manageNewData(this.originalData);
  }

  /** Init chart */
  initChartJS() {
    // this.firstLoad = false;
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    if (this.myChart) {
      this.myChart.destroy();
    }

    const moveChart = {
      id: 'moveChart',
      afterEvent: (chart: any, args: any) => {
        if (this.excludePagination || !chart || !chart.chartArea) return;

        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        canvas.addEventListener('mousemove', (event) => {
          const x = args.event.x;
          const y = args.event.y;

          if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const leftEnabled = this.pagination.arrayIndex - this.pagination.maxPerPage >= 0;
            canvas.style.cursor = leftEnabled ? 'pointer' : 'not-allowed';
          } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            const dataLength = this.pagination.totRecords;
            const rightEnabled = this.pagination.arrayIndex + this.maxValuesPerPageTmp < dataLength;
            canvas.style.cursor = rightEnabled ? 'pointer' : 'not-allowed';
          } else {
            canvas.style.cursor = 'default';
          }
        });
      },
      afterDraw: (chart: any, args: any, pluginOptions: any) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        if (this.excludePagination) {

          // Scrivo il totale dei risultati
          ctx.beginPath();
          const rect = canvas.getBoundingClientRect();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem 'Barlow Condensed'`;
          const pagination = `${chart.config.data.labels.length} ${this.translate.instant('COMMONS.RESULTS')}`;
          const textWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textWidth, top - 12);
          ctx.closePath();

        } else {

          class CircleChevron {
            draw(ctx, x1, pixel, enabled: boolean = true) {
              const angle = Math.PI / 180;

              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = 'rgba(102, 102, 102, .5)';
              ctx.fillStyle = 'white';
              ctx.arc(x1, height / 2 + top, 10, angle * 0, angle * 360, false);
              ctx.stroke();
              ctx.fill();
              ctx.closePath();

              // chevron Arrow
              ctx.beginPath();
              ctx.lineWidth = 3;
              ctx.strokeStyle = enabled ? '#5867DD' : '#CCCCCC';
              ctx.moveTo(x1 + pixel, height / 2 + top - 5.5);
              ctx.lineTo(x1 - pixel, height / 2 + top);
              ctx.lineTo(x1 + pixel, height / 2 + top + 5.5);
              ctx.stroke();
              ctx.closePath();
            }
          }

          const leftEnabled = this.pagination.arrayIndex - this.pagination.maxPerPage >= 0
          let drawCircleLeft = new CircleChevron();
          drawCircleLeft.draw(ctx, left, 3, leftEnabled);

          const dataLength = this.pagination.totRecords;
          const rightEnabled = this.pagination.arrayIndex + this.pagination.maxPerPage < dataLength;
          let drawCircleRight = new CircleChevron();
          drawCircleRight.draw(ctx, right, -3, rightEnabled);

          // Scrivo la paginazione
          ctx.beginPath();
          const rect = canvas.getBoundingClientRect();
          ctx.fillStyle = "#505050";
          ctx.font = `500 1rem 'Barlow Condensed'`;
          const pagination = `${this.translate.instant('EXPORT_PDF.PAGE')} ${(this.pagination.arrayIndex / this.pagination.maxPerPage) + 1} ${this.translate.instant('EXPORT_PDF.OF')} ${Math.ceil(this.pagination.totRecords / this.pagination.maxPerPage)}`;
          const textWidth = ctx.measureText(pagination).width;
          ctx.fillText(pagination, right - textWidth, top - 12);
          ctx.closePath();
        }
      }
    }

    this.myChart = new Chart(this.chart.nativeElement, {
      type: 'bar',
      data: this.graphData,
      // https://www.chartjs.org/docs/2.9.4/developers/plugins.html
      plugins: [
        moveChart,
        {
          afterDraw: (chart: any) => {
            const ctx = this.chart.nativeElement.getContext('2d');

            /************************************/
            // Disegno l'andamento
            const revAttuale = chart.config.data.datasets.find((record: any) => record.tipo === 'revcurr');
            const revPrecedente = chart.config.data.datasets.find((record: any) => record.tipo === 'revprev');

            if (!revAttuale || revAttuale.data.length > this.LIMIT_PRINT_INFOS || this.excludePagination) return;

            const min = chart.scales['x'].min;
            const max = chart.scales['x'].max;

            const endIteration = (min + this.maxValuesPerPageTmp - 1) > max ? max - min : this.maxValuesPerPageTmp;

            for (let index = 0; index < endIteration; index++) {
              let x = chart.scales['x'].getPixelForTick(index);
              let y = chart.scales['y-axis-revenue'].bottom;

              ctx.fillStyle = "#505050";
              ctx.font = `500 1rem 'Barlow Condensed'`;
              if (this.showTotIncidence) {
                const tRevAttuale = parseFloat(revAttuale.data[min + index]);
                const incidenza = tRevAttuale / this.totAtt * 100;

                ctx.fillText(`${this.formatNumber(incidenza, 0)}%`, x - 15, y + 15);
                ctx.fillText(`${this.formatNumber(tRevAttuale, 0)}€`, x - 15, y + 30);
              } else {
                const tRevAttuale = parseFloat(revAttuale.data[min + index]);
                const tRevPrecedente = parseFloat(revPrecedente.data[min + index]);

                ctx.fillStyle = revPrecedente.backgroundColor;
                if (!revPrecedente.hidden) {
                  const label = this.utility.formatNumber(tRevPrecedente, this.locale, 0, true, true);
                  const textWidth = ctx.measureText(label).width;
                  ctx.fillText(`${label}`, x - (textWidth / 2), y + 17);
                }
                ctx.fillStyle = revAttuale.backgroundColor;
                if (!revAttuale.hidden) {
                  const label = this.utility.formatNumber(tRevAttuale, this.locale, 0, true, true);
                  const textWidth = ctx.measureText(label).width;
                  ctx.fillText(`${label}`, x - (textWidth / 2), y + 30);
                }
              }
            }
          },
        }
      ],
      options: {
        name: 'StorageMainGraph',
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            bodyFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            titleFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            footerFont: {
              family: "Barlow Condensed",
              weight: '500',
              size: 14

            },
            padding: 10,
            caretPadding: 10,
            callbacks: {
              beforeTitle: (tooltipItem) => {
                return tooltipItem[0].dataset.label;
              },
              title: (tooltipItem) => {
                return tooltipItem[0].label;
              },
              label: (tooltipItem) => {
                return this.utility.formatNumber(parseFloat(tooltipItem.raw), this.locale, 2, true, true);
              }
            }
          },
          legend: {
            labels: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              }
            },
            display: true,
            position: 'bottom',
            onClick: (e, legendItem) => {
              var index = legendItem.datasetIndex;
              var ci = this.myChart;
              var meta = ci.getDatasetMeta(index);

              // See controller.isDatasetVisible comment
              meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

              this.myChart.config.data.datasets[index].hidden = meta.hidden;

              // We hide a dataset ... rerender the chart
              ci.update();

              this.ref.detectChanges();
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          x: {
            min: 0,
            max: this.pagination.maxPerPage - 1,
            display: true,
            grid: {
              display: false
            },
            stacked: false,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              padding: this.pagination.maxPerPage > this.LIMIT_PRINT_INFOS || this.excludePagination ? 0 : this.PADDING_WITH_INFOS,
              callback: (value: any, index: number, values: any) => {
                const overrideValue = this.graphData.labels[value];
                if ((new Date(overrideValue)).toString().length > 0 && (new Date(overrideValue)).toString() !== 'Invalid Date') {
                  const date = new Date(new Date(overrideValue).setHours(0, 0, 0, 0));
                  return date.toLocaleString(localStorage.getItem('language') || '').replace(/\s\d{2}:\d{2}:\d{2,4}$/, '').replace(',', '');
                } else {
                  return overrideValue.length > 15 ? overrideValue.substring(0, 15) + '...' : overrideValue;
                }
              }
            },
          },
          "y-axis-revenue": {
            beginAtZero: true,
            position: 'left',
            display: true,
            stacked: false,
            ticks: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              callback: (value: any, index: number, values: any) => {
                if (parseFloat(value) > 0)
                  return this.utility.kFormatter(value, this.locale);
              },
              precision: 0,
              integerSteps: true
            },
            title: {
              font: {
                family: "Barlow Condensed",
                weight: '500',
                size: 14

              },
              display: true,
              text: this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.REVENUE_Y', { entity: this.entityLocalized })
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 18,
            top: 18,
            bottom: 0
          }
        }
      }
    });

    const moveScroll = () => {
      // Rimuove il listener precedente per prevenire la duplicazione
      this.myChart.canvas.removeEventListener('click', this.currentClickListener);


      this.currentClickListener = (event) => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = this.myChart;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        let refreshData = false;
        if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
          this.pagination.arrayIndex -= this.pagination.maxPerPage;
          refreshData = true;
        } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
          this.pagination.arrayIndex += this.pagination.maxPerPage;
          refreshData = true;
        }
        if (refreshData) {
          this.getData({
            model: this.entity,
            localized: this.entityLocalized
          });
        }

      };

      // Aggiunge il nuovo listener
      this.myChart.canvas.addEventListener('click', this.currentClickListener);
    }

    this.myChart.ctx.onclick = moveScroll();
  }

  excludeGraphPagination(event: any) {
    this.excludePagination = event.checked;
    this.pagination.maxPerPage = this.pagination.totRecords;
    if (this.excludePagination) {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.pagination.totRecords;
      this.myChart.config.options.scales.x.ticks.padding = 0;
    } else {
      this.myChart.config.options.scales.x.min = 0;
      this.myChart.config.options.scales.x.max = this.pagination.maxPerPage - 1;
      this.myChart.config.options.scales.x.ticks.padding = this.PADDING_WITH_INFOS;
      this.pagination.maxPerPage = this.DEFAULT_PAGINATION_RECORD;
    }
    this.maxValuesPerPageTmp = this.pagination.maxPerPage;
    this.getData({
      model: this.entity,
      localized: this.entityLocalized
    });
  }

  onKeyUpMaxValues(event: KeyboardEvent) {
    if (event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.pagination.maxPerPage = parseInt(this.pagination.maxPerPage);
      this.pagination.arrayIndex = 0;
      this.maxValuesPerPageTmp = this.pagination.maxPerPage;
      this.getData({
        model: this.entity,
        localized: this.entityLocalized
      });
    }
  }

  getMaingraphTitle() {
    if (this.maingraphType == 'GrossRevenue') {
      return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.GROSSREVENUE', { entity: this.entityLocalized });
    } else {
      return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.NETREVENUE', { entity: this.entityLocalized });
    }
  }

  getMaingraphAction() {
    if (this.maingraphType == 'GrossRevenue') {
      return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.NETREVENUE', { entity: this.entityLocalized });
    } else {
      return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.GROSSREVENUE', { entity: this.entityLocalized });
    }
  }

  setRevenueType() {
    if (this.maingraphType == 'GrossRevenue') {
      this.maingraphType = 'NetRevenue';
    } else {
      this.maingraphType = 'GrossRevenue';
    }

    this.refreshGraph(this.maingraphType);
  }

  getTotAttuale() {
    return this.utility.formatNumber(this.totAtt, this.locale, 2, true, true);
  }

  getTotPrecedente() {
    return this.utility.formatNumber(this.totPrec, this.locale, 2, true, true);
  }

  getTotAndamento() {
    const totAndamento = Math.round(((this.totAtt - this.totPrec) / this.totPrec) * 100)
    return (totAndamento !== Infinity) ? totAndamento : 0;
  }

  changeGrouping() {
    this.loaderService.show();
    // Ripristino la lunghezza dei valori per pagina
    this.maxValuesPerPageTmp = this.pagination.maxPerPage;
    let currentFilter = this.filtriService.filterConfig;
    currentFilter['Grouping'] = this.grouping;
    let lang = this.translationService.getSelectedLanguage();
    this.magazzinoService.getWarehouseMainGraph(this.entity, currentFilter, lang).toPromise()
      .then((data: any) => {
        this.manageNewData(data);
        this.loaderService.hide();
      })
      .catch(() => this.loaderService.hide());
  }

  formatNumber(x: any, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
    return this.utility.formatNumber(x, this.locale, decimal, showZero, currency);
  }

  showTotalIncidence() {
    this.showTotIncidence = !this.showTotIncidence;
    this.refreshGraph(this.maingraphType);
  }

  showOnlyCompareData() {
    this.showOnlyCompData = !this.showOnlyCompData;
    this.refreshGraph(this.maingraphType);
  }

  /******************************************************************************
    ______                       _    __   ___       _______   __
   |  ____|                     | |   \ \ / / |     / ____\ \ / /
   | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
   |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
   | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
   |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
       | |
       |_|
  ******************************************************************************/

  exportAsXLSX() {
    switch (this.grouping) {
      case 'Supplier':
        this.xlsxByCompany('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.SUPPLIER');
        break;
      case 'Company':
        this.xlsxByCompany('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.COMPANY');
        break;
      case 'Category':
        this.xlsxByCompany('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.CATEGORY');
        break;
      case 'Month':
        this.xlsxByCompany('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.MONTH');
        break;
      case 'FBType':
        this.xlsxByCompany('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.FBTYPE');
        break;
    }
  }

  xlsxByCompany(columnName: string) {
    this.loaderService.show();

    let xlsx: any[] = [];
    let merges: any[] = [];
    let i: number = 0;

    let rowsStyles: any = [];

    const revenueTypeCurrLabel = this.maingraphType == 'GrossRevenue' ? 'MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.GROSSREVENUE_CURR' : 'MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.NETREVENUE_CURR';
    const revenueTypePrevLabel = this.maingraphType == 'GrossRevenue' ? 'MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.GROSSREVENUE_PREV' : 'MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.NETREVENUE_PREV';

    // Per i totali
    rowsStyles[1] = {
      fgColor: { rgb: 'ffdd45' },
      bold: true,
      alignment: { wrapText: true }
    };

    // FACCIO PRIMA IL BODY, mi serve per calcolare FC % TOT
    let xlsxBody: any[] = [];
    let totFc: number = 0;
    this.data.Items.forEach((item: any) => {
      rowsStyles.push({
        sz: 10
      });

      let columns: any = {};

      const currValue = this.maingraphType == 'GrossRevenue' ? this.utility.formatNumberExcel(item.ActualValue.GrossRevenue, 2) : this.utility.formatNumberExcel(item.ActualValue.NetRevenue, 2);
      const prevValue = this.maingraphType == 'GrossRevenue' ? this.utility.formatNumberExcel(item.PreviousValue.GrossRevenue, 2) : this.utility.formatNumberExcel(item.PreviousValue.NetRevenue, 2);


      columns[`${this.translate.instant(columnName, { entity: this.entityLocalized })}`] = item.Name.toUpperCase();
      columns[`${this.translate.instant(revenueTypePrevLabel, { entity: this.entityLocalized })}`] = prevValue;
      columns[`${this.translate.instant(revenueTypeCurrLabel, { entity: this.entityLocalized })}`] = currValue;
      columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.DIFF')}`] = this.utility.formatNumberExcel(currValue - prevValue, 2);
      columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.DIFF_%')}`] = prevValue ? this.utility.formatNumberExcel((currValue - prevValue) / prevValue * 100, 2) : this.utility.formatNumberExcel(0, 2);

      xlsxBody.push(columns);
      i++;
    });

    // POI FACCIO I TOTALI
    let columns: any = {};

    columns[`${this.translate.instant(columnName, { entity: this.entityLocalized })}`] = this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.TOTALS');
    columns[`${this.translate.instant(revenueTypePrevLabel, { entity: this.entityLocalized })}`] = this.utility.formatNumberExcel(this.totPrec, 2);
    columns[`${this.translate.instant(revenueTypeCurrLabel, { entity: this.entityLocalized })}`] = this.utility.formatNumberExcel(this.totAtt, 2);
    columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.DIFF')}`] = this.utility.formatNumberExcel(this.totAtt - this.totPrec, 2);
    columns[`${this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.DIFF_%')}`] = this.totPrec > 0 ? this.utility.formatNumberExcel((this.totAtt - this.totPrec) / this.totPrec * 100, 2) : this.utility.formatNumberExcel(0, 2);

    xlsx.push(columns);

    xlsx = xlsx.concat(xlsxBody);

    this.loaderService.hide();
    this.excelService.exportAsExcelFile(xlsx, this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.EXPORT_TITLE'), merges, this.columnsList, 1, rowsStyles, true);
  }

  /******************************************************************************
    ______                       _     _____  _____  ______
   |  ____|                     | |   |  __ \|  __ \|  ____|
   | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
   |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
   | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
   |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
         | |
         |_|
  ******************************************************************************/

  getGroupingLabel() {
    switch (this.grouping) {
      case 'Supplier':
        return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.SUPPLIER');
      case 'Company':
        return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.COMPANY');
      case 'Category':
        return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.CATEGORY');
      case 'Month':
        return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.MONTH');
      case 'FBType':
        return this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.FBTYPE');
      default:
        return '';
    }
  }

  exportAsPDF() {

    const config: any = {
      title: this.translate.instant('EXPORT_PDF.TITLE'),
      description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
      waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
      success: this.translate.instant('EXPORT_PDF.MESSAGE'),
      yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
      noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
      closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
      askTitle: true,
      pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
      askExplodeRows: false,
    };

    const dialogRef = this.layoutUtilsService.exportElement(config);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result['header'] = {
          export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.EXPORT_TITLE') }),
          period: this.filtriService.getCurrentPeriod()
        }
        result['footer'] = {
          printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
          page: this.translate.instant('EXPORT_PDF.PAGE'),
          of: this.translate.instant('EXPORT_PDF.OF')
        }
        result['language'] = this.translationService.getSelectedLanguage();
        result['table'].headerRows = 1;
        result['orientation'] = 'portrait';
        let columnsTot = this.columnsList.filter((item: any) => item.enabled).length;

        let pdfTableBody;
        switch (this.grouping) {
          case 'Supplier':
            pdfTableBody = this.getPDFTableBody('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.SUPPLIER');
            break;
          case 'Company':
            pdfTableBody = this.getPDFTableBody('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.COMPANY');
            break;
          case 'Category':
            pdfTableBody = this.getPDFTableBody('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.CATEGORY');
            break;
          case 'Month':
            pdfTableBody = this.getPDFTableBody('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.MONTH');
            break;
          case 'FBType':
            pdfTableBody = this.getPDFTableBody('MAGAZZINO.STATISTICHE.MAINGRAPH.GROUPING_TYPE.FBTYPE');
            break;
        }

        this.pdfService.makePdf(result, pdfTableBody, Array(columnsTot).fill('auto'));
      };
    });

  }

  getPDFTableBody(columnName: string) {

    const revenueTypeCurrLabel = this.maingraphType == 'GrossRevenue' ? 'MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.GROSSREVENUE_CURR' : 'MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.NETREVENUE_CURR';
    const revenueTypePrevLabel = this.maingraphType == 'GrossRevenue' ? 'MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.GROSSREVENUE_PREV' : 'MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.NETREVENUE_PREV';


    let body: any = [];
    let tmpRow: any = [];

    // aggiungo intestazione
    // Header
    tmpRow = [
      { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant(columnName, { entity: this.entityLocalized })}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant(revenueTypePrevLabel, { entity: this.entityLocalized })}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant(revenueTypeCurrLabel, { entity: this.entityLocalized })}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.DIFF')}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.DIFF_%')}`, style: 'tableHeaderStyle' },
    ];
    body.push(tmpRow.filter((item: any) => item.visible));


    let i: number = 0;
    let totFc: number = 0;
    let bodyTmp: any = [];
    this.data.Items.forEach((item: any) => {

      const currValue = this.maingraphType == 'GrossRevenue' ? item.ActualValue.GrossRevenue : item.ActualValue.NetRevenue;
      const prevValue = this.maingraphType == 'GrossRevenue' ? item.PreviousValue.GrossRevenue : item.PreviousValue.NetRevenue;

      tmpRow = [
        { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Name.toUpperCase(), style: 'tableBodyStyle' },
        { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.utility.formatNumber(prevValue, this.locale, 2), style: 'tableBodyStyle' },
        { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.utility.formatNumber(currValue, this.locale, 2), style: 'tableBodyStyle' },
        { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.utility.formatNumber(currValue - prevValue, this.locale, 2), style: 'tableBodyStyle' },
        { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: prevValue > 0 ? this.utility.formatNumber((currValue - prevValue) / prevValue * 100, this.locale, 2) : this.utility.formatNumber(0, this.locale, 2), style: 'tableBodyStyle' },
      ];
      bodyTmp.push(tmpRow.filter((item: any) => item.visible));
      i++;

    });

    // totali Header
    tmpRow = [
      { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.STATISTICHE.MAINGRAPH.XLS_COLUMNS.TOTALS')}`, style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.utility.formatNumber(this.totPrec, this.locale, 2), style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.utility.formatNumber(this.totAtt, this.locale, 2), style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.utility.formatNumber(this.totAtt - this.totPrec, this.locale, 2), style: 'tableHeaderStyle' },
      { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.totPrec > 0 ? this.utility.formatNumber((this.totAtt - this.totPrec) / this.totPrec * 100, this.locale, 2) : this.utility.formatNumber(0, this.locale, 2), style: 'tableHeaderStyle' },
    ];
    body.push(tmpRow.filter((item: any) => item.visible));


    body = body.concat(bodyTmp);

    return body;
  }

  showTableResult: boolean = false;
  exportTableRows: any;
  prepareShowTableResult() {
    this.showTableResult = !this.showTableResult;
    this.exportTableRows = [];
    if (this.showTableResult) {

      this.data.Items.forEach((item: any) => {
        const currValue = this.maingraphType == 'GrossRevenue' ? item.ActualValue.GrossRevenue : item.ActualValue.NetRevenue;
        const prevValue = this.maingraphType == 'GrossRevenue' ? item.PreviousValue.GrossRevenue : item.PreviousValue.NetRevenue;

        let label = '';
        switch (this.grouping) {
          case 'Month':
            label = this.MONTHS_SHORT[parseInt(item.Name)];
            break;
          case 'WeekDay':
            label = this.WEEKDAY[item.Name];
            break;
          case 'Week':
            label = 'W' + item.Name;
            break;
          default:
            label = item.Name.toUpperCase();
        }

        this.exportTableRows.push([
          label,
          this.utility.formatNumber(currValue, this.locale, 2),
          this.utility.formatNumber(prevValue, this.locale, 2),
          this.utility.formatNumber(currValue - prevValue, this.locale, 2),
          prevValue > 0 ? this.utility.formatNumber((currValue - prevValue) / prevValue * 100, this.locale, 2) : 0,
        ]);
      });


      setTimeout(() => {
        let dtOptions: any = {
          destroy: true,
          language: {
            emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
            zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
            processing: '<img class="spinner-abs-centered" src="/assets/media/gif/loaderNEW.gif" alt="">'
          },
          paging: false,
          searching: false,
          ordering: false,
          //scrollY: 'calc(100vh - 350px)',
          //scrollCollapse: true,
          autoWidth: true
        };
        this.table = $('#table_smg').DataTable(dtOptions);
      }, 100);
    }

  }

  getFillRows() {
    return this.data.Items.filter((item: any) => item.ActualValue.NetRevenue > 0).length;
  }
}
