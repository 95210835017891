declare var $: any;


export function parseNumber(n: any, locale: string, decimal: any = 2) {
  if (!n) return 0;

  if (typeof n === 'string' || n instanceof String) {
    const ds: any = getDecimalSeparator(locale);
    let ts = (ds === '.') ? ',' : '.';
    const formatted: number = Number(n.replace(ts, '').replace(ds, '.'));
    return decimal ? parseFloat(formatted.toFixed(decimal)) : formatted;
  }
  else {
    return decimal ? parseFloat(n.toFixed(decimal)) : Number(n);
  }
}

export function getDecimalSeparator(locale) {
  const numberWithDecimalSeparator = 1.1;
  return Intl.NumberFormat(locale)?.
    formatToParts(numberWithDecimalSeparator)?.
    find(part => part.type === 'decimal')?.
    value;
}

import * as numeral from 'numeral';

export function kFormatter(num: any, locale: string, decimal: boolean = true, hideSymbol: boolean = false) {
  const number = numeral(num);
  const currencyCode = locale == 'en' ? ' £' : ' €';
  let formattedValue = '';

  formattedValue = decimal ? number.format('0.0a') : number.format('0a');

  if (hideSymbol) {
    return formattedValue;
  } else {
    return formattedValue + ' ' + currencyCode;
  }

}

export function getSeparator(locale, separatorType = 'decimal') {
  const numberWithGroupAndDecimalSeparator = 1000.1;
  return Intl.NumberFormat(locale)?.
    formatToParts(numberWithGroupAndDecimalSeparator)?.
    find(part => part.type === separatorType)?.
    value;
}

export function formatNumber(x: any, locale: string, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
  // Converti in numero se è una stringa
  if (typeof x === 'string') {
    x = parseNumber(x, locale);
  }

  if (isNaN(x) || x === undefined || x === null) {
    x = 0;
  }

  if (!showZero && x == 0) x = 0;

  // Arrotonda con toFixed e converte di nuovo in numero
  x = parseFloat(x.toFixed(decimal));

  let formatted = '';

  if (currency) {
    formatted = x.toLocaleString(locale, {
      style: 'currency',
      currency: locale == 'en' ? 'GBP' : 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  } else {
    formatted = x.toLocaleString(locale, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  }

  return formatted;
}

export function formatNumberExcel(x: number, decimal: number = 2): number {
  if (!x || String(x).includes('NaN')) { return 0.00 };
  const res = Math.round((x + Number.EPSILON) * 100) / 100;
  return res ? res : 0.00;
}

export function getLocaleData(date: string, locale: string) {
  const event = new Date(date);
  if (event.getFullYear() > 1900) {
    const options = {};
    return event.toLocaleDateString(locale, options);
  }
  else {
    return '--/--/----';
  }

}

export function select2All(ev: any, idElement: string, returnType: string = '') {
  if (ev.checked) {
    (<any>$(`#${idElement}`)).select2('destroy').find('option').prop('selected', 'selected').end().select2();
  } else {
    (<any>$(`#${idElement}`)).select2('destroy').find('option').prop('selected', false).end().select2();
  }
  if (!returnType) {
    return $(`#${idElement}`).val();
  } else {
    let ret: any = [];
    (<any>$(`#${idElement}`)).select2('data').forEach((element: any) => {
      ret.push({
        Id: element.id,
        Name: element.text
      })
    });
    return ret;
  }
}

export function sortList(list: any, table: any, allData: any) {
  let currentOrderId: any = [];
  $(`#${table} tbody tr`).each((index: number, tr: any) => {
    let itemId = $(tr).data('itemid');
    let find = list.find((item: any) => {
      // confronto direttamente senza verifica se c'è o meno il valore erchè quando ho 0
      // il test fallisce altrimenti.
      return item?.Id.toString() == itemId.toString()
    });
    if (find) currentOrderId.push(find);
  })
  if (currentOrderId.length === 0) {
    console.log('NON E\' STATO POSSIBILE ORDINARE, MANCA item.Id');
    return list;
  }
  return currentOrderId;
}

export function insertAndShift(arr, from, to) {
  let cutOut = arr.splice(from, 1)[0]; // cut the element at index 'from'
  arr.splice(to, 0, cutOut);            // insert it at index 'to'
  return arr;
}

/********************************************* */

export function generateRaimbow(index?: number) {

  const raimbow: string[] = [
    // Marroni
    '#564b3f',
    '#8b7155',
    '#b4997c',
    '#c6bbaf',
    '#dbd1c6',
    // Verdi
    '#5d5b3f',
    '#9f9239',
    '#b4ab7c',
    '#c2c29d',
    '#e0dfbf',
    // Blu
    '#3f535d',
    '#527a93',
    '#7c9bb4',
    '#a2b5c8',
    '#bfd1e0',
    // Rossi
    '#5D3F3F',
    '#8B5555',
    '#B47C7C',
    '#C6AFAF',
    '#DBC6C6'
  ];

  if (index && index >= 0) {
    return index > raimbow.length ? raimbow[raimbow.length - 1] : raimbow[index];
  } else {
    // genero un indice random tra 0 e la lunghezza dell'array
    const randomIndex = Math.floor(Math.random() * raimbow.length);
    return raimbow[randomIndex];
  }
}

export function capitalize(word: string) {
  return word[0].toUpperCase() + word.substring(1);
}

export function getFBTypeIndexByValue(value: string) {
  const FBTYPES = [
    'ALL',
    'OTHER',
    'FOOD',
    'BEVERAGE',
    'FOOD_OTHER',
    'FOOD_BEVERAGE',
    'BEVERAGE_OTHER'
  ];

  return FBTYPES.indexOf(value.toUpperCase());
}


  /***************************************************************************************************** */
  /**
   * Gets base unit according to selected unit
   * @param newIngredient 
   */
  export function getBaseUnit(newIngredient: any, unitList: any): any {
    const unit = unitList.find((unit: any) => unit.Id === newIngredient.Unit.Id);
    const baseUnit = unitList.find((u: any) => u.Id === unit.MainUnitId)
    let u: any = unit;
    if (baseUnit && baseUnit.Name.toLowerCase() === 'kg' && unit && unit.Name.toLowerCase() !== 'g') {
      u = unitList.find((u: any) => u.Name.toLowerCase() === 'g');
    } else if (baseUnit && baseUnit.Name.toLowerCase() === 'lt' && unit && unit.Name.toLowerCase() !== 'ml') {
      u = unitList.find((u: any) => u.Name.toLowerCase() === 'ml');
    } else {
      u = unit;
    }

    return {
      Id: u.Id,
      Name: u.Name,
      A: u.A,
      B: u.B,
      Yield: u.Yield,
      BaseId: u.MainUnitId,
      BaseUnitName: u.Name
    };
  }

  export function generateRandomHashString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }