import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FiltriService } from '@app/core/services/filtri.service';
import { SwitchGroupService } from '@app/core/_base/layout';
import { BehaviorSubject, Subscription, lastValueFrom, map } from 'rxjs';
import { ContoEconomicoService } from '../conto-economico/conto-economico.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { LoaderService } from '@app/core/services/loader.service';

@Component({
    selector: 'kt-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    ceData: any;
    currentFilterCfg: any;
    filtriServiceSubscription!: Subscription;
    switchGroupSubcription!: Subscription;
    routeSubscription!: Subscription;
    
    filterCompanies: any;

    constructor(
        private ceService: ContoEconomicoService,
        public filtriService: FiltriService,
        private switchGroupService: SwitchGroupService,
        private router: Router,
        private ref: ChangeDetectorRef,
        public staticCollectionsService: StaticCollectionsService,
        private activatedRoute: ActivatedRoute,
        public loaderService: LoaderService
    ) {
        document.body.classList.add('kt-aside--minimize');

    }

    async ngOnInit() {
        await this.staticCollectionsService.fillStaticCollection(false);

        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            async (filterConfig: any) => {

                const cfgFromLocalStorage = localStorage.getItem('currentFilterCfg') ? JSON.parse(localStorage.getItem('currentFilterCfg') || '') : '';

                // Se ho un filtro memorizzato lo recupero
                if (cfgFromLocalStorage) {
                    this.currentFilterCfg = JSON.parse(JSON.stringify(cfgFromLocalStorage));
                    localStorage.removeItem('currentFilterCfg');
                } else {

                    if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
                        return
                    };
                    if (filterConfig.constructor !== Object) {
                        return
                    };

                    //if (JSON.stringify(this.currentFilterCfg) == JSON.stringify(filterConfig)) {
                    //    return;
                    //}

                    this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                }

                this.ceData = undefined;
                this.ref.detectChanges();
                this.loaderService.show();
                lastValueFrom(this.ceService.getCE(this.currentFilterCfg))
                    .then((ce: any) => {
                        this.loaderService.hide();
                        this.ceData = ce.data;
                        this.ref.detectChanges();
                        if (this.currentFilterCfg.Companies && this.currentFilterCfg.Companies.length > 0) {
                            this.filterCompanies = this.currentFilterCfg.Companies;
                        }
                    })
                    .finally(() => this.loaderService.hide());

            }
        );
    }

    ngOnDestroy() {
        this.filtriServiceSubscription.unsubscribe();
        this.switchGroupSubcription.unsubscribe();
    }

    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            async (event: any) => {
                if (event.change) {
                    this.staticCollectionsService.aziendeForPL$ = undefined;
                    await this.staticCollectionsService.fillStaticCollection(true);
                    this.clearFilters(false);
                }
            }
        );
    }

    goToContoEconomico() {
        localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg))
        this.router.navigate(['/amministrazione/conto-economico']);
    }

    clearFilters(actionFilter: boolean = true) {
        this.filtriService.clearFilters$.next(actionFilter);
        this.currentFilterCfg = {
            Period: 'year',
            fromLocal: true,
            TimeInterval: 'none',
            DayOfWeek: 0
        };
        this.filtriService.filterConfig = this.currentFilterCfg;
    }

    manageFilterCompanies(companies: any) {
        this.currentFilterCfg['Companies'] = companies;
        this.filtriService.filterConfig = this.currentFilterCfg;
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }


}
